import { useContext, useState } from "react";
import { Box, Container, Paper } from "@mui/material";

import { toast } from "react-toastify";
import api from "../../../../../services/api";
import useStore from "../../../../../store/useStore";
import { Context } from "../../../../../components/Context/AuthContext";

import dayjs from "dayjs";
import "dayjs/locale/pt";

import FormWeightStep1 from "./FormWeightStep1";
import FormWeightStep2 from "./FormWeightStep2";
import FormWeightStep3 from "./FormWeightStep3";
import FormWeightStep4 from "./FormWeightStep4";
import SuccessWeight from "./SuccessWeight";

export default function MultiStepFormWeight({ handleClose }) {
  // console.log("MultiStepFormWeight");
  const { aux_add, setAuxAdd } = useContext(Context);
  const usestore = useStore();
  const livestockRepos = usestore.reposLivestock.getLivestockStore("id", usestore.reposLivestock.livestockRepos.id)[0];

  const property_id = localStorage.getItem("Property") || 1;
  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));

  const [data, setData] = useState({
    id_property: property_id,
    id_livestock: livestockRepos.id,
    id_user: userCurrent.id,
    lote: {},
    num_animals: 0,
    num_females: 0,
    num_males: 0,
    new_weight: 0,
    unit: "kg",
    valor: 0,
    date_start: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    date_prev_end: dayjs().add(3, "day").format("YYYY-MM-DD HH:mm:ss"),
    isDeleted: "",
    progress: "A FAZER",
    planning: "realized",
    table_animals: [],
    table_selected: {},
    table_confirm: [],
  });

  // controle do dialog dos componentes
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    handleScroll();
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    handleScroll();
  };

  const handleScroll = () => {
    setTimeout(() => {
      window.jQuery(window.jQuery.find('#focus_top_modal')[0]).parent()[0].scrollTo({ top: 0, left: 0, behavior: "smooth", });
    }, 300);
  };

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ["FormWeightStep1", "FormWeightStep2", "FormWeightStep3", "FormWeightStep4", "SuccessWeight"];

  const onSubmit = async () => {
    let formOk = true;

    if (!data.lote || !data.table_confirm.length) {
      formOk = false;
    }

    if (formOk) {
      if (data.date_start == data.date_prev_end) {
        data.progress = "CONCLUÍDA";
        data.date_end = data.date_start;
      }

      const header = { header: { "Content-Type": "application/json" } };
      const res = await api.post("/activityWeight/save", data, header)
        .then((response) => {
          //console.log(response.data);
          if (response.data.isSuccess) {
            toast.success("Pesagem dos animais realizada com sucesso!");
            usestore.reposLivestock.fetch({ 'id_farm': property_id });
            usestore.reposActivityWeight.fetch({ 'id_property': property_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleNext();
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          toast.error("Erro ao realizadar pesagem dos animais, tente novamente mais tarde!");
        });
    } else {
      toast.error("Por favor, preencha os campos obrigatórios!");
    }
  };

  const setOptLotes = () => {
    // gera novo array sem alterar o array do repos
    let aux_lotes = JSON.parse(JSON.stringify(livestockRepos.lotes));
    const opt_lotes = aux_lotes.filter((lote) => {
      if (lote.total_animals > 0) {
        lote.label = lote.name;
        if (lote.num_animals > 0) {
          lote.list_animals.unshift({
            id: 0,
            id_lote: lote.id,
            identifier: 'Não identificados (' + lote.num_animals + ")",
            sexo: '-',
            weight: lote.weight,
            new_weight: lote.weight,
          })
        }
        return lote;
      }
    });

    data.opt_lotes = opt_lotes;
    data.lote = opt_lotes[0];
    data.unit = opt_lotes[0]?.weight_unit || "kg";
    data.table_animals = opt_lotes[0]?.list_animals;
  };

  const getNumAnimals = () => {
    let count_femeas = data.table_animals.filter((animal) => {
      if ('Fêmea' == animal.sexo) {
        return animal;
      }
    });
    let count_machos = data.table_animals.filter((animal) => {
      if ('Macho' == animal.sexo) {
        return animal;
      }
    });
    let count_ni = data.table_animals.filter((animal) => {
      if ('-' == animal.sexo) {
        return animal;
      }
    });

    data.num_animals = count_ni.length > 0 ? count_ni[0].identifier.replace(/.*\((.*)\).*/, '$1') : 0;
    data.num_females = count_femeas.length;
    data.num_males = count_machos.length;
  };

  //chamada dos componentes
  const getStepContent = (step) => {
    const obj_fn = {
      setData: setData,
      handleNext: handleNext,
      handleBack: handleBack,
      activeStep: activeStep,
      steps: steps,
      handleClose: handleClose,
      onSubmit: onSubmit,
    };
    switch (step) {
      case 0: // data de realização
        return <FormWeightStep1 data={data} obj_fn={obj_fn} />;
      case 1: // seleção do lote
        if (!data.opt_lotes) {
          setOptLotes();
        }
        return <FormWeightStep2 data={data} obj_fn={obj_fn} />;
      case 2: // definição dos pesos
        return <FormWeightStep3 data={data} obj_fn={obj_fn} />;
      case 3: // confirmação da pesagem
        //getNumAnimals();
        return <FormWeightStep4 data={data} obj_fn={obj_fn} />;
      case 4:
        return (
          <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessWeight handleClose={handleClose} data={data} />
            </Paper>
          </Container>
        );
      default:
        return "Não existente";
    }
  };

  return ( <>
    <Box sx={{ width: "100%" }}>
      {getStepContent(activeStep)}
    </Box>
  </> );
}

import React, { useEffect, useState } from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import { green, grey, red } from '@mui/material/colors'

export default function CashFlowTable({ data, obj_fn }) {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const columns = data?.columns || []
  const rows = data?.rows || []

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Typography sx={{ flex: "1 1 100%", mb: 1 }} variant="h6" id="cashFlowCategoryTitle" component="div">
        Fluxo de Caixa por Categoria
      </Typography>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader id={"cashFlowCategory"}>
          <TableHead>
            <TableRow >
              {columns.map((column) => (
                <TableCell
                  key={"TRC_" + column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    fontWeight: 'bold',
                    backgroundColor: grey[200]
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, indexr) => {
                return (<>
                  <TableRow key={"TR_" + row.id} hover >
                    <TableCell
                      colSpan={2}
                      key={"TC_" + row.conta + "_cat_subcat"}
                      align={'left'}
                      style={{
                        minWidth: 200,
                        textAlign: row.category == 'category' ? 'left' : 'right'
                      }}
                    >
                      {row.conta}
                    </TableCell>
                    <TableCell
                      key={"TC_" + row.conta + "_saldo"}
                      align={'right'}
                      sx={{
                        minWidth: 110,
                        '&:not(:hover)': {
                          backgroundColor: row.tipo == "Receita" ? green[50] : red[50],
                        }
                      }}
                    >
                      {obj_fn.formatValue(row.saldo * (row.tipo == "Receita" ? 1 : -1), true)}
                    </TableCell>
                    {(row.saldo_dia).map((saldo, index) => {
                      return (
                        <TableCell
                          key={"TC_" + row.conta + "_dia_" + index}
                          align={'right'}
                          sx={{
                            minWidth: 100,
                            '&:not(:hover)': {
                              backgroundColor: saldo == 0 ? '#FFF' : row.tipo == "Receita" ? green[50] : red[50],
                            }
                          }}
                        >
                          {obj_fn.formatValue(saldo * (row.tipo == "Receita" ? 1 : -1), true)}
                        </TableCell>
                        )
                    })}
                  </TableRow>
                </>)
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage={"Linhas por página"}
        rowsPerPageOptions={[15, 30, 50]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Card, CardHeader, Divider, Grid, Stack } from '@mui/material'
import ButtonRegisterProductionTargetHarvest from './ButtonRegisterProductionTargetHarvest'
import { useState } from 'react'
import { useEffect } from 'react'
import useHarvestTargetReposStore from '../../../store/useHarvestTargetReposStore'
import useStore from '../../../store/useStore'
import CardProductionharvestTabsAreaProduction from './CardProductionharvestTabsAreaProduction'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import CropOriginalIcon from '@mui/icons-material/CropOriginal'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'
import { useContext } from 'react'
import { Context } from '../../../components/Context/AuthContext'
import useHarvestTargetCostReposStore from '../../../store/useHarvestTargetCostReposStore'
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 0, mt: 1, ml: 0, mr: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function CardProductionharvestTabsAreaTop({ listPlanning }) {
  console.log('----Production: harvest-----')
  const { aux_add, setAuxAdd } = useContext(Context)
  // nível 1 superior
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  // contém a listas dos planejamento das metas da safra id_areamap diferentes
  // podem ter planejamentos com id
  console.log('listPlanning1')
  console.log(listPlanning)

  const usestore = useStore()

  //*HARVEST->ÁREAS VINCULADAS
  // *Seleciono os dados da safra específica (harvest)
  // Otenho as áreas vinculadas
  const reposHarvest = usestore.reposHarvest.getHarvestStore('id', usestore.reposHarvest.harvestRepos.id)[0]
  //console.log("reposHarvest")
  //console.log(reposHarvest)

  // *Seleciono todas as áreas existentes (informação completa da área)
  const listAreas = usestore.reposAreas.listAreasMap
  //console.log("listAreas")
  //console.log(listAreas)

  // *Preciso inserir informaçãos na área vinculada
  // Crie um array com os IDs das áreas
  const areaIds = reposHarvest.areas.map((area) => area.id)
  console.log(areaIds)
  // Use o método filter() para filtrar os elementos de listAreas com IDs presentes em areaIds
  const listAreasHarvest = listAreas.filter((area) => areaIds.includes(area.id))
  //console.log(listAreasHarvest);

  //*PLANEJAMENTO
  // *preciso selecionar a lista de planejamento e filtrat por cultura(HARVEST)
  // instancia os repos do planejamento
  // instancia os repos planning se já estiver algum planejamento
  const reposTargetPlanning = useHarvestTargetReposStore((state) => state)
  //console.log("reposTargetPlanning");
  //console.log(reposTargetPlanning);
  // listas de todas as metas do harvest (safra)
  //console.log(reposTargetPlanning.listPlanning)
  const listTargetHarvestCulture = reposTargetPlanning.listPlanning?.filter((item) => item.id_harvest == usestore.reposHarvest.harvestRepos.id)
  // const listTargetHarvestCulture = listPlanning?.filter(item => item.id_harvest == usestore.reposHarvest.harvestRepos.id)
  //console.log("listTargetHarvestCulture1");
  //console.log(listTargetHarvestCulture)
  //console.log(listPlanning)

  //console.log("custo");
  ///CUSTO DE PRODUÇÃO
  //planejamento dos custos de produção
  // venda = lucro - custos
  const reposProductionCostHarvest = useHarvestTargetCostReposStore((state) => state)
  console.log('reposProductionCostHarvest#')
  console.log(reposProductionCostHarvest.listHarvestTargetCost)
  //console.log(area)
  //filtando a lista dos custos de produção por safra
  const listCostHarvest = reposProductionCostHarvest.listHarvestTargetCost.filter((item) => item.id_harvest == usestore.reposHarvest.harvestRepos.id)
  //const result = reposProductionCostFilterHarvest !== undefined ? reposProductionCostFilterHarvest : "Item não encontrado";
  console.log('custo')
  console.log(listCostHarvest)
  console.log('listAreasHarvest1')
  console.log(listAreasHarvest)

  // *Em cada área adiciona os planejamentos correspondentes (target)
  const arrayAreas = []
  for (const area of listAreasHarvest) {
    let target = []
    if (listTargetHarvestCulture.length > 0) {
      //console.log(area.id)
      //console.log(listTargetHarvest)
      target = listTargetHarvestCulture?.filter((item) => item.id_areamap == area.id)
    }
    console.log('target4')
    console.log(target)

    console.log(listCostHarvest)
    let dataCost = []
    if (listCostHarvest.length > 0) {
      console.log('aqui#1')
      console.log(area.id)

      console.log(listCostHarvest?.id_areamap == area.id)
      dataCost = listCostHarvest?.filter((item) => item?.id_target == target[0]?.id)
    }
    //console.log("----")
    //console.log(target)
    //console.log(dataCost)
    const item = { id: area.id, name: area.name, active: true, title: area.name, measure: area.measure, area: area.area, image: area.imageDecode, target: target, dataCost: dataCost, data: area }
    console.log()
    arrayAreas.push(item)
  }

  // Áreas
  const [planningTargetHarvestCulture, setPlanningTargetHarvestCulture] = useState(arrayAreas)

  useEffect(() => {
    setPlanningTargetHarvestCulture(arrayAreas)
  }, [aux_add])

  //dados: id da área, dados da área, title, name, image, lista de metas(target), lista de custos(dataCost)
  console.log('Planejamento harvest com harvest(harvest+infoáreas), data(respostarget), datacost(respostargetCost) ')
  console.log(planningTargetHarvestCulture)

  return (
    <>
      <Box sx={{ width: '100%', mt: 1 }}>
        {/* {listProductionHarvest.length == 0 && <TableProductionCardEmpty title={"Safra"} />} */}
        {planningTargetHarvestCulture.length > 0 && (
          <Card sx={{ mt: 1 }}>
            <CardHeader
              title={
                <>
                  <Grid container spacing={2} sx={{ mb: 1 }}>
                    <Grid item xs={12} md={8}>
                      <Box sx={{ display: 'flex' }}>
                        {/* <BarChartIcon style={{ fontSize: 35 }} /> */}
                        <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                          <Typography fontWeight="500" variant="h6">
                            {/* Produção: {nameAtivity?.tipo_repos == "Criações" ? "Criação" : "Colheita"} */}
                            Gerenciamento do processo de produção
                            <Typography fontWeight="500" variant="subtitle1" style={{ color: '#2e7d32' }}>
                              “O que pode ser medido, pode ser melhorado”, Peter Drucker.
                            </Typography>
                            {/* <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                              Defina a meta de produção (quantidade a produzir, tempo de produção e custo de produção) para cada área.
                            </Typography> */}
                            <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                              A importância das metas é fornecer orientação para as decisões do presente, impulsionando melhorias contínuas na produção.
                            </Typography>
                            <Typography fontWeight="500" variant="subtitle1" style={{ color: '#808080' }}>
                              Neste momento temos {planningTargetHarvestCulture.length} área(s) para definir a meta e avaliar o processo de produção.
                            </Typography>
                          </Typography>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box display="flex" justifyContent="flex-start">
                        <ButtonRegisterProductionTargetHarvest areas={planningTargetHarvestCulture} />
                      </Box>
                    </Grid>
                  </Grid>
                </>
              }
            ></CardHeader>
            <Divider />
          </Card>
        )}
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
          <Tabs value={value} onChange={handleChange} textColor="success" TabIndicatorProps={{ style: { background: 'green' } }} aria-label="basic tabs example">
            {planningTargetHarvestCulture.map((area, index) => (
              <Tab
                key={area.id}
                label={
                  <Grid container direction="row" alignItems="center">
                    <ImageSearchIcon sx={{ mr: 0.5, mb: 0.2 }} />
                    {area.name}
                  </Grid>
                }
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>
        {planningTargetHarvestCulture.map((area, index) => (
          <TabPanel key={area.id} value={value} index={index}>
            {/* <CardRegisterProductionTargetHarvest area={area} index={index} /> */}
            <CardProductionharvestTabsAreaProduction area={area} index={index} />
          </TabPanel>
        ))}
      </Box>
    </>
  )
}

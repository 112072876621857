import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import ListSubheader from '@mui/material/ListSubheader';
import ImageListItem from '@mui/material/ImageListItem';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import dayjs from 'dayjs';
import Loading from '../../../components/Loader/Loading';
import { Dialog } from '@mui/material';

const formatUploadTime = (date) => dayjs(date).format('HH:mm:ss');
const formatSize = (size) => `${(size / 1024).toFixed(2)} KB`;

// Componente TabPanel para exibir o conteúdo das abas
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// Componente principal DynamicTabs
const DynamicTabs = ({ data, isLoading, isLoadingImage, display_demo, handleDelete }) => {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClickOpen = (item) => {
    setSelectedImage(item);
    setOpen(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  const folders = [
    ...new Set(
      Object.values(data)
        .flat()
        .map((item) => item.folder)
    ),
  ];

  const groupedData = folders.reduce((acc, folder) => {
    acc[folder] = Object.keys(data).reduce((subAcc, month) => {
      subAcc[month] = data[month].filter((item) => item.folder === folder);
      return subAcc;
    }, {});
    return acc;
  }, {});
  // console.log('dataq');
  // console.log(data);
  // console.log(folders);

  const translateFolderName = (folderName) => {
    switch (folderName) {
      case 'property':
        return 'Propriedade';
      case 'product':
        return 'Produto';
      case 'harvest':
        return 'Safra';
      case 'livestock':
        return 'Criações';
      case 'albumProperty':
        return 'Álbum da Propriedade';
      case '':
        return 'Outros';
      case 'album':
        return 'Álbum';

      default:
        return folderName;
    }
  };
  const translatedFolders = folders.map(translateFolderName);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          textColor="success"
          TabIndicatorProps={{ style: { background: 'green' } }}
          onChange={handleChange}
          aria-label="dynamic tabs example"
          sx={{
            '.Mui-selected': {
              color: '#2e7d32 !important',
              fontWeight: 'bold',
            },
          }}
        >
          {translatedFolders.map((folder, index) => (
            <Tab label={folder} key={folder} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
      {folders.map((folder, index) => (
        <TabPanel value={value} index={index} key={folder}>
          {isLoading === false ? (
            <Loading />
          ) : (
            Object.keys(groupedData[folder]).map((month) => (
              <div key={month}>
                <ImageListItem key="Subheader" cols={2}>
                  <ListSubheader component="div">{month}</ListSubheader>
                </ImageListItem>
                <Grid container spacing={3}>
                  {groupedData[folder][month].map((item) => (
                    <Grid item xs={12} sm={6} md={3} key={item.image}>
                      {isLoadingImage ? (
                        <Card>
                          <CardHeader
                            title={
                              <Typography variant="subtitle1" component="div">
                                <Skeleton variant="text" />
                              </Typography>
                            }
                            subheader={
                              <Typography variant="subtitle1" component="div">
                                <Skeleton variant="text" />
                              </Typography>
                            }
                          />
                          <Skeleton variant="rect" width="100%" height={250} />
                          <CardContent>
                            <Typography variant="body2" color="textSecondary">
                              <Skeleton variant="text" />
                            </Typography>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: 'background.default' }}>
                              {display_demo ? (
                                <div></div>
                              ) : (
                                <IconButton aria-label="delete" onClick={() => handleDelete(item.image)}>
                                  <DeleteSweepIcon />
                                  <Typography sx={{ mt: 1 }} gutterBottom variant="subtitle1" component="div">
                                    Lixeira
                                  </Typography>
                                </IconButton>
                              )}
                            </Stack>
                          </CardContent>
                        </Card>
                      ) : (
                        <Card>
                          <CardHeader
                            title={
                              <Typography variant="subtitle1" style={{ color: '#2e7d32' }} component="div">
                                {item.title.replace(/\.[^/.]+$/, '')}
                              </Typography>
                            }
                            subheader={
                              <Typography variant="caption" style={{ color: '#808080' }} component="div">
                                {dayjs(item.date).format('DD/MM/YYYY')} {formatUploadTime(item.date)}
                              </Typography>
                            }
                          />
                          <ImageListItem>
                            <CardMedia component="img" width="100%" height="250" image={item.img} alt={item.title} onClick={() => handleClickOpen(item.img)} style={{ cursor: 'pointer' }} />
                            <CardContent>
                              <Typography variant="body2" color="textSecondary">
                                Tamanho: {formatSize(item.size)}
                              </Typography>
                            </CardContent>
                          </ImageListItem>
                          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: 'background.default' }}>
                            {display_demo ? (
                              <div></div>
                            ) : (
                              <IconButton aria-label="delete" onClick={() => handleDelete(item.image)}>
                                <DeleteSweepIcon />
                                <Typography sx={{ mt: 1 }} gutterBottom variant="subtitle1" component="div">
                                  Lixeira
                                </Typography>
                              </IconButton>
                            )}
                          </Stack>
                        </Card>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </div>
            ))
          )}
        </TabPanel>
      ))}
      <Dialog open={open} onClose={handleClose}>
        <CardMedia component="img" image={selectedImage} alt="" />
      </Dialog>
    </Box>
  );
};

export default DynamicTabs;


import {
    Box,
    Button,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import FormattedInputs from "../../maps/components/Form/FormattedInputs";
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';

export default function FromProfile() {


    // errors.chave define true (property.chave não validada) ou false
    const [errors, setErrors] = useState({});
    // gaurdar o array de erros de cada chave
    const [formError, setFormError] = useState({});


    const [tel, setTel] = useState("");

    const [profile, setProfile] = useState({
        id_user: "1",
        firstName: "",
        LastName: "",
        email: "",
        celular: "310000000",
        cpf: "",
        cep: "",
        state: "",
        city: "",
        bairro: "",
        address: "",
        number: "",
        complemento: "descrições",
    });

    const onSubmit = async (e) => {

    };




    // // input do formulário
    const handleChange = (event) => {
        event.persist();
        setProfile({ ...profile, [event.target.name]: event.target.value });
    };


    useEffect(() => {
        setProfile({ ...profile, tel: tel.textmask });
    }, [tel]);



    return (
        <>
            <Box onSubmit={(e) => onSubmit(e)}>
                <Grid container spacing={3} sx={{ mb: 3 }}>
                    <PermContactCalendarIcon />
                    <Box sx={{ ml: 1, display: 'flex' }}>
                        <Stack spacing={0.5}>
                            <Typography fontWeight={700}>Informações pessoais</Typography>
                        </Stack>
                    </Box>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            //InputLabelProps={{ style: { fontSize: 12 } }}
                            InputLabelProps={{ style: { color: "#2e7d32" } }}
                            id="firstName"
                            name="firstName"
                            label="Nome"
                            fullWidth
                            required
                            autoComplete="shipping property-line1"
                            variant="standard"
                            color="success"
                            value={profile.firstName}
                            onChange={handleChange}

                            helperText="Nome"
                        // {...(errors[property.name] && { error: true, helperText: errors[property.name] })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            //InputLabelProps={{ style: { fontSize: 12 } }}
                            required
                            InputLabelProps={{ style: { color: "#2e7d32" } }}
                            id="LastName"
                            name="LastName"
                            label="Sobrenome"
                            fullWidth
                            autoComplete="shipping property-line1"
                            variant="standard"
                            color="success"
                            value={profile.LastName}
                            onChange={handleChange}
                            helperText="Sobrenome"
                        // {...(errors[property.name] && { error: true, helperText: errors[property.name] })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormattedInputs setTel={setTel} tel={profile.tel} />
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={3} sx={{ mt: 3, mb: 3 }}>
                    <LocalPostOfficeIcon />
                    <Box sx={{ ml: 1, display: 'flex' }}>
                        <Stack spacing={0.5}>
                            <Typography fontWeight={700}>Complementares</Typography>
                        </Stack>
                    </Box>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            //InputLabelProps={{ style: { fontSize: 12 } }}
                            InputLabelProps={{ style: { color: "#2e7d32" } }}
                            id="address"
                            name="address"
                            label="Endereço"
                            fullWidth
                            required
                            autoComplete="shipping property-line1"
                            variant="standard"
                            color="success"
                            value={profile.address}
                            onChange={handleChange}
                            helperText="Endereço"
                        // {...(errors[property.name] && { error: true, helperText: errors[property.name] })}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} sx={{ mt: 3 }}>
                    <Grid item xs={12} sm={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}
                        >
                            <Button autoFocus onClick={onSubmit} variant="contained" color="success">
                                Salvar
                            </Button>
                        </Box>

                    </Grid>
                </Grid>

            </Box>
        </>


    );
}

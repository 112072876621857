import * as React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Adjust } from '@mui/icons-material';

export default function AutocompleteGeneric({ value, options, handleChange }) {
  // console.log(value, options);

  const opt_default = {
    multiple: options.multiple || false,
    name: options.name || 'generic',
    label: options.label || 'Select',
    values: options.values || [],
    helperText: options.helperText || 'Selecione algo',
    field: options.field || 'generic',
    variant: options.variant || 'outlined',
    listHeight: options.listHeight || 'auto',
    disableCloseOnSelect: options.disableCloseOnSelect || false,
    disabled: options.disabled || false,
    disableClearable: options.disableClearable || false,
  };

  // Definir valor padrão para o Autocomplete
  const defaultValue = opt_default.multiple
    ? Array.isArray(value)
      ? value
      : [] // Se múltiplos, usa array vazio como padrão
    : value !== undefined
    ? value
    : null; // Se não múltiplos, usa null

  // Verificar se options.values é um array
  const validOptions = Array.isArray(opt_default.values) ? opt_default.values : [];

  return (
    <Autocomplete
      onChange={(event, newValue, reason, details) => {
        if (newValue === null && opt_default.disableClearable) {
          // Caso não permita limpar o valor, retorna
          return;
        }
        // Adicionar verificação para o caso onde details pode ser null
        handleChange(options.field, newValue, reason, details?.option || null);
      }}
      disableCloseOnSelect={opt_default.disableCloseOnSelect}
      disableClearable={opt_default.disableClearable}
      disabled={opt_default.disabled}
      filterSelectedOptions
      value={defaultValue}
      id={'autocomplete_' + options.name}
      multiple={opt_default.multiple}
      options={validOptions}
      ListboxProps={{ style: { maxHeight: opt_default.listHeight } }}
      isOptionEqualToValue={(option, val) => {
        return option.id === val.id && option.name === val.name
      }}
      getOptionLabel={(option) => {
        const label = option.name || option.nome || 'No Label'; // Garantir retorno de string
        if (label === 'No Label') {
          console.warn('Option without valid label:', option);
        }
        return label;
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {option.icon ? <span style={{ marginRight: '8px' }}>{option.icon}</span> : <Adjust color="success" sx={{ mr: 1, width: '15px' }} />}
          {option.name || option.nome || 'No Label'}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} variant={opt_default.variant} label={opt_default.label} color="success" InputLabelProps={{ style: { color: '#2e7d32' } }} helperText={opt_default.helperText} focused />
      )}
      noOptionsText="Sem resultados"  // Texto para exibição quando não houver opções válidas
    />
  );
}

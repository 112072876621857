import React, { useContext, useState } from 'react';
import { Box, Card, CardHeader, Divider, Grid, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import useStore from '../../store/useStore';
import { Context } from '../../components/Context/AuthContext';
import FinancialAccounts from './components/FinancialAccounts';
import Extract from './components/Extract';
import CashFlow from './components/CashFlow';
import FinancialBillsPayReceive from './components/FinancialBillsPayReceive';
import RegisterNewReleaseDialog from './components/outhers/RegisterNewReleaseDialog';
import { ArrowBackIosNew } from '@mui/icons-material';
import Typewriter from '../../components/Typewriter/Typewriter';

const tabs = [
  { index: 0, id: 'principal', label: 'Principal', type: '' },
  { index: 1, id: 'contas_receber', label: 'Valor a Receber', type: 'income' },
  { index: 2, id: 'contas_pagar', label: 'Contas a Pagar', type: 'expense' },
  { index: 3, id: 'extratos', label: 'Extratos', type: '' },
  { index: 4, id: 'fluxo_caixa', label: 'Fluxo de Caixa', type: '' },
];

function formatValue(value, str) {
  var aux_value = value;
  if (typeof value === 'string') {
    aux_value = parseFloat(value);
  } else if (!value) {
    aux_value = 0;
  }
  var aux_val = aux_value.toFixed(2);
  var aux_val_f1 = aux_val.replace('.', ',');

  return (!str ? 'R$ ' : '') + aux_val_f1;
}

function formatLocaleDate(data) {
  const dataFormatada = data.toLocaleDateString('default', {
    month: 'short',
    year: 'numeric',
  });
  return dataFormatada;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={tabs[index].id} aria-labelledby={tabs[index].label} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(tab) {
  return {
    id: tab.id,
    'aria-controls': tab.label,
  };
}

export default function FinancialContentTabs() {
  const { aux_add, setAuxAdd, isMobile } = useContext(Context);
  const usestore = useStore();

  const [isLoadingText, setIsLoadingText] = useState(false);

  setTimeout(() => {
    setIsLoadingText(true);
  }, 500);

  const [tab_active, setTabActive] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setTabActive(newValue);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleMonth = (tipo) => {
    let date = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1 * (tipo === 'next' ? 1 : -1));

    if (selectedDate.getFullYear() !== date.getFullYear()) {
      usestore.reposFinances.fetch({ ano: date.getFullYear() });
      usestore.reposBill.fetch({ ano: date.getFullYear() });
    }

    setSelectedDate(date);
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [buttonType, setButtonType] = useState('');
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const obj_fn = {
    usestore: usestore,
    ismobile: isMobile.any(),
    selectedDate: selectedDate,
    aux_add: aux_add,
    setAuxAdd: setAuxAdd,
    formatLocaleDate: formatLocaleDate,
    formatValue: formatValue,
    buttonType: buttonType,
  };

  const getHeader = (tipo) => {
    return (
      <Grid spacing={2} sx={{ mt: 0 }}>
        <Card sx={{ mt: 1 }}>
          <CardHeader
            title={
              <>
                <Grid container spacing={2} sx={{ mb: 1 }}>
                  <Grid item xs={12} md={12}>
                    <Box sx={{ display: 'flex' }}>
                      {/* <BarChartIcon style={{ fontSize: 35 }} /> */}
                      <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                        {/* <Typography fontWeight="500" variant="subtitle1" style={{ color: '#2e7d32' }}>
                          <>
                            <strong>Cresça seu negócio controlando tudo que entra e sai do estoque</strong>
                          </>
                        </Typography> */}
                        <div className="d-lg-none">
                          <Box sx={{ minHeight: 80 }}>
                            <Typography style={{ color: '#2e7d32' }}>
                              {isLoadingText && <Typewriter tam={30} minHeight={0} text="Para adicionar um lançamento (despesa ou venda), você pode clicar no botão NOVO LANÇAMENTO logo abaixo." />}
                            </Typography>
                          </Box>
                        </div>
                        <div className="d-none d-lg-block">
                          <Box sx={{ minHeight: 30 }}>
                            <Typography style={{ color: '#2e7d32' }}>
                              {isLoadingText && (
                                <Typewriter
                                  tam={30}
                                  minHeight={0}
                                  text="Para adicionar um lançamento financeiro (valor a receber, contas a pagar ...), você pode clicar no botão NOVO LANÇAMENTO logo abaixo."
                                />
                              )}
                            </Typography>
                          </Box>
                        </div>
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          <>
                            <strong>Contas a pagar: </strong>
                            <span>...</span>
                          </>
                        </Typography>
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          <>
                            <strong>Valor a receber: </strong>
                            <span>...</span>
                          </>
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </>
            }
          ></CardHeader>
          <Divider />
        </Card>
        <Grid item sx={{ ml: 3, mt: 1 }}>
          <RegisterNewReleaseDialog type={tabs[tab_active].type} setButtonType={setButtonType} openDialog={openDialog} handleCloseDialog={handleCloseDialog} />
        </Grid>
        <Grid item xs={12} md={6} lg={8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <IconButton titile="Mês Anterior" aria-label="Mês Anterior" onClick={() => handleMonth('previous')}>
            <ArrowBackIosNew color="success" sx={{ fontSize: 30 }} />
          </IconButton>
          <Box sx={{ width: 150 }}>
            <Typography variant="h6">{formatLocaleDate(selectedDate)}</Typography>
          </Box>
          <IconButton titile="Próximo mês" aria-label="Próximo mês" onClick={() => handleMonth('next')}>
            <ArrowBackIosNew color="success" sx={{ transform: 'rotate(180deg)', fontSize: 30 }} />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          {getHeader()}
          <Tabs textColor="success" TabIndicatorProps={{ style: { background: 'green' } }} variant="scrollable" value={tab_active} onChange={handleChangeTab} aria-label="Painéis financeiros">
            <Tab label={tabs[0].label} {...a11yProps(tabs[0])} />
            <Tab label={tabs[1].label} {...a11yProps(tabs[1])} />
            <Tab label={tabs[2].label} {...a11yProps(tabs[2])} />
            <Tab label={tabs[3].label} {...a11yProps(tabs[3])} />
            <Tab label={tabs[4].label} {...a11yProps(tabs[4])} />
          </Tabs>
        </Box>
        <TabPanel value={tab_active} index={0}>
          <FinancialAccounts obj_fn={obj_fn} />
        </TabPanel>
        <TabPanel value={tab_active} index={1}>
          <FinancialBillsPayReceive type={'income'} obj_fn={obj_fn} />
        </TabPanel>
        <TabPanel value={tab_active} index={2}>
          <FinancialBillsPayReceive type={'expense'} obj_fn={obj_fn} />
        </TabPanel>
        <TabPanel value={tab_active} index={3}>
          <Extract obj_fn={obj_fn} />
        </TabPanel>
        <TabPanel value={tab_active} index={4}>
          <CashFlow obj_fn={obj_fn} />
        </TabPanel>
      </Box>
    </>
  );
}

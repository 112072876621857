import * as React from 'react'
import Box from '@mui/material/Box'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Container,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import Typewriter from '../../../components/Typewriter/Typewriter'
import { useState } from 'react'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import GanttChart from '../../../components/ChartsGoogle/GanttChart'
import { format, parseISO } from 'date-fns'
import { ExpandMore } from '@mui/icons-material'
import ImageIcon from '@mui/icons-material/Image'
import { grey } from '@mui/material/colors'

export default function CardComponentArea({ area, index }) {
  function formatarData(dataString) {
    // Converter a string da data em um objeto de data
    const dataObjeto = parseISO(dataString)

    // Formatar a data no formato desejado 'dd/MM/yyyy'
    const dataFormatada = format(dataObjeto, 'dd/MM/yyyy')

    return dataFormatada
  }

  const [imagemVisivel, setImagemVisivel] = useState(false)

  const toggleImagemVisivel = () => {
    setImagemVisivel(!imagemVisivel)
  }

  const handleClose = () => {
    setImagemVisivel(false)
  }

  return (
    <>
      {area && (
        <div key={index}>
          {area.active && (
            <div>
              <Card sx={{ mt: -1, mb: 1 }}>
                <Grid container spacing={2} sx={{ mb: 0, mt: 0 }}>
                  <Grid item xs={12} md={12}>
                    <CardComponent key={index} area={area} imageUrl={area.image} />
                  </Grid>
                </Grid>
              </Card>
            </div>
          )}
        </div>
      )}
    </>
  )
}

function CardComponent({ area, imageUrl }) {
  const [imagemVisivel, setImagemVisivel] = useState(false)

  const toggleImagemVisivel = () => {
    setImagemVisivel(!imagemVisivel)
  }

  const handleClose = () => {
    setImagemVisivel(false)
  }

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12} md={8}>
          <Box sx={{ display: 'flex' }}>
            {/* <BarChartIcon style={{ fontSize: 35 }} /> */}
            <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
              <Typography fontWeight="500" variant="subtitle1" style={{ color: '#2e7d32' }}>
                Para a {area.title}, defina uma meta de produção e acompanhe o seu processo de produção.
              </Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Card sx={{ border: '1px solid grey', alignItems: 'center' }}>
        <CardHeader
          avatar={<FmdGoodIcon style={{ height: '50px', width: '50px', color: grey[500] }} />}
          action={
            <IconButton aria-label="settings" onClick={toggleImagemVisivel}>
              <ImageIcon />
            </IconButton>
          }
          title={area.title}
          subheader={
            <>
              <Stack spacing={0.5}>
                <Typography variant="body2" color="text.secondary">
                  {area.area}
                </Typography>
              </Stack>
            </>
          }
        />
      </Card>
      {/* Renderize o componente ModalImage com base no estado imagemVisivel */}
      <ModalImage open={imagemVisivel} handleClose={handleClose} imageUrl={imageUrl} />
    </>
  )
}

function ModalImage({ open, handleClose, imageUrl }) {
  //console.log("/")
  //console.log(imageUrl)
  return (
    <Dialog open={open} onClose={handleClose}>
      <CardMedia
        component="img"
        height="400" // Altura desejada para o modal
        image={imageUrl}
        alt="Imagem em pop-up"
        style={{ maxWidth: '100%' }}
      />
    </Dialog>
  )
}

//https://aegro.com.br/termos-de-uso/

import React from "react";
import Nav from "../../components/Nav/Nav";
import NavSide from "../../components/Nav/NavSide";
import NavBotton from "../../components/Nav/NavBotton";
import IndicatorsContent from "./IndicatorsContent";
import Footer from "../../components/Footer/Footer";

export default function Indicators() {
  return (
    <>
      <NavSide />
      {/* <Nav /> */}
      <NavBotton />
      <IndicatorsContent />
      <Footer />
    </>
  );
}

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { NavLink } from 'react-router-dom';
import ChartBarP from '../../../../components/Charts/ChartBarP';
import { Box, Chip, Divider, FormControl, Input, InputAdornment, InputLabel, Paper, Stack, Switch } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import SignpostIcon from '@mui/icons-material/Signpost';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import useHarvestReposStore from '../../../../store/useHarvestReposStore';
import { calcularDateProgressBarra } from '../../../../components/Calculations/calcularDateProgressBarra';
import useLivestockTargetReposStore from '../../../../store/useLivestockTargetReposStore';
import useStore from '../../../../store/useStore';
import { useContext } from 'react';
import { Context } from '../../../../components/Context/AuthContext';
import TimelineHorizPhaseLivestock from '../GraphPlan/TimelineHorizPhaseLivestock';
import PlanningLivestockCost from '../CostProductionLivestock/PlanningLivestockCost';
import FormGoalButtonTargetLivestock from '../formGoalTargetLivestock/FormGoalButtonTargetLivestock';
import FormGoalButtonTargetEditLivestock from '../formGoalTargetLivestock/FormGoalButtonTargetEditLivestock';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import usePlanLivestockReposStore from '../../../../store/usePlanLivestockReposStore';
import FormGoalButtonPlanLivestockCreation from './FormGoalButtonPlanLivestockCreation';
import FormGoalButtonPlanLivestockCut from './FormGoalButtonPlanLivestockCut';
import { useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../../../services/api';
import DeleteModalPlanLivestock from './DeleteModalPlanLivestock';
import Typewriter from '../../../../components/Typewriter/Typewriter';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export default function CardMeatProductionOther({ type }) {
  var property_id = localStorage.getItem('Property') || 1;
  const usestore = useStore();

  const livestockRepos = usestore.reposLivestock.livestockRepos;
  const livestockActive = usestore.reposLivestock.getLivestockStore('id', livestockRepos.id)[0];
  console.log('livestockActive1');
  console.log(livestockActive);

  const { idFarm, id } = useParams();

  const typeFaseLoteList = [];
  for (const lote of livestockActive.lotes) {
    typeFaseLoteList.push(lote.fase_name);
  }

  //console.log("typeFaseLoteList")
  //console.log(typeFaseLoteList)
  //console.log(typeFaseLoteList[0])

  const [dataLivestock, setDataLivestock] = React.useState(livestockActive);

  // instancia os repos planning
  const reposPlanning = useLivestockTargetReposStore((state) => state);
  //console.log("reposPlanning1");
  //console.log(reposPlanning);
  for (const iterator of reposPlanning.listPlanning) {
    //console.log(iterator);
  }
  let planRepos = [];
  //console.log("map");
  const reposResult = reposPlanning.listPlanning.filter((itemPlan) => itemPlan.typePlanning === 'Outra' && itemPlan.id_livestock == id);
  console.log('reposPlanning-reposResult');
  console.log(reposResult);
  if (reposResult) {
    planRepos = reposResult;
  } else {
    planRepos = [];
  }
  //console.log(reposResult);
  //console.log("planoCorte");
  //console.log(planRepos);
  //console.log(planRepos[0]);

  const date_start = planRepos[0]?.date_start;
  const date_prev_end = planRepos[0]?.date_prev_end;
  //console.log("data");
  //console.log(date_start);
  //console.log(date_prev_end);
  // Converter as datas para objetos Date
  const newdatePrevEnd = new Date(date_prev_end);
  //console.log(newdatePrevEnd);
  const newdateStart = new Date(date_start);
  // Calcular a diferença em milissegundos
  const diffInMilliseconds = newdatePrevEnd - newdateStart;
  // Converter a diferença em dias
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  //console.log("diffInDays");
  //console.log(diffInDays);

  // Obter a data formatada
  const formattedDatePrevEnd = newdatePrevEnd.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  console.log(formattedDatePrevEnd);

  // Obter a data formatada
  const formattedDateStart = newdateStart.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  // console.log(formattedDateStart);
  const valueProgress = calcularDateProgressBarra(formattedDateStart, formattedDatePrevEnd);
  //console.log(valueProgress.porcentagem);
  //const valueProductivity = planRepos[0]?.expectedYield / planRepos[0]?.area;
  //GMD = peso atual - peso anterior / número do intervalo da pesagem.
  const dateDiff = valueProgress.totalDias;
  //console.log("dateDiff");
  // console.log(valueProgress.totalDias);
  //const valueProductivity = (planRepos[0]?.expectedYield - planRepos[0]?.animalInput) / diffInDays;

  const [production, setProduction] = useState(planRepos[0]?.expectedYield || 0);
  const [timeUnit, setTimeUnit] = useState(planRepos[0]?.unitTime); // 'semana', '15 dias', 'mês', 'ano'
  const [productivity, setProductivity] = useState(0);

  console.log(production);

  const calculateProductivity = () => {
    let days;
    switch (timeUnit) {
      case 'dia':
        days = 1;
        break;
      case 'semana':
        days = 7;
        break;
      case '15 dias':
        days = 15;
        break;
      case 'mês':
        days = 30;
        break;
      case 'ano':
        days = 365;
        break;
      default:
        days = 1;
    }

    const dailyProductivity = production / days;
    console.log('dailyProductivity');
    console.log(dailyProductivity);
    setProductivity(dailyProductivity.toFixed(2));
  };

  useEffect(() => {
    calculateProductivity();
  }, []);

  console.log('productivity');
  console.log(productivity);

  const valueProductivity = productivity;

  console.log('productivity');
  console.log(productivity);

  const [plan, setPlan] = React.useState(planRepos[0]);
  //const [productivity, setProductivity] = React.useState(valueProductivity.toFixed(2));
  // console.log("plan1");
  //console.log(plan);
  //console.log(plan === undefined);
  //console.log(valueProductivity);

  // useEffect(() => {
  //   setProductivity(valueProductivity);
  // }, [plan]);

  const [value, setValue] = React.useState(0);

  const reposPlanLivestock = usePlanLivestockReposStore((state) => state);
  //console.log("reposPlanLivestock##");
  //console.log(reposPlanLivestock);

  //console.log(reposPlanLivestock.listPlanLivestock);
  //console.log(reposPlanLivestock.planningRepos);
  //console.log(reposPlanLivestock.planningRepos.id);
  const planLivestockRepos = reposPlanLivestock.getPlanningStore('id', reposPlanLivestock.planningRepos.id)[0];
  //console.log(planLivestockRepos);

  const [values, setValues] = React.useState(planLivestockRepos);

  // const [values, setValues] = React.useState({
  //   amount: " 1100.00",
  //   percentage: "",
  //   cost: "",
  // });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  //console.log("plan");
  //console.log(plan);

  const { aux_add, setAuxAdd } = useContext(Context);

  useEffect(() => {
    //console.log("refresh ");
    setPlan(planRepos[0]);
  }, [aux_add]);

  // updateIsActiveTargetLivestock
  const [isActive, setIsActive] = React.useState(planRepos[0]?.isActiveTargetLivestock || false);

  const handleSwitchChange = async (event) => {
    // console.log('handleSwitchChange');
    const newState = event.target.checked;
    setIsActive(newState);

    const header = { header: { 'Content-Type': 'application/json' } };
    try {
      const data = { isActiveTargetLivestock: newState, id: plan.id, id_livestock: plan.id_livestock, id_property: plan.id_property };
      // console.log(data);
      // console.log(data);
      const res = await api
        .put('/planning/updateIsActiveTargetLivestock', data, header)
        .then((response) => {
          // console.log(response.data);

          if (response.data.isSuccess) {
            toast.success('Meta atualizada com sucesso!');
            reposPlanning.fetch({ id_farm: idFarm }).then((res) => {
              setTimeout(() => {
                setAuxAdd(aux_add + 1);
              }, 200);
            });
            // setTimeout(() => {
            //   setIsLoading(true);
            // }, 500);
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Error ao atualizar os dados');
        });
      // if (!response.ok) {
      //   throw new Error("Erro ao enviar dados");
      // }
      // Processamento adicional em caso de sucesso, se necessário
    } catch (error) {
      console.error('Erro ao atualizar estado:', error);
      toast.error('Error ao atualizar os dados');
      // Lógica para lidar com erro
    }
  };

  return (
    <Box className="">
      <Box className="content-header" style={{ marginTop: 0 }}>
        <Box className="">
          {/* <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12} md={8}>
              <Box sx={{ display: "flex" }}>
                <SignpostIcon style={{ fontSize: 35 }} />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Planejamento da Criação: {type}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid> */}

          {/* <Grid container spacing={2} sx={{ mb: 0 }}>
            <Grid item xs={12} md={8}>
              <Box sx={{ display: "flex" }}>
             
                <Stack spacing={0.5} sx={{ ml: 5.5, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Projeto:
                  </Typography>
                  <Box sx={{ flex: "1", flexDirection: "column" }}>
                   
                    <Typography component="h3" variant="h6" sx={{ fontWeight: "bold", color: "success.main", textTransform: "uppercase" }}>
                      {livestockActive.label}
                    </Typography>
                    <Typography component="span">{livestockActive.tipo}</Typography>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid> */}
        </Box>
        {/* <Grid container spacing={2} sx={{ mt: 0 }}>
          <Grid item xs={12} md={12}>
            <Box mt={0}>
              <TimelineHorizPhaseLivestock dataLivestock={dataLivestock} />
              {/* <TimelineHoriz />
            </Box>
          </Grid>
        </Grid> */}
        <Box sx={{ minHeight: 30 }}>
          <Typography style={{ color: '#2e7d32' }}>
            <Typewriter tam={30} minHeight={0} text="Na produção animal, você poderá elaborar metas específicas para diferentes produtos (produção de leite, ovos, mel e outros produtos animais)." />
          </Typography>
        </Box>
        <Grid container spacing={2} sx={{ mt: 0 }}>
          {/* <coluna 1 /> */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2} sx={{ mt: 0 }}>
              {/* meta  */}
              <Grid item xs={12} md={12}>
                <Box mt={2}>
                  <Grid container spacing={2} sx={{}}>
                    <Grid item xs={6} md={6}>
                      <Box sx={{ display: 'flex' }}>
                        <Stack spacing={0.5}>
                          <Typography fontWeight="400" variant="h6">
                            Meta Específica
                          </Typography>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>{plan?.typePlanning == 'Outra' ? <FormGoalButtonTargetEditLivestock type={type} plan={plan} /> : <></>}</Box>
                    </Grid>
                  </Grid>
                  <Box mt={2}>
                    {plan?.typePlanning == 'Outra' ? (
                      <Card>
                        <CardContent sx={{ mt: -1 }}>
                          <Grid container spacing={2} sx={{ mb: 1.1 }}>
                            <Grid item xs={12} sm={12}>
                              <Grid container spacing={1}>
                                <Grid item sx={{ width: 50 }}>
                                  <EmojiEventsIcon sx={{ fontSize: 40, color: '#28a745' }} />
                                  {/* <Img alt="complex" src="/dist/img_fp/icon-button/icon-agricolaV1.png" /> */}
                                </Grid>
                                <Grid item xs>
                                  <Typography variant="caption">Produção Esperada</Typography>
                                  <Typography variant="subtitle2">
                                    <strong>{plan?.expectedYield}</strong>{' '}
                                    <span style={{ fontSize: '0.8em' }}>
                                      {plan?.unit}/{plan?.unitTime}
                                    </span>
                                  </Typography>
                                </Grid>
                                <Grid item xs>
                                  <Typography variant="caption">Produtividade Esperada</Typography>
                                  <Typography variant="subtitle2">
                                    {' '}
                                    <strong>{productivity}</strong> <span style={{ fontSize: '0.8em' }}>{plan?.unit}/por dia</span>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider sx={{ mt: 3, mb: 2 }} />
                          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: 'background.default' }}>
                            {true ? (
                              <>
                                <DeleteModalPlanLivestock planId={plan.id} reposPlanning={reposPlanning} idFarm={idFarm} plan={plan} />
                                {/* <IconButton variant="outlined" color="success" onClick={() => handleDelete(plan.id)}>
                                  <DeleteForeverIcon fontSize="small" sx={{ mr: 0.5, mt: -0.2 }} style={{ color: red[500] }} />
                                </IconButton> */}
                              </>
                            ) : (
                              <></>
                            )}
                            <Chip label={isActive ? 'Meta ativa' : 'inativa'} color={isActive ? 'success' : 'default'} variant="outlined" />
                            <Switch onChange={handleSwitchChange} checked={isActive} />
                          </Stack>
                        </CardContent>
                      </Card>
                    ) : (
                      <Box height="88px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                        <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                          <FormGoalButtonTargetLivestock type={type} />
                        </Box>
                        <span>Clique no ícone para definir a meta</span>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
              {/* Prazo  */}
              {/* <Grid item xs={12} md={12}>
                <Box mt={2}>
                  <Box sx={{ display: "flex" }}>
                    <Stack spacing={0.5}>
                      <Typography fontWeight="400" variant="h6">
                        Prazo
                      </Typography>
                    </Stack>
                  </Box>
                  <Box mt={2} display="flex" justifyContent="center">
                    <Card>
                      <CardContent sx={{ mt: -1 }}>
                        <Stack alignItems="center" direction="row" divider={<Divider />} spacing={1}>
                          <Typography variant="caption" color="text.secondary" component="div">

                            {formattedDateStart == "Invalid Date" ? "xx/xx/xxxx" : formattedDateStart}
                          </Typography>
                          <ChartBarP valueProgress={valueProgress.porcentagem > 0 && valueProgress.porcentagem < 101 ? valueProgress.porcentagem : 0} />
                          <Typography variant="caption" color="text.secondary" component="div">

                            {formattedDatePrevEnd == "Invalid Date" ? "xx/xx/xxxx" : formattedDatePrevEnd}
                          </Typography>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Box>
                </Box>
              </Grid> */}
              {/* fim Prazo  */}
            </Grid>
          </Grid>
          {/* <coluna 2/> custo */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Box mt={2}>
                <Box sx={{ display: 'flex' }}>
                  <Stack spacing={0.5}>
                    <Typography fontWeight="400" variant="h6">
                      Prazo
                    </Typography>
                  </Stack>
                </Box>
                <Box mt={2} display="flex" justifyContent="center">
                  <Card>
                    <CardContent sx={{ mt: -1 }}>
                      <Stack alignItems="center" direction="row" divider={<Divider />} spacing={1}>
                        <Typography variant="caption" color="text.secondary" component="div">
                          {formattedDateStart == 'Invalid Date' ? 'xx/xx/xxxx' : formattedDateStart}
                        </Typography>
                        <ChartBarP valueProgress={valueProgress.porcentagem > 0 && valueProgress.porcentagem < 101 ? valueProgress.porcentagem : 0} />
                        <Typography variant="caption" color="text.secondary" component="div">
                          {formattedDatePrevEnd == 'Invalid Date' ? 'xx/xx/xxxx' : formattedDatePrevEnd}
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Box>
              </Box>

              {/* venda  */}
              {/* <Grid item xs={12} md={6}>
                <Box mt={2} sx={{ mt: 5 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                      <Box sx={{ display: "flex" }}>
                        <Stack spacing={0.5}>
                          <Typography fontWeight="400" variant="h6">
                            Venda Esperada
                          </Typography>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6}>
                     
                    </Grid>
                  </Grid>
                  <Box mt={2}>
                    {values ? (
                      <Card>
                        <CardContent sx={{}}>
                          <Grid container spacing={2} sx={{}}>
                            <Grid item xs={12} sm={6}>
                              <Grid container spacing={1}>
                                <Grid item xs>
                                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                                    <InputLabel sx={{ color: "#28a745" }} color="success" htmlFor="standard-adornment-amount">
                                      Valor esperado para venda
                                    </InputLabel>
                                     <Input
                                      color="success"
                                      sx={{ width: 150, color: "#28a745" }}
                                      value={values.amount}
                                      onChange={handleChange("amount")}
                                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    /> 
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                             <Grid item xs={12} sm={6}>
                            <Grid container spacing={1}>
                              <Grid item xs>
                                <Typography variant="caption" color="text.secondary" component="div">
                                  Lucro esperado
                                </Typography>
                                <Typography variant="subtitle2" component="div">
                                  R$ 1.100,00 /saco 60kg
                                  <Typography variant="caption" sx={{ color: "#28a745" }} color="text.secondary" component="div">
                                    (+10%)
                                  </Typography>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid> 
                          </Grid>
                        </CardContent>
                      </Card>
                    ) : (
                      <Box height="110px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                          <FormGoalButtonPlanLivestockCut />
                        </Box>
                        <span>Estime a venda da criação</span>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid> */}
              {/* Lucro  */}
              {/* <Grid item xs={12} md={6}>
                <Box mt={2} sx={{ mt: 5 }}>
                  <Box sx={{ display: "flex" }}>
                    <Stack spacing={0.5}>
                      <Typography fontWeight="400" variant="h6">
                        Lucro Esperado
                      </Typography>
                    </Stack>
                  </Box>
                  <Box mt={2}>
                    {values ? (
                      <Card>
                        <CardContent sx={{}}>
                          <Grid container spacing={2} sx={{}}>
                            <Grid item xs={12} sm={6}>
                              <Grid container spacing={1}>
                                <Grid item xs>
                                  <Typography variant="caption" color="text.secondary" component="div">
                                    Lucro esperado
                                  </Typography>
                                  <Typography variant="subtitle2" component="div">
                                    {/* O lucro desejado é de 10% sobre a venda de R$ 4000.
                                Lucro = Valor da venda * Percentual de lucro
                                Lucro = R$ 4000 * 0,10 
                                    R$ {values?.profitUnitValue} {values?.unitSale}
                                    <Typography variant="caption" sx={{ color: "#28a745" }} color="text.secondary" component="div">
                                      (+{values?.percentageProfit}%)
                                    </Typography>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Grid container spacing={1}>
                                <Grid item xs>
                                  <Typography variant="caption" color="text.secondary" component="div">
                                    Lucro Total
                                  </Typography>
                                  {values?.totalProfit}
                                  <Typography variant="caption" sx={{ color: "#28a745" }} color="text.secondary" component="div"></Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    ) : (
                      <Box height="100px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}></Box>
                        <span>Estime a venda da criação</span>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid> */}
              {/* fim lucro  */}
            </Grid>
            {/* <Box mt={2}>
              <Box sx={{ display: "flex" }}>
                <Stack spacing={0.5}>
                  <Typography fontWeight="400" variant="h6">
                    Custo de Produção
                  </Typography>
                </Stack>
              </Box>
           
              <Box mt={2}>
                {values ? (
                  <Card>
                    <CardContent sx={{ mb: 1 }}>
                      <Grid container spacing={2} sx={{}}>
                        <Grid item xs={12} sm={6}>
                          <Grid container spacing={1}>
                            <Grid item>
                              <PriceChangeIcon />
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="caption" color="text.secondary" component="div">
                                Custo Esperado
                              </Typography>
                              <Typography variant="body2" component="div">
                                {/* R$ {values.amount - (values.amount * values.percentage) / 100} /ca 
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Grid container spacing={1}>
                            <Grid item xs>
                              <Typography variant="caption" color="text.secondary" component="div">
                                Custo total da produção / produção esperada
                              </Typography>
                              {/* Cálculo do Custo da produção por cabeça:
                                  Custo de produção = (Valor da venda - Lucro) 
                                  Portanto, o Custo da produção para 1 boi é aproximadamente R$ 3600.
                                  Custo da produção seria de R$ 3600 por cabeça. 
                                  Produção esperada = 10
                                  Custo da produção / Produção esperada = R$ 3600 / 10
                                  Custo da produção / Produção esperada = R$ 360
                                  Cálculo do Custo da produção total:
                                  Custo da produção total = Custo da produção por cabeça * Produção esperada
                                  Custo da produção total = R$ 3600 * 10
                                  Custo da produção total = R$ 36000
                                   Custo da produção total seria de R$ 36000.
                                  
                                  
                              <Typography variant="body2" component="div">
                                {/* R$ {(values.amount - (values.amount * values.percentage) / 100) * 10} 
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ) : (
                  <Box height="88px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                    <Box sx={{ display: "flex", flexDirection: "row-reverse" }}></Box>
                    <span>Estime a venda da criação</span>
                  </Box>
                )}
              </Box>
              {/* fim card1  
            </Box> */}
          </Grid>

          {/* <Grid item xs={12} md={8}>
            <Grid container spacing={2} sx={{ mt: 1, }}>
              <PlanningLivestockCost />
            </Grid>
          </Grid> */}
          {/* coluna 2  */}
          {/* <Grid item xs={12} md={4}>
            <Grid container spacing={2} sx={{ mt: 0 }}>


            </Grid>
          </Grid> */}
        </Grid>
        {/* fim  */}
      </Box>
    </Box>
  );
}

import * as React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import { Avatar, CardMedia, IconButton, InputAdornment, ListItemAvatar, ListItemButton, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { useEffect } from 'react'
import AgricultureIcon from '@mui/icons-material/Agriculture'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import FenceIcon from '@mui/icons-material/Fence'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

export default function SalesListStock() {
  // const [image, setImage] = useState("");

  // useEffect(() => {
  //   if (data) {
  //     setImage(data);
  //   }
  // }, [data]);

  const patrimony = [
    { group: 'Silo da Propriedade', type: 'Armazen01', nameType: 'Estoque: milho', identification: 'a' },
    { group: 'Silo da Propriedade', type: 'Armazen02', nameType: 'Estoque: milho', identification: 'a' },
    { group: 'Silo de terceiros', type: 'ArmazenT1', nameType: 'Estoque: milho', identification: 'a' },
    { group: 'Silo de terceiros', type: 'ArmazenT2', nameType: 'Estoque: milho', identification: 'a' },
  ]

  // Agrupar dados incluindo todas as informações relevantes
  const groupedData = Object.entries(
    patrimony.reduce((acc, cur) => {
      if (!acc[cur.group]) {
        acc[cur.group] = [{ group: cur.group, type: cur.type, nameType: cur.nameType }]
      } else {
        acc[cur.group].push({ group: cur.group, type: cur.type, nameType: cur.nameType })
      }
      return acc
    }, {})
  ).map(([group, items]) => ({ group, items }))

  console.log(groupedData)

  // Estado para controlar a visibilidade dos grupos
  const [visibleGroups, setVisibleGroups] = useState({})

  const toggleGroupVisibility = (group) => {
    setVisibleGroups((prevState) => ({
      ...prevState,
      [group]: !prevState[group],
    }))
  }

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: '100%',
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        // maxHeight: 300,
        '& ul': { padding: 0 },
      }}
      subheader={<li />}
    >
      <TextField
        sx={{ width: '100%', maxWidth: '100%' }}
        id="filled-search"
        label="Pesquisar silos"
        type="search"
        variant="filled"
        color="success"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              // style={{ display: showClearIcon }}
              //onClick={handleClick}
            >
              <ClearIcon />
            </InputAdornment>
          ),
        }}
      />
      {groupedData.map((groupData) => (
        <li key={`section-${groupData.group}`}>
          <ul>
            <ListSubheader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {groupData.group}
              <IconButton onClick={() => toggleGroupVisibility(groupData.group)}>{visibleGroups[groupData.group] ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
            </ListSubheader>
            {visibleGroups[groupData.group] &&
              groupData.items.map((item) => (
                <>
                  {/* <ListItem key={`${item}`} secondaryAction={<MenuCardSales />}> */}
                  <ListItem key={`${item.type}`} sx={{ ml: -2 }} secondaryAction={<MoreVertIcon />}>
                    <ListItemButton>
                      <ListItemAvatar>
                        {item.group === 'Veículos' && <AgricultureIcon style={{ height: '32px', width: '32px' }} sx={{ mt: -1 }} />}
                        {item.group === '' && <WarehouseIcon style={{ height: '32px', width: '32px' }} />}
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${item.type}`}
                        secondary={
                          <React.Fragment>
                            <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                              {item.nameType}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                </>
              ))}
          </ul>
        </li>
      ))}
    </List>
  )
}

import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Avatar, Box, Card, CardContent, Divider, Fab, FormControl, FormControlLabel, FormGroup, Grid, Menu, Radio, RadioGroup, Typography } from '@mui/material';
import { CalendarMonth } from '@mui/icons-material';
import isBetween from 'dayjs/plugin/isBetween';
import dayjs from 'dayjs';
import CardChatKanban from '../CardChatKanban';
import LoadActivityListLivestock from '../../LoadActivityList/LoadActivityListLivestock';
import { Context } from '../../Context/AuthContext';
import Loading from '../../Loader/Loading';
import useStore from '../../../store/useStore';
import CharTimeActivityProduction from '../../Charts/CharTimeActivityProduction';
import CardLivestockInfoTimeLine from '../../../pages/activities/components/tab/CardLivestockInfoTimeLine';
import CharTimeMultiActivityProduction from '../../Charts/CharTimeMultiActivityProduction';
import LoadActivityListHarvest from '../../LoadActivityList/LoadActivityListHarvest';
import CardHarvestInfoTimeLine from '../../../pages/activitiesHarvest/components/tab/CardHarvestInfoTimeLine';

export default function BoardTimeLineGraph({ type }) {
  const usestore = useStore();
  let filters = {
    table_active: 'realized',
    status: 'edit',
  };

  const { idFarm, id } = useParams();

  const loadActivityListLivestock = LoadActivityListLivestock();
  const loadActivityListHarvest = LoadActivityListHarvest();
  let rows;
  // type: licestock
  if (type == 'harvest') {
    const currentHarvest = usestore.reposHarvest.getHarvestStore('id', id)[0];
    rows = loadActivityListHarvest.loadList(currentHarvest, filters);
  } else if (type == 'livestock') {
    const currentLivestok = usestore.reposLivestock.getLivestockStore('id', id)[0];
    rows = loadActivityListLivestock.loadList(currentLivestok, filters);
  }

  console.log('rows');
  console.log(rows);

  return (
    <>
      {type == 'livestock' && <CardLivestockInfoTimeLine />}
      {type == 'harvest' && <CardHarvestInfoTimeLine />}
      <Grid container spacing={3} sx={{ mt: 0, mb: 4 }}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Cronograma de atividades de campo
              </Typography>

              {/* <CharTimeActivityProduction rows_lv={rows_lv} /> */}
              {rows.length > 0 && <CharTimeMultiActivityProduction rows={rows} type={type} />}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

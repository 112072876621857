import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";

export default function ChartBarPCost() {
  var state = {
    series: [80, 9, 2, 2, 7],
    options: {
      chart: {
        type: "donut",
      },
      labels: ['Animais', 'Nutrição', 'Medicamentos', 'Exames', 'outros'],
      //colors: ["#28a745", "#409832", "#28a745", "#848028", "#28a745"],
      plotOptions: {
        pie: {
          expandOnclick: false,
          donut: {
            size: "60px",
            labels: {
              show: "true",
              total: {
                show: "true",
                showAlways: "true",
                fontSize: "24px",
                color: "#28a745"
              },
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 400,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (


    <div>
      <ReactApexChart options={state.options} series={state.series} type="donut" />
    </div>


  );
}

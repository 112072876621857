import { useContext, useState } from 'react'
import { Box, Container, Grid, Paper } from '@mui/material'
import { orange } from '@mui/material/colors'
import { toast } from 'react-toastify'
import api from '../../../../../services/api'
import useStore from '../../../../../store/useStore'
import { Context } from '../../../../../components/Context/AuthContext'
import GenericModal from '../../../../../components/Dialog/GenericModal'
import dayjs from 'dayjs'
import 'dayjs/locale/pt'
import FormIndividualWeightStep1 from './FormIndividualWeightStep1'
import FormIndividualWeightStep2 from './FormIndividualWeightStep2'
import SuccessWeight from '../Weight/SuccessWeight'

export default function MultiStepFormIndividualWeight({ handleClose }) {
  // console.log('MultiStepFormIndividualWeight')
  const { aux_add, setAuxAdd } = useContext(Context)
  const usestore = useStore()
  const livestockRepos = usestore.reposLivestock.getLivestockStore('id', usestore.reposLivestock.livestockRepos.id)[0]

  const property_id = localStorage.getItem('Property') || 1
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'))

  const getOptAnimals = (tipo) => {
    let aux_opt = []
    livestockRepos.animais.filter((animal) => {
      if (animal[tipo] !== '') {
        aux_opt.push({ id: animal.id, label: animal[tipo] })
        return { id: animal.id, label: animal[tipo] }
      }
    })
    return aux_opt
  }

  const [data, setData] = useState({
    id_property: property_id,
    id_livestock: livestockRepos.id,
    id_user: userCurrent.id,
    options_identifier: getOptAnimals('identifier'),
    identifier: { id: 0, label: '' },
    options_brinco: getOptAnimals('brinco'),
    brinco: { id: 0, label: '' },
    options_sisbov: getOptAnimals('sisbov'),
    sisbov: { id: 0, label: '' },
    options_name: getOptAnimals('name'),
    name: { id: 0, label: '' },
    name_activity: 'PESAGEM INDIVIDUAL',
    lote: {},
    num_animals: 0,
    num_females: 0,
    num_males: 0,
    new_weight: 0,
    weight_medio: 0,
    weight_total: 0,
    unit: 'kg',
    valor: 0,
    date_start: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    date_prev_end: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    date_end: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    isDeleted: '',
    progress: 'CONCLUÍDA',
    planning: 'realized',
    table_animals: livestockRepos.animais,
    table_selected: {},
    table_confirm: [],
  })

  // controle do dialog dos componentes
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    handleScroll()
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    handleScroll()
  }

  const handleScroll = () => {
    setTimeout(() => {
      window.jQuery(window.jQuery.find('#focus_top_modal')[0]).parent()[0].scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, 300)
  }

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ['FormIndividualWeightStep1', 'FormIndividualWeightStep2', 'SuccessWeight']

  const onSubmit = async () => {
    let formOk = true

    if (data.weight_total === 0) {
      toast.error('Ao menos uma animal deve ter novo peso supoerior a 0!')
      formOk = false
    } else if (!data.table_confirm.length) {
      toast.error('Adione pelo menos um animal à pesagem!')
      formOk = false
    }

    if (formOk) {
      if (data.date_start === data.date_prev_end) {
        data.progress = 'CONCLUÍDA'
        data.date_end = data.date_start
      }

      const header = { header: { 'Content-Type': 'application/json' } }
      await api.post('/activityWeight/save', data, header)
        .then((response) => {
          //console.log(response.data);
          if (response.data.isSuccess) {
            toast.success('Pesagem dos animais realizada com sucesso!')
            usestore.reposLivestock.fetch({ id_farm: property_id })
            usestore.reposActivityWeight.fetch({ id_property: property_id }).then((res) => {
              setAuxAdd(aux_add + 1)
              setTimeout(() => {
                handleNext()
              }, 200)
            })
          }
        })
        .catch((error) => {
          console.log('error')
          console.log(error)
          toast.error('Erro ao realizadar pesagem dos animais, tente novamente mais tarde!')
        })
    }
  }

  const [openM, setOpenM] = useState(false)
  const handleCloseIn = () => {
    if (data.num_animals > 0) {
      // console.log('GenericModal')
      setOpenM(true)
    } else {
      handleClose()
    }
  }

  const obj_fn = {
    setData: setData,
    handleNext: handleNext,
    handleBack: handleBack,
    activeStep: activeStep,
    steps: steps,
    onSubmit: onSubmit,
    livestockRepos: livestockRepos,
    usestore: usestore,
    handleClose: handleCloseIn,
  }

  const obj_modal = {
    maxWidth: 'sm',
    title: 'Pesagem em andamento!',
    color_title: orange[600],
    content: (
      <>
        <p>Ao sair, os dados da pesagem de {data.num_animals} animais serão perdidos!</p>
        <p>Deseja realmente sair da pesagem?</p>
      </>
    ),
    // color_close: orange[700],
    handleClose: handleClose,
  }

  //chamada dos componentes
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <FormIndividualWeightStep1 data={data} obj_fn={obj_fn} />
      case 1:
        return <FormIndividualWeightStep2 data={data} obj_fn={obj_fn} />
      case 2:
        return (
          <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessWeight handleClose={handleClose} data={data} />
            </Paper>
          </Container>
        )
      default:
        return 'Não existente, retorne ...'
    }
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {getStepContent(activeStep)}
        <Grid item xs={4} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <GenericModal setOpen={setOpenM} obj_modal={obj_modal} open={openM} />
        </Grid>
      </Box>
    </>
  )
}

import React, { useState } from 'react';
import { Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import useStore from '../../store/useStore';
import FinancialContentTabs from './FinancialContentTabs';
import BankAccountComponent from './components/BankAccount/BankAccount';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function FinancialChoiceContentTabs() {
  let link = window.location.href;
  let aux_tab_active = 0;
  if (/.*\#financialcontent/.test(link)) {
    aux_tab_active = 0;
  } else if (/.*\#bankaccount/.test(link)) {
    aux_tab_active = 1;
  }
  const [value, setValue] = useState(aux_tab_active);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs textColor="success" TabIndicatorProps={{ style: { background: 'green' } }} variant="scrollable" value={value} onChange={handleChange} aria-label="Painéis de controle">
            <Tab label="Controle Financeiro" aria-controls="Controle Financeiro" {...a11yProps(0)} />
            <Tab label="Contas Financeiras" aria-controls="Contas Financeiras" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <FinancialContentTabs />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <BankAccountComponent />
        </TabPanel>
      </Box>
    </>
  );
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, CardMedia, Grid, Stack, Typography } from '@mui/material';
import Typewriter from '../../../../components/Typewriter/Typewriter';

export default function StartProjectHarvest() {
  const [open, setOpen] = React.useState(true);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={handleClose}>
        <DialogTitle>
          <Grid container spacing={3} sx={{ p: 2 }}>
            <img src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="Fazenda Premium Logo" className="brand-image" style={{ opacity: '.8', height: 32, maxWidth: '100%' }} />
            <Box sx={{ ml: 1, display: 'flex' }}>
              <Stack spacing={0.5} sx={{ mt: 0 }}>
                <Typography variant="h6" style={{ color: '#808080' }} fontWeight={500}>
                  Fazenda Premium
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Typography variant="h5" color="textPrimary" gutterBottom>
                Bem vindo a Gestão Agrícola
              </Typography>
              <Typography gutterBottom sx={{ mt: 0, mb: 3 }} fontWeight={500} style={{ color: '#2e7d32' }}>
                <Typewriter tam={60} text="Ao criar um projeto agrícola, incia-se a gestão agrícola." />
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Box sx={{ mt: 0 }}>
                <Typography paragraph variant="subtitile2" fontWeight={300}>
                  Com o projeto criado, sua gestão agrícola contará com uma visão completa e integrada, permitindo o controle preciso de insumos, otimização dos custos de produção, e monitoramento
                  detalhado de cada cultura. Isso garante decisões mais informadas e aumento da eficiência operacional.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item sm={12} xs={12}>
            <CardMedia component="img" width="200" image="/dist/image/gestaoagricola.png" alt="Gestão Agrícola" />
          </Grid>
          <Grid item sm={12} xs={12}>
            <Box sx={{ mt: 0 }}>
              <Typography paragraph variant="subtitile2" fontWeight={300}>
                Clique em fechar e vamos cadastar um projeto agrícola...
              </Typography>
            </Box>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button color="success" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Chip, Dialog, DialogContent, DialogActions, Grid, Stack, Typography } from "@mui/material"
import { toast } from "react-toastify"
import dayjs from 'dayjs'
import api from "../../../../services/api"

const row_stats = {
  'Recebido': {
    chipLabel: 'Recebido',
    chipColor: 'success'
  },
  'Recebido parcial': {
    chipLabel: 'Recebido parcial',
    chipColor: 'warning'
  },
  'aberta': {
    chipLabel: 'Em aberto',
    chipColor: 'default'
  },
  'estornado': {
    chipLabel: 'Estornado',
    chipColor: 'default'
  },
}

export default function ChipStatusBill({ row_data, obj_fn }) {
  const [open, setOpen] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(row_data.status)

  const handleOpen = () => {
    if (row_data.status != 'estornado') {
      setOpen(true)
    }
  }
  const handleClose = () => {
    setOpen(false)
    setSelectedStatus(row_data.status)
  }

  const handleStatusChange = (newStatus) => {
    setSelectedStatus(newStatus)
  }

  const handleUpdateStatus = async () => {
    const header = { header: { "Content-Type": "application/json" } }

    let data = {
      id_user: obj_fn.userCurrent.id,
      bill: row_data,
      status: selectedStatus,
      oldStatus: row_data.status,
      date_payment: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    }

    const res = await api.post('finances/pay_bill', data, header)
      .then((response) => {
        // console.log(response.data)
        if (response.data.isSuccess) {
          toast.success('Lançamento atualizado com sucesso!')
          obj_fn.usestore.reposFinances.fetch()
          obj_fn.usestore.reposFinances.fetchT()
          obj_fn.usestore.reposCashFlow.fetch();
          obj_fn.usestore.reposBill.fetch().then((res) => {
            setSelectedStatus(selectedStatus)
            obj_fn.setAuxAdd(obj_fn.aux_add + 1)
            setTimeout(() => {
              handleClose()
            }, 200)
          })
        }
      })
      .catch((error) => {
        console.log('error')
        console.log(error)
        toast.error('Erro em atualizar o Lançamento. Tente novamente mais tarde.')
        setTimeout(() => {
          handleClose()
        }, 200)
      })
  }

  // const para validar o button atualizar
  const isError = () => {
    let iserror = false

    if (row_data.status == 'estornado') {
      iserror = true
    } else if (row_data.status == selectedStatus) {
      iserror = true
    }
    return iserror
  }

  return (
    <div>
      <Chip label={row_stats[row_data.status].chipLabel} color={row_stats[row_data.status].chipColor} variant={'outlined'} size={'sm'} onClick={handleOpen} disabled={row_data.status == 'estornado'} />
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <DialogContent>
          <Typography variant="subtitle1" sx={{ mt: 0, mb: 2 }}>
            Atualizar situação do pagamento:
          </Typography>
          <Grid container spacing={1}>
            <Grid item sx={{ ml: 1 }}>
              <Box sx={{ border: "0px solid #ccc", borderRadius: "4px" }}>
                <Stack direction="column" spacing={1}>
                  <Chip label="Em aberto" color={selectedStatus === "aberta" ? "success" : "default"} onClick={() => handleStatusChange("aberta")} />
                  <Chip label="Recebido" color={selectedStatus === "Recebido" ? "success" : "default"} onClick={() => handleStatusChange("Recebido")} />
                  {/*<Chip label="Recebido parcial" color={selectedStatus === "Recebido parcial" ? "success" : "default"} onClick={() => handleStatusChange("Recebido parcial")} />*/}
                  <Chip label="Estornado" color={selectedStatus === "estornado" ? "success" : "default"} onClick={() => handleStatusChange("estornado")} />
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button size="small" color="success" onClick={handleClose} style={{marginRight: '25px'}}>
            Cancelar
          </Button>
          <Button size="small" variant="contained" color="success" onClick={!isError() ? handleUpdateStatus : () => null} disabled={isError()}>
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
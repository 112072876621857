import * as React from 'react'
import { makeStyles, styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { NavLink } from 'react-router-dom'
import ChartBarP from '../../../components/Charts/ChartBarP'
import ChartBarProduction from '../../../components/Charts/ChartBarProduction'
import ChartBarProductivity from '../../../components/Charts/ChartBarProductivity'
import { Button, CardActionArea, CardMedia, Paper } from '@mui/material'
import CardIndicator from './CardIndicator'

const classes = {
  root: {
    flexGrow: 1,
  },
  paper: {
    width: 300,
    height: 150,
    padding: 40,
    textAlign: 'center',
    color: 'blue',
    fontFamily: 'Roboto',
  },
}

export default function ListIndicatorsLivestock() {
  //const data = [1, 2, 3, 4, 5]
  const data = [
    { type: 'livestock', title: 'Criações', text: 'Criações utilizadas, lotes e áreas.', icon: 'ListAltIcon' },
    { type: 'livestock', title: 'Evolução das Criações', text: 'Percentual de atividades realizadas num determinado tipo de atividade ao longo do tempo.', icon: 'LeaderboardIcon' },
    { type: 'livestock', title: 'Produção', text: 'Produção de todas as áreas produtivas', icon: 'LeaderboardIcon' },
    { type: 'livestock', title: 'Produtividade', text: 'Produtividade de todas as áreas produtivas', icon: 'TrendingUpIcon' },
    { type: 'livestock', title: 'Rentabilidade', text: 'Produção, custos e lucro da safra', icon: 'TrendingUpIcon' },
  ]

  return (
    <>
      <div>
        <Grid container spacing={3}>
          {data.map((item) => (
            <>
              <Grid item xs={12} sm={6} md={3}>
                {/* <CardIndicator title={data.title} text={data.text} icon={data.icon} /> */}
                <CardIndicator type={item.type} title={item.title} text={item.text} icon={item.icon} />
              </Grid>
            </>
          ))}
        </Grid>
      </div>
    </>
  )
}

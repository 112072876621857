import { Typography } from "@mui/material";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";

//https://apexcharts.com/react-chart-demos/
//https://blog.logrocket.com/charting-react-apexcharts/
export default function ChartPieHarvest({cultivar}) {
  console.log(cultivar)

  // Calcula os valores da série com base nas áreas da cultura
  const series = cultivar.areas.map((area) => parseFloat(area.usedArea));

  const options = {
    chart: {
      type: "pie",
    },
    labels: cultivar.areas.map((area) => `${area.name} - ${area.usedArea} `),
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  // Calcular os valores da série com base nas áreas
  // const totalUsedArea = cultivar.areas.reduce(
  //   (total, area) => total + parseFloat(area.usedArea),
  //   0
  // );


  // var state = {
  //   series: [80, 9, 2, 2, 7],
  //   options: {
  //     chart: {
  //       type: "pie",
  //     },
  //     labels: ['Animais', 'Nutrição', 'Medicamentos', 'Exames', 'outros'],
  //     responsive: [
  //       {
  //         breakpoint: 480,
  //         options: {
  //           chart: {
  //             width: 200,
  //           },
  //           legend: {
  //             position: "bottom",
  //           },
  //         },
  //       },
  //     ],
  //   },
  // };
  return (
    <div>
       <Typography variant="h6">{cultivar.cultivar}</Typography>
       <ReactApexChart options={options} series={series} type="pie" />
    </div>
  );
}

import { TextField, Button, Box, Grid, Typography, Stack, IconButton, Card } from '@mui/material';
import { HighlightOff, Queue } from '@mui/icons-material';
import GenericValueField from '../../../components/Fields/GenericValueField';
import FormAccountSelection from '../../financial/components/Forms/FormAccountSelection';
import * as MuiIcons from '@mui/icons-material';
import dayjs from 'dayjs';
import ChecklistIcon from '@mui/icons-material/Checklist';
import FooterModal from '../../../components/Footer/FooterModal';
import { useRef } from 'react';
import Typewriter from '../../../components/Typewriter/Typewriter';

const size = 50; // Define o tamanho desejado
// const iconMap = {
//   WarehouseIcon: <Warehouse sx={{ fontSize: size, mt: 0 }} />,
//   PrecisionManufacturingIcon: <PrecisionManufacturing sx={{ fontSize: size, mt: 0 }} />,
// };

// Obtenha o ícone dinamicamente usando o nome
function IconComponent({ iconName }) {
  let aux_icon = iconName.replace(/Icon$/, '');
  const Icon = MuiIcons[aux_icon];

  // Verifique se o ícone existe
  if (!Icon) {
    return <span>-</span>;
  }
  return <Icon sx={{ fontSize: size }} />;
}

const getHelperText = (cost) => {
  return (
    <>
      Selecione o produto desejado
      <br />
      Valor unitário: {cost || '-'}
    </>
  );
};

export default function ResourcesStepFormTabGeneralExpenses({ data, obj_fn }) {
  const expenses = data?.resources?.expenses || [];

  // Cria uma referência para o formulário
  const formRef = useRef(null);

  let aux_f = obj_fn.usestore.reposPerson.listFornecedor;
  let aux_t = obj_fn.usestore.reposPerson.listTransportadora;
  const optionsPerson = [...aux_f, ...aux_t];

  const opt_person = {
    multiple: false,
    name: 'supplier',
    label: 'Fornecedor',
    values: optionsPerson,
    field: 'supplier',
    helperText: 'Selecione um fornecedor para a despesa',
    listHeight: 225,
  };

  // const handleChangeSelect = (field, newValue, index) => {
  //   obj_fn.setData((prevState) => {
  //     const newExpenses = [...(prevState?.resources?.expenses || [])];
  //     if (field === 'supplier') {
  //       newExpenses[index] = {
  //         ...newExpenses[index],
  //         person: newValue,
  //         [field]: newValue?.id,
  //       };
  //     } else {
  //       newExpenses[index] = {
  //         ...newExpenses[index],
  //         [field]: newValue,
  //       };
  //     }
  //     return {
  //       ...prevState,
  //       resources: {
  //         ...prevState?.resources,
  //         expenses: newExpenses,
  //       },
  //     };
  //   });
  // };

  const handleChangePay = (event, index) => {
    obj_fn.setData((prevState) => {
      const newExpenses = [...(prevState?.resources?.expenses || [])];
      newExpenses[index] = {
        ...newExpenses[index],
        [event.target.name]: event.target.value,
      };
      if (event.target.name === 'value') {
        newExpenses[index].value_end = parseFloat(event.target.value);
      }
      return {
        ...prevState,
        resources: {
          ...prevState?.resources,
          expenses: newExpenses,
        },
      };
    });
  };

  const handleAdd = () => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      resources: {
        ...prevState?.resources,
        expenses: [
          ...(prevState?.resources?.expenses || []),
          {
            id: (prevState?.resources?.expenses || []).length + 1,
            //??? como obter o person
            person: null,
            category: '',
            description: '',
            value: '',
            value_end: '',
          },
        ],
      },
    }));
    // Rola a tela até o formulário de despesas
    setTimeout(() => {
      formRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100); // Pequeno atraso para garantir que o novo elemento seja renderizado
  };

  const handleRmv = (id) => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      resources: {
        ...prevState?.resources,
        expenses: prevState?.resources?.expenses?.filter((expense) => expense.id !== id),
      },
    }));
  };

  const isError = () => {
    let iserror = false;
    if (expenses.length === 0) {
      iserror = true;
    }
    expenses.map((serv) => {
      if (!serv.category && !serv.subcategory) {
        iserror = true;
      }
      if (!serv.value) {
        iserror = true;
      }
      return serv;
    });
    return iserror;
  };

  const returnError = () => {
    return false;
  };

  const isErrorAddButton = () => {
    return false;
  };

  const handleChangeDate = (date) => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      resources: {
        ...prevState?.resources,
        date_start: dayjs(date),
        date_start_f: dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
        date_start_fc: dayjs(date).format('DD/MM/YYYY'),
        dueDate: dayjs(date),
        dueDate_f: dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
        dueDate_fc: dayjs(date).format('DD/MM/YYYY'),
      },
    }));
  };

  const obj_date = {
    label: 'Data da Despesa',
    field_data: 'date_start',
    text_helper: 'Defina a data da despesa.',
    max: new Date(),
    handleChange: handleChangeDate,
    maxWidth: '250px',
    setData: obj_fn.setData,
  };

  console.log(expenses);

  return (
    <>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Box sx={{ ml: 2, display: 'flex' }}>
            <ChecklistIcon />
            <Stack spacing={0.5} sx={{ mt: 0, ml: 1 }}>
              <Typography fontWeight={700}>Lista de Despesas</Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} alignItems="center" sx={{ mb: 1 }}>
        <Typography sx={{ textAlign: 'start' }} variant="subtitle1" component="div">
          Olá produtor!
        </Typography>
        <Typewriter text="Cliquem em ADICIONAR DESPESA" variant={'subtitle1'} color={'#2e7d32'} />
      </Grid>
      <div ref={formRef}>
        {expenses.map((expense, index) => (
          <div key={expense.id}>
            <Card sx={{ p: 3, mt: 2 }} key={expense.id}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <Grid container spacing={3} sx={{ mt: 1 }}>
                    <Box sx={{ ml: 3, display: 'flex' }}>
                      <MuiIcons.Money />
                      <Stack spacing={0.5} sx={{ mt: 0, ml: 1 }}>
                        <Typography fontWeight={700}>Despesa {index + 1}</Typography>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <IconButton variant="contained" color="error" onClick={() => handleRmv(expense.id)} title="Remover">
                      <HighlightOff />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid container spacing={3} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6} sx={{ mt: '25px' }}>
                    <TextField
                      fullWidth
                      InputLabelProps={{ style: { color: '#2e7d32' } }}
                      id="description"
                      name="description"
                      label="Descrição"
                      value={expense.description}
                      onChange={(event) => handleChangePay(event, index)}
                      variant="outlined"
                      color="success"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ mt: '25px' }}>
                    <GenericValueField
                      handleChange={(event) => handleChangePay(event, index)}
                      options={{
                        required: true,
                        id: 'value',
                        name: 'value',
                        label: 'Valor da Despesa',
                        value: expense.value,
                        variant: 'outlined',
                        helperText: 'Valor da Despesa',
                        endAdornment: true,
                        prefix: ' (R$) ',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} sx={{ ml: 3, mt: 2 }}>
                    <FormAccountSelection data={expense} obj_fn={obj_fn} handleChange={(event) => handleChangePay(event, index)} />
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </div>
        ))}
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Button variant="contained" color="success" onClick={handleAdd} disabled={isErrorAddButton()}>
            <Queue sx={{ mr: 1 }} /> {expenses.length > 0 ? 'Adicionar Outra Despesa' : 'Adicionar Despesa'}
          </Button>
        </Grid>
      </Grid>
      <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  );
}

import React, { useState } from "react";
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { red } from "@mui/material/colors";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { Context } from "../../../../components/Context/AuthContext";
import { useContext } from "react";
import useHarvestTargetCostReposStore from "../../../../store/useHarvestTargetCostReposStore";
import useHarvestTargetReposStore from "../../../../store/useHarvestTargetReposStore";

function DeleteModalPlanHarvest({ planId, idFarm, plan, id_target }) {
  console.log("DeleteModalPlanHarvest")
  console.log(planId)
  console.log(plan)
  console.log(id_target)

  const [open, setOpen] = useState(false);
  const { aux_add, setAuxAdd } = React.useContext(Context);
  const reposHarvestTargetCost = useHarvestTargetCostReposStore((state) => state);
  const reposHarvestTarget = useHarvestTargetReposStore((state) => state);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleConfirmDelete = () => {
    handleDelete(planId);
    handleClose();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #FF0000",
    boxShadow: 24,
    p: 4,
  };

  const handleDelete = async (planId) => {
    // console.log("deletando: ");
    // console.log(plan)
    // console.log(planId);
    const header = { header: { "Content-Type": "application/json" } };
    let activeCost = false
    let new_id_target = 0
    //console.log(id_target)
    // verificar undefined
    if(id_target){
      activeCost = true
      new_id_target = id_target
    }
    const deleteData = { id: plan.id, id_harvest: plan.id_harvest, id_property: plan.id_property, activeCost:activeCost, id_target:new_id_target };
    // console.log(deleteData);
    const res = await api
      .post("/planning/deleteTargeHarvest", deleteData, header)
      .then((response) => {
        // console.log("response")
        // console.log(response)
        if (!response.data.isSuccess) {
          return toast.error("Erro ao deletar a Meta!");
        }
        if (response.data.isSuccess) {
          toast.success("Meta deletada com sucesso!");
          // reposTargetPlanning.fetch({ id_farm: idFarm }).then((res) => {
          //   setTimeout(() => {
          //     setAuxAdd(aux_add + 1);
          //   }, 200);
          // });
          reposHarvestTarget.fetch({ id_farm: idFarm }).then((res) => {
            //setAuxAdd(aux_add + 1);
            // setTimeout(() => {
            //   handleClose()
            // }, 200);
          });
          reposHarvestTargetCost.fetch({ id_farm: idFarm }).then((res) => {
            setTimeout(() => {
              setAuxAdd(aux_add + 1);
             // handleClose();
            }, 200);
          });
          // setTimeout(() => {
          //   setIsLoading(true);
          // }, 500);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Erro ao deletar a Meta!");
      });

   
  };

  return (
    <>
      <IconButton variant="outlined" color="success" onClick={handleOpen}>
        <DeleteForeverIcon fontSize="small" sx={{ mr: 0.5, mt: -0.2 }} style={{ color: red[500] }} />
      </IconButton>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Confirmar a exclusão
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Deseja realmente excluir essa meta?
          </Typography>
          <Button onClick={handleConfirmDelete} color="error">
            Excluir
          </Button>
          <Button onClick={handleClose} color="success">
            Não excluir
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default DeleteModalPlanHarvest;

import React from 'react';
import ReportsTabs from './components/ReportsTabs';
import { Box, Grid, Stack, Typography } from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import TutorialReports from './components/TutorialReports';

export default function ReportsContent() {
  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={8} md={8}>
              <Box sx={{ display: 'flex' }}>
                <ReceiptLongIcon sx={{ fontSize: 35 }} color="text.secondary" />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Relatórios
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                    Informações&nbsp;detalhadas&nbsp;de&nbsp;toda&nbsp;propriedade
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={4} md={4}>
              <Box display="flex" justifyContent="flex-end">
                <TutorialReports />
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="content mt-1">
        <div className="container-fluid">
          <ReportsTabs />
        </div>
      </div>
    </div>
  );
}

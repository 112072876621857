import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Divider, FormControlLabel, Grid, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { AccountBalance, Event, PriceChange } from '@mui/icons-material';

import { BankList } from '../../../../../components/Icons/bank/BankList';
import FooterModal from '../../../../../components/Footer/FooterModal';
import ThreeDotsSpinner from '../../../../../components/Loader/ThreeDotsSpinner';
import Typewriter from '../../../../../components/Typewriter/Typewriter';

export default function FormBankStep3({ data, obj_fn }) {
  const [showTextFase, setShowTextFase] = useState(false);
  setTimeout(() => {
    setShowTextFase(true);
  }, 500);

  // const para validar o button continuar
  const isError = () => {
    return false;
  };

  const returnError = () => {
    return false;
  };

  if (!data.bank) {
    const listBanks = BankList();
    data.bank = listBanks.find((bank) => bank.bank === data.name_bank);
  }

  return (<>
    <Grid container sx={{ mt: 0 }}>
      <Box sx={{ ml: 1, display: "flex" }}>
        <Stack spacing={0.5}>
          <Typography fontWeight={700}>Confirmação dos dados</Typography>
        </Stack>
      </Box>
    </Grid>
    <Grid container sx={{ mt: 2 }} >
      <div className="d-lg-none">
        <Grid container>
          <Grid item xs={12} md={12} alignItems="center"  >
            {showTextFase ? (
              <Box display="flex" alignItems="center" >
                <Typewriter tam={40} minHeight={40} text={"Pronto! Agora basta conferir os dados e clicar em confirmar e salvar!"} variant={"subtitle1"} color={"#2e7d32"} />
              </Box>
            ) : (
              <Box display="flex" alignItems="center" >
                <ThreeDotsSpinner />
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
      <div className="d-none d-lg-block">
        <Grid container>
          <Grid item xs={12} md={12} alignItems="center" >
            {showTextFase ? (
              <Box display="flex" alignItems="center">
                <Typewriter tam={40} minHeight={40} text={"Pronto! Agora basta conferir os dados e clicar em confirmar e salvar!"} variant={"subtitle1"} color={"#2e7d32"} />
              </Box>
            ) : (
              <Box display="flex" alignItems="center" >
                <ThreeDotsSpinner />
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
    </Grid>
    <Divider sx={{ mt: 2, mb: 2, ml: -3, borderColor: '#388e3c' }} />
    <Grid container sx={{ mb: 5 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
          <Box sx={{ ml: 1, display: "flex", flexDirection: "column" }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Dados da conta: ({data.type_title})</Typography>
            </Stack>
          </Box>
        </Grid>
        {(data.type !== "OutrasContas" && data.type !== "Caixinha") &&
          <>
            <Grid item xs={1} md={1} style={{ maxWidth: '30px' }}>
              <Box sx={{ mt: 2 }}>
                {data.bank.icon}
              </Box>
            </Grid>

            <Grid item xs={5} md={5}>
              <ListItem>
                <ListItemText primary="Banco" secondary={data.bank.label} />
              </ListItem>
            </Grid>
          </>
        }
        <Grid item xs={6} md={6}>
          <ListItem>
            <ListItemText primary={`Descrição da conta ${data.type_title}.`}
              secondary={(<>
                <AccountBalance /> {data.name}
              </>)} />
          </ListItem>
        </Grid>
        {(data.type == "Investimento" || data.type == "Poupanca") &&
          <Grid item xs={6} md={6}>
            <ListItem>
              <ListItemText primary={`Conta corrente Vinculada ao ${data.type_title}.`}
                secondary={(<>
                  <AccountBalance /> {data.typeLinkedAccount.label}
                </>)} />
            </ListItem>
          </Grid>
        }
      </Grid>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={12} sx={{ mb: '-20px' }}>
          <Box sx={{ ml: 1, display: "flex", flexDirection: "column" }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Data e saldo inicial </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={5} md={5}>
          <ListItem>
            <ListItemText primary="Data inicial do saldo" secondary={(<>
              <Event /> {data.balance_entry_f}
            </>)} />
          </ListItem>
        </Grid>
        <Grid item xs={7} md={7}>
          <ListItem>
            <ListItemText primary="Saldo na data inicial selecionada"
              secondary={(<>
                <PriceChange /> {data.balance_opening}
              </>)} />
          </ListItem>
        </Grid>
      </Grid>

      {(data.type == "ContaCorrente" || data.type == "Poupanca") && <>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} md={12} sx={{ mb: '-20px' }}>
            <Box sx={{ ml: 1, display: "flex", flexDirection: "column" }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}>Dados da conta bancária</Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <ListItem>
              <ListItemText primary="Agência" secondary={data.agency} />
            </ListItem>
          </Grid>
          <Grid item xs={6}>
            <ListItem>
              <ListItemText primary="Número da conta" secondary={data.account_number} />
            </ListItem>
          </Grid>
          <Grid item xs={6}>
            <ListItem>
              <ListItemText primary="País" secondary={data.country} />
            </ListItem>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="success"
                  name="standard"
                  checked={data.standard}
                  disabled={true}
                />
              }
              label="Definir como Padrão"
            />
          </Grid>
          <Grid container sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ ml: 1, mt: 2 }}>
              O Fazenda Premium preza por total segurança da sua conta! Não informe suas senhas!
            </Typography>
          </Grid>
        </Grid>
      </>}
    </Grid >

    <FooterModal
      data={data}
      obj_fn={obj_fn}
      isError={isError}
      returnError={returnError}
    />
  </>);
}
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CardProductionLivestockTabsContent from './CardProductionLivestockTabsContent';
import PlanningLivestockContentchoice from '../../planning/PlanningLivestockContentchoice';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CardProductionInProgressLivestock from './CardProductionInProgressLivestock';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 0, mt: 1, ml: 0, mr: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CardProductionLivestockTabsProduction({ listPlanning }) {
  // nível 2 inferior
  // console.log("CardProductionharvestTabsTotal1")
  // console.log(area, index)
  const [area, setArea] = React.useState([]);
  const [index, setIndex] = React.useState(1);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log('listPlanning');
  console.log(listPlanning);

  const isActiveTargetLivestock = listPlanning.filter((item) => item.isActiveTargetLivestock === 1)[0];
  console.log(isActiveTargetLivestock);

  return (
    <Box sx={{ width: '100%' }}>
      {/* <CardComponentArea area={area} index={index} /> */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs textColor="success" TabIndicatorProps={{ style: { background: 'green' } }} variant="scrollable" value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <ListAltIcon sx={{ mr: 0.5, mb: 0.2 }} />
                1) Meta A Produzir
                <ArrowForwardIosIcon sx={{ ml: 0.5, mb: 0.1 }} style={{ fontSize: 16 }} />
              </Grid>
            }
            {...a11yProps(0)}
          />
          {isActiveTargetLivestock?.isActiveTargetLivestock && (
            <Tab
              label={
                <Grid container direction="row" alignItems="center">
                  <PendingActionsIcon sx={{ mr: 0.5, mb: 0.2 }} />
                  2) Produzindo
                  <ArrowForwardIosIcon sx={{ ml: 0.5, mb: 0.1 }} style={{ fontSize: 16 }} />
                </Grid>
              }
              {...a11yProps(1)}
            />
          )}

          {isActiveTargetLivestock?.isActiveTargetLivestock && (
            <Tab
              label={
                <Grid container direction="row" alignItems="center">
                  <CenterFocusStrongIcon sx={{ mr: 0.5, mb: 0.2 }} />
                  3) Produção
                  <ArrowForwardIosIcon sx={{ ml: 0.5, mb: 0.1 }} style={{ fontSize: 16 }} />
                </Grid>
              }
              {...a11yProps(2)}
            />
          )}
          {/* <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <PendingActionsIcon sx={{ mr: 0.5, mb: 0.2 }} />
                3) Produção
                <ArrowForwardIosIcon sx={{ ml: 0.5, mb: 0.1 }} style={{ fontSize: 16 }} />
              </Grid>
            }
            {...a11yProps(2)}
          /> */}
          {/* <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <EmojiEventsIcon sx={{ mr: 0.5, mb: 0.2 }} />
                4) Colheita
                <ArrowForwardIosIcon sx={{ ml: 0.5, mb: 0.1 }} style={{ fontSize: 16 }} />
              </Grid>
            }
            {...a11yProps(3)}
          />

          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <AddHomeIcon sx={{ mr: 0.5, mb: 0.2 }} />
                5) Armazenamento
              </Grid>
            }
            {...a11yProps(4)}
          />*/}
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <PlanningLivestockContentchoice listPlanning={listPlanning} />
        {/* <CardRegisterProductionTargetHarvestTabs area={area} index={index} /> */}
        {/* <CardRegisterProductionTargetHarvest /> */}
        {/* https://www.npmjs.com/package/gantt-task-react */}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CardProductionInProgressLivestock area={area} index={index} />
        {/* <CardProductionLivestockTabsContent listPlanning={listPlanning} /> */}
        {/* <CardProductionInProgressLivestock area={area} index={index} listPlanning={listPlanning} /> */}
        {/* <CardProductionLivestockTabsContent /> */}
        {/* <PlanningHarvestContentchoice area={area} index={index} /> */}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CardProductionLivestockTabsContent listPlanning={listPlanning} />
        {/* <CardProductionInProgressLivestock area={area} index={index} listPlanning={listPlanning} /> */}
        {/* <CardProductionLivestockTabsContent /> */}
        {/* <PlanningHarvestContentchoice area={area} index={index} /> */}
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        <CardProductionLivestockTabsContent listPlanning={listPlanning} /> 
        <CardProductionInProgress area={area} index={index} /> 
      </TabPanel> */}
      {/* <TabPanel value={value} index={3}>
        <CardProductionharvestTabs area={area} index={index} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <CardProductionStorage />
      </TabPanel> */}
    </Box>
  );
}

import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardMedia, Divider, Grid, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { AutoAwesomeMotion, ExpandMore, Female, Male } from '@mui/icons-material';
import FooterModal from '../../../../../components/Footer/FooterModal';
import AutocompleteGeneric from '../../../../../components/Fields/AutocompletGeneric';
import ProductRegistrar from '../../../../products/components/ProductRegistrar/ProductRegistrar';
import TableNutritionAnimals from './TableNutritionAnimals';
// import CurrencyInputReal from '../../../../../components/Fields/CurrencyInputReal';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../../../../components/Loader/ThreeDotsSpinner';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import useStore from '../../../../../store/useStore';
import foodsImage from '../../../../../assets/jpg/alimentos.jpg';
import { useParams } from 'react-router-dom';
import GenericValueField from '../../../../../components/Fields/GenericValueField';

export default function FormNutritionStep1({ data, obj_fn }) {
  const [errorr, setError] = useState(false);
  // console.log('FormNutritionStep');
  // console.log(data);

  const [image_livestok, setLivestokImage] = useState('/dist/img_fp/rebanho.jpg');

  const [showText, setShowText] = useState(false);
  setTimeout(() => {
    setShowText(true);
  }, 2000);

  useEffect(() => {
    setShowText(false);
  }, []);

  // const para validar o button continuar
  const isError = () => {
    var iserror = false;
    if (!data.lotes.length) {
      iserror = true;
    } else {
      data.lotes?.map((lote) => {
        let aux_product = lote.product?.nutrition || 0;
        lote.list_animals.map((animal) => {
          if (lote.table_selected[animal.id]) {
            aux_product += animal?.nutrition || 0;
          }
        });
        if (aux_product === 0) {
          iserror = true;
        } else if (aux_product > lote.product?.location_selected?.quanty + (lote.product?.location_selected?.quanty_edit || 0)) {
          iserror = true;
        }
      });
    }
    return iserror || errorr;
  };
  const returnError = () => {
    return false;
  };

  // Monta o array e obj para o campo de lotes
  const opt_lotes = {
    multiple: true,
    name: 'lotes',
    label: 'Lotes da Criação',
    values: data.opt_lotes,
    field: 'lotes',
    variant: 'standard',
    helperText: 'Selecione o(s) lote(s) que receberá alimentação',
    listHeight: 225,
  };

  const handleChangeLote = (field, newValue, selectOption, option) => {
    // console.log('handleChangeLote');
    if (newValue && newValue.length > 0) {
      newValue.map((lote) => {
        if (option && option.id === lote.id) {
          lote.product = null;

          lote.list_animals.map((animal) => {
            animal.nutrition = 0;
            return animal;
          });
          lote.table_selected = {};
          lote.table_confirm = [];
        }

        return lote;
      });
    }
    obj_fn.setData((prevState) => ({ ...prevState, [field]: newValue }));
  };

  // const handleChange = (event) => {
  //   obj_fn.setData((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  // };

  // Handle PRODUTOS ------
  const optionsProducts = JSON.parse(JSON.stringify(obj_fn.usestore.reposProducts.getProductsStoreMultiple([['group_category', 'Nutrição']])));
  const handleChangeSelect = (field, newValue) => {
    // console.log('handleChangeSelect');
    obj_fn.setData((prevState) => ({
      ...prevState,
      lotes: prevState.lotes.map((lote, idx) => {
        let aux_idx = field.split('_');
        if (idx === aux_idx[2]) {
          let aux_prod = JSON.parse(JSON.stringify(newValue));
          aux_prod.location_selected = aux_prod.location.length > 0 ? aux_prod.location[0] : null;

          return { ...lote, product: aux_prod };
        }
        return lote;
      }),
    }));
    if (obj_fn.setUpdated) {
      obj_fn.setUpdated(1);
      obj_fn.setData((prevState) => ({ ...prevState, edit_prod: true }));
    }
  };

  const handleChangeLocal = (field, newValue) => {
    // console.log('handleChangeLocal');
    obj_fn.setData((prevState) => ({
      ...prevState,
      lotes: prevState.lotes.map((lote, idx) => {
        let aux_idx = field.split('_');
        if (idx === aux_idx[3]) {
          let aux_lote_prod = [...lote.product];
          let aux_local = JSON.parse(JSON.stringify(newValue));

          aux_lote_prod[aux_idx[3]].location_selected = aux_local;
          return { ...lote, product: aux_lote_prod };
        }
        return lote;
      }),
    }));
    if (obj_fn.setUpdated) {
      obj_fn.setUpdated(1);
      obj_fn.setData((prevState) => ({ ...prevState, edit_prod: true }));
    }
  };

  const handleChangeQtd = (event, product, index, total) => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      lotes: prevState.lotes.map((lote, idx) => {
        if (idx === index) {
          let aux_lote_prod = { ...lote.product };
          let aux_qtd = parseFloat(event.target.value || '0');
          let aux_qtd_anim = lote.num_selecteds || 1;

          if (total) {
            aux_lote_prod.nutrition = aux_qtd / aux_qtd_anim;
            aux_lote_prod.nutrition_total = aux_qtd;
          } else {
            aux_lote_prod.nutrition = aux_qtd;
            aux_lote_prod.nutrition_total = aux_qtd * aux_qtd_anim;
          }

          lote.list_animals = lote.list_animals.map((animal) => {
            if (lote.table_selected[animal.id]) {
              if (animal.id === 0) {
                // não identificados
                animal.nutrition = aux_lote_prod.nutrition * lote.num_animals;
              } else {
                animal.nutrition = aux_lote_prod.nutrition;
              }
            }
            return animal;
          });

          return { ...lote, product: aux_lote_prod };
        }
        return lote;
      }),
    }));
    if (obj_fn.setUpdated) {
      obj_fn.setUpdated(1);
      obj_fn.setData((prevState) => ({ ...prevState, edit_prod: true }));
    }
  };

  const handleProducts = (product, index) => {
    const opt_products = {
      multiple: false,
      name: 'product_' + product?.id + '_' + index,
      field: 'product_' + product?.id + '_' + index,
      label: 'Produto (Nutrição)',
      values: optionsProducts,
      variant: 'outlined',
      helperText: (
        <>
          1-Selecione o produto desejado
          <br />
          Valor unitário: {product?.stock_cost || ''}
        </>
      ),
      listHeight: 225,
      disableClearable: true,
    };

    return (
      <>
        <AutocompleteGeneric value={product || null} options={opt_products} handleChange={handleChangeSelect} />
      </>
    );
  };

  const handleProductsLocation = (product, index) => {
    const opt_products_local = {
      multiple: false,
      name: 'product_location_' + product?.id + '_' + index,
      field: 'product_location_' + product?.id + '_' + index,
      label: 'Local de estoque (Retirada)',
      values: product?.location || [],
      variant: 'standard',
      helperText: (
        <>
          2-Local onde o produto se encontra
          <br />
          <span style={{ color: 'green' }}>Qtd. em estoque:</span> {product?.location_selected?.quanty || '-'}
          {product?.unit_sigla ? ' (' + product?.unit_sigla + ')' : ''}
          {product?.location_selected?.quanty_edit ? (
            <>
              <br />
              Qtd. em edição: {product?.location_selected?.quanty_edit}
              {product?.unit_sigla ? ' (' + product?.unit_sigla + ')' : ''}
            </>
          ) : (
            ''
          )}
        </>
      ),
      listHeight: 225,
      disabled: !product?.id,
      disableClearable: true,
    };

    return (
      <>
        <AutocompleteGeneric value={product?.location_selected || null} options={opt_products_local} handleChange={handleChangeLocal} />
      </>
    );
  };

  const validQtd = (type, product) => {
    let valid = product?.nutrition > 0;
    let qtd_prod = product?.location_selected?.quanty + (product?.location_selected?.quanty_edit || 0) || 0;
    if (type === 'color') {
      return valid || !product?.id ? 'success' : 'error';
    } else {
      var mensagem = <></>;
      if (!product?.id) {
        mensagem = <></>;
      } else if (!valid) {
        mensagem = (
          <>
            <br />
            <span style={{ color: 'green' }}>Deve ser maior que 0</span>
          </>
        );
      } else if (qtd_prod < (product?.nutrition || 0)) {
        mensagem = (
          <>
            <br />
            <span style={{ color: 'green' }}>Não pode ser maior do que a Qtd. em estoque</span>
          </>
        );
      } else if (type === 'total' && qtd_prod < (product?.nutrition_total || 0)) {
        mensagem = (
          <>
            <br />
            <span style={{ color: 'green' }}>Não pode ser maior do que a Qtd. em estoque</span>
          </>
        );
      }

      return (
        <>
          3-Quantidade: {type}
          {mensagem}
        </>
      );
    }
  };

  const expanded = false;
  // const [expanded, setExpanded] = React.useState(false);

  // const handleExpansion = () => {
  //   setExpanded((prevExpanded) => !prevExpanded);
  // };

  // console.log('data.lotes');
  // console.log(data.lotes);

  // // ------ inicicia os repos
  // const usestore = useStore()
  // // seta opções das Images
  // const useImagesRepos = usestore.reposImages
  // const listImageRepos = useImagesRepos.getImageStore('livestock', listAcitivy.id)
  //   console.log('listImageRepos')
  //   console.log(listImageRepos)
  //   console.log('tipo_icon')
  //   console.log(listAcitivy.tipo_icon)
  //   // listAcitivy.image = listImageRepos[0] ? listImageRepos[0].img : "/dist/img_fp/rebanho-nelore.png";
  //   listAcitivy.image = listImageRepos[0] ? listImageRepos[0].img : `/dist/img_fp/${listAcitivy.tipo_icon}`
  //   setLivestokImage(listAcitivy.image)

  const { id } = useParams();

  // ------ inicicia os repos
  const usestore = useStore();
  // seta opções das Images
  const useImagesRepos = usestore.reposImages;

  const listAcitivy = JSON.parse(JSON.stringify(usestore.reposLivestock.getLivestockStore('id', id)[0]));

  // console.log('lista de criações1');
  // console.log(listAcitivy);

  const listImageRepos = useImagesRepos.getImageStore('livestock', listAcitivy.id);
  // console.log('listImageRepos');
  // console.log(listImageRepos);
  // console.log('tipo_icon');
  // console.log(listAcitivy.tipo_icon);
  // listAcitivy.image = listImageRepos[0] ? listImageRepos[0].img : "/dist/img_fp/rebanho-nelore.png";
  listAcitivy.image = listImageRepos[0] ? listImageRepos[0].img : `/dist/img_fp/${listAcitivy.tipo_icon}`;

  useEffect(() => {
    setLivestokImage(listAcitivy.image);
  }, []);

  const filters_prods = [['group_category', 'Nutrição']];
  // console.log(data);
  // console.log(data.lotes);

  return (
    <>
      <Grid container sx={{ mb: 4, ml: 1 }}>
        <Grid item xs={12} md={12}>
          <Box display="flex" sx={{ mt: 1 }} justifyContent="flex-end">
            {/* <ProductRegistrar filters={filters_prods} /> */}
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 0, mt: obj_fn.isMobile ? 1 : 3 }}>
          <AutoAwesomeMotion />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Lotes para manejo Nutricional</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 0, mt: -2 }}>
          <Grid item xs={12} sm={7} sx={{ mt: 1 }}>
            <AutocompleteGeneric value={data.lotes} options={opt_lotes} handleChange={handleChangeLote} />
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={{ mb: 1, mt: 0 }}>
          {data.lotes?.map((lote, index) => (
            <>
              <Card sx={{ p: 2 }}>
                <>
                  <Grid container spacing={4} sx={{ mt: 1, mb: 1, ml: '-15px', p: 2 }}>
                    <Card sx={{ display: 'flex', padding: 1, width: '100%', overflow: 'auto', border: '2px solid #2e7d32' }} id={'card_lot_' + lote.id}>
                      {/* <CardMedia component="img" sx={{ width: 125 }} image={lote.imageDecode} alt={lote.name} /> */}
                      <Grid item justifyContent="left" xs={12} sm={12} sx={{ mr: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12} container direction="column" alignItems="flex-start">
                            <Grid item container alignItems="center">
                              <Grid item>
                                <AutoAwesomeMotion style={{ color: '#2e7d32' }} sx={{ fontSize: 18 }} />
                              </Grid>
                              <Grid item sx={{ ml: 1 }} style={{ color: '#2e7d32' }}>
                                {lote.name}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <CardMedia
                              component="img"
                              //height="300"
                              //style={{ width: "100%" }} height="100%"
                              image={image_livestok}
                              alt="Criação"
                              sx={{
                                borderRadius: '16px', // Ajuste o valor conforme necessário para o chanfrado desejado
                                height: '100%',
                                width: '100%',
                              }}
                            />
                          </Grid>

                          {/* <Grid item xs={12} md={4} container alignItems="center"></Grid> */}
                          <Grid item xs={12} sm={6}>
                            <Grid item container alignItems="center">
                              <Grid item xs={6} sx={{ mt: 3 }}>
                                {/* <Typography variant="subtitle1">Total de animais:</Typography> */}
                                <Typography variant="subtitle2">
                                  <strong> O lote tem</strong> <span style={{ fontSize: '0.8em' }}>{lote?.total_animals || 0} </span>
                                  <strong>animais</strong>
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sx={{ mt: 3 }}>
                                {/* <Typography variant="subtitle1">Total de animais:</Typography> */}
                                <Typography variant="subtitle2">
                                  <strong> Não identificados:</strong> <span style={{ fontSize: '0.8em' }}> {lote?.num_animals || 0}</span>
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sx={{ mt: 3 }}>
                                {/* <Typography variant="subtitle1">Total de animais:</Typography> */}
                                <Typography variant="subtitle2">
                                  <strong> Fêmeas:</strong>{' '}
                                  <span style={{ fontSize: '0.8em' }}>
                                    {' '}
                                    <Female color="secondary" /> {lote?.num_femeas || 0}
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sx={{ mt: 3 }}>
                                {/* <Typography variant="subtitle1">Total de animais:</Typography> */}
                                <Typography variant="subtitle2">
                                  <strong> Machos:</strong>{' '}
                                  <span style={{ fontSize: '0.8em' }}>
                                    {' '}
                                    <Male color="primary" /> {lote?.num_machos || 0}
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sx={{ mt: 3 }}>
                                <strong> Fase de Manejo:</strong> <span style={{ fontSize: '0.8em' }}>{lote?.fase_name || ''}</span>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </>
                <Grid container>
                  <Grid item xs={12} md={8} sx={{ mt: 2 }} alignItems="center">
                    {showText ? (
                      <Box display="flex" alignItems="center">
                        <Typewriter tam={60} text={'Com lote de animais selecionado, é hora de alimentá-los abaixo.'} variant={'subtitle1'} color={'#2e7d32'} />
                      </Box>
                    ) : (
                      <Box display="flex" alignItems="center">
                        <ThreeDotsSpinner />
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Accordion defaultExpanded={expanded} sx={{ width: '100%' }}>
                  <AccordionSummary expandIcon={<ExpandMore />} aria-controls="Manejo nutricional do lote" id={'acordion_' + lote?.id + '_' + index}>
                    <Typography fontWeight={700}>
                      <FoodBankIcon sx={{ mr: 1 }} /> Manejo nutricional no {lote.name} (clique aqui para alimentá-los)
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ mb: '-24px' }}>
                    <Grid container spacing={4} sx={{ mt: -3, mb: 4 }}>
                      <Grid item xs={12} md={4}>
                        <CardMedia
                          component="img"
                          //height="300"
                          //style={{ width: "100%" }} height="100%"
                          image={foodsImage}
                          alt="Criação"
                          sx={{
                            borderRadius: '16px', // Ajuste o valor conforme necessário para o chanfrado desejado
                            height: '100%',
                            width: '100%',
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <List dense={true}>
                          <ListItem>
                            <Grid container direction="column">
                              <Grid item>
                                <ListItemText primary="1- Selecione o alimento (produto) do estoque" secondary="Caso não encontre o produto, clique em cadastro de produtos." />
                              </Grid>
                              <Grid item>
                                <ProductRegistrar filters={filters_prods} />
                              </Grid>
                            </Grid>
                          </ListItem>

                          <ListItem>
                            <ListItemText primary="2- Defina o local do estoque que será dado a baixa do produto" />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="3- Defina a quantidade de alimento desejado para os animais" />
                          </ListItem>
                        </List>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        {handleProducts(lote.product, index)}
                      </Grid>
                      <Grid item xs={12} sm={3.5} sx={{ mt: obj_fn.isMobile ? -4 : -1 }}>
                        {handleProductsLocation(lote.product, index)}
                      </Grid>
                      <Grid item xs={6} sm={2.25}>
                        {/* <CurrencyInputReal
                          name={'nutrition_' + lote?.id + '_' + index}
                          label="Qtd. média/Ca*"
                          variant="standard"
                          value={lote?.product?.nutrition}
                          adornment="quantidade"
                          endAdornment={lote.product?.unit_sigla ? ' (' + lote.product?.unit_sigla + ')' : ''}
                          onChange={(e) => handleChangeQtd(e, lote.product, index)}
                          helperText={validQtd('média por cabeça', lote.product, index)}
                          color={validQtd('color', lote.product)}
                          disabled={!lote.product?.id}
                        /> */}
                        <GenericValueField
                          handleChange={(e) => handleChangeQtd(e, lote.product, index)}
                          options={{
                            id: 'nutrition_' + lote?.id + '_' + index,
                            name: 'nutrition_' + lote?.id + '_' + index,
                            label: 'Qtd. média/Ca*',
                            value: lote?.product?.nutrition,
                            variant:"standard",
                            helperText: validQtd('média por cabeça', lote.product, index),
                            color: validQtd('color', lote.product),
                            height: '30px',
                            disabled: !lote.product?.id,
                            endAdornment: true,
                            prefix: lote.product?.unit_sigla ? " (" + lote.product?.unit_sigla + ")" : '',
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sm={2.25}>
                        {/* <CurrencyInputReal
                          name={'nutrition_total_' + lote?.id + '_' + index}
                          label="Qtd. total*"
                          variant="standard"
                          value={lote?.product?.nutrition_total}
                          adornment="quantidade"
                          endAdornment={lote.product?.unit_sigla ? ' (' + lote.product?.unit_sigla + ')' : ''}
                          onChange={(e) => handleChangeQtd(e, lote.product, index, 'total')}
                          helperText={validQtd('total', lote.product, index)}
                          color={validQtd('color', lote.product)}
                          disabled={!lote.product?.id}
                        /> */}
                        <GenericValueField
                          handleChange={(e) => handleChangeQtd(e, lote.product, index, 'total')}
                          options={{
                            id: 'nutrition_total_' + lote?.id + '_' + index,
                            name: 'nutrition_total_' + lote?.id + '_' + index,
                            label: 'Qtd. total*',
                            value: lote?.product?.nutrition_total,
                            variant:"standard",
                            helperText: validQtd('total', lote.product, index),
                            color: validQtd('color', lote.product),
                            height: '30px',
                            disabled: !lote.product?.id,
                            endAdornment: true,
                            prefix: lote.product?.unit_sigla ? " (" + lote.product?.unit_sigla + ")" : '',
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <List dense={true}>
                        <ListItem>
                          <ListItemText primary="4- Visualize o manejo nutricional" />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid container spacing={4} sx={{ mt: 1, mb: 1, ml: '-15px' }}>
                      <Card sx={{ display: 'flex', padding: 1, width: '100%', overflow: 'auto' }} id={'card_lot_' + lote.id}>
                        {/* <CardMedia component="img" sx={{ width: 125 }} image={lote.imageDecode} alt={lote.name} /> */}
                        {/* <Grid item justifyContent="left" xs={12} sm={3} sx={{ mr: 1 }}>
                        <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2, mb: 2 }}>
                          Total de animais:
                          <br />
                          {lote?.total_animals || 0}
                        </Typography>
                        <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2, mb: 2 }}>
                          Fase de Manejo:
                          <br />
                          {lote?.fase_name || ''}
                        </Typography>
                        <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2, mb: 2 }}>
                          Fêmeas:
                          <br />
                          <Female color="secondary" /> {lote?.num_femeas || 0}
                        </Typography>
                        <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2, mb: 2 }}>
                          Machos:
                          <br />
                          <Male color="primary" /> {lote?.num_machos || 0}
                        </Typography>
                        <Typography variant="subtitle2" display="block" gutterBottom sx={{ ml: 2, mb: 2 }} title={'Não identificados'}>
                          Não identif.:
                          <br />
                          {lote?.num_animals || 0}
                        </Typography>
                      </Grid> */}

                        <Grid item xs={12} sm={12}>
                          <TableNutritionAnimals data_l={lote} obj_fn={obj_fn} setError={setError} />
                        </Grid>
                      </Card>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Card>
              <Grid item xs={12} sx={{ mt: 4 }}>
                <Divider />
              </Grid>
            </>
          ))}
        </Grid>

        {/* Descrição */}
        {/* <Grid container spacing={3} sx={{ mb: 0, mt: 3, ml: -4 }}>
        <Notes />
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700} style={{ color: '#2e7d32' }}> Descrição geral do manejo sanitário </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid container spacing={1} sx={{ mb: 1, mt: 0, ml: -4 }}>
        <Grid item xs={12} sm={12}>
          <TextareaAutosize
            minRows={3} // Customize the initial number of rows
            maxRows={10} // Customize the maximum number of rows
            id="description"
            name="description"
            placeholder="Informações auxiliares do manejo sanitário"
            value={data.observations}
            onChange={handleChange}
            onBlur={(e) => handleChange(e, true)}
            style={{ width: '100%', padding: '8px' }}
          />
        </Grid>
      </Grid> */}

        {obj_fn.no_footer ? (
          <></>
        ) : (
          <>
            <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
          </>
        )}
      </Grid>
    </>
  );
}

import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import api from '../services/api';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';

// function updateIsActiveStatus(item) {
//   const currentDate = new Date();

//   //return items.map(item => {
//   const startDate = new Date(item.date_start);
//   const endDate = new Date(item.date_prev_end);
//   return (currentDate >= startDate && currentDate <= endDate);
//   //});
// }

// create the store
const usePatrimonyReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listPatrimony: [],
        patrimonyGroup: [],
        patrimonyType: [],
        patrimonyLocation: [],
        patrimonyRepos: {
          label: '',
          id: 0,
          id_property: 0,
          tipo: 'Patrimony',
          name: '',
        },
        fetch: async (item) => {
          var farm_id = localStorage.getItem('Property') || 1;
          if (!item) {
            item = { id_farm: farm_id };
          } else if (!item.id_farm) {
            item.id_farm = farm_id;
          }

          const header = { header: { 'Content-Type': 'application/json' } };
          const response = await api.post('/patrimony/list', item, header);
          // console.log('patrimony/list*****************');
          const data = await response.data;
          // console.log(data);
          // console.log(data._value[0]);
          // console.log(data._value[1]);
          // console.log(data._value[2]);
          // console.log(data._value[3]);
          // let patrimony_group = [];
          //let patrimony_type = [];
          // Atualize o estado usando a função set
          // set((state) => ({
          //   listPatrimony: data[0], // Os dados do patrimônio estão em data[0]
          //   patrimonyGroup: data[1], // Os dados dos grupos de patrimônio estão em data[1]
          // }));
          await set({
            listPatrimony: data._value[0].map((patrimony) => ({
              id: patrimony.id,
              id_user: patrimony.id_user,
              id_property: patrimony.id_property,
              patrimony_group_id: patrimony.patrimony_group_id,
              id_patrimony_type: patrimony.id_patrimony_type,
              id_patrimony_grid: patrimony.id_patrimony_grid,
              name: patrimony.patrimony_name,
              group: patrimony.patrimony_group_name,
              type: patrimony.patrimony_type_name,
              patrimony_group_icon: patrimony.patrimony_group_icon,
              model: patrimony.model,
              manufacturer: patrimony.manufacturer,
              manufacturing_year: patrimony.manufacturing_year || 0,
              lifespan: patrimony.lifespan || 0,
              date_buy: patrimony.date_buy,
              date_buy_fA: dayjs(patrimony.date_buy).format('DD/MM/YYYY'),
              date_buy_fB: dayjs(patrimony.date_buy).format('YYYY-MM-DD HH:mm:ss'),
              value: patrimony.value,
              horimetro_current: patrimony.horimetro_current || 0,
              observation: patrimony.observation,
              image: patrimony.image,
              date_updated: patrimony.date_updated,
              isDeleted: patrimony.isDeleted,
              tipo_repos: 'Patrimony',
              // cultivation_name: patrimony.cultivation_name,
              // label: patrimony.cultivation_name,
              // date_start: dayjs(patrimony.date_start).format(
              //   "YYYY-MM-DD HH:mm:ss"
              // ),
              // date_prev_end: dayjs(patrimony.date_prev_end).format(
              //   "YYYY-MM-DD HH:mm:ss"
              // ),
            })),
          });
          set({
            patrimonyGroup: data._value[1].map((group) => ({
              id: group.id,
              name: group.name,
              name_nf: group.name_nf,
              icon: group.icon,
              value: group.value,
              label: group.label,
            })),
          });
          set({
            patrimonyType: data._value[2].map((type) => ({
              id: type.id,
              name: type.name,
              name_nf: type.name_nf,
              icon: type.icon,
              patrimony_group_id: type.patrimony_group_id,
            })),
          });
          set({
            patrimonyLocation: data._value[3].map((patrimony) => ({
              id: patrimony.id,
              name: data._value[0].find((item) => item.id === patrimony.id_patrimony).patrimony_name,
              id_patrimony: patrimony.id_patrimony,
              id_product: patrimony.id_product,
              quantity: patrimony.quanty,
              date_updated: patrimony.date_updated,
            })),
          });
          set({
            patrimonyRepos:
              !data || data._value.length === 0
                ? {
                    label: '',
                    id: 0,
                    id_property: 0,
                    tipo_repos: 'Patrimony',
                    name: '',
                  }
                : {
                    label: data._value[0].name,
                    id: data._value[0].id,
                    id_property: data._value[0].id_property,
                    tipo_repos: 'Patrimony',
                    //name: data._value[0].cultivation_name,
                  },
          });
          return data;
        },
        changePatrimonyReposRepos: (patrimonyRepos) => set((state) => ({ patrimonyRepos })),
        // pega a(s) livestock(s) da listLivestock de acordo com o [key e val] informados
        getPatrimonyStore: (key, val) => {
          return get().listPatrimony.filter((patrimony) => {
            return patrimony[key] === val;
          });
        },
        // retorna os dados de um patrimonio(location) de acordo com o(s) id(s) passado(s)
        getPatrimonyLocationStore: (ids, qtds) => {
          // garante que ids e qtds sejam array
          if (typeof ids === 'string') {
            ids = ids.split(',');
          }
          if (typeof qtds === 'string') {
            qtds = qtds.split(',');
          }
          let listLocations = JSON.parse(JSON.stringify(get().patrimonyLocation));
          let locations = [];
          listLocations.map((patrimony) => {
            ids.forEach((id_patrimony, index) => {
              if ((patrimony.id = id_patrimony)) {
                // adiciona a quantidade
                patrimony.stock_quanty_array = parseFloat(qtds[index].trim());
                locations.push(patrimony);
              }
            });
            return patrimony;
          });
          return locations;
        },
        // Adiciona uma nova livestock à listLivestock
        addPatrimonyStore: (patrimony) => {
          set((state) => ({
            listPatrimony: [...state.listPatrimony, patrimony],
          }));
        },
        // Gera uma nova listLivestock sem a livestock informada
        delPatrimonyStore: (id) => {
          set({
            listPatrimony: get().listPatrimony.filter((patrimony) => {
              return patrimony.id !== id;
            }),
          });
        },
        // atualiza listLivestock de acordo com id, key e val informados
        updatePatrimonyStore: (id, key, val) => {
          set({
            listPatrimony: get().listPatrimony.map((patrimony) => {
              if (patrimony.id === id) {
                if (key === 'all') {
                  // atualiza todos os dados
                  patrimony = val;
                } else {
                  patrimony[key] = val;
                }
              }
              return patrimony;
            }),
          });
          return get().listPatrimony;
        },
        getPatrimonyGroupStore: (key, val) => {
          return get().patrimonyGroup.filter((group) => {
            return group[key] === val;
          });
        },
        getPatrimonyTypeStore: (key, val) => {
          return get().patrimonyType.filter((type) => {
            return type[key] === val;
          });
        },
      }),
      { name: 'reposPatrimony' }
    )
  )
);

export default usePatrimonyReposStore;

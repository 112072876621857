import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Box, ButtonBase, Grid, Paper, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { AddCircleOutline, Agriculture, Campaign, LocationSearching } from '@mui/icons-material';
import RadarIcon from '@mui/icons-material/Radar';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../../../components/Loader/ThreeDotsSpinner';
import useStore from '../../../../store/useStore';

import { IconSoilPreparation } from '../../../../components/Icons/harvest/unit/IconSoilPreparation';
import { IconPlanting } from '../../../../components/Icons/harvest/unit/IconPlanting';
import { IconFertilization } from '../../../../components/Icons/harvest/unit/IconFertilization';
// import { IconSeedTreatment } from '../../../../components/Icons/harvest/unit/IconSeedTreatment';
import { IconHarvesting } from '../../../../components/Icons/harvest/unit/IconHarvesting';

import MultiStepFormPlanting from '../FormActivities/Planting/MultiStepFormPlanting';
import MultiStepFormHarvesting from '../FormActivities/Harvesting/MultiStepFormHarvesting';
import MultiStepFormMonitoring from '../FormActivities/Monitoring/MultiStepFormMonitoring';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MultiStepFormApplications from '../FormActivities/Applications/MultiStepFormApplications';
import MultiStepFormSoilPreparation from '../FormActivities/SoilPreparation/MultiStepFormSoilPreparation';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

const buttons = {
  'Pré-plantio': [
    { name: 'SoilPreparation', value: true, title: 'Preparo de Solo', icon: <IconSoilPreparation widthStyles={'65'} color="disabled" /> },
    // { name: 'seedTreatment', value: false, title: 'Tratamento de semente ', icon: <IconSeedTreatment widthStyles={'50'} fill="#00000042" color="disabled" /> },
  ],
  Plantio: [
    { name: 'planting', value: true, title: 'Plantio ', icon: <IconPlanting widthStyles={'55'} color="disabled" /> },
    // { name: 'fertilization', value: true, title: 'Aplicar Fertilizante ', icon: <IconFertilization widthStyles={'45'} fill="#00000042" color="disabled" /> },
  ],
  Monitoramento: [
    { name: 'monitoring', value: true, title: 'Monitoramento', icon: <RadarIcon style={{ fontSize: 50 }} color="disabled" /> },
    { name: 'applications', value: true, title: 'Aplicar Defensivos', icon: <LocationSearching style={{ fontSize: 50 }} fill="#00000042" color="disabled" /> },
  ],
  Colheita: [
    // { name: 'machines', value: false, title: 'Máquinas', icon: <Agriculture style={{ fontSize: 50 }} color="disabled" /> },
    { name: 'harvesting', value: true, title: 'Colheita', icon: <IconHarvesting widthStyles={'65'} color="disabled" /> },
  ],

  // Financeiras: [
  //   { name: "buy", value: false, title: "Compra", icon: <PointOfSale style={{ fontSize: 50 }} /> },
  //   { name: "sales", value: false, title: "Venda", icon: <AttachMoney style={{ fontSize: 50 }} /> },
  // ],
  // Marketing: [{ name: 'marketing', value: true, title: 'Marketing ', icon: <Campaign style={{ fontSize: 50 }} color="disabled" /> }],
  // Outras: [{ name: 'others', value: true, title: 'Adicionar outra', icon: <AddCircleOutline style={{ fontSize: 50 }} color="disabled" /> }],
};

// lista os botões de cada atividade que chama o MultiStepForm referente à atividade
export function CardButtonListActivities({ handleClose }) {
  //texto é acionado quando o botão radio é ativado
  setTimeout(() => {
    setShowText(true);
  }, 500);

  const classes = useStyles();
  const { idFarm, id } = useParams();
  const [showText, setShowText] = useState(false);
  const [buttonStates, setButtonStates] = useState({});
  const [selectedComponent, setSelectedComponent] = useState(null);

  const handleButtonClick = (name, value) => {
    setButtonStates({ ...buttonStates });
    setSelectedComponent(name);
  };

  const usestore = useStore();
  const has_plantios = usestore.reposActivityPlanting.getActivityPlantingStore('id_harvest', id);

  const handleValidButton = (button) => {
    return (
      !button.value ||
      (has_plantios.length == 0 && button.name === 'harvest') ||
      // (has_plantios.length == 0 && button.name === 'monitoring') ||
      // (has_plantios.length == 0 && button.name === 'SoilPreparation') ||
      (has_plantios.length == 0 && button.name === 'fertilization') ||
      // (has_plantios.length == 0 && button.name === 'applications') ||
      (has_plantios.length == 0 && button.name === 'harvesting') ||
      (has_plantios.length == 0 && button.name === 'marketing') ||
      (has_plantios.length == 0 && button.name === 'others')
    );
  };

  useEffect(() => {
    // Set initial button states
    const initialButtonStates = {};
    for (const category in buttons) {
      buttons[category].forEach((button) => {
        initialButtonStates[button.name] = button.value;
      });
    }
    setButtonStates(initialButtonStates);
  }, []);

  // Use a lógica de color conforme necessário
  const color = 'disabled'; // Altere para 'enabled' se necessário

  return selectedComponent === null ? (
    <>
      <div className="d-lg-none">
        <Grid container sx={{ mb: 2, height: 30 }}>
          <Grid item xs={12} md={12} alignItems="center">
            {showText ? (
              <Typewriter text="Selecione uma atividade de campo para a sua safra:" variant={'subtitle1'} color={'#2e7d32'} />
            ) : (
              <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                {' '}
                <ThreeDotsSpinner />
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
      <div className="d-none d-lg-block">
        <Grid container sx={{ mb: 2, height: 30 }}>
          <Grid item xs={12} md={12} alignItems="center">
            {showText ? (
              <Typewriter text="Selecione uma atividade de campo para a sua safra:" variant={'subtitle1'} color={'#2e7d32'} />
            ) : (
              <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                {' '}
                <ThreeDotsSpinner />
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
      <Grid item xs={12} md={12} sx={{ mb: 2 }} alignItems="center">
        {has_plantios.length == 0 && (
          <>
            <Typography fontWeight="500" variant="subtitle2" style={{ color: '#2e7d32' }}>
              <strong>Controle da safra por área: </strong>
              {`Com sua áreas demarcadas, você poderá realizar os preparos do solo ou plantio.`}
            </Typography>
            <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
              <strong>Atividade de preparo do solo:</strong> Utilize seus métodos de preparo para melhoria do solo.
            </Typography>
            <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
              <strong>Atividade de plantio: </strong>Atribua a cultivar a ser plantada em cada uma das áreas da safra.
            </Typography>
          </>
        )}
        {has_plantios.length > 0 && (
          <>
            <Typography fontWeight="500" variant="subtitle2" style={{ color: '#2e7d32' }}>
              <strong>Gerencie a sua safra por área. </strong>
            </Typography>
            <Grid item xs={12} md={12} sx={{ mt: 2 }} alignItems="center">
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                  Informações das atividades
                </AccordionSummary>
                <AccordionDetails>
                  <Typography fontWeight="500" variant="subtitle2" style={{ color: '#2e7d32' }}>
                    <strong>Pré-plantio </strong>
                  </Typography>
                  <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                    <strong>Preparo do solo: </strong>
                    {`Envolve a modificação física do solo para criar condições ideais para receber as sementes. Inclui as principais atividades como aração, gradagem, destorroamento, descompactação e correção do solo (como calcário para ajuste de pH).`}
                  </Typography>
                  <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                    <strong>Tratamento de semente: </strong>
                    {`Envolve a preparação das sementes antes do plantio para aumentar a taxa de germinação e proteger contra doenças e pragas. Atividades típicas: Aplicação de fungicidas, inseticidas, bactericidas, e nutrientes às sementes.`}
                  </Typography>
                  <Typography fontWeight="500" variant="subtitle2" style={{ color: '#2e7d32' }}>
                    <strong>Plantio </strong>
                  </Typography>
                  <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                    <strong>Plantio: </strong>
                    {`É a colocação das sementes ou mudas no solo preparado. seguindo as técnicas adequadas para cada tipo de cultura. Isso inclui definir o espaçamento correto, profundidade de plantio e outras práticas que asseguram um bom estabelecimento das plantas.`}
                  </Typography>
                  <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                    <strong>Aplicar Fertilizante: </strong>
                    {`Envolve a adição de nutrientes ao solo ou diretamente às plantas, corrigindo deficiências nutricionais do solo e promovendo um crescimento saudável. Atividades típicas: Aplicação de fertilizantes químicos (NPK, ureia), adubação orgânica (esterco, compostos), adubação foliar e adubação de cobertura.`}
                  </Typography>
                  <Typography fontWeight="500" variant="subtitle2" style={{ color: '#2e7d32' }}>
                    <strong>Monitoramento </strong>
                  </Typography>
                  <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                    <strong>Monitoramento: </strong>
                    {`Consiste em examinar o número de insetos ou os danos causados por pragas ou doenças na cultura, ajudando a tomar decisões informadas sobre a aplicação de defensivos.`}
                  </Typography>
                  <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                    <strong>Aplicar Defensivos: </strong>
                    {`Envolve a aplicação de defensivos agrícolas, como pesticidas, herbicidas e fungicidas, para proteger as plantas contra pragas, doenças e plantas daninhas. Isso ajuda a aumentar a produção e a qualidade da colheita.`}
                  </Typography>
                  <Typography fontWeight="500" variant="subtitle2" style={{ color: '#2e7d32' }}>
                    <strong>Colheita </strong>
                  </Typography>
                  <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                    <strong>Colheita: </strong>
                    {`É a coleta das plantas ou partes das plantas que são o produto final da atividade agrícola. A colheita envolve a identificação da área colhida, tamanho da área, data da colheita, contabilização das horas de trabalho do maquinário e registro dos responsáveis pela atividade. Observações sobre a atividade também podem ser inseridas. Atividades típicas: Corte, coleta manual ou mecanizada, trilhagem, e transporte dos produtos colhidos.`}
                  </Typography>
                  <Typography fontWeight="500" variant="subtitle2" style={{ color: '#2e7d32' }}>
                    <strong>Outra </strong>
                  </Typography>
                  <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                    <strong>Outra: </strong>
                    {`Esta opção é usada para registrar atividades que não estão listadas no software. Basta inserir o nome da atividade no campo Operação e preencher as demais informações conforme descrito acima.`}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </>
        )}
      </Grid>
      {Object.keys(buttons).map((category) => (
        <div key={category}>
          <Typography variant="subtitle2" sx={{ mb: 1, mt: 2 }} color="text.secondary">
            {category}
          </Typography>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
            {buttons[category].map((button) => (
              <Grid item xs={6} sm={6} md={6} key={button.name}>
                <ButtonBase
                  component={Paper}
                  elevation={2}
                  sx={{
                    p: 2,
                    borderRadius: 1,
                  }}
                  onClick={() => handleButtonClick(button.name, buttonStates[button.name])}
                  className={classes.button}
                  disabled={handleValidButton(button)}
                >
                  <Stack direction="column" alignItems="center">
                    {/* {console.log(button)} */}
                    {button.name === 'harvesting' ? (
                      <IconHarvesting widthStyles={'65'} fill={handleValidButton(button) ? '#d3d3d3' : '#000'} color="disabled" />
                    ) : button.name === 'SoilPreparation' ? (
                      <IconSoilPreparation widthStyles={'65'} fill={handleValidButton(button) ? '#d3d3d3' : '#000'} color="disabled" />
                    ) : button.name === 'fertilization' ? (
                      <IconFertilization widthStyles={'55'} fill={handleValidButton(button) ? '#d3d3d3' : '#000'} color="disabled" />
                    ) : handleValidButton(button) ? (
                      button.icon
                    ) : (
                      React.cloneElement(button.icon, { color: '#d3d3d3' })
                    )}
                    <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                      {button.title}
                    </Typography>
                  </Stack>
                </ButtonBase>
              </Grid>
            ))}
          </Grid>
        </div>
      ))}
    </>
  ) : selectedComponent === 'SoilPreparation' ? (
    <MultiStepFormSoilPreparation handleClose={handleClose} />
  ) : selectedComponent === 'monitoring' ? (
    <MultiStepFormMonitoring handleClose={handleClose} />
  ) : selectedComponent === 'planting' ? (
    <MultiStepFormPlanting handleClose={handleClose} />
  ) : selectedComponent === 'harvesting' ? (
    <MultiStepFormHarvesting handleClose={handleClose} />
  ) : selectedComponent === 'applications' ? (
    <MultiStepFormApplications handleClose={handleClose} />
  ) : //) : selectedComponent === "assignArea" ? (
  //  <MultiStepFormAssignArea handleClose={handleClose} tipo={'ADIÇÃO'}/>
  //) : selectedComponent === "desassignArea" ? (
  //  <MultiStepFormAssignArea handleClose={handleClose} tipo={'REMOÇÃO'}/>
  null;
}

import { useContext, useEffect, useState } from 'react';
import { Box, Container, Grid, Paper } from '@mui/material';
import { orange } from '@mui/material/colors';
import { toast } from 'react-toastify';
import api from '../../../../../services/api';
import useStore from '../../../../../store/useStore';
import { Context } from '../../../../../components/Context/AuthContext';
import GenericModal from '../../../../../components/Dialog/GenericModal';
import 'dayjs/locale/pt';
import FormIndividualMarketingtStep1 from './FormIndividualMarketingtStep1';
import FormIndividualMarketingtStep2 from './FormIndividualMarketingtStep2';
import FormIndividualMarketingtStep3 from './FormIndividualMarketingtStep3';
import SuccessMarketing from './SuccessMarketing';

export default function MultiStepMarketing({ handleClose }) {
  console.log('MultiStepFormIndividualWeight');
  const { aux_add, setAuxAdd } = useContext(Context);
  const usestore = useStore();

  const property_id = localStorage.getItem('Property') || 1;
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));

  const [data, setData] = useState({});

  // const [data, setData] = useState({
  //   id_property: property_id,
  //   id_livestock: livestockRepos.id,
  //   id_user: userCurrent.id,
  //   options_identifier: getOptAnimals('identifier'),
  //   identifier: { id: 0, label: '' },
  //   options_brinco: getOptAnimals('brinco'),
  //   brinco: { id: 0, label: '' },
  //   options_sisbov: getOptAnimals('sisbov'),
  //   sisbov: { id: 0, label: '' },
  //   options_name: getOptAnimals('name'),
  //   name: { id: 0, label: '' },
  //   name_activity: 'PESAGEM INDIVIDUAL',
  //   lote: {},
  //   num_animals: 0,
  //   num_females: 0,
  //   num_males: 0,
  //   new_weight: 0,
  //   weight_medio: 0,
  //   weight_total: 0,
  //   unit: 'kg',
  //   valor: 0,
  //   date_start: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  //   date_prev_end: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  //   date_end: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  //   isDeleted: '',
  //   progress: 'CONCLUÍDA',
  //   planning: 'realized',
  //   table_animals: livestockRepos.animais,
  //   table_selected: {},
  //   table_confirm: [],
  // })

  // controle do dialog dos componentes
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    handleScroll();
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    handleScroll();
  };

  const handleScroll = () => {
    setTimeout(() => {
      window.jQuery(window.jQuery.find('#focus_top_modal')[0]).parent()[0].scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, 300);
  };

  const marketingLivestockRepos = usestore.reposMarketingLivestock.getActivityMarketingStore('id_livestock', 1)[0];
  console.log('marketingLivestockRepos');

  console.log(marketingLivestockRepos);
  // const [page, setPage] = useState({
  //   number: '31987683264',
  //   newMessageWhatsapp: 'Eu quero comprar',
  //   title: 'FAZENDA CPT',
  //   address: ' Rodovia Br 120, Km 6, Zona rural s/n, São Geraldo - MG, 36530-000',
  //   image: '',
  //   city: '',
  //   state: '',
  //   backgroundColorHeader: '',
  //   textColorHeader: '',
  //   //mainCreation
  //   titleCreation: 'Vacas Nelore',
  //   descriptionCreation: 'Animal de ótima genética, acompanhada de cria e novilhas.',
  //   amountCreation: '40.000,00 /lote (20 ani.)',
  //   buttonCreation: 'Negociar',
  //   showButton: true,
  //   paymentTextCreation: 'Formas de Pagamento Aceitas por Esse Vendedor',
  //   paymentCreation: 'pix, dinheiro e transferência bancária',
  //   textColorMainCreation: '',
  //   backgroundColorMainCreation: '',
  //   buttonColorMainCreation: '#ffa500',
  //   // Description
  //   titleTable: 'CARACTERÍSTICAS',
  //   characteristics: [
  //     {
  //       title: 'PUREZA DE SANGUE',
  //       description: 'Mestiço, 1ª Raça: Nelore[50%], 2ª Raça: Mocho-Nacional[50%]',
  //     },
  //     {
  //       title: 'APTIDÃO',
  //       description: 'Corte',
  //     },
  //     {
  //       title: 'PESO MÉDIO',
  //       description: '13 Arrobas',
  //     },
  //   ],
  //   textColorTableDescriptionCreation: '',
  //   backgroundColorTableDesciptionCreation: '',
  //   // cor de fundo da landpage
  //   backgroundPageColor: '#ffffff',
  //   isDeleted: 0,
  // })
  const [page, setPage] = useState({});

  useEffect(() => {
    setPage(marketingLivestockRepos);
  }, []);

  const onSubmit = async () => {
    let formOk = true;

    // if (data.weight_total == 0) {
    //   toast.error('Ao menos uma animal deve ter novo peso supoerior a 0!')
    //   formOk = false
    // } else if (!data.table_confirm.length) {
    //   toast.error('Adione pelo menos um animal à pesagem!')
    //   formOk = false
    // }

    let dataMarketing = page;
    dataMarketing.characteristics = JSON.stringify(page.characteristics);
    console.log(dataMarketing);
    dataMarketing.image = '';

    if (formOk) {
      // if (data.date_start == data.date_prev_end) {
      //   data.progress = 'CONCLUÍDA'
      //   data.date_end = data.date_start
      // }

      const header = { header: { 'Content-Type': 'application/json' } };

      const res = await api
        .put('/activityMarketingLivestock/update', dataMarketing, header)
        .then((response) => {
          //console.log(response.data);
          if (response.data.isSuccess) {
            toast.success('Marketing atualizado com sucesso!');
            uploadImage(1);
            //usestore.reposLivestock.fetch({ id_farm: property_id })
            usestore.reposMarketingLivestock.fetch({ id_property: property_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleNext();
              }, 100);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao realizadar pesagem dos animais, tente novamente mais tarde!');
        });
    }
  };

  async function uploadImage(id) {
    console.log('uploadImage');
    // toast.error(`test ${id}`);
    var id_farm = localStorage.getItem('Property') || 1;
    const fd = new FormData();
    fd.append('image', page.imageBlob, `${page.title}.png`);
    //const tipo_item = 'area';
    fd.append('item_type', 'marketing' || '');
    fd.append('item_id', id || 0);
    fd.append('id_farm', id_farm || 1);

    try {
      const response = await api.post('upload', fd);
      if (response.data.isSuccess) {
        toast.success(response.data.isSuccess);
        var farm_id = localStorage.getItem('Property');
        usestore.reposImages.fetch({ id_farm: farm_id }).then((res) => {
          usestore.reposMarketingLivestock.fetch({ id_property: property_id }).then((res) => {
            setTimeout(() => {
              setAuxAdd(aux_add + 1);
            }, 200);
          });
        });
      }
    } catch (error) {
      toast.error('Erro ao salvar na Amazon');
    }
  }

  const [openM, setOpenM] = useState(false);
  const handleCloseIn = () => {
    if (data.num_animals > 0) {
      console.log('GenericModal');
      setOpenM(true);
    } else {
      handleClose();
    }
  };

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ['FormIndividualMarketingtStep1', 'FormIndividualMarketingtStep2', 'FormIndividualMarketingtStep3', 'SuccessWeight'];

  const obj_fn = {
    setData: setData,
    handleNext: handleNext,
    handleBack: handleBack,
    activeStep: activeStep,
    steps: steps,
    onSubmit: onSubmit,
    // livestockRepos: livestockRepos,
    usestore: usestore,
    handleClose: handleCloseIn,
  };

  const obj_modal = {
    maxWidth: 'sm',
    title: 'Marketing atualizado',
    color_title: orange[700],
    content: (
      <>
        <p>Ao sair, os dados da pesagem de {data.num_animals} animais serão perdidos!</p>
        <p>Deseja realmente sair da pesagem?</p>
      </>
    ),
    color_close: orange[700],
    handleClose: handleClose,
  };

  const handleRestoreDefault = () => {
    setPage({
      number: '31987683264',
      newMessageWhatsapp: 'Eu quero comprar',
      title: 'FAZENDA CPT',
      address: ' Rodovia Br 120, Km 6, Zona rural s/n, São Geraldo - MG, 36530-000',
      image: '',
      city: '',
      state: '',
      backgroundColorHeader: '',
      textColorHeader: '',
      //mainCreation
      titleCreation: 'Vacas Nelore',
      descriptionCreation: 'Animal de ótima genética, acompanhada de cria e novilhas.',
      amountCreation: '40.000,00 /lote (20 ani.)',
      buttonCreation: 'Negociar',
      showButton: true,
      paymentTextCreation: 'Formas de Pagamento Aceitas por Esse Vendedor',
      paymentCreation: 'pix, dinheiro e transferência bancária',
      textColorMainCreation: '',
      backgroundColorMainCreation: '',
      buttonColorMainCreation: '#ffa500',
      // Description
      titleTable: 'CARACTERÍSTICAS',
      characteristics: [
        {
          title: 'PUREZA DE SANGUE',
          description: 'Mestiço, 1ª Raça: Nelore[50%], 2ª Raça: Mocho-Nacional[50%]',
        },
        {
          title: 'APTIDÃO',
          description: 'Corte',
        },
        {
          title: 'PESO MÉDIO',
          description: '13 Arrobas',
        },
      ],
      textColorTableDescriptionCreation: '',
      backgroundColorTableDesciptionCreation: '',
      // cor de fundo da landpage
      backgroundPageColor: '#ffffff',
      isDeleted: 0,
    });
  };
  console.log('fim');
  console.log(page);

  //chamada dos componentes
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <FormIndividualMarketingtStep1 data={data} obj_fn={obj_fn} setPage={setPage} page={page} />;
      case 1:
        return <FormIndividualMarketingtStep2 data={data} obj_fn={obj_fn} setPage={setPage} page={page} handleRestoreDefault={handleRestoreDefault} />;
      case 2:
        return <FormIndividualMarketingtStep3 data={data} obj_fn={obj_fn} page={page} onSubmit={onSubmit} />;
      case 3:
        return (
          <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessMarketing handleClose={handleClose} data={data} />
            </Paper>
          </Container>
        );
      default:
        return 'Não existente, retorne ...';
    }
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {getStepContent(activeStep)}
        <Grid item xs={4} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <GenericModal setOpen={setOpenM} obj_modal={obj_modal} open={openM} />
        </Grid>
      </Box>
    </>
  );
}

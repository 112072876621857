import * as React from 'react';
import Box from '@mui/material/Box';
import { ButtonBase, Card, CardContent, CardHeader, CardMedia, Container, Divider, Grid, Paper, Skeleton, Stack, Typography } from '@mui/material';
import Typewriter from '../../../components/Typewriter/Typewriter';
import { useState } from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import useStore from '../../../store/useStore';
import TimelineHorizPhase from '../../planningHarvest/components/GraphPlan/TimelineHorizPhase';
import ChartBarPArea from '../../../components/Charts/ChartBarPArea';
import ChartBarProduction from '../../../components/Charts/ChartBarProduction';
import ChartBarProductivity from '../../../components/Charts/ChartBarProductivity';
import ChartBarPAreaInProgress from '../../../components/Charts/ChartBarPAreaInProgress';
import ChartBarProductionInProgress from '../../../components/Charts/ChartBarProductionInProgress';
import ChartBarProductivityInProgress from '../../../components/Charts/ChartBarProductivityInProgress';
import ChartBarPlantingProductionArea from '../../../components/Charts/ChartBarPlantingProductionArea';
import ChartBarProductionCost from '../../../components/Charts/ChartBarProductionCost';
import LoadActivityListLivestock from '../../../components/LoadActivityList/LoadActivityListLivestock';
import { useParams } from 'react-router-dom';
import CharTimeMultiActivityProduction from '../../../components/Charts/CharTimeMultiActivityProduction';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

export default function CardProductionInProgressLivestock({ area, index }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingText, setIsLoadingText] = useState(true);
  const usestore = useStore();

  console.log('CardProductionInProgress1');
  console.log(area, index);

  // const target_active = (area.target).filter((item) => {
  //   if (item.isActive) {
  //     return item;
  //   }
  // })[0];

  let filters = {
    table_active: 'realized',
    status: 'edit',
  };

  const { idFarm, id } = useParams();
  const currentLivestok = usestore.reposLivestock.getLivestockStore('id', id)[0];

  const loadActivityListLivestock = LoadActivityListLivestock();
  const rows_lv = loadActivityListLivestock.loadList(currentLivestok, filters);
  console.log(rows_lv);

  // console.log("target_active1")
  // console.log(target_active)
  // console.log(area)

  // ])

  return (
    <>
      <Card sx={{ mt: 1 }}>
        <CardHeader
          title={
            <>
              <div className="d-lg-none">
                <Box sx={{ display: 'flex', mb: 1 }}>
                  <Typography sx={{ fontSize: 18 }}>Gerenciamento a evolução das etapas de produção:</Typography>
                </Box>
                <Box sx={{ minHeight: 110 }}>
                  <Typography style={{ color: '#2e7d32' }}>{isLoadingText && <Typewriter tam={110} minHeight={0} text="Gerencie o andamento das atividades de seus animais" />}</Typography>
                </Box>
              </div>

              <div className="d-none d-lg-block">
                <Box sx={{ display: 'flex', mb: 1 }}>
                  <Typography variant="h6" color="textPrimary">
                    Gerenciamento a evolução das etapas de produção:
                  </Typography>
                </Box>
                <Box sx={{ minHeight: 30 }}>
                  <Typography style={{ color: '#2e7d32' }}>{isLoadingText && <Typewriter tam={30} minHeight={0} text="Gerencie o andamento das atividades de seus animais" />}</Typography>
                </Box>
              </div>
            </>
          }
        ></CardHeader>
        <Divider />
      </Card>
      <Card sx={{ mt: 1 }}>
        <Container component="main" maxWidth="" sx={{ mb: 0, maxHeight: '260px', overflowY: 'auto' }}>
          <Paper variant="" sx={{ my: { xs: 3, md: 1 }, p: { xs: 1, md: 1 } }}>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid container spacing={2} sx={{ mt: 0 }}>
                <Grid item xs={12} md={12}>
                  <Box mt={0}>{/* <TimelineHorizPhase date_start={target_active?.date_start} date_prev_end={"xx/xx/20xx"} /> */}</Box>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Card>
      <Grid container spacing={3} sx={{ mt: 2, mb: 4 }}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Atividades ao longo do tempo
              </Typography>
              {rows_lv.length > 0 && <CharTimeMultiActivityProduction rows={rows_lv} />}
              {/* <ChartBarPlantingProductionArea expectedYield={target_active?.area} unit={target_active?.measure} /> */}
              {/* <ChartBarProduction expectedYield={parseFloat(plan?.expectedYield) || 0} unit={plan?.unit || ""} /> */}
            </CardContent>
          </Card>
          {/* <Card>
            <CardContent sx={{ mt: -1 }}>
              <Typography variant="h6" gutterBottom>
                Área a colher
              </Typography>
              <Stack alignItems="center">
                <Stack alignItems="center" direction="row" divider={<Divider />} spacing={1}>
                  <Typography variant="caption" color="text.secondary" component="div">
                     {nameAtivity.tipo_repos == "Safras" ? date_start : dataHarvest.data_inicial} 
                  </Typography>
                  <ChartBarPAreaInProgress valueProgress={"10"} />
                  <Typography variant="caption" color="text.secondary" component="div">
                     {nameAtivity.tipo_repos == "Safras" ? date_prev_end : dataHarvest.data_fim} 
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
          </Card> */}
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Custo da Produção
              </Typography> */}
        {/* {area.cost && <ChartBarProductionCost expectedYield={area.cost?.totalProductionCost || 0} unit={area.cost?.valueCost || ""} />} */}
        {/* </CardContent>
          </Card>
        </Grid> */}
        {/* <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Produtividade
              </Typography>
              <ChartBarProductivityInProgress valueProductivity={10} unit={`/ha`} />

             <ChartBarProductivity valueProductivity={valueProductivity.toFixed(2) || 0} unit={`${plan?.unit || ""}}/ha`} /> 
            </CardContent>
          </Card>
        </Grid> */}
      </Grid>
    </>
  );
}

//https://aegro.com.br/termos-de-uso/

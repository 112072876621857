import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material';
import UploadFunction from '../Upload/UploadFunction';

const UploadDialog = ({ open, TransitionComponent, handleClose, item, callBack, callBackHook }) => {
  return (
    <Dialog open={open} TransitionComponent={TransitionComponent} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
      <DialogTitle>{'Faça o upload de imagens em seu álbum'}</DialogTitle>
      <DialogContent>
        <div style={{ width: '100%' }}>
          <Box
            sx={{
              mt: 3,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <UploadFunction item={item} callBack={callBack} callBackHook={callBackHook} />
          </Box>
          <br />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="success" onClick={handleClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadDialog;

// como aplicar: exemplo: src/pages/main/components/CardProperty.js

// <UploadDialog open={open} TransitionComponent={Transition} handleClose={handleClose} item={item} callBack={callBack} callBackHook={callBackHook} />

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

// const [openU, setOpenU] = useState(false);

//   const handleOpenU = () => {
//     setOpenU(true);
//   };

//   const handleCloseU = () => {
//     setOpenU(false);
//   };

//   const item = {}; // Seu objeto item aqui
//   const callBack = () => {}; // Sua função callback aqui
//   const callBackHook = () => {}; // Seu callback hook aqui

//   return (
//     <div>
//  <IconButton variant="contained" color="primary" onClick={handleOpenU}>
//                   <PhotoCamera color="success" />
//                 </IconButton>
//       <Button variant="contained" color="primary" onClick={handleOpenU}>
//         Open Upload Dialog
//       </Button>
//       <UploadDialog
//         open={openU}
//         TransitionComponent={Transition}
//         handleClose={handleCloseU}
//         item={item}
//         callBack={callBack}
//         callBackHook={callBackHook}
//       />
//     </div>
//   );

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Container, Paper } from "@mui/material";

import FormBankStep1 from "./FormBankStep1";
import FormBankStep2 from "./FormBankStep2";
import SuccessBank from "./SuccessBank";

import { Context } from "../../../../../components/Context/AuthContext";
import useStore from "../../../../../store/useStore";
import api from "../../../../../services/api";
import dayjs from "dayjs";
import FormBankStep3 from "./FormBankStep3";
//https://ajuda.contaazul.com/hc/pt-br/articles/8932395646605
//https://ajuda.contaazul.com/hc/pt-br/sections/115002247788-Gest%C3%A3o-de-compras

export default function MultiStepFormBank({ handleClose }) {
  console.log("MultiStepFormBank");
  const { aux_add, setAuxAdd } = useContext(Context);
  const usestore = useStore();
  var userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  const property_id = localStorage.getItem("Property") || 1;

  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: property_id,
    name: '',
    name_bank: 'bancobrasil',
    bank: '',
    balance_opening: 0,
    balance_date: dayjs(),
    balance_entry: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    balance_entry_f: dayjs().format("DD/MM/YYYY"),
    agency: '',
    account_number: '',
    country: 'Brasil',
    type: '',
    typePeople: "Juridica",
    typeLinkedAccount: "",
    type_title: '',
    standard: false,
  });

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (submit) => {
    if (submit != 'submit' && activeStep === steps.length - 1) {
      onSubmit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      handleScroll();
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    handleScroll();
  };

  const handleScroll = () => {
    setTimeout(() => {
      window.jQuery(window.jQuery.find('#focus_top_modal')[0])?.parent()[0]?.scrollTo({ top: 0, left: 0, behavior: "smooth", });
    }, 300);
  };

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ["FormBankStep1", "FormBankStep2", "FormBankStep3"];

  const onSubmit = async () => {
    let formOk = true;

    if (!data.name || (data.name.length < 5) || !data.name_bank || (!data.balance_date.$D || dayjs(data.balance_date).isAfter(dayjs(new Date())))) {
      formOk = false;
    }

    if (formOk) {
      let bank_bkp = window.jQuery.extend(true, {}, data);

      // remove itens que tenham -> Symbol(react.fragment)
      data.standard = (data.standard ? 1 : 0);
      data.bank = '';
      data.icon = '';
      data.type_icon = '';
      data.balance_date = '';

      const header = { header: { "Content-Type": "application/json" } };
      const res = await api.post("/bank/save", data, header)
        .then((response) => {
          //console.log(response.data);
          if (response.data.isSuccess) {
            toast.success("Conta bancária registrada com sucesso!");
            usestore.reposBank.fetch({ 'id_property': property_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleNext('submit');
              }, 100);
            });
          }
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          toast.error("Erro ao cadastrar a Conta bancária, tente novamente mais tarde!");
        });
      // retorna dados para o data
      setTimeout(() => {
        //data = bank_bkp;
        data.standard = bank_bkp.standard;
        data.bank = bank_bkp.bank;
        data.icon = bank_bkp.icon;
        data.type_icon = bank_bkp.type_icon;
        data.balance_date = bank_bkp.balance_date;
      }, 150);
    } else {
      toast.error("Por favor, preencha os campos obrigatórios!");
    }
  };

  const getStepContent = (step) => {
    const obj_fn = {
      setData: setData,
      handleNext: handleNext,
      handleBack: handleBack,
      activeStep: activeStep,
      steps: steps,
      handleClose: handleClose,
      onSubmit: onSubmit,
    };

    switch (step) {
      case 0:
        // tipo de conta bancária
        return <FormBankStep1 data={data} obj_fn={obj_fn} />;
      case 1:
        // dados do banco
        return <FormBankStep2 data={data} obj_fn={obj_fn} />;
      case 2:
        // confirmação dos dados
        return <FormBankStep3 data={data} obj_fn={obj_fn} />;
      case 3:
        return (
          <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessBank handleClose={handleClose} data={data} />
            </Paper>
          </Container>
        );
      default:
        return "Não existente";
    }
  };

  return (<>
    <Box sx={{ width: "100%" }}>
      {getStepContent(activeStep)}
    </Box>
  </>);
}

import React, { useState } from 'react';
import { Box, Grid, Stack, TextareaAutosize, Typography } from '@mui/material';
import { Input, Notes } from '@mui/icons-material';
import dayjs from "dayjs";

import Typewriter from '../../../../../components/Typewriter/Typewriter';
import FooterModal from '../../../../../components/Footer/FooterModal';
import SingleDateField from '../../../../../components/Fields/SingleDateField';

import FormApportionment from '../../Forms/FormApportionment';
import AutocompletGenericList from '../../../../../components/Fields/AutocompletGenericList';
import FormTableItens from '../../Forms/FormTableItens';
import FormSelectAddItens from '../../Forms/FormSelectAddItens';

export default function FormBuySalesStep1({ data, obj_fn }) {
  const [error, setError] = useState(false);
  obj_fn.setError = setError;

  const handleChange = (event) => {
    obj_fn.setData(prevState => ({ ...prevState, [event.target.name]: event.target.value }));
    if (event.target.name === 'value') {
      obj_fn.setData(prevState => ({ ...prevState, value_end: event.target.value }));
    }
  };
  // const para validar o button continuar
  const isError = () => {
    let iserror = false

    if (!data.products.length && !data.lotes.length && !data.animals.length) {
      iserror = true
    } else if (!data.value || data.error_date || error) {
      iserror = true
    }
    return iserror;
  };
  const returnError = () => {
    return true;
  };

  // Monta o array e obj para o campo de cliente ou fornecedor
  let optionsPerson = [];
  if (data.operationType === "Venda") {
    optionsPerson = obj_fn.usestore.reposPerson.listCliente;
  } else {
    let aux_f = obj_fn.usestore.reposPerson.listFornecedor;
    let aux_t = obj_fn.usestore.reposPerson.listTransportadora;
    optionsPerson = [...aux_f, ...aux_t];
  }
  const opt_person = {
    multiple: false,
    name: data.operationType === "Venda" ? 'client' : 'supplier',
    label: data.operationType === "Venda" ? 'Cliente' : 'Fornecedor',
    values: optionsPerson,
    field: data.operationType === "Venda" ? 'client' : 'supplier',
    helperText: 'Selecione um ' + (data.operationType === "Venda" ? 'Cliente' : 'Fornecedor') + " para a " + data.operationType,
    listHeight: 225,
    reposPerson: obj_fn.usestore.reposPerson,
  }

  const handleChangeSelect = (field, newValue) => {
    console.log('handleChangeSelect');
    if (field === 'client' || field === 'supplier') {
      obj_fn.setData((prevState) => ({ ...prevState, person: newValue }));
      obj_fn.setData(prevState => ({ ...prevState, [field]: newValue?.id }));
    } else {
      obj_fn.setData(prevState => ({ ...prevState, [field]: newValue }));
    }
  };

  // Monta o obj e função para o campo de data do lançamento
  const handleChangeDate = (value) => {
    obj_fn.setData((prevState) => ({ ...prevState, date_release: dayjs(value) }));
    obj_fn.setData((prevState) => ({ ...prevState, date_release_f: dayjs(value).format("YYYY-MM-DD HH:mm:ss") }));
    obj_fn.setData((prevState) => ({ ...prevState, date_release_fc: dayjs(value).format("DD/MM/YYYY") }));

    obj_fn.setData((prevState) => ({ ...prevState, dueDate: dayjs(value) }));
    obj_fn.setData((prevState) => ({ ...prevState, dueDate_f: dayjs(value).format("YYYY-MM-DD HH:mm:ss") }));
    obj_fn.setData((prevState) => ({ ...prevState, dueDate_fc: dayjs(value).format("DD/MM/YYYY") }));

    if (dayjs(data.date_delivery).isBefore(dayjs(value), 'day')) {
      obj_fn.setData((prevState) => ({ ...prevState, date_delivery: dayjs(value) }));
      obj_fn.setData((prevState) => ({ ...prevState, date_delivery_f: dayjs(value).format("YYYY-MM-DD HH:mm:ss") }));
      obj_fn.setData((prevState) => ({ ...prevState, date_delivery_fc: dayjs(value).format("DD/MM/YYYY") }));
    }
  };
  let obj_date = {
    label: 'Data do pedido',
    field_data: 'date_release',
    text_helper: 'Refere-se ao dia em que a ' + data.operationType + ' foi realizada.',
    // min: dayjs(),
    handleChange: handleChangeDate,
    //maxWidth: '250px',
    setData: obj_fn.setData,
  }
  const handleChangeDateDelivery = (value) => {
    obj_fn.setData((prevState) => ({ ...prevState, date_delivery: dayjs(value) }));
    obj_fn.setData((prevState) => ({ ...prevState, date_delivery_f: dayjs(value).format("YYYY-MM-DD HH:mm:ss") }));
    obj_fn.setData((prevState) => ({ ...prevState, date_delivery_fc: dayjs(value).format("DD/MM/YYYY") }));
  };
  let obj_date_delivery = {
    label: 'Data prevista da entrega',
    field_data: 'date_delivery',
    text_helper: ('Refere-se ao dia previsto para os itens ' + (data.operationType === "Venda" ? 'saírem.' : 'chegarem.')),
    min: data.date_release,
    handleChange: handleChangeDateDelivery,
    //maxWidth: '250px',
    setData: obj_fn.setData,
  }

  return ( <>
    <Grid container sx={{ mb: 4 }}>
      <Grid item xs={12} md={12} alignItems="center" style={{ height: obj_fn.ismobile ? 80 : 60 }} sx={{ mt: -5 }}>
        <Typography style={{ color: '#2e7d32' }}>
          <Typewriter tam={0} minHeight={0} text={`Defina a data e os itens desejados para a ${data.operationType}!\nCaso deseje, rateie o valor para Criações e/ou Safras.`} />
        </Typography>
      </Grid>

      {/* dados gerais */}
      <Grid container spacing={3} sx={{ mb: 0, mt: 2, zIndex: 100 }}>
        <Input />
        <Box sx={{ ml: 1, display: "flex" }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Informações do pedido de {data?.operationType}</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid container spacing={3} sx={{ mb: obj_fn.ismobile ? -2 : -4, mt: -3 }}>
        <Grid item xs={6} sm={2.5}>
          <SingleDateField data={data} obj_date={obj_date} />
        </Grid>
        <Grid item xs={6} sm={2.5}>
          <SingleDateField data={data} obj_date={obj_date_delivery} />
        </Grid>
        <Grid item xs={12} sm={7} sx={{ mt: obj_fn.ismobile ? -3 : 3 }} >
          <AutocompletGenericList value={data.person} options={opt_person} handleChange={handleChangeSelect} />
        </Grid>
      </Grid>

      {/* Seleção dos produtos */}
      <FormSelectAddItens data={data} obj_fn={obj_fn}/>

      <FormTableItens data={data} obj_fn={obj_fn} />
      {!data.products.length && !data.lotes.length && !data.animals.length ? (
        <Box sx={{ mt: -1, ml: 1, display: "flex" }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700} style={{color: "#f44336"}}>Carrinho de {data.operationType} encontra-se vazio!</Typography>
          </Stack>
        </Box>
      ) : <></>}

      {/* Rateio de custos */}
      <FormApportionment data={data} obj_fn={obj_fn} />

      {/* Descrição */}
      <Grid container spacing={3} sx={{ mb: 0, mt: 3 }}>
        <Notes />
        <Box sx={{ ml: 1, display: "flex" }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700} style={{color: "#2e7d32"}}> Descrição geral do Lançamento</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid container spacing={1} sx={{ mb: 1, mt: 0 }}>
        <Grid item xs={12} sm={12} >
          <TextareaAutosize
            minRows={3} // Customize the initial number of rows
            maxRows={10} // Customize the maximum number of rows
            id="description"
            name="description"
            placeholder="Descreva sobre do que se trata o Lançamento"
            value={data.description}
            onChange={handleChange}
            onBlur={(e) => handleChange(e, true)}
            style={{ width: '100%', padding: '8px' }}
          />
        </Grid>
      </Grid>

      <FooterModal
        data={data}
        obj_fn={obj_fn}
        isError={isError}
        returnError={returnError}
      />
    </Grid >
  </> );
}



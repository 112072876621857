import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';

function createData(data, safra, origem, destino, situacao, bruto, impurezas, liquido) {
  return { data, safra, origem, destino, situacao, bruto, impurezas, liquido };
}

const rows = [createData('01/12/2023', 'Safra 22/23', 'Talhão 1', 'Silo 1', 'VENDER', '5.000,00', '5%', '4.000,000')];

export default function TableProductionHarvest({ activity, currentHarvestPatrimony, currentHarvest }) {
  console.log('TableProductionHarvest1');
  console.log(activity);
  console.log(currentHarvestPatrimony);
  console.log(currentHarvest);

  function formatDate(date) {
    return dayjs(date).format('DD/MM/YYYY');
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">data</TableCell>
            <TableCell align="left">Safra</TableCell>
            <TableCell align="right">Origem</TableCell>
            <TableCell align="right">Destino</TableCell>
            {/* <TableCell align="right">Situação</TableCell> */}
            <TableCell align="right">Bruto&nbsp;/kg</TableCell>
            <TableCell align="right">Impurezas</TableCell>
            <TableCell align="right">Líquido&nbsp;/kg</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.data}>
              <TableCell align="left">{formatDate(activity.date_start)}</TableCell>
              <TableCell align="left">
                <i>
                  <img className="mr-1" src="/dist/img_fp/icon-button/icon-agricola-green.png" width="24"></img>
                </i>
                {currentHarvest.name}
              </TableCell>
              <TableCell align="right">{activity.name}</TableCell>
              <TableCell align="right">{currentHarvestPatrimony.name}</TableCell>
              {/* <TableCell align="right">{row.situacao}</TableCell> */}
              <TableCell align="right">{activity.products[0].quant}</TableCell>
              <TableCell align="right">{row.impurezas}</TableCell>
              <TableCell align="right">{row.liquido}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

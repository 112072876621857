import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { NavLink, useParams } from 'react-router-dom';
import ChartBarP from '../../../components/Charts/ChartBarP';
import ChartBarProduction from '../../../components/Charts/ChartBarProduction';
import ChartBarProductivity from '../../../components/Charts/ChartBarProductivity';
import TableProduction from './TableProduction';
import { Divider, Stack } from '@mui/material';
import useHarvestTargetReposStore from '../../../store/useHarvestTargetReposStore';
import useHarvestReposStore from '../../../store/useHarvestReposStore';
import { useState } from 'react';
import { calcularDateProgressBarra } from '../../../components/Calculations/calcularDateProgressBarra';
import TableProductionHarvest from './TableProductionHarvest';
import ButtonRegisterProduction from './ButtonRegisterProductionHarvest';
import ButtonRegisterProductionLivestock from './ButtonRegisterProductionLivestock';
import ButtonRegisterProductionHarvest from './ButtonRegisterProductionHarvest';
import { format } from 'date-fns';
import useStore from '../../../store/useStore';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export default function CardProductionHarvest({ production, type, index }) {
  // instancia os repos
  console.log('CardProduction1');
  console.log(index);
  console.log('xxxxxproductionxxxx');
  console.log(production);
  console.log(type);

  const reposPlanning = useHarvestTargetReposStore((state) => state);
  console.log(reposPlanning);
  console.log(reposPlanning.listPlanning);
  console.log(reposPlanning.planningRepos);
  const planRepos = reposPlanning.getPlanningStore('id', production?.id);
  console.log(planRepos[0]);

  let date_start = '';
  let date_prev_end = '';

  if (planRepos[0]) {
    console.log('true');
    date_start = format(new Date(planRepos[0].date_start), 'dd/MM/yyyy');
    date_prev_end = format(new Date(planRepos[0].date_prev_end), 'dd/MM/yyyy');
  }

  console.log(date_start, date_prev_end);

  const valueProductivity = planRepos[0]?.expectedYield / planRepos[0]?.area || 0;

  const [plan, setPlan] = useState(planRepos[0]);
  const [productivity, setProductivity] = useState(valueProductivity?.toFixed(2));
  console.log(plan);
  console.log(productivity);
  // instancia os repos
  const { id } = useParams();
  const reposHarvest = useHarvestReposStore((state) => state);
  const listHarvests = reposHarvest.getHarvestStore('id', id);
  // parei aqui, conseguir a data de
  //const teste = reposHarvest.getHarvestStore("id_areamap", production.id_areamap);
  //console.log(teste)

  const [dataHarvest, setDataHarvest] = useState(listHarvests[0]);
  console.log(dataHarvest);

  const valueProgress = calcularDateProgressBarra(dataHarvest.data_inicial, dataHarvest.data_fim);
  console.log('');
  console.log(valueProgress.porcentagem);

  const nameAtivity = JSON.parse(localStorage.getItem('creationHarvestRepos'));
  console.log(nameAtivity.label);
  console.log(nameAtivity.tipo_repos);

  //harvest
  const usestore = useStore();
  const listActivityHarvesting = usestore.reposActivityHarvesting.listActivityHarvesting;
  const totalProductQuant = listActivityHarvesting
    .flatMap((h) => h.areas) // Flatten the areas arrays into a single array
    .filter((area) => area.id_area === production.id_areamap) // Filter areas with id_area equal to 3
    .flatMap((area) => area.products) // Flatten the products arrays into a single array
    .reduce((sum, product) => sum + product.quant, 0); // Sum the quant values
  console.log('listActivityHarvesting1');
  console.log(listActivityHarvesting);
  console.log(totalProductQuant);

  // const listActivityHarvestingActive = listActivityHarvesting
  //   .flatMap((h) => h.areas) // Flatten the areas arrays into a single array
  //   .filter((area) => area.id_area === production.id_areamap); // Filter areas with id_area equal to 3

  const listActivityHarvestingActive = listActivityHarvesting
    .flatMap((harvest) => harvest.areas.map((area) => ({ ...area, date_start: harvest.date_start }))) // Add date_start to each area
    .filter((area) => area.id_area === production.id_areamap) // Filter areas with id_area equal to 3
    .map((area) => ({
      ...area,
      totalProductQuant: area.products.reduce((sum, product) => sum + product.quant, 0), // Add totalProductQuant field
    }));

  console.log(listActivityHarvestingActive);

  console.log(listActivityHarvestingActive[0].products[0].id_patrimony);

  const id_patrimony = listActivityHarvestingActive[0].products[0].id_patrimony;

  const currentHarvestPatrimony = usestore.reposPatrimony.getPatrimonyStore('id', id_patrimony)[0];
  console.log('currentHarvestPatrimony');
  console.log(currentHarvestPatrimony);

  const [harvestReadyQuant, setharvestReadyQuant] = useState(totalProductQuant);

  const valueProductivityReady = totalProductQuant / planRepos[0]?.area || 0;

  //console.log(plantedArea);

  const currentHarvest = usestore.reposHarvest.getHarvestStore('id', id)[0];

  return (
    <>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent sx={{ mt: -1 }}>
              <Stack alignItems="center">
                <Stack alignItems="center" direction="row" divider={<Divider />} spacing={1}>
                  <Typography variant="caption" color="text.secondary" component="div">
                    {nameAtivity.tipo_repos == 'Safras' ? date_start : dataHarvest.data_inicial}
                  </Typography>
                  <ChartBarP valueProgress={valueProgress.porcentagem > 0 && valueProgress.porcentagem < 101 ? valueProgress.porcentagem : 0} />
                  <Typography variant="caption" color="text.secondary" component="div">
                    {nameAtivity.tipo_repos == 'Safras' ? date_prev_end : dataHarvest.data_fim}
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Produção
              </Typography>
              <ChartBarProduction expectedYield={parseFloat(plan?.expectedYield) || 0} readyQuant={harvestReadyQuant || 0} unit={plan?.unit || ''} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Produtividade
              </Typography>

              <ChartBarProductivity valueProductivity={valueProductivity.toFixed(2) || 0} valueProductivityReady={valueProductivityReady.toFixed(2) || 0} unit={`${plan?.unit || ''}/ha`} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2, mt: 4 }}>
        <Grid item xs={6} md={6}>
          <Typography variant="h6" gutterBottom>
            Registro {nameAtivity.tipo_repos === 'Safras' ? 'da Colheita' : 'da Produção'}
          </Typography>
        </Grid>
        <Grid item xs={6} md={6} container justifyContent="flex-end">
          <ButtonRegisterProductionHarvest />
        </Grid>
        {listActivityHarvestingActive.map((activity) => (
          <Grid item xs={12}>
            <TableProductionHarvest activity={activity} currentHarvestPatrimony={currentHarvestPatrimony} currentHarvest={currentHarvest} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import AddActivities from './components/AddButtonActivities/AddActivities';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import TutorialaddActivities from './components/tutorial/TutorialaddActivities';
import CardActivitiesHarvests from './components/tab/CardActivitiesHarvests';
import ViewListActivitiesMonitoring from './components/FormActivities/Applications/ViewListActivitiesMonitoring';
import ButtonListStock from '../activities/components/Button/ButtonListStock';

export default function ActivitiesHarvestContent() {
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);

  useEffect(() => {
    //console.log("useEffect=ActivitiesHarvestContent atualiza a tabela se true ");
    //console.log(isLoadingActivities);
    if (isLoadingActivities === true) {
      setIsLoadingActivities(false);
    }
  }, [isLoadingActivities]);

  const nameAtivity = JSON.parse(localStorage.getItem('creationHarvestRepos'));
  //console.log(nameAtivity.label);
  //console.log(nameAtivity);

  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12} md={4}>
              <Box sx={{ display: 'flex' }}>
                <PendingActionsIcon style={{ fontSize: 35 }} />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Atividades de Campo
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                    Registro&nbsp;da&nbsp;realização&nbsp;das&nbsp;atividades
                  </Typography>
                  <Typography fontWeight="400" style={{ color: 'gray' }} variant="subtitle2">
                    Safra: {nameAtivity.label}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box display="flex" justifyContent="flex-start">
                <AddActivities isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} />
              </Box>
            </Grid>
            <Grid item xs={6} sm={1}>
              <Box display="flex" justifyContent="flex-end">
                <ViewListActivitiesMonitoring />
              </Box>
            </Grid>
            <Grid item xs={6} md={1}>
              <ButtonListStock />
            </Grid>
            <Grid item xs={6} md={2}>
              <Box display="flex" justifyContent="flex-end">
                <TutorialaddActivities />
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="content">
        <div className="container-fluid">
          <div className="card">
            <CardActivitiesHarvests />
          </div>
        </div>
      </div>
    </div>
  );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import { Card, CardContent, CardHeader, Container, Divider, Grid, Paper, Typography } from '@mui/material';
import Typewriter from '../../../components/Typewriter/Typewriter';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import useStore from '../../../store/useStore';
import TimelineHorizPhase from '../../planningHarvest/components/GraphPlan/TimelineHorizPhase';
import ChartBarPlantingProductionArea from '../../../components/Charts/ChartBarPlantingProductionArea';
import { useParams } from 'react-router-dom';
import LoadActivityListHarvest from '../../../components/LoadActivityList/LoadActivityListHarvest';
import CharTimeMultiActivityProduction from '../../../components/Charts/CharTimeMultiActivityProduction';
const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

export default function CardProductionInProgress({ area, index }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingText, setIsLoadingText] = useState(true);
  const usestore = useStore();

  console.log('CardProductionInProgress1');
  console.log(area, index);

  // setTimeout(() => {
  //   setIsLoading(false);
  // }, 1000);

  // setTimeout(() => {
  //   setIsLoadingText(true);
  // }, 3000);

  //const property_id = localStorage.getItem("Property") || 1;
  //const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  //const reposHarvest = usestore.reposHarvest.getHarvestStore("id", usestore.reposHarvest.harvestRepos.id)[0];
  //console.log("reposHarvest")
  //console.log(reposHarvest)

  //const listAreas = usestore.reposAreas.listAreasMap;
  //console.log("listAreas1")
  //console.log(listAreas)

  // Crie um array com os IDs das áreas
  //const areaIds = reposHarvest.areas.map((area) => area.id);

  // Use o método filter() para filtrar os elementos de listAreas com IDs presentes em areaIds
  //const listAreasHarvest = listAreas.filter((area) => areaIds.includes(area.id));

  //console.log(listAreasHarvest);

  // const [showComponents, setShowComponents] = useState([]);
  // const [selectedTab, setSelectedTab] = useState(0);

  // const arrayButtons = [];
  // for (const area of listAreasHarvest) {
  //   const item = { name: area.name, active: false, title: area.name, measure: area.measure, area: area.area, image: area.imageDecode, data: area, dataHarvest: reposHarvest };
  //   arrayButtons.push(item);
  // }
  //console.log(arrayButtons)

  // como sei quais são as aŕeas usadas na havesrt
  // const [buttons, setButtons] = useState(arrayButtons);

  // const handleButtonClick = (buttonName, buttonIndex) => {
  //   // console.log();
  //   const updatedButtons = buttons.map((button, index) => {
  //     if (button.name === buttonName) {
  //       button.active = !button.active;
  //     } else {
  //       button.active = false;
  //     }
  //     return button;
  //   });

  //   setButtons(updatedButtons);

  //   const button = buttons.find((button) => button.name === buttonName);

  //   if (button) {
  //     if (button.active) {
  //       setShowComponents([button.name]);
  //       setSelectedTab(buttonIndex);
  //     } else {
  //       setShowComponents([]);
  //       setSelectedTab(0);
  //     }
  //   }
  // };

  //console.log(showComponents);
  //console.log("buttons")
  //console.log(buttons)

  const target_active = area.target.filter((item) => {
    if (item.isActiveTargetHarvest) {
      return item;
    }
  })[0];

  console.log('target_active1');
  console.log(target_active);
  console.log(area);

  //const filteredHarvestArea = usestore.reposActivityPlanting.listActivityPlanting.filter((h) => h.areas.some((a) => a.id_area === 3));
  //const harvest = usestore.reposActivityPlanting.getActivityPlantingStore('id_harvest', id)
  //console.log(filteredHarvestArea);
  const totalUsedArea = usestore.reposActivityPlanting.listActivityPlanting
    .flatMap((h) => h.areas) // Flatten the areas arrays into a single array
    .filter((area) => area.id_area === 3) // Filter areas with id_area equal to 3
    .reduce((sum, area) => sum + area.usedArea, 0); // Sum the usedArea values

  console.log(totalUsedArea);

  let plantedAreaTotal = totalUsedArea > target_active?.area ? target_active?.area : totalUsedArea;

  const [plantedArea, setPlantedArea] = useState(plantedAreaTotal);

  console.log(plantedArea);

  let filters = {
    table_active: 'realized',
    status: 'edit',
  };

  const { idFarm, id } = useParams();
  const currentLivestok = usestore.reposHarvest.getHarvestStore('id', id)[0];

  const loadActivityListLivestock = LoadActivityListHarvest();
  const rows_hv = loadActivityListLivestock.loadList(currentLivestok, filters);
  console.log(rows_hv);
  console.log('rows_hv');

  return (
    <>
      <Card sx={{ mt: 1 }}>
        <CardHeader
          title={
            <>
              <div className="d-lg-none">
                <Box sx={{ display: 'flex', mb: 1 }}>
                  <Typography sx={{ fontSize: 18 }}>Gerenciamento a evolução das etapas de produção:</Typography>
                </Box>
                <Box sx={{ minHeight: 110 }}>
                  <Typography style={{ color: '#2e7d32' }}>
                    {isLoadingText && <Typewriter tam={110} minHeight={0} text="Gerencie o andamento das atividades nas aréas de plantio e de colheita." />}
                  </Typography>
                </Box>
              </div>

              <div className="d-none d-lg-block">
                <Box sx={{ display: 'flex', mb: 1 }}>
                  <Typography variant="h6" color="textPrimary">
                    Gerenciamento a evolução das etapas de produção:
                  </Typography>
                </Box>
                <Box sx={{ minHeight: 30 }}>
                  <Typography style={{ color: '#2e7d32' }}>
                    {isLoadingText && <Typewriter tam={30} minHeight={0} text="Gerencie o andamento das atividades nas aréas de plantio e de colheita." />}
                  </Typography>
                </Box>
              </div>
            </>
          }
        ></CardHeader>
        <Divider />
      </Card>
      <Card sx={{ mt: 1 }}>
        <Container component="main" maxWidth="" sx={{ mb: 0, maxHeight: '260px', overflowY: 'auto' }}>
          <Paper variant="" sx={{ my: { xs: 3, md: 1 }, p: { xs: 1, md: 1 } }}>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid container spacing={2} sx={{ mt: 0 }}>
                <Grid item xs={12} md={12}>
                  <Box mt={0}>
                    <TimelineHorizPhase date_start={target_active?.date_start} date_prev_end={'xx/xx/20xx'} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Card>
      <Grid container spacing={3} sx={{ mt: 2, mb: 4 }}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Plantio
              </Typography>
              <ChartBarPlantingProductionArea expectedYield={target_active?.area} unit={target_active?.measure} plantedArea={plantedArea} />
              {/* <ChartBarProduction expectedYield={parseFloat(plan?.expectedYield) || 0} unit={plan?.unit || ""} /> */}
            </CardContent>
          </Card>
          {/* <Card>
            <CardContent sx={{ mt: -1 }}>
              <Typography variant="h6" gutterBottom>
                Área a colher
              </Typography>
              <Stack alignItems="center">
                <Stack alignItems="center" direction="row" divider={<Divider />} spacing={1}>
                  <Typography variant="caption" color="text.secondary" component="div">
                     {nameAtivity.tipo_repos == "Safras" ? date_start : dataHarvest.data_inicial} 
                  </Typography>
                  <ChartBarPAreaInProgress valueProgress={"10"} />
                  <Typography variant="caption" color="text.secondary" component="div">
                     {nameAtivity.tipo_repos == "Safras" ? date_prev_end : dataHarvest.data_fim} 
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
          </Card> */}
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Custo da Produção
              </Typography>
              {area.cost && <ChartBarProductionCost expectedYield={area.cost?.totalProductionCost || 0} unit={area.cost?.valueCost || ''} />}
            </CardContent>
          </Card>
        </Grid> */}
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Atividades ao longo do tempo
              </Typography>
              {rows_hv.length > 0 && <CharTimeMultiActivityProduction rows={rows_hv} />}
            </CardContent>
          </Card>
        </Grid>

        {/* <ChartBarProductionInProgress expectedYield={"100"} unit={""} />  */}
        {/* <ChartBarProduction expectedYield={parseFloat(plan?.expectedYield) || 0} unit={plan?.unit || ""} /> */}

        {/* <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Produtividade
              </Typography>
              <ChartBarProductivityInProgress valueProductivity={10} unit={`/ha`} />

             <ChartBarProductivity valueProductivity={valueProductivity.toFixed(2) || 0} unit={`${plan?.unit || ""}}/ha`} /> 
            </CardContent>
          </Card>
        </Grid> */}
      </Grid>
    </>
  );
}

//https://aegro.com.br/termos-de-uso/

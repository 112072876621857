import React, { useEffect, useState } from 'react';
import { Box, CardMedia, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Map } from '@mui/icons-material';

import FooterModal from '../../../../../components/Footer/FooterModal';
import AutocompleteGeneric from '../../../../../components/Fields/AutocompletGeneric';
// import CardProduct from './CardProducts';
// import ProductRegistrar from '../../../../products/components/ProductRegistrar/ProductRegistrar';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../../../../components/Loader/ThreeDotsSpinner';
import ChartBarHActvSoul from '../../../../../components/Charts/ChartBarHActvSoul';

export default function FormSoilPreparationStep2({ data, obj_fn }) {
  const [showText, setShowText] = useState(false);
  setTimeout(() => {
    setShowText(true);
  }, 2000);

  useEffect(() => {
    setShowText(false);
  }, []);

  // const para validar o button continuar
  const isError = () => {
    var valid = false;
    if (!data.areas.length) {
      valid = true;
    }
    return valid;
  };
  const returnError = () => {
    return false;
  };

  // Monta o array e obj para o campo de projeto
  const optionsAreas = obj_fn.usestore.reposAreas.getAreaUsedMapStore([
    ['id', data.id_harvest],
    ['tipo', 'harvest'],
  ]);

  const opt_areas = {
    multiple: true,
    name: 'areas',
    label: 'Áreas da Safra',
    values: optionsAreas,
    field: 'areas',
    variant: 'standard',
    helperText: 'Selecione a(s) área(s) para o preparo do solo',
    listHeight: 225,
  };
  const handleChangeArea = (field, newValue) => {
    // console.log('handleChangeArea');
    if (newValue && newValue.length > 0) {
      newValue.map((area) => {
        if (!area.product) {
          area.product = null;
        }
        return area;
      });
    }
    obj_fn.setData((prevState) => ({ ...prevState, [field]: newValue }));
  };

  // const handleChangeInput = (e, area) => {
  //   console.log('handleChangeInput')
  //   //console.log(e.target.value, area)
  //   const usedArea = e.target.value ? parseFloat(e.target.value) : ''

  //   obj_fn.setData((prevState) => ({
  //     ...prevState,
  //     areas: prevState.areas.map((area_prev) => {
  //       if (area_prev.id === area.id) {
  //         return { ...area_prev, usedArea }
  //       }
  //       return area_prev
  //     }),
  //   }))
  // }

  const handleChangeInput = (e, area, isPercentage) => {
    const value = e.target.value ? parseFloat(e.target.value) : '';

    // console.log(value);
    // console.log(area);

    if (!isNaN(value) && area.area !== 0) {
      if (isPercentage) {
        let usedArea = (value * area.area) / 100;
        if (usedArea > area.area) {
          usedArea = area.area;
        }
        // console.log(usedArea);
        obj_fn.setData((prevState) => ({
          ...prevState,
          areas: prevState.areas.map((area_prev) => {
            if (area_prev.id === area.id) {
              return { ...area_prev, usedArea };
            }
            return area_prev;
          }),
        }));
      } else {
        let usedArea = value;
        if (usedArea > area.area) {
          usedArea = area.area;
        }
        obj_fn.setData((prevState) => ({
          ...prevState,
          areas: prevState.areas.map((area_prev) => {
            if (area_prev.id === area.id) {
              return { ...area_prev, usedArea: usedArea };
            }
            return area_prev;
          }),
        }));
      }
    }
  };

  // const handleChange = (event) => {
  //   obj_fn.setData((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  // };

  // const filters_prods = [
  //   ['description', 'agricola'],
  //   ['description', 'todos'],
  // ];

  // console.log(data);
  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        {/* <Grid item xs={12} md={12}>
          <Box display="flex" sx={{ mt: 1 }} justifyContent="flex-end">
            <ProductRegistrar filters={filters_prods} />
          </Box>
        </Grid> */}
        <Grid container spacing={3} sx={{ mb: 0, mt: 2 }}>
          <Map />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Área(s) do preparo do solo</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 0, mt: -2 }}>
          <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
            <AutocompleteGeneric value={data.areas} options={opt_areas} handleChange={handleChangeArea} />
          </Grid>
          {/* <Grid item xs={12} sm={5}>
            <Grid sx={{ mt: obj_fn.isMobile ? -2 : 1, ml: 1, display: 'flex' }}>
              <Stack spacing={0.5} align="center">
                <Typography fontWeight={700} style={{ color: '#ff9800' }}>
                  {!data.areas.length ? 'Adicione pelo menos uma área da safra para o plantio!' : 'Adicione um produto para realizar o plantio na(s) área(s) selecionada(s).'}
                </Typography>
              </Stack>
            </Grid>
          </Grid> */}
        </Grid>
        <Grid container spacing={4} sx={{ mb: 1, mt: 0 }}>
          {data.areas?.map((area) => (
            <>
              <Grid item xs={12} md={12} sx={{ mt: 0 }} alignItems="center">
                <Grid item xs={12} md={12} sx={{ mt: 0 }} alignItems="center">
                  {showText ? (
                    <>
                      <Box display="flex" alignItems="center">
                        <Typewriter
                          text={`Por padrão, definimos a área total como a quantidade de área(${area.measure}) onde o preparo do solo será realizado.\n Caso seja necessário inserir outro valor, defina-o na área utilizada.`}
                          variant={'subtitle1'}
                          color={'#2e7d32'}
                        />
                      </Box>
                    </>
                  ) : (
                    <Box display="flex" alignItems="center">
                      <ThreeDotsSpinner />
                    </Box>
                  )}
                </Grid>
                <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
                  <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                    <CardMedia key={'CM_' + area.id} component="img" sx={{ height: 260 }} image={area.url} alt={area.name} />
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                    <Grid item key={'G2_' + area.id} xs={12} sm={12} sx={{ ml: 1.5 }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Área: <strong>{area.name}</strong>
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom>
                        <i className="fa fa-map text-success">
                          {' '}
                          Totalidade: {area.area} ({area.measure})
                        </i>
                      </Typography>
                      <Grid container spacing={3} sx={{ mb: 0, mt: -2 }}>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                          <TextField
                            sx={{ marginRight: 2 }}
                            id={area.id + '-usedArea-hectares'}
                            name={area.name}
                            label={'Área utilizada (ha):'}
                            variant="standard"
                            size="small"
                            value={area.usedArea ? area.usedArea : area.area}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">{area.measure}</InputAdornment>,
                            }}
                            onChange={(e) => handleChangeInput(e, area, false)}
                            // helperText={area.usedArea > area.area ? 'O valor não pode ser maior que a área total.' : ''}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                          <TextField
                            sx={{ marginRight: 2 }}
                            id={area.id + '-usedArea-percentage'}
                            name={area.name}
                            label={'Área utilizada (%):'}
                            variant="standard"
                            size="small"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            value={area.usedArea ? (area.usedArea / area.area) * 100 : 100}
                            onChange={(e) => handleChangeInput(e, area, true)}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ mt: 1 }}>
                          <ChartBarHActvSoul usedArea={area?.usedArea || area?.area} areaTotal={area?.area} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* <Grid item xs={12} md={12} sx={{ mt: 2 }} alignItems="center">
                <Card key={'C1_' + area.id} sx={{ display: 'flex', padding: 1 }} id="img_plantio">
                  <Grid item key={'G3_' + area.id} xs={12} sm={12}>
                    <CardProduct data={data} obj_fn={obj_fn} area={area} />
                  </Grid>
                </Card>
              </Grid> */}
            </>
          ))}
        </Grid>

        {/* Descrição */}
        {/* <Grid container spacing={3} sx={{ mb: 0, mt: 2 }}>
          <Notes />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
                {' '}
                Descrição/observações da atividade de preparo do solo
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 1, mt: 0 }}>
          <Grid item xs={12} sm={12}>
            <TextareaAutosize
              minRows={3} // Customize the initial number of rows
              maxRows={10} // Customize the maximum number of rows
              id="description"
              name="description"
              placeholder="Informações auxiliares para o preparo do solo"
              value={data.observations}
              onChange={handleChange}
              onBlur={(e) => handleChange(e, true)}
              style={{ width: '100%', padding: '8px' }}
            />
          </Grid>
        </Grid> */}

        <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
      </Grid>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Checkmark } from 'react-checkmark';
import { AppBar, Box, Button, CardMedia, Container, Grid, Paper, Toolbar } from '@mui/material';
import { Span } from '../../../../../components/Typography';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import RedirectMap from './RedirectMap';

export default function SuccesGreen({ handleClose }) {
  const [isLoadingText, setIsLoadingText] = useState(false);

  // const handleButtonClick = () => {
  //   //handleClose();
  //   // navigate("/mapa");
  // };

  useEffect(() => {
    setIsLoadingText(false);
  }, []);

  setTimeout(() => {
    setIsLoadingText(true);
  }, 2000);

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 0, md: 2 } }}>
          <div className="sucess">
            <Checkmark size={'128'} />
            <Typography variant="h6" sx={{ mt: 4 }}>
              Pronto! Projeto Pecuário criado com sucesso.
            </Typography>
            <Typography style={{ color: '#2e7d32' }} variant="h6">
              Bem Vindo a Gestão Pecuária!
            </Typography>

            {/* <Grid item sm={12} xs={12}>
              <CardMedia component="img" width="200" image="/dist/image/gestaopecuaria2.png" alt="Gestão Pecuária" />
            </Grid> */}

            {/* <Typography variant="subtitle1" sx={{ mt: 4 }} >
              Agora com um projeto criado, podemos começar o planejamento ou  gerenciamento da sua criação.
            </Typography> */}

            <>
              <div className="d-lg-none">
                <Box sx={{ flexGrow: 1, height: 80 }}>
                  <Typography style={{ color: '#2e7d32' }} sx={{ mt: 2 }}>
                    {isLoadingText && (
                      <Typewriter
                        tam={110}
                        //text="Com o projeto pecuário criado, é hora de iniciar com um planejamento da meta de produção ou seguir direto para gerenciamento das atividades de campo."
                        text="Agora que você iniciou esta jornada, é hora inciar os registros das atividade de campo."
                      />
                    )}
                  </Typography>
                </Box>
              </div>
              <div className="d-none d-lg-block">
                <Box sx={{ flexGrow: 1, mb: 2, height: 60 }}>
                  <Typography style={{ color: '#2e7d32' }} sx={{ mt: 2 }}>
                    {isLoadingText && <Typewriter tam={90} text="Agora que você iniciou esta jornada, é hora inciar os registros das atividade de campo." />}
                  </Typography>
                </Box>
              </div>
            </>

            {/* <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <SignpostIcon style={{ fontSize: 50, mb: 1 }} />
                <Typography sx={{ mt: 2 }}>Planejamento da Criação</Typography>
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <CardMedia component="img" sx={{ width: "30%" }} image={"/dist/image/entrada_atividade.png"} alt="Live from space album cover" />
                <Typography sx={{ mt: 2 }}>Gerenciamento das Atividades</Typography>
              </Grid>
            </Grid> */}

            <RedirectMap handleClose={handleClose} />
          </div>
        </Paper>
      </Container>
      <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
              <Span>Fechar</Span>
            </Button>
          </Box>
          {/* <Box sx={{ flexGrow: 1, textAlign: "center" }}>

            <Button color="success" variant="contained" type="submit" >
              <Span sx={{ textTransform: "capitalize" }}>Salvar</Span>
            </Button>

          </Box> */}
        </Toolbar>
      </AppBar>
    </>
  );
}

import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { NavLink } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import ChartBarPCost from "../../../components/Charts/ChartBarPCost";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

export default function CardProductionCost() {
  /* <div className="d-lg-none">Esconde em telas maiores que lg</div>
<div className="d-none d-lg-block">Esconde em telas menores que lg</div> */
  return (
    <>
      <div className="d-lg-none">
        <Grid container spacing={3} >
          <Grid item xs>
            <Typography variant="h6" component="div">
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <ChartBarPCost />
          </Grid>
          <Grid item xs>
            <Typography variant="caption" color="text.secondary" component="div">
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className="d-none d-lg-block">
        <Grid container spacing={3} sx={{ mt: 0 }}>
          <Grid item xs>
            <Typography variant="h6" component="div">
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            alignItems="center"
            justifyContent="center"
          >
            <ChartBarPCost />
          </Grid>
          <Grid item xs>
            <Typography variant="h6" component="div">

            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import AddActivities from './components/AddButtonActivities/AddActivities';
import CardActivitiesCreations from './components/tab/CardActivitiesCreations';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
// import CardActivitiesCreationsPlan from './components/tab/CardActivitiesCreationsPlan';
import TutorialaddActivities from './components/tutorial/TutorialaddActivities';
import ButtonListStock from './components/Button/ButtonListStock';
import StartActivityLivestock from './components/tab/StartActivityLivestock';

export default function ActivitiesCreationsContent() {
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);

  useEffect(() => {
    // console.log('useEffect=ActivitiesCreationsContent atualiza a tabela se true ');
    // console.log(isLoadingActivities);
    if (isLoadingActivities === true) {
      setIsLoadingActivities(false);
    }
  }, [isLoadingActivities]);

  const nameAtivity = JSON.parse(localStorage.getItem('creationHarvestRepos'));
  // console.log(nameAtivity.label);
  // console.log(nameAtivity);

  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex' }}>
                <PendingActionsIcon style={{ fontSize: 35 }} />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Atividades de Campo
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                    Registro&nbsp;da&nbsp;realização&nbsp;das&nbsp;atividades
                  </Typography>
                  <Typography fontWeight="400" style={{ color: 'gray' }} variant="subtitle2">
                    {nameAtivity.label}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box display="flex" justifyContent="flex-start">
                <AddActivities isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} />
              </Box>
            </Grid>
            <Grid item xs={6} md={1}>
              <ButtonListStock />
            </Grid>
            <Grid item xs={6} md={1}>
              <Box display="flex" justifyContent="flex-end">
                <StartActivityLivestock />
              </Box>
            </Grid>
            <Grid item xs={6} md={1}>
              <Box display="flex" justifyContent="flex-end">
                <TutorialaddActivities />
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="content">
        <div className="container-fluid">
          <div className="card">
            <CardActivitiesCreations />
            {/* <CardActivitiesCreationsPlan isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react'
import { Box, Container, Grid, Paper, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { ExpandMore, Timeline } from '@mui/icons-material'
import ChartLineGeneric from '../../../components/Charts/ChartLineGeneric'
import CashFlowTable from './CashFlowTable'

function getCategoriesFor(type, date) {
  const base_date = date || new Date()
  const year = base_date.getFullYear()
  const month = base_date.getMonth()
  const lastDay = new Date(year, month + 1, 0).getDate()
  const categories = []

  if (type == 'day') {
    for (let day = 1; day <= lastDay; day++) {
      const date = new Date(year, month, day)
      const dayOfWeek = date.toLocaleString('default', { weekday: 'short' })
      const formattedDate = `${dayOfWeek}:${day.toString().padStart(2, '0')}/${(month + 1).toString().padStart(2, '0')}`
      categories.push(formattedDate)
    }
  } else { // if (type == 'month')
    for (let i = 0; i < 12; i++) {
      const date = new Date(year, i);
      const monthName = date.toLocaleString('default', { month: 'short' });
      categories.push(monthName);
    }
  }

  return categories
}

export default function FinancialContentTabs({ obj_fn }) {

  const tableData = (obj_fn) => {
    const finances_data = obj_fn.usestore.reposCashFlow.getCashFlowStoreCategory('data_mes', (obj_fn.selectedDate.getMonth() +1))
    const finance = finances_data.length > 0 ? JSON.parse(JSON.stringify(finances_data)) : []

    var data = {
      columns: [
        {
          id: 'category',
          label: 'Categoria',
          minWidth: 190,
          align: "center",
        },
        {
          id: 'subcategory',
          label: 'Subcategoria',
          minWidth: 190,
          align: "center",
        },
        {
          id: 'saldo',
          label: 'Saldo mês (R$)',
          minWidth: 110,
          align: "center",
        },
        ...getCategoriesFor('day', obj_fn.selectedDate).map((date) => ({
          id: date,
          label: date.replace(/.*:(.*)/, '$1'),
          minWidth: 100,
          align: "center",
        })),
      ],
      rows: finance.filter((option) => option.category == 'category' || option.saldo != 0),
    }
  
    return data
  }

  const chartData = (obj_fn) => {
    const finances_data = obj_fn.usestore.reposCashFlow.getCashFlowStore('data_mes', (obj_fn.selectedDate.getMonth() +1))
    const finance = finances_data.length > 0 ? JSON.parse(JSON.stringify(finances_data[0])) : []

    var data = {
      colors: ["#2e7d32", "#d32f2f", "#0288d1", "#fb8c00"],
      title: "Fluxo de Caixa",
      receitas: finance?.valores?.entradas || [],
      depesas: finance?.valores?.saidas || [],
      saldo: finance?.valores?.saldo || [],
      saldo_acumulado: finance?.valores?.saldo_acumulado || [],
      // saldo_acumulado: finance?.valores?.saldo_acumulado || [],
      categories: getCategoriesFor('day', obj_fn.selectedDate),
    }

    data.series = [
      {
        name: "Receitas realizada",
        data: data.receitas,
      }, {
        name: "Depesas realizadas",
        data: data.depesas,
      }, {
        name: "Saldo dia",
        data: data.saldo,
      }, {
        name: "Saldo acumulado",
        data: data.saldo_acumulado,
      },
    ]
  
    return data
  }
  const table_data = tableData(obj_fn)
  const chart_data = chartData(obj_fn)

  return (
    <Box sx={{ display: 'flex' }} >
      <Container maxWidth="" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="Gráfico do Fluxo de Caixa" id="accordion_cash_flow">
                <Timeline sx={{ mr: 1 }} />
                <Typography>Gráfico do Fluxo de Caixa</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ChartLineGeneric chart_data={chart_data} />
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12} md={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <CashFlowTable data={table_data} obj_fn={obj_fn}/>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box >
  )
}

import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Container, Paper } from '@mui/material';

import FormHarvestingStep1 from './FormHarvestingStep1';
import FormHarvestingStep2 from './FormHarvestingStep2';
import SuccessDialog from '../../../../../components/Dialog/SuccessDialog';

import { Context } from '../../../../../components/Context/AuthContext';
import useStore from '../../../../../store/useStore';
import api from '../../../../../services/api';
import dayjs from 'dayjs';
import FormProgressSelect from '../FormProgress/FormProgressSelect';
import { IconHarvesting } from '../../../../../components/Icons/harvest/unit/IconHarvesting';
import HeaderModal from '../../../../../components/Header/HeaderModal';
import { PendingActions } from '@mui/icons-material';

export default function MultiStepFormHarvesting({ handleClose, type }) {
  // console.log('MultiStepFormHarvesting');
  const { aux_add, setAuxAdd, isMobile } = useContext(Context);
  const usestore = useStore();
  var userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const property_id = localStorage.getItem('Property') || 1;
  const harvestRepos = usestore.reposHarvest.harvestRepos;

  const handleActivity = () => {
    //seta infos para data
    let this_activity = JSON.parse(JSON.stringify(usestore.reposActivityPlanting.getActivityPlantingStore('id_harvest', harvestRepos.id)[0]));
    this_activity.areas.map((area) => {
      const optionsAreas = usestore.reposAreas.getAreaMapStore('id', area.id_area)[0];
      area.id = optionsAreas.id;
      area.url = optionsAreas.url;
      area.area = optionsAreas.area;
      area.measure = optionsAreas.measure;
      area.products = [{ id: null, name: 'Selecione um produto', location: [], quant_colhida: 0, location_selected: null }];
      //area.products = [];

      return area;
    });

    return this_activity.areas;
  };

  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: property_id,
    id_harvest: harvestRepos.id,
    operationType: 'Colheita',

    areas: handleActivity(),
    /* produto utilizado
      areas.map((area) => {
        area.products -> []
        area.products.map((product, index) => {
          area.products[i]
          area.products[i].quant_colhida
          area.products[i].location_selected
        })
      })
    */
    description: '',

    isDeleted: 0,
    progress_radio: 0,
    planning: 'realized',
    progress: 'A FAZER',
    date_start: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    date_prev_end: dayjs().add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
    // date_end: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    date_end: null,
  });

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (submit) => {
    if (submit != 'submit' && activeStep === steps.length - 1) {
      onSubmit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      handleScroll();
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    handleScroll();
  };

  const handleScroll = () => {
    setTimeout(() => {
      window.jQuery(window.jQuery.find('#focus_top_modal')[0]).parent()[0].scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, 300);
  };

  const validate = () => {
    let valid = true;

    if (!data.areas.length) {
      valid = false;
    } else {
      data.areas?.map((area) => {
        area.products.map((prod, idx) => {
          if (!prod?.id) {
            valid = false;
          } else if (prod?.quant_colhida <= 0) {
            valid = false;
          }
        });
      });
    }
    return valid;
  };

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ['FormProgressSelect', 'FormHarvestingStep1', 'FormHarvestingStep2'];
  const steps_text = [
    'Informe como se encontra o progresso da colheita',
    'Preencha as informações necessárias para a realização da colheita',
    'Pronto! Agora basta conferir as informações da colheita e clicar em confirmar!',
  ];

  const onSubmit = async () => {
    let formOk = validate() || false;

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      const res = await api
        .post('/activityHarvesting/save', data, header)
        .then((response) => {
          //console.log(response.data);
          if (response.data.isSuccess) {
            toast.success('Colheita registrada com sucesso!');
            usestore.reposHarvest.fetch({ id_farm: property_id });
            usestore.reposProducts.fetch({ id_farm: property_id });
            usestore.reposPatrimony.fetch({ id_farm: property_id });
            usestore.reposActivityHarvesting.fetch({ id_property: property_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleNext('submit');
              }, 200);
            });
            usestore.reposFinances.fetch({ id_property: property_id })
            usestore.reposFinances.fetchT({ id_property: property_id })
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao registrar o Colheita, tente novamente mais tarde!');
        });
    } else {
      toast.error('Por favor, preencha os campos obrigatórios!');
    }
  };

  const getStepContent = (step) => {
    const obj_fn = {
      usestore: usestore,
      setData: setData,
      handleNext: handleNext,
      handleBack: handleBack,
      activeStep: activeStep,
      steps: steps,
      handleClose: handleClose,
      onSubmit: onSubmit,
      isMobile: isMobile.any(),
    };

    switch (step) {
      case 0:
        // progresso do colheita
        return <FormProgressSelect data={data} obj_fn={obj_fn} options={options} />;
      case 1:
        // identificadores do colheita
        return <FormHarvestingStep1 data={data} obj_fn={obj_fn} />;
      case 2:
        // confirmação dos dados
        return <FormHarvestingStep2 data={data} obj_fn={obj_fn} />;
      case 3:
        const data_success = {
          distination: 'activitiesHarvest/' + property_id + '/' + harvestRepos.id,
          name: 'Atividade de colheita',
          text_success: 'Acesse a lista de suas atividades de campo para monitorar os manejos agrícolas.',
          distination_title: 'Atividades de campo',
          icon: <PendingActions color="success" style={{ fontSize: 100 }} sx={{ mr: 1, width: '50px', height: '50px' }} />,
        };
        return (
          <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessDialog handleClose={handleClose} data={data_success} />
            </Paper>
          </Container>
        );
      default:
        return 'Não existente';
    }
  };

  const options = {
    title: 'Colheita da Safra',
    txt_msn: steps_text[activeStep],
    icon: <IconHarvesting widthStyles={'55'} />,
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {activeStep != 3 && <HeaderModal options={options} />}
        {getStepContent(activeStep)}
      </Box>
    </>
  );
}

import { useEffect, useState } from 'react';
import { Box, Container, Paper } from '@mui/material';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import useStore from '../../../store/useStore';
import useActivityInputReposStore from '../../../store/Activities/useActivityInputReposStore';
import ScrollToTopOnPageChange from '../../../components/Location/ScrollToTopOnPageChange';
import StartFirstStep from './StartFirstStep';
import StartSecondStep from './StartSecondStep';
import StartThirdStep from './StartThirdStep';
import StartFourthStep from './StartFourthStep';
import usePropertyReposStore from '../../../store/usePropertyReposStore';

export default function StartMultiStepForm({ handleClose }) {
  ScrollToTopOnPageChange();
  const usestore = useStore();
  const reposActivitiesInputs = usestore.reposActivityInput;
  const reposProperty = usePropertyReposStore((state) => state);

  console.log('reposProperty1');
  console.log(reposProperty);
  console.log(reposProperty.propertyRepos);
  console.log(reposProperty.propertyRepos.id);

  const property_id = localStorage.getItem('Property') || 1;
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));

  // atualizar o localstorage
  const fetchTodos = useActivityInputReposStore((state) => state.fetch);

  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: reposProperty.propertyRepos.id,
    nameProperty: reposProperty.propertyRepos.label,
    area: reposProperty.propertyRepos.area || '',
    measure: reposProperty.propertyRepos.measure || 'ha',
    //isDeleted: false,
    //center: { lat: "", lng: "" },
  });

  console.log(data);

  console.log('1-init-MultiStepForm');

  const [activeStep, setActiveStep] = useState(0);
  const [date, setDate] = useState('');
  const [text, setText] = useState('');
  const [selection, setSelection] = useState('');

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleSelectionChange = (event) => {
    setSelection(event.target.value);
  };

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4'];

  const onSubmit = async () => {
    console.log('entrou no submit - MultiStepFormInput');

    if (true) {
      const header = {
        header: {
          'Content-Type': 'application/json',
        },
      };

      //const propertyId = JSON.parse(localStorage.getItem("FarmIdcurrent"));
      // message.id_property = propertyId;
      console.log(data);
      const res = await api.post('/activityInput', data, header);
      //const res = true
      console.log('res');
      console.log(res.data.isSuccess);

      if (res.data.isSuccess) {
        console.log('true');
        toast.success('Entrada salva com sucesso!');
        fetchTodos();
        reposActivitiesInputs.fetch({ id_farm: property_id }).then((res) => {
          console.log('reposActivitiesInputs - fetch - res');
          console.log(res);
          console.log('reposActivitiesInputs.listActivityInput');
          console.log(reposActivitiesInputs.listActivityInput);
        });
      } else {
        toast.error(`Opss.. Ocorreu algum erro ao registrar a entrada de animais,por favor, tente novamente.`);
        console.log('erro');
      }
    }
  };

  const getStepContent = (step) => {
    console.log(step);
    switch (step) {
      case 0:
        return <StartFirstStep data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />;
      case 1:
        return <StartSecondStep data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />;
      case 2:
        return <StartThirdStep data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />;
      case 3:
        return <StartFourthStep data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />;
      default:
        return 'Não existente';
    }
  };

  console.log('2-RENDER');
  return (
    <>
      <Box sx={{ width: '100%' }}>
        {/* // se o activeStep que inci-ase com zero === [0,1,2,3] array de step*/}
        {activeStep === steps.length ? (
          // Chamada do componente final do step (activeStep) para animação
          <Container component="main" maxWidth="md" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              {/* <SuccessInput handleClose={handleClose} /> */}
            </Paper>
          </Container>
        ) : (
          <>
            {/* // Chamada dos componentes setp 1, setp 2....  */}
            {getStepContent(activeStep)}
          </>
        )}
      </Box>
    </>
  );
}

import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography, Stack, InputAdornment, IconButton, MenuItem, TextField } from '@mui/material'
import { Category, GridView } from '@mui/icons-material'

export default function FormAccountSelection({ data, obj_fn, handleChange }) {
  const receita = obj_fn.usestore.reposAccount.getAccountStore('name', 'RECEBER')[0]
  const despesa = obj_fn.usestore.reposAccount.getAccountStore('name', 'PAGAR')[0]

  let aux_ic_acc = (data.operationType == 'Conta a receber' ? receita.id : despesa.id)
  // var regex_r_d = new RegExp('.' + aux_ic_acc + '.|$')
  var regex_r_d = new RegExp(aux_ic_acc + '.?')

  var regex_cat = new RegExp('\"category')
  const category = obj_fn.usestore.reposAccount.getAccountStoreRegex('info', regex_cat).filter((option) => regex_r_d.test(option.hierarchy) || option.mother == aux_ic_acc)
  category.sort((a, b) => a.name.localeCompare(b.name))

  var regex_sub = new RegExp('\"subcategory\\d+')
  const subcategory = obj_fn.usestore.reposAccount.getAccountStoreRegex('info', regex_sub)
  subcategory.sort((a, b) => a.name.localeCompare(b.name))

  return (
    <>
      <Grid container spacing={3} sx={{ mb: 0, mt: -1 }}>
        <Category />
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Selecione a conta</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid container spacing={1} sx={{ mb: 2, mt: 0 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            InputLabelProps={{ style: { color: '#2e7d32' } }}
            required
            id="category"
            name="category"
            label="Categoria"
            fullWidth
            value={data.category}
            onChange={handleChange}
            //onBlur={(e) => handleChange(e, true)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <Category />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            select
            SelectProps={{
              MenuProps: { style: { maxHeight: 225 } },
            }}
            variant="standard"
            color="success"
            helperText="Escolha a Categoria"
          >
            {category.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            InputLabelProps={{
              style: {
                color: !data.category ? '#00000099' : '#2e7d32',
              },
            }}
            id="subcategory"
            name="subcategory"
            label="Subcategoria específica"
            fullWidth
            value={data.subcategory}
            onChange={handleChange}
            //onBlur={(e) => handleChange(e, true)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <GridView />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            select
            SelectProps={{
              MenuProps: { style: { maxHeight: 225 } },
            }}
            variant="standard"
            color="success"
            helperText="Opicional: especifíque a subcategoria"
            disabled={!data.category || !(subcategory.filter((option) => option.mother == data.category)).length}
          >
            {subcategory
              .filter((option) => option.mother == data.category)
              .map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      </Grid>
    </>
  )
}

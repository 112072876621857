import ChartBarHGroupCreations from "../../../components/Charts/ChartBarHGroupCreations";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from "@mui/material";
import { useState } from "react";
import usePlanLivestockReposStore from "../../../store/usePlanLivestockReposStore";

export default function CardCostCreations() {

  const [accordionExpanded, setAccordionExpanded] = useState(false);

  const handleAccordionChange = (event, isExpanded) => {
    setAccordionExpanded(isExpanded);
  };

  // planejamento:

  const reposPlanLivestock = usePlanLivestockReposStore((state) => state);
  console.log("reposPlanLivestock##");
  console.log(reposPlanLivestock);

  console.log(reposPlanLivestock.listPlanLivestock);
  console.log(reposPlanLivestock.planningRepos)
  console.log(reposPlanLivestock.planningRepos.id)
  const planLivestockRepos = reposPlanLivestock.getPlanningStore("id", reposPlanLivestock.planningRepos.id)[0];
  console.log(planLivestockRepos);

  const [values, setValues] = useState(planLivestockRepos)

  return (
    <>
      <div>
        <Card>
          <CardContent>
            <Grid container spacing={2} sx={{ mb: 1.1 }}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <Typography variant="caption" color="text.secondary" component="div">
                      Custo esperado
                    </Typography>
                    <Typography variant="body2" component="div">
                      R$ {values?.totalProductionCost}
                    </Typography>
                    <Typography variant="caption" color="text.secondary" component="div">
                      <strong>{values?.productionCost}</strong> <span style={{ fontSize: "0.8em" }}>{values?.unitSale}</span>{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="caption" color="text.secondary" component="div">
                      Custo Realizado
                    </Typography>
                    <Typography variant="body2" component="div">
                      R$ 0
                    </Typography>
                    <Typography variant="caption" color="text.secondary" component="div">
                      ( R$ 0 ha)
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      <div className="border-top ">
        <Box sx={{ height: accordionExpanded ? 600 : 'auto', mb: 2 }}>
          <Accordion expanded={accordionExpanded} onChange={handleAccordionChange}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Gráfico: Custo Esperado x Custo Realizado</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ChartBarHGroupCreations />
            </AccordionDetails>
          </Accordion>
        </Box>
      </div>
    </>
  );
}

import { useContext, useEffect, useState } from 'react'
import {
  Container,
  Grid,
  TextField,
  Button,
  Box,
  Paper,
  Typography,
  MenuItem,
  Select,
  Stack,
  FormControl,
  InputLabel,
  AppBar,
  Toolbar,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Card,
  CardContent,
  CardMedia,
  Autocomplete,
} from '@mui/material'
import Typewriter from '../../../components/Typewriter/Typewriter'
import ChatPlan from '../components/FormPlan/ChatPlan'
import { Span } from '../../../components/Typography'
import IAIcon from '../../../components/Icons/chat/IAIcon'
import { toast } from 'react-toastify'
import useStore from '../../../store/useStore'
import { format } from 'date-fns'
import SuccessTarget from './SuccessTarget/SuccessTarget'
import api from '../../../services/api'
import { Context } from '../../../components/Context/AuthContext'
import { useParams } from 'react-router-dom'
import { LocationOn } from '@mui/icons-material'
import { grey } from '@mui/material/colors'
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion'

export default function FormTarget1({ areas, data, setData, handleNext, handleBack, activeStep, steps, handleClose }) {
  console.log('FormGoalTargetHarvestGeneral')
  console.log(areas)
  console.log(data)
  // errors.chave define true (property.chave não validada) ou false
  const [errors, setErrors] = useState({})
  // gaurdar o array de erros de cada chave
  const [formError, setFormError] = useState({})

  const handleChange = (event, val_input) => {
    console.log('handleChangeInput')
    console.log(val_input)
    setData((prevState) => ({ ...prevState, [event]: val_input }))
    const area = val_input?.area
    setData((prevState) => ({ ...prevState, area: area }))
    const id_areamap = val_input?.data.id
    setData((prevState) => ({ ...prevState, id_areamap: id_areamap }))
  }

  // const para validar o button continuar, se existem as 2 validações
  const isError = () => {
    if (data.area) {
      return false
    }
    return true
  }

  const returnError = () => {
    return false
  }
  const color = '#2e7d32'

  console.log(data)

  return (
    <>
      {/* {isLoading && <SuccessTarget handleClose={handleClose} />} */}
      <Container maxWidth="sm" sx={{ mb: 10 }}>
        <Grid container sx={{ mt: 2 }}>
          {/* <Grid item xs={10} sm={10}>
            <Typography variant="subtitle1" display="block" gutterBottom sx={{ ml: 2, color: "#808080" }}>
              Em caso de dúvida: clique no ícone IA
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleOpenChat}>
              <IAIcon size={32} />
            </IconButton>
          </Grid> */}
        </Grid>
        <Paper sx={{ p: 3, mt: 0, mb: 5 }}>
          {/* <Typography variant="h4" align="center" gutterBottom>
            Planejamento da Safra de Milho
          </Typography> */}

          <Grid container sx={{ mt: 1, mb: 6 }}>
            <Grid container sx={{ mt: 1, mb: 2 }} className="d-lg-none">
              <Grid item xs={12} md={12} alignItems="center" style={{ height: 80 }}>
                <Typography style={{ color: '#2e7d32' }}>
                  <Typewriter tam={80} minHeight={0} text="Bem-vindo à definição de metas na safra! Agora é o momento de estabelecer sua meta de produção e alcançar novos patamares de sucesso. " />
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 1, mb: 6 }} className="d-none d-lg-block">
              <Grid item xs={12} md={12} alignItems="center" style={{ height: 30 }}>
                <Typography style={{ color: '#2e7d32' }}>
                  <Typewriter tam={30} minHeight={0} text="Bem-vindo à definição de metas na safra! Agora é o momento de estabelecer sua meta de produção e alcançar novos patamares de sucesso. " />
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid container spacing={3} sx={{ mt: 2 }}>
              <Box sx={{ ml: 1, display: 'flex' }}>
                <Stack spacing={0.5}>
                  <Typography fontWeight={500}>Para cada área podemos definir a meta de produção.</Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 2 }}>
              <Box sx={{ ml: 1, display: 'flex' }}>
                <Stack spacing={0.5}>
                  <Typography fontWeight={700}>Selecione a área que desejar definir a meta:</Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid container spacing={1} sx={{ mt: 2 }}>
              <Grid container justifyContent="center" sx={{}} spacing={2}>
                <Grid item xs={12} md={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AutoAwesomeMotionIcon style={{ fontSize: 35 }} sx={{ mr: 1, mt: -3 }} />
                    <Stack spacing={0.5} sx={{ flexGrow: 1 }}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        name="areaSelection"
                        color="success"
                        options={data.areas || ''}
                        value={data.areaSelection} // Defina um valor padrão, como `null`, para evitar valores indefinidos
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Card sx={{ display: 'flex', width: '100%' }}>
                              <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                  {/* <img src={option.icon} alt="Icon" style={{ height: "32px", marginLeft: "8px" }} /> */}
                                  <Stack spacing={0.5}>
                                    <Typography fontWeight={700}>{option.name}</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                      <LocationOn sx={{ color: grey[500] }} /> {option.name}
                                    </Typography>
                                  </Stack>
                                </CardContent>
                              </Box>
                              <CardMedia
                                component="img"
                                sx={{
                                  width: 120,
                                  height: 120,
                                  // objectFit: "none",
                                  // objectPosition: "center",
                                }}
                                image={option.image}
                                alt="Area Image"
                              />
                            </Card>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth required name="areaSelection" color="success" label="Selecione a Área de Produção" variant="standard" value={data.lote} />
                        )}
                        onChange={(e, value) => handleChange('areaSelection', value)}
                        noOptionsText="Sem resultados"
                      />
                      <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                        <Typography variant="caption" dis0lay="block" gutterBottom sx={{ ml: 1, color: '#808080' }}>
                          Cada área tem suas próprias metas.
                        </Typography>
                      </Grid>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                <Span>cancelar</Span>
              </Button>
            </Box>
            {data && (
              <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                <Button onClick={handleBack} color="success" sx={{ marginRight: 2 }} disabled={returnError()}>
                  Retornar
                </Button>
                {/* O botão Confirmar e Salvar, leva para tela successo */}
                <Button color="success" variant="contained" type="submit" onClick={!isError() ? handleNext : () => null} disabled={isError()}>
                  {activeStep === steps.length - 1 ? 'Confirmar e Salvar' : 'Continuar'}
                </Button>
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </Container>
    </>
  )
}

import React, { useEffect, useState } from "react"
import { Box, Container, IconButton, Grid, Typography, Button, CardContent, Card, Accordion, AccordionDetails, AccordionSummary, } from "@mui/material"
import { ArrowBackIosNew, BarChart, ExpandMore } from "@mui/icons-material"
import dayjs from "dayjs"
import ChatSidebar from "./ChatSidebar/ChatSidebar"
import { formatValue } from "../../../utils/functions"
// import ChartLineVFinancialDay from "../../../components/Charts/ChartLineVFinancialDay"
// import ChartBarVFinancial from "../../../components/Charts/ChartBarVFinancial"

export default function FinancialAccounts({ obj_fn }) {
  const dados_finance = obj_fn.usestore.reposFinances.financesRepos

  return (
    <Box sx={{ display: "flex" }}>
      <ChatSidebar />
      <Container maxWidth='' sx={{ mb: 3 }}>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12} md={12} lg={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls='Efetivados no ano' id='financeiro_geral' >
                <Typography>Efetivados no ano</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3} >
                  <Grid item xs={12} md={6} lg={4}>
                    <Card sx={{ height: "100%" }}>
                      <CardContent>
                        <Typography variant='h6'>
                          Receitas
                        </Typography>
                        <Typography variant='h4' color='success.main' >
                          {formatValue(dados_finance.entradas, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Card sx={{ height: "100%" }}>
                      <CardContent>
                        <Typography variant='h6'>
                          Depesas
                        </Typography>
                        <Typography variant='h4' color='error.main' >
                          {formatValue(dados_finance.saidas, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Card sx={{ height: "100%" }}>
                      <CardContent>
                        <Typography variant='h6'>
                          Saldo
                        </Typography>
                        <Typography variant='h4' color='primary' >
                          {formatValue(dados_finance.saldo, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        {/* Cards Receitas */}
        <Grid container spacing={3} sx={{ mt: 1, mb: 1 }}>
          <Grid item xs={12} md={12} lg={12}>
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls='A Receber' id='tag_recebimentos' >
                <Typography>A Receber</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3} >
                  <Grid item xs={12} md={6} lg={3}>
                    <Card sx={{ height: "100%" }} aria-controls='Hoje'>
                      <CardContent>
                        <Typography variant='h6'>
                          Hoje
                        </Typography>
                        <Typography variant='h5' color='success.main'>
                          {formatValue(dados_finance.receber_hoje, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Card sx={{ height: "100%" }} aria-controls='Este Mês'>
                      <CardContent>
                        <Typography variant='h6'>
                          Este Mês
                        </Typography>
                        <Typography variant='h5' color='success.main'>
                          {formatValue(dados_finance.receber_mes, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Card sx={{ height: "100%" }} aria-controls='Em atraso'>
                      <CardContent>
                        <Typography variant='h6'>
                          Em atraso
                        </Typography>
                        <Typography variant='h5' color='primary'>
                          {formatValue(dados_finance.receber_atrasado, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Card sx={{ height: "100%" }} aria-controls='Futuros'>
                      <CardContent>
                        <Typography variant='h6'>
                          Futuros
                        </Typography>
                        <Typography variant='h5' color='secondary.dark'>
                          {formatValue(dados_finance.receber_futuro, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        {/* Cards Despesas */}
        <Grid container spacing={3} sx={{ mt: 1, mb: 2 }}>
          <Grid item xs={12} md={12} lg={12}>
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls='A pagar' id='tag_pagamentos' >
                <Typography>A pagar</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3} >
                  <Grid item xs={12} md={6} lg={3}>
                    <Card sx={{ height: "100%" }} aria-controls='Hoje'>
                      <CardContent>
                        <Typography variant='h6'>
                          Hoje
                        </Typography>
                        <Typography variant='h5' color='error.main' >
                          {formatValue(dados_finance.pagar_hoje, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Card sx={{ height: "100%" }} aria-controls='Este Mês'>
                      <CardContent>
                        <Typography variant='h6'>
                          Este Mês
                        </Typography>
                        <Typography variant='h5' color='error.main' >
                          {formatValue(dados_finance.pagar_mes, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Card sx={{ height: "100%" }} aria-controls='Em atraso'>
                      <CardContent>
                        <Typography variant='h6'>
                          Em atraso
                        </Typography>
                        <Typography variant='h5' color='primary'>
                          {formatValue(dados_finance.pagar_atrasado, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Card sx={{ height: "100%" }} aria-controls='Futuros'>
                      <CardContent>
                        <Typography variant='h6'>
                          Futuros
                        </Typography>
                        <Typography variant='h5' color='secondary.dark'>
                          {formatValue(dados_finance.pagar_futuro, true)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        {/* Recent Orders */}
        {/* <Grid container spacing={3} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls='Fluxo de Caixa' id='fluxo_caixa_p' >
                <TimelineIcon sx={{ mr: 1 }} />
                <Typography>Fluxo de Caixa</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <ChartLineVFinancialDay financas={financas} />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={6}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls='Gráfico de Vendas' id='grafico_vendas_p' >
                <BarChart sx={{ mr: 1 }} />
                <Typography>
                  Gráfico de Vendas
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <ChartBarVFinancial financas={financas} />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid> */}
      </Container>
    </Box>
  )
}

import { Box, Grid, Typography, Stack, Button, AppBar, Toolbar, ListItem, ListItemText, List, ListItemAvatar, ButtonBase, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect } from 'react';
import Typewriter from '../../../components/Typewriter/Typewriter';
import { Span } from '../../../components/Typography';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';
import { AccountTree, Spa } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import IconsLivestockActivities from '../../../components/Icons/IconsLivestockActivities';
import { Context } from '../../../components/Context/AuthContext';
import useStore from '../../../store/useStore';
import { useState } from 'react';

const useStyles = makeStyles({
  // selected: {
  //     border: "2px solid green",

  // },
  button: {
    width: '100%',
    height: '100%',
  },
  img: {
    height: 'auto',
  },
});

export default function StartFiveStep({ data, setData, handleNext, handleBack, activeStep, steps, handleClose }) {
  console.log('StartFiveStep');
  console.log(data);

  const navigate = useNavigate();
  const { aux_add, setAuxAdd } = useContext(Context);
  const usestore = useStore();

  const handleButtonClickLivestock = () => {
    usestore.reposProperty.fetch().then((resp) => {
      //setAuxAdd(aux_add + 1);
      // setTimeout(() => {
      //   handleNext();
      //   // setTimeout(() => {
      //   //   setAuxAdd(aux_add + 1);
      //   // }, 5000);
      // }, 200);
      navigate('/livestock');
      usestore.reposProperty.fetch().then((resp) => {});
    });
  };
  const handleButtonClickHarvest = () => {
    navigate('/harvest');
    //usestore.reposProperty.changePropertyRepos({ label: property_address.name, id: property_address.id });
    usestore.reposProperty.fetch().then((resp) => {});
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const para validar o button continuar, se existem as 2 validações
  const isError = () => {
    // if (!data.nameBatch || data.nameBatch.trim() === "") {
    //     return true;
    // }
  };

  // para validar o input
  // const validateProjectName = (value) => {
  //     return value.trim().length > 0;
  // };

  const returnError = () => {
    return false;
  };

  const color = '#2e7d32';

  const classes = useStyles;

  const text =
    'Para termos um excelente gerenciamento da sua propriedade, primeiramente precisamos definir a área de toda a sua propriedade e depois, as áreas onde alocaremos as criações e as safras. Clique em continuar.';

  //show texto
  const [showTextFase, setShowTextFase] = useState(false);
  setTimeout(() => {
    setShowTextFase(true);
  }, 2000);

  return (
    <>
      <Grid container sx={{ mb: 0 }}>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <img src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="AdminLTE Logo" className="brand-image" style={{ opacity: '.8', height: 32, maxWidth: '100%' }} />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5} sx={{ mt: 0 }}>
              <Typography variant="h6" fontWeight={500}>
                Fazenda Premium
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container className={classes.root} sx={{ mt: 4 }} style={{ height: 'auto', maxWidth: '100%' }}>
          <Grid container sm={6} xs={12}>
            <Grid container style={{ padding: '2%' }}>
              <Grid item sm={12} xs={12}>
                {/* <Typography variant="h5" color="textPrimary" gutterBottom>
                  Excelente!!!
                </Typography> */}
                {/* <Typography variant="h6" fontWeight={500}>
                  Próximos passos no Fazenda Premium:
                </Typography> */}
              </Grid>

              <Grid item sm={12} xs={12}>
                <img src="/dist/image/projeto_criar.png" style={{ height: 'auto', maxWidth: '100%', borderRadius: '10px' }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ padding: '2%' }} sm={6} xs={12}>
            <Grid item sm={12} xs={12}>
              <Typography paragraph variant="subtitle1" style={{ color: '#2e7d32' }} fontWeight={500}>
                De posse de um projeto inciaremos a gestão pecuário ou gestão agrícola.
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Grid item sm={12} xs={12}>
                <Typography gutterBottom sx={{ mt: -2 }} fontWeight={500} height={'4px'} style={{ color: '#2e7d32' }}>
                  {showTextFase && <Typewriter tam={4} text="Comece selecionando a principal atividade da propriedade: clique em Criação ou Safra." />}
                </Typography>
              </Grid>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <ButtonBase
                  component={Paper}
                  elevation={2}
                  sx={{ p: 2, borderRadius: 1, flex: 1, margin: '0 5px', animation: `${expandAndFade} 2s linear infinite` }}
                  onClick={() => handleButtonClickLivestock()}
                  className={classes.button}
                >
                  <Stack direction="column" alignItems="center">
                    <IconsLivestockActivities activity={'Geral'} widthStyles={'30px'} />
                    <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                      Criações
                    </Typography>
                  </Stack>
                </ButtonBase>
                <ButtonBase
                  component={Paper}
                  elevation={2}
                  sx={{ p: 2, borderRadius: 1, flex: 1, margin: '0 5px', animation: `${expandAndFade} 2s linear infinite` }}
                  onClick={() => handleButtonClickHarvest()}
                  className={classes.button}
                >
                  <Stack direction="column" alignItems="center">
                    <Spa />
                    <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                      Safras
                    </Typography>
                  </Stack>
                </ButtonBase>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid container className={classes.root} sx={{ mt: 2 }} style={{ height: 'auto', maxWidth: '100%' }}>
          <Grid container sm={12} xs={12}>
            <Grid container style={{ padding: '4%' }}>
              <Grid item sm={12} xs={12}>
                <div className="d-lg-none">
                  <Typography gutterBottom sx={{ mt: 1, mb: 3 }} fontWeight={500} style={{ color: '#2e7d32' }}>
                    <Typewriter tam={70} text="Exlente! Agora precisamso criar um projeto pecuário ou agrícola.  " />
                  </Typography>
                </div>

                <div className="d-none d-lg-block">
                  <Typography gutterBottom sx={{ mt: 0, mb: 3 }} fontWeight={500} style={{ color: '#2e7d32' }}>
                    <Typewriter tam={40} text="Por fim, registraremos a sua primeira safra ou criação, criando um projeto pecuário ou agrícola de sucesso." />
                  </Typography>
                </div>

                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  <Typography paragraph variant="subtitle1" fontWeight={500} sx={{ mt: 1, mb: 2 }}>
                    Para prosseguir, clique no botão "Criações" para acessar a página onde você poderá iniciar um projeto pecuário para seus animais ou clique em "Safras" para começar a criação de um
                    projeto agrícola.
                  </Typography>
                </List>

                
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}

        <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              {/* <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                <Span>cancelar</Span>
              </Button> */}
            </Box>
            {data && (
              <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                <Button onClick={handleBack} color="success" sx={{ marginRight: 2 }} disabled={returnError()}>
                  Retornar
                </Button>
                {/* O botão Confirmar e Salvar, leva para tela successo */}
                {/* <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} color="success" variant="contained" type="submit" onClick={handleClose}>
                  <Span>Continuar</Span>
                </Button> */}
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </Grid>
    </>
  );
}

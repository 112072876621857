import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { NavLink, useParams } from 'react-router-dom';
import ChartBarP from '../../../components/Charts/ChartBarP';
import ChartBarProduction from '../../../components/Charts/ChartBarProduction';
import ChartBarProductivity from '../../../components/Charts/ChartBarProductivity';
import TableProduction from './TableProduction';
import { Divider, Stack } from '@mui/material';
import { useState } from 'react';
import { calcularDateProgressBarra } from '../../../components/Calculations/calcularDateProgressBarra';
import ButtonRegisterProductionLivestock from './ButtonRegisterProductionLivestock';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import { useEffect } from 'react';
import useStore from '../../../store/useStore';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export default function CardProductionLivestock({ plan, type }) {
  // instancia os repos
  console.log('CardProductionLivestock');
  console.log('plan');
  console.log(plan);

  const date_start_format = dayjs(plan.date_start).format('DD/MM/YYYY');
  const date_prev_end_format = dayjs(plan.date_prev_end).format('DD/MM/YYYY');
  //console.log(date_start_format)
  // console.log(date_prev_end_format)

  const valueProgress = calcularDateProgressBarra(date_start_format, date_prev_end_format);
  console.log(valueProgress);

  console.log('plan');

  console.log(plan);

  const valueProductivity = (plan?.expectedYield - plan?.animalInput) / valueProgress.totalDias;

  console.log(valueProductivity, plan?.expectedYield, plan?.animalInput, valueProgress.totalDias);

  // "Outra"
  const [production, setProduction] = useState(plan?.expectedYield || 0);
  const [timeUnit, setTimeUnit] = useState(plan?.unitTime); // 'semana', '15 dias', 'mês', 'ano'
  const [productivity, setProductivity] = useState(0);

  console.log(production);

  const calculateProductivity = () => {
    let days;
    switch (timeUnit) {
      case 'dia':
        days = 1;
        break;
      case 'semana':
        days = 7;
        break;
      case '15 dias':
        days = 15;
        break;
      case 'mês':
        days = 30;
        break;
      case 'ano':
        days = 365;
        break;
      default:
        days = 1;
    }

    const dailyProductivity = production / days;
    console.log('dailyProductivity');
    console.log(dailyProductivity);
    setProductivity(dailyProductivity.toFixed(2));
  };

  useEffect(() => {
    calculateProductivity();
  }, []);

  // //Livestock
  // // instancia os repos planning
  // const reposTargetLivestock = useLivestockTargetReposStore((state) => state);
  // let reposPlan = reposTargetLivestock.listPlanning.filter((itemPlan) => itemPlan.id_livestock == id)
  // console.log(reposPlan)
  // let reposResult = reposPlan.filter((itemPlan) => itemPlan.typePlanning == "Corte" && itemPlan.id_livestock == id)
  // console.log(reposResult)
  const { idFarm, id } = useParams();
  const usestore = useStore();

  //cria
  const [readyQuantFarmed, setReadyQuantFarmed] = useState(0);
  // outro
  const [readyQuantOther, setReadyQuantOther] = useState(0);

  //corte
  // pegar a media de pesso dos animais em contrução/ não vi execeçoes ainda
  const currentLivestok = usestore.reposLivestock.getLivestockStore('id', id)[0];
  console.log(currentLivestok);
  console.log(currentLivestok.lotes[0].weight);
  const [readyQuantBeef, setReadyQuantBeef] = useState(currentLivestok.lotes[0].weight || 0);

  const valueProductivityReadyBeef = (currentLivestok.lotes[0].weight - plan?.animalInput) / valueProgress.diasPassados;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent sx={{ mt: -1 }}>
              <Stack alignItems="center">
                <Stack alignItems="center" direction="row" divider={<Divider />} spacing={1}>
                  <Typography variant="caption" color="text.secondary" component="div">
                    {/* {nameAtivity.tipo_repos == "Safras" ? dataHarvest?.data_inicial : ""} */}
                    {date_start_format}
                  </Typography>
                  <ChartBarP valueProgress={valueProgress.porcentagem > 0 && valueProgress.porcentagem < 101 ? valueProgress.porcentagem : 0} />
                  <Typography variant="caption" color="text.secondary" component="div">
                    {/* {nameAtivity.tipo_repos == "Safras" ? dataHarvest && dataHarvest?.data_fim : ""} */}
                    {date_prev_end_format}
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Produção
              </Typography>

              {type == 'Cria' && <ChartBarProduction expectedYield={parseFloat(plan?.bezerros) || 0} readyQuant={readyQuantFarmed || 0} unit={plan?.unit || ''} />}
              {type == 'Corte' && <ChartBarProduction expectedYield={parseFloat(plan?.expectedYield) || 0} readyQuant={readyQuantBeef || 0} unit={plan?.unitWeight || ''} showRealized={true} />}
              {type == 'Outra' && <ChartBarProduction expectedYield={parseFloat(plan?.expectedYield) || 0} readyQuant={readyQuantOther || 0} unit={plan?.unit || ''} />}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Produtividade
              </Typography>
              {type == 'Cria' && <ChartBarProductivity valueProductivity={valueProductivity.toFixed(2) || 0} valueProductivityReady={0} unit={'kg/dia'} />}
              {type == 'Corte' && (
                <ChartBarProductivity valueProductivity={valueProductivity.toFixed(2) || 0} valueProductivityReady={valueProductivityReadyBeef.toFixed(2) || 0} unit={'kg/dia'} showRealized={true} />
              )}
              {type == 'Outra' && <ChartBarProductivity valueProductivity={productivity || 0} valueProductivityReady={0} unit={plan?.unit + '/dia'} />}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2, mt: 4 }}>
        <Grid item xs={6} md={6}>
          <Typography variant="h6" gutterBottom>
            {/* Registro {nameAtivity.tipo_repos === "Safras" ? "da Colheita" : "da Produção"} */}
          </Typography>
        </Grid>
        <Grid item xs={6} md={6} container justifyContent="flex-end">
          <ButtonRegisterProductionLivestock />
        </Grid>
        <Grid item xs={12}>
          <TableProduction />
        </Grid>
      </Grid>
    </>
  );
}

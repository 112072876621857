import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Card, CardHeader, Container, Divider, Grid, Paper, Skeleton, TableCell, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import Typewriter from '../../../components/Typewriter/Typewriter';

export default function TablesPatrimonyCardEmpty({ isLoadingActivities, setIsLoadingActivities }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingText, setIsLoadingText] = useState(false);
  // const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  // console.log('userCurrent')
  // console.log(userCurrent)
  // const user = userCurrent;
  // console.log(user)
  // const name = user.name;
  // const charName = name[0].toUpperCase();
  // console.log(charName);
  // function stringAvatar(name) {
  //     return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
  // }
  setTimeout(() => {
    setIsLoading(false);
  }, 1000);

  setTimeout(() => {
    setIsLoadingText(true);
  }, 3000);

  return (
    <>
      {isLoading === true ? (
        <>
          <Skeleton variant="rectangular" width="100%" height={550} />
        </>
      ) : (
        <>
          <Card sx={{}}>
            <CardHeader
              title={
                <>
                  <div className="d-lg-none">
                    {' '}
                    <Box sx={{ display: 'flex', mb: 1 }}>
                      <Typography sx={{ fontSize: 18 }}>Vamos registrar ou visualizar todos os seus patrimônios?</Typography>
                    </Box>
                    <Box sx={{ minHeight: 140 }}>
                      <Typography style={{ color: '#2e7d32' }}>
                        {isLoadingText && <Typewriter tam={130} minHeight={0} text="Clique no botão verde (NOVO PATRIMÔNIO) no canto superior direito, ou realize a pesquisa." />}
                      </Typography>
                    </Box>
                  </div>

                  <div className="d-none d-lg-block">
                    <Box sx={{ display: 'flex', mb: 1 }}>
                      <Typography variant="h6" color="textPrimary">
                        Vamos registrar ou visualizar todos os seus patrimônios?
                      </Typography>
                    </Box>
                    <Box sx={{ minHeight: 35 }}>
                      <Typography style={{ color: '#2e7d32' }}>
                        {isLoadingText && <Typewriter tam={30} minHeight={0} text="Clique no botão verde (NOVO PATRIMÔNIO) para cadastrar ou realize a pesquisa." />}
                      </Typography>
                    </Box>
                  </div>
                </>
              }
            ></CardHeader>

            <Divider />
          </Card>
          <Card sx={{ m: 0 }}>
            <Container component="main" maxWidth="sm" sx={{ mb: 12 }}>
              <Paper
                variant=""
                sx={{
                  my: { xs: 3, md: 1 },
                  p: { xs: 2, md: 1 },
                }}
              >
                <Box sx={{ textAlign: 'center' }}>
                  <Grid container xs={12} md={12} sx={{ m: -2 }} item alignItems="center" justifyContent="center">
                    <img src="/dist/image/assinatura.png" name=""></img>
                  </Grid>
                  {/* <Grid container xs={12} md={12} sx={{ mt: 1 }} item alignItems="center" justifyContent="center">
                                        <AddActivities isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} />
                                    </Grid> */}
                  <Grid container xs={12} md={12} sx={{ m: 1 }} item alignItems="center" justifyContent="center">
                    <Typography sx={{ fontSize: 22 }} color="text.secondary" gutterBottom>
                      Gestão do Patrimônio
                    </Typography>

                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      Ao registrar seu patrimônio no software, você ganha acesso a um relatório completo de todos os bens em sua propriedade, incluindo análises detalhadas sobre a depreciação de seus
                      ativos. Este processo é essencial, pois permite um acompanhamento preciso dos custos associados a cada patrimônio, que são uma parte importante das despesas de produção da
                      fazenda.
                    </Typography>
                  </Grid>
                </Box>
              </Paper>
            </Container>
          </Card>
        </>
      )}
    </>
  );
}

//https://aegro.com.br/termos-de-uso/

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FormLivestockProperty from "./Form/FormLivestockProperty";
import Box from "@mui/material/Box";
import { Span } from "../../../components/Typography";
import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";
import { toast } from "react-toastify";
import api from "../../../services/api";
import useStore from "../../../store/useStore";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LivestockPropertyButton({ openModal, setOpenModal }) {
  const [open, setOpen] = React.useState(false);

  const usestore = useStore();
  const propertyRepos = usestore.reposProperty.propertyRepos;
  const propertyActive = usestore.reposProperty.getPropertyStore('id', propertyRepos.id)[0];
  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));

  const [property, setProperty] = React.useState({
    name: propertyActive.name,
    id_user: propertyActive.id_user || userCurrent.id,
    area: propertyActive.area,
    measure: propertyActive.measure,
    ownerName: propertyActive.ownerName,
    address: propertyActive.address,
    tel: propertyActive.tel,
    description: propertyActive.description,
  });
  console.log(property);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpenModal(false)
    setOpen(false);
  };

  React.useEffect(() => {
    if (openModal === false) {
      console.log("useEffect false")
      setOpen(false)
    } else if (openModal === true) {
      console.log("useEffect true")
      setOpen(true)
    }
    //setOpen(true)
  }, [openModal])

  // errors.chave define true (property.chave não validada) ou false
  const [errors, setErrors] = useState({});
  // gaurdar o array de erros de cada chave
  const [formError, setFormError] = useState({});
  //const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(property);

    setFormError({});
    let errors = {};
    let formOk = true;

    console.log(property.area);
    if (!property.area) {
      errors.area = true;
      formOk = false;
      // } else if (property.address.length < 3) {
    } else if (!property.address) {
      errors.address = true;
      formOk = false;
    }

    if (formOk) {
      const header = {
        header: {
          "Content-Type": "application/json",
        },
      };
      const response = await api.post("/property", property, header);

      //const response = await api.get("property/list", header);
      console.log(response.data);
      console.log(response.data.isSuccess);

      if (response) {
        handleClose();
      }
    } else {
      // escreve mensagem de erro para cliente
      toast.error("Por favor, preencha os campos obrigatórios");
      setFormError(errors);

      //return false;
    }

    const isValid = false;
    if (isValid) {
      handleClose();
    }
  };

  return (
    <div>
      {/* <Fab
        sx={{ position: "fixed", top: 80, right: 10 }}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClickOpen}
        color="success"
        aria-label="add"
        variant="extended"
      >
        <AddIcon />
        Propriedade
      </Fab> */}

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Box>
          <AppBar elevation={1} sx={{ position: "relative" }} style={{ background: "#fff" }}>
            <Toolbar>
              <Typography sx={{ color: "#000", flexGrow: 1, textAlign: "center" }} variant="h6" component="div">
                Registrar Propriedade
              </Typography>
              <Typography sx={{ flexGrow: 1, textAlign: "start" }} variant="h6" component="div"></Typography>
              <IconButton edge="center" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon sx={{ color: "#000" }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <FormLivestockProperty formError={formError} handleClose={handleClose} property={property} setProperty={setProperty} onSubmit={onSubmit} />
          <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
            <Toolbar>
              <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                  <Span>cancelar</Span>
                </Button>
              </Box>
              <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                <Button color="success" variant="contained" type="submit" onClick={onSubmit}>
                  <Span>Enviar</Span>
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      </Dialog>
    </div>
  );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, ButtonBase, Card, CardHeader, Chip, Container, Divider, Grid, Paper, Skeleton, Stack, Switch, TableCell, TableRow, Typography } from '@mui/material';
import { Span } from '../../components/Typography';
import MenuPlan from '../signature/components/MenuPlan';
import Typewriter from '../../components/Typewriter/Typewriter';
import AddActivities from '../activities/components/AddButtonActivities/AddActivities';
import { useState } from 'react';
import InputIcon from '@mui/icons-material/Input';
import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import CardMeatProduction from './components/MeatProduction/CardMeatProduction';
import CreationsTabsPlanning from './components/CreationsTabsPlanning';
import CardMeatProductionCreation from './components/MeatProduction/CardMeatProductionCreation';
import CardMeatProductionCut from './components/MeatProduction/CardMeatProductionCut';
import { IconBeefCattle } from '../../components/Icons/unit/IconBeefCattle';
import useLivestockTargetReposStore from '../../store/useLivestockTargetReposStore';
import useStore from '../../store/useStore';
import { useParams } from 'react-router-dom';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import CardMeatProductionOther from './components/MeatProduction/CardMeatProductionOther';
import { expandAndFade } from '../../components/Animations/animationKeyFrames';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

export default function PlanningLivestockContentchoice({ listPlanning, isLoadingActivities, setIsLoadingActivities }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingText, setIsLoadingText] = useState(false);

  const { idFarm, id } = useParams();

  console.log(listPlanning);
  console.log(listPlanning);

  // const usestore = useStore();
  // const currentLivestok = usestore.reposLivestock.getLivestockStore('id', id)[0];

  // console.log(currentLivestok)

  const [typePlanning, setTypePlanning] = useState('');

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    const timer2 = setTimeout(() => {
      setIsLoadingText(true);
    }, 3000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  const [showComponents, setShowComponents] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [buttons, setButtons] = useState([
    { name: 'Cria', active: false, title: 'Cria', icon: <FlagCircleIcon style={{ fontSize: 50 }} /> },
    { name: 'Corte', active: false, title: 'Corte', icon: <FlagCircleIcon style={{ fontSize: 50 }} /> },
    // { name: "Corte", active: false, title: "Corte", icon: <IconBeefCattle widthStyles={80} /> },
    // { name: "Leite", active: false, title: "Leite", icon: <InputIcon style={{ fontSize: 50 }} /> },
    // { name: "Derivados", active: false, title: "Derivados", icon: <InputIcon style={{ fontSize: 50 }} /> },
    { name: 'Outra', active: false, title: 'Producão', icon: <FlagCircleIcon style={{ fontSize: 50 }} /> },
  ]);

  console.log(buttons);

  const [loading, setLoading] = useState(true);

  const toggleButtonActive = (buttonName) => {
    setButtons((prevButtons) =>
      prevButtons.map((button) => {
        if (button.name === buttonName) {
          return { ...button, active: !button.active };
        } else {
          return button;
        }
      })
    );
  };

  // instancia os repos planning
  const reposPlanning = useLivestockTargetReposStore((state) => state);
  console.log('reposPlanning1xxxxx');
  console.log(reposPlanning);

  const activePlan = reposPlanning.listPlanning.filter((active) => active.isActiveTargetLivestock === 1)[0];
  //console.log(activePlan)
  //console.log(activePlan.typePlanning)

  console.log(reposPlanning.planningRepos.id);
  const planRepos = reposPlanning.getPlanningStore('id', reposPlanning.planningRepos.id);
  console.log(planRepos[0]);

  // let idPlan = ""
  // let planRepos = []
  // for (const itemPlan of reposPlanning.listPlanning) {
  //     console.log(itemPlan)
  //     if (itemPlan.typePlanning == "Corte") {
  //         idPlan = itemPlan.id
  //         planRepos = reposPlanning.getPlanningStore("id", idPlan);
  //     } else if (itemPlan.typePlanning == "Cria") {
  //         idPlan = itemPlan.id
  //         planRepos = reposPlanning.getPlanningStore("id", idPlan);
  //     } else {
  //         planRepos[0] = []
  //     }
  // }

  useEffect(() => {
    console.log('useEffect');
    console.log(planRepos[0]);

    if (activePlan) {
      //console.log(planRepos[0].typePlanning);
      console.log(activePlan);
      if (activePlan?.typePlanning == 'Corte') {
        toggleButtonActive('Corte'); // Ativa o botão "Corte"
      } else if (activePlan?.typePlanning == 'Cria') {
        toggleButtonActive('Cria');
      } else if (activePlan?.typePlanning == 'Outra') {
        toggleButtonActive('Outra');
      } else {
        return false;
      }
    }
  }, []);

  //toggleButtonActive("Corte"); // Ativa o botão "Corte"
  //toggleButtonActive("Cria"); // Desativa o botão "Cria"
  console.log('buttons');
  console.log(buttons);
  const handleButtonClick = (buttonName, buttonIndex) => {
    console.log(buttons);

    setButtons((prevButtons) =>
      prevButtons.map((button) => {
        return {
          ...button,
          active: button.name === buttonName ? !button.active : false,
        };
      })
    );

    // Use um callback para garantir que você está lendo o estado mais recente
    setButtons((prevButtons) => {
      const updatedButton = prevButtons.find((button) => button.name === buttonName);

      if (updatedButton && updatedButton.active) {
        setShowComponents([updatedButton.name]);
        setSelectedTab(buttonIndex);
      } else {
        setShowComponents([]);
        setSelectedTab(0);
      }

      return prevButtons; // Retorne o estado atualizado
    });
  };

  const bactive = buttons.find((item) => item.active == true);
  console.log('bactive');
  console.log(bactive);

  console.log('showComponents');
  console.log(showComponents);
  console.log(typePlanning);

  return (
    <>
      {isLoading === true ? (
        <>
          <Card sx={{ mt: 0 }}>
            <Skeleton variant="rectangular" width="100%" height={110} />
          </Card>
          <Card sx={{ mt: 0.5 }}>
            <Skeleton variant="rectangular" width="100%" height={155} />
          </Card>
        </>
      ) : (
        <>
          <Card sx={{ mt: 1 }}>
            <CardHeader
              title={
                <>
                  <div className="d-lg-none">
                    <Box sx={{ display: 'flex', mb: 1 }}>
                      <Typography sx={{ fontSize: 18 }}>Vamos inciar o planejamento da criação?</Typography>
                    </Box>
                    <Box sx={{ minHeight: 50 }}>
                      <Typography style={{ color: '#2e7d32' }}>
                        {isLoadingText && <Typewriter tam={40} minHeight={0} text="Começe definindo uma meta de produção. Clique no botão META, logo abaixo." />}
                      </Typography>
                    </Box>
                  </div>

                  <div className="d-none d-lg-block">
                    <Box sx={{ display: 'flex', mb: 1 }}>
                      <Typography variant="h6" color="textPrimary">
                        Vamos definir um planejamento de sucesso:
                      </Typography>
                    </Box>
                    <Box sx={{ minHeight: 30 }}>
                      <Typography style={{ color: '#2e7d32' }}>
                        {isLoadingText && <Typewriter tam={30} minHeight={0} text="Começe definindo uma meta de produção. Clique no botão META, logo abaixo." />}
                      </Typography>
                    </Box>
                  </div>
                </>
              }
            ></CardHeader>
            <Divider />
          </Card>
          <Card sx={{ mt: 1 }}>
            <Container component="main" maxWidth="" sx={{ mb: 0 }}>
              <Paper variant="" sx={{ my: { xs: 3, md: 1 }, p: { xs: 1, md: 1 } }}>
                <Grid container justifyContent="center" alignItems="center">
                  {buttons.map((button, index) => (
                    <Grid item xs={12} md={3} key={button.name}>
                      <ButtonBase
                        component={Paper}
                        elevation={2}
                        sx={{
                          p: 2,
                          borderRadius: 1,
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          border: button.active ? '2px solid green' : '2px solid transparent',
                          animation: !bactive ? `${expandAndFade} 2s linear infinite` : '',
                        }}
                        onClick={() => handleButtonClick(button.name, index)}
                      >
                        <Stack direction="column" alignItems="center">
                          {button.icon}
                          <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                            {button.title}
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: 'background.default' }}>
                          {/* <Chip>Active account</Chip>
                                <Switch
                                  onChange={() => handleSwitchChange(index)}
                                  checked={cardActive}
                                /> */}
                          {/* {target.isActive && <Chip label="Meta ativa" variant="outlined"></Chip>} */}

                          {button.active && button.name === activePlan?.typePlanning && activePlan?.isActiveTargetLivestock === 1 && <Chip label="Meta ativa" variant="outlined"></Chip>}
                          {/* {button.active && button.name === "Corte" && <Chip label="Meta ativa" variant="outlined"></Chip>}
                          {button.active && button.name === "Outra" && <Chip label="Meta ativa" variant="outlined"></Chip>}  */}

                          {/* <Switch
                          //onChange={(e) => handleSwitchChange(e, index, target)}
                          //checked={target.isActive}
                          /> */}
                        </Stack>
                      </ButtonBase>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Container>
            {buttons.map((button) => (
              <div key={button.name}>
                {button.active && (
                  <div>
                    {button.name === 'Cria' && <CardMeatProductionCreation type={'Cria'} />}
                    {button.name === 'Corte' && <CardMeatProductionCut type={'Corte'} />}
                    {/* {button.name === "Leite" && <div className="content">Em construção</div>}
                    {button.name === "Derivados" && <div className="content">Em construção</div>} */}
                    {button.name === 'Outra' && <CardMeatProductionOther type={'Outra'} />}
                  </div>
                )}
              </div>
            ))}

            <div>
              {/* Renderize o componente correspondente aqui */}
              {/* {typePlanning === "Cria" && <CardMeatProductionCreation type={"Cria"} />} */}
              {/* {typePlanning === "Cria" && <CardMeatProductionCreation type={"Cria"} />} */}
              {/* {typePlanning === "Corte" && <CardMeatProductionCut type={"Corte"} />}
              {typePlanning === "Leite" && <div className="content">Em construção</div>}
              {typePlanning === "Derivados" && <div className="content">Em construção</div>} */}
            </div>
          </Card>
        </>
      )}
    </>
  );
}

//https://aegro.com.br/termos-de-uso/

import * as React from "react"
import { Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material"
import { LocalShipping } from "@mui/icons-material"
import PropTypes from 'prop-types'
import SalesInventoryContent from './SalesInventoryContent'
import TableBuySalesOrder from "../inventory/TableBuySalesOrder"
import TablesPerson from "../person/components/outros/TablesPerson"
import TutorialStockProducts from "../buy/components/tutorial/TutorialStockProducts"
import RegisterBuysSalesDialog from "../financial/components/FormMain/RegisterBuysSalesDialog"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index, type) {
  return {
    id: `tabela-vendas-${index}`,
    'aria-controls': `Tabela: ${type}`,
  }
}

export default function SalesContent() {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12} md={7}>
              <Box sx={{ display: 'flex' }}>
                <LocalShipping sx={{ fontSize: 35 }} color="text.secondary" />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6" >
                    Vendas
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={10} md={3}>
              <RegisterBuysSalesDialog type={"sales"} />
            </Grid>
            <Grid item xs={1} md={1}>
              <TutorialStockProducts />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="content mb-4">
        <div className="container-fluid">
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs textColor="success" TabIndicatorProps={{ style: { background: 'green' } }} value={value} onChange={handleChange} aria-label="Tabelas de Venda">
                <Tab label="Pedidos em aberto" {...a11yProps(0, "Pedidos em aberto")} />
                <Tab label="Pedidos enviados" {...a11yProps(1, "Pedidos enviados")} />
                <Tab label="Clientes" {...a11yProps(2, "Clientes")} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <TableBuySalesOrder type={'income'} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <SalesInventoryContent />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <TablesPerson tab_active={['Cliente', 'Ativo']} />
            </TabPanel>
          </Box>
        </div>
      </div>
    </div>
  );
}

import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardPatrimony from './CardPatrimony';
import TableDepreciation from './TableDepreciation';
import ListMaintenance from './ListMaintenance';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';
import { Grid } from '@mui/material';
import { PlaylistAddCheck } from '@mui/icons-material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index, type) {
  return {
    id: `patrimony-tab-${index}`,
    'aria-controls': `Tabela: ${type}`,
  };
}

export default function PatrimonyTabs() {
  let link = window.location.href;
  let aux_tab_active = 0; //Lista

  if (/.*#depreciation/.test(link)) {
    aux_tab_active = 1;
  } else if (/.*#maintenance/.test(link)) {
    aux_tab_active = 2;
  }

  const [value, setValue] = React.useState(aux_tab_active);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', mt: 0 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} textColor="success" TabIndicatorProps={{ style: { background: 'green' } }} onChange={handleChange} aria-label="Tabelas Patrimônios">
          <Tab
            label={
              <Grid container direction="row" alignItems="center" sx={{ animation: `${expandAndFade} 2s linear infinite` }}>
                <PlaylistAddCheck sx={{ mr: 0.5, mb: 0.2 }} />
                Lista
              </Grid>
            }
            {...a11yProps(0)}
          />
          <Tab label="Depreciação" {...a11yProps(1, 'Depreciação')} />
          <Tab label="Manutenções" {...a11yProps(2, 'Manutenções')} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <CardPatrimony />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TableDepreciation />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ListMaintenance />
      </TabPanel>
    </Box>
  );
}

import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { NavLink } from "react-router-dom";
import ChartBarP from "../../../../components/Charts/ChartBarP";
import { Box, Divider, FormControl, Input, InputAdornment, InputLabel, Paper, Stack } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/pt";
import SignpostIcon from "@mui/icons-material/Signpost";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import useHarvestReposStore from "../../../../store/useHarvestReposStore";
import { calcularDateProgressBarra } from "../../../../components/Calculations/calcularDateProgressBarra";
import useLivestockTargetReposStore from "../../../../store/useLivestockTargetReposStore";
import useStore from "../../../../store/useStore";
import { useContext } from "react";
import { Context } from "../../../../components/Context/AuthContext";
import TimelineHorizPhaseLivestock from "../GraphPlan/TimelineHorizPhaseLivestock";
import PlanningLivestockCost from "../CostProductionLivestock/PlanningLivestockCost";
import FormGoalButtonTargetLivestock from "../formGoalTargetLivestock/FormGoalButtonTargetLivestock";
import FormGoalButtonTargetEditLivestock from "../formGoalTargetLivestock/FormGoalButtonTargetEditLivestock";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

export default function CardMeatProduction({ type }) {
  var property_id = localStorage.getItem("Property") || 1;
  const usestore = useStore();

  const livestockRepos = usestore.reposLivestock.livestockRepos;
  const livestockActive = usestore.reposLivestock.getLivestockStore("id", livestockRepos.id)[0];
  console.log("livestockActive");
  console.log(livestockActive);

  const { id, idFarm } = useParams();

  const typeFaseLoteList = []
  for (const lote of livestockActive.lotes) {
    typeFaseLoteList.push(lote.fase_name)
  }

  console.log("typeFaseLoteList")
  console.log(typeFaseLoteList)
  console.log(typeFaseLoteList[0])

  // switch (type) {
  //   case "Bovinocultura de Corte":
  //     return <IconBeefCattle widthStyles={widthStyles} fill={fill} />;
  //   case "Bovinocultura de Leite":
  //     return <IconMilkCow widthStyles={widthStyles} />;
  //   case "Suinocultura":
  //     return <IconPork widthStyles={widthStyles} />;
  //   case "Avicultura de Corte":
  //     return <IconChicken widthStyles={widthStyles} />;
  //   case "Piscicultura":
  //     return <IconFish widthStyles={widthStyles} />;
  //   default:
  //     //case "Geral":
  //     return <IconGeneral />;
  //   //default:
  //   //  console.log("Desculpe, estamos sem nenhuma " + type + ".");
  //   //  return <p>ICON</p>;
  // }


  //console.log(valueProgress);

  // instancia os repos planning
  const reposPlanning = useLivestockTargetReposStore((state) => state);
  console.log("reposPlanning");
  console.log(reposPlanning);
  const [dataLivestock, setDataLivestock] = React.useState(livestockActive);

  const date_start = dataLivestock.data_inicial;
  const date_prev_end = dataLivestock.data_fim;

  console.log("data")
  // Converter as datas para objetos Date
  const newdatePrevEnd = new Date(date_prev_end);
  console.log(newdatePrevEnd)
  const newdateStart = new Date(date_start);
  // Calcular a diferença em milissegundos
  const diffInMilliseconds = newdatePrevEnd - newdateStart;
  // Converter a diferença em dias
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  console.log("diffInDays")
  console.log(diffInDays)

  // Obter a data formatada
  const formattedDatePrevEnd = newdatePrevEnd.toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  // Obter a data formatada
  const formattedDateStart = newdateStart.toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const valueProgress = calcularDateProgressBarra(formattedDateStart, formattedDatePrevEnd);
  //console.log(reposPlanning.listPlanning);
  //console.log(reposPlanning.planningRepos);
  console.log(reposPlanning.planningRepos.id)
  const planRepos = reposPlanning.getPlanningStore("id", reposPlanning.planningRepos.id);
  console.log(planRepos[0]);
  //const valueProductivity = planRepos[0]?.expectedYield / planRepos[0]?.area;
  //GMD = peso atual - peso anterior / número do intervalo da pesagem.
  const valueProductivity = (planRepos[0]?.expectedYield - planRepos[0]?.animalInput) / diffInDays

  const [plan, setPlan] = React.useState(planRepos[0]);
  const [productivity, setProductivity] = React.useState(valueProductivity.toFixed(2));

  const [value, setValue] = React.useState(0);

  const [values, setValues] = React.useState({
    amount: " 1.100,00",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  //console.log("plan");
  //console.log(plan);

  const { aux_add } = useContext(Context);

  useEffect(() => {
    console.log("refresh ");
    setPlan(planRepos[0]);
  }, [aux_add]);

  return (
    <Box className="content-wrapper bg-white">
      <Box className="content-header" style={{ marginTop: 0 }}>
        <Box className="container-fluid">
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12} md={8}>
              <Box sx={{ display: "flex" }}>
                <SignpostIcon style={{ fontSize: 35 }} />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Planejamento da Criação: {type}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 0 }}>
            <Grid item xs={12} md={8}>
              <Box sx={{ display: "flex" }}>
                {/* <ListAltIcon style={{ fontSize: 35 }} /> */}
                <Stack spacing={0.5} sx={{ ml: 5.5, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Projeto:
                  </Typography>
                  <Box sx={{ flex: "1", flexDirection: "column" }}>
                    {/* <Typography component="span">Gestão da Safra</Typography> */}
                    <Typography component="h3" variant="h6" sx={{ fontWeight: "bold", color: "success.main", textTransform: "uppercase" }}>
                      {livestockActive.label}
                    </Typography>
                    <Typography component="span">{livestockActive.tipo}</Typography>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2} sx={{ mt: 0 }}>
          <Grid item xs={12} md={12}>
            <Box mt={0}>
              <TimelineHorizPhaseLivestock />
              {/* <TimelineHoriz /> */}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 0 }}>
          {/* <coluna 1 /> */}
          <Grid item xs={12} md={8}>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {/* custo  */}
              <PlanningLivestockCost />
            </Grid>
          </Grid>
          {/* coluna 2  */}
          <Grid item xs={12} md={4}>
            <Grid container spacing={2} sx={{ mt: 0 }}>
              {/* meta  */}
              <Grid item xs={12} md={12}>
                <Box mt={2}>
                  <Grid container spacing={2} sx={{}}>
                    <Grid item xs={6} md={6}>
                      <Box sx={{ display: "flex" }}>
                        <Stack spacing={0.5}>
                          <Typography fontWeight="400" variant="h6">
                            Meta
                          </Typography>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>{plan == "" ? <FormGoalButtonTargetLivestock /> : <></>}</Box>
                    </Grid>
                  </Grid>
                  <Box mt={2}>
                    {plan ? (
                      <Card>
                        <CardContent sx={{ mt: -1 }}>
                          <Grid container spacing={2} sx={{ mb: 1.1 }}>
                            <Grid item xs={12} sm={12}>
                              <Grid container spacing={1}>
                                <Grid item sx={{ width: 50 }}>
                                  <EmojiEventsIcon sx={{ fontSize: 40, color: "#28a745" }} />
                                  {/* <Img alt="complex" src="/dist/img_fp/icon-button/icon-agricolaV1.png" /> */}
                                </Grid>
                                <Grid item xs>
                                  <Typography variant="caption">Produção Esperada</Typography>
                                  <Typography variant="subtitle2">
                                    <strong>{plan?.expectedYield}</strong> <span style={{ fontSize: "0.8em" }}>{plan?.unitWeight}/{plan?.unit}</span>
                                  </Typography>
                                </Grid>
                                <Grid item xs>
                                  <Typography variant="caption">Produtividade Esperada</Typography>
                                  <Typography variant="subtitle2">
                                    {" "}
                                    <strong>{productivity}</strong> <span style={{ fontSize: "0.8em" }}>{plan?.unitWeight}/dia</span>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    ) : (
                      <Box height="88px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                          <FormGoalButtonTargetLivestock />

                        </Box>
                        <span>Clique no ícone para definir a meta</span>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
              {/* Prazo  */}
              <Grid item xs={12} md={12}>
                <Box mt={2}>
                  <Box sx={{ display: "flex" }}>
                    <Stack spacing={0.5}>
                      <Typography fontWeight="400" variant="h6">
                        Prazo
                      </Typography>
                    </Stack>
                  </Box>
                  <Box mt={2}>
                    <Card>
                      <CardContent sx={{ mt: -1 }}>
                        <Stack alignItems="center" direction="row" divider={<Divider />} spacing={1}>
                          <Typography variant="caption" color="text.secondary" component="div">
                            {/* {dataLivestock.data_inicial} */}
                            {formattedDateStart}
                          </Typography>
                          <ChartBarP valueProgress={valueProgress} />
                          <Typography variant="caption" color="text.secondary" component="div">
                            {/* {dataLivestock.data_fim} */}
                            {formattedDatePrevEnd}
                          </Typography>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Box>
                </Box>
              </Grid>
              {/* venda  */}
              <Grid item xs={12} md={12}>
                <Box mt={2}>
                  <Box sx={{ display: "flex" }}>
                    <Stack spacing={0.5}>
                      <Typography fontWeight="400" variant="h6">
                        Venda
                      </Typography>
                    </Stack>
                  </Box>
                  <Box mt={2}>
                    {false ? (
                      <Card>
                        <CardContent sx={{}}>
                          <Grid container spacing={2} sx={{}}>
                            <Grid item xs={12} sm={6}>
                              <Grid container spacing={1}>
                                <Grid item xs>
                                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                                    <InputLabel sx={{ color: "#28a745" }} color="success" htmlFor="standard-adornment-amount">
                                      Valor esperado para venda
                                    </InputLabel>
                                    <Input
                                      color="success"
                                      sx={{ width: 150, color: "#28a745" }}
                                      value={values.amount}
                                      onChange={handleChange("amount")}
                                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* <Grid item xs={12} sm={6}>
                            <Grid container spacing={1}>
                              <Grid item xs>
                                <Typography variant="caption" color="text.secondary" component="div">
                                  Lucro esperado
                                </Typography>
                                <Typography variant="subtitle2" component="div">
                                  R$ 1.100,00 /saco 60kg
                                  <Typography variant="caption" sx={{ color: "#28a745" }} color="text.secondary" component="div">
                                    (+10%)
                                  </Typography>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid> */}
                          </Grid>
                        </CardContent>
                      </Card>
                    ) : (
                      <Box height="110px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}></Box>
                        <span>Defina a meta e o custo de produção</span>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
              {/* Lucro  */}
              <Grid item xs={12} md={12}>
                <Box mt={2}>
                  <Box sx={{ display: "flex" }}>
                    <Stack spacing={0.5}>
                      <Typography fontWeight="400" variant="h6">
                        Lucro
                      </Typography>
                    </Stack>
                  </Box>
                  <Box mt={2}>
                    {true ? (
                      <Card>
                        <CardContent sx={{}}>
                          <Grid container spacing={2} sx={{}}>
                            {/* <Grid item xs={12} sm={6}>
                            <Grid container spacing={1}>
                              <Grid item xs>
                                <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                                  <InputLabel sx={{ color: "#28a745" }} color="success" htmlFor="standard-adornment-amount">
                                    Valor esperado para venda
                                  </InputLabel>
                                  <Input
                                    color="success"
                                    sx={{ width: 150, color: "#28a745" }}
                                    value={values.amount}
                                    onChange={handleChange("amount")}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid> */}
                            <Grid item xs={12} sm={6}>
                              <Grid container spacing={1}>
                                <Grid item xs>
                                  <Typography variant="caption" color="text.secondary" component="div">
                                    Lucro esperado
                                  </Typography>
                                  <Typography variant="subtitle2" component="div">
                                    R$ 1.100,00 /saco 60kg
                                    <Typography variant="caption" sx={{ color: "#28a745" }} color="text.secondary" component="div">
                                      (+10%)
                                    </Typography>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    ) : (
                      <Box height="100px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}></Box>
                        <span>Defina a meta e o custo de produção</span>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
              {/* fim lucro  */}
            </Grid>
          </Grid>
        </Grid>
        {/* fim  */}
      </Box>
    </Box>
  );
}

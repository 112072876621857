import { useState } from 'react';
import {
  TextField,
  Box,
  Grid,
  Typography,
  InputAdornment,
  Stack,
  Card,
  CardContent,
  CardMedia,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  ListItem,
  ListItemText,
  List,
  ListItemAvatar,
  Avatar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import Typewriter from '../../../components/Typewriter/Typewriter';
import { Span } from '../../../components/Typography';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import PanToolIcon from '@mui/icons-material/PanTool';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import Bot from '../../../components/Animations/Bot';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';
import StepIcon from '../../../components/Icons/tutorial/StepIcon';
import { HelpOutline } from '@mui/icons-material';

const useStyles = makeStyles({
  // selected: {
  //     border: "2px solid green",

  // },
  img: {
    height: 'auto',
  },
});

export default function StartFourthStep({ data, setData, handleNext, handleBack, activeStep, steps, handleClose }) {
  console.log(data);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const para validar o button continuar, se existem as 2 validações
  const isError = () => {
    // if (!data.nameBatch || data.nameBatch.trim() === "") {
    //     return true;
    // }
  };

  // para validar o input
  // const validateProjectName = (value) => {
  //     return value.trim().length > 0;
  // };

  const returnError = () => {
    return false;
  };

  const color = '#2e7d32';

  const classes = useStyles;

  const text =
    'Para termos um excelente gerenciamento da sua propriedade, primeiramente precisamos definir a área de toda a sua propriedade e depois, as áreas onde alocaremos as criações e as safras. Clique em continuar.';

  return (
    <>
      <Grid container sx={{ mb: 0 }}>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <img src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="AdminLTE Logo" className="brand-image" style={{ opacity: '.8', height: 32, maxWidth: '100%' }} />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5} sx={{ mt: 0 }}>
              <Typography variant="h6" fontWeight={500}>
                Fazenda Premium
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container className={classes.root} sx={{ mt: 2 }} style={{ height: 'auto', maxWidth: '100%' }}>
          <Grid container sm={6} xs={12}>
            <Grid container style={{ padding: '4%' }}>
              <Grid item sm={12} xs={12}>
                {/* <Typography paragraph variant="h6" fontWeight={500}>
                  Próximos passos:
                </Typography> */}
                <div className="d-lg-none">
                  <Typography gutterBottom sx={{ mt: 1, mb: 3 }} fontWeight={500} style={{ color: '#2e7d32' }}>
                    <Typewriter tam={70} text="Restam agora 2 configurações essenciais para gestão da sua propriedade." />
                  </Typography>
                </div>

                <div className="d-none d-lg-block">
                  <Typography gutterBottom sx={{ mt: 0, mb: 3 }} fontWeight={500} style={{ color: '#2e7d32' }}>
                    <Typewriter tam={40} text="Restam agora 2 configurações essenciais para gestão da sua propriedade." />
                  </Typography>
                </div>

                {/* <StartThirdStepRegisterAreaProperty data={data} setData={setData} /> */}
                <List sx={{ width: '100%', bgcolor: 'background.paper', mt: 0 }}>
                  <ListItem key={null}>
                    <ListItemAvatar>
                      <CheckCircleOutlineIcon style={{ color: '#2e7d32' }} />
                    </ListItemAvatar>
                    <ListItemText sx={{ ml: -3, textDecoration: 'line-through' }} primary={<strong>Passo 1</strong>} secondary="Registrar o nome e o tamanho da propriedade" />
                    <span aria-label="comment">
                      <CropOriginalIcon style={{ color: '#2e7d32' }} />
                    </span>
                  </ListItem>
                  <ListItem key={null} sx={{ mt: -2 }}>
                    <ListItemAvatar>
                      <RadioButtonUncheckedIcon />
                    </ListItemAvatar>
                    <ListItemText sx={{ ml: -2 }} primary={<strong>Passo 2</strong>} secondary="Registrar a propriedade no Google Maps." />
                    <span aria-label="comment">
                      <CropOriginalIcon style={{ color: '#2e7d32' }} />
                    </span>
                  </ListItem>
                  {/* <ListItem key={null} sx={{ mt: -2 }}>
                    <ListItemAvatar>
                      <RadioButtonUncheckedIcon />
                    </ListItemAvatar>
                    <ListItemText sx={{ ml: -2 }} primary={<strong>Passo 3</strong>} secondary="Organizar as áreas de produção." />
                    <span aria-label="comment">
                      <ShareLocationIcon style={{ color: '#2e7d32' }} />
                    </span>
                  </ListItem> */}
                  <ListItem key={null} sx={{ mt: -2 }}>
                    <ListItemAvatar>
                      <RadioButtonUncheckedIcon />
                    </ListItemAvatar>
                    <ListItemText sx={{ ml: -2 }} primary={<strong>Passo 3</strong>} secondary="Registrar sua primeira safra ou criação, criando um projeto pecuário ou agrícola." />
                    <span aria-label="comment">
                      <AccountTreeIcon style={{ color: '#2e7d32' }} />
                    </span>
                  </ListItem>
                </List>
                <Grid container sx={{ mt: 1 }}>
                  <Grid item sm={12} xs={12}>
                    <Typography paragraph variant="subtitile1" fontWeight={500}>
                      Em todas as páginas, você encontrará <Span sx={{ animation: `${expandAndFade} 2s linear infinite` }}> menus</Span>,{' '}
                      <Span sx={{ animation: `${expandAndFade} 2s linear infinite` }}> botões</Span> e <Span sx={{ animation: `${expandAndFade} 2s linear infinite` }}> textos</Span> destacados,
                      facilitando a navegação e a sua ação para clicá-los.
                    </Typography>
                    <Typography paragraph variant="subtitile1" style={{ color: '#2e7d32' }} fontWeight={500}>
                      Além disso, disponibilizamos guias passo a passo <StepIcon sx={{ animation: `${expandAndFade} 2s linear infinite` }} />, e menus informativos <HelpOutline /> para ajudá-lo sempre
                      que surgir uma dúvida..
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <Typography paragraph variant="subtitile1" style={{ color: '#2e7d32' }} fontWeight={500}>
                      Clique em continuar...
                    </Typography>
                  </Grid>
                  {/* <Grid item sm={6} xs={6} sx={{ textAlign: 'right', mt: -3 }}>
                    <Bot text={text} />
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sm={6} xs={12}>
            <Grid container style={{ padding: '4%' }}>
              <Grid item sm={12} xs={12}>
                <img src="/dist/image/startmap.jpeg" style={{ height: 'auto', maxWidth: '100%', borderRadius: '10px' }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              {/* <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                <Span>cancelar</Span>
              </Button> */}
            </Box>
            {data && (
              <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                <Button onClick={handleBack} color="success" sx={{ marginRight: 2 }} disabled={returnError()}>
                  Retornar
                </Button>
                {/* O botão Confirmar e Salvar, leva para tela successo */}
                <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} color="success" variant="contained" type="submit" onClick={handleClose}>
                  <Span>Continuar</Span>
                </Button>
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </Grid>
    </>
  );
}

import React, { useState } from 'react'
import { Box, Button, CardMedia, Grid, List, ListItem, ListItemText } from "@mui/material"
import { Queue } from '@mui/icons-material'
import AutocompleteGeneric from '../../../../components/Fields/AutocompletGeneric'
import IconsLivestockActivities from '../../../../components/Icons/IconsLivestockActivities'
// import CurrencyInputReal from '../../../../components/Fields/CurrencyInputReal'
import GenericValueField from '../../../../components/Fields/GenericValueField'

export default function FormAddLotesSales({ data, obj_fn }) {
  const optionsLotesList = JSON.parse(JSON.stringify( obj_fn.usestore.reposLivestock.listLotes ))
  const optionsLotes = optionsLotesList.map((item) => {
    item.weight_unit = item.weight_unit || 'kg'
    item.peso = (item.weight ? item.weight + " (" + item.weight_unit + ")" : '-')

    if (!item.image) {
      const listImageRepos = obj_fn.usestore.reposImages.getImageStore("lote", item.id);
      const itemData = listImageRepos.filter((img) => {
        return img.img;
      });
      item.image = itemData[0] ? itemData[0].img : null
    }

    return item;
  })

  const [selectedLote, setSelectedLotes] = useState(null)

  const handleChange = (event) => {
    setSelectedLotes(prevState => ({ ...prevState, [event.target.name]: parseFloat(event.target.value) }))
  }

  const handleAdd = () => {
    const aux_add = JSON.parse(JSON.stringify(data.lotes))
    selectedLote.discount = 0
    aux_add.push(selectedLote)

    var val_total = 0
    var aux_val = 0
    for (var i = 0, tam = aux_add.length; i < tam; i++) {
      aux_val = parseFloat(aux_add[i].value)
      // aux_val -= aux_val * parseFloat(aux_add[i].discount) / 100

      val_total += aux_val
      aux_add[i].value_end = aux_val
    }
    var aux_value = (data.value - data.lotes_value) + val_total
    var aux_value_end = (data.value_end - data.lotes_value) + val_total

    obj_fn.setData(prevState => ({ ...prevState, lotes_value: val_total }))
    obj_fn.setData(prevState => ({ ...prevState, lotes: aux_add }))

    obj_fn.setData(prevState => ({ ...prevState, value: aux_value }))
    obj_fn.setData(prevState => ({ ...prevState, value_end: aux_value_end }))

    obj_fn.setData(prevState => ({ ...prevState, lote_selected: null }))
    setSelectedLotes(null)
  }

  const handleChangeSelect = (field, newValue) => {
    console.log('handleChangeSelect')
    let aux_lote = newValue ? JSON.parse(JSON.stringify(newValue)) : null

    setSelectedLotes(aux_lote)
    obj_fn.setData(prevState => ({ ...prevState, lote_selected: aux_lote }))
  }

  const opt_lotes = {
    multiple: false,
    name: 'lote',
    field: 'lote',
    label: ('Lote para: ' + data.operationType),
    values: optionsLotes,
    variant: 'standard',
    helperText: (<>
      Selecione o lote desejado
      <br />
      Qtd. animais Não Identificados: {selectedLote?.num_animals || '-'}
    </>),
    listHeight: 225,
    disableClearable: true,
  }

  // const para validar os campos e o button add ao carrinho
  const validQtdVal = (type) => {
    let text = ''
    var mensagem = (<></>);
    let error = '';
    let is_error = false

    if (type == 'quantidade' || type == 'isError') {
      let qtd = selectedLote?.quant
      text = "Quantidade de animais para: " + data?.operationType

      if (!qtd) {
        error = 'Quantidade deve ser maior que 0!'
        is_error = true
      } else if (data.operationType == "Venda" && qtd > selectedLote?.num_animals) {
        error = 'Não pode ser maior do que a Qtd de animais do lote'
        is_error = true
      }
    }
    if (type == 'valor' || type == 'isError') {
      let valor = selectedLote?.value
      text = "Valor total dos animais"

      if (!valor) {
        error = 'Valor deve ser maior que 0!'
        is_error = true
      }
    }

    if (type == 'isError') {
      return selectedLote?.id ? is_error : true
    }

    if (error && selectedLote?.id) {
      mensagem = (<>
        <br/>
        <span style={{ color: 'red' }}>
          {error}
        </span>
      </>);
    }

    return (<>
      {text}
      {mensagem}
    </>);
  };

  return ( <>
    <Grid container spacing={3}>
      <Box sx={{ display: "flex", justifyContent: "center", p: 1, ml: 1, mt: 1 }}>
        <Grid container spacing={3} >
          <Grid item xs={12} sm={6}>
            <AutocompleteGeneric value={selectedLote} options={opt_lotes} handleChange={handleChangeSelect} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={4} sx={{ mt: (obj_fn.ismobile ? -5 : -3), mb: -2 }}>
              <Grid item xs={8} sm={8}>
                <List dense={true}>
                  <ListItem>
                    <ListItemText primary={"Fase de manejo: " + (selectedLote?.fase_name || '-')} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={"Peso médio: " + (selectedLote?.peso || '-')} />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={4} md={4}>
                {!selectedLote?.image ? <>
                  <IconsLivestockActivities activity={'Geral'} widthStyles={'80px'} fill={'#1f2d3d'}/>
                </> : 
                  <CardMedia
                    component="img"
                    image={selectedLote?.image}
                    alt="Imagem do lote"
                    sx={{
                      borderRadius: '16px',
                      height: '80px',
                      width: '100px',
                    }}
                  />
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4}>
            {/* <CurrencyInputReal
              required
              id="quant"
              name="quant"
              label="Quantidade"
              color="success"
              value={selectedLote?.quant}
              onChange={handleChange}
              disabled={!selectedLote?.id || selectedLote?.id_animal}
              adornment="quantidade"
              helperText={validQtdVal('quantidade')}
            /> */}
            <GenericValueField
              handleChange={handleChange}
              options={{
                id: "quant",
                name: "quant",
                label: "Quantidade *",
                value: selectedLote?.quant,
                helperText: validQtdVal('quantidade'),
                disabled:!selectedLote?.id || selectedLote?.id_animal,
                width: '80%'
              }}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            {/* <CurrencyInputReal
              required
              id="value"
              name="value"
              label="Valor (R$)"
              color="success"
              value={selectedLote?.value}
              onChange={handleChange}
              disabled={!selectedLote?.id}
              helperText={validQtdVal('valor')}
            /> */}
            <GenericValueField
              handleChange={handleChange}
              options={{
                required: true,
                id: "value",
                name: "value",
                label: "Valor",
                value: selectedLote?.value,
                helperText: "Valor total dos animais",
                helperTextError: "Valor deve ser maior que 0!",
                disabled:!selectedLote?.id,
                endAdornment: true,
                width: '80%'
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} sx={{ mt: 2 }}>
            <Button variant="contained" color="success" onClick={handleAdd} disabled={validQtdVal('isError')}>
              <Queue sx={{mr: 1}}/> Adicionar ao carrinho
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  </>)
}
import { useState, useEffect } from 'react';
// import Loading from '../../Loader/Loading';
import api from '../../../services/api';
import { apiGetPermissionRoleDefault } from '../../../queries/apiGetPermissionRoleDefault';
import useStore from '../../../store/useStore';

export default function useAuth() {
  var tokenAuthenticated = JSON.parse(localStorage.getItem('authenticated') || 'false');
  const [authenticated, setAuthenticated] = useState(tokenAuthenticated);
  // indicar que estamos fazendo o loading para esperar o finalizar do useffects
  const [isLoading, setIsLoading] = useState('start');
  const [aux, setAux] = useState({ rl: 0, rh: 0, ra: 0, ri: 0 });

  // const [loading, setLoading] = useState(true);

  // instancia os repos
  const usestore = useStore();

  // toda vez que atualiza a página perde-se as informações
  // execute só uma vez após o componente ser montado
  useEffect(() => {
    // exite uma demora para pegar essa informação
    // ao executar também está sendo executado o useEffect do login (assincrono)
    // necessita indicar a aplicação que estamos fazendo um loading
    var tokenAuthenticated = JSON.parse(localStorage.getItem('authenticated') || 'false');

    // exite um autenticated?
    // se existe seto o authenticated
    if (tokenAuthenticated) {
      setAuthenticated(true);
    }

    // setLoading(false);
  }, []);

  const handleLoading = (target, value) => {
    //recebe o estado anterior como um parâmetro e retorna o novo estado
    setAux((prevState) => ({ ...prevState, [target]: value }));
  };

  function handleRepos(farm_id) {
    setIsLoading(400);
    setAux({ rl: 100, rh: 100, ra: 100, ri: 100 });
    //parseInt(Math.random() * 100);
    //usestore.reposUser.fetch({ id_property: farm_id });

    usestore.reposLivestock.fetch({ id_farm: farm_id }).then((res) => {
      handleLoading('rl', 0);
    });
    setTimeout(() => {
      usestore.reposHarvest.fetch({ id_farm: farm_id }).then((res) => {
        handleLoading('rh', 0);
        usestore.reposHarvest.fetchCultivation({ id_farm: farm_id });
      });
    }, 80);
    setTimeout(() => {
      usestore.reposAreas.fetch({ id_farm: farm_id }).then((res) => {
        handleLoading('ra', 0);
      });
    }, 160);
    setTimeout(() => {
      usestore.reposImages.fetch({ id_farm: farm_id }).then((res) => {
        handleLoading('ri', 0);
      });
    }, 240);
    setTimeout(() => {
      usestore.reposDocFiles.fetch({ id_farm: farm_id }).then((res) => {
        handleLoading('rdf', 0);
      });
    }, 280);
    setTimeout(() => {
      usestore.reposActivityInput.fetch({ id_farm: farm_id });
      usestore.reposActivityPastureManagement.fetch({ id_farm: farm_id });
      usestore.reposFinances.fetch();
      usestore.reposFinances.fetchT();
      usestore.reposMessenger.fetch();
      usestore.reposTargetHarvest.fetch();
      usestore.reposActivityIdentify.fetch();
      usestore.reposActivityWeight.fetch();
      usestore.reposActivityAssignArea.fetch();
      usestore.reposActivityBatch.fetch();
      usestore.reposActivityPlanting.fetch();
      usestore.reposActivitySoilPreparation.fetch();
      usestore.reposActivityHarvesting.fetch();
      usestore.reposTargetLivestock.fetch();
      usestore.reposHarvestTargetCost.fetch();
      usestore.reposActivityMedicines.fetch();
      usestore.reposActivityNutrition.fetch();
      // usestore.reposPlanLivestock.fetch();
      usestore.reposActivityTransferBatch.fetch();
      usestore.reposActivityMonitoring.fetch();
      usestore.reposActivityApplications.fetch();
      usestore.reposMarketingLivestock.fetch({ id_farm: farm_id });
      usestore.reposBank.fetch();
      usestore.reposPerson.fetch();
      usestore.reposAccount.fetch();
      usestore.reposProducts.fetch();
      usestore.reposProducts.fetchCategory();
      usestore.reposProducts.fetchUnits();
      usestore.reposProducts.fetchPackaging();
      usestore.reposProducts.fetchLocationStock();
      usestore.reposBill.fetch();
      usestore.reposBuys.fetch();
      usestore.reposSales.fetch();
      usestore.reposPatrimony.fetch({ id_farm: farm_id });
      usestore.reposPatrimonyMaintenance.fetch({ id_farm: farm_id });
      usestore.reposActivityProduction.fetch();

      // relatórios
      usestore.reposCashFlow.fetch();
      usestore.reposCostProduction.fetch();
    }, 380);
  }

  async function handleLogin() {
    // console.log('useAuth------Entrou-handleLogin');
    localStorage.setItem('listActvs', JSON.stringify([]));
    localStorage.setItem('listActvsH', JSON.stringify([]));
    localStorage.setItem('listMsn', JSON.stringify([]));
    localStorage.setItem('authenticated', JSON.stringify(true));

    //local para que o demo não faça upload e alterações(true)
    localStorage.setItem('display', false);

    var farm_id = localStorage.getItem('Property') || 1;
    // carregando as permissões do usuário
    apiGetPermissionRoleDefault();
    // carrega áreas da propriedade

    usestore.reposUser.fetch({ id_property: farm_id }).then((res) => {
      handleRepos(farm_id);
      setAuthenticated(true);
      return 1000;
    });
  }
  useEffect(() => {
    // console.log(isLoading);
    setIsLoading(aux.rl + aux.rh + aux.ra + aux.ri);
  }, [aux]);

  async function handleLogout() {
    // console.log('handleLogout');
    setIsLoading(0);
    setAux({ rl: 0, rh: 0, ra: 0, ri: 0 });

    if (!authenticated) {
      localStorage.clear();
      localStorage.setItem('authenticated', 'false');
      setAuthenticated(false);
      return;
    }
    localStorage.clear();
    localStorage.setItem('authenticated', 'false');
    setAuthenticated(false);
    setTimeout(() => {
      delete api.defaults.headers.common['token'];
    }, 100);
    const header = { header: { 'Content-Type': 'application/json' } };
    await api.get('/users/logout', header);
  }

  // useEffects: vai carregar o meu componete inteiro, carregará a função handleLogin e handleLogout
  // se o use efeects não estiver terminado ele não vai renderizar meus componentes abaixo (rotas)
  // vai renderizar o H1
  // quando terminar, o useEffects será falso, o componente será renderizado e rederiza os compoentes
  //if (loading) {
  //  return <Loading />;
  //}

  return {
    authenticated,
    handleLogin,
    handleLogout,
    setAuthenticated,
    setIsLoading,
    isLoading,
    handleRepos,
  };
}

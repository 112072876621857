import React, { useState } from 'react'
import { Box, Button, CardMedia, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material"
import { Female, Male, Queue } from '@mui/icons-material'
import dayjs from "dayjs";
import AutocompleteGeneric from '../../../../components/Fields/AutocompletGeneric'
// import CurrencyInputReal from '../../../../components/Fields/CurrencyInputReal'
import SingleDateField from '../../../../components/Fields/SingleDateField';
import GenericValueField from '../../../../components/Fields/GenericValueField';

const default_lote = {
  id: null,
  id_livestock: null,
  livestock: null,
  id_area: null,
  area: null,
  new_lote: 1,
  name: '',
  weight: '',
  weight_unit: 'Kg',
  quantidade: '',
  value: '',
  fase_name: 'Cria',
  image: '',
  num_animals: 0,
  num_females: 0,
  num_males: 0,
  id_group: null
}
/* campos que o animal deve ter para ser registrado (identificado)
  * identifier - brinco - sisbov - nome
  Pelo menos um identificador deve ser preenchido
*/
const default_animal = {
  sexo: 'femea',
  identifier: '', // *
  brinco: '', // *
  sisbov: '', // *
  name: '', // *
  race: '',
  weight: '',
  weight_unit: 'Kg',
  date_birth: dayjs(),
  value: '',
  selectedLote: default_lote,
  lote: '',
  new_lote: 0,
  fase_name: 'Cria',
}
const tipo_lote = [
  { id: 0, label: 'Lote cadastrado' },
  { id: 1, label: 'Novo Lote' },
];

const faseManejo = [
  {id: 'Cria', label: 'Cria'},
  {id: 'Recria', label: 'Recria'},
  {id: 'Engorda', label: 'Engorda'},
  {id: 'Reprodução', label: 'Reprodução'},
  {id: 'Produção', label: 'Produção'},
  // "Completo",
];
export default function FormAddAnimalsBuy({ data, obj_fn }) {
  const optionsLotesList = JSON.parse(JSON.stringify( obj_fn.usestore.reposLivestock.listLotes ))
  const optionsLotes = optionsLotesList.map((item) => {
    item.weight_unit = item.weight_unit || 'kg'
    item.peso = (item.weight ? item.weight + " (" + item.weight_unit + ")" : '-')
    item.new_lote = 0

    if (!item.image) {
      const listImageRepos = obj_fn.usestore.reposImages.getImageStore("lote", item.id);
      const itemData = listImageRepos.filter((img) => {
        return img.img;
      });
      item.image = itemData[0] ? itemData[0].img : null
    }

    return item;
  })

  const [selectedAnimal, setSelectedAnimals] = useState(default_animal)

  const opt_lotes = {
    multiple: false,
    name: 'lote',
    field: 'lote',
    label: 'Lote ao qual o animal será viunculado',
    values: optionsLotes,
    variant: 'standard',
    helperText: (<>
      Fase de manejo: {selectedAnimal?.selectedLote?.fase_name || '-'}
      <br />
      Qtd. animais Não Identificados: {selectedAnimal?.selectedLote?.num_animals || '-'}
      <br />
      <Female color="secondary" /> Fêmeas: {selectedAnimal?.selectedLote?.num_females || '-'}
      <Male color="primary" sx={{ ml: 3 }}/> Machos: {selectedAnimal?.selectedLote?.num_males || '-'}

    </>),
    listHeight: 225,
    disableClearable: true,
  }

  const handleChange = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    if (value && (field == 'value' || field == 'weight' || field == 'new_lote' )) {
      value = parseFloat(value);
    }
    setSelectedAnimals(prevState => ({ ...prevState, [event.target.name]: value }))
  }

  const handleChangeSelectLote = (field, newValue) => {
    // console.log('handleChangeSelectLote')
    let aux_lote = newValue ? JSON.parse(JSON.stringify(newValue)) : null
    
    setSelectedAnimals(prevState => ({ ...prevState, selectedLote: aux_lote }))
    setSelectedAnimals(prevState => ({ ...prevState, fase_name: aux_lote ? aux_lote.fase_name : 'Cria' }))
    setSelectedAnimals(prevState => ({ ...prevState, lote: aux_lote ? aux_lote.name : '' }))
    setSelectedAnimals(prevState => ({ ...prevState, new_lote: aux_lote ? aux_lote.new_lote : 0 }))
  }

  const optionsLivestockList = JSON.parse(JSON.stringify( obj_fn.usestore.reposLivestock.listLivestock ))
  const opt_livestock = {
    name: 'livestock',
    field: 'livestock',
    label: 'Criações',
    values: optionsLivestockList,
    variant: 'standard',
    helperText: (<>
      Selecione em qual Criação lote ficará
    </>),
    listHeight: 225,
    disableClearable: true,
  }
  const handleChangeSelectLivestock = (field, newValue) => {
    // console.log('handleChangeSelectLivestock')
    let aux_livestock = newValue ? JSON.parse(JSON.stringify(newValue)) : null

    setSelectedAnimals(prevState => ({ 
      ...prevState, 
      selectedLote: {
        ...prevState.selectedLote, 
        livestock: aux_livestock
      }
    }))

    setSelectedAnimals(prevState => ({ 
      ...prevState, 
      selectedLote: {
        ...prevState.selectedLote, 
        id_livestock: (aux_livestock ? aux_livestock.id : null)
      }
    }))

    if (selectedAnimal?.selectedLote?.new_lote) {
      setSelectedAnimals(prevState => ({ 
        ...prevState, 
        selectedLote: {
          ...prevState.selectedLote, 
          id_group: (aux_livestock ? aux_livestock.id_group : null)
        }
      }))
    }
  }

  const handleChangeSelectArea = (field, newValue) => {
    // console.log('handleChangeSelectArea')
    let aux_area = newValue ? JSON.parse(JSON.stringify(newValue)) : null

    setSelectedAnimals(prevState => ({ 
      ...prevState, 
      selectedLote: {
        ...prevState.selectedLote, 
        area: aux_area
      }
    }))
    setSelectedAnimals(prevState => ({ 
      ...prevState,
      selectedLote: {
        ...prevState.selectedLote,
        id_area: (aux_area ? aux_area.id : null)
      }
    }))
  }

  const handleAreas = () => {
    const optionsAreasList = JSON.parse(JSON.stringify( obj_fn.usestore.reposAreas.getAreaUsedMapStore([['id', selectedAnimal?.selectedLote?.id_livestock], ['tipo', 'livestock']]) ))
    const opt_areas = {
      name: 'areas',
      field: 'areas',
      label: 'Áreas da Criação',
      values: optionsAreasList,
      variant: 'standard',
      helperText: (<>
        Selecione em qual Área o lote ficará
      </>),
      listHeight: 225,
      disableClearable: true,
      disabled: !selectedAnimal?.selectedLote?.id_livestock
    }

    return (<>
      <Grid item xs={12} sm={4} >
        <AutocompleteGeneric value={selectedAnimal?.selectedLote?.area} options={opt_areas || []} handleChange={handleChangeSelectArea} />
      </Grid>
      <Grid item xs={12} sm={4} >
        {!selectedAnimal?.selectedLote?.area ? <></> : <>
          <CardMedia
            component="img"
            image={selectedAnimal?.selectedLote?.area?.imageDecode || ''}
            alt="Imagem da area"
            sx={{
              borderRadius: '16px',
              height: '80px',
              width: '100%',
            }}
          />
        </>}
      </Grid>
    </>)
  };

  const handleChangeDate = (newValue, id) => {
    // console.log('handleChangeDate')
    setSelectedAnimals(prevState => ({ ...prevState, date_birth: newValue }))
  }
  const obj_date = {
    label: 'Data de Nascimento',
    id: 'date_birth',
    name: 'date_birth',
    field_data: 'dueDate',
    text_helper: 'Data em que o animal nasceu',
    max: dayjs(),
    handleChange: handleChangeDate,
    maxWidth: '250px',
  }

  // const para validar os campos e o button add ao carrinho
  const validFields = (type) => {
    let text = ''
    var mensagem = (<></>);
    let error = '';
    let is_error = false

    if (type == 'lote' || type == 'isError') {
      if (selectedAnimal?.new_lote) {
        const loteExists = obj_fn.usestore.reposLivestock.getLotesStore('name', selectedAnimal?.lote)
        text = "Informe o nome do Lote"

        if (loteExists.length > 0) {
          error = 'Nome já existente, escolha outro nome!'
          is_error = true
        } else if (selectedAnimal?.lote?.length < 5) {
          error = 'Nome do lote deve ter no mínimo 5 caracteres!'
          is_error = true
        }
      } else {
        if (!selectedAnimal?.selectedLote) {
          error = 'Selecione um lote para vincular o animal'
          is_error = true
        }
      }
    }

    if (type == 'fase_name' || type == 'isError') {
      // let fase = selectedAnimal?.fase_name
      text = 'Selecione a fase de manejo em que o animal se encontra'

      // if (!fase) {
      //   error = 'Selecione a fase de manejo do animal'
      //   is_error = true
      // }
    }
    if (type == 'valor' || type == 'isError') {
      let valor = selectedAnimal?.value
      text = "Valor total do animal"

      if (!valor) {
        error = 'Valor deve ser maior que 0!'
        is_error = true
      }
    }

    if (selectedAnimal?.new_lote) {
      if (!selectedAnimal?.selectedLote?.id_livestock || !selectedAnimal?.selectedLote?.id_area) {
        is_error = true
      }
    }
    if (!selectedAnimal?.identifier && !selectedAnimal?.brinco && !selectedAnimal?.name) {
      is_error = true
    }

    if (type == 'isError') {
      return is_error
    }

    if (is_error) {
      mensagem = (<>
        <br/>
        <span style={{ color: 'red' }}>
          {error}
        </span>
      </>);
    }

    return (<>
      {text}
      {mensagem}
    </>);
  };

  const handleAdd = () => {
    const aux_add = JSON.parse(JSON.stringify(data.animals))
    selectedAnimal.discount = 0
    selectedAnimal.nome = selectedAnimal.identifier + (selectedAnimal.name ? ' | ' : '')
    selectedAnimal.nome += selectedAnimal.name + (selectedAnimal.brinco ? ' | ' : '')
    selectedAnimal.nome += selectedAnimal.brinco
    selectedAnimal.peso = (selectedAnimal.weight ? selectedAnimal.weight + " (" + selectedAnimal.weight_unit + ")" : '-')

    aux_add.push(selectedAnimal)

    var val_total = 0
    var aux_val = 0
    for (var i = 0, tam = aux_add.length; i < tam; i++) {
      aux_val = parseFloat(aux_add[i].value)
      // aux_val -= aux_val * parseFloat(aux_add[i].discount) / 100

      val_total += aux_val
      aux_add[i].value_end = aux_val
    }
    var aux_value = (data.value - data.animals_value) + val_total
    var aux_value_end = (data.value_end - data.animals_value) + val_total

    obj_fn.setData(prevState => ({ ...prevState, animals_value: val_total }))
    obj_fn.setData(prevState => ({ ...prevState, animals: aux_add }))

    obj_fn.setData(prevState => ({ ...prevState, value: aux_value }))
    obj_fn.setData(prevState => ({ ...prevState, value_end: aux_value_end }))

    // adiciona informações do novo lote
    if (selectedAnimal.new_lote) {
      let aux_lot = selectedAnimal.selectedLote
      aux_lot.name = selectedAnimal.lote
      aux_lot.weight = selectedAnimal.weight
      aux_lot.weight_unit = selectedAnimal.weight_unit
      // aux_lot.value = selectedAnimal.value
      aux_lot.fase_name = selectedAnimal.fase_name
      aux_lot.num_females += (selectedAnimal.sexo == 'female' ? 1 : 0)
      aux_lot.num_males += (selectedAnimal.sexo == 'male' ? 1 : 0)
      aux_lot.peso = (aux_lot.weight ? aux_lot.weight + " (" + aux_lot.weight_unit + ")" : '-')

      obj_fn.usestore.reposLivestock.addLoteStore(aux_lot)

      let aux_lots = JSON.parse(JSON.stringify(data.lotes))
      aux_lots.push(aux_lot)
      obj_fn.setData(prevState => ({ ...prevState, lotes: aux_lots }))
    }

    obj_fn.setData(prevState => ({ ...prevState, animal_selected: null }))
    setSelectedAnimals(default_animal)
  }

  return ( <>
    <Grid container spacing={3}>
      <Box sx={{ display: "flex", justifyContent: "center", p: 1, ml: 1, mt: 1 }}>
        <Grid container spacing={3} >
          {/* lote - sexo - dataNascimento */}
          <Grid item xs={12} sm={5}>
            <Grid container spacing={2} >
              <Grid item xs={12} sm={12}>
                <RadioGroup
                  label="seleção tipo do lote"
                  aria-label="seleção tipo do lote"
                  name="lote_radio_group"
                  value={selectedAnimal?.new_lote}
                  onChange={handleChange}
                  style={{ display: "flex", flexDirection: "row" }}
                  defaultValue={0}
                >
                  {tipo_lote.map((prog) => (
                    <FormControlLabel
                      key={prog.id}
                      value={prog.id}
                      name="new_lote"
                      control={<Radio color="success" />}
                      label={prog.label}
                    />
                  ))}
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={12} sx={{ mt: -2 }}>
                {selectedAnimal?.new_lote ? <>
                  <TextField
                    label="Nome do Lote"
                    name="lote"
                    id="lote"
                    color="success"
                    variant="standard"
                    fullWidth
                    value={selectedAnimal?.lote}
                    onChange={handleChange}
                    onBlur={(e) => handleChange(e, true)}
                    helperText={validFields('lote')}
                  />
                </> : <>
                  <AutocompleteGeneric value={selectedAnimal?.selectedLote?.id ? selectedAnimal?.selectedLote : null} options={opt_lotes} handleChange={handleChangeSelectLote} />
                </>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="faseManejo-label" color="success">
                    Fase de Manejo
                  </InputLabel>
                  <Select color="success" fullWidth variant="outlined" 
                    labelId="faseManejo-label"
                    label="Fase de Manejo" 
                    name="fase_name" 
                    value={selectedAnimal?.fase_name} 
                    onChange={(e) => handleChange(e)}
                    disabled={!selectedAnimal?.new_lote}
                    defaultValue={'Cria'}
                  >
                    {faseManejo.map((prog) => (
                      <MenuItem key={prog.id} value={prog.id} >
                        {prog.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {validFields('fase_name')}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="sexoAnimal-label" color="success">
                    Sexo do animal
                  </InputLabel>
                  <Select color="success" fullWidth variant="outlined" 
                    labelId="sexoAnimal-label"
                    label="Sexo do animal" 
                    name="sexo" 
                    value={selectedAnimal?.sexo} 
                    onChange={(e) => handleChange(e)}
                    defaultValue={'femea'}
                  >
                    <MenuItem key={"Fêmea"} value={"femea"} >
                      <Female color="secondary" />
                      Fêmea
                    </MenuItem>
                    <MenuItem key={"Macho"} value={"macho"}>
                      <Male color="primary" />
                      Macho
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    Selecione o sexo do animal
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {/* identificadores */}
          <Grid item xs={12} sm={7}>
            <Grid container spacing={2} >
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Identificador"
                  name="identifier"
                  id="identifier"
                  color="success"
                  variant="standard"
                  fullWidth
                  value={selectedAnimal?.identifier}
                  onChange={handleChange}
                  onBlur={(e) => handleChange(e, true)}
                  helperText={ <> Identificador do animal <br /> Ex: CPTMC000001 </> }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Brinco"
                  name="brinco"
                  id="brinco"
                  color="success"
                  variant="standard"
                  fullWidth
                  value={selectedAnimal?.brinco}
                  onChange={handleChange}
                  onBlur={(e) => handleChange(e, true)}
                  helperText={ <> Brinco do animal <br /> Ex: 000000000000001 </> }
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mt: -2 }}>
                <TextField
                  label="Nome"
                  name="name"
                  id="name"
                  color="success"
                  variant="standard"
                  fullWidth
                  value={selectedAnimal?.name}
                  onChange={handleChange}
                  onBlur={(e) => handleChange(e, true)}
                  helperText={ <> Nome do animal <br /> Ex: Mimosa </> }
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mt: -2 }}>
                <TextField
                  label="Sisbov"
                  name="sisbov"
                  id="sisbov"
                  color="success"
                  variant="standard"
                  fullWidth
                  value={selectedAnimal?.sisbov}
                  onChange={handleChange}
                  onBlur={(e) => handleChange(e, true)}
                  helperText={ <> Número sisbov do animal <br /> Ex: 07631000001 </> }
                  // O número SISBOV ou número de cadastro no SISBOV, é a sequência de quinze dígitos que compõe a identificação individual de bovinos ou búfalos;
                  // País: (076 || 105) + UF(2 dígitos) e o código de 6 dígitos do animal
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mt: -2 }}>
                <TextField
                  label="Raça"
                  name="race"
                  id="race"
                  color="success"
                  variant="standard"
                  fullWidth
                  value={selectedAnimal?.race}
                  onChange={handleChange}
                  onBlur={(e) => handleChange(e, true)}
                  helperText={ <> Ex1: Angus, Nelore <br /> Ex2: Misto </> }
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mt: -2 }}>
                <TextField
                  label="Peso médio"
                  name="weight"
                  id="weight"
                  type="number"
                  color="success"
                  variant="standard"
                  fullWidth
                  InputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: 12,
                    min: 0,
                  }}
                  value={selectedAnimal?.weight}
                  onChange={handleChange}
                  onBlur={(e) => handleChange(e, true)}
                  helperText={"Peso médio do animal"}
                />
              </Grid>
            </Grid>
          </Grid>
          {!selectedAnimal?.new_lote ? <></> : <>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={4} >
                <Grid item xs={12} sm={4} >
                  <AutocompleteGeneric value={selectedAnimal?.selectedLote?.livestock} options={opt_livestock} handleChange={handleChangeSelectLivestock} />
                </Grid>
                {/* area */}
                {handleAreas()}
              </Grid>
            </Grid>
          </>}
          {/* valor - btnAdd */}
          <Grid item xs={12} sm={12} sx={{ mt: -1, mb: -5 }} >
            <Grid container spacing={3} >
              <Grid item xs={12} sm={4} sx={{ mt: -2 }}>
                <SingleDateField data={data} obj_date={obj_date} />
              </Grid>
              <Grid item xs={12} sm={4}>
                {/* <CurrencyInputReal
                  required
                  id="value"
                  name="value"
                  label="Valor (R$)"
                  color="success"
                  value={selectedAnimal?.value}
                  onChange={handleChange}
                  helperText={validFields('valor')}
                /> */}
                <GenericValueField
                  handleChange={handleChange}
                  options={{
                    required: true,
                    id: "value",
                    name: "value",
                    label: 'Valor',
                    value: selectedAnimal?.value,
                    helperText: "Valor total do animal",
                    helperTextError: "Valor deve ser maior que 0!",
                    endAdornment: true,
                    width: '80%'
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button variant="contained" color="success" onClick={handleAdd} disabled={validFields('isError')}>
                  <Queue sx={{mr: 1}}/> Adicionar ao carrinho
                </Button>
                {(!selectedAnimal?.identifier && !selectedAnimal?.brinco && !selectedAnimal?.sisbov && !selectedAnimal?.nome) &&
                  <FormHelperText>
                    Informe pelo menos um dos seguintes campos:
                    <br/>
                    Identificador, Brinco, Nome
                  </FormHelperText>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  </>)
}
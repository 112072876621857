import React, { useContext, useEffect, useState } from 'react'
import { Box, Card, CardHeader, Divider, Grid, IconButton, Stack, Typography } from '@mui/material'
import { ArrowBackIosNew, ListAlt } from '@mui/icons-material'
import dayjs from 'dayjs'
import useStore from '../../store/useStore'
import InputsList from './components/InputsList'
import RolePermission from "../team/components/RolePermission"

function formatLocaleDate(data) {
  const dataFormatada = data.toLocaleDateString("default", {
    month: "short",
    year: "numeric",
  })
  return dataFormatada
}

const convertAreasToArray = (obj, array) => {
  const areasArray = Object.keys(obj[array]).map(key => obj[array][key])
  return {
    ...obj,
    [array]: areasArray
  }
}

const analizaAtividadeAgricola = (aux_list, obj_fn, aux_prods) => {
  var valid_i, valid_f

  var lista = aux_list.filter((activity) => {
    let data_f = activity.date_end ? dayjs(activity.date_end) : dayjs(obj_fn.selectedDate).add(1, 'day')

    valid_i = dayjs(activity.date_start).isBetween(dayjs(obj_fn.date_i), obj_fn.date_f, 'day')
    valid_f = dayjs(data_f).isBetween(dayjs(obj_fn.date_i), obj_fn.date_f, 'day')

    if (activity.id_harvest == obj_fn.currentProject.id && (valid_i || valid_f) ) {
      activity.areas.map((prod) => {
        if (!aux_prods[prod.id_product]) {
          var aux_area = JSON.parse(JSON.stringify(obj_fn.areas_default))
          aux_area[prod.id_area].quantity += prod.quant
          aux_prods[prod.id_product] = {
            id: prod.id_product,
            name: prod.name_product,
            total: prod.quant,
            unit: prod.unit,
            areas: aux_area
          }
        } else {
          aux_prods[prod.id_product].total += prod.quant
          aux_prods[prod.id_product].areas[prod.id_area].quantity += prod.quant
        }
      })

      if (!obj_fn.equipe_default[activity.id_user]) {
        obj_fn.equipe_default[activity.id_user] = {id: activity.id_user}
      }

      return activity
    }
  })
  return lista
}
const analizaAtividadePecuaria = (aux_list, obj_fn, aux_prods) => {
  var valid_i, valid_f

  var lista = aux_list.filter((activity) => {
    let data_f = activity.date_end ? dayjs(activity.date_end) : dayjs(obj_fn.selectedDate).add(1, 'day')

    valid_i = dayjs(activity.date_start).isBetween(dayjs(obj_fn.date_i), obj_fn.date_f, 'day')
    valid_f = dayjs(data_f).isBetween(dayjs(obj_fn.date_i), obj_fn.date_f, 'day')

    if (activity.id_livestock == obj_fn.currentProject.id && (valid_i || valid_f) ) {
      activity.lote_products.map((prod) => {
        if (!aux_prods[prod.id_product]) {
          var aux_area = JSON.parse(JSON.stringify(obj_fn.areas_default))
          aux_area[obj_fn.listLoteArea.lotes[prod.id_lote].area].quantity += prod.quant
          aux_prods[prod.id_product] = {
            id: prod.id_product,
            name: prod.name_product,
            total: prod.quant,
            unit: prod.unit,
            areas: aux_area
          }
        } else {
          aux_prods[prod.id_product].total += prod.quant
          aux_prods[prod.id_product].areas[obj_fn.listLoteArea.lotes[prod.id_lote].area].quantity += prod.quant
        }
      })
      activity.animal_products.map((prod) => {
        if (!aux_prods[prod.id_product]) {
          var aux_area = JSON.parse(JSON.stringify(obj_fn.areas_default))
          aux_area[obj_fn.listLoteArea.animais[prod.id_animal].area].quantity += prod.quant
          aux_prods[prod.id_product] = {
            id: prod.id_product,
            name: prod.name_product,
            total: prod.quant,
            unit: prod.unit,
            areas: aux_area
          }
        } else {
          aux_prods[prod.id_product].total += prod.quant
          aux_prods[prod.id_product].areas[obj_fn.listLoteArea.animais[prod.id_animal].area].quantity += prod.quant
        }
      })

      if (!obj_fn.equipe_default[activity.id_user]) {
        obj_fn.equipe_default[activity.id_user] = {id: activity.id_user}
      }

      return activity
    }
  })
  return lista
}

function createObjRow(obj_fn) {
  // inicia o array da lista com objetos
  let list_rows = []

  let aux_list = []
  var obj_list = {}
  var listLoteArea = {
    lotes: {},
    animais: {}
  }
  var areas_default = {}
  for (var i = 0, tam = obj_fn.currentProject.areas.length; i < tam; i++) {
    areas_default[obj_fn.currentProject.areas[i].id] = {
      id: obj_fn.currentProject.areas[i].id,
      area: obj_fn.currentProject.areas[i].name,
      quantity: 0
    }
  }
  obj_fn.areas_default = areas_default
  obj_fn.equipe_default = {}

  // Produtos - Insumos -> list_rows[0]
  list_rows.push({
    group_category: 'Produtos - Insumos',
    subgroups: []
  })

  var aux_prods, len_sub, i, tam

  if (obj_fn.currentProject.tipo_repos == 'Safras') {
    // aux_list = obj_fn.usestore.reposActivityPlanting.listActivityPlanting
    aux_list = obj_fn.listActivities.listActivityPlanting
    if (aux_list.length > 0) {
      // Plantios -> subgroups[0]
      list_rows[0].subgroups.push({
        category_name: 'Plantios',
        category_type: 'Produto',
        total: 0,
        products: [],
      })
      aux_prods = {}
      obj_list.listPlanting = analizaAtividadeAgricola(aux_list, obj_fn, aux_prods)
      
      len_sub = list_rows[0].subgroups.length - 1
      for (i = 0, tam = Object.keys(aux_prods).length; i < tam; i++) {
        list_rows[0].subgroups[len_sub].total += aux_prods[Object.keys(aux_prods)[i]].total
        list_rows[0].subgroups[len_sub].products.push(
          convertAreasToArray( aux_prods[Object.keys(aux_prods)[i]], 'areas' )
        )
      }
    }

    // aux_list = obj_fn.usestore.reposActivityApplications.listActivityApplications
    aux_list = obj_fn.listActivities.listActivityApplications
    if (aux_list.length > 0) {
      // Aplicações -> subgroups[0]
      list_rows[0].subgroups.push({
        category_name: 'Aplicações',
        category_type: 'Produto',
        total: 0,
        products: [],
      })
      aux_prods = {}
      obj_list.listPlanting = analizaAtividadeAgricola(aux_list, obj_fn, aux_prods)
      
      len_sub = list_rows[0].subgroups.length - 1
      for (i = 0, tam = Object.keys(aux_prods).length; i < tam; i++) {
        list_rows[0].subgroups[len_sub].total += aux_prods[Object.keys(aux_prods)[i]].total
        list_rows[0].subgroups[len_sub].products.push(
          convertAreasToArray( aux_prods[Object.keys(aux_prods)[i]], 'areas' )
        )
      }
    }

  } else { // Criações
    // localização dos animais nas Áreas
    aux_list = obj_fn.usestore.reposActivityPastureManagement.listPastureManagement
    obj_list.listPastureManagement = aux_list.filter((activity) => {
      let data_f = activity.date_exit ? dayjs(activity.date_exit) : dayjs(obj_fn.selectedDate).add(1, 'day')

      var valid_i = dayjs(activity.date_start).isBetween(dayjs(obj_fn.date_i), obj_fn.date_f, 'day')
      var valid_f = dayjs(data_f).isBetween(dayjs(obj_fn.date_i), obj_fn.date_f, 'day')
      
      if (activity.id_livestock == obj_fn.currentProject.id && (valid_i || valid_f) ) {
        if (activity.id_lote) {
          listLoteArea.lotes[activity.id_lote] = { id: activity.id_lote, area: activity.id_areamap }
        } else {
          listLoteArea.animais[activity.id_animal] = { id: activity.id_animal, area: activity.id_areamap }
        }
        return activity
      }
    })
    obj_fn.listLoteArea = listLoteArea

    // aux_list = obj_fn.usestore.reposActivityMedicines.listActivityMedicines
    aux_list = obj_fn.listActivities.listActivityMedicines
    if (aux_list.length > 0) {
      aux_prods = {}
      // Medicamentos -> subgroups[0]
      list_rows[0].subgroups.push({
        category_name: 'Medicamentos',
        category_type: 'Produto',
        total: 0,
        products: [],
      })
      obj_list.listMedicins = analizaAtividadePecuaria(aux_list, obj_fn, aux_prods)

      len_sub = list_rows[0].subgroups.length - 1
      for (i = 0, tam = Object.keys(aux_prods).length; i < tam; i++) {
        list_rows[0].subgroups[len_sub].total += aux_prods[Object.keys(aux_prods)[i]].total
        list_rows[0].subgroups[len_sub].products.push(
          convertAreasToArray( aux_prods[Object.keys(aux_prods)[i]], 'areas' )
        )
      }
    }
    // aux_list = obj_fn.usestore.reposActivityNutrition.listActivityNutrition
    aux_list = obj_fn.listActivities.listActivityNutrition
    if (aux_list.length > 0) {
      aux_prods = {}
      // Nutrição -> subgroups[1]
      list_rows[0].subgroups.push({
        category_name: 'Nutrição',
        category_type: 'Produto',
        total: 0,
        products: [],
      })
      obj_list.listNutrition = analizaAtividadePecuaria(aux_list, obj_fn, aux_prods)

      len_sub = list_rows[0].subgroups.length - 1
      for (i = 0, tam = Object.keys(aux_prods).length; i < tam; i++) {
        list_rows[0].subgroups[len_sub].total += aux_prods[Object.keys(aux_prods)[i]].total
        list_rows[0].subgroups[len_sub].products.push(
          convertAreasToArray( aux_prods[Object.keys(aux_prods)[i]], 'areas' )
        )
      }
    }
    // outras subcategorias dentro de produto
    // subgroups.push( ... )
    // subgroups[2 ... n]

    // outras categorias que não sejam produto:
    // list_rows.push( ... )
    // list_rows[1 ... n]
    //   ... subgroups.push( ... )
    //   ... subgroups[0 ... n]
  }

  // Pessoas -> list_rows[n]
  list_rows.push({
    group_category: 'Equipe',
    subgroups: []
  })
  var equipe = {}
  var aux_equipe = {}
  var role

  for (var i = 0, tam = Object.keys(obj_fn.equipe_default).length; i < tam; i++) {
    aux_list = obj_fn.usestore.reposUser.getUserStore('id', Object.keys(obj_fn.equipe_default)[i])[0]
    if (!aux_equipe[aux_list.role]) {
      role = RolePermission(aux_list.id_role)

      aux_equipe[aux_list.role] = {}
      equipe[aux_list.role] = {
        category_name: role,
        category_type: 'Membro',
        team: []
      }
    }
    if (!aux_equipe[aux_list.role][aux_list.id]) {
      aux_equipe[aux_list.role][aux_list.id] = {
        id: aux_list.id,
        name: aux_list.name,
        phone: aux_list.phone,
        email: aux_list.email
      }
      equipe[aux_list.role].team.push(aux_equipe[aux_list.role][aux_list.id])
    }
  }
  list_rows[list_rows.length-1].subgroups = (convertAreasToArray( {equipe: equipe}, 'equipe' )).equipe

  return list_rows
}

export default function InputsContent() {
  const usestore = useStore()
  const currentAtivity = JSON.parse(localStorage.getItem('creationHarvestRepos'))
  var currentProject
  const [listActivityPlanting, setListActivityPlanting] = useState(usestore.reposActivityPlanting.listActivityPlanting)
  const [listActivityApplications, setListActivityApplications] = useState(usestore.reposActivityApplications.listActivityApplications)
  const [listActivityMedicines, setListActivityMedicines] = useState(usestore.reposActivityMedicines.listActivityMedicines)
  const [listActivityNutrition, setListActivityNutrition] = useState(usestore.reposActivityNutrition.listActivityNutrition)

  if (currentAtivity.tipo_repos == 'Safras') {
    currentProject = usestore.reposHarvest.getHarvestStore('id', currentAtivity.id)[0]
  } else {
    currentProject = usestore.reposLivestock.getLivestockStore('id', currentAtivity.id)[0]
  }

  const [selectedDate, setSelectedDate] = useState(new Date())
  const handleMonth = (tipo) => {
    let date = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + (1 * (tipo == "next" ? 1 : -1))
    )
    // atualiza repos com os dados do ano desejado
    if (selectedDate.getFullYear() != date.getFullYear()) {
      var obj_filter = {
        filter: true,
        between: true,
        id_property: currentProject.id_property,
        date: '',
        dateI: dayjs(date).startOf('year').format('YYYY-MM-DD HH:mm:ss'),
        dateF: dayjs(date).endOf('year').format('YYYY-MM-DD HH:mm:ss'),
      }

      if (currentAtivity.tipo_repos == 'Safras') {
        obj_filter.date = 'ap.date_start'
        usestore.reposActivityPlanting.fetchFiltered(obj_filter).then((res) => {
          setListActivityPlanting(res)
        })
        usestore.reposActivityApplications.fetchFiltered(obj_filter).then((res) => {
          setListActivityApplications(res)
        })
      } else {
        obj_filter.date = 'am.date_start'
        usestore.reposActivityMedicines.fetchFiltered(obj_filter).then((res) => {
          setListActivityMedicines(res)
        })
        usestore.reposActivityNutrition.fetchFiltered(obj_filter).then((res) => {
          setListActivityNutrition(res)
        })
      }
    }

    setSelectedDate(date)
  }

  const obj_fn = {
    usestore: usestore,
    selectedDate: selectedDate,
    date_i: dayjs(selectedDate).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    date_f: dayjs(selectedDate).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
    currentProject: currentProject,
    listActivities: {
      listActivityPlanting: listActivityPlanting,
      listActivityApplications: listActivityApplications,
      listActivityMedicines: listActivityMedicines,
      listActivityNutrition: listActivityNutrition,
    }
  }

  let listResources = createObjRow(obj_fn)

  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12} md={8}>
              <Box sx={{ display: 'flex' }}>
                <ListAlt style={{ fontSize: 35 }} />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Recursos da Produção
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
          <div className="content">
            <Card sx={{ mt: 1 }}>
              <CardHeader
                title={
                  <>
                    <Grid container spacing={2} sx={{ mb: 1 }}>
                      <Grid item xs={12} md={8}>
                        <Box sx={{ display: 'flex' }}>
                          <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                            <Typography fontWeight="500" variant="h6">
                              Acompanhamento dos recursos de produção
                              <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                                Acompanhe a locação de recursos (insumos, equipamentos, mão de obra...) que foram incluídos na realização das atividades.
                              </Typography>
                            </Typography>
                          </Stack>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4} sx={{ alignSelf: "start", }} >
                        <Grid item  sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", }} >
                          <IconButton titile="Mês Anterior" aria-label='Mês Anterior' onClick={() => handleMonth("previous")} >
                            <ArrowBackIosNew color='success' sx={{ fontSize: 30 }} />
                          </IconButton>
                          <Box sx={{ width: 150 }}>
                            <Typography variant='h6'>
                              {formatLocaleDate(selectedDate)}
                            </Typography>
                          </Box>
                          <IconButton titile="Próximo mês" aria-label='Próximo mês' onClick={() => handleMonth("next")} >
                            <ArrowBackIosNew color='success' sx={{ transform: "rotate(180deg)", fontSize: 30, }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                }
              ></CardHeader>
              <Divider />
            </Card>
            <InputsList listResources={listResources} />
          </div>
        </div>
      </div>
    </div>
  )
}

import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ListIcon from '@mui/icons-material/List'
import { Card, CardHeader, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material'
import TableProductionCardEmpty from './TableProductionCardEmpty'
import useStore from '../../../store/useStore'
import { useState } from 'react'
import { useEffect } from 'react'
import useHarvestTargetReposStore from '../../../store/useHarvestTargetReposStore'
import Typewriter from '../../../components/Typewriter/Typewriter'
import CardRegisterProductionTargetHarvest from './CardRegisterProductionTargetHarvest'
import ButtonRegisterProductionTargetHarvest from './ButtonRegisterProductionTargetHarvest'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import FolderIcon from '@mui/icons-material/Folder'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
export default function CardRegisterProductionTargetHarvestTabs({ area, index }) {
  console.log('CardRegisterProductionTargetHarvestTabs')
  console.log(area, index)

  // const [value, setValue] = React.useState(0);
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // const [isLoading, setIsLoading] = useState(true);
  // const [isLoadingText, setIsLoadingText] = useState(false);
  // const usestore = useStore();

  // // setTimeout(() => {
  // //   setIsLoading(false);
  // // }, 1000);

  // // setTimeout(() => {
  // //   setIsLoadingText(true);
  // // }, 3000);

  // //const property_id = localStorage.getItem("Property") || 1;
  // //const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  // const reposHarvest = usestore.reposHarvest.getHarvestStore("id", usestore.reposHarvest.harvestRepos.id)[0];
  // //console.log("reposHarvest")
  // console.log(reposHarvest)

  // const listAreas = usestore.reposAreas.listAreasMap;
  // console.log("listAreas")
  // console.log(listAreas)

  // // Crie um array com os IDs das áreas
  // const areaIds = reposHarvest.areas.map((area) => area.id);
  // // console.log(areaIds)
  // // Use o método filter() para filtrar os elementos de listAreas com IDs presentes em areaIds
  // const listAreasHarvest = listAreas.filter((area) => areaIds.includes(area.id));
  // console.log(listAreasHarvest);

  // // preciso selecionar a lista de planejamento e filtrat por cultura
  // // instancia os repos do planejamento
  // // instancia os repos planning se já estiver algum planejamento
  // const reposTargetPlanning = useHarvestTargetReposStore((state) => state);
  // console.log("reposTargetPlanning");
  // //console.log(reposTargetPlanning);
  // // listas de todas as metas do harvest (safra)
  // console.log(reposTargetPlanning.listPlanning)
  // const listTargetHarvest = reposTargetPlanning.listPlanning.filter(item => item.id_harvest == usestore.reposHarvest.harvestRepos.id)
  // console.log(listTargetHarvest)

  // const arrayAreas = [];
  // for (const area of listAreasHarvest) {

  //   let target = []
  //   if (listTargetHarvest.length > 0) {
  //     console.log(area.id)
  //     console.log(listTargetHarvest)
  //     target = listTargetHarvest?.filter(item => item.id_areamap == area.id)
  //   }
  //   console.log(target)
  //   const item = { id: area.id, name: area.name, active: true, title: area.name, measure: area.measure, area: area.area, image: area.imageDecode, target: target, data: area };
  //   arrayAreas.push(item);

  // }
  // console.log("arrayAreas")
  // console.log(arrayAreas)

  // // como sei quais são as aŕeas usadas na havesrt
  // const [areas, setAreas] = useState(arrayAreas);

  // useEffect(() => {
  //   setAreas(arrayAreas)
  // }, [])

  // console.log("areasFim")
  // console.log(areas)

  return (
    <>
      <Box sx={{ width: '100%', mt: 1 }}>
        {/* {listProductionHarvest.length == 0 && <TableProductionCardEmpty title={"Safra"} />} */}
        {area && (
          <Card sx={{ mt: 1 }}>
            <CardHeader
              title={
                <>
                  <Grid container spacing={2} sx={{ mb: 1 }}>
                    <Grid item xs={12} md={12}>
                      <Box sx={{ display: 'flex' }}>
                        {/* <BarChartIcon style={{ fontSize: 35 }} /> */}
                        <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                          <Typography fontWeight="500" variant="h6">
                            {/* Produção: {nameAtivity?.tipo_repos == "Criações" ? "Criação" : "Colheita"} */}
                            Sua lista de Metas de Produção da área: <strong>{area.name}</strong>
                            <Typography fontWeight="500" variant="subtitle1" style={{ color: '#808080' }}>
                              Defina a meta de produção (quantidade a produzir, tempo de produção e custo de produção) para cada área.
                            </Typography>
                            {/* <List>
                              <ListItem>
                                <ListItemIcon>
                                  <FolderIcon />
                                </ListItemIcon>
                                <ListItemText primary="Quantidade a Produzir" secondary={'Define metas realistas e maximiza o uso de recursos.'} />
                              </ListItem>
                              <ListItem>
                                <ListItemIcon>
                                  <FolderIcon />
                                </ListItemIcon>
                                <ListItemText primary="Tempo de Produção" secondary={'Garante operações eficientes e oportunas, reduzindo riscos.'} />
                              </ListItem>
                              <ListItem>
                                <ListItemIcon>
                                  <FolderIcon />
                                </ListItemIcon>
                                <ListItemText primary="Custo de Produção" secondary={'Assegura a viabilidade econômica, orientando decisões financeiras.'} />
                              </ListItem>
                            </List> */}
                          </Typography>
                        </Stack>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12} md={4}>
                      <Box display="flex" justifyContent="flex-start">
                        <ButtonRegisterProductionTargetHarvest areas={areas} />
                      </Box>
                    </Grid> */}
                  </Grid>
                </>
              }
            ></CardHeader>
            <Divider />
          </Card>
        )}
        <CardRegisterProductionTargetHarvest area={area} index={index} />
        {/* <CardProductionHarvest production={plan} type={plan.dataArea.name} index={index} /> */}
        {/* {plan.idIndex == "1" && <CardProductionHarvest production={plan} type={plan.dataArea.name} />}*/}
      </Box>
    </>
  )
}

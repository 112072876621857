import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import { Box, Grid, Stack, TextField, Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { deDE } from '@mui/x-date-pickers/locales'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../../../../components/Loader/ThreeDotsSpinner';
import ToolipBoostrap from '../../../../../components/Tooltip/ToolipBoostrap';

const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  deDE, // use 'de' locale for UI texts (start, next month, ...)
);

export default function FormProgressRealized({ data, obj_fn }) {
  const [showText, setShowText] = useState(false);
  setTimeout(() => {
    setShowText(true);
  }, 500);

  const dateNow = new Date()
  const dayBefore = dayjs(dateNow).subtract(1, 'day');

  const [value, setValue] = useState(dayjs(dayBefore));
  const [valueEnd, setValueEnd] = useState(dateNow);

  // valor para inicializar o data
  useEffect(() => {
    obj_fn.setData(prevState => ({ ...prevState, date_start: dayjs(value).format('YYYY-MM-DD HH:mm:ss') }));
    obj_fn.setData(prevState => ({ ...prevState, date_prev_end: dayjs(valueEnd).format('YYYY-MM-DD HH:mm:ss') }));

    obj_fn.setData((prevState) => ({ ...prevState, date_end: dayjs(valueEnd).format('YYYY-MM-DD HH:mm:ss') }))
  }, [value]);

  useEffect(() => {
    obj_fn.setData(prevState => ({ ...prevState, date_prev_end: dayjs(valueEnd).format('YYYY-MM-DD HH:mm:ss') }));
    obj_fn.setData((prevState) => ({ ...prevState, date_end: dayjs(valueEnd).format('YYYY-MM-DD HH:mm:ss') }))
  }, [valueEnd]);

  useEffect(() => {
    obj_fn.setData((prevState) => ({ ...prevState, progress: 'CONCLUÍDA' }));
  }, []);

  const weekdays = { "2ª": "seg", "3ª": "ter", "4ª": "qua", "5ª": "qui", "6ª": "sex", "Sa": "sáb", "Do": "dom", }
  const longText = "Definir prazos é o caminho que direciona suas ações rumo ao sucesso na atividade pecuária, permitindo que você alcance metas, otimize recursos e garanta o progresso sustentável do seu rebanho. Com prazos definidos, você poderá monitorar o progresso da atividade.";
  const text = "Importância de definir prazo para atividade.";

  return (<>
    <Grid container spacing={2} >
      <Grid container spacing={1} sx={{ mt: 4 }}>
        <div className="d-none d-lg-block">
          <Grid container>
            <Grid item xs={12} md={12} alignItems="center" sx={{ height: 40 }}>
              {showText ? ( <>
                <Typography style={{ color: "#2e7d32" }}>
                  <Typewriter tam={60} text="Registramos 1 dia para realização desta atividade. Se preferir, é possível personalizar." />
                </Typography>
              </> ) : (
                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                  {" "} <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
        <div className="d-lg-none">
          <Grid container>
            <Grid item xs={12} md={12} alignItems="center" sx={{ height: 60 }}>
              {showText ? ( <>
                <Typography style={{ color: "#2e7d32" }}>
                  <Typewriter tam={100} text="Registramos 1 dia para realização desta atividade. Se preferir, é possível personalizar." />
                </Typography>
              </> ) : (
                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                  {" "} <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} md={12} sx={{ my: 0, height: 125 }} >
        <LocalizationProvider theme={theme} dateAdapter={AdapterDayjs} adapterLocale="pt">
          <div className="d-lg-none">
            <Stack spacing={3}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <MobileDatePicker
                    //views={['day', 'month', 'year']}
                    label="Data de Início*"
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    adapterLocale="pt"
                    inputFormat="DD/MM/YYYY"
                    dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                    renderInput={(params) => <TextField {...params} color="success" />}
                    maxDate={new Date()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MobileDatePicker
                    //views={['day', 'month', 'year']}
                    label="Data de Término*"
                    value={valueEnd}
                    onChange={(newValue) => { setValueEnd(newValue); }}
                    adapterLocale="pt"
                    inputFormat="DD/MM/YYYY"
                    dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                    renderInput={(params) => <TextField {...params} color="success" />}
                    maxDate={new Date()}
                  />
                </Grid>
              </Grid>
            </Stack>
          </div>
          <div className="d-none d-lg-block">
            <Stack spacing={3}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DesktopDatePicker
                    //views={['day', 'month', 'year']}
                    label="Data de Início*"
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    adapterLocale="pt"
                    inputFormat="DD/MM/YYYY"
                    dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                    maxDate={new Date()}
                    renderInput={(params) => <TextField {...params} color="success"
                      error={!value}
                      helperText={!value && (<p id="ht_r_value" className="form-text text-danger is-invalid">Por favor, insira uma data válida.</p>)}
                    />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DesktopDatePicker
                    //views={['day', 'month', 'year']}
                    label="Data de Término*"
                    value={valueEnd}
                    onChange={(newValue) => { setValueEnd(newValue); }}
                    color="success"
                    adapterLocale="pt"
                    inputFormat="DD/MM/YYYY"
                    dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                    maxDate={new Date()}
                    renderInput={(params) => <TextField {...params}
                      color="success"
                      error={!valueEnd}
                      helperText={!valueEnd && (<p id="ht_r_valueEnd" className="form-text text-danger is-invalid">Por favor, insira uma data válida.</p>)}
                    />}
                  />
                </Grid>
              </Grid>
            </Stack>
          </div>
          <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, ml: 2, color: "#808080" }}>
            Por padrão, o sistema considera 1 dia para realizar a atividade.
            <br />
            Caso preferir, alterare as datas como desejado.
          </Typography>
        </LocalizationProvider>
      </Grid>

      <Grid item xs={12} sm={12} sx={{ mt: -1 }}>
        <ToolipBoostrap text={text} longText={longText} />
      </Grid>
    </Grid>
  </>);
}
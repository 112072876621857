import React, { useState } from 'react'
import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import { Queue } from '@mui/icons-material'
import AutocompleteGeneric from '../../../../components/Fields/AutocompletGeneric'
// import CurrencyInputReal from '../../../../components/Fields/CurrencyInputReal'
import GenericValueField from '../../../../components/Fields/GenericValueField'
import { green } from '@mui/material/colors'

export default function FormAddProduct({ data, obj_fn }) {
  const optionsProducts = JSON.parse(JSON.stringify(obj_fn.usestore.reposProducts.listProducts))
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [location_selected, setLocation] = useState(selectedProduct?.id_location || null)

  const handleChange = (event) => {
    setSelectedProduct(prevState => ({ ...prevState, [event.target.name]: parseFloat(event.target.value) }))
  }
  const handleChangeUnit = (event) => {
    obj_fn.setData(prevState => ({ ...prevState, [event.target.name]: event.target.value }))
  }

  const handleAdd = () => {
    const aux_add = JSON.parse(JSON.stringify(data.products))
    selectedProduct.id_location = location_selected.id
    selectedProduct.packaging_unit = data.packaging_unit
    selectedProduct.discount = 0
    aux_add.push(selectedProduct)

    obj_fn.setData(prevState => ({ ...prevState, product_selected: null }))
    obj_fn.setData(prevState => ({ ...prevState, packaging_unit: 'medida' }))
    setSelectedProduct(null)
    setLocation(null)

    var val_total = 0
    var aux_val = 0
    for (var i = 0, tam = aux_add.length; i < tam; i++) {
      aux_val = parseFloat(aux_add[i].value)
      // aux_val -= aux_val * parseFloat(aux_add[i].discount) / 100

      val_total += aux_val
      aux_add[i].value_end = aux_val
    }
    var aux_value = (data.value - data.products_value) + val_total
    var aux_value_end = (data.value_end - data.products_value) + val_total

    obj_fn.setData(prevState => ({ ...prevState, products_value: val_total }))
    obj_fn.setData(prevState => ({ ...prevState, products: aux_add }))

    obj_fn.setData(prevState => ({ ...prevState, value: aux_value }))
    obj_fn.setData(prevState => ({ ...prevState, value_end: aux_value_end }))
  }

  const handleChangeSelect = (field, newValue) => {
    console.log('handleChangeSelect')
    let aux_prod = newValue ? JSON.parse(JSON.stringify(newValue)) : null

    if (aux_prod) {
      let aux_location = aux_prod.location.length > 0 ? aux_prod.location[0] : null
      aux_prod.id_patrimony = aux_location?.id_patrimony
      aux_prod.id_location = aux_location
      setLocation(aux_location)
    } else {
      setLocation(null)
    }
    setSelectedProduct(aux_prod)
    obj_fn.setData(prevState => ({ ...prevState, product_selected: aux_prod }))
  }

  const opt_products = {
    multiple: false,
    name: 'product',
    field: 'product',
    label: ('Produto para: ' + data.operationType),
    values: optionsProducts,
    variant: 'standard',
    helperText: (<>
      Selecione o produto desejado
      <br/>
      Valor unitário: {selectedProduct?.stock_cost || '-'}
    </>),
    listHeight: 225,
    disableClearable: true,
  }

  const opt_products_local = {
    multiple: false,
    name: 'product_location_' + selectedProduct?.id,
    field: 'product_location',
    label: 'Local de estoque ' + (data.operationType === "Venda" ? '(origem)' : '(destino)'),
    values: (selectedProduct?.location || []),
    variant: 'standard',
    helperText: (<>
      Local onde o produto {data.operationType === "Venda" ? 'está' : 'será'} armazenado!
      <br/>
      Quantidade em estoque: 
      {!location_selected?.quanty ? '-' : 
        location_selected?.quanty * (data.packaging_unit === 'unidade' ? 1 : (selectedProduct?.volume_pkg || 1))
      }
      {data.packaging_unit === 'unidade' ? 
        selectedProduct?.packaging_sigla ? " (" + selectedProduct?.packaging_sigla + ")" : '' :
        selectedProduct?.unit_sigla ? " (" + selectedProduct?.unit_sigla + ")" : ''
      }
    </>),
    listHeight: 225,
    disabled: !data.product_selected?.id,
    disableClearable: true,
  }
  const handleChangeLocal = (field, newValue) => {
    console.log('handleChangeLocal')
    setSelectedProduct(prevState => ({ ...prevState, id_patrimony: newValue?.id }))
    setSelectedProduct(prevState => ({ ...prevState, id_location: newValue || null }))
    let aux_local = JSON.parse(JSON.stringify(newValue))
    setLocation(aux_local)
  }

  // const para validar os campos e o button add ao carrinho
  const validQtdVal = (type) => {
    let text = ''
    var mensagem = (<></>);
    let error = '';
    let is_error = false

    if (type === 'quantidade' || type === 'isError') {
      let qtd = selectedProduct?.quant
      let qtd_estoq = location_selected?.quanty * (data.packaging_unit === 'unidade' ? 1 : (selectedProduct?.volume_pkg || 1))
      text = "Quantidade de produtos para: " + data?.operationType

      if (!qtd) {
        error = 'Quantidade deve ser maior que 0!'
        is_error = true
      } else if (data.operationType === "Venda" && qtd > qtd_estoq) {
        error = 'Não pode ser maior do que a Qtd em estoque'
        is_error = true
      }
    }
    if (type === 'valor' || type === 'isError') {
      let valor = selectedProduct?.value
      text = "Valor total dos produtos"

      if (!valor) {
        error = 'Valor deve ser maior que 0!'
        is_error = true
      }
    }
    if (type === 'isError') {
      return selectedProduct?.id ? is_error : true
    }

    if (error && selectedProduct?.id) {
      mensagem = (<>
        <br/>
        <span style={{ color: 'red' }}>
          {error}
        </span>
      </>);
    }

    return (<>
      {text}
      {mensagem}
    </>);
  };

  return ( <>
    <Grid container spacing={3} >
      <Box sx={{ display: "flex", justifyContent: "center", p: 1, ml: 1, mt: 1 }}>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={6}>
            <AutocompleteGeneric value={data.product_selected} options={opt_products} handleChange={handleChangeSelect} />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ mt: obj_fn.ismobile ? -1 : 0 }} >
            <AutocompleteGeneric value={location_selected} options={opt_products_local} handleChange={handleChangeLocal} />
          </Grid>
          <Grid item xs={12} sm={4.5}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="packaging_unit" color="success">
                Embalagem ou Medida
              </InputLabel>
              <Select
                labelId="packaging_unit"
                id="selectPackagingUnit"
                label="Embalagem ou Medida"
                name="packaging_unit"
                value={data.packaging_unit}
                onChange={(e) => handleChangeUnit(e)}
                disabled={!selectedProduct?.volume}
                color="success"
                MenuProps={{
                  sx: {
                    "&& .Mui-selected": {
                      backgroundColor: green[50]
                    },
                    "&& .MuiMenuItem-root": {
                      "&:hover": {
                        backgroundColor: green[100]
                      }
                    },
                  }
                }}
              >
                <MenuItem key={"medida"} value={"medida"}>
                  Medida ({selectedProduct?.unit_name})
                </MenuItem>
                <MenuItem key={"unidade"} value={"unidade"} >
                  Unidade/Embalagem ({selectedProduct?.packaging_name})
                </MenuItem>
              </Select>
              <FormHelperText>
                Unidade/Embalagem escolhida no cadastro do produto
                <br/>
                O produto será utlilizado por Unidade/Embalagem ou por sua medida (conteúdo)?
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3.5}>
            {/* <CurrencyInputReal
              required
              id="quant"
              name="quant"
              label="Quantidade"
              color="success"
              value={selectedProduct?.quant}
              onChange={handleChange}
              // helperText={"Quantidade de produtos"}
              disabled={!data.product_selected}
              adornment="quantidade"
              endAdornment={(
                data.packaging_unit === 'unidade' ? 
                selectedProduct?.packaging_sigla ? " (" + selectedProduct?.packaging_sigla + ")" : '' :
                selectedProduct?.unit_sigla ? " (" + selectedProduct?.unit_sigla + ")" : ''
              )}
              helperText={validQtdVal('quantidade')}
            /> */}
            <GenericValueField
              handleChange={handleChange}
              options={{
                required: true,
                id:"quant",
                name:"quant",
                label: 'Quantidade',
                value: selectedProduct?.quant,
                variant: 'outlined',
                helperText: validQtdVal('quantidade'),
                disabled: !data.product_selected,
                endAdornment: true,
                prefix: (
                  data.packaging_unit === 'unidade' ? 
                  selectedProduct?.packaging_sigla ? " (" + selectedProduct?.packaging_sigla + ") " : '' :
                  selectedProduct?.unit_sigla ? " (" + selectedProduct?.unit_sigla + ") " : ''
                )
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3.5}>
            {/* <CurrencyInputReal
              required
              id="value"
              name="value"
              label="Valor (R$)"
              color="success"
              value={selectedProduct?.value}
              onChange={handleChange}
              // helperText={"Valor total do produto"}
              disabled={!data.product_selected}
              helperText={validQtdVal('valor')}
            /> */}
            <GenericValueField
              handleChange={handleChange}
              options={{
                required: true,
                id:"value",
                name:"value",
                label: 'Valor total',
                value: selectedProduct?.value,
                variant: 'outlined',
                helperText: validQtdVal('valor'),
                disabled: !data.product_selected,
                endAdornment: true,
                prefix: " (R$) ",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button variant="contained" color="success" onClick={handleAdd} disabled={validQtdVal('isError')}>
              <Queue sx={{mr: 1}}/> Adicionar ao carrinho
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  </>)
}
import React, { useState } from 'react';
import { Box, Grid, InputAdornment, Stack, TextField, TextareaAutosize, Typography } from '@mui/material';
import { Input, Notes } from '@mui/icons-material';
import dayjs from 'dayjs';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import FooterModal from '../../../../../components/Footer/FooterModal';
import SingleDateField from '../../../../../components/Fields/SingleDateField';
// import CurrencyTextField from '../../../../../components/Fields/CurrencyTextField';
import FormApportionment from '../../Forms/FormApportionment';
import FormAccountSelection from '../../Forms/FormAccountSelection';
import AutocompletGenericList from '../../../../../components/Fields/AutocompletGenericList';
import GenericValueField from '../../../../../components/Fields/GenericValueField';

const text_title = {
  'Conta a receber': 'Por favor, defina o dinheiro previsto para entrar no caixa (venda de produtos ou serviços)',
  'Conta a pagar': 'Por favor, defina as saídas previstas (acertos com fornecedores ou quitação de contas de consumo)',
  Compra: '',
  Venda: '',
};

export default function FormEntryStep2({ data, obj_fn }) {
  const [error, setError] = useState(false);
  obj_fn.setError = setError;

  const handleChange = (event) => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    if (event.target.name === 'value') {
      obj_fn.setData((prevState) => ({
        ...prevState,
        value_end: event.target.value,
      }));
    }
  };
  // const para validar o button continuar
  const isError = () => {
    if (!data.value || !data.category || data.error_date || error) {
      return true;
    }
    return false;
  };
  const returnError = () => {
    return false;
  };

  // Monta o array e obj para o campo de cliente ou fornecedor
  let optionsPerson = [];
  if (data.operationType === 'Conta a receber') {
    optionsPerson = obj_fn.usestore.reposPerson.listCliente;
  } else {
    let aux_f = obj_fn.usestore.reposPerson.listFornecedor;
    let aux_t = obj_fn.usestore.reposPerson.listTransportadora;
    optionsPerson = [...aux_f, ...aux_t];
  }
  // console.log(data)
  const opt_person = {
    multiple: false,
    name: data.operationType === 'Conta a receber' ? 'client' : 'supplier',
    label: data.operationType === 'Conta a receber' ? 'Cliente' : 'Fornecedor',
    values: optionsPerson,
    field: data.operationType === 'Conta a receber' ? 'client' : 'supplier',
    helperText: '',
    listHeight: 225,
  };
  opt_person.helperText = data.operationType + ' de: ' + opt_person.label;

  const handleChangeSelect = (field, newValue) => {
    // console.log('handleChangeSelect');
    if (field === 'client' || field === 'supplier') {
      obj_fn.setData((prevState) => ({ ...prevState, person: newValue }));
      obj_fn.setData((prevState) => ({
        ...prevState,
        [field]: newValue?.id,
      }));
    } else {
      obj_fn.setData((prevState) => ({ ...prevState, [field]: newValue }));
    }
  };

  // Monta o obj e função para o campo de data do lançamento
  const handleChangeDate = (value) => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      date_release: dayjs(value),
    }));
    obj_fn.setData((prevState) => ({
      ...prevState,
      date_release_f: dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
    }));
    obj_fn.setData((prevState) => ({
      ...prevState,
      date_release_fc: dayjs(value).format('DD/MM/YYYY'),
    }));

    obj_fn.setData((prevState) => ({ ...prevState, dueDate: dayjs(value) }));
    obj_fn.setData((prevState) => ({
      ...prevState,
      dueDate_f: dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
    }));
    obj_fn.setData((prevState) => ({
      ...prevState,
      dueDate_fc: dayjs(value).format('DD/MM/YYYY'),
    }));
  };
  let obj_date = {
    label: 'Data do lançamento',
    field_data: 'date_release',
    text_helper: data.operationType ? 'Dia de execução da competência.' : 'Dia de execução da competência.',
    min: data.date_release,
    handleChange: handleChangeDate,
    //maxWidth: '250px',
    setData: obj_fn.setData,
  };

  // console.log(data.person)
  // console.log(opt_person)

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid item xs={12} md={12} alignItems="center" style={{ height: 20 }} sx={{ mt: -5 }}>
          <Typography style={{ color: '#2e7d32' }}>
            <Typewriter tam={0} minHeight={0} text={text_title[data.operationType]} />
          </Typography>
        </Grid>

        {/* dados gerais */}
        <Grid container spacing={3} sx={{ mb: 0, mt: 2, zIndex: 100 }}>
          <Input />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Informações do lançamento: {data?.operationType}</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 0, mt: -3 }}>
          <Grid item xs={6} sm={2}>
            <SingleDateField data={data} obj_date={obj_date} />
          </Grid>
          <Grid item xs={6} sm={4} sx={{ mt: '25px' }}>
            {/* <AutocompleteGeneric
                            value={data.person}
                            options={opt_person}
                            handleChange={handleChangeSelect}
                        /> */}
            <AutocompletGenericList value={data.person} options={opt_person} handleChange={handleChangeSelect} label={data.operationType === 'Conta a receber' ? 'Cliente' : 'Fornecedor'} />
          </Grid>

          <Grid item xs={6} sm={3.5} sx={{ mt: '25px' }}>
            <TextField
              fullWidth
              //focused
              InputLabelProps={{ style: { color: '#2e7d32' } }}
              id="descriptionEntry"
              name="descriptionEntry"
              label="Descrição"
              value={data.descriptionEntry}
              onChange={handleChange}
              //onBlur={(e) => handleChange(e, true)}
              variant="outlined"
              color="success"
              //helperText=""
              InputProps={{
                startAdornment: <InputAdornment position="start">{''}</InputAdornment>,
              }}
            ></TextField>
          </Grid>
          <Grid item xs={6} sm={2.5} sx={{ mt: '25px' }}>
            {/* <CurrencyTextField required id="value" name="value" label="Valor Total*" color="success" value={data.value} onChange={handleChange} helperText="Valor total do Lançamento" /> */}
            <GenericValueField
              handleChange={handleChange}
              options={{
                required: true,
                id: "value",
                name: "value",
                label: 'Valor Total',
                value: data.value,
                helperText: "Valor total do Lançamento",
                endAdornment: true,
              }}
            />
          </Grid>
        </Grid>

        {/* Seleção da conta */}
        <FormAccountSelection data={data} obj_fn={obj_fn} handleChange={handleChange} />

        {/* Rateio de custos */}
        <FormApportionment data={data} obj_fn={obj_fn} />

        {/* Descrição */}
        <Grid container spacing={3} sx={{ mb: 0, mt: 3 }}>
          <Notes />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
                {' '}
                Descrição geral do Lançamento
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 1, mt: 0 }}>
          <Grid item xs={12} sm={12}>
            <TextareaAutosize
              minRows={3} // Customize the initial number of rows
              maxRows={10} // Customize the maximum number of rows
              id="description"
              name="description"
              placeholder="Descreva sobre do que se trata o Lançamento"
              value={data.observations}
              onChange={handleChange}
              onBlur={(e) => handleChange(e, true)}
              style={{ width: '100%', padding: '8px' }}
            />
          </Grid>
        </Grid>
        <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
      </Grid>
    </>
  );
}

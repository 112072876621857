import { Avatar, CardContent, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import AvatarFp from "../../components/Avatar/AvatarFp";
import SimpleTable from "../../components/Tables/SimpleTable";
import PersonIcon from '@mui/icons-material/Person';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import MenuPlan from "./components/MenuPlan";
import { green, red } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LogoNameWhite from "../../assets/png/logo-name-white.png";
import CardChangePassword from "./components/CardChangePassword";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});


export default function ChangePasswordContent() {
  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  console.log('userCurrent')
  console.log(userCurrent)
  const user = userCurrent;
  console.log(user)


  const name = user.name;
  const charName = name[0].toUpperCase();
  console.log(charName);

  function stringAvatar(name) {
    return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
  }



  return (
    <>
      <div className="content-wrapper bg-white">
        <section className="content-header" style={{ marginTop: 0 }}>
          <div className="container-fluid">

            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} md={12}>
                <Typography gutterBottom variant="h5" component="div">
                  Mudar a senha
                </Typography>
                <Grid item xs={12} md={12}>
                  <CardChangePassword />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </section >
      </div >



    </>
  );
}

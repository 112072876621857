import React, { useContext, useEffect, useState } from 'react'
import { green } from '@mui/material/colors'
import { ArrowBackIosNew, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Box, Button, Collapse, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import useStore from '../../../store/useStore'
import { Context } from '../../../components/Context/AuthContext'
import ChartDonutGeneric from '../../../components/Charts/ChartDonutGeneric'
import { formatValue } from '../../../utils/functions'

function formatLocaleDate(data) {
  const dataFormatada = data.toLocaleDateString("default", {
    month: "short",
    year: "numeric",
  })
  return dataFormatada
}

function calcPorcentagem(val, total, str) {
  let val1 = (val * 100) / total
  let val2 = val1.toFixed(2)
  return str ? val2.replace('.', ',') : Number(val2)
}

function CreateRow({ key, row, openAll }) {
  const [open, setOpen] = useState(false)

  const handleToggle = () => {
    setOpen(!open)
  }

  useEffect(() => {
    setOpen(openAll)
  }, [openAll])

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton aria-label={"Expandir " + row.name} size="small" onClick={handleToggle}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
            <Typography sx={{ color: green[500] }} fontWeight="800" variant="subtitle2" onClick={handleToggle}>
              {row.name}
            </Typography>
          </Box>
        </TableCell>
        <TableCell align="right">
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <div>
              <Typography sx={{ color: green[500] }} fontWeight="500" variant="subtitle2">
                Total: {formatValue(row.valor, true)}
              </Typography>
            </div>
            <div>
              <Typography fontWeight="500" variant="subtitle2">
                Participação: {row.participacao + "%"}
              </Typography>
            </div>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 30, paddingTop: 0, paddingLeft: 0, paddingRight: 0, margin: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table aria-label="purchases">
                <TableBody>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                        <TableCell ></TableCell>
                        <TableCell align="left">Categoria</TableCell>
                        <TableCell align="right">Valor&nbsp;(R$)</TableCell>
                        <TableCell align="right">Participação&nbsp;(%)</TableCell>
                      </TableRow>
                    </TableHead>
                    {row.itens.map((item) => (
                      <TableRow key={item.nome} sx={{ '& > *': { borderBottom: 'unset' } }}>
                        <TableCell align="left">
                          <Typography sx={{ flex: '1 1 100%', fontWeight: 'bold', width: 300 }} color="inherit" variant="subtitle2" component="div">
                            {item.nome}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">{
                          /subcategory/.test(item.category) ? item.group_category : 
                          item.category == 'category' ? 'Despesa' : item.category
                        }</TableCell>
                        <TableCell align="right">{formatValue(item.valor)}</TableCell>
                        <TableCell align="right">{calcPorcentagem(item.valor, row.valor, true)}</TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

function returnChartData(isMobile, series, labels) {
  const default_chart = {
    title: "Gráfico de custos",
    series: series?.length > 0 ? series : [100],
    labels: labels?.length > 0 ? labels : ['Nenhum custo ou despesa'],
    height: isMobile.any() ? 310 : 475,
    width: isMobile.any() ? 310 : 475,
    style: {
      width: "100%",
      display: 'flex',
      justifyContent: 'center',
    }
  }

  return default_chart
}

export default function TableProductionCost() {
  const { aux_add, isMobile } = useContext(Context)
  const usestore = useStore()
  const creationHarvest = JSON.parse(localStorage.getItem('creationHarvestRepos'))

  const [openAll, setOpenAll] = useState(false)
  const handleOpenAll = () => {
    setOpenAll(!openAll)
  }

  const [selectedDate, setSelectedDate] = useState(new Date())
  const handleMonth = (tipo) => {
    let date = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + (1 * (tipo == "next" ? 1 : -1))
    )
    if (selectedDate.getFullYear() != date.getFullYear()) {
      usestore.reposCostProduction.fetch({ 'ano': date.getFullYear() })
    }

    setSelectedDate(date)
  }

  const default_row = [{
    name: "Nenhum custo ou despesa",
    valor: 0,
    participacao: 100,
    itens: []
  }]
  const [chart_data, setChartData] = useState(returnChartData(isMobile))
  const [rows, setRows] = useState([])

  const handleRows = () => {
    var mes_atual = (selectedDate.getMonth() +1)
    let list = creationHarvest.tipo_repos == "Criações" ? 'livestock' : 'harvest'
    let filter = [['id_projeto', creationHarvest.id], ['data_mes', mes_atual]]
    const row_obj = usestore.reposCostProduction.getCompostCostProductionStore(list, filter)[0]

    let custos = row_obj?.custos || []
    let total = row_obj?.valor_mes || 0

    let series = []
    let labels = []
    let all_rows = []

    custos.map((row) => {
      let porcent = calcPorcentagem(row.valor, total)
      let name = row.tipo_custo

      series.push(porcent)
      labels.push(name)
      all_rows.push({
        name: name,
        valor: row.valor,
        participacao: porcent,
        itens: row.itens
      })
      
      return row
    })

    var data_chart = returnChartData(isMobile, series, labels)

    setChartData(data_chart)
    setRows(all_rows.length > 0 ? all_rows : default_row)
  }

  useEffect(() => {
    handleRows()
  }, [aux_add, selectedDate])

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
        <Grid item xs={12} md={8}>
          <div className={isMobile.any() ? "d-lg-none" : "d-none d-lg-block"}>
            <Box display="flex" justifyContent="flex-start">
              <ChartDonutGeneric chart_data={chart_data} />
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
          <Grid item xs={12} md={12} sx={{ alignSelf: "start", }} >
            <Grid item  sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", }} >
              <IconButton titile="Mês Anterior" aria-label='Mês Anterior' onClick={() => handleMonth("previous")} >
                <ArrowBackIosNew color='success' sx={{ fontSize: 30 }} />
              </IconButton>
              <Box sx={{ width: 150 }}>
                <Typography variant='h6'>
                  {formatLocaleDate(selectedDate)}
                </Typography>
              </Box>
              <IconButton titile="Próximo mês" aria-label='Próximo mês' onClick={() => handleMonth("next")} >
                <ArrowBackIosNew color='success' sx={{ transform: "rotate(180deg)", fontSize: 30, }} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} sx={{ alignSelf: "end" }}>
            <Box display="flex" justifyContent="flex-end">
              <Button onClick={handleOpenAll} size="small" variant="contained" color={'inherit'} style={{ marginBottom: '20px' }}>
                {openAll ? 'Fechar Todos' : 'Abrir Todos'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableBody>
            {rows.map((row, index) => (
              <CreateRow key={index} row={row} openAll={openAll} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

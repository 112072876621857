import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(data, criacao, origem, destino, situacao, animais, reais, arroba, hectares) {
  return { data, criacao, origem, destino, situacao, animais, reais, arroba, hectares };
}

const rows = [createData("01/12/2023", "Bovinos 22/23", "piquete 1", "abatedouro", "VENDER", "100", "440", "4,6")];

export default function TableProduction() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>data</TableCell>
            <TableCell align="right">Criação</TableCell>
            <TableCell align="right">Origem</TableCell>
            <TableCell align="right">Destino</TableCell>
            {/* <TableCell align="right">Situação</TableCell> */}
            <TableCell align="right">Animais</TableCell>
            <TableCell align="right">@&nbsp;/ca</TableCell>
            <TableCell align="right">@&nbsp;/ha</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.data} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row.data}
              </TableCell>
              <TableCell align="right">
                <i>
                  <img className="mr-1" src="/dist/img_fp/icon-button/todas_pecuarias_pb.png" width="24"></img>
                </i>
                {row.criacao}
              </TableCell>
              <TableCell align="right">{row.origem}</TableCell>
              <TableCell align="right">{row.destino}</TableCell>
              {/* <TableCell align="right">{row.situacao}</TableCell> */}
              <TableCell align="right">{row.animais}</TableCell>
              <TableCell align="right">{row.reais}</TableCell>
              <TableCell align="right">{row.arroba}</TableCell>
              <TableCell align="right">{row.hectares}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import { Avatar, Box, Button, Container, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { green } from "@mui/material/colors";
import LogoNameWhite from "../../../assets/png/logo-name-white.png";
import FromProfile from "./FromProfile";

export default function CardProfile() {

    const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
    console.log('userCurrent')
    console.log(userCurrent)
    const user = userCurrent;
    console.log(user)
    const name = user.name;
    const charName = name[0].toUpperCase();
    console.log(charName);

    function stringAvatar(name) {
        return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
    }

    return (
        <>
            <Card sx={{ m: 2 }}>

                <CardHeader
                    sx={{}}

                    avatar={
                        <Avatar sx={{ bgcolor: green[500], ml: 4 }} aria-label="recipe">
                            {charName}
                        </Avatar>
                    }
                    action={

                        <Grid sx={{ mb: -4, mr: 2 }}>
                            {/* <MenuPlan /> */}
                        </Grid>
                    }
                    title="Pedro Henrique"
                    subheader="Email: pedro@gmail.com"
                >

                </CardHeader>

                <Divider />
            </Card >

            <Card sx={{ m: 2 }}>
                <Container component="main" maxWidth="xl" sx={{}}>
                    <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                        <FromProfile />
                    </Paper>
                </Container>
            </Card >
            <Card sx={{ m: 2 }}>
                <Container component="main" maxWidth="sm" sx={{ mb: 3 }}>
                    <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                        <Box sx={{ textAlign: "center" }}>
                            {/* <Grid container style={{ background: "#07bc0c" }} xs={12} md={12} item alignItems="center" justifyContent="center">

                                <img src={LogoNameWhite} width="200"></img>

                            </Grid> */}
                            <Typography sx={{ fontSize: 22 }} color="text.secondary" gutterBottom>
                                Maximizando a gestão de sua propriedade
                            </Typography>

                        </Box>
                        <Box sx={{ textAlign: "center" }}>
                            <Button color="success" variant="outlined" type="submit" >
                                <img src="dist/image/logo-CPT-Softwares.png" width="100"></img>
                            </Button>
                        </Box>

                    </Paper>
                </Container>
            </Card >


        </>
    );
}

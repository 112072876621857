import moment from 'moment'
import React from 'react'
import { useState } from 'react'
import Chart from 'react-apexcharts'
import ModalColorChipsUpdateDate from '../../pages/activities/components/ListActivities/ModalColorChipsUpdateDate'
import { useEffect } from 'react'

export default function CharTimeActivityProduction({ rows_lv }) {
  // const [dataActivity, setDataActivity] = useState(() => {
  //   return rows_lv.map((row, index) => ({
  //     x: row.activity,
  //     y: [new Date(row.startDate_nf).getTime(), addHoursToTimestamp(new Date(row.endDate_nf).getTime(), 24)],
  //     //fillColor: '#008FFB', // Você pode definir a cor como desejar
  //     fillColor: getNextColor(colors, index),
  //   }))
  // })
  /// formatacao da serie:

  function addHoursToTimestamp(timestamp, hours) {
    // Cria um novo objeto Date a partir do timestamp
    const date = new Date(timestamp)

    // Adiciona o número de horas à data
    date.setHours(date.getHours() + hours)

    // Retorna o timestamp da data modificada
    return date.getTime()
  }

  function getNextColor(colors, currentIndex) {
    return colors[currentIndex % colors.length]
  }
  //const colors = ['#7277D5', '#4C74E6', '#268EC3', '#36B37E']
  const colors = ['#2fc582', '#1ba466', '#077d3f', '#115c34', '#113e91', '#195ac0', '#297dd3', '#42a4ef', '#038288', '#1a9ba1', '#37c5cf']

  // Agrupando os dados por equipe
  const groupedData = {}
  rows_lv.forEach((row) => {
    if (!groupedData[row.team]) {
      groupedData[row.team] = []
    }
    groupedData[row.team].push(row)
  })

  const labels = Object.keys(groupedData)
  console.log(labels)

  const dataActivity = labels.map((label, index) => ({
    name: label,
    data: groupedData[label].map((activity, activityIndex) => ({
      x: activity.activity.toLowerCase(),
      y: [new Date(activity.startDate_nf).getTime(), addHoursToTimestamp(new Date(activity.endDate_nf).getTime(), 24)],
      fillColor: getNextColor(colors, activityIndex),
      rangeName: `range-${label.toLowerCase()}-${activityIndex}`,
    })),
  }))

  // const [dataActivity, setDataActivity] = useState(() => {
  //   return rows_lv.map((row, index) => ({
  //     x: row.activity,
  //     y: [new Date(row.startDate_nf).getTime(), new Date(row.endDate_nf).getTime()],
  //     //fillColor: '#008FFB', // Você pode definir a cor como desejar
  //     fillColor: getNextColor(colors, index),
  //   }))
  // })

  //const [dataActivity, setDataActivity] = useState(dataAct)

  console.log(dataActivity)
  console.log(dataActivity)

  // console.log('dataActivity')
  // console.log(dataActivity)

  const [modalOpen, setModalOpen] = useState(false) // Estado para controlar a abertura do modal

  const handleBarClick = (event, chartContext, config) => {
    // Aqui você pode lidar com o clique na barra do gráfico
    console.log(config) // Exibe os detalhes da barra clicada
    setModalOpen(true) // Abre o modal ao clicar na barra
  }

  const state = {
    options: {
      chart: {
        height: 350,
        type: 'rangeBar',
        events: {
          click: handleBarClick, // Adiciona um evento de clique nas barras
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          dataLabels: {
            hideOverflowingLabels: false,
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          console.log('Data point  index:', opts.dataPointIndex)
          console.log('Labels:', opts.w.globals.labels)
          var label = opts.w.globals.labels[opts.dataPointIndex] // Use o índice para acessar o rótulo correspondente
          // var label = opts.dataPoint.x // Usando diretamente o nome do ponto de dados
          var a = moment(val[0])
          var b = moment(val[1])
          var diff = b.diff(a, 'days')
          return label + ': ' + diff + (diff > 1 ? ' days' : ' day')
        },
        style: {
          colors: ['#f3f4f5', '#fff'],
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },

      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        show: false,
      },
      grid: {
        row: {
          colors: ['#f3f4f5', '#fff'],
          opacity: 1,
        },
      },
    },
    series: [
      {
        data: dataActivity,
      },
    ],
  }

  const series1 = [
    {
      data: [
        {
          x: 'Analysis',
          y: [new Date('2019-02-27').getTime(), new Date('2019-03-04').getTime()],
          fillColor: '#008FFB',
        },
        {
          x: 'Design',
          y: [new Date('2019-03-04').getTime(), new Date('2019-03-08').getTime()],
          fillColor: '#00E396',
        },
        {
          x: 'Coding',
          y: [new Date('2019-03-07').getTime(), new Date('2019-03-10').getTime()],
          fillColor: '#775DD0',
        },
        {
          x: 'Testing',
          y: [new Date('2019-03-08').getTime(), new Date('2019-03-12').getTime()],
          fillColor: '#FEB019',
        },
        {
          x: 'Deployment',
          y: [new Date('2019-03-12').getTime(), new Date('2019-03-17').getTime()],
          fillColor: '#FF4560',
        },
      ],
    },
  ]

  const series3 = [
    {
      name: 'Cliente',
      data: [
        {
          x: 'Entrada',
          y: [new Date('2019-03-05').getTime(), new Date('2019-03-08').getTime()],
        },
        {
          x: 'Vinculação',
          y: [new Date('2019-03-08').getTime(), new Date('2019-03-11').getTime()],
        },
        {
          x: 'Medicamentos',
          y: [new Date('2019-03-11').getTime(), new Date('2019-03-16').getTime()],
        },
      ],
    },
    {
      name: 'Leonardo',
      data: [
        {
          x: 'Entrada',
          y: [new Date('2019-03-02').getTime(), new Date('2019-03-05').getTime()],
        },
        {
          x: 'Medicamentos',
          y: [new Date('2019-03-06').getTime(), new Date('2019-03-09').getTime()],
        },
        {
          x: 'Medicamentos',
          y: [new Date('2019-03-10').getTime(), new Date('2019-03-19').getTime()],
        },
      ],
    },
  ]
  console.log('f-xc-')
  console.log(series3)
  console.log(dataActivity)

  return (
    <div className="app">
      <div className="row">
        <div style={{ width: '100%' }} className="bg-white w-full">
          <Chart options={state.options} series={dataActivity} type="rangeBar" height={350} />
        </div>
      </div>
      <ModalColorChipsUpdateDate open={modalOpen} handleClose={() => setModalOpen(false)} />
    </div>
  )
}

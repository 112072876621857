import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import DirectionsIcon from '@mui/icons-material/Directions';
import { Avatar, Box, ListItemAvatar, Typography } from '@mui/material';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import PanToolIcon from '@mui/icons-material/PanTool';

export default function FirstSecondAcessMapList() {
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Box>
        <ListItem
          key={null}
          disableGutters
          secondaryAction={
            <span aria-label="comment">
              <HighlightAltIcon />
            </span>
          }
        >
          <ListItemText
            primary={
              <Typography gutterBottom fontWeight={500}>
                Passo 1: No canto superior direito do mapa, clique no botão verde com a palavra "ÁREA DA PROPRIEDADE".
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
          <img alt="Imagem" src="/dist/image/areadapropriedade.png" style={{ maxWidth: '100%' }} />
        </ListItem>
      </Box>
      <Box>
        <ListItem
          key={null}
          disableGutters
          secondaryAction={
            <span aria-label="comment">
              <HighlightAltIcon />
            </span>
          }
        >
          <ListItemText
            primary={
              <Typography gutterBottom fontWeight={500}>
                Passo 2: Clicando em editar, você conseguirá atualizar as informações.
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
          <img alt="Imagem" src="/dist/image/form_mapadapropriedade.png" style={{ maxWidth: '100%' }} />
        </ListItem>
      </Box>
      <Box>
        <ListItem
          key={null}
          disableGutters
          secondaryAction={
            <IconButton aria-label="comment">
              <HighlightAltIcon />
            </IconButton>
          }
        >
          <ListItemText
            primary={
              <Typography gutterBottom fontWeight={500}>
                Passo 3: No campo Área Total, insira o tamanho da sua propriedade.
              </Typography>
            }
          />
        </ListItem>
      </Box>

      <ListItem
        key={null}
        disableGutters
        secondaryAction={
          <IconButton aria-label="comment">
            <HighlightAltIcon />
          </IconButton>
        }
      >
        <ListItemText
          primary={
            <Typography gutterBottom fontWeight={500}>
              Por fim, clique em "SALVAR".
            </Typography>
          }
        />
      </ListItem>
    </List>
  );
}

import React, { useEffect } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import CategoryIcon from '@mui/icons-material/Category';
import TutorialStockProducts from './components/tutorial/TutorialStockProducts';
import ListProductsContent from './ListProductsContent';
import ProductRegistrar from './components/ProductRegistrar/ProductRegistrar';
import TutorialStepGeral from '../../components/Tutorial/TutorialStepGeral';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ProductsContentTabs({ tab }) {
  // console.log('InventoryChoiseContentTabs');

  const [value, setValue] = React.useState(parseInt(tab || '0'));

  // const { aux_add } = useContext(Context)

  //let link = window.location.href
  // console.log(/.*\#financialcontent/.test(link))
  // // let aux_tab_active = 0;
  // // if (/.*\#financialcontent/.test(link)) {
  // //   aux_tab_active = 0;
  // // } else if (/.*\#bankaccount/.test(link)) {
  // //   aux_tab_active = 1;
  // // }
  // // const [value, setValue] = useState(aux_tab_active);

  // console.log(value);
  // console.log(tab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // Atualiza o estado 'value' quando o parâmetro de rota 'tab' muda
    // console.log('atualizou');
    // console.log(tab);
    setTimeout(() => {
      setValue(parseInt(tab || '0'));
    }, 400);
    //
    // handleChange(true, tab)
  }, []);

  useEffect(() => {
    // Atualiza o estado 'value' quando o parâmetro de rota 'tab' muda
    // console.log('atualizou');
    // console.log(tab);
    setTimeout(() => {
      setValue(parseInt(tab || '0'));
    }, 400);
    //
    // handleChange(true, tab)
  }, [tab]);

  const objText = {
    icon: <CategoryIcon sx={{ fontSize: 35 }} color="text.secondary" />, // Substitua pelo ícone desejado
    title: 'Produtos', // Título do diálogo
    subtitle: 'Passo a Passo', // Subtítulo abaixo do título
    textAnimation: 'Para registrar os produtos da propriedade, basta cadastrar os produtos e você poderá visualizá-los na lista ativa de produto ou no menu controle de estoque ', // Texto que será animado pelo componente Typewriter
    description: 'Clique no botão abaixo e siga o passo a passo:', // Descrição adicional
    textButton: ['1- Registrar os produtos', '2- Visualizar a lista de produtos', '3- Controle de estoque'], // Texto complementar ou final
    linkButton: ['openProductRegistrarModal', '/productcatalog', '/inventory'], // O primeiro agora vai abrir o modal
    // linkButton: ['openProductRegistrarModal', '/inventory'], // Primeiro é um componente, segundo é um link
    filters_prods: [['group_category']],
  };
  //

  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex' }}>
                <CategoryIcon sx={{ fontSize: 35 }} color="text.secondary" />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    {/* Produção: {nameAtivity?.tipo_repos === "Criações" ? "Criação" : "Colheita"} */}
                    Produtos
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                    Registro&nbsp;dos&nbsp;produtos&nbsp;da&nbsp;propriedade
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ gap: 2 }}>
                <ProductRegistrar />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ gap: 2 }}>
                <TutorialStepGeral objText={objText} />
                <TutorialStockProducts />
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="content mb-4">
        <div className="container-fluid">
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs textColor="inherit" TabIndicatorProps={{ style: { background: 'green' } }} value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Catálogo de Produtos" {...a11yProps(0)} />
                {/* <Tab label="Controle de Estoque" {...a11yProps(1)} />
                <Tab label="Produtos" {...a11yProps(2)} /> */}
                {/* <Tab label="Fornecedores" {...a11yProps(2)} /> */}
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <ListProductsContent />
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
             
            </TabPanel>
            <TabPanel value={value} index={2}>
            
            </TabPanel> */}
          </Box>
        </div>
      </div>
    </div>
  );
}

import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Container, Paper } from '@mui/material';
import SuccessDialog from '../../../../components/Dialog/SuccessDialog';
import { Context } from '../../../../components/Context/AuthContext';
import useStore from '../../../../store/useStore';
import api from '../../../../services/api';
import FormProductStep2 from './FormProductStep2';
import FormProductStep1 from './FormProductStep1Choise';
import FormProductStep3 from './FormProductStep3';
import FormPersonStep4 from './FormPersonStep4';
import FormPersonStep5 from './FormPersonStep5';
// URL API
// const API_URL_BASE = 'http://localhost:5000';

const data_success = {
  distination: 'productcatalog',
  name: 'Produto',
  // text_success: 'Acesse suas <...ADD DESTINO AKI...> para verificar os Clientes/Fornecedores cadastrados.',
  text_success: 'Acesse a pagina de registros para verificar os produtos cadastrados',
  distination_title: 'Registro dos produtos da propriedade',
};

export default function MultiStepFormProduct({ handleClose, filters }) {
  // console.log('MultiStepFormProduct');
  const { aux_add, setAuxAdd } = useContext(Context);
  const usestore = useStore();
  var userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const property_id = localStorage.getItem('Property') || 1;

  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: Number(property_id),
    name: '',
    id_unit: 1,
    unit_name: 'Unidade',
    id_packaging: 1,
    packaging_name: 'Unidade',
    unit_sigla: 'un',
    id_category: 32,
    category_name: 'Outros',
    location: '',
    description: '',
    cod_barra: '',
    // qr_code: "",
    brand: '',
    observacoes: '',
    image: null,
    valor_produto: 0,
    stock_quanty: 0,
    stock_cost: 0,
    stock_quanty_min: 1,
    stock_quanty_max: 0,
    volume_pkg: 0,
    altura: 0,
    largura: 0,
    profundidade: 0,
    volume: 0,
    peso_liquido: 0,
    peso_bruto: 0,
    activeList: true,
    products: [
      {
        stock_quanty: 0,
        name: '',
        id_unit: 1,
        unit_name: 'Unidade',
        stock_cost: 0,
        category: { id: 32, name: 'Outros' },
      },
    ],
  });

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (submit) => {
    // console.log('activeStep');
    // console.log(activeStep);
    // console.log(steps);
    if (submit !== 'submit' && activeStep === steps.length - 1) {
      onSubmit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      handleScroll();
    }
  };

  const handleNext2 = (submit) => {
    // console.log('activeStep');
    // console.log(activeStep);
    if (submit !== 'submit' && activeStep === steps.length - 1) {
      onSubmit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 2);
      handleScroll();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    handleScroll();
  };

  const handleBack2 = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 2);
    handleScroll();
  };

  const handleScroll = () => {
    setTimeout(() => {
      window.jQuery(window.jQuery.find('#focus_top_modal')[0]).parent()[0].scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, 300);
  };

  const validate = () => {
    // console.log('validate');
    let valid = true;
    // console.log(!data.name);
    if (!data.name) {
      valid = false;
    }
    return valid;
  };

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ['FormProductStep1', 'FormProductStep2', 'FormProductStep3', 'FormPersonStep4', 'FormPersonStep5'];

  const handleReposUpdate = (event) => {
    // atualizar o produto
    usestore.reposProducts.fetchLocationStock({ id_property: property_id }).then((res) => {
      // console.log('atualizou o LocationStock');
    });
    usestore.reposPatrimony.fetch({ id_property: property_id }).then((res) => {
      // console.log('atualizou o reposPatrimony');
    });
    usestore.reposFinances.fetchT({ id_property: property_id }).then((res) => {
      // console.log('atualizou o reposFinances');
    });
    usestore.reposProducts.fetch({ id_property: property_id }).then((res) => {
      // console.log('atualizou o reposProducts');
      setAuxAdd(aux_add + 1);
      setTimeout(() => {
        handleClose();
      }, 200);
    });
  };

  const onSubmitList = async () => {
    // console.log(data.products);
    // const dataToSubmit = {
    //   id_property: data.id_property,
    //   activeList: data.id_property,
    //   products: data.products,
    // };
    //let formOk = validate() || false]
    // console.log('onSubmitList');
    // console.log(data);
    if (true) {
      const header = { header: { 'Content-Type': 'application/json' } };
      await api.post('products/productList', data, header)
        .then((response) => {
          // console.log('response.data');
          // console.log(response.data);
          if (response.data.isSuccess) {
            toast.success('Produto cadastrado com sucesso!');
            // atualizou o produto e a transaction
            handleReposUpdate();

            usestore.reposProducts.fetch({ id_property: property_id }).then((res) => {
              usestore.reposFinances.fetch({ id_property: property_id }).then((res) => {
                setAuxAdd(aux_add + 1);
                // console.log('atualizou o reposFinances');
              });
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          // toast.error('Erro ao cadastrar produto, tente novamente mais tarde!')
          toast.error(error.response.data);
        });
    } else {
      toast.error('Por favor, preencha os campos obrigatórios!');
    }
  };

  const onSubmit = async () => {
    // console.log('onSubmit');
    // console.log(data);
    let formOk = validate();
    // console.log(formOk);

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      await api.post('products/', data, header)
        .then((response) => {
          // console.log('response.data');
          // console.log(response.data);
          if (response.data.isSuccess) {
            // console.log(response.data._value[1].insertId);
            const id = response.data._value[1].insertId;
            uploadImage(id);
            toast.success('Produto cadastrado com sucesso!');
            // atualizar o produto e a transaction
            handleReposUpdate();
          } else if (response.data) {
            toast.error(response.data);
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error(error.response.data);
        });
    } else {
      toast.error('Por favor, preencha os campos obrigatórios!');
    }
  };

  async function uploadImage(id) {
    // console.log('uploadImage');
    // toast.error(`test ${id}`);
    var id_farm = localStorage.getItem('Property') || 1;
    const fd = new FormData();
    fd.append('image', data.file, `${data.name}.png`);
    //const tipo_item = 'area';
    fd.append('item_type', 'product' || '');
    fd.append('item_id', id || 0);
    fd.append('id_farm', id_farm || 1);

    try {
      const response = await api.post('upload', fd);
      if (response.data.isSuccess) {
        toast.success(response.data.isSuccess);
        var farm_id = localStorage.getItem('Property');
        usestore.reposImages.fetch({ id_farm: farm_id }).then((res) => {
          usestore.reposAreas.fetch({ id_farm: property_id }).then((res) => {
            setTimeout(() => {
              setAuxAdd(aux_add + 1);
              // if (container) {
              //   container();
              // } else {
              //   setIsLoading(true);
              // }
            }, 200);
          });

          setTimeout(() => {
            setAuxAdd(aux_add + 1);
          }, 200);
        });
      }
    } catch (error) {
      toast.error('Erro ao salvar na Amazon');
    }
  }

  const getStepContent = (step) => {
    const obj_fn = {
      setData: setData,
      handleNext: handleNext,
      handleNext2: handleNext2,
      handleBack: handleBack,
      handleBack2: handleBack2,
      activeStep: activeStep,
      steps: steps,
      handleClose: handleClose,
      onSubmit: onSubmit,
      onSubmitList: onSubmitList,
      usestore: usestore,
      filters: filters || '',
    };
    // console.log('step');
    // console.log(step);
    switch (step) {
      case 0:
        // identificadores botões
        return <FormProductStep1 data={data} obj_fn={obj_fn} />;
      case 1:
        // identificadores quick
        return <FormProductStep2 data={data} obj_fn={obj_fn} />;
      //return true
      case 2:
        // identificadores complete
        return <FormProductStep3 data={data} obj_fn={obj_fn} />;
      //return true
      case 3:
        // confirmação dos dados quick
        return <FormPersonStep4 data={data} obj_fn={obj_fn} />;
      case 4:
        // confirmação dos dados complete
        return <FormPersonStep5 data={data} obj_fn={obj_fn} />;
      case 5:
        return (
          <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessDialog handleClose={handleClose} data={data_success} />
            </Paper>
          </Container>
        );
      default:
        return 'Não existente';
    }
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>{getStepContent(activeStep)}</Box>
    </>
  );
}

import React, { useEffect, useState } from 'react'
import { Box, Chip, Grid, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs';
import Typewriter from '../../../../../components/Typewriter/Typewriter'
import ToolipBoostrap from '../../../../../components/Tooltip/ToolipBoostrap'
import ThreeDotsSpinner from '../../../../../components/Loader/ThreeDotsSpinner'

const stage = [
  { id: 1, label: 'A FAZER' },
  { id: 2, label: 'EM PROGRESSO' },
  { id: 3, label: 'CONCLUÍDA' },
]

export default function FormProgressNow({ data, obj_fn }) {
  const [showText, setShowText] = useState(false)
  setTimeout(() => {
    setShowText(true)
  }, 500)

  const handleChange = (faseLabel) => {
    setSelectedFase(faseLabel)
    obj_fn.setData((prevState) => ({ ...prevState, progress: faseLabel }))
    if (faseLabel == 'CONCLUÍDA') {
      obj_fn.setData((prevState) => ({ ...prevState, date_end: dayjs(data.date_start).format('YYYY-MM-DD HH:mm:ss') }))
    } else {
      obj_fn.setData((prevState) => ({ ...prevState, date_end: null }))
    }
  }

  const [selectedFase, setSelectedFase] = useState('EM PROGRESSO')
  useEffect(() => {
    obj_fn.setData((prevState) => ({ ...prevState, progress: 'EM PROGRESSO' }))
  }, [])

  const longText =
    'Definir prazos é o caminho que direciona suas ações rumo ao sucesso na atividade pecuária, permitindo que você alcance metas, otimize recursos e garanta o progresso sustentável do seu rebanho. Com prazos definidos, você poderá monitorar o progresso da atividade.'
  const text = 'Importância de definir prazo para atividade.'

  return (
    <Grid container spacing={2}>
      <Grid container spacing={1} sx={{ mt: 4 }}>
        <div className="d-none d-lg-block">
          <Grid container>
            <Grid item xs={12} md={12} alignItems="center" sx={{ height: 40 }}>
              {showText ? (
                <>
                  <Typography style={{ color: '#2e7d32' }}>
                    <Typewriter tam={60} text="Com a data de hoje registrada, precisamos definir o progresso da atividade." />
                  </Typography>
                </>
              ) : (
                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                  {' '}
                  <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
        <div className="d-lg-none">
          <Grid container>
            <Grid item xs={12} md={12} alignItems="center" sx={{ height: 60 }}>
              {showText ? (
                <>
                  <Typography style={{ color: '#2e7d32' }}>
                    <Typewriter tam={100} text="Com a data de hoje registrada, precisamos definir o progresso da atividade." />
                  </Typography>
                </>
              ) : (
                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                  {' '}
                  <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} sx={{ mt: 0 }}>
        <Stack direction="row" spacing={1}>
          {stage.map((fase) => (
            <Chip key={fase.id} label={fase.label} color={selectedFase === fase.label ? 'success' : 'default'} onClick={() => handleChange(fase.label)} clickable />
          ))}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
        <ToolipBoostrap text={text} longText={longText} />
      </Grid>
    </Grid>
  )
}

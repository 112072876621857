import React, { useContext, useEffect, useState } from 'react'
import { IconButton, Menu, MenuItem, Paper } from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import dayjs from 'dayjs'
import useStore from '../../../store/useStore'
import { Context } from '../../../components/Context/AuthContext'
import ModalVisualizeBill from './outhers/ModalVisualizeBill'
import ChipStatusBill from './outhers/ChipStatusBill'

function formatValue(value) {
  var aux_value = value
  if (typeof value === 'string') {
    aux_value = parseFloat(value)
  } else if (!value) {
    aux_value = 0
  }
  var aux_val = aux_value.toFixed(2)
  var aux_val_f1 = aux_val.replace('.', ',')

  return aux_val_f1
}

const getColumns = (type) => {
  return [
    //{ id: 'id_bill', label: 'id_bill', minWidth: 50 },
    { 
      id: 'id',
      label: 'ID',
      minWidth: 50
    },
    { 
      id: 'date_expire',
      label: 'Vencimento',
      minWidth: 100,
      align: 'center',
    },
    { 
      id: 'date_payment',
      label: (type === 'income' ? 'Recebimento' : 'Pagamento'),
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'description',
      label: 'Descrição',
      minWidth: 170,
      //align: 'right',
      //format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'person',
      label: (type === 'income' ? 'Cliente' : 'Fornecedor'),
      minWidth: 100,
      //align: 'right',
      //format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'installment',
      label: 'Parcela',
      minWidth: 120,
      align: 'right',
      //format: (value) => value.toFixed(2),
    },
    {
      id: 'value',
      label: 'Valor (R$)',
      minWidth: 120,
      align: 'right',
      //format: (value) => value.toFixed(2),
    },
    {
      id: 'situation',
      label: 'Situação',
      minWidth: 120,
      align: 'center',
    },
    {
      id: 'actions',
      label: 'Ações', // Nomeie a nova coluna como 'Ações'
      minWidth: 100,
      align: 'center',
    },
  ]
}

// Função para criar dados com valores iniciais para "Ações" (ícone)
function createDataRow(item, id, date_expire, date_payment, description, person, installment, value, status) {
  return { item, id, date_expire, date_payment, description, person, installment, value, status }
}

export default function FinancialBillsTable({ type, rowsBills }) {
  const usestore = useStore()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { aux_add, setAuxAdd } = useContext(Context)
  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));

  const [obj_fn, setObjFun] = useState({
    usestore: usestore,
    isOpen: 0,
    aux_add: aux_add,
    setAuxAdd: setAuxAdd,
    userCurrent: userCurrent
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const [openShow, setOpenShow] = useState(false)
  // Gerenciamento do estado do menu
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = (e, type, idBill) => {
    setAnchorEl(null)
    if (type === 'show') {
      setOpenShow(true)
      setObjFun(prevState => ({ ...prevState, isOpen: idBill }))
    }
  }
  const handleCloseShow = () => {
    setOpenShow(false)
    setObjFun(prevState => ({ ...prevState, isOpen: 0 }))
  }

  // retorna o nome da pessoa
  function getNamePerson(id_person) {
    const person = usestore.reposPerson.getPersonStore('id', id_person)[0]
    return person.name
  }

  const [columns, setColumns] = useState([])
  const [rows, setRows] = useState([])

  useEffect(() => {
    setColumns(getColumns(type))

    const aux_list = rowsBills.map((item, index) => {
      return createDataRow(
        item,
        item.id,
        dayjs(item.date_expire).format('DD/MM/YYYY HH:mm'),
        item.date_payment ? dayjs(item.date_payment).format('DD/MM/YYYY HH:mm') : '',
        item.description,
        item.info.id_person ? getNamePerson(item.info.id_person) : '',
        (item.portion_text || item.portion),
        formatValue(item.value),
        item.status
      )
    })
    setRows(aux_list)
  }, [rowsBills, aux_add])

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="Tabela de contas">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, fontWeight: 'bold', }} >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id]
                      if (column.id === 'actions') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <IconButton aria-controls="actions-menu" aria-haspopup="true" onClick={handleMenuClick} >
                              <MoreVert />
                            </IconButton>
                            <Menu
                              id="actions-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleMenuClose}
                            >
                              {/* <MenuItem onClick={(e) => handleMenuClose(e, 'edit', row.id)}>Editar</MenuItem> */}
                              <MenuItem onClick={(e) => handleMenuClose(e, 'show', row.id)}>Visualizar</MenuItem>
                            </Menu>
                            {openShow && <ModalVisualizeBill bill={row.item} open={openShow} handleClose={handleCloseShow} obj_fn={obj_fn} />}
                          </TableCell>
                        )
                      } else if (column.id === 'situation') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <ChipStatusBill row_data={row.item} obj_fn={obj_fn} />
                          </TableCell>
                        )
                      } else {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        )
                      }
                    })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage={"Linhas por página"}
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

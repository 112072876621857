import React, { useEffect, useState, useContext } from 'react';
import Loading from '../../../../components/Loader/Loading';
import { Card, CardContent, CardMedia, Checkbox, Grid, ListItemIcon, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import useAreasMapReposStore from '../../../../store/useAreasMapReposStore';
import { Context } from '../../../../components/Context/AuthContext';
import { expandAndFade } from '../../../../components/Animations/animationKeyFrames';
import Typewriter from '../../../../components/Typewriter/Typewriter';

const useStyles = makeStyles({
  selected: {
    border: '2px solid green',
    overflow: 'auto',
  },
});

export default function ListAreasMapsHarvest({ data, setData, newArea }) {
  const classes = useStyles();
  let file = '';
  const [loading, setLoading] = useState(true);
  const [imageNew, setImageNew] = useState();
  const { aux_add } = useContext(Context);

  // ------ inicicia os repos das Areas ------
  const fetchAreas = useAreasMapReposStore((state) => state);
  const areaMapList = window.jQuery.extend(true, [], fetchAreas.listAreasMap);
  //const areaMapList = (fetchAreas.listAreasMap).map((repos) => ({ name: repos.name, id: repos.id, area: repos.area , usedArea: '' , measure: repos.measure, image: repos.image}));
  const [areas, setAreas] = useState([]);

  //data.areas = []
  // console.log(data);
  // todas as areas da lista
  // console.log(areas);

  // seleciona os ids e adiciona em data.areas
  const selectedIds = {};
  data.areas?.forEach((area) => (selectedIds[area.id] = area.id));
  // Marca áreas selecionadas
  const [selectedId, setSelectedId] = useState(selectedIds);
  // console.log('selectedIds');
  // console.log(selectedIds);

  useEffect(() => {}, []);

  const handleChange = (e, area) => {
    // entra o checkbox selecionado
    // console.log('handleChange');
    // console.log(area);
    selectedIds[area.id] = !selectedIds[area.id];
    setSelectedId({ ...selectedIds });

    if (selectedIds[area.id]) {
      setData((prevState) => ({
        ...prevState,
        areas: [...prevState.areas, area],
      }));
    } else {
      setData((prevState) => ({
        ...prevState,
        areas: prevState.areas.filter((area_prev) => area_prev.id !== area.id),
      }));
    }

    //setIsLoading(true);
  };

  const handleChangeInput = (e, area) => {
    // console.log('handleChangeInput');
    // console.log(e.target.value, area);
    const usedArea = parseFloat(e.target.value || '0');

    setData((prevState) => ({
      ...prevState,
      areas: prevState.areas.map((area_prev) => {
        if (area_prev.id === area.id) {
          return { ...area_prev, usedArea };
        }
        return area_prev;
      }),
    }));
  };

  function imageBuffer() {
    const image = areaMapList.length > 0 ? areaMapList[0].image : '';
    file = image;
    setImageNew(file);
  }

  const setMapAreas = async () => {
    imageBuffer();
    setAreas(areaMapList);
    setLoading(false);
  };

  useEffect(() => {
    if (loading) {
      setMapAreas();
    }
  }, []);

  useEffect(() => {
    setMapAreas();
  }, [newArea, aux_add]);

  return (
    <div className="row">
      <div className="col">
        {loading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            <Grid container spacing={2} sx={{ mt: 0.5 }}>
              <Grid item xs={12} md={12} alignItems="center">
                {areas.length === 0 ? (
                  <Typography style={{ color: '#2e7d32' }}>{true && <Typewriter tam={30} minHeight={0} text="Desenhe uma nova área no Gogole Maps para alocar a sua safra." />}</Typography>
                ) : (
                  <Typography style={{ color: '#2e7d32' }}>
                    {true && <Typewriter tam={60} minHeight={0} text="Crie uma nova área se desejar. Depois selecione a área e clique no botão Selecionar" />}
                  </Typography>
                )}
              </Grid>
              {areas?.map((area) => (
                <Grid key={area.id} item container alignItems="center" justifyContent="center">
                  <Card sx={{ display: 'flex' }} className={selectedId[area.id] ? classes.selected : null}>
                    <ListItemIcon>
                      <Checkbox
                        sx={{
                          '& .MuiSvgIcon-root': {
                            // Aplica os estilos ao ícone do Checkbox
                            animation: `${expandAndFade} 2s linear infinite`, // Aplica a animação expandAndFade ao ícone
                          },
                        }}
                        checked={selectedId[area.id] ? true : false}
                        onChange={(e) => handleChange(e, area)}
                        color="success"
                      />
                      <CardMedia component="img" sx={{ width: 100 }} image={area?.url || area?.imageDecode} alt={area.name} />
                      <CardContent sx={{ flex: 1 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', pl: 1, pb: 1 }}>
                          <Typography variant="subtitle1" gutterBottom>
                            Área: {area.name}
                          </Typography>
                          <Typography variant="subtitle2" gutterBottom>
                            <i className="fa fa-map text-success">
                              {' '}
                              {area.area} ({area.measure})
                            </i>
                          </Typography>
                        </Box>
                        <TextField
                          label={'Área utilizada:'}
                          disabled={!selectedId[area.id] ? true : false}
                          sx={{ marginRight: 2 }}
                          vaGridriant="standard"
                          size="small"
                          onChange={(e) => handleChangeInput(e, area)}
                          id={area.id + ''}
                          name={area.name}
                          defaultValue={selectedIds[area.id] || ''}
                        />
                      </CardContent>
                    </ListItemIcon>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </div>
    </div>
  );
}

import { Typography } from "@mui/material";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";

//https://apexcharts.com/react-chart-demos/
//https://blog.logrocket.com/charting-react-apexcharts/
export default function ChartPieHarvesting({ cultivar }) {
  console.log("cultivar1");
  console.log(cultivar.areas);

  // Calcula os valores da série com base nas áreas da cultura
  const totalArea = cultivar.areas.reduce(
    (total, area) => total + parseFloat(area.usedArea),
    0
  );
  console.log("totalArea");
  console.log(totalArea);
  const series = [
    parseFloat(totalArea), // Área Total
    parseFloat(totalArea * 0.2), // 20% da primeira área plantada
    parseFloat(totalArea * 0.6), // 60% da segunda área plantada
  ];
  console.log("series");
  console.log(series);

  const options = {
    chart: {
      type: "pie",
    },
    labels: ["Área Total", "20% da 1ª Área", "60% da 2ª Área"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div>
      <Typography variant="h6">{cultivar.cultivar}</Typography>
      <ReactApexChart options={options} series={series} type="pie" />
    </div>
  );
}

import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CardProductionharvestTabs from './CardProductionharvestTabs';
import PlanningHarvestContentchoice from '../../planningHarvest/PlanningHarvestContentchoice';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CardProductionInProgress from './CardProductionInProgress';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CardProductionStorage from './CardProductionStorage';
import AddHomeIcon from '@mui/icons-material/AddHome';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CardRegisterProductionTargetHarvestTabs from './CardRegisterProductionTargetHarvestTabs';
import CardComponentArea from './CardComponentArea';
import { useEffect } from 'react';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 0, mt: 1, ml: 0, mr: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CardProductionharvestTabsAreaProduction({ area, index }) {
  // nível 2 inferior
  console.log('CardProductionharvestTabsTotal1');
  console.log(area, index);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const activeTarget = area?.target.filter((item) => item.isActiveTargetHarvest === 1);
  console.log(activeTarget);

  const [loading, setLoading] = React.useState(area.target.length > 0 ? true : false);

  useEffect(() => {
    setLoading(area.target.length > 0 ? true : false);
  }, [area]);

  return (
    <Box sx={{ width: '100%' }}>
      <CardComponentArea area={area} index={index} />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs textColor="success" TabIndicatorProps={{ style: { background: 'green' } }} variant="scrollable" value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <ListAltIcon sx={{ mr: 0.5, mb: 0.2 }} />
                1) Lista de Metas
                <ArrowForwardIosIcon sx={{ ml: 0.5, mb: 0.1 }} style={{ fontSize: 16 }} />
              </Grid>
            }
            {...a11yProps(0)}
          />

          {loading && activeTarget.length > 0 && (
            <Tab
              label={
                <Grid container direction="row" alignItems="center">
                  <CenterFocusStrongIcon sx={{ mr: 0.5, mb: 0.2 }} />
                  2) Meta A Produzir
                  <ArrowForwardIosIcon sx={{ ml: 0.5, mb: 0.1 }} style={{ fontSize: 16 }} />
                </Grid>
              }
              {...a11yProps(1)}
            />
          )}
          {loading && activeTarget.length > 0 && (
            <Tab
              label={
                <Grid container direction="row" alignItems="center">
                  <PendingActionsIcon sx={{ mr: 0.5, mb: 0.2 }} />
                  3) Produzindo
                  <ArrowForwardIosIcon sx={{ ml: 0.5, mb: 0.1 }} style={{ fontSize: 16 }} />
                </Grid>
              }
              {...a11yProps(2)}
            />
          )}
          {loading && activeTarget.length > 0 && (
            <Tab
              label={
                <Grid container direction="row" alignItems="center">
                  <EmojiEventsIcon sx={{ mr: 0.5, mb: 0.2 }} />
                  4) Colheita
                  <ArrowForwardIosIcon sx={{ ml: 0.5, mb: 0.1 }} style={{ fontSize: 16 }} />
                </Grid>
              }
              {...a11yProps(3)}
            />
          )}

          {loading && activeTarget.length > 0 && (
            <Tab
              label={
                <Grid container direction="row" alignItems="center">
                  <AddHomeIcon sx={{ mr: 0.5, mb: 0.2 }} />
                  5) Armazenamento
                </Grid>
              }
              {...a11yProps(4)}
            />
          )}
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <CardRegisterProductionTargetHarvestTabs area={area} index={index} />
        {/* <CardRegisterProductionTargetHarvest /> */}
        {/* https://www.npmjs.com/package/gantt-task-react */}
      </TabPanel>
      {loading && (
        <>
          <TabPanel value={value} index={1}>
            <PlanningHarvestContentchoice area={area} index={index} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <CardProductionInProgress area={area} index={index} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <CardProductionharvestTabs area={area} index={index} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <CardProductionStorage area={area} />
          </TabPanel>
        </>
      )}
    </Box>
  );
}

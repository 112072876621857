import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';
import { IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, TextField, Typography } from '@mui/material';
import AgricultureIcon from "@mui/icons-material/Agriculture";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import FenceIcon from "@mui/icons-material/Fence";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function ListStockMobile() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        color='success'
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Lista de Patrimônios
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <ListPatrimonyStock />
      </StyledMenu>
    </div>
  );
}


function ListPatrimonyStock() {
  // const [image, setImage] = useState("");

  // useEffect(() => {
  //   if (data) {
  //     setImage(data);
  //   }
  // }, [data]);

  const patrimony = [
    { group: "ARROZ", type: "arrozB", nameType: "Estoque: Casa 1", identification: "sw4" },
    { group: "ARROZ", type: "arrozA", nameType: "Safra 22/23", identification: "modelo MF 290" },
    { group: "SEMENTES", type: "braquiaraA", nameType: "Armazém", identification: "modelo MF 290" },
    { group: "SEMENTES", type: "milho", nameType: "Armazém", identification: "modelo MF 290" },
  ];


  // Agrupar dados incluindo todas as informações relevantes
  const groupedData = Object.entries(
    patrimony.reduce((acc, cur) => {
      if (!acc[cur.group]) {
        acc[cur.group] = [{ group: cur.group, type: cur.type, nameType: cur.nameType }];
      } else {
        acc[cur.group].push({ group: cur.group, type: cur.type, nameType: cur.nameType });
      }
      return acc;
    }, {})
  ).map(([group, items]) => ({ group, items }));

  console.log(groupedData);

  // Estado para controlar a visibilidade dos grupos
  const [visibleGroups, setVisibleGroups] = useState({});

  const toggleGroupVisibility = (group) => {
    setVisibleGroups(prevState => ({
      ...prevState,
      [group]: !prevState[group],
    }));
  };

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: "100%",
        bgcolor: "background.paper",
        position: "relative",
        overflow: "auto",
        // maxHeight: 300,
        "& ul": { padding: 0 },
      }}
      subheader={<li />}
    >
      <TextField
        sx={{ width: "100%", maxWidth: "100%", }}
        id="filled-search"
        label="Pesquisar Patrimônio"
        type="search"
        variant="filled"
        color="success"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
            // style={{ display: showClearIcon }}
            //onClick={handleClick}
            >
              <ClearIcon />
            </InputAdornment>
          )
        }}
      />
      {groupedData.map((groupData) => (
        <li key={`section-${groupData.group}`}>
          <ul>
            <ListSubheader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {groupData.group}
              <IconButton onClick={() => toggleGroupVisibility(groupData.group)}>
                {visibleGroups[groupData.group] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </ListSubheader>
            {visibleGroups[groupData.group] && groupData.items.map((item) => (
              <>
                {/* <ListItem key={`${item}`} secondaryAction={<MenuCardSales />}> */}
                <ListItem key={`${item.type}`} sx={{ ml: -2 }} secondaryAction={<MoreVertIcon />}>
                  <ListItemButton>
                    <ListItemAvatar>
                      {item.group === "Veículos" && <AgricultureIcon style={{ height: "32px", width: "32px" }} sx={{ mt: -1 }} />}
                      {item.group === "Unidade Armazenadora" && <WarehouseIcon style={{ height: "32px", width: "32px" }} />}
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${item.type}`}
                      secondary={
                        <React.Fragment>
                          <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
                            {item.nameType}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </>
            ))}
          </ul>
        </li>
      ))}
    </List>
  );
}

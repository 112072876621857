import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Collapse, Divider, Grid, IconButton, Paper, Stack, Typography } from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { ExpandMore, KeyboardArrowDown, KeyboardArrowUp, PersonPin, Widgets } from '@mui/icons-material'
import { green } from '@mui/material/colors'

// Calcula as porcentagens com base em 'val' e 'total' informados
function calcPorcentagem(val, total, str) {
  let val1 = (val * 100) / total
  let val2 = val1.toFixed(2)
  return str ? val2.replace('.', ',') : Number(val2)
}

const ProductRow = ({ product, productIndex, total }) => {
  // Estado local para controlar se o produto está expandido ou não
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow>
        <TableCell >
          <IconButton aria-label={"Detalhar: " + product.name} size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell  component="th" scope="row">
          <Typography sx={{ flex: '1 1 100%', fontWeight: 'bold' }} color="inherit" variant="subtitle2" component="div"  onClick={() => setOpen(!open)}>
            <Widgets fontSize="small" color="action" sx={{ mr: 1 }} />
            {product.name}
          </Typography>
        </TableCell>
        <TableCell align="right" >
          {product.total + ' ' + product.unit}
        </TableCell>
        <TableCell align="right" >
          {calcPorcentagem(product.total, total, true)}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0, margin: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 0 }}>
              <Typography sx={{ ml: 12, mt: 2, mb: 1 }}>Áreas</Typography>
              <TableContainer>
                <Table aria-label={"Tabela das áreas"}>
                  <TableBody>
                    {product.areas.map((area, areaIndex) => (
                      <TableRow key={areaIndex} sx={{ '& > td': { border: '0px solid #e0e0e0' } }}>
                        <TableCell align="left" />
                        <TableCell align="left" >
                          <Typography sx={{ flex: '1 1 100%', fontWeight: 'bold',  ml: 3 }} color="inherit" variant="subtitle2" component="div">
                            {area.area}
                          </Typography>
                        </TableCell>
                        <TableCell align="right" >
                          {area.quantity + ' ' + product.unit}
                        </TableCell>
                        <TableCell align="right" >
                          {calcPorcentagem(area.quantity, product.total, true)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
const TeamRow = ({ team, teamIndex }) => {
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell></TableCell>
        <TableCell component="th" scope="row" align="left">
          <Typography sx={{ flex: '1 1 100%', fontWeight: 'bold' }} color="inherit" variant="subtitle2" component="div">
            <PersonPin fontSize="small" color="action" sx={{ mr: 1 }} />
            {team.name}
          </Typography>
        </TableCell>
        <TableCell align="left">{team.email}</TableCell>
        <TableCell align="right">{team.phone}</TableCell>
      </TableRow>
    </>
  )
}

const InputsList = ({ listResources }) => {
  return (
    <>
      {listResources.map((group, index) => (
        <React.Fragment key={index}>
          <Divider sx={{ mb: 3 }} />
          <div className="container-fluid">
            <Grid item xs={12} md={8}>
              <Box sx={{ display: 'flex' }}>
                <Stack spacing={0.5} sx={{ ml: 1, mt: -0.5 }}>
                  <Typography sx={{ color: green[500] }} fontWeight="bold" variant="subtitle1">
                    {group.group_category}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            {group.subgroups.map((subgroup, index) => (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls={"Painel: " + subgroup.category_name} id={"painel_" + subgroup.category_name}>
                  <Typography>{subgroup.category_name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <TableContainer component={Paper}>
                      <Table aria-label={"Lista: " + subgroup.category_type + "_" + index}>
                        <TableHead>
                          <TableRow sx={{ border: '0px solid #e0e0e0' }}>
                            <TableCell align="left" sx={{ width: 50 }} />
                            <TableCell align="left" >
                              {subgroup.category_type}
                            </TableCell>
                            <TableCell align={subgroup.team ? 'left' : 'right'} sx={{ maxWidth: 340 }}>
                              {subgroup.team ? 'Email' : 'Quantidade'}
                            </TableCell>
                            <TableCell align="right" sx={{ maxWidth: 340 }}>
                              {subgroup.team ? 'Telefone' : 'Participação (%)'}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {subgroup.products && subgroup.products.map((product, productIndex) => 
                            <ProductRow key={"subgroup_" + index + "_" + product.name} product={product} productIndex={productIndex} total={subgroup.total} />
                          )}
                          {subgroup.team && subgroup.team.map((team, teamIndex) => 
                            <TeamRow key={"subgroup_" + index + "_" + team.name} team={team} teamIndex={teamIndex} />
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </React.Fragment>
      ))}
    </>
  )
}

export default InputsList

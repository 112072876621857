import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import api from '../../services/api';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import LZString from 'lz-string';

// create the store
const useActivityMonitoringReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listActivityMonitoring: [],
        fetch: async (item) => {
          var id_property = localStorage.getItem('Property') || 1;
          if (!item) {
            item = { id_property: id_property };
          } else if (!item.id_property) {
            item.id_property = id_property;
          }
          const header = { header: { 'Content-Type': 'application/json' } };
          const response = await api.post('activityMonitoring/list', item, header);
          console.log('Monitoringx');

          const data = await response.data;
          console.log(data);
          console.log(data._value);

          await set({
            listActivityMonitoring: data._value.map((activityMonitoring) => {
              console.log(activityMonitoring);
              return {
                id: activityMonitoring.id,
                id_property: activityMonitoring.id_property,
                id_user: activityMonitoring.id_user,
                name_user: activityMonitoring.name_user,
                id_harvest: activityMonitoring.id_harvest,
                areas: [{ id_area: activityMonitoring.id_area, name: activityMonitoring.name_area, usedArea: activityMonitoring.heatmapRadius, area: activityMonitoring.area_area }],
                id_area: activityMonitoring.id_area,
                monitoringName: activityMonitoring.monitoringName ? activityMonitoring.monitoringName : '',
                typeScale: activityMonitoring.typeScale,
                heatmapRadius: activityMonitoring.heatmapRadius ? activityMonitoring.heatmapRadius : '',
                heatmapWeight: activityMonitoring.heatmapWeight ? activityMonitoring.heatmapWeight : '',
                areaTranferImage: activityMonitoring.areaTranferImage ? activityMonitoring.areaTranferImage : '',
                marker: activityMonitoring.marker ? JSON.parse(base64_decode(activityMonitoring.marker)) : '',
                zoom: activityMonitoring.zoom ? activityMonitoring.zoom : '',
                image: activityMonitoring.image,
                imageArea: activityMonitoring.imageArea,
                name_area: activityMonitoring.name_area,
                coordinates_area: JSON.parse(base64_decode(activityMonitoring.coordinates_area)),
                zoom_area: activityMonitoring.zoom_area,
                area_area: activityMonitoring.area_area,
                measure_area: activityMonitoring.measure_area,
                image_area: LZString.decompressFromBase64(activityMonitoring.image_area),
                date_start: dayjs(activityMonitoring.date_start).format('YYYY-MM-DD HH:mm:ss'),
                date_start_f: dayjs(activityMonitoring.date_start).format('DD/MM/YYYY'),
                date_prev_end: dayjs(activityMonitoring.date_prev_end).format('YYYY-MM-DD HH:mm:ss'),
                date_prev_end_f: dayjs(activityMonitoring.date_prev_end).format('DD/MM/YYYY'),
                date_end: activityMonitoring.date_end ? dayjs(activityMonitoring.date_end).format('YYYY-MM-DD HH:mm:ss') : '',
                date_end_f: activityMonitoring.date_end ? dayjs(activityMonitoring.date_end).format('DD/MM/YYYY') : '',
                description: activityMonitoring.description,
                isDeleted: activityMonitoring.isDeleted,
                id_transaction: activityMonitoring.id_transaction ? activityMonitoring.id_transaction : '',
                type: 'activity',
                activity: activityMonitoring.name,
                name_activity: activityMonitoring.name,
                progress: activityMonitoring.progress || 'CONCLUÍDA',
                planning: activityMonitoring.planning || 'realized',
                tipo_repos: 'MONITORING',
              };
            }),
          });

          const lista = JSON.parse(localStorage.getItem('listActvsH'));
          console.log('MONITORINGX');
          console.log(lista);
          const lista_i = get().listActivityMonitoring;
          console.log(lista_i);

          let array_aux = lista.filter((activity) => {
            if (!/MONITORING.*/.test(activity.activity)) {
              return activity;
            }
          });
          const res = array_aux.concat(lista_i);
          console.log(res);
          localStorage.setItem('listActvsH', JSON.stringify(res));

          return data;
        },
        // pega o(s) plantio(s) da listactivityMonitoring de acordo com o [key e val] informados
        getActivityMonitoringStore: (key, val) => {
          return get().listActivityMonitoring.filter((activityMonitoring) => {
            if (activityMonitoring[key] == val) {
              return activityMonitoring;
            }
          });
        },
        // // Adiciona um nov plantio à listactivityMonitoring
        addActivityMonitoringStore: (monitoring) => {
          set((state) => ({
            listActivityMonitoring: [...state.listActivityMonitoring, monitoring],
          }));
        },
        // Gera uma nova listactivityMonitoringsem a activity informada
        delMonitoringStore: (id) => {
          set({
            listActivityMonitoring: get().listActivityMonitoring.filter((activity) => {
              if (activity.id != id) {
                return activity;
              }
            }),
          });
        },
        // atualiza listActivityPlanting de acordo com id, key e val informados
        updateActivityMonitoringStore: (id, key, val) => {
          set({
            listActivityMonitoring: get().listActivityMonitoring.map((activity) => {
              if (activity.id === id) {
                if (key == 'all') {
                  // atualiza todos os dados
                  activity = val;
                } else {
                  activity[key] = val;
                }
              }
              return activity;
            }),
          });
          return get().listActivityMonitoring;
        },
      }),
      { name: 'reposActivityMonitoring' }
    )
  )
);

export default useActivityMonitoringReposStore;

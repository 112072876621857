import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Tabs, Tab, Typography } from "@mui/material";
import TablesHarvest from "./TablesHarvest";
import DeleteIcon from "@mui/icons-material/Delete";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CardHarvests() {
  const [value, setValue] = useState(0);
  const [tab_active, setTabActive] = useState("ativa");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    var aux_val = ["ativa", "finalizada", "inativa", "excluida"];
    setTabActive(aux_val[newValue]);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs textColor="success" TabIndicatorProps={{ style: { background: "green" } }} variant="scrollable" value={value} onChange={handleChange} aria-label="basic tabs example"
          sx={{
            ".Mui-selected": {
              'color': "#2e7d32 !important",
              'fontWeight': 'bold',
            },
          }} >
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <PendingActionsIcon sx={{ mr: 0.5, mb: 0.2 }} />
                Atuais/Ativas
              </Grid>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <PublishedWithChangesIcon sx={{ mr: 0.5, mb: 0.2 }} />
                Finalizadas
              </Grid>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <ContentPasteOffIcon sx={{ mr: 0.5, mb: 0.2 }} />
                Inativas
              </Grid>
            }
            {...a11yProps(2)}
          />
          <Tab
            label={
              <Grid container direction="row" alignItems="center">
                <DeleteIcon sx={{ mr: 0.5, mb: 0.2 }} />
                Lixeira
              </Grid>
            }
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>
      <Box sx={{}}>
        <Typography>
          <TablesHarvest tab_active={tab_active} />
        </Typography>
      </Box>
    </Box>
  );
}

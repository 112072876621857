import React, { useContext } from 'react'
import { Button, DialogActions, Container, Dialog, DialogContent, DialogTitle } from '@mui/material'
import dayjs from "dayjs"
import FormMainConfirm from '../financial/components/FormMain/FormMainConfirm'
import { Context } from '../../components/Context/AuthContext'
import useStore from '../../store/useStore'

export default function TableBuySalesOrderContent({ row, open, handleClose, type }) {
  const usestore = useStore()
  const { isMobile } = useContext(Context)
  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"))

  if (!row) {
    return (<></>)
  }
  const row_aux = row.row

  let aux_person = null
  if (row_aux.id_person) {
    aux_person = usestore.reposPerson.getCompostPersonStore([
      ['id', row_aux.id_person], ['type', (type === 'expense' ? 'Fornecedor' : 'Cliente')]
    ])[0]
  }
  if (row_aux.id_trans_pay) {
    row_aux.arr_installment = usestore.reposBill.getBillStore('id_transaction', row_aux.id_trans_pay)
  }

  let text_type = (type === 'expense' ? "Compra" : "Venda")

  const data = {
    id: row_aux.id,
    id_user: row_aux.info_entry?.id_user || userCurrent.id,
    id_property: row_aux.id_property,
    operationType: text_type,
    operationName: text_type,

    //step1
    //  Informações do lançamento
    date_release: dayjs(row_aux.date_entry),
    date_release_f: dayjs(row_aux.date_entry).format("YYYY-MM-DD HH:mm:ss"),
    date_release_fc: dayjs(row_aux.date_entry).format("DD/MM/YYYY"),
    date_delivery: dayjs(row_aux.date_payment),
    date_delivery_f: dayjs(row_aux.date_payment).format("YYYY-MM-DD HH:mm:ss"),
    date_delivery_fc: dayjs(row_aux.date_payment).format("DD/MM/YYYY"),
    supplier: (type === 'expense' ? row_aux.id_person : null),
    client: (type === 'expense' ? null : row_aux.id_person),
    person: aux_person,
    //  produtos adicionados
    products: row_aux.products,
    product_selected: null,
    products_value: 0,
    packaging_unit: 'medida',

    //  lotes adicionados
    lotes: row_aux.lotes,
    lote_selected: null,
    lotes_value: 0,
    //  animais adicionados
    animals: row_aux.animals,
    animal_selected: null,
    animals_value: 0,

    //  patrimonios adicionados
    // patrimonys: [],
    // patrimony_selected: null,
    // patrimonys_value: 0,

    //  serviços adicionados
    // services: [],
    // service_selected: null,
    // services_value: 0,

    //  Vincular com e descrição
    projects: [],
    projects_apport: row_aux.info_entry?.aport || [],
    description: row_aux.description,

    //step2
    //  Condições pagamento
    dueDate: dayjs(row_aux.date_payment),
    dueDate_f: dayjs(row_aux.date_payment).format("YYYY-MM-DD HH:mm:ss"),
    dueDate_fc: dayjs(row_aux.date_payment).format("DD/MM/YYYY"),

    installment: row_aux.info_entry?.installment || 'a_vista',
    installmentInterval: 30,
    formPayment: row_aux.info_entry?.formPayment,
    bankAccount: row_aux.info_entry?.id_bank,
    payed: false,
    //  Previsão de pagamento
    fee: row_aux.info_entry?.fee || 0,
    penalty: row_aux.info_entry?.penalty || 0,
    discount: row_aux.info_entry?.discount || 0,
    value: row_aux.value,
    value_end: row_aux.value,
    //  Parcelas do pagamento
    arr_installment: row_aux.arr_installment || [],

    error_date: false,
  }

  const obj_fn = {
    usestore: usestore,
    ismobile: isMobile.any(),
  }

  return (
    <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
      <DialogTitle>
        Pedido de {text_type + " #" + data.id}
      </DialogTitle>

      <DialogContent>
        <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
          {/* <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}> */}
            <FormMainConfirm data={data} obj_fn={obj_fn} visualize={true}/>
          {/* </Paper> */}
        </Container>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="success" onClick={handleClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

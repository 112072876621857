import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Box, Button, Card, CardHeader, Divider, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { format } from 'date-fns'
import SearchIcon from '@mui/icons-material/Search'
// import InventoryHistversion2 from './InventoryHistversion2'
import InventoryHistCmp from './InventoryHistCmp'
import { formatValue } from '../../../utils/functions'
// import useFinancesReposStore from '../../../store/useFinancesReposStore'
// import usePatrimonyReposStore from '../../../store/usePatrimonyReposStore'
// import useUserReposStore from '../../../store/useUserReposStore'
import useStore from '../../../store/useStore'

const columns = [
  { id: 'data', label: 'Data', minWidth: 10 },
  { id: 'name', label: 'Itens', minWidth: 10 },
  { id: 'type', label: 'Tipo', minWidth: 10, align: 'center' },
  { id: 'localOrig', label: 'Origem', minWidth: 100 },
  { id: 'localDest', label: 'Destino', minWidth: 100 },
  { id: 'user', label: 'Equipe', minWidth: 10 },
  { id: 'quanty', label: 'Quantidade', minWidth: 80, align: 'right' },
  { id: 'valor', label: 'Valor(R$)', minWidth: 80, align: 'right', format: (value) => value.toFixed(2) },
]

function createData(data, name, type, localOrig, localDest, user, quanty, valor) {
  return { data, name, type, localOrig, localDest, user, quanty, valor }
}

export default function InventoryHist() {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedProduct, setSelectedProduct] = useState(null)
  const usestore = useStore();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleProductClick = (product) => {
    setSelectedProduct(product)
  }

  const clearSelectedProduct = () => {
    setSelectedProduct(null)
  }

  // const userCurrent = JSON.parse(localStorage.getItem('userCurrent'))
  // const property_id = localStorage.getItem('Property') || 1

  // const reposFinances = useFinancesReposStore((state) => state)
  //console.log(reposFinances.listTransactions)
  const reposlistTransactionsFilter = usestore.reposFinances.listTransactions.filter(
    (transaction) =>
      transaction.id_property === 1 &&
      (transaction.type === 'Entrada de produtos' ||
        transaction.type === 'Saída de produtos' ||
        transaction.type === 'Movimentar produtos' ||
        transaction.type === 'Ajuste de produtos' ||
        transaction.type === 'Aplicação de Medicamentos' || 
        transaction.type === 'Manutenção de patrimônio')
  )

  // console.log('reposlistTransactionsFilter ')
  // console.log(reposlistTransactionsFilter)

  // const reposPatrimony = usePatrimonyReposStore((state) => state)

  // const reposUser = useUserReposStore((state) => state)
  // console.log(reposUser.listUser)

  // Convertendo os dados para o formato esperado pelas linhas da tabela
  const formattedRows = reposlistTransactionsFilter.map((transaction) => {
    // console.log(transaction.info)

    // definir patrimônio
    const patrimony_origin = usestore.reposPatrimony.patrimonyLocation.filter((item) => item.id === transaction.info.id_patrimony_origin)[0]
    const patrimony_destiny = usestore.reposPatrimony.patrimonyLocation.filter((item) => item.id === transaction.info.id_patrimony_destiny)[0]
    // console.log(patrimony_origin?.name)
    // console.log(patrimony_destiny?.name)

    const user = usestore.reposUser.listUser.filter((item) => item.id === transaction.id_user)[0]

    // definir equipe
    // const user = userCurrent
    // console.log(user)
    // const nameUser = user.name
    // const charName = nameUser[0].toUpperCase()
    // console.log(charName)

    return createData(
      format(new Date(transaction.date_entry), 'dd/MM/yyyy'), // Data
      transaction.info.name, // Itens
      `${transaction.info.type}:${transaction.info.subtype}`,
      //`Fornecedor: ${transaction.info.name}`, // Origem (Exemplo, você pode ajustar conforme necessário)
      //`Estoque: ${transaction.info.name}`,
      patrimony_origin?.name ? patrimony_origin?.name : transaction.info.id_patrimony_origin,
      // `Estoque: ${patrimony_destiny?.name}`, // Destino (Exemplo, você pode ajustar conforme necessário)
      patrimony_destiny?.name ? patrimony_destiny?.name : transaction.info.id_patrimony_destiny,
      user.name,
      transaction.quanty, // Quantidade
      formatValue(transaction.value) // Valor
    )
  })

  // console.log(productList)
  // console.log(formattedRows)

  const filteredProducts = formattedRows?.filter((product) => product.name?.toLowerCase().includes(searchTerm.toLowerCase()))

  return (
    <div>
      <Box sx={{ width: '100%', mb: 2 }}>
        {/* {listPlanning.length === 0 && <TableProductionCardEmpty title={"Criação"} />} */}
        <Card sx={{ mt: 1 }}>
          <CardHeader
            title={
              <>
                <Grid container spacing={2} sx={{ mb: 1 }}>
                  <Grid item xs={12} md={12}>
                    <Typography sx={{ mt: 0 }} variant="subtitle2">
                      <strong>Histórico do produto: </strong>Analise o histórico de entrada e saída de cada categoria de produto.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography sx={{ mt: 0 }} variant="subtitle2">
                      A seguir adicione o nome do produto desejado no campo de pesquisa.
                    </Typography>
                  </Grid>
                </Grid>
              </>
            }
          ></CardHeader>
          <Divider />
        </Card>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, p: 1 }}>
        {/* Dropdown para Filtros */}

        {/* Campo de Pesquisa */}
        <TextField
          //sx={{ width: "100%", maxWidth: "100%", }}
          sx={{ width: '30%', maxWidth: '30%' }}
          label="Pesquisar Produto"
          id="filled-search"
          //variant="filled"
          type="search"
          size="small"
          color="success"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            // endAdornment: (
            //   <InputAdornment
            //     position="end"
            //   // style={{ display: showClearIcon }}
            //   //onClick={handleClick}
            //   >
            //     <ClearIcon />
            //   </InputAdornment>
            // )
          }}
        />
      </Box>
      {searchTerm && ( // Renderizar a lista somente se houver um termo de pesquisa
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredProducts.map((product, index) => (
                  <TableRow key={index} onClick={() => handleProductClick(product)}>
                    {columns.map((column) => (
                      <TableCell key={column.id}>{product[column.id]}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {selectedProduct && (
        <div>
          <Button onClick={clearSelectedProduct} variant="contained" color="secondary" style={{ marginTop: '20px' }}>
            Voltar para Lista
          </Button>
          <div style={{ marginTop: '20px' }}>
            <h2>Dados do Produto Selecionado:</h2>
            <p>Data: {selectedProduct.data}</p>
            <p>Itens: {selectedProduct.name}</p>
            <p>Tipo: {selectedProduct.type}</p>
            <p>Origem: {selectedProduct.localOrig}</p>
            <p>Destino: {selectedProduct.localDest}</p>
            <p>Equipe: {selectedProduct.user}</p>
            <p>Quantidade: {selectedProduct.quanty}</p>
            <p>Valor (R$): {formatValue(selectedProduct.valor)}</p>
          </div>
          <Box sx={{ mt: 2, p: 1 }}>
            <InventoryHistCmp />
          </Box>
        </div>
      )}

      {/* <InventoryHistversion2 /> */}
    </div>
  )
}

import React from "react";
import Nav from "../../components/Nav/Nav";
import NavSide from "../../components/Nav/NavSide";
import NavBotton from "../../components/Nav/NavBotton";
import ProductionCostContent from "./ProductionCostContent";
import Footer from "../../components/Footer/Footer";

export default function ProductionCost() {
  return (
    <>
      {/* <NavSide /> */}
      {/* <Nav /> */}
      {/* <NavBotton /> */}
      <ProductionCostContent />
      <Footer />
    </>
  );
}

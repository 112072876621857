import React, { useState } from 'react';
import { AppBar, Box, Button, Container, Dialog, IconButton, Paper, Slide, Toolbar, Typography } from "@mui/material";
import { AddBox, Close } from "@mui/icons-material";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MultiStepFormBank from "./addBank/MultiStepFormBank";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalAddBank() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="inherit" startIcon={<AccountBalanceIcon />} onClick={handleClickOpen}>
        Cadastrar Banco
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Box sx={{ width: "100%" }} id="focus_top_modal">
          <AppBar elevation={1} sx={{ position: "relative" }} style={{ background: "#fff" }}>
            <Toolbar>
              <Typography sx={{ color: "#000", flexGrow: 1, textAlign: "center" }} variant="h6" component="div">
                Cadastrar Banco
              </Typography>
              <Typography sx={{ flexGrow: 1, textAlign: "start" }} variant="h6" component="div"></Typography>
              <IconButton edge="center" color="inherit" onClick={handleClose} aria-label="close">
                <Close sx={{ color: "#000" }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Container component="main" maxWidth="md" sx={{}}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
              <MultiStepFormBank handleClose={handleClose} />
            </Paper>
          </Container>
        </Box>
      </Dialog>
    </div>
  );
}

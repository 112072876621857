import React from 'react';
import { Box, Card, CardHeader, Divider, Grid, Stack, Typography } from '@mui/material';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import FinancialChoiceContentTabs from './FinancialChoiceContentTabs';
import RegisterPerson from './components/SingleRegsiter/RegisterPerson';
import Typewriter from '../../components/Typewriter/Typewriter';
import { useState } from 'react';
import TutorialFinancial from './components/Tutorial/TutorialFinancial';

export default function FinancialContent() {
  const [isLoadingText, setIsLoadingText] = useState(false);

  setTimeout(() => {
    setIsLoadingText(true);
  }, 3000);

  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12} md={8}>
              <Box sx={{ display: 'flex' }}>
                <LocalAtmIcon sx={{ fontSize: 35 }} color="text.secondary" />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Financeiro
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={10} md={3}>
              <RegisterPerson />
            </Grid>
            <Grid item xs={2} md={1} justifyContent="flex-end">
              <TutorialFinancial />
            </Grid>
          </Grid>
        </div>
      </div>

      <Card sx={{ m: 2 }}>
        <CardHeader
          title={
            <>
              <div className="d-lg-none">
                <Box sx={{ display: 'flex', mb: 1 }}>
                  <Typography sx={{ fontSize: 16, color: '#2e7d32' }}>
                    {' '}
                    Bem-vindo ao controle financeiro: controle suas contas bancárias, suas contas a pagar e receber, organize seu fluxo de caixa, vendas, e muito mais.
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', mb: 1 }}>
                  <Typography sx={{ fontSize: 14 }}> Centralize suas contas bancárias em um único sistema.</Typography>
                </Box>
                {/* <Box sx={{ minHeight: 110 }}>
                  <Typography style={{ color: '#2e7d32' }}>
                    {isLoadingText && (
                      <Typewriter tam={110} minHeight={0} text=" Para criar uma solicitação, você pode clicar no botão verde (+) no canto inferior direito e depois clicar em solicitações." />
                    )}
                  </Typography>
                </Box> */}
              </div>

              <div className="d-none d-lg-block">
                <Box sx={{ display: 'flex', mb: 1 }}>
                  <Typography sx={{ fontSize: 16, color: '#2e7d32' }} color="textPrimary">
                    Bem-vindo ao controle financeiro: controle suas contas bancárias, suas contas a pagar e receber, organize seu fluxo de caixa, vendas, e muito mais.
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', mb: 1 }}>
                  <Typography sx={{ fontSize: 14 }}> Centralize suas contas bancárias em um único sistema.</Typography>
                </Box>
                {/* <Box sx={{ minHeight: 45 }}>
                  <Typography style={{ color: '#2e7d32' }}>
                    {isLoadingText && (
                      <Typewriter tam={30} minHeight={0} text=" Para criar uma solicitação, você pode clicar no botão verde (+) no canto inferior direito e depois clicar em solicitações." />
                    )}
                  </Typography>
                </Box> */}
              </div>
            </>
          }
        ></CardHeader>

        <Divider />
      </Card>

      <div className="content mt-1">
        <div className="container-fluid">
          <FinancialChoiceContentTabs />
        </div>
      </div>
    </div>
  );
}

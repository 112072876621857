import { Box, Grid, Typography, Stack, Button, AppBar, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { useContext } from 'react';
import usePropertyReposStore from '../../../store/usePropertyReposStore';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { Context } from '../../../components/Context/AuthContext';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';
import { useState } from 'react';
import StartSecondStepForm from './StartSecondStepForm';

const useStyles = makeStyles({
  // selected: {
  //     border: "2px solid green",

  // },
  img: {
    height: 'auto',
  },
});

export default function StartSecondStep({ data, setData, handleNext, handleBack, activeStep, steps, handleClose }) {
  console.log(data);

  const { aux_add, setAuxAdd } = useContext(Context);

  const reposProperty = usePropertyReposStore((state) => state);

  const [imageSrc, setImageSrc] = useState('/dist/image/startmap.jpeg');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const para validar o button continuar, se existem as 2 validações
  const isError = () => {
    if (!data.nameProperty || data.nameProperty.trim() === '') {
      return true;
    }
    // if (!data.city || data.city.trim() === "") {
    //     return true;
    // }
    return false;
  };

  const returnError = () => {
    return false;
  };

  const color = '#2e7d32';

  const classes = useStyles;

  const onSubmit = async () => {
    // console.log(onSubmit);
    // console.log(data.nameProperty);
    // console.log(reposProperty.propertyRepos.label);
    if (data.nameProperty === reposProperty.propertyRepos.label && data.area === reposProperty.propertyRepos.area) {
      // console.log('ok');
      // toast.success("Lembre depois de cadastrar o nome da propriedade.");
      handleNext();
      return true;
    }
    const header = {
      header: {
        'Content-Type': 'application/json',
      },
    };
    const newData = { id: data.id_property, nameProperty: data.nameProperty, area: data.area, measure: data.measure };
    console.log(newData);
    console.log(newData);
    await api
      .put('/property/name', newData, header)
      .then((response) => {
        // console.log('response');
        // console.log(response);
        if (response.data.isSuccess) {
          reposProperty.fetch().then((res) => {
            // console.log("fetch - res");
            //console.log(res);
            //setAuxAdd(aux_add + 1);
          });
          handleNext();
          toast.success('Excelente!!! Registro básico da propriedade realizado!');
          //handleOpen()
          //setLoading(false)
        }
      })
      .catch((error) => {
        // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
        console.log('error');
        console.log(error);
        toast.error(error.response.data);
      });
  };

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <img src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="AdminLTE Logo" className="brand-image" style={{ opacity: '.8', height: 32, maxWidth: '100%' }} />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5} sx={{ mt: 0 }}>
              <Typography variant="h6" fontWeight={500}>
                Fazenda Premium
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container className={classes.root} sx={{ mt: 3 }} style={{ height: 'auto', maxWidth: '100%' }}>
          <Grid container sm={6} xs={12}>
            <Grid container style={{ padding: '4%' }}>
              <StartSecondStepForm isError={isError} data={data} setData={setData} />
            </Grid>
          </Grid>

          <Grid container sm={6} xs={12}>
            <Grid container style={{ padding: '4%' }}>
              <Grid item sm={12} xs={12}>
                <img src={imageSrc} style={{ height: 'auto', maxWidth: '100%', borderRadius: '10px' }} />
              </Grid>
            </Grid>
          </Grid>

          {true && (
            <Grid item sm={12} xs={12} sx={{ mt: 3 }}>
              <Typography paragraph variant="subtitile1" style={{ color: '#2e7d32' }} fontWeight={500}>
                Após atualizar as informações, clique abaixo em continuar...
              </Typography>
            </Grid>
          )}
        </Grid>
        <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              {/* <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                <Span>cancelar</Span>
              </Button> */}
            </Box>
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              <Button onClick={handleBack} color="success" sx={{ marginRight: 2 }} disabled={returnError()}>
                Retornar
              </Button>
              {/* O botão Confirmar e Salvar, leva para tela successo */}
              {/* <Button color="success" variant="contained" type="submit" onClick={isError() ? onSubmit() : () => null} disabled={isError()}> */}
              <Button
                sx={{
                  animation: `${expandAndFade} 2s linear infinite`, // Aplica a animação expandAndFade ao botão
                }}
                color="success"
                variant="contained"
                type="submit"
                onClick={onSubmit}
                disabled={isError()}
              >
                {activeStep === steps.length - 1 ? 'Confirmar e Salvar' : 'Continuar'}
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Grid>
    </>
  );
}

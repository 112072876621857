import React, { Component } from 'react'
import Chart from 'react-apexcharts'

export default function ChartBaVInvetoryHis() {
  const state = {
    options: {
      chart: {
        id: 'basic-bar',
      },
      colors: ['#28a745'],
      xaxis: {
        categories: ['Entradas', 'Saídas', 'Saldo'],
      },
    },
    series: [
      {
        name: 'series-1',
        data: [300, 50, 250],
      },
    ],
  }

  return (
    <div className="">
      <div className="">
        <div className="mixed-chart">
          <Chart options={state.options} series={state.series} type="bar" />
        </div>
      </div>
    </div>
  )
}

import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../../../../components/Loader/Loading';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Radio, Grid, ListItemIcon } from '@mui/material';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import useAreasMapReposStore from '../../../../../store/useAreasMapReposStore';
import useLivestockReposStore from '../../../../../store/useLivestockReposStore';

const useStyles = makeStyles({
  selected: {
    border: '2px solid green',
    boxSizing: 'border-box',
    margin: '-2px',
  },
});

export default function FormInputStep3AreasMapsAreasMapsRegister({ data, setData, newArea }) {
  const { id } = useParams();
  const classes = useStyles();
  let file = '';
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [imageNew, setImageNew] = useState();
  const [areas, setAreas] = useState(data?.areas || []);
  const [selectedId, setSelectedId] = useState(data?.areas.length > 0 ? data.areas[0].id : 0);

  //obter os dados das áreas de mapa da loja e é armazenado em fetchAreas.
  const fetchAreas = useAreasMapReposStore((state) => state);
  //um clone do array fetchAreas.listAreasMap
  //const areaMapList = window.jQuery.extend(true, [], fetchAreas.listAreasMap);
  //console.log("areaMapListxxx1")
  //console.log(areaMapList)
  const fetchLivestock = useLivestockReposStore((state) => state);
  // // descobrir a lista de livestock com id atual
  // const listLivestock = window.jQuery.extend(true, [], fetchLivestock.listLivestock);
  // const listLivestock_id = listLivestock.filter(item => item.id == id);
  // console.log("listLivestock_id")
  // console.log(listLivestock_id)
  const listLivestock_id = fetchLivestock.getLivestockStore('id', id);
  // console.log('listLivestock_id1');
  // console.log(listLivestock_id);

  const arrayAreaId = listLivestock_id[0].areas.map((item) => item.id);
  // console.log('arrayAreaId');
  // console.log(arrayAreaId);

  //const arrayAreaUsed = listLivestock_id[0].areas.map((item => item.usedArea))
  //console.log("arrayAreaUsed")
  //console.log(arrayAreaUsed)

  const areaMapList = [];
  for (const idarea of arrayAreaId) {
    // console.log(idarea);
    const area = fetchAreas.getAreaMapStore('id', idarea);
    // console.log("areaxxxx")
    //console.log(area[0].image)
    var arrayAreaUsed = listLivestock_id[0].areas.filter((item) => {
      if (item.id == idarea) {
        return item.usedArea;
      }
    });
    //console.log("arrayAreaUsed")
    // console.log(arrayAreaUsed);
    area[0].usedArea = arrayAreaUsed[0].usedArea;

    areaMapList.push(area[0]);
  }

  //O método map é usado no array data.areas para preencher o objeto selectedIds com as áreas selecionadas do data.
  var selectedIds = {};
  data.areas?.map((area) => (selectedIds[area.id] = true));

  //função para lidar com a alteração de seleção de área
  const handleChange = (event, area) => {
    // console.log('handleChange');
    // console.log(area);
    //define o selectedId para a área selecionada
    setSelectedId(area.id);
    //atualiza o data com a área selecionada e seu nome
    setData((prevState) => ({
      ...prevState,
      areas: areas.filter((area_prev) => area_prev.id === area.id),
    }));

    setData((prevState) => ({ ...prevState, nameArea: area.name }));

    setIsLoading(true);
  };

  //função para lidar definida para lidar com a alteração do valor da área utilizada
  // const handleChangeInput = (e, area) => {
  //   var usedArea = parseFloat(e.target.value || '0');

  //   setData((prevState) => ({
  //     ...prevState,
  //     areas: prevState.areas.map((area_prev) => {
  //       if (area_prev.id === area.id) {
  //         area_prev.usedArea = usedArea;
  //       }
  //       return area_prev;
  //     }),
  //   }));
  // };

  //função imageBuffer é definida para armazenar a imagem da primeira área de areaMapList no estado imageNew.
  function imageBuffer() {
    const image = areaMapList[0].image;
    file = image;
    setImageNew(file);
  }
  //A função setMapAreas é definida como assíncrona para carregar as áreas de mapa e definir o estado areas com areaMapList e o estado loading como false.
  //Ela chama a função imageBuffer para definir a imagem.
  const setMapAreas = async () => {
    imageBuffer();
    setAreas(areaMapList);
    setLoading(false);
  };

  // é usado para chamar a função setMapAreas uma vez quando o componente é montado, caso loading seja verdadeiro.
  useEffect(() => {
    if (loading) {
      setMapAreas();
    }
  }, []);

  //O hook useEffect é usado para chamar a função setMapAreas quando newArea é alterado.
  useEffect(() => {
    setMapAreas();
  }, [newArea]);

  // console.log(areaMapList);
  // console.log(areas);

  // Passo 1: Ordenar o array 'areas' por ID
  areas.sort((a, b) => a.id - b.id);

  // Passo 2: Criar um objeto vazio para o mapa ordenado
  const mapaOrdenado = {};

  // Passo 3: Preencher o mapa ordenado com os elementos do array
  areas.forEach((area) => {
    mapaOrdenado[area.id] = area;
  });

  return (
    <div className="row">
      <div className="col">
        {loading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            <Grid container spacing={2} sx={{ mt: 0.5 }}>
              {areas?.map((area) => (
                <Grid item xs={12} md={6} key={area.id} container alignItems="center" justifyContent="center">
                  <Card sx={{ display: 'flex', boxSizing: 'border-box' }} className={selectedId === area.id ? classes.selected : null}>
                    <ListItemIcon>
                      <Radio checked={selectedId === area.id} onChange={(e) => handleChange(e, area)} color="success" />
                      <CardMedia component="img" sx={{ width: 100 }} image={area.url || area.imageDecode} alt={area.name} />
                      <CardContent sx={{ flex: 1 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', pl: 1, pb: 1 }}>
                          <Typography variant="subtitle1" gutterBottom>
                            Área: {area.name}
                          </Typography>
                          <Typography variant="subtitle2" gutterBottom>
                            {/* <i className="fa fa-map text-success"> {area.area} ({area.measure})</i> */}
                            <i className="fa fa-map text-success">
                              {' '}
                              {area.usedArea} ({area.measure})
                            </i>
                          </Typography>
                        </Box>
                        {/* <TextField
                          label={"Área utilizada: " + (area.area || '')}
                          disabled={selectedId !== area.id}
                          sx={{ marginRight: 2 }}
                          variant="standard"
                          size="small"
                          onChange={(e) => handleChangeInput(e, area)}
                          id={area.id + ""}
                          name={area.name}
                          defaultValue={area.usedArea || ""}
                        /> */}
                      </CardContent>
                    </ListItemIcon>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </div>
    </div>
  );
}

import { Box, Grid, IconButton, Paper, Divider, Typography, Menu, ListItem, ListItemText, Skeleton } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { StandaloneSearchBox, LoadScript, GoogleMap } from '@react-google-maps/api';
import { GpsFixed, Search, Directions, CropOriginal } from '@mui/icons-material';
import LoadScriptOnlyIfNeeded from '../../../LoadScript/LoadScriptOnlyIfNeeded';
import '../../maps/components/Map.css';
import Typewriter from '../../../components/Typewriter/Typewriter';
import { Context } from '../../../components/Context/AuthContext';
import MenuIcon from '@mui/icons-material/Menu';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';

const libraries = ['places', 'drawing'];
const apiKey = 'AIzaSyBNOeEroFWGH9RPyAUlol-YHLcyHAx0xTU';

const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';

// extrair do place e criar um objeto address
const extractAddress = (place) => {
  //console.log(place);
  const address = {
    city: '',
    state: '',
    zip: '',
    country: '',
    route: '',
    street_number: '',
    plain() {
      const city = this.city ? this.city + ', ' : '';
      const zip = this.zip ? this.zip + ', ' : '';
      const state = this.state ? this.state + ', ' : '';
      const route = this.route ? this.route + ', ' : '';
      const street_number = this.street_number ? this.street_number + ', ' : '';
      return city + route + street_number + zip + this.country;
    },
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;
    // console.log("component");
    //console.log(component);

    if (types.includes('administrative_area_level_2')) {
      address.city = value;
    }

    if (types.includes('administrative_area_level_1')) {
      address.state = component.short_name;
    }

    if (types.includes('postal_code')) {
      address.zip = value;
    }

    if (types.includes('country')) {
      address.country = value;
    }

    if (types.includes('route')) {
      address.route = value;
    }
    if (types.includes('street_number')) {
      address.street_number = value;
    }
  });

  return address;
};

export default function StartThirdStepForm({ isError, data, setData, setProperty_address, property_address }) {
  //console.log(address);
  //console.log(address);

  const searchBoxRef = useRef(null);

  // useEffect to adjust the position of the suggestion box
  // useEffect(() => {
  //   const searchBoxContainer = document.querySelector('.pac-container');
  //   if (searchBoxContainer) {
  //     searchBoxContainer.style.position = 'absolute';
  //     searchBoxContainer.style.left = '-200px'; // Move 50px to the left
  //     searchBoxContainer.style.width = 'calc(100% + 200px)'; // Adjust the width if needed
  //     searchBoxContainer.style.zIndex = 9999; // Ensure it stays above other elements
  //   }
  // }, [searchBoxRef]);

  const [mapCenter, setMapCenter] = useState({ lat: -20.9115994, lng: -42.7944169 });
  const [zoom, setZoom] = useState(3);
  const [address, setAddress] = useState({});
  const [address_complete, setAddress_complete] = useState({});

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      const location = place.geometry.location;
      setMapCenter({ lat: location.lat(), lng: location.lng() });
      const newZoom = 14.8;
      setZoom(newZoom);
      const address_compl = place?.formatted_address;

      setAddress_complete(address_compl);
      console.log('address_complete');
      console.log(address_compl);
      const _address = extractAddress(place);
      setAddress(_address);
      console.log('_address');
      console.log(_address);
      setProperty_address({ ...property_address, lat: mapCenter.lat || '', lng: mapCenter.lng || '', zoom: newZoom || 14.8, address: address_compl, city: _address?.city, state: _address?.state });
    }
  };

  // const findMyLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       console.log('findMyLocation', position);
  //       //reverseGeocode(position.coords);
  //     });
  //   }
  // };
  const [isMapLoaded, setIsMapLoaded] = useState(false); // Adicione um estado para controle de carregamento

  //Referência do mapa:onload do google para obter a referência do mapa
  // prop onLoad: Essa função onLoad recebe um parâmetro padrão que é a instância atual do mapa .
  // map: google.maps.Map
  const onLoad = (map) => {
    mapRef.current = map;
    setIsMapLoaded(true); // Quando o mapa carrega, desabilita o skeleton
    //setMap(map);
    //setWindGoogle(window.google);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsMapLoaded(true);
    }, 2000);
  }, []);

  const mapRef = useRef(null);

  // referencia atual do zoom do mapa
  const onZoomChanged = (value) => {
    console.log(zoom);
    console.log(value);
    console.log(mapRef.current);
    console.log(mapRef.current?.zoom);
    if (!mapRef.current) return;
    setZoom(mapRef.current?.zoom);
    // console.log(zoom);
    if (value) setZoom(value);
    setProperty_address({ ...property_address, zoom: zoom || 14.8 });
  };

  // // referencia atual do centro do mapa
  // const handleCenterChanged = () => {
  //   if (!mapRef.current) return;
  //   const newPos = mapRef.current.getCenter().toJSON();
  //   // console.log("newPos");
  //   //console.log(mapRef.current);
  //   const newlocationcenter = {
  //     lat: newPos.lat,
  //     lng: newPos.lng,
  //     //address: property_address.address,
  //     zoom: zoom,
  //     url: '',
  //   };
  //   setMapCenter(newlocationcenter);
  //   setProperty_address({ ...property_address, lat: newlocationcenter.lat || '', lng: newlocationcenter.lng || '', zoom: zoom });
  //   //reverseGeocode({ latitude: newPos.lat, longitude: newPos.lng });
  // };

  // mobile para o search
  const { isMobile } = useContext(Context);
  const ismobile = isMobile.any();

  // Função que atualiza o valor do input
  const handleInputChange = (e) => {
    const value = e.target.value;
    console.log(value);
    setProperty_address({ ...property_address, name: value });
  };

  return (
    <LoadScriptOnlyIfNeeded googleMapsApiKey={apiKey} libraries={libraries}>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={6}>
          {!isMapLoaded ? (
            // Skeleton para o mapa enquanto ele carrega
            <Skeleton variant="rectangular" width="100%" height={400} />
          ) : (
            <GoogleMap onLoad={onLoad} center={mapCenter} zoom={zoom} mapContainerStyle={{ height: '100%', width: '100%' }} onZoomChanged={() => {}} onDragEnd={() => {}}>
              {/* Outros elementos do mapa, como marcadores */}
            </GoogleMap>
          )}
          {Object.keys(address_complete).length > 0 && (
            <>
              <ListItem>
                <ListItemText primary={address_complete} />
              </ListItem>
              <Box sx={{ ml: 2 }}>
                <Typography paragraph variant="subtitile1" style={{ color: '#2e7d32' }} fontWeight={500}>
                  Encontrado a propriedade, clique em continuar...
                </Typography>
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid spacing={1} sx={{ mb: 0, mt: 0 }} style={{ padding: '4%' }}>
            <Box style={{ height: 'auto', width: '100%', padding: '10px 0' }}>
              <Grid item sm={12} xs={12} sx={{ mb: 0, mt: 0 }}>
                <Typography gutterBottom sx={{ mt: 1, mb: 0 }} fontWeight={500} style={{ color: '#2e7d32' }}>
                  <Typewriter tam={60} text=" Ótimo! Agora precisamos registrar a propriedade no Google Maps." />
                </Typography>
                <Typography gutterBottom style={{ color: '#2e7d32' }}></Typography>
              </Grid>
            </Box>
            <Box sx={{ mt: -1, width: '100%' }}>
              <ListItem
                key={null}
                style={{ height: '100px', position: 'relative' }}
                disableGutters
                secondaryAction={
                  <IconButton aria-label="comment">
                    <CropOriginal style={{ color: '#2e7d32' }} />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={<strong>Passo 2:</strong>}
                  secondary={
                    <>
                      <Typography style={{ color: '#2e7d32' }}>Clique com o cursor sobre o nome da propriedade e selecione o endereço sugerido.</Typography>
                    </>
                  }
                />
              </ListItem>
              <ListItem
                key={null}
                sx={{ mt: -3 }}
                style={{ height: '100px', position: 'relative' }}
                disableGutters
                secondaryAction={
                  <IconButton aria-label="comment">
                    <GpsFixed style={{ color: '#2e7d32' }} />
                  </IconButton>
                }
              >
                <ListItemText secondary="Caso a pesquisa da Google não encontre, procure um nome de um local mais próximo." />
              </ListItem>
            </Box>

            <Grid container spacing={0} sx={{ mt: 4 }}>
              <Box sx={{ mt: 2, width: '100%' }}>
                <Grid item sm={12} xs={12} sx={{ mb: 0, mt: 0 }}>
                  <StandaloneSearchBox onLoad={(ref) => (searchBoxRef.current = ref)} onPlacesChanged={onPlacesChanged}>
                    <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}>
                      {ismobile ? (
                        <IconButton sx={{ p: '10px' }} aria-label="menu">
                          <MenuIcon />
                        </IconButton>
                      ) : (
                        //  <IconButton onClick={findMyLocation} sx={{ p: '10px' }} aria-label="my location"></IconButton>
                        // <IconButton sx={{ p: '10px' }} aria-label="my location">
                        <Box sx={{ p: '10px' }}>
                          <GpsFixed color="success" />
                        </Box>

                        // </IconButton>
                      )}
                      <input
                        type="text"
                        placeholder="Digite aqui o nome da propriedade"
                        className={`search-box-google input-animation`}
                        value={property_address.name}
                        //onFocus={true} // Exibe lista ao focar no campo
                        //onBlur={true}
                        onChange={handleInputChange}
                        style={{
                          animation: `${expandAndFade} 2s linear infinite`,
                          border: '1px solid transparent',
                          borderRadius: '3px',
                          outline: '1px', // Remove default outline
                          padding: '10px', // Add some padding
                          boxSizing: 'border-box',
                          flex: 1, // Make input take remaining space
                          textOverflow: 'ellipses',
                          cursor: 'text', // Add the cursor style
                        }}
                      />
                    </Paper>
                  </StandaloneSearchBox>
                </Grid>
                {/* {suggestions.length > 0 && (
              <Box sx={{ position: 'absolute', zIndex: 1000, background: 'white', width: '100%', maxHeight: '200px', overflowY: 'auto' }}>
                {suggestions.map((suggestion, index) => (
                  <ListItem button key={index} onClick={() => handleSuggestionClick(suggestion)}>
                    <ListItemText primary={suggestion.formatted_address} />
                  </ListItem>
                ))}
              </Box>
            )} */}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LoadScriptOnlyIfNeeded>
  );
}

import { Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import CardWeather from "../maps/components/WeatherForecast/CardWeather";
import { useFetchWeatherForecast } from "../maps/components/WeatherForecast/useFetchWeatherForecast";
import AddIcon from "@mui/icons-material/Add";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { green } from "@mui/material/colors";
import PropTypes from "prop-types";
import InventoryList from "../inventory/components/InventoryList";
import InventoryHist from "../inventory/components/InventoryHist";
import InventoryMov from "../inventory/components/InventoryMov";
import WidgetsIcon from "@mui/icons-material/Widgets";
import InventorySltockListContent from "../inventory/InventorySltockListContent";
import ListStockMobile from "../inventory/ListStockMobile";
import ListStock from "../inventory/ListStock";
import InventoryListTable from "../inventory/components/InventoryListTable";
import SalesListStockMobile from "./SalesListStockMobile";
import SalesInventoryListTable from "./SalesInventoryListTable";
import SalesListStock from "./SalesListStock";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SalesInventoryContent() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="">
      <div className="content mb-4">
        <div className="container-fluid">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs textColor="success" TabIndicatorProps={{ style: { background: "green" } }} value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Produção Agrícola" {...a11yProps(0)} />
                <Tab label="Produção animal" {...a11yProps(0)} />
                {/* <Tab label="Movimentações" {...a11yProps(1)} />
                <Tab label="Histórico do produto" {...a11yProps(2)} /> */}
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {/* <InventoryList /> */}
              <Grid container spacing={2} sx={{ mt: 1, p: 1 }}>
                <Grid className="d-lg-none" item xs={12} md={3} sx={{ mt: -2 }}>
                  <SalesListStockMobile />
                </Grid>
                <Grid className="d-none d-lg-block" item xs={12} md={2}>
                  <Typography sx={{ mt: 0, mb: 2 }} variant="h6" component="div">
                    Silos
                  </Typography>
                  <SalesListStock />
                </Grid>
                <Grid item xs={12} md={10}>
                  <SalesInventoryListTable />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>

            </TabPanel>
            <TabPanel value={value} index={2}>
              <InventoryHist />
            </TabPanel>
          </Box>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react'
import { AppBar, Avatar, Box, Button, Card, CardContent, CardHeader, Divider, Grid, ListItem, ListItemText, Stack, Toolbar, Typography } from '@mui/material'
import Typewriter from '../../../../components/Typewriter/Typewriter'
// import FooterModal from '../../../../components/Footer/FooterModal'
import { Input, Notes } from '@mui/icons-material'
import LocalSeeIcon from '@mui/icons-material/LocalSee'
import InventoryIcon from '@mui/icons-material/Inventory'
import HighlightAltIcon from '@mui/icons-material/HighlightAlt'
import ThreeDotsSpinner from '../../../../components/Loader/ThreeDotsSpinner'

export default function FormPersonStep5({ data, obj_fn }) {
  const [showTextFase, setShowTextFase] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowTextFase(true)
    }, 500)

    return () => clearTimeout(timeout)
  }, [])

  // const isError = () => false

  const returnError = () => false

  // console.log(data)

  return (
    <>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Confirmação dos dados (completo)</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid container spacing={1} sx={{ mt: 2 }}>
        <div className="d-lg-none">
          <Grid container>
            <Grid item xs={12} md={12} alignItems="center">
              {showTextFase ? (
                <Box display="flex" alignItems="center">
                  <Typewriter text={'Pronto! Agora basta conferir os dados da pessoa e clicar em confirmar!'} variant={'subtitle1'} color={'#2e7d32'} />
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
        <div className="d-none d-lg-block">
          <Grid container>
            <Grid item xs={12} md={12} alignItems="center" style={{ height: 30 }}>
              {showTextFase ? (
                <Box display="flex" alignItems="center">
                  <Typewriter text={'Pronto! Agora basta conferir os dados da pessoa e clicar em confirmar!'} variant={'subtitle1'} color={'#2e7d32'} />
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Divider sx={{ mt: 1, mb: 5, ml: -3, borderColor: '#388e3c' }} />

      <Grid container spacing={3} sx={{ mb: 2, backgroundColor: '#F6F6F6', py: 2, borderRadius: '6px' }}>
        {/* Informações gerais */}

        {/* NOME */}
        <Grid item xs={12} sm={2}>
          <div>
            <Card>
              <CardContent>
                <Grid container spacing={4} sx={{}}>
                  <Grid item xs={12} sm={12}>
                    <Typography sx={{ fontSize: 14, textAlign: 'center' }} color="text.secondary">
                      {data.category_name ? data.category_name.toUpperCase() : 'PRODUTO'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography sx={{ fontSize: 14, textAlign: 'center' }}>
                      <strong>{data.name ? data.name.toUpperCase() : 'Nome do produto'}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} sx={{ marginTop: 'auto' }}>
                    <Typography variant="body2" style={{ textAlign: 'center' }}>
                      {data.packaging_name === 'Unidade' ? `${data.unit_name} (${data.unit_sigla})` : `${data.packaging_name} de ${data.volume} ${data?.unit_sigla}`}
                    </Typography>
                    <Typography sx={{ fontSize: 8, textAlign: 'center' }}>
                      <strong>{data.brand ? data.brand : 'marca'}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Box sx={{ ml: 1, display: 'flex', flexDirection: 'column' }}>
                <Stack spacing={0.5}>
                  <Typography fontWeight={700}>
                    <Input sx={{ mr: 1 }} /> Dados gerais
                  </Typography>
                </Stack>
              </Box>
            </Grid>

            {/* NOME */}
            <Grid item xs={3} md={3}>
              <ListItem>
                <ListItemText primary={<Typography fontWeight="bold">{'Nome do produto'}</Typography>} secondary={data.name} />
              </ListItem>
            </Grid>

            {/* local */}
            {/* <Grid item xs={3} md={3}>
            <ListItem>
              <ListItemText primary={<Typography fontWeight="bold">{'Local'}</Typography>} secondary={data.location} />
            </ListItem>
          </Grid> */}

            {/* UNIDADE */}
            <Grid item xs={3} md={3}>
              <ListItem>
                <ListItemText primary={<Typography fontWeight="bold">{'Unidade'}</Typography>} secondary={data.unit_name} />
              </ListItem>
            </Grid>

            {/* VALOR */}
            {data.valor_produto > 0 && (
              <Grid item xs={3} md={3}>
                <ListItem>
                  <ListItemText primary={<Typography fontWeight="bold">{'Valor'}</Typography>} secondary={data.stock_cost} />
                </ListItem>
              </Grid>
            )}

            {/* CATEGORIA */}
            <Grid item xs={3} md={3}>
              <ListItem>
                <ListItemText primary={<Typography fontWeight="bold">{'Categoria'}</Typography>} secondary={data.category_name} />
              </ListItem>
            </Grid>

            {/* MARCA */}
            {data.brand !== '' && (
              <Grid item xs={6} md={4}>
                <ListItem>
                  <ListItemText primary={<Typography fontWeight="bold">{'Marca'}</Typography>} secondary={data.brand} />
                </ListItem>
              </Grid>
            )}

            {/* Código de barra */}
            {data.cod_barra !== '' && (
              <Grid item xs={12} md={4}>
                <ListItem>
                  <ListItemText primary={<Typography fontWeight="bold">{'Código de barra'}</Typography>} secondary={data.cod_barra} />
                </ListItem>
              </Grid>
            )}

            {/* DESCRIÇAO */}
            {data.description !== '' && (
              <Grid item xs={12} md={12}>
                <ListItem>
                  <ListItemText primary={<Typography fontWeight="bold">{'Descrição'}</Typography>} secondary={data.description} />
                </ListItem>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* Observações */}
      {data.observacoes !== '' && (
        <Grid
          container
          spacing={3}
          sx={{
            mb: 2,
            mt: 0,
            backgroundColor: '#F6F6F6',
            py: 2,
            borderRadius: '6px',
          }}
        >
          <Box sx={{ mb: -1, ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>
                <Notes sx={{ mr: 1 }} />
                Observações gerais
              </Typography>
            </Stack>
          </Box>
          <Grid item xs={12} sm={12}>
            <ListItem>
              <ListItemText primary="" secondary={data.observacoes} />
            </ListItem>
          </Grid>
        </Grid>
      )}

      {/* FOTOS */}
      {!data.image ? (
        <Grid item xs={12} sm={12}>
          <ListItem>
            <ListItemText primary="" secondary={'Nenhuma imagem foi selecionada para o produto.'} />
          </ListItem>
        </Grid>
      ) : (
        <Grid
          container
          spacing={3}
          sx={{
            mb: 2,
            mt: 0,
            backgroundColor: '#F6F6F6',
            py: 2,
            borderRadius: '6px',
          }}
        >
          <Box sx={{ mb: 2, ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>
                <LocalSeeIcon sx={{ mr: 1 }} />
                Foto
              </Typography>
            </Stack>
          </Box>
          {/* TODO: EXIBIR AS IMAGENS ADICIONADAS PELO USUÁRIO */}
          <Card sx={{ mt: 4 }}>
            <Box height="80px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="8px">
              <CardHeader avatar={<Avatar aria-label="Imagem do produto" src={`data:image/jpeg;base64,${data.image}`}></Avatar>} title={data?.name || ''} subheader={data?.group || ''} />
            </Box>
          </Card>
          {/* <Grid item xs={12} sm={12}>
            <ListItem>
              <ListItemText primary="" secondary={data.image === '' ? 'Nenhuma imagem foi selecionada.' : data.image} />
            </ListItem>
          </Grid> */}
        </Grid>
      )}

      {/* ESTOQUE */}
      <Grid
        container
        spacing={3}
        sx={{
          mb: 2,
          mt: 0,
          backgroundColor: '#F6F6F6',
          py: 2,
          borderRadius: '6px',
        }}
      >
        {data.qnt_disponivel === 0 ? (
          <>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{ mt: 2, ml: 2 }}
              style={{
                color: '#2e7d32',
              }}
            >
              Não foram adicionado <strong>quantidade ao estoque</strong> . Você poderá adicionar posteriormente em ...
            </Typography>
          </>
        ) : (
          <>
            <Grid item xs={12} md={12} sx={{ mb: 2 }}>
              <Box sx={{ mb: -1, ml: 1, display: 'flex' }}>
                <Stack spacing={0.5}>
                  <Typography fontWeight={700}>
                    <InventoryIcon sx={{ mr: 1 }} />
                    Estoque
                  </Typography>
                </Stack>
              </Box>
            </Grid>

            {/* Quantidade disponível */}
            <Grid xs={6} sm={6}>
              <ListItem>
                <ListItemText primary={<Typography fontWeight="bold">{'Quantidade disponível'}</Typography>} secondary={data.stock_quanty} />
              </ListItem>
            </Grid>

            {/* Custo Médio */}
            <Grid xs={6} sm={6}>
              <ListItem>
                <ListItemText primary={<Typography fontWeight="bold">{'Custo médio'}</Typography>} secondary={data.stock_cost} />
              </ListItem>
            </Grid>

            {/* Estoque mínimo */}
            <Grid xs={6} sm={6}>
              <ListItem>
                <ListItemText primary={<Typography fontWeight="bold">{'Estoque mínimo'}</Typography>} secondary={data.stock_quanty_min} />
              </ListItem>
            </Grid>

            {/* Estoque máximo */}
            <Grid xs={6} sm={6}>
              <ListItem>
                <ListItemText primary={<Typography fontWeight="bold">{'Estoque máximo'}</Typography>} secondary={data.stock_quanty_max} />
              </ListItem>
            </Grid>
          </>
        )}

        {data.altura === 0 ? (
          <>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{ mt: 2, ml: 2 }}
              style={{
                color: '#2e7d32',
              }}
            >
              Não foram adicionado <strong>pesos e dimensões ao produto</strong>. Você poderá editar e modificar a qualquer momento.
            </Typography>
          </>
        ) : (
          <>
            {/* Pesos e Dimensões */}
            <Grid
              container
              spacing={3}
              sx={{
                mb: 2,
                mt: 0,
                backgroundColor: '#F6F6F6',
                py: 2,
                borderRadius: '6px',
              }}
            >
              <Grid item xs={12} md={12} sx={{ mb: 2 }}>
                <Box sx={{ mb: -1, ml: 1, display: 'flex' }}>
                  <Stack spacing={0.5}>
                    <Typography fontWeight={700}>
                      <HighlightAltIcon sx={{ mr: 1 }} />
                      Pesos e Dimensões
                    </Typography>
                  </Stack>
                </Box>
              </Grid>

              {/* Altura */}
              <Grid xs={6} sm={4}>
                <ListItem>
                  <ListItemText primary={<Typography fontWeight="bold">{'Altura'}</Typography>} secondary={`${data.altura} cm`} />
                </ListItem>
              </Grid>

              {/* Largura */}
              <Grid xs={6} sm={4}>
                <ListItem>
                  <ListItemText primary={<Typography fontWeight="bold">{'Largura'}</Typography>} secondary={`${data.largura} cm`} />
                </ListItem>
              </Grid>

              {/* Profundidade */}
              <Grid xs={6} sm={4}>
                <ListItem>
                  <ListItemText primary={<Typography fontWeight="bold">{'Profundidade'}</Typography>} secondary={`${data.profundidade} cm`} />
                </ListItem>
              </Grid>

              {/* Volume */}
              <Grid xs={6} sm={4}>
                <ListItem>
                  <ListItemText primary={<Typography fontWeight="bold">{'Volume'}</Typography>} secondary={data.volume} />
                </ListItem>
              </Grid>

              {/* Peso Líquido */}
              <Grid xs={6} sm={4}>
                <ListItem>
                  <ListItemText primary={<Typography fontWeight="bold">{'Peso Líquido'}</Typography>} secondary={`${data.peso_liquido} KG`} />
                </ListItem>
              </Grid>

              {/* Peso Bruto */}
              <Grid xs={6} sm={4}>
                <ListItem>
                  <ListItemText primary={<Typography fontWeight="bold">{'Peso Bruto'}</Typography>} secondary={`${data.peso_bruto} KG`} />
                </ListItem>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      {/* <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} /> */}
      <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button color="success" variant="outlined" type="submit" onClick={obj_fn.handleClose}>
              Cancelar
            </Button>
          </Box>
          {data && (
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              <Button onClick={obj_fn.handleBack2} color="success" sx={{ marginRight: 2 }} disabled={returnError()}>
                Retornar
              </Button>
              {/* O botão Confirmar e Salvar, leva para tela successo */}
              <Button color="success" variant="contained" type="submit" onClick={obj_fn.onSubmit}>
                Salvar
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { Box, Container, IconButton, Grid, Paper, Typography, Menu, MenuItem } from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import dayjs from 'dayjs'
import ChatSidebar from './ChatSidebar/ChatSidebar'
import ModalVisualizeBill from './outhers/ModalVisualizeBill'

function compareDates(dateStr1, dateStr2) {
  const date1 = new Date(dateStr1)
  const date2 = new Date(dateStr2)
  return date1 - date2
}

// Função para criar dados com valores iniciais para "Ações" (ícone)
function createDataRow(item, type, id, date_expire, date_payment, description, person, installment, status, date_nf ) {
  return { item, type, id, date_expire, date_payment, description, person, installment, status, date_nf }
}

const columns = [
  { 
    id: 'type',
    label: 'Tipo',
    minWidth: 100
  },
  // { 
  //   id: 'id',
  //   label: 'ID',
  //   minWidth: 50
  // },
  { 
    id: 'date_payment',
    label: 'Data',
    minWidth:
    150
  },
  {
    id: 'description',
    label: 'Descrição',
    minWidth: 170,
    //align: 'right',
    //format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'person',
    label: 'Cliente / Fornecedor',
    minWidth: 100,
    //align: 'right',
    //format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'installment',
    label: 'Total da Parcela (R$)',
    minWidth: 120,
    align: 'right',
    //format: (value) => value.toFixed(2),
  },
  {
    id: 'actions',
    label: 'Ações',
    minWidth: 100,
    align: 'center',
  },
]

const buttons = [
  {id: 0, label: 'Receitas do período', color: 'green'},
  {id: 1, label: 'Depesas do período', color: 'red'},
  {id: 2, label: 'Total do período', color: 'blue'},
]

export default function Extract({ obj_fn }) {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [rows, setRows] = useState([])

  const [activeButton, setActiveButton] = useState(null)
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const [openShow, setOpenShow] = useState(false)
  // Gerenciamento do estado do menu
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const [obj_fn2, setObjFun] = useState({
    usestore: obj_fn.usestore,
    isOpen: 0,
    aux_add: obj_fn.aux_add,
    setAuxAdd: obj_fn.setAuxAdd,
    userCurrent: obj_fn.userCurrent
  })
  const handleMenuClose = (e, type, idBill) => {
    setAnchorEl(null)
    setOpenShow(true)
    setObjFun(prevState => ({ ...prevState, isOpen: idBill }))
  }
  const handleCloseShow = () => {
    setOpenShow(false)
    setObjFun(prevState => ({ ...prevState, isOpen: 0 }))
  }

  // retorna o nome da pessoa
  function getNamePerson(id_person) {
    const person = obj_fn.usestore.reposPerson.getPersonStore('id', id_person)[0]
    return person.name
  }

  const [buttons_val, setBtnVal] = useState([0, 0, 0])

  useEffect(() => {
    const aux_list = [];
    var aux_trans = {}
    var aux_tipo = 0
    let aux_btn_val = [0, 0, 0]

    let listBills = obj_fn.usestore.reposBill.listBills
    listBills.filter((bill) => {
      if (bill.date_payment) {
        if (dayjs(dayjs(bill.date_payment).format('YYYY-MM')).isSame(dayjs(dayjs(obj_fn.selectedDate).format('YYYY-MM')))) {
          aux_tipo = (bill.type_bill === 'pay' ? 1 : 0)
          aux_btn_val[aux_tipo] += bill.value
          aux_btn_val[2] += bill.value * (aux_tipo > 0 ? -1 : 1)
          aux_trans[bill.id_trans_pay] = bill.id_trans_pay;
          aux_trans[bill.id_transaction] = bill.id_transaction;

          aux_list.push(createDataRow(
            bill,
            (bill.type_bill === 'pay' ? 'Despesa' : 'Receita'),
            bill.id,
            dayjs(bill.date_expire).format('DD/MM/YYYY HH:mm'),
            bill.date_payment ? dayjs(bill.date_payment).format('DD/MM/YYYY HH:mm') : '',
            bill.description ? bill.description : (" - Parcela " + bill.portion),
            bill.info.id_person ? getNamePerson(bill.info.id_person) : '',
            obj_fn.formatValue(bill.value),
            (bill.type_bill === 'pay' ? 'Pago' : 'Recebido'),
            dayjs(bill.date_payment ? bill.date_payment : bill.date_expire).format('YYYY-MM-DD HH:mm:ss'),
          ))
        }
      }
      return bill;
    })

    let listTrans = obj_fn.usestore.reposFinances.listTransactions
    listTrans.filter((trans) => {
      if (trans.record.payed && !aux_trans[trans.id]) {
        if (dayjs(dayjs(trans.date_entry).format('YYYY-MM')).isSame(dayjs(dayjs(obj_fn.selectedDate).format('YYYY-MM')))) {
          aux_tipo = (trans.type === "Compra" || trans.type === "Despesa" || /Manutenção.*/.test(trans.type) ? 1 : 0)

          aux_btn_val[aux_tipo] += trans.value
          aux_btn_val[2] += trans.value * (aux_tipo > 0 ? -1 : 1)

          aux_list.push(createDataRow(
            trans,
            trans.type,
            trans.id,
            dayjs(trans.date_entry).format('DD/MM/YYYY HH:mm'),
            dayjs(trans.date_entry).format('DD/MM/YYYY HH:mm'),
            trans.description || '',
            trans.fkys[0].id_person ? getNamePerson(trans.fkys[0].id_person) : '',
            obj_fn.formatValue(trans.value),
            aux_tipo > 0 ? "Pago" : "Recebido",
            dayjs(trans.date_entry).format('YYYY-MM-DD HH:mm:ss'),
          ))
        }
      }
      return trans;
    })

    aux_list.sort((a, b) => compareDates(b.date_nf, a.date_nf))
    setRows(aux_list)
    setBtnVal(aux_btn_val)

  }, [obj_fn.selectedDate])

  return (
    <Box sx={{ display: 'flex' }} >
      <ChatSidebar />
      <Container maxWidth="" sx={{ mt: 2, mb: 4 }}>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid container spacing={0} sx={{ mb: 1 }} justifyContent="center">
            {buttons.map((btn, index) => (
              <Grid item key={index} xs={6} md={2}>
                <div
                  style={{
                    cursor: 'pointer',
                    border: '1px solid #ccc',
                    borderBottom: '1px solid #ccc',
                    padding: '10px',
                    textAlign: 'center',
                    position: 'relative',
                  }}
                  onClick={() => handleButtonClick(index)}
                >
                  <Typography >
                    {btn.label}
                  </Typography>
                  <Typography component="p" variant="h6" style={{ color: btn.color }}>
                    {obj_fn.formatValue(buttons_val[index])}
                  </Typography>
                  {activeButton === index && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        borderBottom: '3px solid green',
                      }}
                    />
                  )}
                </div>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="Tabela de Extratos">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, fontWeight: 'bold', }} >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                            {columns.map((column) => {
                              const value = row[column.id]
                              if (column.id === 'actions') {
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    <IconButton aria-controls="Ações" aria-haspopup="true" onClick={handleMenuClick} >
                                      <MoreVert />
                                    </IconButton>
                                    <Menu
                                      id="Ações"
                                      anchorEl={anchorEl}
                                      keepMounted
                                      open={Boolean(anchorEl)}
                                      onClose={handleMenuClose}
                                    >
                                      {/* <MenuItem onClick={(e) => handleMenuClose(e, 'edit', row.id)}>Editar</MenuItem> */}
                                      <MenuItem onClick={(e) => handleMenuClose(e, 'show', row.id)}>Visualizar</MenuItem>
                                    </Menu>
                                    {openShow && <ModalVisualizeBill bill={row.item} open={openShow} handleClose={handleCloseShow} obj_fn={obj_fn2} />}
                                  </TableCell>
                                )
                              } else {
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {value}
                                  </TableCell>
                                )
                              }
                            })}
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                labelRowsPerPage={"Linhas por página"}
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}


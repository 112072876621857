import React, { useState } from "react";
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  InputAdornment,
  IconButton,
  styled,
  Box,
  RadioGroup,
  Radio,
} from "@mui/material";
import ApexCharts from "react-apexcharts";
import ChartPieHarvest from "../../../components/Charts/ChartPieHarvest";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ChartPieHarvesting from "../../../components/Charts/ChartPieHarvesting";

const Img = styled("img")({
  //margin: "auto",
  display: "block",
  maxWidth: "50%",
  maxHeight: "50%",
  width: 30,
});

const HarvestIndicator = ({ dataCultivars, setDataCultivars }) => {
  console.log(dataCultivars);

  const initCultivar = dataCultivars.map((cultivar) => cultivar.cultivar);

  console.log(initCultivar);

  const [cultura, setCultura] = useState("Todas");
  const [situacao, setSituacao] = useState("");
  // const [projetosSelecionados, setProjetosSelecionados] = useState([]);
  const [selectedOption, setSelectedOption] = useState("areaDePlantio"); // Opção inicial selecionada

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // ... Outras variáveis de estado podem ser necessárias

  const handleCulturaChange = (event) => {
    const selectedValue = event.target.value;
    console.log(event.target.value);
    console.log(cultura);
    setCultura(selectedValue); // Atualize o estado quando o valor for alterado

    // Resete os checkboxes quando uma nova cultura for selecionada
    const updatedDataCultivars = dataCultivars.map((item) => ({
      ...item,
      active: false,
    }));
    setDataCultivars(updatedDataCultivars);

    // Ao selecionar "Todas", defina todos os cultivares como ativos
    if (selectedValue === "Todas") {
      const updatedDataCultivars = dataCultivars.map((item) => ({
        ...item,
        active: false,
      }));
      setDataCultivars(updatedDataCultivars);
    }
  };

  console.log(cultura);

  const handleSituacaoChange = (event) => {
    console.log(event.target.value);
    setSituacao(event.target.value);
  };

  const handleCheckboxChange = (event, cultivar) => {
    // Vamos usar o valor da propriedade 'checked' em vez de 'event.target.value'
    const updatedDataCultivars = dataCultivars.map((item) => {
      if (item.harvest === cultivar.harvest) {
        return { ...item, active: event.target.checked };
      }
      return item;
    });

    // Agora, você pode atualizar o estado com os novos valores
    setDataCultivars(updatedDataCultivars);
  };

  // Configurações do gráfico
  const chartOptions = {
    // ... suas configurações de gráfico aqui
  };

  console.log("HarvestIndicator");
  console.log(dataCultivars);

  const harvestSituation = [
    { label: "Todas", value: "Todas" },
    { label: "Safras Atuais", value: "atuais" },
    { label: "Safras Arquivadas", value: "arquivadas" },
  ];

  const handleChange = (event) => {
    //console.log(event.target.value);
    setDataCultivars({
      ...dataCultivars,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Typography variant="h6">Cultivares</Typography>
        <Typography variant="body2">
          Cultivares utilizados, área e proporção
        </Typography>
        <Grid container spacing={3} sx={{ mt: 2, mb: 2 }}>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{ style: { color: "#2e7d32" } }}
              id="cultivar"
              name="cultivar"
              label="Cultivares"
              select
              fullWidth
              value={cultura}
              onChange={handleCulturaChange}
              onBlur={(e) => handleCulturaChange(e, true)}
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <IconButton>
              //         <AssignmentIcon />
              //       </IconButton>
              //     </InputAdornment>
              //   ),
              // }}
              variant="outlined"
              color="success"
              helperText={`Selecione o cultivar.`}
            >
              {/* Adicione uma opção "Todas" ao seletor */}
              <MenuItem value="Todas">Todas</MenuItem>
              {/* Mapeie os itens do array para criar as opções do seletor */}
              {dataCultivars.map((option) => (
                <MenuItem key={option.cultivar} value={option.cultivar}>
                  <Box display="flex" alignItems="center">
                    <Img
                      sx={{ mt: -0.5, mr: 0.5 }}
                      alt="complex"
                      src={option.cultivation_icon || ""}
                    />
                    <Typography variant="body1">{option.cultivar}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{ style: { color: "#2e7d32" } }}
              id="harvestSituation"
              name="harvestSituation"
              label="Situação da safra"
              select
              fullWidth
              //value={data.unit}
              onChange={handleSituacaoChange}
              onBlur={(e) => handleSituacaoChange(e, true)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <AssignmentIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              color="success"
              //helperText={`Selecione a situação da sua safra.`}
            >
              {harvestSituation.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Typography variant="h6">Fazenda CPT</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              {dataCultivars.map(
                (cultivar) =>
                  // Verifique se todas as culturas estão selecionadas quando "Todas" está selecionada
                  (cultura === "Todas" || cultivar.cultivar === cultura) && (
                    <FormControlLabel
                      key={cultivar.harvest}
                      control={
                        <Checkbox
                          checked={cultivar.active}
                          onChange={(e) => handleCheckboxChange(e, cultivar)}
                        />
                      }
                      label={cultivar.harvest}
                    />
                  )
              )}
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>

      {/* <Grid item xs={6}>
        {dataCultivars.map((cultivar) => (
          <Grid container spacing={3} key={cultivar.cultivar}>
            <Grid item xs={12}>
              {cultivar.active && <ChartPieHarvest cultivar={cultivar} />}
            </Grid>
          </Grid>
        ))}
      </Grid> */}
      <Grid item xs={6}>
        <RadioGroup
          aria-label="Área"
          name="area"
          value={selectedOption}
          onChange={handleOptionChange}
        >
          <Box display="flex" alignItems="center">
            <FormControlLabel
              value="areaDePlantio"
              control={<Radio />}
              label="Área de Plantio"
            />
            <FormControlLabel
              value="areaPlantada"
              control={<Radio />}
              label="Área Plantada"
            />
          </Box>
        </RadioGroup>

        {dataCultivars.map((cultivar) => (
          <Grid container spacing={3} key={cultivar.cultivar}>
            <Grid item xs={12}>
              {cultivar.active &&
                ((selectedOption === "areaDePlantio" && (
                  <ChartPieHarvest cultivar={cultivar} />
                )) ||
                  (selectedOption === "areaPlantada" && (
                    <>
                      <ChartPieHarvesting cultivar={cultivar} />
                    </>
                  )))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default HarvestIndicator;

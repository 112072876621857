import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Card, CardHeader, Container, Divider, Grid, Paper, Skeleton, TableCell, TableRow, Typography } from '@mui/material';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import { useState } from 'react';

export default function TablesActivitiesHarvestCardEmpty({ isLoadingActivities, setIsLoadingActivities }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingText, setIsLoadingText] = useState(false);

  setTimeout(() => {
    setIsLoading(false);
  }, 1000);

  setTimeout(() => {
    setIsLoadingText(true);
  }, 3000);

  return (
    <>
      {isLoading === true ? (
        <>
          <Skeleton variant="rectangular" width="100%" height={600} />
        </>
      ) : (
        <>
          <Card sx={{ m: 1 }}>
            <CardHeader
              title={
                <>
                  <div className="d-lg-none">
                    <Box sx={{ display: 'flex', mb: 1 }}>
                      <Typography sx={{ fontSize: 18 }}>Vamos registrar as realizações das atividades e operações do campo?</Typography>
                    </Box>
                    <Box sx={{ minHeight: 110 }}>
                      <Typography style={{ color: '#2e7d32' }}>
                        {isLoadingText && (
                          <Typewriter tam={110} minHeight={0} text=" Para criar a sua primeira atividade, você pode clicar no botão verde (+ NOVA ATIVIDADE) no canto superior direito." />
                        )}
                      </Typography>
                    </Box>
                  </div>

                  <div className="d-none d-lg-block">
                    <Box sx={{ display: 'flex', mb: 1 }}>
                      <Typography variant="h6" color="textPrimary">
                        Vamos registrar as realizações das atividades e operações do campo?
                      </Typography>
                    </Box>
                    <Box sx={{ minHeight: 30 }}>
                      <Typography fontWeight="500" variant="subtitle1" style={{ color: '#808080' }}>
                        Vamos iniciar criando uma <strong style={{ color: '#2e7d32' }}>"ATIVIDADE DE PREPARO DO SOLO OU PLANTIO"</strong> para a sua cultura.
                      </Typography>
                    </Box>
                    <Box sx={{ minHeight: 30 }}>
                      <Typography style={{ color: '#2e7d32' }}>
                        {isLoadingText && <Typewriter tam={30} minHeight={0} text=" Para criar a sua primeira atividade, você pode clicar no botão verde (+ NOVA ATIVIDADE) na parte superior." />}
                      </Typography>
                    </Box>
                  </div>
                </>
              }
            ></CardHeader>

            <Divider />
          </Card>
          <Card sx={{ m: 1 }}>
            <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
              <Paper variant="" sx={{ my: { xs: 3, md: 1 }, p: { xs: 2, md: 1 } }}>
                <Box sx={{ textAlign: 'center' }}>
                  <Grid container xs={12} md={12} sx={{ m: 0 }} item alignItems="center" justifyContent="center">
                    <img src="/dist/image/atividades_safras.png" name=""></img>
                  </Grid>
                  {/* <Grid container xs={12} md={12} sx={{ mt: 1 }} item alignItems="center" justifyContent="center">
                                        <AddActivities isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} />
                                    </Grid> */}
                  <Grid container xs={12} md={12} sx={{ m: 1 }} item alignItems="center" justifyContent="center">
                    <Typography sx={{ fontSize: 22 }} color="text.secondary" gutterBottom>
                      Gestão das atividades
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      Registre todas as atividades do dia a dia, planeje com antecedência as tarefas futuras e obtenha ideias (insights) valiosos para impulsionar sua produtividade. Desperte o
                      potencial da sua propriedade e comece hoje mesmo a colher os frutos de uma gestão eficiente e estratégica. Clique no botão verde + NOVA ATIVIDADE no canto superior direito para
                      iniciar sua atividade.
                    </Typography>
                  </Grid>
                </Box>
              </Paper>
            </Container>
          </Card>
        </>
      )}
    </>
  );
}

//https://aegro.com.br/termos-de-uso/

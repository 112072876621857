import { Avatar, Grid } from "@mui/material";
import React from "react";
import AvatarFp from "../../components/Avatar/AvatarFp";
import SimpleTable from "../../components/Tables/SimpleTable";
import { CardContent, Typography } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import { green, red } from "@mui/material/colors";
import { Box } from "@mui/system";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import MenuProfile from "./components/MenuProfile";
import LogoNameWhite from "../../assets/png/logo-name-white.png";
import CardProfile from "./components/CardProfile";

export default function ProfileContent() {
  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  console.log('userCurrent')
  console.log(userCurrent)
  const user = userCurrent;
  console.log(user)
  const name = user.name;
  const charName = name[0].toUpperCase();
  console.log(charName);

  function stringAvatar(name) {
    return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
  }

  return (
    <>
      <div className="content-wrapper bg-white">
        <section className="content-header" style={{ marginTop: 0 }}>
          <div className="container-fluid">
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} md={12}>
                <Typography gutterBottom variant="h5" component="div">
                  Meus dados
                </Typography>
                <Grid item xs={12} md={12}>
                  <CardProfile />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </section >
      </div >
    </>
  );
}

import React, { Component } from 'react'
import Chart from 'react-google-charts'

const columns = [
    { type: "string", label: "Task ID" },
    { type: "string", label: "Task Name" },
    { type: "string", label: "Resource" },
    { type: "date", label: "Start Date" },
    { type: "date", label: "End Date" },
    { type: "number", label: "Duration" },
    { type: "number", label: "Percent Complete" },
    { type: "string", label: "Dependencies" },
];


const rows = [
    [
        "Meta01",
        "Meta01", // Usando a função para renderizar o conteúdo
        "Meta01",
        new Date(2023, 0, 1),
        new Date(2023, 5, 30),
        null,
        100,
        null,
    ],
    [
        "Meta02",
        "Meta02",
        "Meta02",
        new Date(2023, 6, 1),
        new Date(2023, 11, 30),
        null,
        100,
        "Meta01",
    ],

];


//export 

export const options = {
    height: 400,
    gantt: {
        criticalPathEnabled: false,
        trackHeight: 100,
        defaultStartDateMillis: new Date(2023, 9, 10),
        arrow: {
            angle: 100,
            width: 2,
            color: 'black',
            radius: 0,
        },
        barHeight: 50,
        labelStyle: {
            fontName: 'Roboto2',
            fontSize: 14,
            color: '#ffff',
        },
    },
};

function converterData(dataString) {
    // Divida a data em partes
    const partes = dataString.split(' ');

    // Divida a parte da data em ano, mês e dia
    const [ano, mes, dia] = partes[0].split('-').map(Number);

    // Crie uma nova data usando as partes extraídas
    const novaData = new Date(ano, mes - 1, dia); // Lembre-se de subtrair 1 do mês, pois o mês começa em 0

    return novaData;
}

function GanttChart({ targets }) {
    console.log("GanttChart")
    console.log(targets)

    const newRows = []

    for (const target of targets) {
        console.log(target)
        const item = [
            target.label,
            target.label, // Usando a função para renderizar o conteúdo
            target.label,
            //new Date(2023, 0, 1),
            // new Date(2023, 5, 30),
            converterData(target.date_start),
            converterData(target.date_prev_end),
            null,
            target.expectedYield,
            null,
        ]
        newRows.push(item)
    }

    console.log(newRows)

    const data = [columns, ...newRows];

    // const item = [
    //     area.name,
    //     area.name, // Usando a função para renderizar o conteúdo
    //     area.name,
    //         new Date(2023, 0, 1),
    //         new Date(2023, 5, 30),
    //         null,
    //         area.data.,
    //         null,
    //     ],

    return (
        <div className="">
            <Chart
                width="100%"
                height="50%"
                chartType="Gantt"
                loader={<div>Loading Chart</div>}
                data={data}
                rootProps={{ 'data-testid': '1' }}
                options={options}
            />
        </div>
    );
}

export default GanttChart;






// const rows = [
//     [
//       "2014Spring",
//       "Spring 2014",
//       "spring",
//       new Date(2014, 2, 22),
//       new Date(2014, 5, 20),
//       null,
//       100,
//       null,
//     ],
//     [
//       "2014Summer",
//       "Summer 2014",
//       "summer",
//       new Date(2014, 5, 21),
//       new Date(2014, 8, 20),
//       null,
//       100,
//       null,
//     ],
//     [
//       "2014Autumn",
//       "Autumn 2014",
//       "autumn",
//       new Date(2014, 8, 21),
//       new Date(2014, 11, 20),
//       null,
//       100,
//       null,
//     ],
//     [
//       "2014Winter",
//       "Winter 2014",
//       "winter",
//       new Date(2014, 11, 21),
//       new Date(2015, 2, 21),
//       null,
//       100,
//       null,
//     ],
//     [
//       "2015Spring",
//       "Spring 2015",
//       "spring",
//       new Date(2015, 2, 22),
//       new Date(2015, 5, 20),
//       null,
//       50,
//       null,
//     ],
//     [
//       "2015Summer",
//       "Summer 2015",
//       "summer",
//       new Date(2015, 5, 21),
//       new Date(2015, 8, 20),
//       null,
//       0,
//       null,
//     ],
//     [
//       "2015Autumn",
//       "Autumn 2015",
//       "autumn",
//       new Date(2015, 8, 21),
//       new Date(2015, 11, 20),
//       null,
//       0,
//       null,
//     ],
//     [
//       "2015Winter",
//       "Winter 2015",
//       "winter",
//       new Date(2015, 11, 21),
//       new Date(2016, 2, 21),
//       null,
//       0,
//       null,
//     ],
//     [
//       "Football",
//       "Football Season",
//       "sports",
//       new Date(2014, 8, 4),
//       new Date(2015, 1, 1),
//       null,
//       100,
//       null,
//     ],
//     [
//       "Baseball",
//       "Baseball Season",
//       "sports",
//       new Date(2015, 2, 31),
//       new Date(2015, 9, 20),
//       null,
//       14,
//       null,
//     ],
//     [
//       "Basketball",
//       "Basketball Season",
//       "sports",
//       new Date(2014, 9, 28),
//       new Date(2015, 5, 20),
//       null,
//       86,
//       null,
//     ],
//     [
//       "Hockey",
//       "Hockey Season",
//       "sports",
//       new Date(2014, 9, 8),
//       new Date(2015, 5, 21),
//       null,
//       89,
//       null,
//     ],
//   ];

import { useState, useContext } from "react";
import { Context } from "../../../../../components/Context/AuthContext";
import { Button, Box } from "@mui/material";
// import Success from "./Success";
import Confirm from "./Confirm";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import { toast } from "react-toastify";
import api from "../../../../../services/api";
import useStore from "../../../../../store/useStore";
import SuccessGreen from "./SuccessGreen";

export default function MultiStepForm({ data_culture, handleClose, getImage }) {
  // console.log("MultiStepForm - harvest")
  const usestore = useStore();
  const { aux_add, setAuxAdd } = useContext(Context);

  const [activeStep, setActiveStep] = useState(0);
  const property_id = (localStorage.getItem("Property") || 1);

  const steps = ["Step 1", "Step 2", "Step 3", "Step 4"];

  // errors.chave define true (property.chave não validada) ou false
  // const [errors, setErrors] = useState({});
  // gaurdar o array de erros de cada chave
  const [formError, setFormError] = useState({});

  var userCurrent = JSON.parse(localStorage.getItem("userCurrent"));

  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: property_id,
    name: "",
    typenameHarvest: data_culture.cultivation_name,
    id_cultivation: data_culture.id_cultivation,
    date_start: "",
    dateEnd: "",
    date_start: "",
    date_prev_end: "",
    areas: [],
    usedArea: [],
    measure: "hectares",
    cultivation_icon: data_culture.cultivation_icon,
    cultivation_name: data_culture.cultivation_name,
    status: "0",
    isDeleted: "0",
    situation: 'ativa',
    observation: ""
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    setFormError({});
    let errors = {};
    let formOk = true;
    //console.log(data);

    if (data.name.length < 5) {
      errors.name = true;
      formOk = false;
      // } else if (property.address.length < 3) {
    } else if (data.areas.length === 0) {
      errors.area = true;
      formOk = false;
    }

    if (formOk) {
      const areas_bkp = window.jQuery.extend(true, [], data.areas);

      data.areas = data.areas.map((repos) => ({ id: repos.id, area: repos.area, usedArea: repos.usedArea || repos.area }));
      const header = { header: { "Content-Type": "application/json" } };
      // console.log("onSubmit harvest/save");
      //console.log(data)
      const res = await api.post("/harvest/save", data, header)
        .then((response) => {
          //console.log(response.data);
          if (response.data.isSuccess) {
            toast.success('Safra criada com sucesso');
            usestore.reposProperty.fetch(userCurrent.id);
            usestore.reposAreas.fetch({ 'id_farm': property_id });
            usestore.reposHarvest.fetch({ 'id_farm': property_id }).then((res) => {
              // console.log('reposHarvest.fetch');
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleNext();
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error(error.response.data);
        })
      data.areas = areas_bkp;
    } else {
      toast.error("Por favor, preencha os campos obrigatórios");
      setFormError(errors);
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0: // seleção da data
        return <FirstStep data={data} setData={setData} handleNext={handleNext} handleClose={handleClose} activeStep={activeStep} steps={steps} />;
      case 1: // setar o nome da harvest
        return <SecondStep data={data} setData={setData} handleNext={handleNext} handleClose={handleClose} handleBack={handleBack} activeStep={activeStep} steps={steps} />;
      case 2: // seleção da área
        return <ThirdStep data={data} setData={setData} formError={formError} handleClose={handleClose} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} />;
      case 3:
        return <Confirm data={data} onSubmit={onSubmit} handleNext={handleNext} handleBack={handleBack} handleClose={handleClose} />;
      default:
        return "Não existente";
    }
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (<>
    <Box sx={{ my: 1 }}>
      {activeStep == 4 ? <></> :
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 2 }} >
          <Button disabled variant="" color="success" style={{ minHeight: "20vh", minWidth: "25vh" }} >
            <div className="nav-link" >
              <div className="justify-content-center " >
                {getImage(data.cultivation_icon)}
              </div>
              <div className=".text-success mt-3" style={{ color: '#2e7d32' }}>{data.cultivation_name}</div>
            </div>
          </Button>
        </Box>
      }
    </Box>
    <Box sx={{ width: "100%", maxWidth: 900 }}>
      {activeStep === steps.length ? (
        <SuccessGreen handleClose={handleClose} />
      ) : (<>
        {getStepContent(activeStep)}
      </>)}
    </Box>
  </>);
}

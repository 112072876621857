import { Skeleton, Box, Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import { useEffect, useState, useContext } from 'react'
import { Link, NavLink } from 'react-router-dom'
import CardTimeLine from './CardTimeLine'
import SendIcon from '@mui/icons-material/Send'
import CardInfoCentral from './CardInfoCentral'
import { toast } from 'react-toastify'
import { Context } from '../../../components/Context/AuthContext'
import CardCreations from './CardCreations'
import CardSafras from './CardSafras'
import CardAreas from './CardAreas'
import CardWeather from '../../maps/components/WeatherForecast/CardWeather'
import CardTeam from './CardTeam'
import useStore from '../../../store/useStore'
import CardProperty from './CardProperty'
import CardTraining from './CardTraining'
// import ClimatempoWeather from '../../../components/ClimaTempo/ClimatempoWeather'
import FinancialBillsPayReceive from '../../financial/components/FinancialBillsPayReceive'

export default function CardGeral() {
  const { aux_add, setAuxAdd, isLoading, isMobile } = useContext(Context)

  // instancia os repos
  const usestore = useStore()

  const property_id = localStorage.getItem('Property') || 1
  const propertyRepos = usestore.reposProperty.getPropertyStore('id', property_id)
  //const [value_property, setValue] = useState(propertyRepos[0]);

  //const [list_livestock, setValueLivestock] = useState(usestore.reposLivestock.listLivestock);
  const changeLivestockRepos = usestore.reposLivestock.changeLivestockRepos

  //const [list_harvest, setValueHarvest] = useState(usestore.reposHarvest.listHarvest);
  const changeHarvestRepos = usestore.reposHarvest.changeHarvestRepos

  const valueAreas = usestore.reposProperty.listAreasProperty

  const optionsEquipe = usestore.reposUser.listUser

  const [data_cards, setDataCard] = useState({})
  useEffect(() => {
    // console.log(isLoading)
    setDataCard({
      property: propertyRepos[0],
      criacoes: usestore.reposLivestock.listLivestock,
      safras: usestore.reposHarvest.listHarvest,
      areas: valueAreas,
      equipe: optionsEquipe,
    })
  }, [isLoading, aux_add])

  const obj_fn = {
    usestore: usestore,
    ismobile: isMobile.any(),
    // selectedMonth: selectedMonth,
    aux_add: aux_add,
    setAuxAdd: setAuxAdd
  }

  return (
    <>
      {/* Cards superiores*/}
      <div className=" ">
        <div className="content ">
          <div className="container-fluid ">
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} md={6}>
                <CardProperty />
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="d-none d-lg-block">
                  <CardTraining />
                </div>
                {/* <div className="d-none d-lg-block">
              <div className="info-box">
                <span className="info-box-icon bg-success">
                  <i className="far fa-flag" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Aprendizado e Suporte</span>
                  <div className="d-flex justify-content-between">
                    <span className="info-box-number">Aprenda como ter maior produtividade com o Fazenda Premium.</span>
                    <NavLink to="/suport" className="nav-link">
                      <Button variant="contained" size="medium" color="success">
                        SAIBA MAIS
                      </Button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>  */}
              </Grid>
            </Grid>

            <div className="row ">
              {/* card 1 coluna 1*/}
              <div className="col-lg-6 mt-4">
                <div className="">
                  <div className=" border-0">
                    <Box sx={{ display: 'flex' }}>
                      <Stack spacing={0.5} sx={{}}>
                        <Typography fontWeight="400" variant="h6">
                          Informações Gerais da Propriedade
                        </Typography>
                      </Stack>
                    </Box>
                    <div className="mt-4">{!isLoading && data_cards.property ? <CardInfoCentral data_cards={data_cards} /> : <Skeleton variant="rectangular" />}</div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className=" border-0">
                    <Box sx={{ display: 'flex' }}>
                      <Stack spacing={0.5} sx={{}}>
                        <Typography fontWeight="400" variant="h6">
                          Áreas
                        </Typography>
                      </Stack>
                    </Box>
                    <div className="mt-4">{!isLoading && data_cards.areas ? <CardAreas data_cards={data_cards} /> : <Skeleton variant="rectangular" />}</div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className=" border-0">
                    <Box sx={{ display: 'flex' }}>
                      <Stack spacing={0.5} sx={{}}>
                        <Typography fontWeight="400" variant="h6">
                          Equipe
                        </Typography>
                      </Stack>
                    </Box>
                    <div className="mt-4">{!isLoading && data_cards.equipe ? <CardTeam data_cards={data_cards} /> : <Skeleton variant="rectangular" />}</div>
                  </div>
                </div>
              </div>
              {/* card 2 coluna 2 */}
              <div className="col-lg-6 mt-4">
                <div className="">
                  <div className="border-0">
                    <Box sx={{ display: 'flex' }}>
                      <Stack spacing={0.5} sx={{}}>
                        <Typography fontWeight="400" variant="h6">
                          Criações
                        </Typography>
                      </Stack>
                    </Box>
                    <div className="mt-4">{!isLoading && data_cards.criacoes ? <CardCreations data_cards={data_cards} changeRepos={changeLivestockRepos} /> : <Skeleton variant="rectangular" />}</div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="border-0">
                    <Box sx={{ display: 'flex' }}>
                      <Stack spacing={0.5} sx={{}}>
                        <Typography fontWeight="400" variant="h6">
                          Safras
                        </Typography>
                      </Stack>
                    </Box>
                    <div className="mt-4">{!isLoading && data_cards.safras ? <CardSafras data_cards={data_cards} changeRepos={changeHarvestRepos} /> : <Skeleton variant="rectangular" />}</div>
                  </div>
                </div>
                {/* <div className="mt-4">
                  <div className="border-0">
                    <Box sx={{ display: 'flex' }}>
                      <Stack spacing={0.5} sx={{}}>
                        <Typography fontWeight="400" variant="h6">
                          Clima
                        </Typography>
                      </Stack>
                    </Box>
                    <div className="mt-4">
                      <Card>
                        <CardContent>
                          <Grid container spacing={2} sx={{ mb: 1.1 }} direction="row" alignItems="center" justifyContent="center">
                          
                            {!isLoading && data_cards.property ? <CardWeather idFarm={property_id} /> : <Skeleton variant="rectangular" />}
                          </Grid>
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="row ">
              <div className="col-lg-12 ">
                <div className=" border-0 mt-4">
                  <div className="d-flex justify-content-between">
                    <h3 className="card-title">Contas a Pagar</h3>
                  </div>
                  <div className="mt-4">
                    <Card>
                      <CardContent>
                        <Grid container spacing={2} sx={{ mb: 1.1 }} direction="row" alignItems="center" justifyContent="center">
                          <FinancialBillsPayReceive type={'expense'} obj_fn={obj_fn}/>
                        </Grid>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-lg-12 ">
                <div className=" border-0 mt-4">
                  <div className="d-flex justify-content-between">
                    <h3 className="card-title">Clima</h3>
                  </div>
                  <div className="mt-4">
                    <Card>
                      <CardContent>
                        <Grid container spacing={2} sx={{ mb: 1.1 }} direction="row" alignItems="center" justifyContent="center">
                          {/* <ClimatempoWeather cityId='210' /> */}
                          {!isLoading && data_cards.property ? <CardWeather idFarm={property_id} /> : <Skeleton variant="rectangular" />}
                        </Grid>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
      {/* FIM Cards superiores*/}
    </>
  )
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import StepIcon from '../Icons/tutorial/StepIcon';
import { expandAndFade } from '../Animations/animationKeyFrames';
import Typewriter from '../Typewriter/Typewriter';
import { useNavigate } from 'react-router-dom';
import ProductRegistrar from '../../pages/products/components/ProductRegistrar/ProductRegistrar';
import RegisterNewStorageLocations from '../../pages/inventory/components/RegisterNewStorage/RegisterNewStorageLocations';
import RegisterPerson from '../../pages/financial/components/SingleRegsiter/RegisterPerson';
import { Grid, Typography } from '@mui/material';
import RegisterBuysSalesDialog from '../../pages/financial/components/FormMain/RegisterBuysSalesDialog';
import RegisterBuyDialog from '../../pages/inventory/RegisterBuyDialog';
import RegisterNewPatrimony from '../../pages/patrimony/RegisterNewPatrimony';
import RegisterNewMaintenance from '../../pages/patrimony/RegisterNewMaintenance';
import StartProjectLivestock from '../../pages/livestock/components/addLivestock/tutorial/StartProjectLivestock';
import SelectLivestock from '../../pages/livestock/components/addLivestock/SelectLivestock';
import SelectHarvest from '../../pages/harvest/components/addHarvest/SelectHarvest';

export default function TutorialStepGeral({ openModal, setOpenModal, objText }) {
  const [open, setOpen] = React.useState(openModal || false);
  const [showProductRegistrar, setShowProductRegistrar] = React.useState(false); // Estado para controlar se exibe ProductRegistrar
  const [showStockRegistrarModal, setShowStockRegistrarModal] = React.useState(false);
  const [showPeopleRegistrarModal, setShowPeopleRegistrarModal] = React.useState(false);
  const [showBuyRegistrarModal, setShowBuyRegistrarModal] = React.useState(false);
  const [showSalesRegistrarModal, setShowSalesRegistrarModal] = React.useState(false);
  const [showPatrimonyRegistrarModal, setShowPatrimonyRegistrarModal] = React.useState(false);
  const [showMaintenanceRegistrarModal, setShowMaintenanceRegistrarModal] = React.useState(false);
  const [showGPRegistrarModal, setShowGPRegistrarModal] = React.useState(false);
  const [showProjectLivestockRegistrarModal, setShowProjectLivestockRegistrarModal] = React.useState(false);
  const [showProjectHarvestRegistrarModal, setShowProjectHarvestRegistrarModal] = React.useState(false);

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (setOpenModal) {
      setOpenModal(false);
    }
  };

  const textLink = 'Clique no botão a seguir para ser redirecionado. ';

  const handleButtonClick = (linkOrAction) => {
    if (linkOrAction === 'openProductRegistrarModal') {
      // Alterna a exibição do ProductRegistrar
      setShowProductRegistrar((prev) => !prev);
    } else if (linkOrAction === 'openStockRegistrarModal') {
      setShowStockRegistrarModal((prev) => !prev);
    } else if (linkOrAction === 'openPeopleRegistrarModal') {
      setShowPeopleRegistrarModal((prev) => !prev);
    } else if (linkOrAction === 'openBuyRegistrarModal') {
      setShowBuyRegistrarModal((prev) => !prev);
    } else if (linkOrAction === 'openSalesRegistrarModal') {
      setShowSalesRegistrarModal((prev) => !prev);
    } else if (linkOrAction === 'openPatrimonyRegistrarModal') {
      setShowPatrimonyRegistrarModal((prev) => !prev);
    } else if (linkOrAction === 'openMaintenanceRegistrarModal') {
      setShowMaintenanceRegistrarModal((prev) => !prev);
    } else if (linkOrAction === 'openGPRegistrarModal') {
      setShowGPRegistrarModal((prev) => !prev);
    } else if (linkOrAction === 'openProjectLivestockRegistrarModal') {
      setShowProjectLivestockRegistrarModal((prev) => !prev);
    } else if (linkOrAction === 'openProjectHarvestRegistrarModal') {
      setShowProjectHarvestRegistrarModal((prev) => !prev);
    } else if (linkOrAction === '/patrimony') {
      console.log(linkOrAction);
      // console.log(`${linkOrAction}#depreciation`);
      handleClose(); // Fecha o modal principal
      setTimeout(() => {
        //  navigate(`${linkOrAction}#depreciation`); // Redireciona para a URL com o hash
        navigate(`${linkOrAction}`); // Redireciona para a URL com o hash
      }, 200); // Aguarda 200ms para garantir o fechamento do modal
    } else if (linkOrAction) {
      // Caso seja um link, navega
      console.log(linkOrAction);
      handleClose(); // Fecha o modal principal
      setTimeout(() => {
        navigate(linkOrAction); // Redireciona após o modal fechar
      }, 200); // Aguarda 200ms para garantir o fechamento do modal
    }
  };

  const getMenuItem = (component) => {
    return (
      <span
        onClick={(event) => event.stopPropagation()}
        disablefocusripple
        disableripple
        onKeyDown={(event) => {
          if (/P|p/.test(event.key) || event.key === 'Tab' || event.key === 'Home' || event.key === 'End') {
            event.stopPropagation();
          }
        }}
      >
        {component}
      </span>
    );
  };

  return (
    <div>
      <Button variant="text" color="success" onClick={(e) => handleClickOpen(e)} sx={{ animation: `${expandAndFade} 2s linear infinite` }}>
        <StepIcon />
      </Button>
      <Dialog maxWidth="sm" fullWidth open={open || openModal} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {objText?.icon}{' '}
            <Typography sx={{ ml: 1 }} variant="h6">
              {objText?.title}
            </Typography>
          </div>
          <Typography sx={{ ml: 5.5 }} variant="subtitle2" color="textSecondary">
            {objText?.subtitle}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} md={12} alignItems="center">
              <Typewriter tam={70} text={objText?.textAnimation} variant={'subtitle1'} color={'#2e7d32'} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} alignItems="center">
              {objText?.description}
            </Grid>
            {/* Renderizando os botões com links */}
            {objText?.textButton?.map((text, index) => {
              const linkOrAction = objText?.linkButton?.[index];
              return (
                <Grid item xs={12} md={12} key={index} alignItems="center">
                  <Button variant="outlined" color="success" onClick={() => handleButtonClick(linkOrAction)}>
                    {text}
                  </Button>
                  {/* Renderiza o componente ProductRegistrar abaixo do botão */}
                  {linkOrAction === 'openProductRegistrarModal' && showProductRegistrar && (
                    <>
                      <Grid item xs={12} md={12} sx={{ mt: 2 }} alignItems="center">
                        {textLink}
                      </Grid>
                      <Grid item xs={12} md={12} sx={{ mb: 1 }} alignItems="center">
                        <ProductRegistrar filters={objText.filters_prods} open={showProductRegistrar} onClose={() => setShowProductRegistrar(false)} />
                      </Grid>
                    </>
                  )}

                  {linkOrAction === 'openStockRegistrarModal' && showStockRegistrarModal && (
                    <>
                      <Grid item xs={12} md={12} sx={{ mt: 2 }} alignItems="center">
                        {textLink}
                      </Grid>
                      <Grid item xs={12} md={12} sx={{ mb: 1 }} alignItems="center">
                        <RegisterNewStorageLocations />
                      </Grid>
                    </>
                  )}
                  {linkOrAction === 'openPeopleRegistrarModal' && showPeopleRegistrarModal && (
                    <>
                      <Grid item xs={12} md={12} sx={{ mt: 2 }} alignItems="center">
                        {textLink}
                      </Grid>
                      <Grid item xs={12} md={12} sx={{ mb: 1 }} alignItems="center">
                        <RegisterPerson />
                      </Grid>
                    </>
                  )}
                  {linkOrAction === 'openBuyRegistrarModal' && showBuyRegistrarModal && (
                    <>
                      <Grid item xs={12} md={12} sx={{ mt: 2 }} alignItems="center">
                        {textLink}
                      </Grid>
                      <Grid item xs={12} md={12} sx={{ mb: 1 }} alignItems="center">
                        {getMenuItem(<RegisterBuyDialog type={'buy'} color1={'#2e7d32'} />)}
                      </Grid>
                    </>
                  )}
                  {linkOrAction === 'openSalesRegistrarModal' && showSalesRegistrarModal && (
                    <>
                      <Grid item xs={12} md={12} sx={{ mt: 2 }} alignItems="center">
                        {textLink}
                      </Grid>
                      <Grid item xs={12} md={12} sx={{ mb: 1 }} alignItems="center">
                        {getMenuItem(<RegisterBuyDialog type={'sales'} color1={'#2e7d32'} />)}
                      </Grid>
                    </>
                  )}
                  {linkOrAction === 'openPatrimonyRegistrarModal' && showPatrimonyRegistrarModal && (
                    <>
                      <Grid item xs={12} md={12} sx={{ mt: 2 }} alignItems="center">
                        {textLink}
                      </Grid>
                      <Grid item xs={12} md={12} sx={{ mb: 1 }} alignItems="center">
                        <RegisterNewPatrimony />
                      </Grid>
                    </>
                  )}
                  {linkOrAction === 'openMaintenanceRegistrarModal' && showMaintenanceRegistrarModal && (
                    <>
                      <Grid item xs={12} md={12} sx={{ mt: 2 }} alignItems="center">
                        {textLink}
                      </Grid>
                      <Grid item xs={12} md={12} sx={{ mb: 1 }} alignItems="center">
                        <RegisterNewMaintenance />
                      </Grid>
                    </>
                  )}
                  {linkOrAction === 'openGPRegistrarModal' && showGPRegistrarModal && (
                    <>
                      <Grid item xs={12} md={12} sx={{ mt: 2 }} alignItems="center">
                        {textLink}
                      </Grid>
                      <Grid item xs={12} md={12} sx={{ mb: 1 }} alignItems="center">
                        <StartProjectLivestock />
                      </Grid>
                    </>
                  )}

                  {linkOrAction === 'openProjectLivestockRegistrarModal' && showProjectLivestockRegistrarModal && (
                    <>
                      <Grid item xs={12} md={12} sx={{ mt: 2 }} alignItems="center">
                        {textLink}
                      </Grid>
                      <Grid item xs={12} md={12} sx={{ mb: 1 }} alignItems="center">
                        <SelectLivestock />
                      </Grid>
                    </>
                  )}
                  {linkOrAction === 'openProjectHarvestRegistrarModal' && showProjectHarvestRegistrarModal && (
                    <>
                      <Grid item xs={12} md={12} sx={{ mt: 2 }} alignItems="center">
                        {textLink}
                      </Grid>
                      <Grid item xs={12} md={12} sx={{ mb: 1 }} alignItems="center">
                        <SelectHarvest />
                      </Grid>
                    </>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="success" onClick={handleClose} autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Stack } from '@mui/material';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import ResourcesStepFormTabGeneralExpenses from './ResourcesStepFormTabGeneralExpenses';
import ExpensesCostCalculator from './ExpensesCostCalculator';
import ResourcesStepFormTabInputs from './ResourcesStepFormTabInputs';
import { useEffect } from 'react';
import FormApportionment from '../../financial/components/Forms/FormApportionment';
import FooterModal from '../../../components/Footer/FooterModal';
import { CurrencyExchange, ShoppingCart } from '@mui/icons-material';
import CustomRadioGroup from '../../../components/Fields/CustomRadioGroup';
import ChecklistIcon from '@mui/icons-material/Checklist';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ResourcesStepFormMainTab({ data, obj_fn }) {
  const [value, setValue] = React.useState(0);
  const [showTabs, setShowTabs] = React.useState(false); // Estado para mostrar ou ocultar os tabs
  const [showApportionment, setShowApportionment] = React.useState(false); // Novo estado para controlar exibição de FormApportionment

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // if (showTabs == false) {
  //   console.log(data);

  // }

  useEffect(() => {
    if (showTabs == false) {
      obj_fn.setData((prevData) => ({
        ...prevData,
        resources: {
          ...prevData.resources,
          expenses: prevData.resources?.expenses || [], // Inicializa expenses se undefined
          products: prevData.resources?.products || [], // Inicializa products se undefined
        },
      }));
    }
  }, []);

  useEffect(() => {
    const savedShowTabs = localStorage.getItem('showTabs');
    //console.log(savedShowTabs);
    if (savedShowTabs !== null) {
      //console.log(savedShowTabs);
      setShowTabs(savedShowTabs === 'true');
    }
  }, []);

  // Função chamada para o segundo RadioGroup (mostrar FormApportionment)
  const handleApportionmentChange = (event) => {
    const newValue = event.target.value === 'Sim';
    var project = [];

    if (newValue) {
      let aux_project = [];
      // busca dados do projeto ativo
      var creationHarvestRepos = JSON.parse(localStorage.getItem('creationHarvestRepos') || '{ "label": "", "id": 0, "id_property": 0, "tipo_repos": "" }');
      if (creationHarvestRepos.tipo_repos === 'Safras') {
        aux_project = obj_fn.usestore.reposHarvest.getHarvestStore('id', creationHarvestRepos.id)[0];
      } else {
        aux_project = obj_fn.usestore.reposLivestock.getLivestockStore('id', creationHarvestRepos.id)[0];
      }

      if (aux_project?.id) {
        project.push({
          id: aux_project.id,
          name: aux_project.name,
          id_account: aux_project.id_account,
          tipo_repos: aux_project.tipo_repos,
          val: 100,
        });
      }
    }
    obj_fn.setData((prevState) => ({
      ...prevState,
      projects_apport: project, // atualiza apport do data
      resources: {
        ...prevState.resources,
        projects_apport: project,
      },
    }));
    setShowApportionment(newValue); // Atualiza o estado para exibir ou ocultar FormApportionment
  };

  const bankRepos = obj_fn.usestore.reposBank.bankRepos;

  // useEffect para monitorar mudanças em data.projects_apport
  useEffect(() => {
    var project = data?.projects_apport || data?.resources?.projects_apport || [];

    obj_fn.setData((prevState) => ({
      ...prevState, // Mantém o estado anterior
      projects_apport: project, // Atualiza projects_apport
      resources: {
        ...prevState.resources, // Preserva outros valores de resources
        projects_apport: project, // Atualiza projects_apport
        bankAccount: bankRepos,
      },
    }));
  }, [data?.projects_apport]);

  // Função chamada ao mudar a seleção (Sim/Não)
  const handleSelectChange = (event) => {
    const newValue = event.target.value === 'Sim';
    console.log(newValue);

    setShowTabs(newValue); // Atualiza o estado de 'showTabs'
    localStorage.setItem('showTabs', newValue); // Salva a escolha no localStorage

    var project = [];
    if (!newValue) {
      // Limpa os dados de expenses, products, e projects_apport quando a escolha é "Não"
      obj_fn.setData((prevState) => ({
        ...prevState,
        projects_apport: project, // atualiza apport do data
        resources: {
          expenses: [],
          products: [],
          projects_apport: project,
        },
      }));
    // } else {
    //   // Sim
    //   // busca dados do projeto ativo
    //   var creationHarvestRepos = JSON.parse(localStorage.getItem('creationHarvestRepos') || '{ "label": "", "id": 0, "id_property": 0, "tipo_repos": "" }');
    //   if (creationHarvestRepos.tipo_repos === 'Safras') {
    //     project = obj_fn.usestore.reposHarvest.getHarvestStore('id', creationHarvestRepos.id);
    //   } else {
    //     project = obj_fn.usestore.reposLivestock.getLivestockStore('id', creationHarvestRepos.id);
    //   }

    //   obj_fn.setData((prevState) => ({
    //     ...prevState,
    //     projects_apport: project, // atualiza apport do data
    //     resources: {
    //       projects_apport: project,
    //     },
    //   }));
    }
  };

  // const para validar o button continuar
  const isError = () => {
    var iserror = false;
    // if (true) {
    //   iserror = true;
    // }
    return iserror;
  };

  const returnError = () => {
    return false;
  };
  // console.log('data');
  // console.log(data);

  return (
    <Box sx={{ width: '100%' }}>
      {/* Pergunta com combobox Sim/Não */}
      {/* Usando o componente CustomRadioGroup */}
      <Grid container spacing={3} sx={{ mb: 0, mt: 5 }}>
        <CurrencyExchange />
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Registros dos custos de produção:</Typography>
          </Stack>
        </Box>
      </Grid>
      <FormControl fullWidth sx={{ mt: 3 }}>
        <CustomRadioGroup labelText="Você gostaria de registrar as despesas (custo operacional) para esta atividade?" value={showTabs ? 'Sim' : 'Não'} onChange={handleSelectChange} />
      </FormControl>

      {showTabs && (
        <>
          <Grid container sx={{ mt: 2 }}>
            <Grid item sm={12} xs={12}>
              <Typography paragraph variant="subtitle1" color="textSecondary" fontWeight={500}>
                Ao optar por 'Sim', por favor, registre as despesas ou insumos utilizados a partir do estoque.
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{}} />
          <Grid container spacing={3} sx={{ mb: 3, mt: 4 }}>
            <Box sx={{ ml: 1, display: 'flex' }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}>
                  <ShoppingCart sx={{ mr: 1 }} />
                  Despesas (Custos Operacionais)
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <ExpensesCostCalculator data={data} obj_fn={obj_fn} />

          <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 5 }}>
            <Tabs textColor="success" TabIndicatorProps={{ style: { background: 'green' } }} variant="scrollable" value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab
                label={
                  <Grid container direction="row" alignItems="center">
                    <PermIdentityIcon sx={{ mr: 0.5, mb: 0.2 }} />
                    Despesas Gerais
                  </Grid>
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <Grid container direction="row" alignItems="center">
                    <CenterFocusStrongIcon sx={{ mr: 0.5, mb: 0.2 }} />
                    Insumos (produtos)
                  </Grid>
                }
                {...a11yProps(1)}
              />
              {/* <Tab
                label={
                  <Grid container direction="row" alignItems="center">
                    <CenterFocusStrongIcon sx={{ mr: 0.5, mb: 0.2 }} />
                    Manutenção
                  </Grid>
                }
                {...a11yProps(2)}
              /> */}
              {/* <Tab
                label={
                  <Grid container direction="row" alignItems="center">
                    <CenterFocusStrongIcon sx={{ mr: 0.5, mb: 0.2 }} />
                    Instalações
                  </Grid>
                }
                {...a11yProps(3)}
              />
              <Tab
                label={
                  <Grid container direction="row" alignItems="center">
                    <CenterFocusWeakIcon sx={{ mr: 0.5, mb: 0.2 }} />
                    Outros
                  </Grid>
                }
                {...a11yProps(4)}
              /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ResourcesStepFormTabGeneralExpenses data={data} obj_fn={obj_fn} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ResourcesStepFormTabInputs data={data} obj_fn={obj_fn} />
          </TabPanel>
          {/* <TabPanel value={value} index={2}>
            <ResourcesStepFormTabMaintenance data={data} obj_fn={obj_fn} />
          </TabPanel> */}
          {/*  <TabPanel value={value} index={2}>
        <FourthStepFormInputOthersTabMachinery data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <FourthStepFormInputOthersTabInstallations data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <FourthStepFormInputOthersTabOthers data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />
      </TabPanel> */}
          {/* Novo RadioGroup para o FormApportionment */}
          <Divider sx={{ mt: 4 }} />
          {(data.resources?.expenses?.length > 0 || data.resources?.products?.length > 0) && (
            <FormControl fullWidth sx={{ mt: 1 }}>
              <CustomRadioGroup
                labelText={
                  <>
                    Você deseja atribuir as despesas à propriedade ou distribuí-las (rateá-las)?
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 0 }}>
                      Caso opte por 'Não', a despesa será automaticamente registrada na safra atual ({data?.projects_apport[0]?.label}).
                    </Typography>
                  </>
                }
                value={showApportionment ? 'Sim' : 'Não'}
                onChange={handleApportionmentChange}
              />
            </FormControl>
          )}

          {/* Exibir FormApportionment se showApportionment for true */}
          {showApportionment && (
            <Grid item xs={12} sm={12} sx={{ mt: 0, mb: 8 }}>
              <FormApportionment data={data} obj_fn={obj_fn} />
            </Grid>
          )}
          <Divider sx={{}} />
          <Grid container sx={{ mt: 2, mb: 2 }}>
            <Grid item sm={12} xs={12}>
              <Typography paragraph variant="subtitile1" style={{ color: '#2e7d32' }} fontWeight={500}>
                Optando por inserir despesas é necessário inserir as informações para continuar.
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      {!showTabs && (
        <>
          <Grid container sx={{ mt: 3 }}>
            <Grid item sm={12} xs={12}>
              <Typography paragraph variant="subtitle1" style={{ color: '#2e7d32' }} fontWeight={500}>
                Se você optar por não registrar as despesas, clique em 'CONTINUAR' para prosseguir...
              </Typography>
            </Grid>
          </Grid>
          <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
        </>
      )}
    </Box>
  );
}

import { useState } from 'react';
import { TextField, Box, Grid, Typography, InputAdornment, Stack, Card, CardContent, CardMedia, Button, AppBar, Toolbar, ListItemText, IconButton, ListItem, List } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { Span } from '../../../components/Typography';
import Typewriter from '../../../components/Typewriter/Typewriter';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import PanToolIcon from '@mui/icons-material/PanTool';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';

const useStyles = makeStyles({
  // selected: {
  //     border: "2px solid green",

  // },
  img: {
    height: 'auto',
  },
});

export default function StartSecondStep({ data, setData, handleNext, handleBack, activeStep, steps, handleClose }) {
  console.log(data);

  const [imageSrc, setImageSrc] = useState('/dist/image/startmap.jpeg');

  const handleCropIconClick = () => {
    setImageSrc('/dist/image/start_registername.png'); // Substitua pelo caminho da imagem desejada para o CropOriginalIcon
  };

  const handleShareLocationIconClickSecond = () => {
    setImageSrc('/dist/image/startmap.jpeg'); // Substitua pelo caminho da imagem desejada para o ShareLocationIcon
  };

  const handleShareLocationIconClickThrid = () => {
    setImageSrc('/dist/image/map_areas.png'); // Substitua pelo caminho da imagem desejada para o ShareLocationIcon
  };

  const handleShareLocationIconClickFourth = () => {
    setImageSrc('/dist/image/map_criacao_safra.png'); // Substitua pelo caminho da imagem desejada para o ShareLocationIcon
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const para validar o button continuar, se existem as 2 validações
  const isError = () => {
    return false;
  };

  const returnError = () => {
    return false;
  };

  const color = '#2e7d32';

  const classes = useStyles;

  return (
    <>
      <Grid container sx={{ mb: 0 }}>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <img src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="AdminLTE Logo" className="brand-image" style={{ opacity: '.8', height: 32, maxWidth: '100%' }} />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5} sx={{ mt: 0 }}>
              <Typography variant="h6" fontWeight={500}>
                Fazenda Premium
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container className={classes.root} sx={{ mt: 3 }} style={{ height: 'auto', maxWidth: '100%' }}>
          <Grid container sm={6} xs={12}>
            <Grid item sm={12} xs={12} sx={{ mb: 0, mt: 1 }}>
              {/* <Typography paragraph variant="h6" fontWeight={500}>
                                Configurações iniciais
                            </Typography> */}
              <Box sx={{ mt: 1 }}>
                <div className="d-lg-none">
                  <Typography gutterBottom style={{ color: '#2e7d32' }}>
                    <Typewriter tam={60} text="Como é o seu primeiro acesso, precisaremos realizar as configurações iniciais." />
                  </Typography>
                </div>

                <div className="d-none d-lg-block">
                  <Typography gutterBottom style={{ color: '#2e7d32' }}>
                    <Typewriter tam={60} text="Como é o seu primeiro acesso, precisaremos realizar as configurações iniciais." />
                  </Typography>
                </div>
              </Box>
              <List sx={{ width: '100%', bgcolor: 'background.paper', mt: 2 }}>
                <Box>
                  <ListItem
                    key={null}
                    disableGutters
                    secondaryAction={
                      <IconButton aria-label="comment">
                        <CropOriginalIcon style={{ color: '#2e7d32' }} onClick={handleCropIconClick} />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={<strong>Passo 1:</strong>} secondary="Registrar o nome e o tamanho da propriedade." />
                  </ListItem>
                </Box>
                <Box>
                  <ListItem
                    key={null}
                    disableGutters
                    secondaryAction={
                      <IconButton aria-label="comment">
                        <CropOriginalIcon style={{ color: '#2e7d32' }} onClick={handleShareLocationIconClickSecond} />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={<strong>Passo 2:</strong>} secondary="Registrar a propriedade no Google Maps." />
                  </ListItem>
                </Box>
                {/* <Box>
                  <ListItem
                    key={null}
                    disableGutters
                    secondaryAction={
                      <IconButton aria-label="comment">
                        <ShareLocationIcon style={{ color: '#2e7d32' }} onClick={handleShareLocationIconClickThrid} />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={<strong>Passo 3:</strong>} secondary="Organizar as áreas de produção no Google Maps." />
                  </ListItem>
                </Box> */}
                <ListItem
                  key={null}
                  disableGutters
                  secondaryAction={
                    <IconButton aria-label="comment">
                      <AccountTreeIcon style={{ color: '#2e7d32' }} onClick={handleShareLocationIconClickFourth} />
                    </IconButton>
                  }
                >
                  <ListItemText primary={<strong>Depois:</strong>} secondary="Registrar sua primeira safra ou criação, criando um projeto pecuário ou agrícola." />
                </ListItem>
              </List>
              <Typography paragraph variant="subtitile1" style={{ color: '#2e7d32' }} fontWeight={500}>
                Vamos lá? Clique em continuar...
              </Typography>
            </Grid>
          </Grid>

          <Grid container sm={6} xs={12}>
            <Grid container style={{ padding: '4%' }}>
              <Grid item sm={12} xs={12}>
                <img src={imageSrc} style={{ height: 'auto', maxWidth: '100%', borderRadius: '10px' }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              {/* <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                <Span>cancelar</Span>
              </Button> */}
            </Box>
            {data && (
              <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                <Button onClick={handleBack} color="success" sx={{ marginRight: 2 }} disabled={returnError()}>
                  Retornar
                </Button>
                {/* O botão Confirmar e Salvar, leva para tela successo */}
                <Button
                  sx={{
                    animation: `${expandAndFade} 2s linear infinite`, // Aplica a animação expandAndFade ao botão
                  }}
                  color="success"
                  variant="contained"
                  type="submit"
                  onClick={!isError() ? handleNext : () => null}
                  disabled={isError()}
                >
                  {activeStep === steps.length - 1 ? 'Confirmar e Salvar' : 'Continuar'}
                </Button>
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </Grid>
    </>
  );
}

import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import api from '../services/api';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';

// create the store
const useUserReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listUser: [],
        userRepos: {},
        intervalo: {},
        fetch: async (item) => {
          var id_property = (localStorage.getItem("Property") || 1);

          if (!item) {
            item = { 'id_property': id_property };
          } else if (!item.id_property) {
            item.id_property = id_property;
          }
          const header = { header: { "Content-Type": "application/json" } };
          const response = await api.post("users/list", (item), header);
          const data = await response.data;
          const data_map = await (data.isSuccess ? data._value : []);
          await set({
            listUser:
              data_map.map((user) => ({
                id: user.id,
                name: user.name,
                charName: user.name[0].toUpperCase(),
                email: user.email,
                phone: user.phone || '',
                farms: user.farms.split(","),
                id_role: user.id_role,
                role: user.role,
                created_at: dayjs(user.created_at).format('YYYY-MM-DD HH:mm:ss'),
                permission: JSON.parse(user.permission),
                permissions: user.permissions,
                settings: user.settings,
                isDeleted: user.isDeleted,
              }))
          })
          const userCurrent = JSON.parse(localStorage.getItem("userCurrent") || '{"id": 1}');

          set({
            userRepos: get().getUserStore('id', userCurrent.id)[0]
          })
          // atualiza userCurrent inicial que está com permissions faltando
          var user_current = window.jQuery.extend(true, [], get().getUserStore('id', userCurrent.id))[0];
          user_current.settings.has_update = false;

          if (typeof user_current.permission != 'string') {
            user_current.permission = JSON.stringify(user_current.permission);
          }

          localStorage.setItem("userCurrent", JSON.stringify(user_current));

          // seta FONTE DO SITE
          let val_zoom = user_current.settings['geral']['fontSize'];
          localStorage.setItem("setting_zoom", val_zoom);
          window.jQuery(window.jQuery.find("body")).css({ zoom: val_zoom });

          get().setInterv();

          return data;
        },
        // pega o(s) user(s) da listUser de acordo com o [key e val] informados
        getUserStore: (key, val) => {
          return get().listUser.filter((user) => {
            return user[key] == val;
          })
        },
        // Adiciona uma nova user à listUser
        addUserStore: (user) => {
          set(state => ({
            listUser: [...state.listUser, user]
          }))
        },
        // Gera uma nova listUser sem a user informada
        delUserStore: (id) => {
          set({
            listUser:
              get().listUser.filter((user) => {
                return user.id !== id;
              })
          })
        },
        // atualiza listUser de acordo com id, key e val informados
        updateUserStore: (id, key, val) => {
          set({
            listUser:
              get().listUser.map((user) => {
                if (user.id === id) {
                  if (key === 'all') { // atualiza todos os dados
                    user = val;
                  } else {
                    user[key] = val;
                  }
                }
                return user;
              })
          })
          return get().listUser;
        },
        rmSettingsLH: (itens, tipo) => {
          let new_userC = JSON.parse(localStorage.getItem("userCurrent"));
          if (!new_userC || !new_userC.settings) {
            return false;
          }
          let stgs_lv = new_userC.settings[tipo];

          for (const item of itens) {
            // livestock ou safra
            if (new_userC.settings[tipo].first === item.id) {
              new_userC.settings[tipo].first = stgs_lv.second;
              new_userC.settings[tipo].second = stgs_lv.third;
              new_userC.settings[tipo].third = 0;
            }
            if (new_userC.settings[tipo].second === item.id) {
              new_userC.settings[tipo].second = stgs_lv.third;
              new_userC.settings[tipo].third = 0;
            }
            if (new_userC.settings[tipo].third === item.id) {
              new_userC.settings[tipo].third = 0;
            }
          }

          get().updateSettings(new_userC);
        },
        updateSettings: (new_userC) => {
          new_userC.settings.has_update = true;

          get().updateUserStore(new_userC.id, 'settings', new_userC.settings);
          set({ userRepos: new_userC });

          if (typeof new_userC.permission != 'string') {
            new_userC.permission = JSON.stringify(new_userC.permission);
          }
          localStorage.setItem("userCurrent", JSON.stringify(new_userC));

          return new_userC;
        },
        setInterv: () => {
          // 1 minuto verifica se houveram atualizações no settings
          set({ intervalo: 
            setInterval(() => {
              let auth = JSON.parse(localStorage.getItem("authenticated") || "false");
              if (!auth) {
                clearInterval(get().intervalo);
                return false;
              }
              let new_userC = JSON.parse(localStorage.getItem("userCurrent"));
              if (!new_userC || !new_userC.settings || !new_userC.settings.has_update) {
                return false;
              }

              let data = {
                icons_property: new_userC.settings.icons_property,
                icons_gestao: new_userC.settings.icons_gestao,
                mapa: new_userC.settings.mapa,
                geral: new_userC.settings.geral,
                safra: new_userC.settings.safra,
                livestock: new_userC.settings.livestock,
                zootecnico: new_userC.settings.zootecnico,
                lembretes: new_userC.settings.lembretes,
                backup: new_userC.settings.backup,
                id: new_userC.id,
              }
              new_userC.settings.has_update = false;
              localStorage.setItem("userCurrent", JSON.stringify(new_userC));

              const header = { header: { "Content-Type": "application/json" } };
              api.post("/users/settingsUser", data, header).then((response) => {
                if (response.data.isSuccess) {
                  console.log("Configurações atualizadas com sucesso!");
                }
              })
              .catch((error) => {
                console.log("Erro ao atualizar Configurações, tente novamente mais tarde!");
                console.log(error);
              });

            }, 60000)
          });
        },
      }),
      { name: "reposUser" }
    )
  )
);

export default useUserReposStore;
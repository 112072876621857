import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"

function formatValue(value, only_v) {
  var aux_value = value
  if (typeof value == 'string') {
    aux_value = parseFloat(value)
  } else if (!value) {
    aux_value = 0
  }
  var aux_val = aux_value.toFixed(2)
  var aux_val_f1 = aux_val.replace('.', ',')

  return (!only_v ? 'R$ ' : '') + aux_val_f1
}

// https://apexcharts.com/docs/options/datalabels/
export default function ChartLineGeneric({ chart_data }) {

  let defaul_chart = {
    series: (chart_data?.series || [{name: "None", data: [0] }]),
    colors: (chart_data?.colors || ["#000"]),
    title: (chart_data?.title || "None"),
    categories: (chart_data?.categories || ['Void']),
    height: chart_data?.height || 400
  }

  const state = {
    series: defaul_chart.series,
    options: {
      chart: {
        height: defaul_chart.height,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      colors: defaul_chart.colors,
      stroke: {
        width: 3,
        curve: "smooth",
      },
      title: {
        text: defaul_chart.title,
        align: "left",
      },
      dataLabels: {
        enabled: true,
        formatter: function(value, { seriesIndex, dataPointIndex, w }) {
          return formatValue(value, true)
        }
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return val + ": " + formatValue(opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex])
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      yaxis: {
        labels: {
          formatter: function (y) {
            return "R$ " + y.toFixed(0)
          },
        },
      },
      xaxis: {
        type: 'category',
        categories: defaul_chart.categories,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return formatValue(val)
          },
        },
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
    },
  }

  return (
    <div style={{ width: "100%" }} className="bg-white w-full" id={defaul_chart.title}>
      <ReactApexChart options={state.options} series={state.series} type="line" height={defaul_chart.height} />
    </div>
  )
}

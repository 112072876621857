import React from 'react';
import IndicatorsTabs from './components/IndicatorsTabs';
import { Box, Grid, Stack, Typography } from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';
import PollIcon from '@mui/icons-material/Poll';
import TutorialIndicators from './components/TutorialIndicators';

export default function IndicatorsContent() {
  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={8} md={8}>
              <Box sx={{ display: 'flex' }}>
                <PollIcon sx={{ fontSize: 35 }} color="text.secondary" />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Indicadores - EM CONSTRUÇÃO
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                    Avaliação&nbsp;do&nbsp;desempenho&nbsp;das&nbsp;criações&nbsp;ou&nbsp;safras
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={4} md={4}>
              <Box display="flex" justifyContent="flex-end">
                <TutorialIndicators />
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="content mt-1">
        <div className="container-fluid">
          <IndicatorsTabs />
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react'
import { Box, Grid, Typography, Stack, Table, TableBody, TableRow, TableCell, TableContainer } from '@mui/material'
import { InsertLink } from '@mui/icons-material'
// import PercentTextField from '../../../../components/Fields/PercentTextField'
import AutocompleteGeneric from '../../../../components/Fields/AutocompletGeneric'
import GenericValueField from '../../../../components/Fields/GenericValueField'

export default function FormApportionment({ data, obj_fn }) {
  const [porcent, setPorcent] = useState(0);
  const id_property = localStorage.getItem('Property') || 1

  // Monta o array e obj para o campo de projeto
  const optionsLivestocks = obj_fn.usestore.reposLivestock.listLivestock
  const optionsHarvests = obj_fn.usestore.reposHarvest.listHarvest
  const initFarm = obj_fn.usestore.reposProperty.getPropertyStore('id', id_property)
  const optionsProjects = [...initFarm, ...optionsLivestocks, ...optionsHarvests]
  //const optionsProjects = [...optionsLivestocks, ...optionsHarvests];

  const opt_projects = {
    multiple: true,
    name: 'prop_c_h',
    label: 'Tipo de Projeto',
    values: optionsProjects,
    field: 'projects',
    variant: 'standard',
    helperText: 'Selecione para onde deseja ratear o valor',
    listHeight: 225,
  }

  const handleChangeSelect = (field, newValue) => {
    console.log('handleChangeSelect')
    var aux_val = 0
    var err_porcent = 100
    let projects_apport = []
    newValue.map((project) => {
      aux_val = Number((100 / newValue.length).toFixed(2))
      projects_apport.push({
        id: project.id,
        name: project.name,
        id_account: project.id_account,
        tipo_repos: project.tipo_repos,
        val: aux_val,
      })
      err_porcent = Number((err_porcent - aux_val).toFixed(2))
      return project
    })
    if (err_porcent !== 0 && projects_apport.length > 0) {
      projects_apport[0].val = Number((err_porcent + projects_apport[0].val).toFixed(2))
    }
    obj_fn.setData((prevState) => ({ ...prevState, projects_apport: projects_apport }))
  }

  const handleChangeProjectsApport = (event, blur) => {
    var value = parseFloat(event.target.value)
    var err_porcent = 100

    var field = event.target.name
    var id_type = field.split('_')

    var aux_val
    for (var i = 0, tam = data.projects_apport.length; i < tam; i++) {
      if (id_type[0] === i) {
        aux_val = value
      } else {
        aux_val = data.projects_apport[i].val
      }

      err_porcent -= aux_val
      data.projects_apport[i].val = aux_val
    }
    obj_fn.setData((prevState) => ({ ...prevState, projects_apport: data.projects_apport }))

    err_porcent = parseFloat(err_porcent.toFixed(2))
    if (err_porcent !== 0) {
      obj_fn.setError(true)
    } else {
      obj_fn.setError(false)
    }
    setPorcent(err_porcent)
  }
  // const handleChangeProjectsApport2 = (event, blur) => {
  //   var value = Number(event.target.value)
  //   var err_porcent = 0

  //   var field = event.target.name
  //   var id_type = field.split('_')
  //   //console.log(id_type);

  //   var aux_val
  //   for (var i = 0, tam = data.projects_apport.length; i < tam; i++) {
  //     if (id_type[0] == i) {
  //       aux_val = value
  //     } else if (i > id_type[0]) {
  //       aux_val = Number(((100 - err_porcent) / (tam - i)).toFixed(2))
  //     } else {
  //       aux_val = data.projects_apport[i].val
  //     }

  //     err_porcent += aux_val
  //     data.projects_apport[i].val = aux_val
  //   }
  //   obj_fn.setData((prevState) => ({ ...prevState, projects_apport: data.projects_apport }))

  //   if (err_porcent % 2 != 0) {
  //     obj_fn.setError(true)
  //   } else {
  //     obj_fn.setError(false)
  //   }
  // }

  return (
    <>
      <Grid container spacing={3} sx={{ mb: 0, mt: 2 }}>
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>
              <InsertLink sx={{ mr: 1 }} />
              Lançar valor para a Propriedade ou ratear com Criações ou Safras
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid container spacing={1} sx={{ mb: 1, mt: 0 }}>
        <Grid item xs={12} sm={10}>
          <AutocompleteGeneric value={data.projects_apport} options={opt_projects} handleChange={handleChangeSelect} />
        </Grid>
        <Grid item xs={12} sm={6}>
          {!data?.projects_apport?.length ? (
            <></>
          ) : (
            <>
              <TableContainer>
                <Table sx={{}} aria-label="table_projects_aport" size={'medium'}>
                  <TableBody>
                    {data.projects_apport.map((project, index) => (
                      <TableRow key={project.id + project.tipo_repos}>
                        <TableCell align="left">{project.tipo_repos === 'Property' ? 'Propriedade' : project.tipo_repos }</TableCell>
                        <TableCell align="left">{project.name}</TableCell>
                        <TableCell align="right" width={'150px'}>
                          {/* <PercentTextField */}
                          <GenericValueField
                            handleChange={handleChangeProjectsApport}
                            options={{
                              required: true,
                              label: '',
                              name: index + '_' + project.id + '_' + project.tipo_repos,
                              id: index + '_' + project.id + '_' + project.tipo_repos,
                              color: 'success',
                              value: project.val,
                              max: 100,
                              variant: 'outlined',
                              endAdornment: true,
                              prefix: ' % ',
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ ml: 2, display: (porcent !== 0 ? 'flex' : 'none') }} >
                <Typography variant="subtitle1" color={"error"}>
                  {porcent < 0 ? "Porcentagem distribuida ultrapassa 100% em: " + (porcent*-1):
                  "Porcentagem a ser distribuida: " + porcent } %
                  <br/>
                  Ajuste os valores para continuar!
                </Typography>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

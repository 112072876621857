import React, { useState } from 'react';
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Category, AutoAwesomeMotion } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import PropTypes from "prop-types";
import IconsLivestockActivities from '../../../../components/Icons/IconsLivestockActivities';
import FormAddAnimalsSale from './FormAddAnimalsSale';
import FormAddLotesSales from './FormAddLotesSales';
import FormAddLotesBuy from './FormAddLotesBuy';
import FormAddProduct from './FormAddProduct';
import FormAddAnimalsBuy from './FormAddAnimalsBuy';
import ProductRegistrar from '../../../products/components/ProductRegistrar/ProductRegistrar';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `formTableAddItens-tab-${index}`,
//     "aria-controls": `formTableAddItens-tabpanel-${index}`,
//   };
// }

export default function FormSelectAddItens({ data, obj_fn }) {
  const [value, setValue] = useState(0);

  const handleChange = (event) => {
    setValue(event.target.value);
    obj_fn.setData(prevState => ({ ...prevState, product_selected: null }))
    obj_fn.setData(prevState => ({ ...prevState, lote_selected: null }))
    obj_fn.setData(prevState => ({ ...prevState, animal_selected: null }))
  };

  return ( <>
    <Grid container spacing={6} sx={{ ml: -2, mb: 0, mt: 2 }}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
          <FormControl display="flex" sx={{ m: 1, minWidth: 120 }} >
            <InputLabel id="selectBuySale-label" color="success">
              Item para: {data.operationType}
            </InputLabel>
            <Select
              labelId="selectBuySale-label"
              id="selectBuySale"
              color="success"
              value={value}
              label={'Item para:' + data.operationType}
              onChange={handleChange}
              MenuProps={{
                sx: {
                  "&& .Mui-selected": {
                    backgroundColor: green[50]
                  },
                  "&& .MuiMenuItem-root": {
                    "&:hover": {
                      backgroundColor: green[100]
                    }
                  },
                }
              }}
            >
              <MenuItem value={0}>
                <Category sx={{ mr: 0.5, mb: 0.2 }} />
                Produto
              </MenuItem>
              <MenuItem value={1}>
                <AutoAwesomeMotion sx={{ mr: 0.5, mb: 0.2 }} />
                Lote
              </MenuItem>
              <MenuItem value={2} >
                <IconsLivestockActivities activity={'Geral'} widthStyles={'28px'} fill={'#1f2d3d'}/>
                 Animal individual
              </MenuItem>
              {/* <MenuItem value={3}>Patrimônios</MenuItem> */}
              {/* <MenuItem value={4}>Serviços</MenuItem> */}
            </Select>
            <FormHelperText>
              Selecione o tipo de item desejado para: {data.operationType}
            </FormHelperText>
          </FormControl>
          {value === 0 && <ProductRegistrar /> }
        </Box>
        <TabPanel value={value} index={0}>
          <FormAddProduct data={data} obj_fn={obj_fn} />
        </TabPanel>
        <TabPanel value={value} index={1}>
        {data.operationType === 'Compra' ? <>
          <FormAddLotesBuy data={data} obj_fn={obj_fn} />
        </> : <>
          <FormAddLotesSales data={data} obj_fn={obj_fn} />
        </>}
        </TabPanel>
        <TabPanel value={value} index={2}>
        {data.operationType === 'Compra' ? <>
          <FormAddAnimalsBuy data={data} obj_fn={obj_fn} />
        </> : <>
          <FormAddAnimalsSale data={data} obj_fn={obj_fn} />
        </>}
        </TabPanel>
        {/* <TabPanel value={value} index={3}>
          <FormAddPatrimonio data={data} obj_fn={obj_fn} />
        </TabPanel> */}
        {/* <TabPanel value={value} index={4}>
          <FormAddServico data={data} obj_fn={obj_fn} />
        </TabPanel> */}
      </Box>
    </Grid>
  </>);
}
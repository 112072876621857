import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import SelectLivestock from './components/addLivestock/SelectLivestock';
import TutorialAddProject from './components/addLivestock/tutorial/TutorialAddProject';
import CardCreations from './components/outros/CardCreations';
import StartProjectLivestock from './components/addLivestock/tutorial/StartProjectLivestock';
import TutorialStepGeral from '../../components/Tutorial/TutorialStepGeral';

export default function LivestockContent() {
  const objText = {
    icon: <img src="/dist/img_fp/icon-button/todas_pecuarias.png" className="img-fluid" width="40" alt="Imagem responsiva"></img>, // Substitua pelo ícone desejado
    title: 'Criações', // Título do diálogo
    subtitle: 'Passo a Passo', // Subtítulo abaixo do título
    textAnimation: 'Com o projeto pecuário podemos planejar e gerenciar de forma eficiente as atividades pecuárias e os custos em uma propriedade rural.', // Texto que será animado pelo componente Typewriter
    description: 'Clique no botão abaixo e siga o passo a passo:', // Descrição adicional
    textButton: ['1- Conhecimentos de Gestão', '2- Cria projeto Pecuário', '3- Visualizar a lista de projetos pecuários'], // Texto complementar ou final
    linkButton: ['openGPRegistrarModal', 'openProjectLivestockRegistrarModal', '/livestock'], // O primeiro agora vai abrir o modal
    // linkButton: ['openPatrimonyRegistrarModal', '/inventory'], // Primeiro é um componente, segundo é um link
    filters_prods: [['group_category']],
  };

  return (
    <Box className="content-wrapper bg-white">
      <Box className="content-header" style={{ marginTop: 0 }}>
        <Box className="container-fluid">
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ height: 40, width: 40 }}>
                  <img src="/dist/img_fp/icon-button/todas_pecuarias.png" className="img-fluid" width="40" alt="Imagem responsiva"></img>
                </Box>
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Criações
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                    Gestão da criação
                  </Typography>
                </Stack>
              </Box>
            </Grid>

            <Grid item xs={12} md={3}>
              <SelectLivestock />
            </Grid>
            {/* <Grid item xs={6} md={1}>
              <StartProjectLivestock />
            </Grid> */}
            <Grid item xs={12} md={3}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ gap: 2 }}>
                <TutorialStepGeral objText={objText} />
                <TutorialAddProject />
              </Box>
            </Grid>
            {/* <Grid item xs={2} md={2}>
                  <TutorialAddProject />
                </Grid>
                <Grid item xs={1} md={1}>
                  <StartProjectLivestock />
                </Grid> */}
          </Grid>
        </Box>
      </Box>
      <div className="content">
        <div className="container-fluid">
          <div className="card">
            <CardCreations />
          </div>
        </div>
      </div>
    </Box>
  );
}

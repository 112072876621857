import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import ReplyIcon from "@mui/icons-material/Reply";
import { DrawingManager, GoogleMap, LoadScript, Marker, OverlayView, Polygon } from "@react-google-maps/api";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import LoadScriptOnlyIfNeeded from "../../../../../../LoadScript/LoadScriptOnlyIfNeeded";
import useAreasMapReposStore from "../../../../../../store/useAreasMapReposStore";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import SaveMapEditButton from "./components/SaveMapEditButton";
import { Avatar, Card, Collapse, Divider, Grid, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import { green, grey } from "@mui/material/colors";
import { CircleNotificationsOutlined, LocationOn } from "@mui/icons-material";
import CreateIcon from '@mui/icons-material/Create';
import useStore from "../../../../../../store/useStore";
import useLivestockReposStore from "../../../../../../store/useLivestockReposStore";
import usePropertyReposStore from "../../../../../../store/usePropertyReposStore";
import useActivityPastureManagementReposStore from "../../../../../../store/Activities/useActivityPastureManagementReposStore";
import { getPolygonCenter } from "../../../../../../components/Calc/getPolygonCenter";
import "./styles.css";
import MapIcon from '@mui/icons-material/Map';
import html2canvas from "html2canvas";

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// const center = {
//   lat: -20.9223027,
//   lng: -42.7958599,
// };


const libraries = ["drawing"];
const apiKey = "AIzaSyBNOeEroFWGH9RPyAUlol-YHLcyHAx0xTU";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const MapView = ({ data, setData }) => {
  console.log("MapView")
  console.log(data)
  const navigate = useNavigate();
  const usestore = useStore();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    setTimeout(() => {
      setIsLoading(false)
    }, 1000);

  }, [])


  const [map, setMap] = React.useState();

  // será usado para referenciar o mapa
  const mapRef = useRef(null);

  // carrego o id da criação
  //carego o id da propriedade
  const { idFarm, id } = useParams();

  // dados da propriedade
  // mapa: definir o centro da propriedade
  const [center, setCenter] = useState({ lat: -20.9223027, lng: -42.7958599 });
  const [zoom, setZoom] = useState(center.zoom || 15);

  //data:  
  //data.lote: lote selecionado para transferência - id do lote
  //opt_lotes: lista dos lotes
  console.log("lote")
  console.log(data.lote)
  console.log(data.lote.id)
  console.log(data.opt_lotes)

  //Preciso recuperra a área do lote selecionado
  //identificar id_areamap (onde está a criação)

  // lista o manejo de pastagem para identificar id_areamap (onde está a criação)
  const reposPastureManagement = useActivityPastureManagementReposStore(state => state)
  console.log(reposPastureManagement)
  const reposlistPastureManagement = reposPastureManagement.listPastureManagement
  let listPastureManagementActive = reposlistPastureManagement.filter((item) => item.date_exit === null);
  console.log("listPastureManagementActive");
  console.log(listPastureManagementActive);

  let pastureManagementActive = listPastureManagementActive.filter((item) => item.id_lote === data.lote.id);
  console.log(pastureManagementActive)
  const id_areamap = pastureManagementActive[0].id_areamap
  //console.log(id_areamap)
  // for (const pastureManagement of reposlistPastureManagement) {
  //   //console.log(pastureManagement)
  //   //console.log(pastureManagement.date_exit)
  //   if (!pastureManagement.date_exit) {
  //     //console.log("ok1")
  //     id_areamap = pastureManagement.id_areamap
  //   }
  // }
  // // tenho a área do lote de animais
  // console.log(id_areamap)

  // carrego a lista da criação current (livestock)
  // dentro da criação temos as áreas usadas
  const livestockRepos = usestore.reposLivestock.getLivestockStore('id', id)[0];
  // console.log(livestockRepos)
  //const id_areamap = livestockRepos.id_areamap
  console.log(id_areamap)
  // tipo de livestock para recuperar o icon do marker
  console.log(livestockRepos.tipo)
  // a criação tem aŕea 1 e 2

  const list_areas = [];
  // devo criar a lista de áreas ativas do lote
  for (const area of livestockRepos.areas) {
    // pego o id da area 
    console.log(area.id)
    const areaActive = usestore.reposAreas.getAreaMapStore('id', area.id);
    list_areas.push(areaActive[0])
  }
  // lista de áreas
  console.log(list_areas)

  // baricentro
  function getPolygonCenterbarycenter(polygonCoords) {
    let centroidX = 0,
      centroidY = 0;

    for (let i = 0; i < polygonCoords.length; i++) {
      console.log(polygonCoords[i].lat)
      centroidX += polygonCoords[i].lat;
      centroidY += polygonCoords[i].lng;
    }

    centroidX = centroidX / polygonCoords.length;
    centroidY = centroidY / polygonCoords.length;

    return { lat: centroidX, lng: centroidY };
  }

  useEffect(() => {
    data.areaTranferId = { id_area: "", name: "", image: "" }
  }, [])


  // definir a posição do marker ao selecionar a área desejada para transferencia
  const [markerPosition, setMarkerPosition] = useState();
  // seleciona o local do marker
  const handlePolygonClick = async (event, item) => {
    console.log("select area")
    console.log(item)
    console.log()
    const latLng = event.latLng.toJSON();
    setMarkerPosition(latLng);

    //console.log(event.target.name)
    //console.log(event.target.value)
    setData(prevState => ({ ...prevState, "areaTranferId": { id_area: item.id, name: item.name, image: "" } }));
    setData((prevState) => ({ ...prevState, "id_areamap": item.id }));
    // atualizar area

  };


  // devo criar um array para armazenar a lista de coordenada de cada área
  const [plgn_areas, setPolygons] = useState([]);

  var paths_coord = [];
  let markerBatchCoordinates = [];
  // lista de áreas com coordenadas decodigicadas para serem plotadas no mapa
  const listAreaActive = []
  const [markerBatch, setMarkerBatch] = useState({})
  const [iconMarkerBatch, setIconMarkerBatch] = useState({})
  const [numberAnimalsBatch, setNumberAnimalsBatch] = useState(data.lote.total_animals.toString())


  async function getId() {
    console.log("getId: get poligons areaUsed");
    let iconUrl, position;
    for (const [index, areaActive] of list_areas.entries()) {
      console.log("areaActive")
      // criar um array com as coordernadas de cada área da lista para plotar no mapa
      console.log(areaActive)
      let decoded_coordinates = JSON.parse(base64_decode(areaActive.coordinates));
      console.log(decoded_coordinates)
      //const name_coordinates = getPolygonBaseCenter(decoded_coordinates)
      //console.log(name_coordinates)
      listAreaActive.push({
        name: areaActive.name,
        //areaUse: areaActive.areaUse,
        //areatotal: areaActive.areatotal,
        coordenadas: decoded_coordinates,
      })
      console.log(listAreaActive)
      console.log(areaActive.id, id_areamap)

      // pegar as coordendas da área do lote plotará no grafico
      // informações de numeros de animias total em cada área
      if (areaActive.id === id_areamap) {
        //console.log("lote ativo")
        console.log(areaActive)
        //console.log(areaActive.total_animais)
        //setNumberAnimalsBatch((areaActive.total_animais).toString())
        markerBatchCoordinates.push(decoded_coordinates)
        console.log(markerBatchCoordinates[0])
        setMarkerBatch(getPolygonCenterbarycenter(markerBatchCoordinates[0]))
        setMarkerPosition(getPolygonCenterbarycenter(markerBatchCoordinates[0]))
        console.log(markerBatch)

        // tipo do icone
        let iconUrl = "/dist/image";
        console.log(livestockRepos.tipo)
        if (livestockRepos.tipo === "Bovinocultura de Corte") {
          iconUrl += "/entrada_boi";
        } else if (livestockRepos.tipo === "Bovinocultura de Leite") {
          iconUrl += "/entrada_vaca";
        } else if (livestockRepos.tipo === "Avicultura de Corte") {
          iconUrl += "/entrada_frango";
        } else if (livestockRepos.tipo === "Suinocultura") {
          iconUrl += "/entrada_suino";
        } else if (livestockRepos.tipo === "Piscicultura") {
          iconUrl += "/entrada_peixe";
        } else {
          iconUrl += "/entrada_outros";
        }
        let iconBatch = iconUrl + "_afazer.png";
        console.log(iconBatch)
        setIconMarkerBatch(iconBatch)
      }

      // if (areaActive.areas_used) {
      //   console.log(index)
      //   const creation = areaActive.areas_used[index];
      //   console.log(areaActive.areas_used)
      //   console.log(creation)
      //   // tipo do icone
      //   iconUrl = "/dist/image";
      //   // if (creation.tipo_livestock === "Bovinocultura de Corte") {
      //   //   iconUrl += "/entrada_boi";
      //   // } else if (creation.tipo_livestock === "Bovinocultura de Leite") {
      //   //   iconUrl += "/entrada_vaca";
      //   // } else if (creation.tipo_livestock === "Avicultura de Corte") {
      //   //   iconUrl += "/entrada_frango";
      //   // } else if (creation.tipo_livestock === "Suinocultura") {
      //   //   iconUrl += "/entrada_suino";
      //   // } else if (creation.tipo_livestock === "Piscicultura") {
      //   //   iconUrl += "/entrada_peixe";
      //   // } else {//if (/Outro/.test(creation.tipo_livestock)) {
      //   //   iconUrl += "/entrada_outros";
      //   // }
      // }

      paths_coord.push({
        'coordenadas': decoded_coordinates,
        //'marks': 
        'iconUrl': iconUrl + "_afazer.png",
        'name': areaActive.name,
        'number_animals': areaActive.total_animais || 0,
        'areatotal': areaActive.area,
        'id': areaActive.id
        // 'areaUse': areaUse,
      });


    }
    // setar a lista de coordenadas
    console.log(paths_coord)
    setPolygons(paths_coord);
  }


  useEffect(() => {
    // getId();
    setTimeout(() => {
      getId();
    }, 1000);

  }, [])



  const listcoordinates = []
  for (const coordinates of plgn_areas) {
    listcoordinates.push(coordinates.coordenadas)
  }


  const calcularCentroEntrePoligonos = (poligonos) => {
    let centroLat = 0;
    let centroLng = 0;
    let totalVertices = 0;

    for (const poligono of poligonos) {
      for (const coordenada of poligono) {
        centroLat += coordenada.lat;
        centroLng += coordenada.lng;
        totalVertices++;
      }
    }

    centroLat /= totalVertices;
    centroLng /= totalVertices;

    return { lat: centroLat, lng: centroLng };
  };

  // const moverCentroParaOutroLugar = (lat, lng) => {
  //   setCenter({ lat, lng });
  // };


  // cria o poligono
  const [drawingModeEnabled, setDrawingModeEnabled] = useState('polygon');



  const onLoadPoly = (polygon) => {
    console.log(listcoordinates)
    const novoCentro = calcularCentroEntrePoligonos(listcoordinates);
    console.log(novoCentro)
    setCenter(novoCentro);
  }


  const optionsDraw = {
    drawingControl: true,
    drawingControlOptions: {
      drawingMode: drawingModeEnabled
    },
    fillColor: "green",
    fillOpacity: 0.7,
    strokeColor: "#53f444",
    strokeWeight: 2,
    polygonOptions: {
      fillColor: `#2196F3`,
      strokeColor: `#2196F3`,
      fillOpacity: 0.5,
      strokeWeight: 2,
      clickable: true,
      editable: true,
      draggable: true,
      zIndex: 1,
    },
    circleOptions: {
      fillColor: `#2196F3`,
      strokeColor: `#2196F3`,
      fillOpacity: 0.5,
      strokeWeight: 2,
      clickable: true,
      editable: true,
      draggable: true,
      zIndex: 1,
    },
  };

  console.log("xxxxx")
  console.log(data.areaTranferId)

  const renderOverlayContent = () => (
    <Card style={{ position: 'fixed', bottom: '4%', left: '50%', transform: 'translate(-50%, -50%)', background: 'white', padding: '10px' }}>
      <Box sx={{ p: 1, display: 'flex' }}>

        <div className="d-none d-lg-block">


          <Stack spacing={0} sx={{ ml: 1, alignItems: 'center', width: 400 }}>
            <Grid container spacing={1} sx={{ width: 400 }}>
              <Grid item>
                <Avatar variant="rounded" sx={{ bgcolor: green[500] }}>
                  <LocationOn />
                </Avatar>
              </Grid>
              <Grid item sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography fontWeight={700}>Tranferência do lote</Typography>
                <Typography variant="body2" color="text.secondary">
                  Origem: <strong>{data.lote.area.name}</strong> <ArrowRightIcon sx={{ mt: -0.2, color: green[500] }} /> Destino: <strong>{data.areaTranferId.name == "" ? "Clique na área desejada" : data.areaTranferId.name}</strong>
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </div>
        <div className="d-lg-none">
          <Typography variant="body2" fontWeight={700}>Clique na área</Typography>
        </div>

      </Box>

    </Card >
  );

  //console.log("plgn_areas")
  //console.log(plgn_areas)
  //console.log(markerBatch)
  //console.log(iconMarkerBatch)
  //console.log(numberAnimalsBatch)

  // OverlayView do name da área
  const [expanded, setExpanded] = useState(false);

  // // função para clicar no icon e retornar no centro do mapa
  // function centerMaps() {
  //   map?.panTo(center || origin);
  //   onZoomChanged(zoom || 15);
  //   handleIconClick()
  // }

  const handleIconClick = () => {
    setExpanded(!expanded);
  };



  //<LoadScript id="scrit-loader" googleMapsApiKey={'AIzaSyBNOeEroFWGH9RPyAUlol-YHLcyHAx0xTU' || ""} libraries={["places"]}>
  return (
    <>
      {isLoading && center.lat !== "" ? <Skeleton variant="rectangular" width={"75vw"} height={"80vh"} /> :
        <Box style={{ width: "75vw", height: "80vh" }} >
          <LoadScriptOnlyIfNeeded id="scrit-loader" googleMapsApiKey={apiKey} libraries={libraries}>
            {/* <button type="text" placeholder="Last name" onClick={() => (refreshPage())} >Reload</button> */}
            <Box sx={{ "& > :not(style)": { m: 1 } }}>
              {/* <FloatingButtoDraw /> */}
              {/* <FloatingButtoDrawGeneral path={path} setPath={setPath} drawingModeEnabled={drawingModeEnabled} handleDrawingMode={handleDrawingMode} /> */}
              {/* <LightTooltip title="Retornar" placement="left">
          <Fab sx={{ position: "fixed", top: 1, right: 10, backgroundColor: "#fff" }} color="neutral" size="medium" onClick={() => navigate("/areasmaps")} aria-label="edit">
            <ReplyIcon />
          </Fab>
        </LightTooltip> */}

            </Box>
            {/* <SaveMapEditButton path={path} area={area} setArea={setArea} areasMap_data={areasMap_data} /> */}

            {!center ? <>
              <Box >
                <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
              </Box>
            </> :
              <GoogleMap
                id="map"
                mapContainerStyle={{
                  height: "80vh",
                  width: "75vw",
                }}
                ref={mapRef}
                center={center}
                zoom={Number(zoom)}
                options={{
                  mapTypeId: "satellite", // Define o tipo de mapa padrão como "Satélite"
                  //mapTypeControl: true,
                  streetViewControl: false,
                  fullscreenControl: false,
                  zoomControl: true,
                  zoomControlOptions: {
                    //style: window.google.maps.ZoomControlStyle.SMALL,
                    gestureHandling: "greedy", // Torna o zoom mais sensível
                  },
                }}
              >
                {true && (
                  <>

                    {/* <Marker
                      key={mark_lvst.tipo_item + "_" + mark_lvst.id_item + "_" + index}
                      position={mark_lvst.position}
                      onClick={() => linkLivestockHarvest(mark_lvst)}
                      icon={returnIcon(mark_lvst)}
                      label={mark_lvst.safra ? " " : returnLabel(mark_lvst)}
                      zIndex={mark_lvst.zIndex}
                      title={mark_lvst.title}
                    /> */}
                    {renderOverlayContent()}
                    {/* {markerBatch && numberAnimalsBatch && <Marker
                position={markerBatch}
                icon={iconMarkerBatch}
                label={{
                  text: numberAnimalsBatch,
                  fontSize: "12px",
                  className: "custom-marker-label"
                }}
              />} */}

                    {markerPosition && (
                      <Marker
                        position={markerPosition}
                        icon={iconMarkerBatch}
                        label={{
                          text: numberAnimalsBatch,
                          fontSize: "12px",
                          className: "custom-marker-label"
                        }}
                      />

                    )}
                    {plgn_areas.map((item) =>
                      <>
                        <React.Fragment key={item.id}>
                          <Polygon
                            //a propriedade paths do objeto Polygon especifica uma matriz
                            options={optionsDraw}
                            path={item.coordenadas}
                            onLoad={onLoadPoly}
                            onClick={(e) => handlePolygonClick(e, item)}
                          />
                        </React.Fragment>

                        <OverlayView
                          mapPaneName={"overlayMouseTarget"}
                          position={getPolygonCenterbarycenter(item.coordenadas)}
                        >
                          <IconButton>
                            <Card sx={{ backgroundColor: 'black', color: 'white', borderRadius: '8px' }}>
                              <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                                <Avatar variant="rounded" src="/dist/image/icon_verde_location.png" sx={{ width: 21, height: 28, position: 'absolute', left: '0px' }} />
                                <Stack spacing={0.5}>
                                  <Typography sx={{ fontSize: 10, color: 'white', ml: '8px' }} fontWeight={500}>{item.name}</Typography>
                                  {/* <Collapse in={expanded}>
                              <Divider />
                              <Typography sx={{ fontSize: 10, color: 'white' }} color="text.secondary">
                                <MapIcon sx={{ color: grey[800], fontSize: 12, color: 'white' }} /> {item.areaUse}/{item.areatotal} ha
                              </Typography>
                            </Collapse> */}
                                </Stack>
                              </Box>
                            </Card>
                          </IconButton>

                        </OverlayView >

                      </>
                    )}

                  </>
                )}
              </GoogleMap>
            }
          </LoadScriptOnlyIfNeeded >
        </Box>
      }
    </>
  );
};

export default MapView;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkmark } from 'react-checkmark';
import { makeStyles } from '@mui/styles';
import { AppBar, Box, Button, ButtonBase, CardMedia, Container, Grid, Paper, Stack, Toolbar, Typography } from '@mui/material';
import { Span } from '../../../../../components/Typography';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import useStore from '../../../../../store/useStore';
import { Context } from '../../../../../components/Context/AuthContext';
import { expandAndFade } from '../../../../../components/Animations/animationKeyFrames';
import { PendingActions } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

export default function SuccessGreen({ handleClose }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [isLoadingText, setIsLoadingText] = useState(false);

  const usestore = useStore();
  // pegar o Harvest current
  const reposHarvestList = usestore.reposHarvest.listHarvest;
  // console.log(reposHarvestList);

  reposHarvestList.sort((a, b) => b.id - a.id);
  const idFarm = reposHarvestList[0].id_property;

  const { setMenuContext } = React.useContext(Context);

  const handleButtonClick = () => {
    handleClose();

    var harvest = {
      label: reposHarvestList[0].name,
      id: reposHarvestList[0].id,
      id_property: reposHarvestList[0].id_property,
      id_cultivation: reposHarvestList[0].id_cultivation,
      cultivation_icon: '/dist/image/icon_cultures/' + reposHarvestList[0].cultivation_icon,
      tipo_repos: 'Safras',
    };
    localStorage.setItem('creationHarvestRepos', JSON.stringify(harvest));
    usestore.reposHarvest.changeHarvestRepos(harvest);

    setMenuContext(new Date().getTime());
    // jogar para o map id
    navigate(`/activitiesHarvest/${idFarm}/${harvest.id}`);
  };

  setTimeout(() => {
    setIsLoadingText(true);
  }, 1000);

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 0, md: 2 } }}>
          <div className="sucess">
            <Checkmark size={'128'} />
            <Typography variant="h6" sx={{ mt: 4 }}>
              Pronto! Projeto Agrícola criado com sucesso.
            </Typography>
            <Typography style={{ color: '#2e7d32' }} variant="h6">
              Bem Vindo a Gestão Agrícola!
            </Typography>

            {/* <Grid item sm={12} xs={12}>
              <CardMedia component="img" width="200" image="/dist/image/gestaoagricola2.png" alt="Gestão Agrícola" />
            </Grid> */}

            <>
              <div className="d-lg-none">
                <Box sx={{ flexGrow: 1, height: 80 }}>
                  <Typography style={{ color: '#2e7d32' }} sx={{ mt: 2 }}>
                    {isLoadingText && (
                      <Typewriter
                        tam={110}
                        //text="Com o projeto pecuário criado, é hora de iniciar com um planejamento da meta de produção ou seguir direto para gerenciamento das atividades de campo."
                        text="Agora que você iniciou esta jornada, é hora inciar os registros das atividade de campo."
                      />
                    )}
                  </Typography>
                </Box>
              </div>
              <div className="d-none d-lg-block">
                <Box sx={{ flexGrow: 1, mb: 2, height: 60 }}>
                  <Typography style={{ color: '#2e7d32' }} sx={{ mt: 2 }}>
                    {isLoadingText && <Typewriter tam={90} text="Agora que você iniciou esta jornada, é hora inciar os registros das atividade de campo." />}
                  </Typography>
                </Box>
              </div>
            </>
            {/* <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                
                <ButtonBase component={Paper} elevation={2} sx={{ p: 2, borderRadius: 1 }} onClick={() => navigate(`/planningHarvest/${idFarm}/${id}`)} className={classes.button}>
                  <Stack direction="column" alignItems="center" sx={{ mt: -2 }}>
                    <SignpostIcon style={{ fontSize: 50, mb: 1 }} />
                    <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                      Planejamento
                    </Typography>
                  </Stack>
                </ButtonBase>
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <ButtonBase component={Paper} elevation={2} sx={{ p: 2, borderRadius: 1 }} onClick={() => navigate(`/activitiesHarvest/${idFarm}/${id}`)} className={classes.button}>
                  <Stack direction="column" alignItems="center" sx={{ mt: 1 }}>
                    <img style={{ maxHeight: 50 }} src="/dist/image/entrada_preto.png" alt="image" class="img-fluid" />
                    <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                      Gerenciamento das Atividades
                    </Typography>
                  </Stack>
                </ButtonBase>
               
              </Grid>
            </Grid> */}
            {/* <Typography variant="subtitle1" sx={{ mt: 1, mb: 2 }}>
              Clique no botão abaixo para acessar o mapa e comece um novo olhar e um novo ciclo de oportunidades.
            </Typography> */}
            <Typography variant="subtitle1" sx={{ mt: 1, mb: 2 }}>
              Clique no botão abaixo.
            </Typography>
            <ButtonBase
              component={Paper}
              elevation={2}
              sx={{ p: 2, borderRadius: 1, animation: `${expandAndFade} 2s linear infinite` }}
              onClick={() => handleButtonClick('Mapa')}
              className={classes.button}
            >
              <Stack direction="column" alignItems="center">
                <PendingActions />
                {/* <img style={{ maxHeight: 100 }} src="/dist/image/entrada.png" class="img-fluid" /> */}
                <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                  Atividades de Campo
                </Typography>
              </Stack>
            </ButtonBase>
          </div>
          {/* <TutorialNextStep /> */}
        </Paper>
      </Container>
      <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
              <Span>Fechar</Span>
            </Button>
          </Box>
          {/* <Box sx={{ flexGrow: 1, textAlign: "center" }}>

            <Button color="success" variant="contained" type="submit" >
              <Span sx={{ textTransform: "capitalize" }}>Salvar</Span>
            </Button>

          </Box> */}
        </Toolbar>
      </AppBar>
      {/* <Container component="main" maxWidth="sm" >
      <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <div className="sucess">
          <Checkmark size={'196'} />
          <Typography variant="h6" sx={{ mt: 4 }} >
            Pronto! Registro da safra realizada.
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 4 }} >
            Agora com a safra devidamente criada, podemos visualizar no mapa da propriedade a área que ficará a cultura.
          </Typography>
          <ButtonBase
            component={Paper}
            elevation={2}
            sx={{ p: 2, borderRadius: 1 }}
            onClick={() => handleButtonClick()}
            className={classes.button}
          >
            <Stack direction="column" alignItems="center">
              <img style={{ maxHeight: 100 }} src="/dist/image/entrada.png" alt="image" class="img-fluid" />
              <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                Mapa
              </Typography>
            </Stack>
          </ButtonBase>
          <Typography sx={{ mt: 2 }} style={{ color: '#2e7d32' }}>
            <Typewriter tam={50} text=" Acesse o mapa e realize a gestão de sua safra" />
          </Typography>
        </div>

      </Paper>
    
    </Container>
    <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1, textAlign: "center" }}>
          <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
            <Span>Fechar</Span>
          </Button>
        </Box>
      </Toolbar>
    </AppBar> */}
    </>
  );
}

import React, { useState } from 'react';
import dayjs from "dayjs";
import { makeStyles } from '@mui/styles';
import { Input, PointOfSale, Notes, Payment } from '@mui/icons-material';
import { Box, Card, CardContent, Divider, Grid, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../../../components/Loader/ThreeDotsSpinner';
import FooterModal from '../../../../components/Footer/FooterModal';
import FormTableItens from '../Forms/FormTableItens';
import { formatValue } from '../../../../utils/functions';

const useStyles = makeStyles((theme) => ({
  boxShadow: {
    width: "100%",
    boxSizing: 'border-box',
    border: '1px solid transparent',
    boxShadow: "0 2px 6px #00000033",
    borderRadius: '20px',
    padding: '8px',
    outline: 'none',
    textOverflow: 'ellipses',
  },
}));

//Confirmação dos dados do lançamento
export default function FormMainConfirm({ data, obj_fn, visualize }) {
  const classes = useStyles();

  const [showText, setShowText] = useState(false);
  setTimeout(() => {
    setShowText(true);
  }, 500);
  // ativar o botao retornar
  const returnError = () => {
    return false;
  };
  const isError = () => {
    return false;
  };

  // calcula o valor de porcentagem sobre um valor 
  function calcValue(percent, value) {
    var aux_value = value;
    if (typeof value === 'string') {
      aux_value = parseFloat(value);
    }
    var aux_val = aux_value * (parseFloat(percent||'0')/100);

    return formatValue(aux_val, true);
  }

  // retorna o nome do banco de acordo com o ID 
  function getNameBank(id_bank) {
    const bankaccount = obj_fn.usestore.reposBank.getBankStore('id', id_bank)[0];
    return bankaccount?.name;
  }
  function getNameAccount(id_acc) {
    if (!id_acc) {
      return '';
    }
    const account = obj_fn.usestore.reposAccount.getAccountStore('id', id_acc)[0];
    return account?.name;
  }

  return ( <>
    {visualize ? <></> : <>
    <Grid container spacing={3} sx={{ mt: 1 }}>
      <Box sx={{ ml: 1, display: "flex" }}>
        <Stack spacing={0.5}>
          <Typography fontWeight={700}>Confirmação do lançamento: {data.operationType}</Typography>
        </Stack>
      </Box>
    </Grid>
    <Grid container spacing={1} sx={{ mt: 2 }} >
      <div className="d-lg-none">
        <Grid container>
          <Grid item xs={12} sm={12} alignItems="center" >
            {showText ? (
              <Box display="flex" alignItems="center">
                <Typewriter text={"Pronto! Agora basta conferir os dados do lançamento e clicar em confirmar!"} variant={"subtitle1"} color={"#2e7d32"} />
              </Box>
            ) : (
              <Box display="flex" alignItems="center" >
                <ThreeDotsSpinner />
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
      <div className="d-none d-lg-block">
        <Grid container>
          <Grid item xs={12} sm={12} alignItems="center" >
            {showText ? (
              <Box display="flex" alignItems="center">
                <Typewriter text={"Pronto! Agora basta conferir os dados do lançamento e clicar em confirmar!"} variant={"subtitle1"} color={"#2e7d32"} />
              </Box>
            ) : (
              <Box display="flex" alignItems="center" >
                <ThreeDotsSpinner />
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
    </Grid>
    <Divider sx={{ mt: 1, mb: 2, ml: -3, borderColor: '#388e3c' }}/></>}

    {/* Informações do lançamento */}
    <Grid container sx={{ mb: 4 }}>
      <Grid container spacing={2} sx={{mt: 1}} >
        <Grid item xs={12} sm={12} >
          <Box sx={{ ml: 1, display: "flex", flexDirection: "column" }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}><Input sx={{mr: 1}}/> Informações Gerais</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={4} sm={4}>
          <ListItem>
            <ListItemText primary="Data do Lançamento:" secondary={data.date_release_fc} />
          </ListItem>
        </Grid>
        <Grid item xs={4} sm={4}>
          <ListItem>
            <ListItemText primary={data.operationType === "Conta a receber" || data.operationType === 'Venda' ? "Cliente:" : "Fornecedor:"} secondary={data.person?.name} />
          </ListItem>
        </Grid>
        {data.descriptionEntry &&
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Descrição:" secondary={data.descriptionEntry} />
            </ListItem>
          </Grid>
        }

        {/* {data.products && data.products.length > 0 ? <></> : <> */}
        {data.operationType === "Venda" || data.operationType === "Compra" ? <></> : <> 
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Valor Total:" secondary={formatValue(data.value, true)} />
            </ListItem>
          </Grid>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Categoria da conta:" secondary={getNameAccount(data.category)} />
            </ListItem>
          </Grid>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Subcategoria especifica:" secondary={getNameAccount(data.subcategory)} />
            </ListItem>
          </Grid>
        </>}
      </Grid>

      {!data.products?.length && !data.lotes?.length && !data.animals?.length ? <></> : <>
        <Grid container spacing={2} sx={{mt: 1}} >
          {/* <FormProductTable data={data} obj_fn={obj_fn} visualize={true}/> */}
          <FormTableItens data={data} obj_fn={obj_fn} visualize={true}/>
        </Grid>
      </>}

      {/* Condições de pagamento */}
      <Grid container spacing={2} sx={{mt: 1}} >
        <Grid item xs={12} sm={12} >
          <Box sx={{ ml: 1, display: "flex", flexDirection: "column" }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}><Payment sx={{mr: 1}}/> Condições de pagamento</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={6} sm={3}>
          <ListItem>
            <ListItemText primary="Parcelamento:" secondary={/.*vista/.test(data.installment) ? 'À vista' : data.installment + 'X'} />
          </ListItem>
        </Grid>
        <Grid item xs={6} sm={3}>
          <ListItem>
            <ListItemText primary="Vencimento:" secondary={data.dueDate_fc} />
          </ListItem>
        </Grid>
        <Grid item xs={6} sm={3}>
          <ListItem>
            <ListItemText primary="Forma de Pagamento:" secondary={data.formPayment} />
          </ListItem>
        </Grid>
        <Grid item xs={6} sm={3}>
          <ListItem>
            <ListItemText primary="Conta de Pagamento:" secondary={getNameBank(data.bankAccount)} />
          </ListItem>
        </Grid>
      </Grid>

      {/* Previsão de pagamento */}
      <Grid container spacing={2} sx={{mt: 1}} >
        <Grid item xs={12} sm={12} >
          <Box sx={{ ml: 1, display: "flex", flexDirection: "column" }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}><Payment sx={{mr: 1}}/> Previsão de pagamento</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={4} sm={4}>
          <ListItem>
            <ListItemText primary="Juros:" secondary={
              data.fee + "%." + (obj_fn.ismobile ? <br/> : '') + " (" + calcValue(data.fee, data.value) + ")"} />
          </ListItem>
        </Grid>
        <Grid item xs={4} sm={4}>
          <ListItem>
            <ListItemText primary="Multa:" secondary={
              data.penalty + "%." + (obj_fn.ismobile ? <br/> : '') + " (" + calcValue(data.penalty, data.value) + ")"} />
          </ListItem>
        </Grid>
        <Grid item xs={4} sm={4}>
          <ListItem>
            <ListItemText primary="Desconto:" secondary={
              data.discount + "%." + (obj_fn.ismobile ? <br/> : '') + " (" + calcValue(data.discount, data.value) + ")"} />
          </ListItem>
        </Grid>
      </Grid>

      {/* Parcelas do pagamento */}
      {!/.*vista/.test(data.installment) && <>
        <Divider sx={{ mt: 2, mb: 2, ml: -3, borderColor: '#388e3c' }}/>
        <Grid container spacing={2} sx={{mt: 2, mb: 3}} >
          <Grid item xs={12} sm={12} >
            <Box sx={{ ml: 1, display: "flex", flexDirection: "column" }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}><Payment sx={{mr: 1}}/> Parcelas do pagamento</Typography>
              </Stack>
            </Box>
          </Grid>
          {data.arr_installment.map((portion, index) => (<>
            <Grid container item key={"portion_" + index} xs={12} sm={12} className={classes.boxShadow}>
              <Grid key={"GI1_" + index} item xs={1} sm={1}>
                <ListItem>
                  <ListItemText primary="Parcela:" secondary={(index+1) + "/" + data.installment} />
                </ListItem>
              </Grid>
              <Grid key={"GI2_" + index} item xs={1.5} sm={1.5}>
                <ListItem>
                  <ListItemText primary="Vencimento:" secondary={dayjs(portion.dueDate).format("DD/MM/YYYY")} />
                </ListItem>
              </Grid>
              <Grid key={"GI3_" + index} item xs={1.5} sm={1.5}>
                <ListItem>
                  <ListItemText primary="Valor Parcela:" secondary={formatValue((portion.valueTotal || portion.value), true)} />
                </ListItem>
              </Grid>
              <Grid key={"GI4_" + index} item xs={2} sm={2}>
                <ListItem>
                  <ListItemText primary="Forma de Pagamento:" secondary={portion.formPayment} />
                </ListItem>
              </Grid>
              <Grid key={"GI5_" + index} item xs={2.5} sm={2.5}>
                <ListItem>
                  <ListItemText primary="Conta de Pagamento:" secondary={getNameBank(portion.bankAccount || portion.id_bank)} />
                </ListItem>
              </Grid>
              <Grid key={"GI6_" + index} item xs={2.5} sm={2.5}>
                <ListItem>
                  <ListItemText primary="Descricao:" secondary={portion.description} />
                </ListItem>
              </Grid>
              <Grid key={"GI7_" + index} item xs={1} sm={1}>
                <ListItem>
                  <ListItemText primary="Pago:" secondary={portion.payed ? "Sim" : "Não"} />
                </ListItem>
              </Grid>
            </Grid>
          </>))}
        </Grid>
      </>}

      {/* Rateio e Descrição */}
      {!data.projects_apport.length > 0 && !data.description ? <></> : <>
        <Grid container spacing={2} sx={{ mt: 1, mb: 1 }} >
          <Grid item xs={12} sm={12} >
            <Box sx={{ ml: 1, display: "flex", flexDirection: "column" }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}><Notes sx={{mr: 1}}/> Rateios e Descrição</Typography>
              </Stack>
            </Box>
          </Grid>
          {data.projects_apport.length > 0 ? <>
            <Grid item >
              <ListItemText primary="Lançar valor para a Propriedade ou ratear com Criações ou Safras" />
              <Grid container spacing={2} sx={{ mt: "-20px" }} >
                {data.projects_apport?.map((project) => (
                  <Grid key={project.id + "_" + project.tipo_repos} item container alignItems="left" justifyContent="left" xs={6} sm={6}>
                    <Card sx={{ display: "flex", minWidth: '450px'}}>
                      <ListItemIcon>
                        <CardContent sx={{ flex: 1 }}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', pl: 1, pb: 1 }}>
                            <Typography variant="subtitle1" gutterBottom>
                              {project.name} ({project.tipo_repos === 'Property' ? 'Propriedade' : project.tipo_repos})
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                              Rateio de: {project.val}%. ({calcValue(project.val, data.value_end)})
                            </Typography>
                          </Box>
                        </CardContent>
                      </ListItemIcon>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </> : <></>}
          {data.description ? <>
            <Grid item xs={12} sm={12}>
              <ListItem>
                <ListItemText primary="Descrição Geral do Lançamento:" secondary={data.description} />
              </ListItem>
            </Grid>
          </> : <></>}
        </Grid>
      </>}
      <Grid item xs={12} sm={12} sx={{ mt: 2, mb: 2 }}>
        <Box sx={{ ml: 1, display: "flex", flexDirection: "column" }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={200} display="inline" >
              <PointOfSale sx={{ mr: 1 }}/> Total da {data.operationType}:
              <Typography fontWeight={700} display="inline" sx={{ ml: 2 }}>
                {formatValue(data.value_end, true)}
              </Typography>
            </Typography>
          </Stack>
        </Box>
      </Grid>
    </Grid>
    {visualize ? <></> :
    <FooterModal
      data={data}
      obj_fn={obj_fn}
      isError={isError}
      returnError={returnError}
    />}
  </> );
}
import React, { useEffect } from 'react';
import { useState } from 'react';
import Loading from '../../components/Loader/Loading';
import { LivestockButtonVertical } from './components/LivestockButtonVertical';
import LivestockPropertyButton from './components/LivestockPropertyButton';
import LivestockPropertyLocationButton from './components/LivestockPropertyLocationButton';
import { Map } from './components/Map';
import { WeatherForecastButtonVertical } from './components/WeatherForecastButtonVertical';
import { WeatherForecastButtonVerticalModal } from './components/WeatherForecastButtonVerticalModal';
import { useNavigate } from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api';
import ButtonDialogMap from './components/Messenger/ButtonDialogMap';
import MessengerMapBottom from './components/Messenger/MessengerMapBottom';
import { HarvestButtonVertical } from './components/HarvestButtonVertical';
import { AreasButtonVertical } from './components/AreasButtonVertical';
import SpaIcon from '@mui/icons-material/Spa';
import { Grid, Skeleton } from '@mui/material';
import FloatingButtonGeneral from '../../components/Button/FloatingButtonGeneral';
import LinearIndeterminate from '../../components/Loader/LoagingLinear';
import FirstAcessMap from './tutorial/FirstAcessMap';
import CreateProject from './tutorial/CreateProject';
import CreateArea from './tutorial/CreateArea';
import Fab from '@mui/material/Fab';
//import useActivityInputReposStore from "../../store/Activities/useActivityInputReposStore";
//import usePropertyReposStore from "../../store/usePropertyReposStore";
//import useLivestockReposStore from "../../store/useLivestockReposStore";
//import useAreasMapReposStore from "../../store/useAreasMapReposStore";
//import useHarvestReposStore from "../../store/useHarvestReposStore";
import useStore from '../../store/useStore';
import CreateProjectInit from './tutorial/CreateProjectInit';

function MapsGoogleMap({ setIsLoadingMap }) {
  const navigate = useNavigate();
  console.log('MapsGoogleMap');
  const property_id = localStorage.getItem('Property') || 1;
  const usestore = useStore();
  const userCurrent = usestore.reposUser.userRepos;

  const refreshPage = () => {
    navigate(0);
  };

  // carregament inicial do mapa
  const [lat, setLat] = useState(-20.9230016);
  const [lng, setLng] = useState(-42.7960554);
  const [zoom, setZoom] = useState(15);

  //inicializar o mapa
  const [isLoading, setLoading] = useState(false);
  const [isLoadingTimer, setLoadingTimer] = useState(false);
  const [centerInit, setcenterInit] = useState({});
  const [property_adress, setPropertyAdress] = useState({});

  setTimeout(() => {
    setLoadingTimer(true);
  }, 2000);

  //const reposHarvest = useHarvestReposStore((state) => state);
  const reposHarvest = usestore.reposHarvest;
  //  verificar se exite a latitude da propriedade
  //const reposProperty = usePropertyReposStore(state => state);
  const reposProperty = usestore.reposProperty;
  const property_data = reposProperty.getPropertyStore('id', reposProperty.propertyRepos.id)[0];
  //console.log(property_data)
  //console.log(property_data.label)
  useEffect(() => {
    setcenterInit({
      lat: Number(property_data?.latitude || '-21.7'),
      lng: Number(property_data?.longitude || '-61.5'),
      address: property_data?.address || '',
      zoom: Number(property_data?.zoom || 3),
    });
    setPropertyAdress({
      city: property_data.city,
      state: property_data.state,
      address: property_data.address || '',
      tel: property_data.tel || '',
    });
    setLoading(true);
  }, []);

  // verificar se exite a latitude da propriedade e se existe o registro da areas
  //const reposAreas = useAreasMapReposStore(state => state);
  let reposAreaActive = [];
  //console.log(reposAreas)
  if (property_data.latitude) {
    reposAreaActive = usestore.reposAreas.listAreasMap;
    //console.log(reposAreaActive)
  }

  // verificar se exite a latitude da propriedade e se existe o registro da criação
  /*const livestockRepos = useLivestockReposStore(state => state);
  let livestockActive = []
  //console.log(livestockRepos)
  if (property_data.latitude && reposAreaActive.length != 0) {
    livestockActive = livestockRepos.listLivestock
    //console.log(livestockActive)
  }*/
  //const creationHarvestRepos = JSON.parse(localStorage.getItem("creationHarvestRepos"));

  const [currentComponent, setCurrentComponent] = useState(null);
  const [filters, setFilters] = useState({
    empty: userCurrent.settings.mapa.empty,
    livestock: userCurrent.settings.mapa.livestock,
    harvest: userCurrent.settings.mapa.harvest,
    markers: userCurrent.settings.mapa.markers,
    planning: userCurrent.settings.mapa.planning,
    names_areas: userCurrent.settings.mapa.names_areas,
    posts: userCurrent.settings.mapa.posts,
  });

  const handleFilters = (event, tipo) => {
    setFilters((prevState) => ({ ...prevState, [tipo]: event.target.checked }));

    userCurrent.settings['mapa'][tipo] = event.target.checked;

    usestore.reposUser.updateSettings(userCurrent);
  };

  useEffect(() => {
    const timeout2 = setTimeout(() => {
      if (reposAreaActive.length === 0) {
        // setCurrentComponent(<CreateArea propertyName={property_data.label} />);
        setCurrentComponent(<CreateProjectInit propertyName={property_data.label} />);
      }
    }, 4000); // Tempo de espera em milissegundos (4 segundos neste exemplo)
    return () => {
      clearTimeout(timeout2);
    }; // Limpa os timeouts quando o componente é desmontado
  }, []);

  //!property_data[0].latitude ? <FirstAcessMap /> : reposAreaActive.length == 0 ? <CreateArea /> : livestockActive.length == 0 ? <CreateProject /> : null

  var margin_top = 85;
  if (window.innerWidth < 400) {
    margin_top = 135;
  }

  //isLoading
  console.log(centerInit);
  console.log(centerInit);
  return (
    <>
      {isLoadingTimer && (
        <div style={{ position: 'fixed', top: margin_top, left: 10, width: 60, zIndex: 1111 }}>
          <AreasButtonVertical filters={filters} handleFilters={handleFilters} usestore={usestore} />
          <LivestockButtonVertical />
          <HarvestButtonVertical />
          <div className="d-none d-lg-block">
            <WeatherForecastButtonVertical idFarm={property_id} />
          </div>
          <div className="d-lg-none">
            <WeatherForecastButtonVerticalModal idFarm={property_id} />
          </div>
          <FloatingButtonGeneral />
        </div>
      )}
      {/* <ButtonDialogMap /> */}
      {property_data.latitude == '-21.7' ? (
        <FirstAcessMap propertyName={property_data.label} />
      ) : reposAreaActive.length === 0 ? (
        currentComponent
      ) : reposProperty?.listLivestock == [] || reposHarvest?.listHarvest == [] ? (
        <CreateProject />
      ) : null}
      {/* {!property_data.latitude && <FirstAcessMap />}
      {reposAreaActive.length == 0 && <CreateArea />}
      {livestockActive.length == 0 && <CreateProject />} */}
      {isLoading ? (
        //<LoadScript id="scrit-loader" googleMapsApiKey={"AIzaSyBNOeEroFWGH9RPyAUlol-YHLcyHAx0xTU" || ""} libraries={["places"]}>
        <Map
          // setLat={setLat}
          // setLng={setLng}
          // setZoom={setZoom}
          // zoom={zoom}
          // lat={lat}
          // lng={lng}
          centerInit={centerInit}
          setIsLoadingMap={setIsLoadingMap}
          // onPolygonComplete={onPolygonComplete}
          // paths={propertyPaths}
          // setPaths={setPropertyPaths}

          // drawingModeEnabled={drawingModeEnabled}
          filters={filters}
          property_adress={property_adress}
        />
      ) : (
        //</LoadScript>
        <div className=" ">
          <div className=""></div>
          <div className="">
            <div className="" style={{ height: '100%', position: 'relative', width: '100%', left: 0, right: 0, bottom: 0, top: 0 }}>
              {/* <LinearIndeterminate /> */}
            </div>
            <div className="" style={{ height: '100%', position: 'fixed', width: '100%', left: 0, right: 0, bottom: 0, top: 75, overflow: 'hidden' }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" width={'100%'} height={800} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" width={'100%'} height={80} />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MapsGoogleMap;

import React, { useContext, useState } from "react";
import SignRoutes from "./SignRoutes";
import OtherRoutes from "./OtherRoutes";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Context } from "../components/Context/AuthContext";
import { useNavigate } from "react-router-dom";
import Nav from "../components/Nav/Nav";
import NavBotton from "../components/Nav/NavBotton";
import NavSide from "../components/Nav/NavSide";

function Router() {
  const navigate = useNavigate();
  const { authenticated, isLoading, handleLogout } = useContext(Context);

  const location = useLocation();
  const showNav = (
    location.pathname !== "/mapsareasdraw" &&
    !/\/areamap\/\d+/.test(location.pathname)
  );
  const showNavareamap = location.pathname !== "/areamap/";
  const showNavSide = (
    /\/person.*/.test(location.pathname) ||
    /\/patrimony.*/.test(location.pathname) ||
    /\/livestock.*/.test(location.pathname) ||
    /\/harvest.*/.test(location.pathname) ||
    /\/areasmaps.*/.test(location.pathname) ||
    /\/activities.*\//.test(location.pathname) ||
    /\/resources\//.test(location.pathname) ||
    /\/productioncost\//.test(location.pathname) ||
    /\/inventory.*/.test(location.pathname) ||
    /\/financial.*/.test(location.pathname) ||
    /settingsuser/.test(location.pathname)
  );
  const showNavBotton = (
    /\/person.*/.test(location.pathname) ||
    /\/patrimony.*/.test(location.pathname) ||
    /\/livestock.*/.test(location.pathname) ||
    /\/harvest.*/.test(location.pathname) ||
    /\/areasmaps.*/.test(location.pathname) ||
    /\/activities.*\//.test(location.pathname) ||
    /\/resources\//.test(location.pathname) ||
    /\/productioncost\//.test(location.pathname) ||
    /\/inventory.*/.test(location.pathname) ||
    /\/financial.*/.test(location.pathname) ||
    false
  );

  // Após 172h pede o login novamente
  const date = new Date();
  const dateToExpire = new Date(localStorage.getItem("expiresTime"));
  if (authenticated && dateToExpire.getTime() - date.getTime() <= 0) {
    toast.error("Sessão expirada, favor logar novamente!");
    handleLogout();
    navigate("/");
  }

  return authenticated ? (
    <>
      {showNav && <Nav />}
      {showNavSide && <NavSide />}
      {showNavBotton && <NavBotton />}
      <OtherRoutes />
    </>
  ) : (
    <SignRoutes />
  );
}
export default Router;

import React, { Component } from "react";
import Chart from "react-apexcharts";

export default function ChartBarPArea({ valueProgress }) {
  //console.log("ChartBarP");
  //console.log(valueProgress);
  var state = {
    optionsRadial: {
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },

          dataLabels: {
            showOn: "always",
            name: {
              offsetY: -20,
              show: true,
              color: "#888",
              fontSize: "13px",
            },
            value: {
              formatter: function (val) {
                return val;
              },
              color: "#111",
              fontSize: "30px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#28a745"],
          inverseColors: false,
          opacityFrom: 0.9,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Área"],
    },

    seriesRadial: [valueProgress],
  };

  return (
    <div className="app">
      <div className="row">
        <div style={{ width: "100%" }} className="bg-white w-full">
          <Chart options={state.optionsRadial} series={state.seriesRadial} type="radialBar" height="200" />
        </div>
      </div>
    </div>
  );
}

// import { DatePicker } from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Span } from "../../../../components/Typography";
import { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { AccountCircle } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormattedInputs from "./FormattedInputs";
import api from "../../../../services/api";
import { useForm } from "react-hook-form";

const currencies = [
  {
    value: "ha",
    label: "hectare (ha)",
  },
  {
    value: "m2",
    label: "metros (m²)",
  },
];

export default function FormLivestockProperty({ formError, handleClose, property, setProperty, onSubmit }) {
  //const [currency, setCurrency] = useState("");
  const [tel, setTel] = useState("");

  console.log(property);

  // // input do formulário
  const handleChange = (event) => {
    event.persist();
    setProperty({ ...property, [event.target.name]: event.target.value });
  };

  //input do campo medida
  const handleChangeArea = (event) => {
    //setCurrency(event.target.value);
    setProperty({ ...property, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    setProperty({ ...property, tel: tel.textmask });
  }, [tel]);

  console.log(formError);

  //const [state, setState] = useState({ date: new Date() });
  //const handleDateChange = (date) => setState({ ...state, date });

  return (
    <section className="content ">
      <div className="container-fluid mt-4 " style={{ maxWidth: 900 }}>
        <Box onSubmit={(e) => onSubmit(e)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                helperText="Propriedade"
                //InputLabelProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { color: "#2e7d32" } }}
                disabled
                id="property"
                name="property"
                label="Nome da Propriedade"
                fullWidth
                autoComplete="shipping property-line1"
                variant="standard"
                color="success"
                value={property.name}
                onChange={handleChange}

                // {...(errors[property.name] && { error: true, helperText: errors[property.name] })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                //InputLabelProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { color: "#2e7d32" } }}
                required
                id="area"
                name="area"
                label="Qual sua área total?"
                fullWidth
                autoComplete="given-name"
                variant="standard"
                color="success"
                value={property.area}
                onChange={handleChange}
                error={formError.area && property.area === ""}
                helperText={formError.area && property.area === "" ? "Por favor, preencha o campo obrigatório!" : "texto "}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                InputLabelProps={{ style: { color: "#2e7d32" } }}
                id="measure"
                name="measure"
                label="measure"
                select
                value={property.measure}
                onChange={handleChangeArea}
                helperText="Selecione a medida da área"
                variant="standard"
                color="success"
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                //helperText="Insira o endereço da propriedade para que possamos encontrar sua propriedade no mapa"
                InputLabelProps={{ style: { color: "#2e7d32" } }}
                required
                id="address"
                name="address"
                label="Localize a sua propriedade rural no mapa"
                fullWidth
                autoComplete="shipping property-line1"
                variant="standard"
                color="success"
                value={property.address}
                onChange={handleChange}
                error={formError.address && property.address === ""}
                helperText={formError.address && property.address === "" ? "Por favor, preencha o campo obrigatório!" : "Insira o endereço da sua propriedade para que possamos encontrá-la no mapa "}
              />
            </Grid>
          </Grid>
          <br />
          <div>
            <Accordion sx={{ my: 2 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>Opicional - Mais informações</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ width: "100%" }}>
                  <TextField
                    helperText="Por favor, entre com o noem do proprietário da propriedade"
                    InputLabelProps={{ style: { color: "#2e7d32" } }}
                    id="ownerName"
                    name="ownerName"
                    label="Nome do Proprietário da Fazenda"
                    fullWidth
                    autoComplete="shipping property-line1"
                    variant="standard"
                    color="success"
                    value={property.ownerName}
                    onChange={handleChange}
                  />
                  <br />
                  <FormattedInputs setTel={setTel} tel={property.tel} />
                  <br />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      margin: "auto",
                    }}
                  >
                    <TextField
                      sx={{ width: 300 }}
                      id="outlined-multiline-static"
                      label="Possui alguma observação? Descreva aqui"
                      multiline
                      // InputProps={{ style: { fontSize: 40 } }}
                      InputLabelProps={{ style: { fontSize: 12, color: "#808080" } }}
                      rows={3}
                      variant="outlined"
                    />
                  </Box>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <br />
          <br />
          <br />
        </Box>
      </div>
    </section>
  );
}

import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FormatListNumberedRtlIcon from "@mui/icons-material/FormatListNumberedRtl";
import { useState } from "react";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PollIcon from "@mui/icons-material/Poll";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import ButtonIndicators from "./ButtonIndicators";

export default function CardIndicator({
  type,
  title,
  text,
  icon,
  dataCultivars,
  setDataCultivars,
}) {
  let iconData = "";
  console.log(title);
  switch (icon) {
    case "FormatListNumberedRtlIcon":
      iconData = <FormatListNumberedRtlIcon sx={{ fontSize: 40 }} />;
      break;
    case "TrendingUpIcon":
      iconData = <TrendingUpIcon sx={{ fontSize: 40 }} />;
      break;
    case "LeaderboardIcon":
      iconData = <LeaderboardIcon sx={{ fontSize: 40 }} />;
      break;
    default:
      iconData = <LeaderboardIcon sx={{ fontSize: 40 }} />;
    // console.log(`Sorry, we are out of ${icon}.`);
  }

  return (
    <>
      {title && (
        <Card sx={{ maxWidth: 345 }}>
          <CardHeader sx={{ height: 70 }} action={iconData} title={title} />

          <CardContent sx={{ height: 100 }}>
            <Typography variant="body2" color="text.secondary">
              {text}
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            {/* <IconButton aria-label="add to favorites">
                            <PollIcon />
                            <Typography sx={{ mt: 1, ml: 1 }} gutterBottom variant="subtitle1" component="div">
                                Visualizar
                            </Typography>
                        </IconButton> */}
            <ButtonIndicators
              type={type}
              title={title}
              dataCultivars={dataCultivars}
              setDataCultivars={setDataCultivars}
            />
          </CardActions>
        </Card>
      )}
    </>
  );
}

import React, { useState } from 'react';
import { Box, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import WidgetsIcon from '@mui/icons-material/Widgets';
import InventoryContent from './InventoryContent';
import TutorialStock from './components/tutorial/TutorialStock';
// import StepStock from './components/tutorial/StepStock';
// import ListProductsContent from '../products/ListProductsContent';
// import TableBuySalesOrder from './TableBuySalesOrder';
import CustomizedMenusBuy from './components/CustomizedMenus/CustomizedMenusBuy';
import TutorialStepGeral from '../../components/Tutorial/TutorialStepGeral';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `controle_estoque-tab-${index}`,
    'aria-controls': `Estoque-${index}`,
  };
}

export default function InventoryChoiseContentTabs() {
  // console.log('InventoryChoiseContentTabs')
  // const { tab } = useParams() // Obtém o parâmetro de rota para determinar qual tab será selecionada

  const [value, setValue] = useState(0);

  // const { aux_add } = useContext(Context)

  //let link = window.location.href
  // console.log(/.*\#financialcontent/.test(link))
  // // let aux_tab_active = 0;
  // // if (/.*\#financialcontent/.test(link)) {
  // //   aux_tab_active = 0;
  // // } else if (/.*\#bankaccount/.test(link)) {
  // //   aux_tab_active = 1;
  // // }
  // // const [value, setValue] = useState(aux_tab_active);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // useEffect(() => {
  //   // Atualiza o estado 'value' quando o parâmetro de rota 'tab' muda
  //   setTimeout(() => {
  //     setValue(parseInt(tab || '0'));
  //   }, 400);
  // }, [tab]);

  const objText = {
    icon: <WidgetsIcon sx={{ fontSize: 35 }} color="text.secondary" />, // Substitua pelo ícone desejado
    title: 'Controle de Estoque', // Título do diálogo
    subtitle: 'Passo a Passo', // Subtítulo abaixo do título
    textAnimation: 'Controle o estoque da propriedade através de entradas, saídas e movimentações dos produtos registrados.', // Texto que será animado pelo componente Typewriter
    description: 'Clique no botão abaixo e siga o passo a passo:', // Descrição adicional
    textButton: ['1- Registrar um novo local de estoque', '2- Registrar os produtos', '3- Registrar Clientes/Fornecedores', '4- Pedido de Compra', '5- Pedido de Venda'], // Texto complementar ou final
    linkButton: ['openStockRegistrarModal', 'openProductRegistrarModal', 'openPeopleRegistrarModal', 'openBuyRegistrarModal', 'openSalesRegistrarModal'], // O primeiro agora vai abrir o modal
    // linkButton: ['openPatrimonyRegistrarModal', '/inventory'], // Primeiro é um componente, segundo é um link
    filters_prods: [['group_category']],
  };

  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex' }}>
                <WidgetsIcon sx={{ fontSize: 35 }} color="text.secondary" />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    {/* Produção: {nameAtivity?.tipo_repos == "Criações" ? "Criação" : "Colheita"} */}
                    Estoque
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                    Gerencia&nbsp;o&nbsp;controle&nbsp;de produtos&nbsp;da&nbsp;propriedade
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            {/* <Grid item xs={3} md={3}>
              <SplitButtonBuy />
            </Grid> */}
            <Grid item xs={12} md={3}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ gap: 2 }}>
                <CustomizedMenusBuy />
              </Box>
            </Grid>
            {/* <Grid item xs={4} md={3}>
              <ProductRegistrar />
            </Grid>
            <Grid item xs={4} md={3}>
              <RegisterBuyDialog type={'buy'} />
            </Grid> */}
            <Grid item xs={12} md={3}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ gap: 2 }}>
                <TutorialStepGeral objText={objText} />
                <TutorialStock />
              </Box>
            </Grid>
            <Grid item xs={1} md={1}></Grid>
            {/* <Grid item xs={1} md={1}>
              <StepStock tab={tab} />
            </Grid> */}
          </Grid>
        </div>
      </div>

      <div className="content mb-4">
        <div className="container-fluid">
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs textColor="inherit" TabIndicatorProps={{ style: { background: 'green' } }} value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Controle de Estoque" {...a11yProps(0)} />
                {/* <Tab label="Catálogo de Produtos" {...a11yProps(1)} /> */}
                {/* <Tab label="Catálogo de Produtos" {...a11yProps(2)} /> */}
                {/* <Tab label="Fornecedores" {...a11yProps(2)} /> */}
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {/* <TableBuySalesOrder type={'expense'} /> */}
              <InventoryContent />
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
              <ListProductsContent />
            </TabPanel> */}
            {/* <TabPanel value={value} index={2}>
              <InventoryRegisterContentTabs /> 
              
            </TabPanel> */}
            {/* <TabPanel value={value} index={2}>
              <TablesPerson tab_active={["Fornecedor", "Ativo"]} />
            </TabPanel> */}
          </Box>
        </div>
      </div>
    </div>
  );
}

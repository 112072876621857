import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ListIcon from "@mui/icons-material/List";
import { Card, CardHeader, Divider, Grid, Stack } from "@mui/material";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import CardMeatProductionCut from "../../planning/components/MeatProduction/CardMeatProductionCut";
import CardMeatProductionCreation from "../../planning/components/MeatProduction/CardMeatProductionCreation";
import CardProductionLivestock from "./CardProductionLivestock";
import TableProductionCardEmpty from "./TableProductionCardEmpty";
import CardProductionLivestockTabsAreaProduction from "./CardProductionLivestockTabsAreaProduction";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function CardProductionLivestockTabsContent({ listPlanning }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log("CardProductionLivestockTabs");
  console.log(listPlanning);

  console.log(listPlanning.length);

  const activePlan = listPlanning.filter(active => active.isActiveTargetLivestock === 1)
  

  return (
    <Box sx={{ width: "100%" }}>
      {/* {listPlanning.length == 0 && <TableProductionCardEmpty title={"Criação"} />} */}

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} textColor="success" TabIndicatorProps={{ style: { background: "green" } }} aria-label="basic tabs example">
          {activePlan.map((plan, index) => (
            <Tab
              key={plan.id}
              label={
                <Grid container direction="row" alignItems="center">
                  <ListIcon sx={{ mr: 0.5, mb: 0.2 }} />
                  { plan.typePlanning}
                </Grid>
              }
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {activePlan.map((plan, index) => (
        <TabPanel key={plan.id} value={value} index={index}>
          {/* Renderizar o conteúdo correspondente ao índice */}
          {/* Por exemplo, para o índice 0: */}
          {plan.typePlanning === "Cria" && <CardProductionLivestock plan={plan} type={"Cria"} />}
          {/* Para o índice 1: */}
          {plan.typePlanning === "Corte" && <CardProductionLivestock plan={plan} type={"Corte"} />}
          {/* Para o índice 2: */}
          {plan.typePlanning === "Outra" && <CardProductionLivestock plan={plan} type={"Outra"} />}
        </TabPanel>
      ))}
    </Box>
  );
}

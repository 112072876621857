import * as React from 'react'
import { makeStyles, styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { NavLink } from 'react-router-dom'
import ChartBarP from '../../../../components/Charts/ChartBarP'
import ChartBarProduction from '../../../../components/Charts/ChartBarProduction'
import ChartBarProductivity from '../../../../components/Charts/ChartBarProductivity'
import { Button, CardActionArea, CardMedia, Divider, Paper } from '@mui/material'
import CardReports from './CardReports'

const classes = {
  root: {
    flexGrow: 1,
  },
  paper: {
    width: 300,
    height: 150,
    padding: 40,
    textAlign: 'center',
    color: 'blue',
    fontFamily: 'Roboto',
  },
}

export default function ListReportsPropertyCard() {
  //const data = [1, 2, 3, 4, 5]
  const data = [
    {
      type: 'property',
      group: 'Geral',
      doc: 'ListCreationHarvest',
      title: 'Lista <span style="font-size:16px;">das Criações e Safras</span>',
      text: 'Lista de todas as suas <b>criações e safras</b>.',
      icon: 'WidgetsIcon',
    },

    // { type: 'property', group: 'Geral', doc: 'SolicitaçõesProperty', title: 'Solicitações', text: 'Lista de todas as <b>Solicitações</b>.', icon: 'WidgetsIcon' },
    { type: 'property', group: 'Financeiro', doc: 'ContasProperty', title: 'Contas', text: 'Lista de todas as <b>contas a pagar</b>.', icon: 'WidgetsIcon' },
    {
      type: 'property',
      group: 'Financeiro',
      doc: 'CustosProperty',
      title: 'Custos <span style="font-size:16px;">de produção</span>',
      text: 'Lista de <b>custos</b> agrupadas em categoria e recursos.',
      icon: 'WidgetsIcon',
    },
    { type: 'property', group: 'Financeiro', doc: 'RentabilidadeProperty', title: 'Rentabilidade', text: 'Lista toda a produção, custos e lucro da propriedade', icon: 'WidgetsIcon' },

    {
      type: 'property',
      group: 'Estoque',
      doc: 'StockProperty',
      title: 'Estoque',
      text: 'Lista de todos <b>os itens</b> em seus locais de estoque com as respectivas quantidades e valores.',
      icon: 'WidgetsIcon',
    },
    { type: 'property', group: 'Estoque', doc: 'PatrimonyProperty', title: 'Patrimônio', text: 'Lista todos os <b>patrimônios</b> da propriedade.', icon: 'WidgetsIcon' },
  ]

  // Agrupando os dados por 'group'
  const groupedData = data.reduce((groups, item) => {
    if (!groups[item.group]) {
      groups[item.group] = []
    }
    groups[item.group].push(item)
    return groups
  }, {})

  return (
    <>
      <div>
        {Object.keys(groupedData).map((group) => (
          <div key={group}>
            <Typography sx={{ mt: 2, ml: 1 }} color="#808080" gutterBottom variant="subtitle1" component="div">
              {group}
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={3}>
              {groupedData[group].map((item) => (
                <Grid item xs={12} sm={6} md={3} key={item.doc}>
                  <CardReports type={item.type} doc={item.doc} title={item.title} text={item.text} icon={item.icon} />
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
      </div>
    </>
  )
}

import CardMsn from "./forms/CardMsn";
import AppForm from "./forms/AppForm";

export default function DialogMessengerContent({ handleClose }) {
  return (
    <>
      <section className="content ">
        <div className="container-fluid " style={{ maxWidth: 900 }}>
          <CardMsn handleClose={handleClose} />
        </div>
      </section>
    </>
  );
}

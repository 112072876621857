import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import PollIcon from '@mui/icons-material/Poll';
import { Container, Paper } from "@mui/material";
import HarvestIndicator from "./HarvestIndicator";
import { useStore } from "zustand";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ButtonIndicators({type, title, dataCultivars, setDataCultivars}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div>
      {/* <Button variant="outlined" color="success" startIcon={<i className="fa fa-trophy text-success mr-1" />} onClick={handleClickOpen}>
        Registro da colheita
      </Button> */}
      <IconButton aria-label="add to favorites" color="success" onClick={handleClickOpen}>
                            <PollIcon />
                            <Typography sx={{ mt: 1, ml: 1 }} gutterBottom variant="subtitle1" component="div">
                                Visualizar
                            </Typography>
                        </IconButton>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Box>
          <AppBar elevation={1} sx={{ position: "relative" }} style={{ background: "#fff" }}>
            <Toolbar>
              <Typography sx={{ color: "#000", flexGrow: 1, textAlign: "center" }} variant="h6" component="div">
                Indicador: {title}
              </Typography>
              <Typography sx={{ flexGrow: 1, textAlign: "start" }} variant="h6" component="div"></Typography>
              <IconButton edge="center" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon sx={{ color: "#000" }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          {/* <FormGoalTarget handleClose={handleClose} /> */}
          <Container component="main" maxWidth="lg" sx={{ mb: 0 }}>
                <Paper variant="" sx={{ my: { xs: 3, md: 1 }, p: { xs: 2, md: 1 } }}>
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    
                  </Box>
                  <Box sx={{ margin: "0 auto", width: "80%", mt: 2 }}>
                  { (type === "harvest" && title === "Cultivares") && <HarvestIndicator dataCultivars={dataCultivars} setDataCultivars={setDataCultivars}/> }
                  </Box>
                </Paper>
              </Container>
          
        </Box>
      </Dialog>
    </div>
  );
}

import React, { useState } from 'react'
import { Box, Button, CardMedia, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, List, ListItem, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material"
import { Queue } from '@mui/icons-material'
import AutocompleteGeneric from '../../../../components/Fields/AutocompletGeneric'
import IconsLivestockActivities from '../../../../components/Icons/IconsLivestockActivities';
// import CurrencyInputReal from '../../../../components/Fields/CurrencyInputReal'
import GenericValueField from '../../../../components/Fields/GenericValueField'

const default_lote = {
  id: null,
  id_livestock: null,
  livestock: null,
  id_area: null,
  area: null,
  new_lote: 0,
  name: '',
  weight: 0,
  weight_unit: 'Kg',
  quant: 0,
  value: 0,
  fase_name: 'Cria',
  image: '',
  num_animals: 0,
  num_females: 0,
  num_males: 0,
  id_group: null
}
const tipo_lote = [
  { id: 0, label: 'Lote cadastrado' },
  { id: 1, label: 'Novo Lote' },
];

const faseManejo = [
  {id: 'Cria', label: 'Cria'},
  {id: 'Recria', label: 'Recria'},
  {id: 'Engorda', label: 'Engorda'},
  {id: 'Reprodução', label: 'Reprodução'},
  {id: 'Produção', label: 'Produção'},
  // "Completo",
];

export default function FormAddLotesBuy({ data, obj_fn }) {
  const optionsLotesList = JSON.parse(JSON.stringify( obj_fn.usestore.reposLivestock.listLotes ))
  const optionsLotes = optionsLotesList.map((item) => {
    item.weight_unit = item.weight_unit || 'kg'
    item.peso = (item.weight ? item.weight + " (" + item.weight_unit + ")" : '-')
    item.new_lote = 0

    if (!item.image) {
      const listImageRepos = obj_fn.usestore.reposImages.getImageStore("lote", item.id);
      const itemData = listImageRepos.filter((img) => {
        return img.img
      })
      item.image = itemData[0] ? itemData[0].img : null
    }

    return item
  })

  const [selectedLote, setSelectedLotes] = useState(default_lote)

  const handleChange = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    if (value && (field === 'value' || field === 'weight' || field === 'new_lote' )) {
      value = parseFloat(value);
    }

    if (field === 'new_lote') {
      let aux_lot = default_lote
      aux_lot.new_lote = value
      setSelectedLotes(aux_lot)
    }
    setSelectedLotes(prevState => ({ ...prevState, [field]: value }))
  }

  const handleChangeSelect = (field, newValue) => {
    // console.log('handleChangeSelect')
    let aux_lote = newValue ? JSON.parse(JSON.stringify(newValue)) : null

    setSelectedLotes(aux_lote)
    obj_fn.setData(prevState => ({ ...prevState, lote_selected: aux_lote }))
  }

  const opt_lotes = {
    multiple: false,
    name: 'lote',
    field: 'lote',
    label: ('Lote para: ' + data.operationType),
    values: optionsLotes,
    variant: 'standard',
    helperText: (<>
      Selecione o lote desejado
      <br />
      Qtd. animais Não Identificados: {selectedLote?.num_animals || '-'}
    </>),
    listHeight: 225,
    disableClearable: true,
  }

  const optionsLivestockList = JSON.parse(JSON.stringify( obj_fn.usestore.reposLivestock.listLivestock ))
  const opt_livestock = {
    name: 'livestock',
    field: 'livestock',
    label: 'Criações',
    values: optionsLivestockList,
    variant: 'standard',
    helperText: (<>
      Selecione em qual Criação lote ficará
    </>),
    listHeight: 225,
    disableClearable: true,
  }
  const handleChangeSelectLivestock = (field, newValue) => {
    // console.log('handleChangeSelectLivestock')
    let aux_livestock = newValue ? JSON.parse(JSON.stringify(newValue)) : null

    setSelectedLotes(prevState => ({ ...prevState, livestock: aux_livestock }))
    setSelectedLotes(prevState => ({ ...prevState, id_livestock: (newValue ? newValue.id : null) }))

    if (selectedLote?.new_lote) {
      setSelectedLotes(prevState => ({ ...prevState, id_group: (newValue ? newValue.id_group : null) }))
    }
  }

  const handleChangeSelectArea = (field, newValue) => {
    // console.log('handleChangeSelectArea')
    let aux_area = newValue ? JSON.parse(JSON.stringify(newValue)) : null

    setSelectedLotes(prevState => ({ ...prevState, area: aux_area }))
    setSelectedLotes(prevState => ({ ...prevState, id_area: (newValue ? newValue.id : null) }))
  }

  const handleAreas = () => {
    const optionsAreasList = JSON.parse(JSON.stringify( obj_fn.usestore.reposAreas.getAreaUsedMapStore([['id', selectedLote?.id_livestock], ['tipo', 'livestock']]) ))
    const opt_areas = {
      name: 'areas',
      field: 'areas',
      label: 'Áreas da Criação',
      values: optionsAreasList,
      variant: 'standard',
      helperText: (<>
        Selecione em qual Área o lote ficará
      </>),
      listHeight: 225,
      disableClearable: true,
      disabled: !selectedLote?.id_livestock
    }

    return (<>
      <Grid item xs={12} sm={4} >
        <AutocompleteGeneric value={selectedLote?.area} options={opt_areas || []} handleChange={handleChangeSelectArea} />
      </Grid>
      <Grid item xs={12} sm={4} >
        {!selectedLote?.area ? <></> : <>
          <CardMedia
            component="img"
            image={selectedLote?.area?.imageDecode || ''}
            alt="Imagem da area"
            sx={{
              borderRadius: '16px',
              height: '80px',
              width: '100%',
            }}
          />
        </>}
      </Grid>
    </>)
  };

  // const para validar os campos e o button add ao carrinho
  const validFields = (type) => {
    let text = ''
    var mensagem = (<></>);
    let error = '';
    let is_error = false

    if (type === 'name' || type === 'isError') {
      if (selectedLote?.new_lote) {
        const loteExists = obj_fn.usestore.reposLivestock.getLotesStore('name', selectedLote?.name)
        text = "Informe o nome do Lote"

        if (loteExists.length > 0) {
          error = 'Nome já existente, escolha outro nome!'
          is_error = true
        } else if (selectedLote?.name?.length < 5) {
          error = 'Nome do lote deve ter no mínimo 5 caracteres!'
          is_error = true
        }
      } else {
        if (!selectedLote?.id) {
          error = 'Selecione um lote de animais'
          is_error = true
        }
      }
    }
    if (type === 'fase_name' || type === 'isError') {
      // let fase = selectedLote?.fase_name
      text = 'Selecione a fase de manejo em que o lote se encontra'

      // if (!fase) {
      //   error = 'Selecione a fase de manejo do animal'
      //   is_error = true
      // }
    }
    if (type === 'quantidade' || type === 'isError') {
      let qtd = selectedLote?.quant
      text = "Quantidade de animais para: " + data?.operationType

      if (!qtd) {
        error = 'Quantidade deve ser maior que 0!'
        is_error = true
      } else if (data.operationType === "Venda" && qtd > selectedLote?.num_animals) {
        error = 'Não pode ser maior do que a Qtd de animais do lote'
        is_error = true
      }
    }
    if (type === 'valor' || type === 'isError') {
      let valor = selectedLote?.value
      text = "Valor total dos animais no lote"

      if (!valor) {
        error = 'Valor deve ser maior que 0!'
        is_error = true
      }
    }

    if (type === 'isError') {
      return is_error
    }

    if (is_error) {
      mensagem = (<>
        <br/>
        <span style={{ color: 'red' }}>
          {error}
        </span>
      </>);
    }

    return (<>
      {text}
      {mensagem}
    </>);
  };

  const handleAdd = () => {
    const aux_add = JSON.parse(JSON.stringify(data.lotes))
    selectedLote.discount = 0
    aux_add.push(selectedLote)

    var val_total = 0
    var aux_val = 0
    for (var i = 0, tam = aux_add.length; i < tam; i++) {
      aux_val = parseFloat(aux_add[i].value)
      // aux_val -= aux_val * parseFloat(aux_add[i].discount) / 100

      val_total += aux_val
      aux_add[i].value_end = aux_val
    }
    var aux_value = (data.value - data.lotes_value) + val_total
    var aux_value_end = (data.value_end - data.lotes_value) + val_total

    obj_fn.setData(prevState => ({ ...prevState, lotes_value: val_total }))
    obj_fn.setData(prevState => ({ ...prevState, lotes: aux_add }))

    obj_fn.setData(prevState => ({ ...prevState, value: aux_value }))
    obj_fn.setData(prevState => ({ ...prevState, value_end: aux_value_end }))

    if (selectedLote.new_lote) {
      selectedLote.peso = (selectedLote.weight ? selectedLote.weight + " (" + selectedLote.weight_unit + ")" : '-')
      obj_fn.usestore.reposLivestock.addLoteStore(selectedLote)
    }

    obj_fn.setData(prevState => ({ ...prevState, lote_selected: null }))
    setSelectedLotes(default_lote)
  }

  return ( <>
    <Grid container spacing={3}>
      <Box sx={{ display: "flex", justifyContent: "center", p: 1, ml: 1, mt: 1 }}>
        <Grid container spacing={3} >
          <Grid item xs={12} sm={12} sx={{ mb: -2 }}>
            <RadioGroup
              label="seleção tipo do lote"
              aria-label="seleção tipo do lote"
              name="lote_radio_group"
              value={selectedLote?.new_lote}
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
              defaultValue={0}
            >
              {tipo_lote.map((prog) => (
                <FormControlLabel
                  key={prog.id}
                  value={prog.id}
                  name="new_lote"
                  control={<Radio color="success" />}
                  label={prog.label}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            {selectedLote?.new_lote ? <>
              <TextField
                label="Nome do Lote"
                name="name"
                id="name"
                color="success"
                variant="standard"
                fullWidth
                value={selectedLote?.name}
                onChange={handleChange}
                onBlur={(e) => handleChange(e, true)}
                helperText={validFields('name')}
              />
            </> : <>
              <AutocompleteGeneric value={(!selectedLote?.new_lote && selectedLote?.id ? selectedLote : null)} options={opt_lotes} handleChange={handleChangeSelect} />
            </>}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={4} sx={{ mt: -5, mb: -1 }}>
              {selectedLote?.new_lote ? <>
                <Grid item xs={12} sm={6} >
                  <FormControl sx={{ m: 1, minWidth: 160 }}>
                    <InputLabel id="faseManejo-label" color="success">
                      Fase de Manejo
                    </InputLabel>
                    <Select color="success" fullWidth variant="outlined" 
                      labelId="faseManejo-label"
                      label="Fase de Manejo" 
                      name="fase_name" 
                      value={selectedLote?.fase_name} 
                      onChange={(e) => handleChange(e)}
                      disabled={!selectedLote?.new_lote}
                      defaultValue={'Cria'}
                    >
                      {faseManejo.map((prog) => (
                        <MenuItem key={prog.id} value={prog.id} >
                          {prog.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {validFields('fase_name')}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <TextField
                    label="Peso médio"
                    name="weight"
                    id="weight"
                    type="number"
                    color="success"
                    variant="standard"
                    fullWidth
                    InputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      maxLength: 12,
                      min: 0,
                    }}
                    value={selectedLote?.weight}
                    onChange={handleChange}
                    onBlur={(e) => handleChange(e, true)}
                    helperText={"Peso médio individual dos animais no lote"}
                  />
                </Grid>
              </> : <>
                <Grid item xs={8} sm={8}>
                  <List dense={true}>
                    <ListItem>
                      <ListItemText primary={"Fase de manejo: " + (selectedLote?.fase_name || '-')} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={"Peso médio: " + (selectedLote?.peso || '-')} />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={4} md={4}>
                  {!selectedLote?.image ? <>
                    <IconsLivestockActivities activity={'Geral'} widthStyles={'80px'} fill={'#1f2d3d'}/>
                  </> : 
                    <CardMedia
                      component="img"
                      image={selectedLote?.image}
                      alt="Imagem do lote"
                      sx={{
                        borderRadius: '16px',
                        height: '80px',
                        width: '100px',
                      }}
                    />
                  }
                </Grid>
              </>}
            </Grid>
          </Grid>
          {!selectedLote?.new_lote ? <></> : <>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={4} >
                <Grid item xs={12} sm={4} >
                  <AutocompleteGeneric value={selectedLote?.livestock} options={opt_livestock} handleChange={handleChangeSelectLivestock} />
                </Grid>
                {/* area */}
                {handleAreas()}
              </Grid>
            </Grid>
          </>}
          <Grid item xs={6} sm={4}>
            {/* <CurrencyInputReal
              required
              id="quant"
              name="quant"
              label="Quantidade"
              color="success"
              value={selectedLote?.quant}
              onChange={handleChange}
              adornment="quantidade"
              helperText={validFields('quantidade')}
            /> */}
            <GenericValueField
              handleChange={handleChange}
              options={{
                required: true,
                id: "quant",
                name: "quant",
                label: "Quantidade",
                value: selectedLote?.quant,
                // helperText: validFields('quantidade'),
                helperText: "Quantidade de animais para: Compra",
                helperTextError: "Quantidade deve ser maior do que 0",
                width: '80%'
              }}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            {/* <CurrencyInputReal
              required
              id="value"
              name="value"
              label="Valor"
              color="success"
              value={selectedLote?.value}
              onChange={handleChange}
              helperText={validFields('valor')}
            /> */}
            <GenericValueField
              handleChange={handleChange}
              options={{
                required: true,
                id: "value",
                name: "value",
                label: "Valor",
                value: selectedLote?.value,
                helperText: "Valor total dos animais no lote",
                helperTextError: "Valor deve ser maior que 0!",
                disabled:!selectedLote?.id,
                endAdornment: true,
                width: '80%'
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} sx={{ mt: 2 }}>
            <Button variant="contained" color="success" onClick={handleAdd} disabled={validFields('isError')}>
              <Queue sx={{mr: 1}}/> Adicionar ao carrinho
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  </>)
}
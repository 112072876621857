import * as React from "react";
import { makeStyles, styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { NavLink } from "react-router-dom";
import ChartBarP from "../../../components/Charts/ChartBarP";
import ChartBarProduction from "../../../components/Charts/ChartBarProduction";
import ChartBarProductivity from "../../../components/Charts/ChartBarProductivity";
import { Button, CardActionArea, CardMedia, Paper } from "@mui/material";
import CardIndicator from "./CardIndicator";
import { useStore } from "zustand";
import useHarvestReposStore from "../../../store/useHarvestReposStore";
import { useEffect } from "react";
import { useState } from "react";

const classes = {
  root: {
    flexGrow: 1,
  },
  paper: {
    width: 300,
    height: 150,
    padding: 40,
    textAlign: "center",
    color: "blue",
    fontFamily: "Roboto",
  },
};

export default function ListIndicatorsHarvest() {
  const [dataCultivars, setDataCultivars] = useState([]);
  //const data = [1, 2, 3, 4, 5]
  const data = [
    { type: "harvest", title: "Cultivares", text: "Cultivares utilizadas e áreas de produção.", icon: "ListAltIcon" },
    { type: "harvest", title: "Evolução da Safra", text: "Percentual de atividades realizadas num determinado tipo de atividade ao longo do tempo.", icon: "LeaderboardIcon" },
    { type: "harvest", title: "Produção", text: "Produção de todas as áreas produtivas", icon: "LeaderboardIcon" },
    { type: "harvest", title: "Produtividade", text: "Produtividade de todas as áreas produtivas", icon: "TrendingUpIcon" },
    { type: "harvest", title: "Rentabilidade", text: "Produção, custos e lucro da safra", icon: "TrendingUpIcon" },
  ];

 const property_id = (localStorage.getItem("Property") || 1);
    // // instancia os repos da safra atual
  const reposHarvest = useHarvestReposStore((state) => state);
  console.log(reposHarvest)
  //const listHarvests = reposHarvest.getHarvestStore("id", property_id);
  const listHarvests = reposHarvest.listHarvest

  console.log(listHarvests)
  //const reposHarvests = usestore.reposHarvest;
  //const reposAreasMap = usestore.reposAreas;

  const cultivars = []

    for (const cultivar of listHarvests) {
      cultivars.push({
        type: "harvest",
        active: false,
        cultivar: cultivar.cultivation_name,
        cultivation_icon: cultivar.cultivation_icon,
        harvest: cultivar.label,
        areas: cultivar.areas
      })
    }

    useEffect(() => {
      setDataCultivars(cultivars)
    }, [])
    
   
   console.log(cultivars)

   

  return (
    <>
      <div>
        <Grid container spacing={3}>
          {data.map((item) => (
            <>
              <Grid item xs={12} sm={6} md={3}>
                {/* <CardIndicator title={data.title} text={data.text} icon={data.icon} /> */}
                <CardIndicator type={item.type} title={item.title} text={item.text} icon={item.icon} dataCultivars={dataCultivars} setDataCultivars={setDataCultivars}/>
              </Grid>
            </>
          ))}
        </Grid>
      </div>
    </>
  );
}

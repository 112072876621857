import * as React from 'react';
import { Box, Card, CardContent, Divider, Grid, ListItem, ListItemIcon, ListItemText, Stack, Typography, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Notes, Payment } from '@mui/icons-material';
import FooterModal from '../../../../components/Footer/FooterModal';
import MaintenanceCostCalculator from './MaintenanceCostCalculator';
import dayjs from 'dayjs';
import { formatValue } from '../../../../utils/functions';

const useStyles = makeStyles((theme) => ({
  boxShadow: {
    width: "100%",
    boxSizing: 'border-box',
    border: '1px solid transparent',
    boxShadow: "0 2px 6px #00000033",
    borderRadius: '20px',
    padding: '8px',
    outline: 'none',
    textOverflow: 'ellipses',
  },
}));

export default function FormMaintenancenStep5({ data, obj_fn }) {
  const classes = useStyles();

  const isError = () => {
    return false;
  };
  const returnError = () => {
    return false;
  };

  // calcula o valor de porcentagem sobre um valor
  function calcValue(percent, value) {
    var aux_value = value;
    if (typeof value === 'string') {
      aux_value = parseFloat(value);
    }
    var aux_val = aux_value * (parseFloat(percent || '0') / 100);

    return formatValue(aux_val, true);
  }

  // retorna o nome do banco de acordo com o ID 
  function getNameBank(id_bank) {
    const bankaccount = obj_fn.usestore.reposBank.getBankStore('id', id_bank)[0];
    return bankaccount.name;
  }

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
        {/* dados gerais */}
        <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
          {/* <Input /> */}
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Informações essenciais</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 0, mt: -3, ml: -5 }}>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Data de início" secondary={dayjs(data.date_start).format('DD/MM/YYYY')} />
            </ListItem>
          </Grid>
          <Grid item xs={6} sm={6}>
            <ListItem>
              <ListItemText primary="Patrimônio" secondary={data.name} />
            </ListItem>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12}>
            <Box sx={{ ml: 1, display: 'flex', flexDirection: 'column' }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}>Valor total da manutenção:</Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Card>
              <MaintenanceCostCalculator data={data} obj_fn={obj_fn} />
            </Card>
          </Grid>
        </Grid>
        {/* Condições de pagamento */}
        <Grid container spacing={2} sx={{mt: 1}} >
          <Grid item xs={12} sm={12} >
            <Box sx={{ ml: 1, display: "flex", flexDirection: "column" }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}>
                  <Payment sx={{mr: 1}}/>
                  Condições de pagamento
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <ListItem>
              <ListItemText primary="Parcelamento:" secondary={data.installment == 'a_vista' ? 'À vista' : data.installment + 'X'} />
            </ListItem>
          </Grid>
          {/* <Grid item xs={6} sm={3}>
            <ListItem>
              <ListItemText primary="Vencimento:" secondary={data.dueDate_fc} />
            </ListItem>
          </Grid> */}
          <Grid item xs={6} sm={3}>
            <ListItem>
              <ListItemText primary="Forma de Pagamento:" secondary={data.formPayment} />
            </ListItem>
          </Grid>
          <Grid item xs={6} sm={3}>
            <ListItem>
              <ListItemText primary="Conta de Pagamento:" secondary={getNameBank(data.bankAccount)} />
            </ListItem>
          </Grid>
        </Grid>

        {/* Previsão de pagamento */}
        <Grid container spacing={2} sx={{mt: 1}} >
          <Grid item xs={12} sm={12} >
            <Box sx={{ ml: 1, display: "flex", flexDirection: "column" }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}><Payment sx={{mr: 1}}/> Previsão de pagamento</Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Juros:" secondary={
                data.fee + "%." + (obj_fn.ismobile ? <br/> : '') + " (" + calcValue(data.fee, data.value) + ")"} />
            </ListItem>
          </Grid>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Multa:" secondary={
                data.penalty + "%." + (obj_fn.ismobile ? <br/> : '') + " (" + calcValue(data.penalty, data.value) + ")"} />
            </ListItem>
          </Grid>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Desconto:" secondary={
                data.discount + "%." + (obj_fn.ismobile ? <br/> : '') + " (" + calcValue(data.discount, data.value) + ")"} />
            </ListItem>
          </Grid>
        </Grid>

        {/* Parcelas do pagamento */}
        {data.installment !== "a_vista" && <>
          <Divider sx={{ mt: 2, mb: 2, ml: -3, borderColor: '#388e3c' }}/>
          <Grid container spacing={2} sx={{mt: 2, mb: 3}} >
            <Grid item xs={12} sm={12} >
              <Box sx={{ ml: 1, display: "flex", flexDirection: "column" }}>
                <Stack spacing={0.5}>
                  <Typography fontWeight={700}>
                    <Payment sx={{mr: 1}}/>
                    Parcelas do pagamento
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            {data.arr_installment.map((portion, index) => (<>
              <Grid container item key={"portion_" + index} xs={12} sm={12} className={classes.boxShadow}>
                <Grid key={"GI1_" + index} item xs={1} sm={1}>
                  <ListItem>
                    <ListItemText primary="Parcela:" secondary={(index+1) + "/" + data.installment} />
                  </ListItem>
                </Grid>
                <Grid key={"GI2_" + index} item xs={1.5} sm={1.5}>
                  <ListItem>
                    <ListItemText primary="Vencimento:" secondary={dayjs(portion.dueDate).format("DD/MM/YYYY")} />
                  </ListItem>
                </Grid>
                <Grid key={"GI3_" + index} item xs={1.5} sm={1.5}>
                  <ListItem>
                    <ListItemText primary="Valor Parcela:" secondary={formatValue((portion.valueTotal || portion.value), true)} />
                  </ListItem>
                </Grid>
                <Grid key={"GI4_" + index} item xs={2} sm={2}>
                  <ListItem>
                    <ListItemText primary="Forma de Pagamento:" secondary={portion.formPayment} />
                  </ListItem>
                </Grid>
                <Grid key={"GI5_" + index} item xs={2.5} sm={2.5}>
                  <ListItem>
                  <ListItemText primary="Conta de Pagamento:" secondary={getNameBank(portion.bankAccount || portion.id_bank)} />
                  </ListItem>
                </Grid>
                <Grid key={"GI6_" + index} item xs={2.5} sm={2.5}>
                  <ListItem>
                    <ListItemText primary="Descricao:" secondary={portion.description} />
                  </ListItem>
                </Grid>
                {/* <Grid key={"GI7_" + index} item xs={1} sm={1}>
                  <ListItem>
                    <ListItemText primary="Pago:" secondary={portion.payed ? "Sim" : "Não"} />
                  </ListItem>
                </Grid> */}
              </Grid>
            </>))}
          </Grid>
        </>}

        {/* Rateio e Descrição */}
        {!data.projects_apport.length > 0 && !data.description ? <></> : <>
          <Grid container spacing={2} sx={{ mt: 1, mb: 1 }} >
            <Grid item xs={12} sm={12} >
              <Box sx={{ ml: 1, display: "flex", flexDirection: "column" }}>
                <Stack spacing={0.5}>
                  <Typography fontWeight={700}>
                    <Notes sx={{mr: 1}}/> 
                    Rateios e Descrição
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            {data.projects_apport.length > 0 ? <>
              <Grid item >
                <ListItemText primary="Lançar valor para a Propriedade ou ratear com Criações ou Safras" />
                <Grid container spacing={2} sx={{ mt: "-20px" }} >
                  {data.projects_apport?.map((project) => (
                    <Grid key={project.id + "_" + project.tipo_repos} item container alignItems="left" justifyContent="left" xs={6} sm={6}>
                      <Card sx={{ display: "flex", minWidth: '450px'}}>
                        <ListItemIcon>
                          <CardContent sx={{ flex: 1 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', pl: 1, pb: 1 }}>
                              <Typography variant="subtitle1" gutterBottom>
                                {project.name} ({project.tipo_repos === 'Property' ? 'Propriedade' : project.tipo_repos})
                              </Typography>
                              <Typography variant="subtitle2" gutterBottom>
                                Rateio de: {project.val}%. ({calcValue(project.val, data.value_end)})
                              </Typography>
                            </Box>
                          </CardContent>
                        </ListItemIcon>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </> : <></>}
            {data.description ? <>
              <Grid item xs={12} sm={12}>
                <ListItem>
                  <ListItemText primary="Descrição Geral do Lançamento:" secondary={data.description} />
                </ListItem>
              </Grid>
            </> : <></>}
          </Grid>
        </>}
      </Grid>

      {obj_fn.no_footer || !obj_fn.handleNext ? <></> : 
        <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
      }
    </>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ListIcon from "@mui/icons-material/List";
import { Card, CardHeader, Divider, Grid, Stack } from "@mui/material";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import CardMeatProductionCut from "../../planning/components/MeatProduction/CardMeatProductionCut";
import CardMeatProductionCreation from "../../planning/components/MeatProduction/CardMeatProductionCreation";
import CardProductionLivestock from "./CardProductionLivestock";
import TableProductionCardEmpty from "./TableProductionCardEmpty";
import CardProductionLivestockTabsAreaProduction from "./CardProductionLivestockTabsAreaProduction";
import CardProductionLivestockTabsProduction from "./CardProductionLivestockTabsAreaProduction";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function CardProductionLivestockTabs({ listPlanning }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log("CardProductionLivestockTabs11");
  console.log(listPlanning);

  console.log(listPlanning.length);

  return (
    <Box sx={{ width: "100%" }}>
      {/* {listPlanning.length == 0 && <TableProductionCardEmpty title={"Criação"} />} */}
      <Card sx={{ mt: 1 }}>
        <CardHeader
          title={
            <>
              <Grid container spacing={2} sx={{ mb: 1 }}>
                <Grid item xs={12} md={8}>
                  <Box sx={{ display: "flex" }}>
                    {/* <BarChartIcon style={{ fontSize: 35 }} /> */}
                    <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                      <Typography fontWeight="500" variant="h6">
                        {/* Produção: {nameAtivity?.tipo_repos == "Criações" ? "Criação" : "Colheita"} */}
                        Gerenciamento do processo de produção
                        {/* <Typography fontWeight="500" variant="subtitle1" style={{ color: "#808080" }}>
                          Neste momento temos x áreas para definir o processo de produção.
                        </Typography> */}
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: "#808080" }}>
                          Defina a meta de produção (quantidade a produzir, tempo de produção e custo de produção).
                        </Typography>
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box display="flex" justifyContent="flex-start">
                    {/* <ButtonRegisterProductionTargetHarvest areas={areas} /> */}
                  </Box>
                </Grid>
              </Grid>
            </>
          }
        ></CardHeader>
        <Divider />
      </Card>

      <CardProductionLivestockTabsProduction listPlanning={listPlanning} />
    </Box>
  );
}

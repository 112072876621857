import React, { useContext, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
// import { toast } from 'react-toastify';
import { AppBar, Box, Button, Dialog, DialogContent, DialogTitle, Divider, Fab, Grid, LinearProgress, Paper, Skeleton, Toolbar, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
// import DrawArea from './DrawArea';
// import FormSaveArea from './FormSaveArea';
// import html2canvas from 'html2canvas';
import MapDraw from '../../mapsAreasDraw/components/MapDraw';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';
import MapaTutorial from '../../livestock/components/addLivestock/tutorial/MapaTutorial';
import useStore from '../../../store/useStore';
import { Context } from '../../../components/Context/AuthContext';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function DialogRegisterArea({ setData }) {
  const [open, setOpen] = useState(false);
  const [drawM, setDrawM] = useState(true);
  const { aux_add, setAuxAdd } = useContext(Context);

  // const [path, setPath] = useState([]);
  // const [image_area, setImage] = useState('');
  // const [drawF, setDrawF] = useState(false);
  // const [textButon, setTextButton] = useState('Próximo');

  /*const captureImage = () => {
    var element = document.getElementById('mapDrawArea');
    html2canvas(element, {
      backgroundColor: null,
      useCORS: true,
    }).then(function (canvas) {
      var base64image = canvas.toDataURL('image/jpeg');
      setDrawM(false);
      setImage(base64image);
      setDrawF(true);
    });
  };*/

  // controla o estado de desenho ou formSave
  /*const handleDraw = (e) => {
    if (!path || path.length < 1) {
      toast.warning('Desenhe uma área de no mínimo 3 lados!');
      return;
    }
    if (drawM) {
      setTextButton('Retornar');
      captureImage();
    } else {
      setDrawM(true);
      setDrawF(false);
      setTextButton('Próximo');
    }
  };*/

  const handleClickOpen = () => {
    setOpen(true);
  };

  const usestore = useStore();
  const handleClose = (id) => {
    // setPath([]);
    setOpen(false);
    setDrawM(true);
    // setDrawF(false);
    // setImage('');
    // setTextButton('Próximo');
    if (id) {
      var area = usestore.reposAreas.getAreaMapStore('id', id)[0];
      if (area) {
        area.usedArea = '';
        setData((prevState) => ({
          ...prevState,
          areas: [...prevState.areas, area],
        }));
        setTimeout(() => {
          setAuxAdd(aux_add + 2);
        }, 300);
      }
    }
  };

  // const onSubmit = async (e) => {
  //   e.preventDefault();
  //   console.log('entrou no submit');
  // };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);

  const numberHeight = '74vh';

  return (
    <>
      <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} size="small" variant="outlined" color="success" onClick={handleClickOpen}>
        Desenhar nova área
      </Button>

      <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" fullWidth={true} maxWidth={'lg'}>
        <section className="content " style={{ overflowY: 'hidden', paddingBottom: 80 }}>
          <div className="container-fluid ">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
              <Grid container justifyContent="space-between" alignItems="center">
                {/* Ajuste da imagem à esquerda */}
                <Grid item xs={7} textAlign="left">
                  <Box sx={{ textAlign: 'center' }}>
                    <Grid container item alignItems="center" justifyContent="flex-start">
                      <img
                        src="/dist/image/movimentoteclado.png"
                        alt="movimento teclado"
                        style={{
                          width: '100%',
                          maxWidth: '300px',
                          height: 'auto',
                          transition: 'transform 0.3s ease',
                        }}
                        onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.3)')} // Aumenta a imagem em 50%
                        onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')} // Volta ao tamanho original
                      />
                    </Grid>
                  </Box>
                </Grid>

                {/* Ajuste do botão à direita */}
                <Grid item xs={5} container justifyContent="flex-end">
                  <MapaTutorial />
                </Grid>
              </Grid>
            </DialogTitle>

            <Divider />
            {isLoading ? (
              <>
                <LinearProgress color="success" />
                <Skeleton variant="rectangular" width={'100vw'} height={'80vh'} />
              </>
            ) : (
              <DialogContent style={{ padding: '2px', height: '80vh' }}>
                {!drawM || !open ? <></> : <MapDraw container={handleClose} numberHeight={numberHeight} />}
                {/*!drawM || !open ? <></> : <DrawArea path={path} setPath={setPath}/> */}
                {/*drawF ? <FormSaveArea handleClose={handleClose} path={path} image_area={image_area} setAddArea={setAddArea} /> : <></>*/}
              </DialogContent>
            )}
            <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
              <Toolbar>
                <Grid container sx={{ width: '100%' }} spacing={2}>
                  <Grid item xs={6} md={6} textAlign="left">
                    <Fab sx={{ backgroundColor: '#fff' }} color="neutral" variant="extended" size="small" onClick={handleClose} aria-label="Close">
                      <Close sx={{ mr: 1 }} /> Cancelar
                    </Fab>
                  </Grid>

                  <Grid item xs={6} md={6} textAlign="right">
                    <Typography paragraph color="#000000" variant="subtitle2" fontWeight={300}>
                      Desenhe a nova área de produção
                    </Typography>
                  </Grid>
                  {/* <div className="d-none d-lg-block">
                      <Box sx={{ textAlign: 'center' }}>
                        <Grid container xs={12} md={12} sx={{ m: -2 }} item alignItems="center" justifyContent="center">
                          <img
                            src="/dist/image/movimentoteclado.png"
                            alt="movimento teclado"
                            style={{
                              width: '100%',
                              maxWidth: '230px',
                              height: 'auto',
                              transition: 'transform 0.3s ease', // Transição suave de 0.3s para o efeito de zoom
                            }}
                            onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.5)')} // Aumenta a imagem em 20%
                            onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')} // Volta ao tamanho original
                          />
                        </Grid>
                      </Box> */}
                  {/* <List sx={{ width: '100%', bgcolor: 'background.paper', m: 0 }}>
                        <ListItem
                          key={null}
                          disableGutters
                          secondaryAction={
                            <span aria-label="comment">
                              <CheckBoxOutlineBlank style={{ color: '#2e7d32' }} />
                            </span>
                          }
                        >
                          <ListItemText primary="" secondary="Clique no mapa, crie os pontos da área e depois salve o desenho" />
                        </ListItem>
                      </List> */}
                  {/* </div> */}

                  {/* Se quiser adicionar o outro botão, você pode descomentar e ajustar assim:
    <Grid item xs={12} textAlign="left">
      <Fab color="info" variant="extended" size="small" onClick={(e) => handleDraw(e)} aria-label="add">
        {textButon}
      </Fab>
    </Grid>
    */}
                </Grid>
              </Toolbar>
            </AppBar>
          </div>
        </section>
      </Dialog>
    </>
  );
}

import { useContext, useEffect, useState } from 'react'
import { Box, Container, Paper } from '@mui/material'
import SuccessInput from '../../activities/components/FormActivities/Input/SuccessInput'
import { toast } from 'react-toastify'
import api from '../../../services/api'
import useStore from '../../../store/useStore'
import { Context } from '../../../components/Context/AuthContext'
import { useParams } from 'react-router-dom'
import useHarvestTargetReposStore from '../../../store/useHarvestTargetReposStore'
import FormTarget1 from './FormTarget1'
import FormTarget2 from './FormTarget2'
import FormTarget3Confirm from './FormTarget3Confirm'
import dayjs from 'dayjs'
import FormTarget3 from './FormTarget3'
import useHarvestTargetCostReposStore from '../../../store/useHarvestTargetCostReposStore'

export default function MultiStepTargetHarvestGeneral({ areas, handleClose }) {
  console.log('MultiStepTargetHarvestGeneral')
  console.log('areas')
  console.log(areas)

  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // errors.chave define true (property.chave não validada) ou false
  const [errors, setErrors] = useState({})
  // gaurdar o array de erros de cada chave
  const [formError, setFormError] = useState({})
  const usestore = useStore()

  // pegar o harvest com a cultura atual
  // / seta as opções de safras
  //const optionsHarvests = usestore.reposHarvest.listHarvest;
  const initHarvests = usestore.reposHarvest.harvestRepos
  //console.log(initHarvests)
  //const currentHarvests = usestore.reposHarvest.harvestRepos;
  const harvestCultureCurrent = usestore.reposHarvest.getHarvestStore('id', initHarvests.id)[0]
  console.log('harvestCultureCurrent1')
  console.log(harvestCultureCurrent)

  const property_id = localStorage.getItem('Property') || 1
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'))
  const { idFarm } = useParams()

  //const id_areamapfilter = areas.filter

  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: harvestCultureCurrent.id_property,
    id_harvest: harvestCultureCurrent.id,
    id_cultivation: harvestCultureCurrent.id_cultivation,
    id_group: harvestCultureCurrent.id_group,
    // group_name: harvestCurrent[0].group_name,
    id_areamap: areas[0].data.id,
    cultivation_name: harvestCultureCurrent.cultivation_name,
    //startDate: harvestCurrent[0].data_inicial,
    // endDate: harvestCurrent.data_fim,
    date_start: dayjs(harvestCultureCurrent.date_start).format('YYYY-MM-DD'),
    date_prev_end: dayjs(harvestCultureCurrent.date_prev_end).format('YYYY-MM-DD'),
    expectedYield: '',
    unit: 'sc 60kg',
    cost: '',
    valueCost: 'pt-BR',
    unitCost: 'sc 60kg',
    //areas[0] default
    areaSelection: areas[0] || '',
    areas: areas,
    //area: calcularSomatorioUsedArea(harvestCurrent[0].areas),
    area: areas[0].area || '',
    measure: areas[0].data.measure,
    observation: '',
    isDeleted: 0,
    isActiveTargetHarvest: 0,
    data_cost: {
      insert: false,
      id_user: userCurrent.id,
      id_property: harvestCultureCurrent.id_property,
      id_harvest: harvestCultureCurrent.id,
      id_target: harvestCultureCurrent?.id_target,
      expectedProduction: '',
      unit: '',
      //saleUnitValue: formatNumber(planRepos.cost) || "",
      saleUnitValue: '',
      valueSale: 'pt-BR',
      unitSale: 'sc 60kg',
      percentageProfit: '',
      // area: calcularSomatorioUsedArea(harvestCurrent[0].areas),
      area: areas[0].area || '',
      measure: areas[0].data.measure,
      id_areamap: areas[0].data.id,
      observation: '',
      isDeleted: 0,
    },
  })

  console.log('data')
  console.log(data)

  const [activeStep, setActiveStep] = useState(0)
  const [date, setDate] = useState('')
  const [text, setText] = useState('')
  const [selection, setSelection] = useState('')

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleDateChange = (event) => {
    setDate(event.target.value)
  }

  const handleTextChange = (event) => {
    setText(event.target.value)
  }

  const handleSelectionChange = (event) => {
    setSelection(event.target.value)
  }

  /// lista o manejo de pastagem para identificar id_areamap (onde está a criação)
  // const reposPastureManagement = useActivityPastureManagementReposStore(state => state)
  // console.log(reposPastureManagement)
  // const reposlistPastureManagement = reposPastureManagement.listPastureManagement

  // atualizar o repos no submmit
  const reposHarvestTargetPlanning = useHarvestTargetReposStore((state) => state)
  const reposHarvestTargetCost = useHarvestTargetCostReposStore((state) => state)

  const { aux_add, setAuxAdd } = useContext(Context)

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6']

  const onSubmit = async () => {
    ///e.preventDefault();
    // console.log('onSubmit')
    // console.log(data)
    setFormError({})
    let errors = {}
    let formOk = true

    // if (data.nameBatch.length < 5) {
    //   errors.nameBatch = true;
    //   formOk = false;
    //   // } else if (property.address.length < 3) {
    // } else if (data.areas.length === 0) {
    //   errors.area = true;
    //   formOk = false;
    // }

    const header = { header: { 'Content-Type': 'application/json' } }

    if (formOk) {
      // console.log('submit cost')
      const res = await api
        .post('/planning/targetHarvest', data, header)
        .then((response) => {
          // console.log(response.data)
          if (response.data.isSuccess) {
            //setIsLoading(true);
            toast.success('Meta criada com sucesso!')
            reposHarvestTargetPlanning.fetch({ id_farm: idFarm }).then((res) => {
              //setAuxAdd(aux_add + 1);
              // setTimeout(() => {
              //   handleClose()
              // }, 200);
            })
            reposHarvestTargetCost.fetch({ id_farm: idFarm }).then((res) => {
              setAuxAdd(aux_add + 1)
              setTimeout(() => {
                handleClose()
              }, 200)
            })
            // var updt_lv = reposLivestocks.updateLivestockStore(id, "all", data);
            // setTimeout(() => {
            //   setAuxAdd(aux_add + 1);
            //   handleClose();
            // }, updt_lv.length);
          }
        })
        .catch((error) => {
          console.log('error')
          console.log(error)
        })
    } else {
      // escreve mensagem de erro para cliente
      toast.error('Por favor, preencha os campos obrigatórios')
      setFormError(errors)
      //return false;
    }
  }

  const getStepContent = (step) => {
    //console.log(step);
    switch (step) {
      case 0:
        return <FormTarget1 areas={areas} data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />
      case 1:
        return <FormTarget2 areas={areas} data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />
      // case 2:
      //   return <FormTarget3 areas={areas} data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />;
      case 2:
        return <FormTarget3Confirm data={data} onSubmit={onSubmit} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />
      default:
        return 'Não existente'
    }
  }

  //console.log("2-RENDER");
  return (
    <>
      <Box sx={{ width: '100%' }}>
        {/* // se o activeStep que inci-ase com zero === [0,1,2,3] array de step*/}
        {activeStep === steps.length ? (
          // Chamada do componente final do step (activeStep) para animação
          <Container component="main" maxWidth="md" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessInput handleClose={handleClose} data={data} />
            </Paper>
          </Container>
        ) : (
          <>
            {/* // Chamada dos componentes setp 1, setp 2....  */}
            {getStepContent(activeStep)}
          </>
        )}
      </Box>
    </>
  )
}

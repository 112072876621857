import { useRef, useState } from 'react';
import { Button, Box, Grid, Typography, Stack, IconButton, Card } from '@mui/material';
import FooterModal from '../../../components/Footer/FooterModal';
import CurrencyInputReal from '../../../components/Fields/CurrencyInputReal';
import { Category, HighlightOff, Queue } from '@mui/icons-material';
import GenericValueField from '../../../components/Fields/GenericValueField';
import AutocompleteGeneric from '../../../components/Fields/AutocompletGeneric';
import ProductRegistrar from '../../products/components/ProductRegistrar/ProductRegistrar';
import Typewriter from '../../../components/Typewriter/Typewriter';

const getHelperText = (cost) => {
  return (
    <>
      Selecione o produto desejado
      <br />
      Valor unitário: {cost || '-'}
    </>
  );
};

export default function ResourcesStepFormTabInputs({ data, obj_fn }) {
  const optionsProducts = obj_fn.usestore.reposProducts.listProducts;

  // Cria uma referência para o formulário
  const formRef = useRef(null);

  const [optionsLocations, setLocations] = useState(data.product?.location || []);
  const [location_selected, setLocation] = useState(data.product?.location_selected || null);

  // Ensure data.resources is defined, initialize if not present
  const products = data.resources?.products || [];

  const opt_products = {
    multiple: false,
    name: 'product',
    field: 'product',
    label: 'Produto *',
    values: optionsProducts,
    variant: 'standard',
    helperText: '',
    listHeight: 225,
  };

  const opt_products_local = {
    multiple: false,
    name: 'product_location_',
    field: 'product_location',
    label: 'Local de estoque',
    values: optionsLocations,
    variant: 'standard',
    helperText: (
      <>
        Local onde o produto se encontra
        <br />
        Qtd. em estoque: {location_selected?.quanty || '-'} {data.product?.unit_sigla ? ' (' + data.product?.unit_sigla + ')' : ''}
      </>
    ),
    listHeight: 225,
    disabled: !data.product?.id,
    disableClearable: true,
  };

  const handleAdd = () => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      resources: {
        ...prevState.resources,
        products: [
          ...(prevState.resources?.products || []),
          {
            id: prevState.resources?.products?.length + 1 || 1,
            product: { id: null, location: [], stock_cost: '', unit_sigla: '' },
            location_selected: { id: null, quanty: '', quant_utilizada: '', value: '' },
          },
        ],
      },
    }));
    // Rola a tela até o formulário de despesas
    setTimeout(() => {
      formRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100); // Pequeno atraso para garantir que o novo elemento seja renderizado
  };

  const handleChangeSelect = (index, newValue) => {
    obj_fn.setData((prevState) => {
      const updatedProducts = [...(prevState.resources?.products || [])];
      if (newValue) {
        updatedProducts[index].product = newValue;
        updatedProducts[index].location_selected = newValue.location.length > 0 ? newValue.location[0] : { id: null, quanty: '', quant_utilizada: '', value: '' };
      } else {
        updatedProducts[index].product = { id: null, location: [], stock_cost: '', unit_sigla: '' };
        updatedProducts[index].location_selected = { id: null, quanty: '', quant_utilizada: '', value: '' };
      }

      return { ...prevState, resources: { ...prevState.resources, products: updatedProducts } };
    });
  };

  const handleChangeLocal = (index, newValue) => {
    obj_fn.setData((prevState) => {
      const updatedProducts = [...(prevState.resources?.products || [])];
      updatedProducts[index].location_selected = newValue;

      return { ...prevState, resources: { ...prevState.resources, products: updatedProducts } };
    });
  };

  const handleChangeQtd = (index, event) => {
    const newValue = parseFloat(event.target.value || '0');
    obj_fn.setData((prevState) => {
      const updatedProducts = [...(prevState.resources?.products || [])];
      const selectedProduct = updatedProducts[index];
      const aux_local = { ...selectedProduct.location_selected, quant_utilizada: newValue, value: newValue * (selectedProduct.product.stock_cost || 0) };
      selectedProduct.location_selected = aux_local;

      return { ...prevState, resources: { ...prevState.resources, products: updatedProducts } };
    });
  };

  const handleRmv = (index) => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      resources: {
        ...prevState.resources,
        products: prevState.resources.products.filter((_, i) => i !== index),
      },
    }));
  };

  const validQtd = (product, type) => {
    const isValidQuantity =
      Number.isFinite(product.location_selected?.quant_utilizada) &&
      Number.isFinite(product.location_selected?.quanty) &&
      product.location_selected?.quanty >= product.location_selected?.quant_utilizada;

    if (type === 'color') {
      return isValidQuantity ? 'success' : 'error';
    } else {
      return (
        <>
          Qtd. a ser utilizada
          {!isValidQuantity && (
            <>
              <br />
              <span style={{ color: 'success' }}>Não pode ser maior do que a Quantidade em estoque</span>
            </>
          )}
        </>
      );
    }
  };

  const getHelperText = (stockCost) => {
    return stockCost ? `Custo do produto: ${stockCost}` : 'Custo não disponível';
  };

  const isError = () => {
    let isError = false;
    if (!data.resources.products || !data.resources.products?.length) {
      isError = true;
    }
    data.products?.forEach((prod) => {
      if (!prod.product?.id || !prod?.location_selected?.id || !prod?.location_selected?.quant_utilizada || !prod?.location_selected?.value) {
        isError = true;
      }
    });
    return isError;
  };

  const isErrorAddButton = () => {
    return false;
  };

  const returnError = () => {
    return false;
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Box sx={{ ml: 2, display: 'flex' }}>
            <Category />
            <Stack spacing={0.5} sx={{ mt: 0, ml: 1 }}>
              <Typography fontWeight={700}>Lista de Insumos</Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box display="flex" justifyContent="flex-end">
          <ProductRegistrar />
        </Box>
      </Grid>
      <Grid item xs={12} md={12} alignItems="center" sx={{ mb: 1 }}>
        <Typography sx={{ textAlign: 'start' }} variant="subtitle1" component="div">
          Olá produtor!
        </Typography>
        <Typewriter text="Cliquem em ADICIONAR USO DE INSUMO" variant={'subtitle1'} color={'#2e7d32'} />
      </Grid>
      <div ref={formRef}>
        {products.map((product, index) => (
          <div key={product.id}>
            <Card sx={{ p: 1, mt: 2 }} key={index}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <Grid container spacing={3} sx={{ mt: 1 }}>
                    <Box sx={{ ml: 3, display: 'flex' }}>
                      <Category />
                      <Stack spacing={0.5} sx={{ mt: 0, ml: 1 }}>
                        <Typography fontWeight={700}>Insumo {index + 1}</Typography>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <IconButton variant="contained" color="error" onClick={() => handleRmv(index)} title="Remover">
                      <HighlightOff />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <AutocompleteGeneric
                    value={product.product.id ? product.product : null}
                    options={{ ...opt_products, helperText: getHelperText(product.product.stock_cost) }}
                    // options={opt_products}
                    handleChange={(field, newValue) => handleChangeSelect(index, newValue)}
                  />
                </Grid>
                <Grid item xs={12} sm={4} sx={{ mt: 0 }}>
                  <AutocompleteGeneric
                    value={product.location_selected.id ? product.location_selected : null}
                    options={{ ...opt_products_local, values: product.product.location }}
                    handleChange={(field, newValue) => handleChangeLocal(index, newValue)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mt: 0, mb: 0 }}>
                <Grid item xs={6} sm={4}>
                  <CurrencyInputReal
                    name={`quant_stq_${product.product.id}_${product.location_selected.id}`}
                    label="Qtd. em estoque"
                    variant="outlined"
                    size="small"
                    value={product.location_selected.quanty || '-'}
                    adornment="quantidade"
                    endAdornment={product.product.unit_sigla ? ` (${product.product.unit_sigla})` : ''}
                    helperText="Disponível no estoque"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  {/* <CurrencyInputReal
                name={`quant_uti_${product.product.id}_${product.location_selected.id}`}
                label="Quantidade utilizada"
                variant="outlined"
                value={product.location_selected.quant_utilizada}
                adornment="quantidade"
                endAdornment={product.product.unit_sigla ? ` (${product.product.unit_sigla})` : ''}
                onChange={(event) => handleChangeQtd(index, event)}
                helperText={validQtd(product, 'helperText')}
                color={validQtd(product, 'color')}
                disabled={!product.location_selected.id}
              /> */}
                  <GenericValueField
                    handleChange={(event) => handleChangeQtd(index, event)}
                    options={{
                      required: true,
                      id: `quant_uti_${product.product.id}_${product.location_selected.id}`,
                      name: `quant_uti_${product.product.id}_${product.location_selected.id}`,
                      label: 'Quantidade utilizada',
                      value: product.location_selected.quant_utilizada,
                      variant: 'outlined',
                      helperText: validQtd(product, 'helperText'),
                      color: validQtd(product, 'color'),
                      endAdornment: true,
                      prefix: product.product.unit_sigla ? ` (${product.product.unit_sigla})` : '',
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <CurrencyInputReal
                    name={`prod_value_${product.product.id}_${product.location_selected.id}`}
                    label="Custo"
                    variant="outlined"
                    size="small"
                    value={product.location_selected.value || '-'}
                    helperText="Custo do produto"
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </Card>
          </div>
        ))}
      </div>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={12}>
          <Button variant="contained" color="success" onClick={handleAdd} disabled={isErrorAddButton()}>
            <Queue sx={{ mr: 1 }} /> {products.length > 0 ? 'Adicionar outro insumo (produto)' : 'Adicionar uso de insumo (produto)'}
          </Button>
        </Grid>
      </Grid>
      <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  );
}

import React, { useEffect } from 'react';
import { Chip, Paper, Typography } from '@mui/material';
import Loading from '../../../../components/Loader/Loading';

export default function ChipsArrayAreaMaps({ data, setData, isLoading, setIsLoading }) {
  useEffect(() => {
    // console.info('ChipsArrayAreaMaps.');
  }, [data.areas])

  const handleDelete = (e, chipToDelete) => {
    // console.info('Delete area.');
    const newdataArea = data.areas.filter((chip) => chip.id !== chipToDelete.id);
    setData(prevState => ({ ...prevState, "areas": newdataArea }));
  };

  setIsLoading(false);

  return (<>
    {isLoading ? <Loading /> :
      <Paper sx={{ padding: 0.5 }} component="ul">
        {data.areas && data.areas.map((dataItem, index) => {
          return (<>
            <Chip
              key={dataItem.id + "_" + index}
              variant="outlined"
              label={(
                <Typography key={"T_" + dataItem.id + "_" + index}> 
                  {dataItem.label || dataItem.name} ({dataItem.usedArea || dataItem.area} {dataItem.measure})
                </Typography>
              )}
              onDelete={e => handleDelete(e, dataItem)}
              sx={{ padding: 0.5, ml: 1 }}
            />
          </>);
        })}
      </Paper>
    }
  </>);
}

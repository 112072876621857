import * as React from 'react';
import Box from '@mui/material/Box';
import { ButtonBase, Card, CardActions, CardContent, CardHeader, CardMedia, Container, Divider, Grid, IconButton, Paper, Skeleton, Stack, Typography } from '@mui/material';
import Typewriter from '../../../components/Typewriter/Typewriter';
import { useState } from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import useStore from '../../../store/useStore';
import TimelineHorizPhase from '../../planningHarvest/components/GraphPlan/TimelineHorizPhase';
import TableProductionHarvestStorage from './TableProductionHarvestStorage';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { grey } from '@mui/material/colors';
import AddHomeIcon from '@mui/icons-material/AddHome';
import BalanceIcon from '@mui/icons-material/Balance';
import useHarvestTargetReposStore from '../../../store/useHarvestTargetReposStore';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

export default function CardProductionStorage({ area }) {
  //const classes = useStyles();
  //const [isLoading, f] = useState(true);
  const [isLoadingText, setIsLoadingText] = useState(false);
  const usestore = useStore();

  // setTimeout(() => {
  //   setIsLoading(false);
  // }, 1000);

  setTimeout(() => {
    setIsLoadingText(true);
  }, 3000);

  console.log(area);
  // contem a lista de metas
  console.log(area.target);

  const target_active = (area?.target).filter((item) => {
    if (item.isActiveTargetHarvest) {
      return item;
    }
  })[0];

  //area ativa tem id_areamap
  console.log(target_active);

  // const reposPlanning = useHarvestTargetReposStore((state) => state);

  // console.log(reposPlanning.listPlanning);
  // console.log(reposPlanning.planningRepos);
  // const planRepos = reposPlanning.getPlanningStore('id', area?.id);
  // console.log(planRepos[0]);

  //lista de colheitas
  const listActivityHarvesting = usestore.reposActivityHarvesting.listActivityHarvesting;
  console.log(listActivityHarvesting);
  console.log(listActivityHarvesting);

  const listActivityHarvestingFiltered = listActivityHarvesting.flatMap((h) => h.areas).filter((area) => target_active.id_areamap === area.id_area);
  console.log(listActivityHarvestingFiltered);

  console.log(listActivityHarvestingFiltered[0].products[0].id_patrimony);

  const id_patrimony = listActivityHarvestingFiltered[0].products[0].id_patrimony;

  const currentHarvestPatrimony = usestore.reposPatrimony.getPatrimonyStore('id', id_patrimony)[0];
  console.log('currentHarvestPatrimony');
  console.log(currentHarvestPatrimony);

  // const totalProductQuant = harvestReady
  //   .flatMap((h) => h.areas) // Flatten the areas arrays into a single array
  //   .filter((area) => area.id_area === 3) // Filter areas with id_area equal to 3
  //   .flatMap((area) => area.products) // Flatten the products arrays into a single array
  //   .reduce((sum, product) => sum + product.quant, 0); // Sum the quant values
  // console.log(totalProductQuant);
  // const [harvestReadyQuant, setharvestReadyQuant] = useState(totalProductQuant);

  return (
    <>
      {false === true ? (
        <>
          <Card sx={{ mt: 0 }}>
            <Skeleton variant="rectangular" width="100%" height={110} />
          </Card>
          <Card sx={{ mt: 0.5 }}>
            <Skeleton variant="rectangular" width="100%" height={155} />
          </Card>
        </>
      ) : (
        <>
          <Card sx={{ mt: 1 }}>
            <CardHeader
              title={
                <>
                  <div className="d-lg-none">
                    <Box sx={{ display: 'flex', mb: 1 }}>
                      <Typography sx={{ fontSize: 18 }}>Armazenamento da colheita</Typography>
                    </Box>
                    <Box sx={{ minHeight: 110 }}>
                      <Typography style={{ color: '#2e7d32' }}>{isLoadingText && <Typewriter tam={110} minHeight={0} text="Visualize o local de armazenamento da sua colheita." />}</Typography>
                    </Box>
                  </div>

                  <div className="d-none d-lg-block">
                    <Box sx={{ display: 'flex', mb: 1 }}>
                      <Typography variant="h6" color="textPrimary">
                        Armazenamento da colheita
                      </Typography>
                    </Box>
                    <Box sx={{ minHeight: 30 }}>
                      <Typography style={{ color: '#2e7d32' }}>{isLoadingText && <Typewriter tam={30} minHeight={0} text="Visualize o local de armazenamento da sua colheita." />}</Typography>
                    </Box>
                  </div>
                </>
              }
            ></CardHeader>
            <Divider />
          </Card>

          <Card sx={{ mt: 1, mb: 5 }}>
            <Container component="main" maxWidth="">
              {/* <Paper variant="" sx={{ my: { xs: 3, md: 1 }, p: { xs: 1, md: 1 } }}>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                  <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12} md={12}>
                      <Box mt={0}>
                        <p>armazenamento</p>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper> */}
              <Grid container spacing={2} sx={{ mb: 2, mt: 0 }}>
                {/* <Grid item xs={6} md={6}>
                  <Typography variant="h6" gutterBottom>
                     Registro {nameAtivity.tipo_repos === "Safras" ? "da Colheita" : "da Produção"} 
                    Armazenamento
                  </Typography>
                </Grid> */}
                <Grid item xs={6} md={6} container justifyContent="flex-end">
                  {/* <ButtonRegisterProductionHarvest /> */}
                </Grid>
                {listActivityHarvestingFiltered.map((item) => (
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader
                        avatar={<AddHomeIcon style={{ height: '50px', width: '50px' }} />}
                        action={
                          <IconButton aria-label="settings">
                            <MoreVertIcon />
                          </IconButton>
                        }
                        title={currentHarvestPatrimony.name}
                        subheader={
                          <>
                            <Stack spacing={0.5}>
                              <Typography variant="body2" color="text.secondary">
                                Propriedade
                              </Typography>
                              {/* <Typography variant="body2" color="text.secondary">
                              15 horas trabalhadas
                            </Typography> */}
                            </Stack>
                          </>
                        }
                      />
                      <CardContent>
                        {item.products.map((product) => (
                          <Grid item xs={12}>
                            <TableProductionHarvestStorage cultivation_name={target_active.cultivation_name} product={product} />
                          </Grid>
                        ))}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Card>
                    <CardActions disableSpacing>
                      <Stack spacing={0.5}>
                        {/* <Typography variant="body2" color="text.secondary">
                          <AccessAlarmsIcon style={{ height: "18px", width: "18px" }} sx={{ color: grey[500] }} /> Trabalhados(15 horas)
                        </Typography>
                        <Typography fontWeight={700} variant="h6">
                          10.000,00 /kg
                        </Typography> */}
                      </Stack>
                      <Stack spacing={0.5} sx={{ ml: 'auto' }}>
                        <Typography variant="body2" color="text.secondary">
                          <BalanceIcon style={{ height: '18px', width: '18px' }} sx={{ color: grey[500] }} /> Total
                        </Typography>
                        <Typography fontWeight={700} variant="h6">
                          10.000,00 /kg
                        </Typography>
                      </Stack>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </Card>
        </>
      )}
    </>
  );
}

//https://aegro.com.br/termos-de-uso/

import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { AppBar, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Container, Grid, IconButton, Paper, Stack, Toolbar, Typography } from "@mui/material";
import { Span } from "../../../components/Typography";
import ConfirmListAreasMapsLivestockImagens from "../../livestock/components/addLivestock/ConfirmListAreasMapsLivestockImagens";
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import FormInputFooter from "../../activities/components/FormActivities/Input/FormInputFooter";
import { useEffect } from "react";
import { green, grey, orange } from "@mui/material/colors";
import FormInputStep6ConfirmRadioButtonProgress from '../../activities/components/FormActivities/Input/FormInputStep6ConfirmRadioButtonProgress'
import FlagIcon from '@mui/icons-material/Flag';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventIcon from '@mui/icons-material/Event';
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function FormTarget3Confirm({ onSubmit, data, setData, handleNext, handleBack, activeStep, steps, handleClose }) {

  console.log(data)
  const handleSubmit = () => {
    console.log("onSubmit")
    onSubmit()
    //handleNext();
  };

  // ativar o botao retornar
  const returnError = () => {
    return false;
  };

  const isError = () => {

    return false;
  };

  useEffect(() => {
    // retirando a imagem da área para realizar o post
    console.log("console.log(data.areas)")
    console.log(data.areas)
    // for (const area of data.areas) {
    //   console.log(area)
    //   area.image = ""
    // }

  }, [data])

  console.log("data")
  console.log(data)
  return (
    <>
      <Container maxWidth="sm" sx={{ mb: 10 }}>
        {/* <Grid item container alignItems="center" justifyContent="center" >
          <Grid item sx={{ mt: 1, height: 120 }} >
            <img
              style={{ maxHeight: 120 }}
              src="/dist/image/entrada.png"
              alt="image"
              class="img-fluid"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{}} >
          <Grid item xs={12} md={12} style={{ justifyContent: 'center' }}>
            <FormInputStep6ConfirmRadioButtonProgress data={data} setData={setData} />
          </Grid>
        </Grid> */}
        <Grid container spacing={1} sx={{}}>
          <Grid item xs={12} md={12}>
            <ListItem style={{ display: 'flex', justifyContent: 'center' }}>
              <ListItemText primary="Cultivo:" secondary={
                <Paper sx={{ p: 1, backgroundColor: green[50] }}>
                  <Typography noWrap={false} sx={{ wordBreak: 'break-all' }} variant='h6'>
                    {data.cultivation_name.toUpperCase()}
                  </Typography>
                </Paper>}
              />
            </ListItem>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2} sx={{ mt: 0, align: 'center' }}>
          <Grid item xs={12} md={12}>
            {/* {area.target[0]} */}
            <Card sx={{}}>
              <CardHeader
                subheader={`Meta de Produção`}
              />
            </Card>
            <Card sx={{}}>
              <CardHeader
                avatar={<FlagIcon style={{ height: "50px", width: "50px" }} />}
                // action={
                //   <IconButton aria-label="settings">
                //     <MoreVertIcon />
                //   </IconButton>
                // }
                title={data.cultivation_name.toUpperCase()}
              // subheader={
              //   <>
              //     <Stack spacing={0.5}>
              //       <Typography variant="body2" color="text.secondary">
              //         {area.title}
              //       </Typography>

              //     </Stack>
              //   </>
              // }
              />
              <CardContent>
                <Grid item xs={12}>
                  Meta: <strong>{data.expectedYield}  {data.unit}</strong>
                </Grid>
                {data.data_cost.percentageProfit !== "" &&
                  <Grid item xs={12}>
                    Lucratividade: <strong>{data.data_cost.percentageProfit} %</strong>
                  </Grid>
                }
              </CardContent>
              <CardActions disableSpacing>
                <Stack spacing={0.5}>
                  <Typography variant="body2" color="text.secondary">
                    <CalendarMonthIcon style={{ height: "18px", width: "18px" }} sx={{ color: grey[500] }} /> Data Inicial
                  </Typography>
                  <Typography fontWeight={700} variant="h6">
                    {dayjs(data.date_start).format('DD/MM/YYYY')}
                  </Typography>
                </Stack>
                <Stack spacing={0.5} sx={{ ml: "auto" }}>
                  <Typography variant="body2" color="text.secondary">
                    <EventIcon style={{ height: "18px", width: "18px" }} sx={{ color: grey[500] }} /> Data Final de Análise
                  </Typography>
                  <Typography fontWeight={700} variant="h6">
                    {dayjs(data.date_prev_exit).format('DD/MM/YYYY')}
                  </Typography>
                </Stack>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={12}>
            <Card sx={{ m: 1 }}>
              <CardMedia component="img" height="250" image={data.areaSelection.image} alt="Imagem" />
              <CardActionArea>
                <CardActions >
                  <p>{data.areaSelection.name} </p>
                  <p>Área utilizada: {data.area}</p>
                </CardActions>
              </CardActionArea>
            </Card>
          </Grid>

        </Grid>

        <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
              <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                <Span>cancelar</Span>
              </Button>
            </Box>
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
              <Button onClick={handleBack} color="success" sx={{ marginRight: 2 }} disabled={returnError()}>
                Retornar
              </Button>
              {/* O botão Confirmar e Salvar, leva para tela successo */}
              <Button color="success" variant="contained" type="submit" onClick={handleSubmit}>
                Salvar
              </Button>
            </Box>

          </Toolbar>
        </AppBar>
      </Container>
    </>
  );
}

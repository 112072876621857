import * as React from 'react'
import { Box, CardMedia, Grid, ListItem, ListItemText, Stack, TextareaAutosize, Typography } from '@mui/material';
import { Input, Map, Notes } from '@mui/icons-material';
import dayjs from 'dayjs';
import FooterModal from '../../../../../components/Footer/FooterModal';
import ChartBarHActvSoul from '../../../../../components/Charts/ChartBarHActvSoul';

export default function FormSoilPreparationStep4({ data, obj_fn }) {
  // const [showTextFase, setShowTextFase] = useState(false);
  // setTimeout(() => {
  //   setShowTextFase(true);
  // }, 500);

  // const para validar o button continuar
  const isError = () => {
    return false;
  };

  const returnError = () => {
    return false;
  };

  const handleChange = (event) => {
    obj_fn.setData((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  };
  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        {/* dados gerais */}
        <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
          <Input />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Datas e progresso do plantio</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 0, mt: -3 }}>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Data de início" secondary={dayjs(data.date_start).format('DD/MM/YYYY')} />
            </ListItem>
          </Grid>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Data de término" secondary={dayjs(data.date_prev_end).format('DD/MM/YYYY')} />
            </ListItem>
          </Grid>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Progresso" secondary={data.progress} />
            </ListItem>
          </Grid>
        </Grid>

        {/* Área(s) de plantio */}
        <Grid container spacing={3} sx={{ mb: 0, mt: 1 }}>
          <Map />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Área(s) de preparo do solo</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={4} sx={{ mb: 1, mt: -2 }}>
          {data.areas?.map((area) => (
            <>
              <Grid item key={'G1_' + area.id} xs={12} sm={12}>
                <>
                  <Grid item xs={12} md={12} sx={{ mt: 0 }} alignItems="center">
                    <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
                      <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                        <CardMedia key={'CM_' + area.id} component="img" sx={{ height: 260 }} image={area.url} alt={area.name} />
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                        <Grid container spacing={3} sx={{ mb: 0, mt: -2 }}>
                          <Grid item xs={12} md={12} sx={{ mt: 1 }}>
                            <Grid item key={'G2_' + area.id} xs={12} sm={12} sx={{ ml: 1.5 }}>
                              <Typography variant="subtitle1" gutterBottom>
                                Área: <strong>{area.name}</strong>
                              </Typography>
                              <Typography variant="subtitle2" gutterBottom>
                                <i className="fa fa-map text-success">
                                  {' '}
                                  Totalidade: {area.area} ({area.measure})
                                </i>
                              </Typography>
                              <Typography variant="subtitle1" gutterBottom>
                                Área de preparo do solo: <strong>{area.usedArea || area.area}</strong> ({area.measure})
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={12} sx={{ mt: -2 }}>
                            <ChartBarHActvSoul usedArea={area?.usedArea || area?.area} areaTotal={area?.area} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </>
          ))}
        </Grid>

        <Grid container spacing={3} sx={{ mb: 0, mt: 2 }}>
          <Notes />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
                {' '}
                Descrição/observações da atividade de preparo do solo
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 1, mt: 0 }}>
          <Grid item xs={12} sm={12}>
            <TextareaAutosize
              minRows={3} // Customize the initial number of rows
              maxRows={10} // Customize the maximum number of rows
              id="description"
              name="description"
              placeholder="Informações auxiliares para o preparo do solo"
              value={data.observations}
              onChange={handleChange}
              onBlur={(e) => handleChange(e, true)}
              style={{ width: '100%', padding: '8px' }}
            />
          </Grid>
        </Grid>

        {/* Descrições */}
        {/* <Grid container spacing={3} sx={{ mb: 2, mt: 2 }}>
          <Box sx={{ mb: -1, ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>
                <Notes sx={{ mr: 1 }} />
                Descrição geral do plantio
              </Typography>
            </Stack>
          </Box>
          <Grid item xs={12} sm={12}>
            <ListItem>
              <ListItemText primary="" secondary={data.description} />
            </ListItem>
          </Grid>
        </Grid> */}
      </Grid>

      <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  );
}

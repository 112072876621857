import React from "react";
import MapDraw from "./components/MapDraw";

export default function MapsAreasDrawContent() {
  //paths && paths.length >1
  console.log('MapsAreasDrawContent')
  return (
    <>
      <div >
        <div style={{ position: "absolute", minHeight: "100vh", overflow: "hidden" }}>
          <MapDraw />
        </div>
      </div>
    </>

  );
}

import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import { Box, Card, CardHeader, Chip, Divider, Grid, IconButton, Menu, MenuItem, Paper, Stack, Tooltip, Typography, } from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import { MoreVert, Wysiwyg } from '@mui/icons-material'
import useStore from '../../store/useStore'
import api from '../../services/api'
import { Context } from '../../components/Context/AuthContext'
import TableBuySalesOrderContent from './TableBuySalesOrderContent'

const getColumns = (type) => {
  var array = [
    { id: 'id', label: 'Num', minWidth: 50 },
    { id: 'date', label: 'Data do pedido', minWidth: 100 },
    {
      id: 'person',
      label: !type || type === 'expense' ? 'Fornecedor' : 'Cliente',
      minWidth: 100,
      //align: 'right',
      //format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'amount',
      label: 'Valor (R$)',
      minWidth: 120,
      align: 'right',
      format: (value) => value.toFixed(2),
    },
    {
      id: 'situation',
      label: 'Situação',
      minWidth: 120,
      align: 'right',
      format: (value) => value.toFixed(2),
    },
    {
      id: 'releases',
      label: '', // Nomeie a nova coluna como 'Ações'
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'actions',
      label: 'Ações', // Nomeie a nova coluna como 'Ações'
      minWidth: 100,
      align: 'center',
    },
  ]
  return array
}

// Função para criar dados com valores iniciais para "Ações" (ícone)
function createDataRow(id, date, person, amount, situation, payed, inventory, row) {
  return { id, date, person, amount, situation, payed, inventory, row }
}

const formatRows = (list) => {
  var array = []
  for (var i = 0, tam = list.length; i < tam; i++) {
    array.push(createDataRow(
      list[i].id,
      list[i].date_entry_f,
      list[i].person,
      list[i].value,
      list[i].status,
      list[i].id_trans_pay ? true : false,
      list[i].id_trans_inv ? true : false,
      list[i]
    ))
  }
  return array
}

export default function TableBuySalesOrder({ type }) {
  const usestore = useStore()
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'))
  const property_id = localStorage.getItem('Property') || 1
  const { aux_add, setAuxAdd } = useContext(Context)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  // Gerenciamento do estado do menu
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const [columns, setColumns] = useState([])
  const [rows, setRows] = useState([])

  useEffect(() => {
    let setrows = []
    if (type === 'income') {
      setrows = formatRows(usestore.reposSales.listSale)
    } else if (type === 'expense') {
      setrows = formatRows(usestore.reposBuys.listBuy)
    }
    setColumns(getColumns(type))
    setRows(setrows)
  }, [aux_add])

  // Funções para lidar com cliques nos chips
  const handleFinanceClick = async (row) => {
    /*row.id_user = Number(userCurrent.id);
    row.id_property = Number(property_id);
    row.date_entry = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
    
    const header = { header: { "Content-Type": "application/json" } };
    const res = await api.post(`/buy/payment`, row, header) // buy ou sales
      .then((response) => {
        //console.log(response.data);
        if (response.data.isSuccess) {
          toast.success("Estoque da compra atualizado com sucesso!");
          usestore.reposFinances.fetchT({ 'id_property': property_id });
          usestore.reposBuys.fetch({ 'id_property': property_id }).then((res) => {
            setAuxAdd(aux_add + 1);
          });
        }
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        toast.error("Erro ao atualizar compra, tente novamente mais tarde!");
      });*/
  }

  const handleStockClick = async (row) => {
    row.id_user = Number(userCurrent.id)
    row.id_property = Number(property_id)
    row.date_entry = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')

    const header = { header: { 'Content-Type': 'application/json' } }

    let url = (type === 'income' ? '/sales' : '/buy') + '/inventory'
    await api.post(url, row, header) // buy ou sales
      .then((response) => {
        //console.log(response.data);
        if (response.data.isSuccess) {
          usestore.reposFinances.fetchT({ id_property: property_id })

          let reposFetch
          if (type === 'income') {
            reposFetch = usestore.reposSales
          } else {
            reposFetch = usestore.reposBuys
          }
          toast.success('Estoque da ' + (type === 'income' ? 'venda' : 'compra') + ' atualizado com sucesso!')
          reposFetch.fetch({ id_property: property_id }).then((res) => {
            setAuxAdd(aux_add + 1)
          })
        }
      })
      .catch((error) => {
        console.log('error')
        console.log(error)
        toast.error('Erro ao atualizar compra, tente novamente mais tarde!')
      })
  }

  const [openDialog, setOpenDialog] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)

  const handleDialogOpen = (row) => {
    setSelectedRow(row)
    setOpenDialog(true)
    handleMenuClose()
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
    setSelectedRow(null)
  }

  let text_type = (type === 'expense' ? "compra" : "venda")
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Box sx={{ width: '100%' }}>
        {/* {listPlanning.length === 0 && <TableProductionCardEmpty title={"Criação"} />} */}
        <Card sx={{ mt: 1 }}>
          <CardHeader
            title={
              <>
                <Grid container spacing={2} sx={{ mb: 1 }}>
                  <Grid item xs={12} md={12}>
                    <Box sx={{ display: 'flex' }}>
                      {/* <BarChartIcon style={{ fontSize: 35 }} /> */}
                      <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                        <Typography fontWeight="500" variant="subtitle1" style={{ color: '#2e7d32' }}>
                          <strong>Menos tarefas manuais para você ganhar tempo</strong>
                        </Typography>
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          <strong>
                            {type === 'expense' ?
                              'O pedido de compra é a formalização da autorização da compra por parte da sua empresa em relação ao fornecedor escolhido.'
                              :
                              'O pedido de venda é a formalização da venda entre a sua empresa e o cliente escolhido.'
                            }
                            {/* O pedido de venda é uma ferramenta vital para registrar, gerenciar e controlar vendas, definindo o que deve ser faturado e a entrega correta de produtos para o cliente.
                              Ele inclui informações como detalhes do cliente, condições comerciais e financeiras acordadas. Além disso, o pedido de venda também auxilia na gestão de estoque,
                              permitindo a atualização do inventário conforme as vendas são concluídas. */}
                          </strong>
                        </Typography>
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                            <strong>Registre um pedido de {text_type}: </strong>
                            <span> realize o pedido e rastre-o até à entrega final de forma simples.</span>
                        </Typography>
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          <strong>Controle a sua {text_type}: </strong>
                          <span>
                            Visualize informações como: Detalhes do {type === 'expense' ? 'fornecedor' : 'cliente'}, Condições comerciais e Financeiras acordadas. 
                          </span>
                          <br/>
                          <span>
                            Além disso, o pedido de {text_type} também o auxilia na gestão de estoque, permitindo a atualização do inventário conforme os pedidos são concluídos.
                          </span>
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Box display="flex" justifyContent="flex-start">
                      <Typography fontWeight="500" sx={{ mt: 2 }} variant="subtitle1">
                        Pedidos:
                      </Typography>
                      <Typography fontWeight="500" sx={{ mt: 2 }} variant="subtitle1">
                        Tenha visibilidade do histórico e porgresso de suas {type === 'expense' ? 'Compras' : 'Vendas'}
                      </Typography>
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12} md={4}>
                    <Box display="flex" sx={{ mt: 1 }} justifyContent="flex-end">
                      {type === 'expense' && <RegisterBuyDialog type={'buy'} />}
                      {type === 'income' && <RegisterBuyDialog type={'sales'} />}
                    </Box>
                  </Grid> */}
                </Grid>
              </>
            }
          ></CardHeader>
          <Divider />
        </Card>
      </Box>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, fontWeight: 'bold', }} >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id]
                    if (column.id === 'releases') {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {/* Outros ícones ou ações */}
                          <Tooltip title={!row.payed || !row.inventory ? 'Em Espera' : 'Finalizada'} arrow>
                            <Chip
                              // variant="outlined"
                              label={!row.payed || !row.inventory ? '!' : 'F'}
                              color={!row.payed || !row.inventory ? 'warning' : 'success'}
                              //onClick={() => handleFinanceClick(row)}
                              style={{
                                marginRight: '10px',
                                borderRadius: '0', // Isso torna os cantos quadrados
                              }}
                            />
                          </Tooltip>
                          <Tooltip title={row.payed ? 'Conta lançada' : 'Lançar Conta'} arrow>
                            <Chip
                              // variant="outlined"
                              label="C"
                              color="success"
                              onClick={() => handleFinanceClick(row)}
                              style={{
                                marginRight: '10px',
                                borderRadius: '0', // Isso torna os cantos quadrados
                              }}
                              disabled={row.payed}
                            />
                          </Tooltip>
                          <Tooltip title={row.inventory ? 'Estoque lançado' : 'Lançar Estoque'} arrow>
                            <Chip
                              //variant="outlined"
                              label="E"
                              color="info"
                              onClick={() => handleStockClick(row)}
                              style={{
                                marginRight: '10px',
                                borderRadius: '0', // Isso torna os cantos quadrados
                              }}
                              disabled={row.inventory}
                            />
                          </Tooltip>
                          {/* Menu existente e outros elementos, se houver */}
                        </TableCell>
                      )
                    }
                    if (column.id === 'actions') {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <IconButton aria-controls="Ações sobre o pedido" aria-haspopup="true" onClick={handleMenuClick}>
                            <MoreVert />
                          </IconButton>
                          <Menu id="actions-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                            <MenuItem onClick={() => handleDialogOpen(row)}>
                              <Wysiwyg style={{ marginRight: '10px' }} />
                              Visualizar
                            </MenuItem>
                            {/* <MenuItem onClick={handleMenuClose}>
                              <Edit style={{ marginRight: '10px' }} />
                              Editar
                            </MenuItem> */}
                            <MenuItem
                              onClick={() => {
                                handleMenuClose()
                                handleFinanceClick(row) // Substitua por sua função específica
                              }}
                              disabled={row.payed}
                            >
                              <Chip
                                //variant="outlined"
                                label="C"
                                color="success"
                                style={{
                                  marginRight: '10px',
                                  borderRadius: '0', // Isso torna os cantos quadrados
                                }}
                                disabled={row.payed}
                              />
                              Lançar Conta
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleMenuClose()
                                handleStockClick(row) // Substitua por sua função específica
                              }}
                              disabled={row.inventory}
                            >
                              <Chip
                                // variant="outlined"
                                label="E"
                                color="info"
                                style={{
                                  marginRight: '10px',
                                  borderRadius: '0', // Isso torna os cantos quadrados
                                }}
                                disabled={row.inventory}
                              />
                              Lançar Estoque
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      )
                    } else if (column.id === 'situation') {
                      let chipLabel, chipColor

                      // Mapear as situações para rótulos e cores específicos
                      switch (value) {
                        case 'Finalizada':
                          chipLabel = 'Atendido'
                          chipColor = 'success'
                          break
                        case 'Aberta':
                          chipLabel = 'Em aberto'
                          chipColor = 'warning' // Ou escolha outra cor apropriada
                          break
                        default:
                          chipLabel = 'Desconhecido'
                          chipColor = 'default'
                          break
                      }

                      return (
                        <TableCell key={column.id} align={column.align}>
                          <Chip
                            label={chipLabel}
                            color={chipColor}
                            //variant="outlined"
                            disabled
                          />
                        </TableCell>
                      )
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {value}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <TableBuySalesOrderContent row={selectedRow} open={openDialog} handleClose={handleDialogClose} type={type}/>
    </Paper>
  )
}

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(cultura, bruto, impurezas, liquido) {
  return { cultura, bruto, impurezas, liquido };
}

const rows = [createData('Café', '5.000,00', '5%', '4.000,000')];

export default function TableProductionHarvestStorage({ cultivation_name, product }) {
  console.log(product);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Cultura</TableCell>
            {/* <TableCell align="right">Situação</TableCell> */}
            <TableCell align="right">Quantidade&nbsp;/kg</TableCell>
            <TableCell align="right">Impurezas</TableCell>
            <TableCell align="right">Líquido&nbsp;/kg</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              <i>
                <img className="mr-1" src="/dist/img_fp/icon-button/icon-agricola-green.png" width="24"></img>
              </i>
              {cultivation_name}
            </TableCell>
            {/* <TableCell align="right">{row.situacao}</TableCell> */}
            <TableCell align="right">{product.quant}</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

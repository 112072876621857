import * as React from 'react';
import Box from '@mui/material/Box';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Card, CardContent, CardHeader, Chip, Container, Divider, Grid, IconButton, Paper, Stack, Switch, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import IEProgressBars from '../../indexExperience/components/IEProgressBars';
import { green } from '@mui/material/colors';
import StepperIE from '../../indexExperience/components/StepperIE';
import CircularStaticIE from '../../indexExperience/components/CircularStaticIE';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PublicIcon from "@mui/icons-material/Public";
import GroupsIcon from "@mui/icons-material/Groups";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import HomeWorkIcon from '@mui/icons-material/HomeWork';

import CardPropertyProducts from '../../property/components/CardPropertyProducts';
import { Span } from '../../../components/Typography';
import MenuPlan from './MenuPlan';

export default function CardPlan({ listPropertyRepos }) {

    const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
    console.log('userCurrent')
    console.log(userCurrent)
    const user = userCurrent;
    console.log(user)
    const name = user.name;
    const charName = name[0].toUpperCase();
    console.log(charName);

    function stringAvatar(name) {
        return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
    }

    return (
        <>

            <Card sx={{ m: 2 }}>
                {/* <Box sx={{ p: 2, display: 'flex' }}>
                            <Stack spacing={0.5}>
                                <Typography fontWeight={700}>Índice de experiência</Typography>
                            </Stack>
                        </Box> */}
                <CardHeader
                    sx={{}}

                    // avatar={
                    //     <Avatar variant="rounded" sx={{ bgcolor: green[500], ml: 4 }} aria-label="recipe">
                    //         <HomeWorkIcon />
                    //     </Avatar>
                    // }
                    action={

                        <Grid sx={{ mb: -4, mr: 2 }}>
                            <MenuPlan />
                        </Grid>
                    }
                    title="Pedro Henrique"
                    subheader="Email: pedro@gmail.com"
                >

                </CardHeader>

                <Divider />
            </Card >

            <Card sx={{ m: 2 }}>
                <Container component="main" maxWidth="sm" sx={{ mb: 3 }}>
                    <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                        <Box sx={{ textAlign: "center" }}>
                            <Grid container xs={12} md={12} item alignItems="center" justifyContent="center">
                                <img src="/dist/image/assinatura.png" name=""></img>

                            </Grid>
                            <Typography sx={{ fontSize: 22 }} color="text.secondary" gutterBottom>
                                Deseja maximizar a gestão de sua propriedade?
                            </Typography>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Entre em contato com um de nossos consultores e veja o que o o CPT Softwares tem para oferecer.
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: "center" }}>

                            <Button color="success" variant="outlined" type="submit" >
                                <Span sx={{ pl: 1, textTransform: "capitalize" }}>Falar com consutor</Span>
                            </Button>
                        </Box>
                    </Paper>
                </Container>



            </Card >

        </>
    );
}

//https://aegro.com.br/termos-de-uso/
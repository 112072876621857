import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import WidgetsIcon from "@mui/icons-material/Widgets";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#14db22" : "#4fb028",
  },
}));

function createData(name, percentage, quantidade, valortotal, participação, price) {
  return {
    name,
    percentage,
    quantidade,
    valortotal,
    participação,
    price,
    history: [
      {
        date: "2020-01-05",
        insumo: "Semente A",
        local: "casa 1",
        valoruni: "R$ 1,00",
        amount: 3,
      },
      {
        date: "2020-01-02",
        insumo: "Semente B",
        local: "casa 2",
        valoruni: "R$ 10,00",
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography sx={{ flex: "1 1 100%", fontWeight: "bold" }} color="inherit" variant="subtitle2" component="div">
            <WidgetsIcon fontSize="small" color="action" sx={{ mr: 1 }} />
            {row.name}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <BorderLinearProgress variant="determinate" value={row.percentage} />
        </TableCell>
        <TableCell align="right">{row.quantidade}</TableCell>
        <TableCell align="right">{row.valortotal}</TableCell>
        <TableCell align="right">{row.participação}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Estoque
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Item</TableCell>
                    <TableCell align="right">Local</TableCell>
                    <TableCell align="right">Valor uni (R$)</TableCell>
                    <TableCell align="right">Quantidade</TableCell>
                    <TableCell align="right">Valor total (R$)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell>{historyRow.insumo}</TableCell>
                      <TableCell align="right">{historyRow.local}</TableCell>
                      <TableCell align="right">{historyRow.valoruni}</TableCell>
                      <TableCell align="right">{historyRow.amount}</TableCell>
                      <TableCell align="right">{Math.round(historyRow.amount * row.price * 100) / 100}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData("Sementes", 50, 100, "R$13.00", "80%", 3.99),
  createData("Ração", 30, 9.0, "R$ 2.500", "4%", 4.99),
  createData("Medicamentos", 50, 16.0, "R$ 1.500", "4%", 3.79),
  createData("Vacina", 50, 3.7, "R$ 1.500", "2%", 2.5),
  createData("Máquinas", 50, 16.0, "R$ 5.500", "8%", 1.5),
  createData("Ferramentas", 50, 16.0, "R$ 2.500", "4%", 1.5),
  createData("Fertilizantes", 50, 16.0, "R$ 24.500", "10%", 1.5),
  createData("Outros ", 50, 16.0, "R$ 2.500", "4%", 1.5),
];

export default function InventoryList() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Categoria</TableCell>
            <TableCell>Estoque (%)</TableCell>
            <TableCell align="right">Quantidade</TableCell>
            <TableCell align="right">Valor total&nbsp;(R$)</TableCell>
            <TableCell align="right">Participação&nbsp;(%)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import { useState, useEffect, useContext } from 'react';
import { Context } from '../../../../components/Context/AuthContext';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, FormControlLabel, Grid } from '@mui/material';
import { alpha, Chip, Skeleton, Box, Toolbar, Typography, Paper, IconButton, Tooltip, Switch } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import EditHarvest from '../editHarvest/EditHarvest';
import DeleteHarvest from '../deleteHarvest/DeleteHarvest';
import GrassIcon from '@mui/icons-material/Grass';
import useStore from '../../../../store/useStore';
import TablesCreationsCardEmpty from './TablesHarvestCardEmpty';
import TablesHarvestCardProjectManagement from './TablesHarvestCardProjectManagement';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../../../components/Loader/ThreeDotsSpinner';

//função descendingComparator() para ajudar na classificação descendente das linhas da tabela
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

//função getComparator() retorna uma função de comparação com base na direção da classificação.
function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// cabeçalhos
const headCells = [
  {
    id: 'name',
    align: 'left',
    disablePadding: true,
    label: 'Safras',
  },
  {
    id: 'date_start',
    align: 'center',
    disablePadding: false,
    label: 'Data inicial',
  },
  {
    id: 'date_end',
    align: 'center',
    disablePadding: false,
    label: 'Previsão de fim',
  },
  {
    id: 'etapa',
    align: 'center',
    disablePadding: false,
    label: 'Etapa',
  },
  {
    id: 'numAreas',
    align: 'right',
    disablePadding: false,
    label: 'Núm. áreas',
  },
  {
    id: 'edit',
    align: 'right',
    disablePadding: false,
    label: 'Ações',
  },
];

//exibir opções de filtro e botões de ação na tabela.
function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, tab_active } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow key={tab_active}>
        <TableCell padding="checkbox"> </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align} padding={headCell.disablePadding ? 'none' : 'normal'} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  tab_active: PropTypes.any,
};

//A função EnhancedTableToolbar() recebe como argumento o número de linhas selecionadas e retorna o componente Toolbar com as opções de filtro e botões de ação.
function EnhancedTableToolbar(props) {
  const { numSelected, selecteded, repos, tab_active } = props;
  //console.log(props)
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selecionado
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          Lista de Projetos
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <DeleteHarvest selected={selecteded} usestore={repos} tab_active={tab_active} />
        </Tooltip>
      ) : (
        <Tooltip title="Lista de filtros">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selecteded: PropTypes.any,
  repos: PropTypes.any,
  tab_active: PropTypes.any,
};

export default function TablesHarvest({ tab_active }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = useState([]);
  const [selecteded, setSelecteded] = useState({});
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [rows, setRowsHarvest] = useState([]);

  // instancia os repos
  const usestore = useStore();
  const reposHarvests = usestore.reposHarvest;

  const property_id = localStorage.getItem('Property') || 1;

  // variavel para atualizar a tabela após edição dos dados da harvest
  const { aux_add } = useContext(Context);
  useEffect(() => {
    console.log('refresh TablesHarvest');
    setSelected([]);
    setSelecteded([]);
    setTimeout(() => {
      handleRows();
    }, 200);
  }, [aux_add, tab_active]);

  const handleRows = () => {
    const listHarvests = reposHarvests.listHarvest;
    var listHarvests_row = listHarvests.filter((item) => item.situation == tab_active).map((item) => item);
    setRowsHarvest(listHarvests_row);

    /*let rows_lv = [];
    var data;
    for (const item of listHarvests_row) {
      //console.log(item);
      data = { id: item.id, id_property: item.id_property, type: item.tipo, activityName: item.name, startDate: item.data_inicial, forecastEndDate: item.data_fim, areaNumber: (item.areas ? item.areas.length : 0), id_tipe: item.id_tipe };
      rows_lv.push(data);
    }
    setRowsHarvest(rows_lv);*/
    setIsLoadingTable(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name, id, icon) => {
    if (tab_active == 'excluida') {
      return;
    }
    var target_parent_name = window.jQuery(event.target).parentsUntil('table').parent().eq(0).attr('name') || '';
    if (target_parent_name != 'tableListHarvests') {
      return;
    }
    target_parent_name = window.jQuery(event.target).parentsUntil('td').parent().eq(0).attr('name') || '';
    if (target_parent_name == 'EditHarvest') {
      return;
    }
    //console.log("handleClick");
    //console.log(event, name);
    const selectedIndex = selected.indexOf(name);
    //console.log(selectedIndex);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);

    if (selectedIndex === -1) {
      selecteded[id] = { id: id, name: name, icon: icon };
    } else {
      delete selecteded[id];
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChange = (e) => {
    var harvest = JSON.parse(window.jQuery(e.currentTarget).attr('value'));
    localStorage.setItem('creationHarvestRepos', JSON.stringify(harvest));
    localStorage.setItem('ButtonActive', 'Painel');
    localStorage.setItem('navMenuActive', 'selectCreationHarvest');
    reposHarvests.changeHarvestRepos(harvest);
  };

  const getImage = (icon, width, height) => {
    return /outra/.test(icon) ? (
      <GrassIcon />
    ) : (
      <i>
        {' '}
        <img src={icon} width={width ? width : '50'} height={height ? height : '35'}></img>{' '}
      </i>
    );
  };

  //{rows.length == 0 ? <TablesCreationsCardEmpty /> :
  return (
    <>
      {rows.length == 0 ? (
        <TablesCreationsCardEmpty />
      ) : (
        <Box sx={{ width: '100%' }}>
          <TablesHarvestCardProjectManagement />
          <Box sx={{ p: 2 }} alignItems="center">
            {true ? (
              <Typewriter text={`Clique abaixo no projeto agrícola e realize a gestão das atividades de campo e do custo de produção.`} variant={'subtitle1'} color={'#2e7d32'} />
            ) : (
              <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                {' '}
                <ThreeDotsSpinner />
              </Box>
            )}
          </Box>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <EnhancedTableToolbar numSelected={selected.length} selecteded={selecteded} repos={usestore} tab_active={tab_active} />
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'} name="tableListHarvests">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  tab_active={tab_active}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      var selecteded = {};
                      selecteded[row.id] = { id: row.id, name: row.name, icon: getImage(row.cultivation_icon, 30, 30) };
                      return (
                        <>
                          {isLoadingTable === true ? (
                            <>
                              <TableRow key={'void'}>
                                <TableCell padding="checkbox" height={67}>
                                  <Skeleton variant="rectangular" height={33} />
                                </TableCell>
                                <TableCell component="th" id={labelId} scope="row" padding="none" height={67}>
                                  <Skeleton variant="rectangular" height={34} />
                                </TableCell>
                                <TableCell align="right" height={67}>
                                  <Skeleton variant="rectangular" height={34} />
                                </TableCell>
                                <TableCell align="right" height={67}>
                                  <Skeleton variant="rectangular" height={34} />
                                </TableCell>
                                <TableCell align="right" height={67}>
                                  <Skeleton variant="rectangular" height={34} />
                                </TableCell>
                                <TableCell align="right" height={67}>
                                  <Skeleton variant="rectangular" height={34} />
                                </TableCell>
                              </TableRow>
                            </>
                          ) : (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, row.name, row.id, getImage(row.cultivation_icon, 30, 30))}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.name}
                              selected={isItemSelected}
                              sx={{ cursor: 'pointer' }}
                            >
                              <TableCell padding="checkbox">{getImage(row.cultivation_icon, 30, 30)}</TableCell>
                              <TableCell component="th" id={labelId} scope="row" padding="none">
                                <td className="text-uppercase">
                                  <NavLink
                                    to={{ pathname: '/controlpanelharvest/' + property_id + '/' + row.id }}
                                    className="small-box-footer text-success"
                                    onClick={(e) => {
                                      handleChange(e);
                                    }}
                                    value={JSON.stringify({
                                      label: row.name,
                                      id_property: row.id_property,
                                      id: row.id,
                                      id_cultivation: row.id_cultivation,
                                      cultivation_icon: row.cultivation_icon,
                                      tipo_repos: 'Safras',
                                    })}
                                  >
                                    {row.name}
                                  </NavLink>
                                </td>
                              </TableCell>
                              <TableCell align="center">{row.data_inicial}</TableCell>
                              <TableCell align="center">{row.data_fim}</TableCell>
                              <TableCell align="center">
                                {' '}
                                <Chip label={row.grow} color="success" variant="outlined" size="small" />{' '}
                              </TableCell>
                              <TableCell align="right">{row.areas ? row.areas.length : 0}</TableCell>
                              <TableCell align="right" name="EditHarvest" width={'112px'}>
                                <EditHarvest id={row.id} usestore={usestore} tab_active={tab_active} />
                                {tab_active != 'excluida' ? <></> : <DeleteHarvest selected={selecteded} usestore={usestore} tab_active={tab_active} />}
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow key={'emptyRows'} style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              labelRowsPerPage={'Linhas por página'}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Visualização da tabela" />
        </Box>
      )}
    </>
  );
}

import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Grid } from '@mui/material'
import InputIcon from '@mui/icons-material/Input'
import ChartBaVInvetoryHis from '../../../components/Charts/ChartBaVInvetoryHis'

const TAX_RATE = 0.07

export default function InventoryHistCmp() {
  return (
    <Grid container spacing={3} sx={{ mb: 2 }}>
      <Grid item xs={4}>
        <TableContainer component={Paper}>
          <Table aria-label="spanning table">
            <TableBody>
              <TableRow>
                <TableCell colSpan={2}>
                  <InputIcon sx={{ mr: 1 }} /> Entradas
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>Semente A</TableCell>
                <TableCell align="right">400</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>Semente A</TableCell>
                <TableCell align="right">200</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={4}>
        <p>Saldo atual</p>
        <ChartBaVInvetoryHis />
      </Grid>
      <Grid item xs={4}>
        <TableContainer component={Paper}>
          <Table aria-label="spanning table">
            <TableBody>
              <TableRow>
                <TableCell colSpan={2}>
                  <InputIcon sx={{ mr: 1 }} /> Saídas
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>Semente A</TableCell>
                <TableCell align="right">100</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>Semente A</TableCell>
                <TableCell align="right">150</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

import { Badge } from '@mui/material';
import { useState, useEffect } from 'react';

function Check() {
    // Online state
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        // Update network status
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
        };

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);

        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);

        // Specify how to clean up after this effect for performance improvment
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    }, [isOnline]);

    return (
        <div className=' mr-1 '>
            {isOnline ? (
                <Badge badgeContent={"online"} color="success">
                </Badge>
            ) : (
                <Badge badgeContent={"off"} color="primary">
                </Badge>
            )}
        </div>
    );
}

export default Check;
import React from 'react'
import NavSide from '../../components/Nav/NavSide'
import NavBotton from '../../components/Nav/NavBotton'
import Footer from '../../components/Footer/Footer'
import BuyContentTabs from './BuyContentTabs'

export default function Buy() {
  return (
    <>
      <NavSide />
      {/* <Nav /> */}
      <NavBotton />
      <BuyContentTabs />
      <Footer />
    </>
  )
}

// testar a inserção de dados do José

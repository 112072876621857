import * as React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { NavLink } from 'react-router-dom'
import { Button, CardActionArea, CardMedia, Divider, Paper } from '@mui/material'
import CardReports from './CardReports'

const classes = {
  root: {
    flexGrow: 1,
  },
  paper: {
    width: 300,
    height: 150,
    padding: 40,
    textAlign: 'center',
    color: 'blue',
    fontFamily: 'Roboto',
  },
}

export default function ListReportsLivestockCard() {
  //const data = [1, 2, 3, 4, 5]
  const data = [
    {
      type: 'livestock',
      group: 'Geral',
      doc: 'ListaCriação',
      title: 'Lista <span style="font-size:16px;">das Criações</span>',
      text: 'Lista de todas as suas <b>criações</b> ativas.',
      icon: 'WidgetsIcon',
    },
    {
      type: 'livestock',
      group: 'Geral',
      doc: 'Atividades',
      title: 'Atividades <span style="font-size:16px;">das Criações</span>',
      text: 'Lista de <b>atividades realizadas</b> com seus insumos, máquinas e equipe.',
      icon: 'WidgetsIcon',
    },
    {
      type: 'livestock',
      group: 'Geral',
      doc: 'RecursosCriação',
      title: 'Recursos <span style="font-size:16px;">das Criações</span>',
      text: 'Quantidade total de <b>recursos</b> utilizados nas criações.',
      icon: 'ListAltIcon',
    },
    { type: 'livestock', group: 'Geral', doc: 'LotesCriação', title: 'Lotes <span style="font-size:16px;">das Criações</span>', text: 'Lista dos lotes <b>das criações</b>.', icon: 'WidgetsIcon' },

    {
      type: 'livestock',
      group: 'Financeiro',
      doc: 'ContasCriação',
      title: 'Contas <span style="font-size:16px;">das Criações</span>',
      text: 'Lista de <b>contas a pagar</b> das criações.',
      icon: 'WidgetsIcon',
    },
    {
      type: 'livestock',
      group: 'Financeiro',
      doc: 'CustosCriação',
      title: 'Custos <span style="font-size:16px;">de Produção das Criações</span>',
      text: 'Lista de <b>custos</b> agrupadas em categoria e recursos.',
      icon: 'WidgetsIcon',
    },
    {
      type: 'livestock',
      group: 'Financeiro',
      doc: 'RentabilidadeCriação',
      title: 'Rentabilidade <span style="font-size:16px;">das Criações</span>',
      text: 'Produção, custos e lucro da safra',
      icon: 'WidgetsIcon',
    },

    // {
    //   type: 'livestock',
    //   group: 'Estoque',
    //   doc: 'EstoqueCriação',
    //   title: 'Estoque <span style="font-size:16px;">das Criações</span>',
    //   text: 'Lista <b>os itens</b> das criações em seus locais de estoque com as respectivas quantidades e valor',
    //   icon: 'WidgetsIcon',
    // },
    {
      type: 'livestock',
      group: 'Estoque',
      doc: 'MovEstoqueCriação',
      title: 'Movimentações de Estoque <span style="font-size:16px;">das Criações</span>',
      text: 'Lista das <b>movimentações de estoque</b> das criações',
      icon: 'WidgetsIcon',
    },
  ]

  // Agrupando os dados por 'group'
  const groupedData = data.reduce((groups, item) => {
    if (!groups[item.group]) {
      groups[item.group] = []
    }
    groups[item.group].push(item)
    return groups
  }, {})

  return (
    <>
      <div>
        {Object.keys(groupedData).map((group) => (
          <div key={group}>
            <Typography sx={{ mt: 2, ml: 1 }} color="#808080" gutterBottom variant="subtitle1" component="div">
              {group}
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={3}>
              {groupedData[group].map((item) => (
                <Grid item xs={12} sm={6} md={3} key={item.doc}>
                  <CardReports type={item.type} doc={item.doc} title={item.title} text={item.text} icon={item.icon} />
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
      </div>
    </>
  )
}

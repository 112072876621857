import { useContext, useState } from "react";
import { Box, Container, Paper } from "@mui/material";

import SuccessIdentification from "./SuccessIdentification";
import { toast } from "react-toastify";
import api from "../../../../../services/api";
import useStore from "../../../../../store/useStore";
import { Context } from "../../../../../components/Context/AuthContext";
import FormIdentification1 from "./FormIdentification1";
import FormIdentification2 from "./FormIdentification2";
import FormIdentification3 from "./FormIdentification3";
import FormIdentification4 from "./FormIdentification4";

import dayjs from "dayjs";

export default function MultiStepFormIdentification({ handleClose }) {
  // console.log("MultiStepFormIdentification");
  const usestore = useStore();
  const livestockRepos = usestore.reposLivestock.getLivestockStore("id", usestore.reposLivestock.livestockRepos.id)[0];

  const property_id = localStorage.getItem("Property") || 1;
  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));

  /** https://old.cnpgc.embrapa.br/eventos/2000/dcnelore/apostila2.html
    2.3 Manejo do rebanho
      2.3.1 Cria
      Os bezerros permanecem com as mães até os 6/7 meses de idade, quando são desmamados. 
    idade final: 202 dias
    peso final: 169.5
      2.3.2 Recria
      Todos os animais são pesados aos 12,18 e 24 meses de idade, sendo que os machos são pesados antes de entrarem em confinamento (21/22 meses).
    idade final: 20 meses
    peso final: 350 quilos
      2.3.3 Engorda
      Machos com mais de 330 kg de peso vivo são confinados até atingirem 450 kg de peso vivo, quando são encaminhados para o abate.
      Os animais inferiores, com menos de 330 kg de peso vivo, são vendidos magros.
    idade final: 21/22 meses
    peso final: 360+ a 450-
      -.-.- Terminação
    idade: 22+ meses
    peso: 450+ kg

  média de TEMPO utilizada:
    cria:     4 meses -> 120 dias
    recria:   15 meses -> 450 dias
    engorda:  20 meses -> 600 dias
    producao: 24 meses -> 720 dias
  média de PESO das fases
    cria:       40-169 kg -> 104.5 -> 105
    recria:     169-350 kg -> 259.5 -> 260
    engorda:    350-450 kg -> 400
    produção/terminação: 450 kg 
  */

  const getDateBirth = (lote) => {
    var date_birth = lote?.date_entry;
    var aux_fase = lote.fase_name.toLowerCase();
    // BOVINOS
    if (/cria/.test(aux_fase)) {
      date_birth = dayjs(date_birth).subtract(120, "day").format("YYYY-MM-DD HH:mm:ss");
    } else if (/recria/.test(aux_fase)) {
      date_birth = dayjs(date_birth).subtract(450, "day").format("YYYY-MM-DD HH:mm:ss");
    } else if (/engorda/.test(aux_fase)) {
      date_birth = dayjs(date_birth).subtract(600, "day").format("YYYY-MM-DD HH:mm:ss");
    } else if (/.*produção/.test(aux_fase)) {
      date_birth = dayjs(date_birth).subtract(720, "day").format("YYYY-MM-DD HH:mm:ss");
    }

    return date_birth;
  };
  const getWeight = (lote) => {
    var weight = 0;
    var aux_fase = lote.fase_name.toLowerCase();
    // BOVINOS
    if (/cria/.test(aux_fase)) {
      weight = 105;
    } else if (/recria/.test(aux_fase)) {
      weight = 260;
    } else if (/engorda/.test(aux_fase)) {
      weight = 400;
    } else if (/producao/.test(aux_fase)) {
      weight = 450;
    }
    return weight;
  };

  const list_lotes = livestockRepos.lotes.map((lote) => ({
    id: lote.id,
    label: lote.name,
    //icon: <AutoAwesomeMotionIcon />,
    num_femeas: lote.num_femeas,
    num_machos: lote.num_machos,
    num_animals: lote.num_animals,
    total_animals : (lote.num_animals + lote.num_femeas + lote.num_machos),
    total_identifi : (lote.num_femeas + lote.num_machos),
    id_group: lote.id_group,
    fase_id: lote.fase_id,
    fase_name: lote.fase_name,
    date_entry: dayjs(lote.date_entry).format("YYYY-MM-DD HH:mm:ss"),
  }));

  const opt_lotes = list_lotes.filter((lote) => {
    return lote.num_animals > 0
  })

  var date_birth_aux = opt_lotes.length > 0 ? getDateBirth(opt_lotes[0]) : dayjs().format("YYYY-MM-DD HH:mm:ss");
  var weight_aux = opt_lotes.length > 0 ? getWeight(opt_lotes[0]) : 0;

  const [data, setData] = useState({
    id_property: property_id,
    id_livestock: livestockRepos.id,
    data_livestock: livestockRepos,
    id_user: userCurrent.id,
    opt_lotes: opt_lotes,
    lote: opt_lotes[0],
    num_animals: 0,
    num_females: 0,
    num_males: 0,
    date_birth: date_birth_aux,
    prefix: "",
    // SUGERIR UMA NÚMERAÇÃO INICIAL
    num_initial: "",
    num_final: "",
    race: "",
    weight: weight_aux,
    sexo: "Fêmea",
    unit: "kg",
    date_start: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    date_prev_end: dayjs().add(3, "day").format("YYYY-MM-DD HH:mm:ss"),
    isDeleted: "",
    progress: "EM PROGRESSO",
    planning: "realized",
    animals: [],
    table_animals: [],
    table_animals_p: [],
    table_confirm: [],
    stageData: "",
  });

  // controle do dialog dos componentes
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    handleScroll();
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    handleScroll();
    if (activeStep === 1) {
      data.stageData = '';
      data.progress = "EM PROGRESSO";
    }
  };

  const handleScroll = () => {
    setTimeout(() => {
      window.jQuery(window.jQuery.find('#focus_top_modal')[0]).parent()[0].scrollTo({ top: 0, left: 0, behavior: "smooth", });
    }, 300);
  };

  const { aux_add, setAuxAdd } = useContext(Context);
  // const [formError, setFormError] = useState({
  //   name: "",
  //   lote: [],
  //   num_animals: 0,
  // });

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ["FormIdentification1", "FormIdentification2", "FormIdentification3", "FormIdentification4", "SuccessIdentification"];

  const onSubmit = async () => {
    let formOk = true;

    if (!data.lote || !data.animals.length) {
      formOk = false;
    }

    if (formOk) {
      if (data.progress === "CONCLUÍDA") {
        data.date_end = data.date_start;
      }

      const header = { header: { "Content-Type": "application/json" } };
      // const res = await api
      await api.post("/activityIdentify/save", data, header)
        .then((response) => {
          // console.log(response.data);
          if (response.data.isSuccess) {
            toast.success("Identificação dos animais " + (data.progress === "concluida" ? "realizada" : "iniciada") + " com sucesso!");
            usestore.reposLivestock.fetch({ 'id_farm': property_id });
            usestore.reposActivityWeight.fetch({ id_property: property_id });
            usestore.reposActivityIdentify.fetch({ id_property: property_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleNext();
              }, 100);
            });
          }
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          toast.error("Erro ao " + (data.progress === "concluida" ? "realizar" : "iniciar") + " identificação dos animais, tente novamente mais tarde!");
        });
    } else {
      toast.error("Por favor, preencha os campos obrigatórios!");
    }
  };

  //chamada dos componentes
  const getStepContent = (step) => {
    const obj_fn = {
      setData: setData,
      handleNext: handleNext,
      handleBack: handleBack,
      activeStep: activeStep,
      steps: steps,
      handleClose: handleClose,
      onSubmit: onSubmit,
      getDateBirth: getDateBirth,
      getWeight: getWeight,
    };
    switch (step) {
      case 0:
        // seleção do lote
        return <FormIdentification1 data={data} obj_fn={obj_fn} />;
      case 1:
        // data de realização
        return <FormIdentification2 data={data} obj_fn={obj_fn} />;
      case 2:
        // definição dos dados de identificação
        return <FormIdentification3 data={data} obj_fn={obj_fn} />;
      case 3:
        // confirmação da identificação
        return <FormIdentification4 onSubmit={onSubmit} data={data} obj_fn={obj_fn} />;
      case 4:
        return (
          <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessIdentification handleClose={handleClose} data={data} />
            </Paper>
          </Container>
        );
      default:
        return "Não existente";
    }
  };

  return ( <>
    <Box sx={{ width: "100%" }}>
      {getStepContent(activeStep)}
    </Box>
  </> );
}

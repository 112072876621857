import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, IconButton, List, ListItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';

export default function TutorialIndicators({ openModal, setOpenModal }) {
  // ativar para abrir quando iniciar
  //const [open, setOpen] = React.useState(openModal);
  const [open, setOpen] = React.useState(openModal || false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (setOpenModal) {
      setOpenModal(false);
    }
  };

  //handleClickOpen()

  return (
    <div>
      <IconButton variant="outlined" color="success" onClick={(e) => handleClickOpen(e)}>
        <HelpOutlineIcon />
      </IconButton>
      <Dialog open={open || openModal} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AccountTreeIcon sx={{ mr: 1 }} /> Indicadores
          </div>
          <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
            Administração da Propriedade
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography paragraph variant="subtitile1" fontWeight={500}>
              Os indicadores fornecem uma visão abrangente e detalhada do desempenho das suas criações e safras, facilitando a tomada de decisões informadas para a gestão da propriedade.
            </Typography>
          </Box>
          <List component="div" disablePadding>
            <Box sx={{ mt: 2, mb: 2 }}>
              <Typography paragraph variant="subtitile2">
                Criações:
              </Typography>
            </Box>
            <ListItem>
              <Typography>Criações utilizadas, lotes e áreas.</Typography>
            </ListItem>
            <ListItem>
              <Typography>Evolução das Criações: Percentual de atividades realizadas ao longo do tempo.</Typography>
            </ListItem>
            <ListItem>
              <Typography>Produção: Produção de todas as áreas produtivas.</Typography>
            </ListItem>
            <ListItem>
              <Typography>Produtividade: Produtividade de todas as áreas produtivas.</Typography>
            </ListItem>
            <ListItem>
              <Typography>Rentabilidade: Produção, custos e lucro da safra.</Typography>
            </ListItem>
            <Box sx={{ mt: 2, mb: 2 }}>
              <Typography paragraph variant="subtitile2">
                Safras:
              </Typography>
            </Box>
            <ListItem>
              <Typography>Cultivares utilizadas e áreas de produção.</Typography>
            </ListItem>
            <ListItem>
              <Typography>Evolução da Safra: Percentual de atividades realizadas ao longo do tempo.</Typography>
            </ListItem>
            <ListItem>
              <Typography>Produção: Produção de todas as áreas produtivas.</Typography>
            </ListItem>
            <ListItem>
              <Typography>Produtividade: Produtividade de todas as áreas produtivas.</Typography>
            </ListItem>
            <ListItem>
              <Typography>Rentabilidade: Produção, custos e lucro da safra.</Typography>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} color="success" onClick={handleClose} autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import api from '../services/api';
import LZString from 'lz-string';

function formatNumber(num) {
  var parse_num = num;
  if (/\..*\,/.test(num)) {
    parse_num = num.replace(/\./, '');
  }
  parse_num = parse_num.replace(/\,/, '.');
  return parseFloat(parse_num);
}

// create the store
const usePropertyReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listProperty: [],
        propertyRepos: { label: '', id: 0 },
        fetch: async (id_user) => {
          var user_id = id_user;
          var user = JSON.parse(localStorage.getItem('userCurrent') || '{"id": "1"}');
          if (!user) {
            user_id = user.id;
          }

          const header = { header: { 'Content-Type': 'application/json' } };
          const response = await api.post('property/list', { id_user: user_id }, header);
          const data = await response.data;
          const data_map = await (data.isSuccess ? data._value : []);
          await set({
            listProperty: data_map.map((property) => ({
              id: property.id,
              id_user: property.id_user,
              name: property.name,
              label: property.name,
              property: property.property,
              ownerName: property.ownerName,
              address: property.address || '',
              state: property.state || '',
              city: property.city || '',
              image: property.image || '',
              imageDecode: property.image ? LZString.decompressFromBase64(property.image) : '',
              tel: property.tel || '',
              description: property.description || '',
              area: formatNumber(property.area || '0') || 0,
              measure: property.measure || 'ha',
              latitude: property.lat || -21.7,
              longitude: property.lng || -61.5,
              zoom: property.zoom || 3,
              isDeleted: property.isDeleted || 0,
              total_safras: property.total_safras,
              total_cultivation: property.total_cultivation,
              total_livestocks: property.total_livestocks,
              total_animais: property.total_animais,
              entradas: property.entradas,
              saidas: property.saidas,
              total_acumulado: property.total_acumulado,
              id_account: property.id_account,
              tipo_repos: 'Property',
            })),
          });
          set({
            propertyRepos: !data_map || data_map.length == 0 ? { label: '', id: 0 } : { label: data_map[0].name, id: data_map[0].id, area: data_map[0].area, measure: data_map[0].measure },
          });
          localStorage.setItem('Property', !data_map || data_map.length == 0 ? 0 : data_map[0].id);
          get().fetchAreasProperty();
          return data;
        },
        changePropertyRepos: (propertyRepos) => {
          set((state) => ({ propertyRepos }));
          localStorage.setItem('Property', propertyRepos.id);
        },
        // pega a(s) property(s) da listProperty de acordo com o [key e val] informados
        getPropertyStore: (key, val) => {
          return get().listProperty.filter((property) => {
            if (property[key] == val) {
              return property;
            }
          });
        },
        // Adiciona uma nova property à listProperty
        addPropertyStore: (property) => {
          set((state) => ({
            listProperty: [...state.listProperty, property],
          }));
        },
        // Gera uma nova listProperty sem a property informada
        delPropertyStore: (id) => {
          set({
            listProperty: get().listProperty.filter((property) => {
              if (property.id != id) {
                return property;
              }
            }),
          });
        },
        // atualiza listProperty de acordo com id, key e val informados
        updatePropertyStore: (id, key, val) => {
          set({
            listProperty: get().listProperty.map((property) => {
              if (property.id === id) {
                if (key == 'all') {
                  // atualiza todos os dados
                  property = val;
                } else {
                  property[key] = val;
                }
              }
              return property;
            }),
          });
        },
        listAreasProperty: [],
        fetchAreasProperty: async (item) => {
          var id_property = localStorage.getItem('Property') || 1;

          if (!item) {
            item = { id_property: id_property };
          } else if (!item.id_property) {
            item.id_property = id_property;
          }
          const header = { header: { 'Content-Type': 'application/json' } };
          const response = await api.post('/property/areas', item, header);
          const data = await response.data;
          await set({
            listAreasProperty: !data._value ? [] : data._value[0],
          });
        },
      }),
      { name: 'reposProperty' }
    )
  )
);

export default usePropertyReposStore;

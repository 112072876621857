// ReportComponent.js
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import pdfMake from 'pdfmake/build/pdfmake'
import PrintIcon from '@mui/icons-material/Print'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { IconButton, Typography } from '@mui/material'
//import { documento as basics } from "./docReports/Example/DocBasics";
//Property
import { documento as ListCreationHarvest } from '../docReports/docProperty/DocReportPropertyListCreationHarvest'
import { documento as ListCreationHarvestBW } from '../docReports/docProperty/DocReportPropertyListCreationHarvestBW'
import { documento as ListStockProperty } from '../docReports/docProperty/DocReportPropertyListStockProperty'
import { documento as ListPatrimonyProperty } from '../docReports/docProperty/DocReportPropertyListPatrimonyProperty'

//livestock
import { documento as ListCreation } from '../docReports/docLivestock/DocReportLivestockListCreation'
import { documento as ListCreationBW } from '../docReports/docLivestock/DocReportLivestockListCreationBW'
import { documento as ActivitiesCreation } from '../docReports/docLivestock/DocReportLivestockActivitiesCreation'
import { documento as ActivitiesCreationBW } from '../docReports/docLivestock/DocReportLivestockActivitiesCreationBW'
//harvest
import { documento as ListHarvest } from '../docReports/docHarvest/DocReportLivestockListHarvest'
import { documento as ListHarvestBW } from '../docReports/docHarvest/DocReportLivestockListHarvestBW'
import { documento as ActivitiesHarvest } from '../docReports/docHarvest/DocReportLivestockActivitiesHarvest'
import { documento as ActivitiesHarvestBW } from '../docReports/docHarvest/DocReportLivestockActivitiesHarvestBW'
//import { documento as stock } from '../docReports/DocReportStock'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'

pdfMake.vfs = pdfFonts.pdfMake.vfs

function ImportReportComponentPdfMake({ type, doc }) {
  //Primeiro cria-se a indeificação: link type === 'harvest' && doc === 'Atividades'
  //Segundo cria-se o link com o documento: pdfMake.createPdf(ListHarvest).open({}, window.open('', '_blank'))
  //Terceiro: cria-se o documento(documento): documento as ListHarvest (src/pages/reports/components/docReports/DocReportLivestockListHarvest.js)
  //console.log(type, doc);

  // arquivos coloridos estilizados
  const generatePDF = () => {
    //Property
    if (type === 'property' && doc === 'ListCreationHarvest') pdfMake.createPdf(ListCreationHarvest).open({}, window.open('', '_blank'))
    if (type === 'property' && doc === 'StockProperty') pdfMake.createPdf(ListStockProperty).open({}, window.open('', '_blank'))
    if (type === 'property' && doc === 'PatrimonyProperty') pdfMake.createPdf(ListPatrimonyProperty).open({}, window.open('', '_blank'))
    //livestock
    if (type === 'livestock' && doc === 'ListaCriação') pdfMake.createPdf(ListCreation).open({}, window.open('', '_blank'))
    if (type === 'livestock' && doc === 'Atividades') pdfMake.createPdf(ActivitiesCreation).open({}, window.open('', '_blank'))
    //harvest
    if (type === 'harvest' && doc === 'ListHarvest') pdfMake.createPdf(ListHarvest).open({}, window.open('', '_blank'))
    if (type === 'harvest' && doc === 'Atividades') pdfMake.createPdf(ActivitiesHarvest).open({}, window.open('', '_blank'))
    // if (type === "livestock" && doc === "EstoqueCriação") pdfMake.createPdf(stock).open({}, window.open("", "_blank"));
    // pdfMake.createPdf(report).open();
  }
  // arquivos preto e branco simples
  const generatePDFBlackWhite = () => {
    //Property
    if (type === 'property' && doc === 'ListCreationHarvest') pdfMake.createPdf(ListCreationHarvestBW).open({}, window.open('', '_blank'))

    //livestock
    if (type === 'livestock' && doc === 'ListaCriação') pdfMake.createPdf(ListCreationBW).open({}, window.open('', '_blank'))
    if (type === 'livestock' && doc === 'Atividades') pdfMake.createPdf(ActivitiesCreationBW).open({}, window.open('', '_blank'))
    //harvest
    if (type === 'harvest' && doc === 'ListHarvest') pdfMake.createPdf(ListHarvestBW).open({}, window.open('', '_blank'))
    if (type === 'harvest' && doc === 'Atividades') pdfMake.createPdf(ActivitiesHarvestBW).open({}, window.open('', '_blank'))
    // if (type === "livestock" && doc === "EstoqueCriação") pdfMake.createPdf(stock).open({}, window.open("", "_blank"));
    // pdfMake.createPdf(report).open();
  }

  //https://github.com/GlerystonMatos/react-pdf
  return (
    <>
      <IconButton aria-label="generate report" onClick={generatePDFBlackWhite}>
        <LocalPrintshopIcon />
      </IconButton>
      <IconButton aria-label="generate report" color="success" onClick={generatePDF}>
        <PrintIcon />
        <Typography sx={{ mt: 1, ml: 1 }} gutterBottom variant="subtitle1">
          Gerar o relatório
        </Typography>
      </IconButton>
    </>
  )
}

export default ImportReportComponentPdfMake

import { NavLink, useParams } from 'react-router-dom';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { Dashboard, FormatListBulleted } from '@mui/icons-material';
import useStore from '../../../store/useStore';

import TutorialPainelCentral from '../components/tutorial/TutorialPainelCentral';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CardPeriod from '../../../components/Cards/CardPeriod';
import CardAreas from '../../../components/Cards/CardAreas';
import CardTimeLinePainel from '../../../components/Cards/CardTimeLinePainel';

import CardCostCreations from './CardCostCreations';
import CardMetaCost from './CardMetaCost';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';
import Typewriter from '../../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../../components/Loader/ThreeDotsSpinner';
//import ChartBarVPE from "../../../components/Charts/ChartBarVPE";

export default function CardPainel() {
  // instancia os repos
  const usestore = useStore();
  const { idFarm, id } = useParams();

  const reposUser = usestore.reposUser;
  console.log(reposUser.userRepos);
  console.log(reposUser.userRepos);

  const propertyRepos = usestore.reposProperty.getPropertyStore('id', idFarm);

  const areaTotalProperty = propertyRepos[0].area;

  const livestock = usestore.reposLivestock.getLivestockStore('id', id)[0];

  return (
    <>
      <Box>
        <Box sx={{ marginTop: 0 }} className="content-header">
          <Box className="container-fluid">
            <Grid container spacing={2} sx={{ mb: 0, justifyContent: 'space-between' }}>
              <Grid item xs={8} md={6}>
                <Box sx={{ display: 'flex' }}>
                  <Dashboard sx={{ fontSize: 35 }} color="text.secondary" />
                  <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                    <Typography fontWeight="500" variant="h6">
                      Painel de Controle da Criação
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                      Painel&nbsp;de&nbsp;informações&nbsp;vitais&nbsp;da&nbsp;criação
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={4} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <TutorialPainelCentral />
              </Grid>
              <Grid item xs={12} md={12} alignItems="center">
                {true ? (
                  <Typewriter text={`Seja bem vindo a gestão pecuária, ${reposUser.userRepos.name.toUpperCase()}!`} variant={'subtitle1'} color={'#2e7d32'} />
                ) : (
                  <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                    {' '}
                    <ThreeDotsSpinner />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className="content-header">
          <Box className="container-fluid">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img alt="complex" height={50} src="/dist/img_fp/icon-button/todas_pecuarias.png" style={{ marginRight: '8px' }} />
                  <Typography fontWeight="500" color="#28a745" variant="h6">
                    {livestock.tipo}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box>
          <Box className="container-fluid">
            <Grid container spacing={2}>
              {/* card 1 coluna 1*/}
              <Grid item xs={12} md={6} sx={{ mt: -1 }}>
                <Box mt={4}>
                  <CardPeriod item={livestock} />
                </Box>
                <Box mt={4}>
                  <Box sx={{ display: 'flex' }}>
                    <Stack spacing={0.5}>
                      <Typography fontWeight="400" variant="h6">
                        Áreas da Criação
                      </Typography>
                    </Stack>
                  </Box>
                  <Box mt={4}>
                    <CardAreas item={livestock} areaTotalProperty={areaTotalProperty} />
                  </Box>
                </Box>
                <Box mt={4}>
                  <Box sx={{ display: 'flex' }}>
                    <Stack spacing={0.5}>
                      <Typography fontWeight="400" variant="h6">
                        Produção e Produtividade
                      </Typography>
                    </Stack>
                  </Box>
                  <Box mt={4}>
                    <CardMetaCost />
                  </Box>
                </Box>
                {/* <Box mt={4}>
                  <Box sx={{ display: "flex" }}>
                    <Stack spacing={0.5}>
                      <Typography fontWeight="400" variant="h6">
                        Custo Esperado x Custo Realizado
                      </Typography>
                    </Stack>
                  </Box>
                  <Box mt={4}>
                    <CardCostCreations />
                  </Box>
                </Box> */}
              </Grid>
              {/* card 2 coluna 2 */}
              <Grid item xs={12} md={6} mt={2} sx={{ mb: 2, mt: -4 }}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                        <NavLink to={{ pathname: '/activities/' + idFarm + '/' + id }} className="nav-link">
                          <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} variant="outlined" size="medium" color="success" startIcon={<PendingActionsIcon />}>
                            Atividades
                          </Button>
                        </NavLink>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <FormatListBulleted style={{ color: '#2e7d32', width: '40px', height: '40px' }} />
                        <Typography fontWeight="400" variant="h6">
                          Linha do tempo
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Box>
                    <CardTimeLinePainel item={livestock} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {/* card nova linha */}
            <Grid container spacing={2}>
              {/* <Grid item xs={12} mt={4}>
                <Box>
                  <Box sx={{ display: "flex" }}>
                    <Stack spacing={0.5}>
                      <Typography fontWeight="400" variant="h6">
                        Venda Projetada x Custo Projetado
                      </Typography>
                    </Stack>
                  </Box>
                  <Box mt={4}>
                    <ChartBarVPE />
                  </Box>
                </Box>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}

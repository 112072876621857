import React, { useEffect, useState } from 'react';
import dayjs from "dayjs";
import { Box, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { AccountBalance } from '@mui/icons-material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { BankList, SelectBanks } from "../../../../../components/Icons/bank/BankList";
import FooterModal from '../../../../../components/Footer/FooterModal';
import SingleDateField from '../../../../../components/Fields/SingleDateField';
import useStore from '../../../../../store/useStore';
// import CurrencyInputReal from '../../../../../components/Fields/CurrencyInputReal';
import GenericValueField from '../../../../../components/Fields/GenericValueField';

const typePeople = [
  {
    value: "Fisica",
    label: "Física",
  },
  {
    value: "Juridica",
    label: "Jurídica",
  },
  {
    value: "Estrangeira",
    label: "Estrangeira",
  }
];

// const linkedAccount = [
//   {
//     value: "corrente",
//     label: "corrente",
//   },
// ];

export default function FormBankStep2({ data, obj_fn }) {
  const [error_date, setErrorDate] = useState(false);
  const usestore = useStore();
  const reposBank = usestore.reposBank;
  // console.log(reposBank.listBank)
  // const listBanks = reposBank.listBank;
  ///reposBank.listBank

  const filteredData = reposBank.listBank.filter(account => account.type === 'ContaCorrente');

  // console.log(filteredData)

  const linkedAccount = filteredData.map(obj => ({
    value: obj.bank,
    label: obj.name,
  }));

  // console.log(linkedAccount)

  const [listBanks, setListBank] = useState(BankList());


  if (!data.bank) {
    data.bank = listBanks.find((bank) => bank.bank === data.name_bank);
  }

  // console.log(listBanks)

  const isErrorDate = () => {
    if (!data.balance_date.$D || dayjs(data.balance_date).isAfter(dayjs(new Date()))) {
      setErrorDate(true);
      return true;
    }
    setErrorDate(false);
    return false;
  };

  function countItauBanks(bankType) {
    let count = 0;
    for (const obj of listBanks) {
      if (obj.bank === bankType) {
        count++;
      }
    }
    return count;
  }

  useEffect(() => {
    // console.log("Entrou")
    if (data.type == "Investimento") {
      obj_fn.setData((prevState) => ({ ...prevState, "name": data.bank.label + "- Investimento em" }));
    } else if (data.type == "OutrasContas") {
      obj_fn.setData((prevState) => ({ ...prevState, "name": "" }));
    } else if (data.type == "Caixinha") {
      obj_fn.setData((prevState) => ({ ...prevState, "name": "" }));
    } else {
      // console.log(data)
      const bankTypeInit = data.name_bank;
      const bankCount = countItauBanks(bankTypeInit);
      //console.log(bankCount)
      obj_fn.setData((prevState) => ({ ...prevState, "name": data.type_title + " " + data.bank.label + " " + (bankCount + 1) }));
    }

  }, [])


  const handleChangeFlagcard = (id, value) => {
    // console.log(value)
    //reposBank

    // const bankType = value.bank;
    // const itauCount = reposBank.countBanks(bankType)
    // //const itauCount = countItauBanks(bankType);

    // obj_fn.setData((prevState) => ({ ...prevState, 'bank': value }));
    // obj_fn.setData((prevState) => ({ ...prevState, 'name_bank': (value?.bank || 'caixa') }));
    // obj_fn.setData((prevState) => ({ ...prevState, "name": "Conta " + value?.label + " " + (itauCount + 1) }));
  };

  const handleChangeBank = (id, value) => {
    // console.log(value)
    //reposBank

    const bankType = value.bank;
    const itauCount = reposBank.countBanks(bankType)
    //const itauCount = countItauBanks(bankType);

    obj_fn.setData((prevState) => ({ ...prevState, 'bank': value }));
    obj_fn.setData((prevState) => ({ ...prevState, 'name_bank': (value?.bank || 'caixa') }));
    obj_fn.setData((prevState) => ({ ...prevState, "name": "Conta " + value?.label + " " + (itauCount + 1) }));
  };

  const handleChangeDate = (value) => {
    obj_fn.setData((prevState) => ({ ...prevState, balance_date: dayjs(value) }));
    obj_fn.setData((prevState) => ({ ...prevState, balance_entry: dayjs(value).format("YYYY-MM-DD HH:mm:ss") }));
    obj_fn.setData((prevState) => ({ ...prevState, balance_entry_f: dayjs(value).format("DD/MM/YYYY") }));
    isErrorDate();
  };



  const handleChange = (event) => {
    var field = event.target.name;
    var value = event.target.value;
    // console.log("value")
    // console.log(value)
    // console.log(field)
    if (field == 'name') {
      obj_fn.setData((prevState) => ({ ...prevState, "name": data.bank.label + "- Investimento em" }));
    }
    if (field == 'standard') {
      value = event.target.checked;
    } else if (field == 'balance_opening') {
      value = parseFloat(value || "0");
    }
    obj_fn.setData((prevState) => ({ ...prevState, [field]: value }));
  };

  useEffect(() => {
    isErrorDate();
  }, [data.balance_date]);

  // const para validar o button continuar
  const isError = () => {
    if (!data.name || data.name.length < 5 || isErrorDate()) {
      return true;
    }
    return false;
  };

  const returnError = () => {
    return false;
  };

  let obj_date = {
    label: 'Data inicial do saldo',
    field_data: 'balance_date',
    text_helper: 'Defina a data em que o saldo da conta foi iniciado.',
    max: new Date(),
    handleChange: handleChangeDate,
    maxWidth: '250px',
    setData: obj_fn.setData,
  }

  // console.log("data")
  // console.log(data)

  return (<>
    <Grid container sx={{ mb: 4 }}>
      <Box sx={{ ml: 1, mt: 0, mb: 2, display: "flex", flexDirection: "column" }}>
        <Stack spacing={0.5}>
          <Typography fontWeight={700}>Dados da conta: ({data.type_title})</Typography>
        </Stack>
      </Box>

      <Grid container spacing={2}>
        {(data.type !== "OutrasContas" && data.type !== "Caixinha") &&
          <>
            <Grid item xs={4} md={1} style={{ maxWidth: '30px' }}>
              <Box sx={{ mt: 3 }}>
                {data.bank?.icon || ''}
              </Box>
            </Grid>
            <Grid item xs={8} md={5} style={{ minWidth: '220px' }}>
              <Box sx={{ mt: 1 }}>
                <SelectBanks value={data.bank} setValue={handleChangeBank} />
              </Box>
            </Grid>
          </>
        }

        <Grid item xs={12} md={6} style={{ minWidth: '220px' }}>
          <TextField
            required
            fullWidth
            color="success"
            variant="standard"
            label={`Descrição da conta ${data.type_title}.`}
            name="name"
            id="name"
            value={data.name}
            onChange={handleChange}
            onBlur={(e) => handleChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <AccountBalance />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            //error={!data.name || data.name.length < 5}
            helperText={<>Identificador da conta para facilitar sua busca de informações.{!data.name || data.name.length < 5 ? (<><br /><span>* Mínimo de 5 caracteres</span></>) : ''}</>}
          />
        </Grid>
      </Grid>

      {(data.type == "ContaCorrente" || data.type == "Poupanca" || data.type == "Investimento") &&
        <>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={1} md={1} style={{ maxWidth: '30px' }}>
              <Box sx={{ mt: 2 }}>
                &nbsp;
              </Box>
            </Grid>
            {data.type !== "Investimento" &&
              <Grid item xs={11} sm={5}>
                <TextField
                  InputLabelProps={{ style: { color: "#2e7d32" } }}
                  id="typePeople"
                  name="typePeople"
                  label="Tipo de pessoa (modalidade)"
                  select
                  fullWidth
                  value={data.typePeople}
                  onChange={handleChange}
                  onBlur={(e) => handleChange(e, true)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <AssignmentIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  color="success"
                  helperText="Opicional: especifíque a categoria"
                >
                  {typePeople.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}

                </TextField>
              </Grid>
            }
            {(data.type == "Investimento" || data.type == "Poupanca") &&
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{ style: { color: "#2e7d32" } }}
                  id="typeLinkedAccount"
                  name="typeLinkedAccount"
                  label="Conta Corrente Vinculada"
                  select
                  fullWidth
                  value={data.typeLinkedAccount}
                  onChange={handleChange}
                  onBlur={(e) => handleChange(e, true)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <AssignmentIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  color="success"
                //helperText="Opicional: especifíque a categoria"
                >
                  {linkedAccount.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}

                </TextField>
              </Grid>
            }
          </Grid>
        </>
      }
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={12}>
          <Box sx={{ ml: 1, mb: 1, display: "flex", flexDirection: "column" }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Defina o saldo inicial </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={5} sx={{}}>
          <SingleDateField data={data} obj_date={obj_date} />
        </Grid>
        <Grid item xs={12} md={7} sx={{ mt: 3 }} >
          {/* <CurrencyInputReal
            value={data.balance_opening}
            label="Saldo inicial"
            name="balance_opening"
            onChange={handleChange}
            helperText="Defina o saldo na data inicial selecionada"
            color="success"
          /> */}
          <GenericValueField
            handleChange={handleChange}
            options={{
              id: "balance_opening",
              name: "balance_opening",
              label: 'Saldo inicial',
              value: data.balance_opening,
              helperText: "Defina o saldo na data inicial selecionada",
              endAdornment: true,
              width: '270px'
            }}
          />
        </Grid>
      </Grid>
      {(data.type == "ContaCorrente" || data.type == "Poupanca") && <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box sx={{ ml: 1, mb: -2, display: "flex", flexDirection: "column" }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}>Dados da conta bancária</Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <TextField
              // /fullWidth
              color="success"
              variant="standard"
              label="Agência"
              name="agency"
              value={data.agency}
              onChange={handleChange}
              onBlur={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              color="success"
              variant="standard"
              label="Número da conta"
              name="account_number"
              value={data.account_number}
              onChange={handleChange}
              onBlur={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              color="success"
              variant="standard"
              label="País"
              name="country"
              value={data.country}
              onChange={handleChange}
              onBlur={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="success"
                  name="standard"
                  checked={data.standard}
                  onChange={handleChange}
                  onBlur={(e) => handleChange(e)}
                />
              }
              label="Definir como Padrão"
            />
          </Grid>
          <Typography variant="caption" sx={{ ml: 1, mt: 2 }}>
            O Fazenda Premium preza por total segurança da sua conta! Não informe suas senhas!
          </Typography>
        </Grid>
      </>}


    </Grid >

    <FooterModal
      data={data}
      obj_fn={obj_fn}
      isError={isError}
      returnError={returnError}
    />
  </>);
}
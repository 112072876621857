import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CardContent, Typography, Grid, Card, Box, Stack, IconButton, Skeleton, Container, Paper, Divider, CardHeader, Avatar, CardActions, Collapse, Button } from '@mui/material';
import moment from 'moment';
import api from '../../services/api';
import CardHeard from './CardHeard';
import useStore from '../../store/useStore';
import { styled } from '@mui/material/styles';
import LivestockListTableTab from './LivestockListTableTab';
import CardAreaBath from './CardAreaBath';
import { calculaIdade } from '../../components/Calculations/CalculaIdade';
import IconsLivestock from '../../components/Icons/IconsLivestock';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Context } from '../../components/Context/AuthContext';
import Typewriter from '../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../components/Loader/ThreeDotsSpinner';
import { expandAndFade } from '../../components/Animations/animationKeyFrames';
import { PendingActions } from '@mui/icons-material';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

// const Img = styled('img')({
//   margin: 'auto',
//   display: 'block',
//   maxWidth: '100%',
//   maxHeight: '100%',
// });

export default function LivestockListContent() {
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const { id } = useParams();
  const { aux_add } = useContext(Context);

  const [dataActivityLivestock, setDataActivityLivestock] = useState([]);
  // const [dataListAnimal, setDataListAnimal] = useState([]);

  const [image_livestok, setLivestokImage] = useState('/dist/img_fp/rebanho.jpg');
  const property_id = localStorage.getItem('Property') || 1;

  // ------ inicicia os repos
  const usestore = useStore();
  // seta opções das Images
  const useImagesRepos = usestore.reposImages;
  // seta as opções de criações
  //const listAcitivy = Object.assign([], usestore.reposLivestock.getLivestockStore("id", id)[0]);
  // console.log('lista de criações');
  const listAcitivy = JSON.parse(JSON.stringify(usestore.reposLivestock.getLivestockStore('id', id)[0]));

  // console.log(listAcitivy);

  /*const listAreas = usestore.reposAreas.listAreasMap
  console.log("listAreas");
  console.log(listAreas);

  const [imageBath, setImageBath] = useState([]);

  useEffect(() => {
    const images = []
    for (const area of listAreas) {
      images.push(area.imageDecode)
    }
    setImageBath(images)
    console.log("images")
    console.log(images)
  }, [])*/

  function calculaGmd(item, tipo) {
    if (!item.weight_list.length) {
      return '-';
    }
    let gmd_item = 0;
    var d1 = new Date(item.weight_list[item.weight_list.length - 1].date);
    var d2 = new Date();

    //if (tipo == 'animal') {
    //  d1 = new Date(item.date_birth);
    //} else {
    //  d1 = new Date(item.date_created);
    //}
    var dias = d2.getDate() - d1.getDate();

    item.weight_list.map((pesagem, index) => (gmd_item += pesagem.weight));
    let gmd_item_f = (gmd_item / (dias || 1)).toFixed(2);

    return gmd_item_f + ' (' + item.weight_unit + ')';
  }

  const listActivyLivestock = () => {
    listAcitivy.totais = {
      animais: 0,
      femeas: 0,
      machos: 0,
      kilos: 0,
      val: 0,
      val_kg: 0,
      arroba: 0,
      val_arroba: 0,
      listAreas: [],
    };
    var list_animais = listAcitivy.animais || [];
    var list_lotes = listAcitivy.lotes || [];

    const listImageRepos = useImagesRepos.getImageStore('livestock', listAcitivy.id);
    // console.log('listImageRepos');
    // console.log(listImageRepos);
    // console.log('tipo_icon');
    // console.log(listAcitivy.tipo_icon);
    // listAcitivy.image = listImageRepos[0] ? listImageRepos[0].img : "/dist/img_fp/rebanho-nelore.png";
    listAcitivy.image = listImageRepos[0] ? listImageRepos[0].img : `/dist/img_fp/${listAcitivy.tipo_icon}`;
    setLivestokImage(listAcitivy.image);

    var aux_tipo;
    var aux_qtd;
    list_animais.map((item) => {
      if (!item.lote) {
        if (item.sexo == 'Macho') {
          listAcitivy.totais.machos += 1;
        } else {
          listAcitivy.totais.femeas += 1;
        }
        listAcitivy.totais.animais += 1;
      }
      listAcitivy.totais.kilos += parseFloat(item.weight || '0');
      listAcitivy.totais.val += parseFloat(item.valor);
      item.idade = calculaIdade(item.date_birth, 'completa');
      item.ageMonths = calculaIdade(item.date_birth, 'mes');
      item.date_birth_f = formatData(item.date_birth);
      if (/Bovinocultura/.test(listAcitivy.tipo)) {
        aux_tipo = 'Bovinocultura de ' + (item.sexo == 'Macho' ? 'Corte' : 'Leite');
        item.icon_animal = <IconsLivestock type={aux_tipo} widthStyles={'32px'} />;
      } else {
        item.icon_animal = <IconsLivestock type={listAcitivy.tipo} widthStyles={'32px'} />;
      }
      item.weight_unit = item.weight_unit || 'kg';
      item.image_livestock = image_livestok;
      item.weight_list = JSON.parse(JSON.stringify(usestore.reposActivityWeight.getCompostAnimalWeightStore([['progress', 'CONCLUÍDA'], ['id', item.id]])));
      item.weight_list.sort((a, b) => new Date(b.date_update || b.date) - new Date(a.date_update || a.date));
      item.gmd = calculaGmd(item, 'animal');
      return item;
    });
    list_lotes.map((item) => {
      aux_qtd = Number(item.num_femeas || '0') + Number(item.num_machos || '0') + Number(item.num_animals || '0');
      listAcitivy.totais.femeas += Number(item.num_femeas || '0');
      listAcitivy.totais.machos += Number(item.num_machos || '0');
      listAcitivy.totais.animais += aux_qtd;
      listAcitivy.totais.kilos += parseFloat(item.weight || '0') * Number(item.num_animals || '0');
      listAcitivy.totais.val += parseFloat(item.valor_medio);

      item.weight_total = parseFloat(item.weight || '0') * Number(item.num_animals || '0');
      item.weight_unit = item.weight_unit || 'kg';
      item.list_animals = item.list_animals.map((anim) => {
        item.weight_total += parseFloat(anim.weight || '0');
        anim.idade = calculaIdade(anim.date_birth, 'completa');
        anim.ageMonths = calculaIdade(anim.date_birth, 'mes');
        return anim;
      });
      item.weight_total = item.weight_total.toFixed(2);

      item.image_livestock = image_livestok;
      item.num_animais_ni = Number(item.num_animals || '0');
      item.num_animais = aux_qtd;
      item.ageMonths = calculaIdade(item.date_created, 'mes');
      item.date_created_f = formatData(item.date_created);
      item.icon_lote = <IconsLivestock type={listAcitivy.tipo} widthStyles={'32px'} />;
      item.weight_list = JSON.parse(JSON.stringify(usestore.reposActivityWeight.getCompostLoteWeightStore([['progress', 'CONCLUÍDA'], ['id', item.id]])));
      item.weight_list.sort((a, b) => new Date(b.date_update || b.date) - new Date(a.date_update || a.date));
      item.gmd = calculaGmd(item, 'lote');
      return item;
    });

    if (listAcitivy.totais.kilos > 0) {
      listAcitivy.totais.val_kg = parseFloat(listAcitivy.totais.val / listAcitivy.totais.kilos).toFixed(2);
    }
    listAcitivy.totais.arroba = parseFloat(listAcitivy.totais.kilos / 15).toFixed(2);
    if (listAcitivy.totais.arroba > 0) {
      listAcitivy.totais.val_arroba = parseFloat(listAcitivy.totais.val / listAcitivy.totais.arroba).toFixed(2);
    }
    listAcitivy.tipo_item = 'livestock';
    listAcitivy.id_farm = property_id;

    listAcitivy.listAreas = [];
    listAcitivy.listAreas = listAcitivy.areas.map((area) => {
      var area_repos = usestore.reposAreas.getAreaMapStore('id', area.id)[0];
      area.imageDecode = area_repos.imageDecode;
      return area;
    });

    setDataActivityLivestock(listAcitivy);
    // setDataListAnimal(listAcitivy.animais);

    setLoading(false);
    setTimeout(() => {
      setLoadingTable(false);
    }, 1000);
  };

  useEffect(() => {
    if (loading) {
      listActivyLivestock();
    }
  }, [loading, aux_add]);

  function formatData(Inputdata) {
    if (!Inputdata) {
      return '';
    }
    return moment(Inputdata).format('DD/MM/YYYY');
  }

  // function print() {
  //   window.print();
  // }

  const handleGetImages = async (img_key) => {
    const listImageRepos = useImagesRepos.getImageStore('livestock', dataActivityLivestock.id);
    const itemData = listImageRepos.filter((img) => {
      if (img_key && img.image != img_key) {
        // deleta a outra imagem da atividade
        useImagesRepos.delImageStore(img.image);
        api.delete(`/upload/${img.image}/1`);
      } else if (img.isDeleted == 0) {
        return img.img;
      }
    });

    dataActivityLivestock.image = itemData[0] ? itemData[0].img : `/dist/img_fp/${listAcitivy.tipo_icon}`;
    setLivestokImage(dataActivityLivestock.image);
  };
  const handleDelete = async () => {
    const listImageRepos = useImagesRepos.getImageStore('livestock', dataActivityLivestock.id);
    if (!listImageRepos[0]) {
      return;
    }
    const res = await api
      .delete(`/upload/${listImageRepos[0].image}/0`)
      .then((response) => {
        useImagesRepos.updateImageStore(listImageRepos[0].image, 'isDeleted', 1);
        toast.success('Imagem movida para a lixeira com sucesso!');
        setTimeout(() => {
          handleGetImages();
        }, 20);
      })
      .catch(() => {
        toast.error('Erro ao mover a imagem para a lixeira!');
      });
  };

  function filterCamposTable(input) {
    var i, len;
    var td_cod, td_sex, td_pes, td_val;
    var txtCod, txtSex, txtPes, txtVal;
    var filter = input.toUpperCase();
    var table = document.getElementById('tableListAnimais');
    var tr = table.getElementsByTagName('tr');

    for (i = 0, len = tr.length; i < len; i++) {
      td_cod = tr[i].getElementsByTagName('td')[1];
      td_sex = tr[i].getElementsByTagName('td')[2];
      td_pes = tr[i].getElementsByTagName('td')[3];
      td_val = tr[i].getElementsByTagName('td')[4];
      if (td_cod || td_sex || td_pes || td_val) {
        txtCod = td_cod.textContent || td_cod.innerText;
        txtSex = td_sex.textContent || td_sex.innerText;
        txtPes = td_pes.textContent || td_pes.innerText;
        txtVal = td_val.textContent || td_val.innerText;
        if (txtCod.toUpperCase().indexOf(filter) > -1 || txtSex.toUpperCase().indexOf(filter) > -1 || txtPes.toUpperCase().indexOf(filter) > -1 || txtVal.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = '';
        } else {
          tr[i].style.display = 'none';
        }
      }
    }
  }

  // if (loadingTable) {
  //   return (
  //     <>
  //       <div className="content-wrapper bg-white">
  //         <div className="content-header" style={{ marginTop: 0 }}>
  //           <div className="container-fluid">
  //             <Grid container spacing={2} sx={{ mb: 1 }}>
  //               <Grid item xs={12}>
  //                 <Skeleton variant="rectangular" height={40} />
  //               </Grid>
  //             </Grid>
  //           </div>
  //         </div>
  //         <div className="content ">
  //           <div className="container-fluid">
  //             <div className="card">
  //               <div className="card-body">
  //                 <CardContent>
  //                   <Skeleton variant="rectangular" height={165} />
  //                 </CardContent>
  //               </div>
  //             </div>
  //           </div>
  //           <Skeleton variant="rectangular" height={480} />
  //         </div>
  //         <div className="card mt-3">
  //           <Skeleton variant="rectangular" height={400} />
  //         </div>
  //       </div>
  //     </>
  //   );
  // }

  function getAreaUsed(areas) {
    var usedArea = 0;
    for (var i = 0, tam = areas.length; i < tam; i++) {
      usedArea += parseFloat(areas[i].usedArea);
    }
    return usedArea.toFixed(2);
  }
  // console.log('dataActivityLivestock');
  // console.log(dataActivityLivestock);
  // console.log(dataActivityLivestock.lotes);
  // console.log(image_livestok);

  const [expanded, setExpanded] = React.useState(false);
  const [icon, setIcon] = React.useState('');

  useEffect(() => {
    if (dataActivityLivestock?.lotes?.length > 0) {
      setIcon(dataActivityLivestock.lotes[0]?.icon_lote);
    }
  }, [dataActivityLivestock]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate(`/activities/${property_id}/${dataActivityLivestock.id}`);
  };

  const [showText, setShowText] = useState(false);
  setTimeout(() => {
    setShowText(true);
  }, 500);
  let txt_msn = 'Monitore seus animais nas listas abaixo e depois planeje as práticas de manejo na tela de Atividades de campo!';

  return (
    <>
      <div className="content-wrapper bg-white">
        <div className="content-header" style={{ marginTop: 0 }}>
          <div className="container-fluid">
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex' }}>
                  <img src="/dist/img_fp/icon-button/todas_pecuarias.png" width="45" alt="Imagem geral animais"></img>
                  <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                    <Typography fontWeight="500" variant="h6">
                      Animais
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                      Animais&nbsp;de&nbsp;produção
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ mt: 1 }}>
                  <CardHeader
                    title={
                      <>
                        <Grid container spacing={2} sx={{ mb: 1 }}>
                          <Grid item xs={12} md={12}>
                            <Box sx={{ display: 'flex' }}>
                              {/* <BarChartIcon style={{ fontSize: 35 }} /> */}
                              <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                                <Typography fontWeight="500" variant="h6">
                                  {/* Produção: {nameAtivity?.tipo_repos == "Criações" ? "Criação" : "Colheita"} */}
                                  Acompanhamento dos animais de produção
                                  <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                                    Monitore regularmente o estado de saúde dos animais e avalie seu desempenho.
                                  </Typography>
                                </Typography>
                              </Stack>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={12} alignItems="center">
                            {showText ? (
                              <Box display="flex" alignItems="center">
                                <Typewriter text={txt_msn} variant={'subtitle1'} color={'#2e7d32'} />
                              </Box>
                            ) : (
                              <Box display="flex" alignItems="center">
                                <ThreeDotsSpinner />
                              </Box>
                            )}
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Box display="flex" justifyContent="flex-start">
                              {/* <ButtonRegisterProductionTargetHarvest areas={areas} /> */}
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    }
                  ></CardHeader>
                  <Divider />
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
        {loadingTable === true ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={1}>
                  <Grid item sx={{ width: 70 }}>
                    {/* <Img alt="complex" src="/dist/img_fp/icon-button/todas_pecuarias.png" /> */}
                  </Grid>
                  <Grid item xs={8} md={9}>
                    <Typography variant="h6" color="#28a745" component="div">
                      <Skeleton variant="text" />
                    </Typography>

                    <Typography variant="caption" color="text.secondary" component="div">
                      <Skeleton variant="text" />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Container component="main" maxWidth="lg">
              <Paper variant="" sx={{ my: { xs: 3, md: 2 }, p: { xs: 0, md: 2 } }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ bgcolor: `white` }} aria-label="recipe">
                            <Skeleton variant="circular" width={40} height={40} />
                          </Avatar>
                        }
                        title={
                          <>
                            <Box sx={{ mt: 2, mb: 2 }}>
                              <Typography variant="h6" component="div">
                                <Skeleton variant="text" />
                              </Typography>
                              <Typography sx={{ ml: 0 }} variant="body1" color="text.secondary" component="div">
                                <Skeleton variant="text" />
                              </Typography>
                            </Box>
                          </>
                        }
                        // subheader="September 14, 2016"
                      />
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Card sx={{ width: '70%', maxWidth: '100%', '@media (max-width:600px)': { width: '100%' } }}>
                          <Skeleton variant="rectangular" height={600} />
                        </Card>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
              </Paper>
            </Container>
          </>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={1}>
                  <Grid item sx={{ width: 70 }}>
                    {/* <Img alt="complex" src="/dist/img_fp/icon-button/todas_pecuarias.png" /> */}
                  </Grid>
                  <Grid item xs={8} md={9}>
                    <NavLink
                      to={{
                        pathname: '/controlpanelcreations/' + property_id + '/' + dataActivityLivestock.id,
                      }}
                    >
                      <Typography variant="h6" color="#28a745" component="div">
                        {dataActivityLivestock.label}
                      </Typography>
                    </NavLink>
                    <Typography variant="caption" color="text.secondary" component="div">
                      {dataActivityLivestock.tipo}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Container component="main" maxWidth="lg">
              <Paper variant="" sx={{ my: { xs: 3, md: 2 }, p: { xs: 0, md: 2 } }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ bgcolor: `white` }} aria-label="recipe">
                            {icon}
                          </Avatar>
                        }
                        // action={
                        //   <IconButton aria-label="settings">
                        //     <MoreVertIcon />
                        //   </IconButton>
                        // }
                        title={
                          <>
                            <Box sx={{ mt: 2, mb: 2 }}>
                              <Typography variant="h6" component="div">
                                Número de animais: {dataActivityLivestock.totais.animais} ca
                              </Typography>
                              <Typography sx={{ ml: 0 }} variant="body1" color="text.secondary" component="div">
                                <i className="fa fa-map text-success" /> {getAreaUsed(dataActivityLivestock.areas)} ha
                              </Typography>
                            </Box>
                          </>
                        }
                        // subheader="September 14, 2016"
                      />
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Card sx={{ width: '70%', maxWidth: '100%', '@media (max-width:600px)': { width: '100%' } }}>
                          <CardHeard image_livestok={image_livestok} item={dataActivityLivestock} callBack={handleGetImages} callBackHook={useImagesRepos} handleDelete={handleDelete} />
                        </Card>
                      </div>

                      <CardContent>
                        <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                          <Grid item xs={12}>
                            <Typography variant="body2" color="text.secondary">
                              {`A Criação é composta por ${dataActivityLivestock.lotes ? dataActivityLivestock.lotes.length : 0} lote(s). `}
                            </Typography>
                          </Grid>

                          <Grid item xs={6} sm={3} md={3}>
                            <Box sx={{ mt: 2 }}>
                              <Typography variant="body1" component="div">
                                <strong>Lotes:</strong> {dataActivityLivestock.lotes ? dataActivityLivestock.lotes.length : 0}
                              </Typography>
                              {dataActivityLivestock.lotes &&
                                dataActivityLivestock.lotes.map((item) => (
                                  <Typography key={item.name} variant="body1" component="div">
                                    {item.name}: <strong>{item.num_animais}</strong> ca
                                  </Typography>
                                ))}
                            </Box>
                          </Grid>

                          <Grid item xs={6} sm={3} md={3}>
                            <Box sx={{ mt: 1 }}>
                              <Typography variant="body1" component="div">
                                <strong>Identificados:</strong> {dataActivityLivestock.animais ? dataActivityLivestock.animais.length : 0} ca
                              </Typography>
                              <Box sx={{ mt: 2 }}>
                                <Typography variant="body2" component="div">
                                  <MaleIcon sx={{ fontSize: 20, mt: -0.5 }} />
                                  Machos: {dataActivityLivestock.totais.machos}{' '}
                                </Typography>
                              </Box>
                              <Box sx={{ mt: 1 }}>
                                <Typography variant="body2" component="div">
                                  <FemaleIcon />
                                  Femeas: {dataActivityLivestock.totais.femeas}{' '}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={3} md={3}>
                            <Box sx={{ mt: 1 }}>
                              <Typography variant="body1" component="div">
                                <strong>Peso do Lote</strong>
                              </Typography>
                              <Box sx={{ mt: 1 }}>
                                <Typography variant="body2" component="div">
                                  <strong>Peso Total (Kg)</strong>: {dataActivityLivestock.totais.kilos}{' '}
                                </Typography>
                              </Box>
                              <Box sx={{ mt: 1, mb: 1 }}>
                                <Typography variant="body2" component="div">
                                  <strong>Peso Total (@):</strong> {dataActivityLivestock.totais.arroba}{' '}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions disableSpacing>
                        {/* <IconButton aria-label="add to favorites">
                          <FavoriteIcon />
                        </IconButton>
                        <IconButton aria-label="share">
                          <ShareIcon />
                        </IconButton>
                         */}
                        <Box sx={{ m: 2 }}>
                          <Typography fontWeight="500" variant="h6">
                            Áreas da Criação
                          </Typography>
                        </Box>
                        <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
                          <ExpandMoreIcon />
                        </ExpandMore>
                      </CardActions>
                      <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                          <CardAreaBath listAreas={dataActivityLivestock.listAreas} livestock={dataActivityLivestock} />
                        </CardContent>
                      </Collapse>
                    </Card>
                  </Grid>
                </Grid>
              </Paper>
            </Container>

            <Container component="main" maxWidth="" sx={{}}>
              <Grid container spacing={2} sx={{ mb: 1 }}>
                <Grid item xs={8}>
                  <Box sx={{ display: 'flex' }}>
                    <Stack spacing={0.5} sx={{ ml: 4, mt: 0.5 }}>
                      <Typography fontWeight="700" variant="h6">
                        Mapa do Rebanho
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ display: 'flex' }} alignItems="end">
                    <NavLink to={{ pathname: '/activities/' + property_id + '/' + dataActivityLivestock.id }} className="nav-link">
                      <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} variant="outlined" size="medium" color="success" startIcon={<PendingActions />}>
                        Atividades de campo
                      </Button>
                    </NavLink>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: -2 }}>
                <Grid item xs={8}>
                  <Box sx={{ display: 'flex' }}>
                    <Stack spacing={0.5} sx={{ ml: 4, mt: 0.5 }}>
                      <Typography>
                        <strong>Lista de Lotes:</strong> Informações gerais dos Animais.
                      </Typography>
                      <Typography>
                        <strong>Lista de Identificados:</strong> Informações individuais de cada animal.
                        <Typography></Typography>
                        <strong>Lista de Idade:</strong> Informações sobre a idade do rebanho.
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
              <Paper
                variant=""
                sx={{
                  my: { xs: 1, md: 2 },
                  p: { xs: 2, md: 3 },
                }}
              >
                <LivestockListTableTab livestock={dataActivityLivestock} />
              </Paper>
            </Container>
          </>
        )}
      </div>
    </>
  );
}

import React from 'react';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import { AppBar, Box, Button, Grid, Toolbar, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ChipsArrayAreaMaps from '../ChipsArrayAreaMaps';
import AreaSelectDialog from '../AreaSelectDialog';
import { Span } from '../../../../../components/Typography';
import { expandAndFade } from '../../../../../components/Animations/animationKeyFrames';
import MapaTutorial from '../tutorial/MapaTutorial';

export default function ThirdStep({ data, setData, formError, handleClose, handleNext, handleBack, activeStep, steps, isLoading, setIsLoading }) {
  const isError = () => {
    if (data.areas.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const returnError = () => {
    return false;
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid container spacing={2} sx={{}}>
          <Grid item xs={1} md={1}>
            <LocationOnIcon sx={{ color: 'action.active', mr: 0.5 }} />
          </Grid>
          <Grid item xs={11} md={11}>
            <Typography gutterBottom style={{ color: '#2e7d32' }}>
              <Typewriter text="Defina todas as áreas que você aloca os seus animais." />
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} alignItems="center" sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} alignItems="center">
              <Typography variant="h6" gutterBottom>
                Áreas da criação na propriedade
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} alignItems="center">
              <AreaSelectDialog data={data} setData={setData} isLoading={isLoading} setIsLoading={setIsLoading} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <ChipsArrayAreaMaps data={data} setData={setData} isLoading={isLoading} setIsLoading={setIsLoading} />
          {formError.area && (
            <small id="NameLivestock" className="form-text text-danger is-invalid">
              Defina uma área para a sua criação
            </small>
          )}
        </Grid>
        {data.areas.length > 0 && (
          <Grid item sm={12} xs={12} sx={{ mt: 2 }}>
            <Typography paragraph variant="subtitle1" style={{ color: '#2e7d32' }} fontWeight={500}>
              Clique abaixo em continuar...
            </Typography>
          </Grid>
        )}
      </Grid>

      <AppBar color="success" elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button variant="text" color="inherit" type="submit" onClick={handleClose}>
              <Span>CANCELAR</Span>
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button onClick={handleBack} variant="text" color="inherit" sx={{ marginRight: 2 }} disabled={returnError()}>
              Retornar
            </Button>
            <Button sx={{ my: 2, animation: `${expandAndFade} 2s linear infinite` }} variant="text" color="inherit" onClick={!isError() ? handleNext : () => null} disabled={isError()}>
              {activeStep === steps.length - 1 ? 'Confirmar e Salvar' : 'Continuar'}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

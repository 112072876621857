import React, { useState } from "react";
import { Radio, RadioGroup, FormControlLabel, Typography, Grid } from "@mui/material";
import { useEffect } from "react";
import Typewriter from "../../../../../components/Typewriter/Typewriter";

export default function FormInputStep6ConfirmRadioButtonProgress({ data, setData }) {
    const [selectedFase, setSelectedFase] = useState("realized");
    // const [selectedProgress, setSelectedProgress] = useState("");


    const handleFaseChange = (event) => {
        console.log("event");
        console.log(event.target.value);

        setData((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
        setSelectedFase(event.target.value)
    };

    useEffect(() => {
        setData((prevState) => ({
            ...prevState,
            planning: selectedFase, // Define "Planejamento" como valor inicial
        }));
    }, []); // Executa apenas uma vez no momento da montagem do componente

    // const handleProgressChange = (event) => {
    //     console.log("event");
    //     console.log(event.target.value);

    //     setData((prevState) => ({
    //         ...prevState,
    //         [event.target.name]: event.target.value,
    //     }));
    //     // setSelectedProgress(event.target.value)
    // };

    const faseStyle = (fase) => ({
        backgroundColor: data.stage === fase ? "#5cb85c" : "#fff",
        color: data.stage === fase ? "#fff" : "#333",
        marginRight: "5px",
        borderRadius: "5px",
        padding: "2px",
        cursor: "pointer",
    });

    return (
        <>
            <Grid container spacing={3} alignItems="center" justifyContent="center">
                <Grid item className="d-none d-lg-block">
                    <Typography sx={{ color: '#2e7d32', textAlign: 'center' }}>
                        <Typewriter tam={30} text="Pronto!! Agora basta conferir e clicar em confirmar." />
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} className="d-lg-none">
                    <Typography sx={{ color: '#2e7d32', textAlign: 'center' }}>
                        <Typewriter tam={60} text="Pronto!! Agora basta conferir e clicar em confirmar." />
                    </Typography>
                </Grid>

            </Grid>
            <RadioGroup
                sx={{ mt: 1 }}
                aria-label="planning"
                name="planning"
                value={selectedFase}
                defaultValue=""
                onChange={handleFaseChange}
                style={{ display: "flex", flexDirection: "row" }}
            >
                <FormControlLabel
                    value="realized"
                    name="planning"
                    control={<Radio color="success" />}
                    label={<div style={faseStyle("Realização")}>Realização</div>}
                />
                {/* <FormControlLabel
                    value="planning"
                    name="planning"
                    control={<Radio color="success" />}
                    label={<div style={faseStyle("Planejamento")}>Planejamento</div>}
                /> */}
            </RadioGroup>
            {/* {data.stage === "Realização" && (
                <RadioGroup
                    aria-label="progress"
                    name="progress"
                    value={data.progress}
                    onChange={handleProgressChange}
                    style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
                >
                    <FormControlLabel
                        value="A Fazer"
                        name="progress"
                        control={<Radio color="success" />}
                        label={<div style={faseStyle("A Fazer")}>A Fazer</div>}
                    />
                    <FormControlLabel
                        value="Em Progresso"
                        name="progress"
                        control={<Radio color="success" />}
                        label={<div style={faseStyle("Em Progresso")}>Em Progresso</div>}
                    />
                    <FormControlLabel
                        value="Concluída"
                        name="progress"
                        control={<Radio color="success" />}
                        label={<div style={faseStyle("Concluída")}>Concluída</div>}
                    />
                </RadioGroup>
            )} */}
        </>
    );
}
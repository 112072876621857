import React, { useState, useContext } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Button, ButtonGroup, Grid, Box } from '@mui/material'
import { Divider, FormGroup, FormControlLabel, Switch, Typography } from '@mui/material'
import { AccountTree, ExpandMore, FormatSize, Home, TextDecrease, TextIncrease, Public } from '@mui/icons-material'
import { green, gray } from '@mui/material/colors'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import useStore from '../../store/useStore'
import { Context } from '../../components/Context/AuthContext'
import api from '../../services/api'
import RegisterCostCenterDialog from './RegisterCostCenterDialog'
import Typewriter from '../../components/Typewriter/Typewriter'
import ButtonIncomeFullScreenDialog from './ButtonIncomeFullScreenDialog'
import ButtonExpenseFullScreenDialog from './ButtonExpenseFullScreenDialog'

export default function SettingsContent() {
  const usestore = useStore()
  const userCurrent = usestore.reposUser.userRepos
  const { aux_add, setAuxAdd, isMobile, setMenuContext } = useContext(Context)
  const ismobile = isMobile.any()

  const handleChangeSetting = (event, config, tipo) => {
    console.log('handleChangeSetting')
    userCurrent.settings[config][tipo] = event.target.checked
    //usestore.reposUser.updateUserStore(userCurrent.id, 'settings', userCurrent.settings);
    //localStorage.setItem("userCurrent", JSON.stringify(userCurrent));
    //setAuxAdd(aux_add + 1);
    updateSettings(userCurrent)
  }

  const mountSwitch = (config, tipo) => {
    return (
      <Switch
        checked={userCurrent.settings[config][tipo]}
        onChange={(e) => {
          handleChangeSetting(e, config, tipo)
        }}
        inputProps={{ 'aria-label': 'controlled' }}
        color="success"
        sx={{ mr: 2 }}
      />
    )
  }

  const handleChangeZoom = (tipo) => {
    var valor = Number(window.jQuery(window.jQuery.find('body')).css('zoom'))
    if (tipo === 'mais') {
      valor += 0.1
    } else {
      valor -= 0.1
    }
    valor = Number(valor.toFixed(1))
    if (valor > 1.4) {
      valor = 1.4
    } else if (valor < 0.7) {
      valor = 0.7
    }
    let val_zoom = Number(localStorage.getItem('setting_zoom') || '1')
    if (val_zoom != valor) {
      window.jQuery(window.jQuery.find('body')).css({ zoom: valor })
      setMenuContext(new Date().getTime())
      localStorage.setItem('setting_zoom', valor)
    }
    userCurrent.settings['geral']['fontSize'] = valor
    //usestore.reposUser.updateUserStore(userCurrent.id, 'settings', userCurrent.settings);
    //setAuxAdd(aux_add + 1);
    updateSettings(userCurrent)
  }

  const updateSettings = (new_user_current) => {
    usestore.reposUser.updateSettings(new_user_current)
    setAuxAdd(aux_add + 1)
  }

  return (
    <>
      <div className="content-wrapper bg-white">
        <section className="content-header" style={{ marginTop: 0 }}>
          <div className="container-fluid">
            <Grid container spacing={0} sx={{ mt: 2 }}>
              <Grid item xs={12} md={8}>
                <Typography gutterBottom variant="h5" component="div">
                  Configurações Gerais
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 1, mb: 1, borderColor: green[700] }} />
            <Grid item xs={12} md={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>
                    <Home color="success" sx={{ mr: 1 }} /> Menu Propriedade
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mb: '-15px',
                      mt: '-20px',
                      justifyContent: 'center',
                      textAlign: 'left',
                    }}
                  >
                    <Grid item xs={12} md={12} style={{ justifyContent: 'center' }}>
                      <Typography sx={{ color: '#2e7d32' }}>Ative ou desative os ícones que deseja visualizar no menu da Propriedade.</Typography>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center" sx={{ mt: 0.5 }} spacing={3}>
                    <Grid item xs={ismobile ? 12 : 4} md={ismobile ? 12 : 4} style={{ justifyContent: 'center' }}>
                      <FormGroup>
                        <FormControlLabel control={mountSwitch('icons_property', 'Map')} label="Mapa" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('icons_property', 'Painel')} label="Central" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('icons_property', 'Area')} label="Áreas" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('icons_property', 'Team')} label="Equipe" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('icons_property', 'Messenger')} label="Solicitações" style={{ marginLeft: 0 }} />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={ismobile ? 12 : 4} md={ismobile ? 12 : 4} style={{ justifyContent: 'center' }}>
                      <FormGroup>
                        <FormControlLabel control={mountSwitch('icons_property', 'Livestock')} label="Criações" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('icons_property', 'Harvest')} label="Safras" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('icons_property', 'Product')} label="Produtos" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('icons_property', 'Inventory')} label="Estoque" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('icons_property', 'Patrimony')} label="Patrimônio" style={{ marginLeft: 0 }} />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={ismobile ? 12 : 4} md={ismobile ? 12 : 4} style={{ justifyContent: 'center' }}>
                      <FormGroup>
                        <FormControlLabel control={mountSwitch('icons_property', 'Buy')} label="Compra" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('icons_property', 'Sales')} label="Venda" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('icons_property', 'Financial')} label="Financeiro" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('icons_property', 'Indicators')} label="Indicadores" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('icons_property', 'Reports')} label="Relatórios" style={{ marginLeft: 0 }} />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Divider sx={{ mt: 1, mb: 1, borderColor: green[700] }} />
            <Grid item xs={12} md={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>
                    <AccountTree color="success" sx={{ mr: 1 }} /> Menu Criações/Safras
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mb: '-15px',
                      mt: '-20px',
                      justifyContent: 'center',
                      textAlign: 'left',
                    }}
                  >
                    <Grid item xs={12} md={12} style={{ justifyContent: 'center' }}>
                      <Typography sx={{ color: '#2e7d32' }}>Ative ou desative os ícones que deseja visualizar no menu de Criações/Safras.</Typography>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center" sx={{ mt: 0.5 }} spacing={3}>
                    <Grid item xs={ismobile ? 12 : 4} md={ismobile ? 12 : 4} style={{ justifyContent: 'center' }}>
                      <FormGroup>
                        <FormControlLabel control={mountSwitch('icons_gestao', 'Map')} label="Mapa" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('icons_gestao', 'Painel')} label="Central" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('icons_gestao', 'Area')} label="Áreas" style={{ marginLeft: 0 }} />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={ismobile ? 12 : 4} md={ismobile ? 12 : 4} style={{ justifyContent: 'center' }}>
                      <FormGroup>
                        <FormControlLabel control={mountSwitch('icons_gestao', 'Animais')} label="Animais" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('icons_gestao', 'Culturas')} label="Culturas" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('icons_gestao', 'Activities')} label="Atividades" style={{ marginLeft: 0 }} />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={ismobile ? 12 : 4} md={ismobile ? 12 : 4} style={{ justifyContent: 'center' }}>
                      <FormGroup>
                        <FormControlLabel control={mountSwitch('icons_gestao', 'Planning')} label="Planejamentos" style={{ marginLeft: 0 }} />

                        <FormControlLabel control={mountSwitch('icons_gestao', 'Production')} label="Produção" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('icons_gestao', 'Resources')} label="Recursos" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('icons_gestao', 'ProductionCost')} label="Custo de Produção" style={{ marginLeft: 0 }} />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Divider sx={{ mt: 1, mb: 1, borderColor: green[700] }} />
            <Grid item xs={12} md={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>
                    <Public color="success" sx={{ mr: 1 }} /> Mapa
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mb: '-15px',
                      mt: '-20px',
                      justifyContent: 'center',
                      textAlign: 'left',
                    }}
                  >
                    <Grid item xs={12} md={12} style={{ justifyContent: 'center' }}>
                      <Typography sx={{ color: '#2e7d32' }}>Ative ou desative os itens que deseja visualizar no mapa.</Typography>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center" sx={{ mt: 0.5 }} spacing={3}>
                    <Grid item xs={ismobile ? 12 : 4} md={ismobile ? 12 : 4} style={{ justifyContent: 'center' }}>
                      <FormGroup>
                        <FormControlLabel control={mountSwitch('mapa', 'empty')} label="Áreas vazias" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('mapa', 'livestock')} label="Criações" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('mapa', 'harvest')} label="Safras" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('mapa', 'markers')} label="Marcadores" style={{ marginLeft: 0 }} />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={ismobile ? 12 : 4} md={ismobile ? 12 : 4} style={{ justifyContent: 'center' }}>
                      <FormGroup>
                        <FormControlLabel control={mountSwitch('mapa', 'planning')} label="Planejamentos" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('mapa', 'names_areas')} label="Nomes Áreas" style={{ marginLeft: 0 }} />
                        <FormControlLabel control={mountSwitch('mapa', 'posts')} label="Solicitações" style={{ marginLeft: 0 }} />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={ismobile ? 12 : 4} md={ismobile ? 12 : 4} style={{ justifyContent: 'center' }}>
                      <FormGroup></FormGroup>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Divider sx={{ mt: 1, mb: 1, borderColor: green[700] }} />
            <Grid item xs={12} md={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>
                    <FormatSize color="success" sx={{ mr: 1 }} /> Visualizações/Texto
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mb: '-10px',
                      mt: '-20px',
                      justifyContent: 'center',
                      textAlign: 'left',
                    }}
                  >
                    <Grid item xs={12} md={12} style={{ justifyContent: 'center' }}>
                      <Typography sx={{ color: '#2e7d32' }}>Altere a visualização geral do sistema.</Typography>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center" sx={{ mt: 0.5 }} spacing={3}>
                    <Grid item xs={ismobile ? 12 : 4} md={ismobile ? 12 : 4} style={{ justifyContent: 'center' }}>
                      <ButtonGroup disableElevation color="success" size="small" variant="outlined" aria-label="outlined button group">
                        <Button title="Aumentar fonte" id="option_a1" autoComplete="off" defaultChecked onClick={(e) => handleChangeZoom('mais')}>
                          <TextIncrease sx={{ mr: 1 }} /> Aumentar fonte
                        </Button>
                      </ButtonGroup>
                    </Grid>
                    <Grid item xs={ismobile ? 12 : 4} md={ismobile ? 12 : 4} style={{ justifyContent: 'center' }}>
                      <ButtonGroup disableElevation color="success" size="small" variant="outlined" aria-label="outlined button group">
                        <Button title="Diminuir fonte" id="option_a2" autoComplete="off" onClick={(e) => handleChangeZoom('menos')}>
                          <TextDecrease sx={{ mr: 1 }} /> Diminuir fonte
                        </Button>
                      </ButtonGroup>
                    </Grid>
                    <Grid item xs={ismobile ? 12 : 4} md={ismobile ? 12 : 4} style={{ justifyContent: 'center' }}>
                      <FormGroup>
                        <FormControlLabel control={mountSwitch('geral', 'check_on')} label="Exibir Online/Offline" style={{ marginLeft: 0 }} />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            {/* https://www.youtube.com/watch?v=2x_6fUb3tNg https://www.youtube.com/watch?v=ynD-q7bfKts */}
            <Divider sx={{ mt: 1, mb: 1, borderColor: green[700] }} />
            <Grid container spacing={0} sx={{ mt: 2 }}>
              <Grid item xs={12} md={8}>
                <Typography gutterBottom variant="h5" component="div">
                  Plano de contas
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 1, mb: 1, borderColor: green[700] }} />
            <Grid item xs={12} md={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>
                    <RequestQuoteIcon color="success" sx={{ mr: 1, mt: -0.5 }} /> Plano de contas
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <Grid container spacing={1} sx={{ mb: '-10px', mt: '-20px', justifyContent: "center", textAlign: "left" }}>
                  <Grid item xs={12} md={12} style={{ justifyContent: "center" }}>
                    <Typography sx={{ color: "#2e7d32" }}>
                      Altere a visualização geral do sistema.
                    </Typography>
                  </Grid>
                </Grid> */}
                  <Grid container sx={{ mt: 0.5, mb: 4 }} spacing={1}>
                    <Grid item xs={12} md={12} sx={{ mt: -3, ml: 4.5 }}>
                      <Typography style={{ color: '#2e7d32' }}>
                        <Typewriter tam={40} minHeight={0} text={`O plano de contas é um mapa que lista e organiza todas as categorias de despesas, receitas e ativos financeiros de uma empresa. `} />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ mt: 1, ml: 4.5 }}>
                      <Typography gutterBottom component="div">
                        As contas de entrada são as receitas, como vendas de produtos.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ ml: 4.5 }}>
                      <Typography gutterBottom component="div">
                        As contas de saída são as despesas, como pagamento de fornecedores e salários.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ mt: 1, ml: 4.5 }}>
                      <Typography gutterBottom component="div">
                        Ele é a espinha dorsal da contabilidade da empresa, permitindo a categorização sistemática de todas as entradas e saídas de recursos.
                        <br /> Organize o seu plano de contas. Exclua o que você não utiliza e inclua o que você mais usa.
                      </Typography>
                    </Grid>

                    <Grid container spacing={0} sx={{ mt: 5, mb: -2, ml: 5 }}>
                      <Grid item xs={6} md={6}>
                        <Typography gutterBottom variant="h6" component="div">
                          Categorias (
                          <span
                            style={{
                              color: 'green',
                            }}
                          >
                            RECEITAS
                          </span>
                          )
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <ButtonIncomeFullScreenDialog />
                      </Grid>
                    </Grid>
                    <Grid container spacing={0} sx={{ mt: 5, mb: -2, ml: 5 }}>
                      <Grid item xs={6} md={6}>
                        <Typography gutterBottom variant="h6" component="div">
                          Categorias (<span style={{ color: 'red' }}>DESPESAS</span>)
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <ButtonExpenseFullScreenDialog />
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </div>
        </section>
      </div>
    </>
  )
}

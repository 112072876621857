import React, { useEffect, useContext, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid, Paper, Skeleton, Stack, Typography, Tabs, Tab, Card } from '@mui/material';
import { Context } from '../../components/Context/AuthContext';
import { CardArea } from './components/CardArea';
import api from '../../services/api';
import Loading from '../../components/Loader/Loading';
import html2canvas from 'html2canvas';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { FamilyRestroomTwoTone, Image } from '@mui/icons-material';
import LinearDeterminate from '../../components/Loader/LinearDeterminate';
import LinearIndeterminate from '../../components/Loader/LoagingLinear';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import CreateDrawArea from './components/tutorial/CreateDrawArea';
import RedirectProject from './components/tutorial/RedirectProject';
import AreasMapsCardEmpty from './AreasMapsCardEmpty';
import TutorialAddArea from './components/tutorial/TutorialAddArea';
import useStore from '../../store/useStore';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DeleteIcon from '@mui/icons-material/Delete';
import { expandAndFade } from '../../components/Animations/animationKeyFrames';
import Typewriter from '../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../components/Loader/ThreeDotsSpinner';

export default function AreasMapsContent() {
  const navigate = useNavigate();
  const [areas, setAreas] = useState([]);
  const [areas_l, setAreasL] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [imageNew, setImageNew] = useState();
  const [areaTotal, setAreaTotal] = useState(0);
  const [areaTotalL, setAreaTotalL] = useState(0);

  const [measure, setMeasure] = useState('');
  let file = '';

  const { aux_add, menuContext, setMenuContext } = useContext(Context);
  const usestore = useStore();
  const reposLivestock = usestore.reposLivestock;
  const reposHarvest = usestore.reposHarvest;

  const property = usestore.reposProperty.getPropertyStore('id', usestore.reposProperty.propertyRepos.id)[0];
  const creationHarvestRepos = JSON.parse(localStorage.getItem('creationHarvestRepos'));

  const { id } = useParams();

  const listAreas = usestore.reposAreas.listAreasMap;
  const tipoMenu = localStorage.getItem('navMenuActive') || 'selectProperty';

  let areaMapList = [];
  let areaMapListL = [];
  if (!id || tipoMenu == 'selectProperty') {
    areaMapList = usestore.reposAreas.getAreaMapStore('isDeleted', '0');
    // console.log(areaMapList);

    areaMapListL = usestore.reposAreas.getAreaMapStore('isDeleted', '1');
  } else if (tipoMenu == 'selectCreationHarvest') {
    let creationHarvest = {};
    if (creationHarvestRepos.tipo_repos == 'Safras') {
      creationHarvest = reposHarvest.getHarvestStore('id', id)[0];
    } else {
      //tipo_repos == 'Criações'
      creationHarvest = reposLivestock.getLivestockStore('id', id)[0];
    }
    const arrayAreaUsed = creationHarvest.areas.map((item) => item.id);
    // preenche array apenas com áreas da criação ou safra
    for (const idarea of arrayAreaUsed) {
      const area = usestore.reposAreas.getAreaMapStore('id', idarea);
      areaMapList.push(area[0]);
    }
  } else {
    // if (tipoMenu == 'selectAreaMap') {
    areaMapList = usestore.reposAreas.getAreaMapStore('id', id);
  }

  function imageBuffer() {
    const image = areaMapList && areaMapList.length > 0 ? areaMapList[0].image : '';
    file = image;
    setImageNew(file);
  }

  function getAreaMeasure(area, measure) {
    var area_m = area;
    // 1 hectare = 10.000 metros quadrados
    if (property.measure === 'ha') {
      // measure == 'm2'
      area_m = area * 0.0001;
    } else if (property.measure === 'm2') {
      // measure == 'ha'
      area_m = area / 10000;
    }

    return area_m;
  }

  async function getMapArea() {
    imageBuffer();
    console.log('getMapArea');
    setAreas(areaMapList);
    setAreasL(areaMapListL);

    var area_total = 0;
    areaMapList.map((areaMap) => {
      if (property.measure == areaMap.measure) {
        area_total += parseFloat(areaMap.area);
      } else {
        area_total += getAreaMeasure(parseFloat(areaMap.area), areaMap.measure);
      }
      return areaMap;
    });
    let formattedNumber = area_total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    setAreaTotal(formattedNumber);
    area_total = 0;
    areaMapListL.map((areaMap) => {
      if (property.measure == areaMap.measure) {
        area_total += parseFloat(areaMap.area);
      } else {
        area_total += getAreaMeasure(parseFloat(areaMap.area), areaMap.measure);
      }
      return areaMap;
    });
    formattedNumber = area_total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    setAreaTotalL(formattedNumber);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  useEffect(() => {
    getMapArea();
  }, [aux_add, id]);

  const [value, setValue] = useState(0);
  const [tab_active, setTabActive] = useState('ativa');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    var aux_val = ['ativa', 'excluida'];
    setTabActive(aux_val[newValue]);
  };
  function a11yProps(index) {
    return {
      'id': "areas_" + (index == 0 ? "ativas_" : "lixeira_") + index,
      'aria-controls': "Áreas: " + (index == 0 ? "Ativas" : "Lixeira"),
    };
  }
  //isLoading
  // <Skeleton variant="rectangular" width={120} height={40} />
  //

  const handleNavigate = () => {
    if (id) {
      localStorage.setItem('navMenuActive', 'selectProperty');
      setMenuContext(new Date().getTime());
      navigate('/areasmaps');
    } else {
      navigate('/mapsareasdraw');
    }
  };

  // console.log(areas_l);
  // console.log(areas);

  return (
    <>
      <div className="content-wrapper bg-white">
        <div className="content-header" style={{ marginTop: 0 }}>
          {listAreas.length == 0 && <CreateDrawArea />}
          {listAreas.length > 0 && property.total_safras + property.total_livestocks == 0 && <RedirectProject numberArea={listAreas.length} />}
          <div class=" " style={{ marginTop: 0 }}>
            {/*
            {isLoading == true && (
              <>
                <div className="" style={{ height: "100%", position: "relative", width: "100%", left: 0, right: 0, bottom: 0, top: -15 }}>
                  <LinearIndeterminate />
                </div>
                 <div className="container-fluid">
                  <Grid container spacing={2} sx={{ mb: 0 }}>
                    <Grid item xs={6} md={6}>
                      <Box sx={{ display: "flex" }}>
                        <Skeleton variant="rectangular" width={200} height={55} />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={8} md={6}>
                          <Skeleton variant="rectangular" width={120} height={40} />
                        </Grid>
                        <Grid item xs={4} md={3}>
                          <Skeleton variant="rectangular" width={120} height={40} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div> 
              </>
            )}
            */}
            <div className="container-fluid">
              <Grid container spacing={2} sx={{ mb: 0 }}>
                <Grid item xs={6} md={6}>
                  <Box sx={{ display: 'flex' }}>
                    <CropOriginalIcon sx={{ fontSize: 35 }} color="text.secondary" />
                    <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                      {id ? (
                        <>
                          <Typography fontWeight="500" variant="h6">
                            Áreas&nbsp;de&nbsp;Produção
                          </Typography>
                          {areas_l.length > 0 && (
                            <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                              Áreas&nbsp;de&nbsp;Produção da criação
                            </Typography>
                          )}
                          {areas.length > 0 && (
                            <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                              Áreas&nbsp;de&nbsp;Produção da safra
                            </Typography>
                          )}
                        </>
                      ) : (
                        <>
                          <Typography fontWeight="500" variant="h6">
                            Áreas&nbsp;de&nbsp;Produção
                          </Typography>
                          <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                            Registro&nbsp;das&nbsp;áreas&nbsp;de&nbsp;Produção&nbsp;da&nbsp;propriedade
                          </Typography>
                        </>
                      )}

                      <Typography variant="body2" color="text.secondary">
                        Total: {tab_active == 'ativa' ? areaTotal : areaTotalL} ({property.measure})
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={8} md={6}>
                      <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} color="success" onClick={() => handleNavigate()} variant="contained">
                        {id ? 'Ir para áreas da Propriedade' : 'Criar área'}
                      </Button>
                    </Grid>
                    <Grid item xs={4} md={3}>
                      <TutorialAddArea />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} alignItems="center">
                  <Card sx={{ p: 3 }}>
                    {true ? (
                      <Typewriter text={`Clique em "CRIAR ÁREA" para registrar uma área de produção para a sua criação ou safra.`} variant={'subtitle1'} color={'#2e7d32'} />
                    ) : (
                      <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                        {' '}
                        <ThreeDotsSpinner />
                      </Box>
                    )}
                  </Card>
                </Grid>
              </Grid>
            </div>

            <div className="content ">
              <div className="container-fluid">
                {listAreas.length == 0 ? (
                  <AreasMapsCardEmpty />
                ) : (
                  <>
                    <Box sx={{ width: '100%' }}>
                      {isLoading == true ? (
                        <Box sx={{ m: '5px' }}>
                          <Skeleton variant="rectangular" width={250} height={35} />
                        </Box>
                      ) : (
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: '5px' }}>
                          <Tabs textColor="success" TabIndicatorProps={{ style: { background: 'green' } }} variant="scrollable" value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab
                              label={
                                <Grid container direction="row" alignItems="center">
                                  <PendingActionsIcon sx={{ mr: 0.5, mb: 0.2 }} />
                                  Ativas
                                </Grid>
                              }
                              {...a11yProps(0)}
                            />
                            <Tab
                              style={{ display: !id ? 'flex' : 'none' }}
                              label={
                                <Grid container direction="row" alignItems="center">
                                  <DeleteIcon sx={{ mr: 0.5, mb: 0.2 }} />
                                  Lixeira
                                </Grid>
                              }
                              {...a11yProps(1)}
                            />
                          </Tabs>
                        </Box>
                      )}
                      <Grid container spacing={3} style={{ display: tab_active == 'ativa' ? true : 'none' }}>
                        {areas?.map((areaMap) => (
                          <Grid item xs={12} sm={6} md={3} key={areaMap.id}>
                            {isLoading == true ? <Skeleton variant="rectangular" width="100%" height={300} /> : <CardArea areaMap={areaMap} usestore={usestore} tab_active={tab_active} />}
                          </Grid>
                        ))}
                      </Grid>
                      <Grid container spacing={3} style={{ display: tab_active == 'excluida' ? true : 'none' }}>
                        {areas_l?.map((areaMap) => (
                          <Grid item xs={12} sm={6} md={3} key={areaMap.id}>
                            {isLoading == true ? <Skeleton variant="rectangular" width="100%" height={300} /> : <CardArea areaMap={areaMap} usestore={usestore} tab_active={tab_active} />}
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Fab } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/system";
import { H6, Span } from "../../../../components/Typography";

import SendIcon from "@mui/icons-material/Send";
import DialogMessengerContent from "../../../Messenger/DialogMessenger/DialogMessengerContent";
import FormCardMsn from "./FormCardMsn";
import Drawer from '@mui/material/Drawer';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ButtonDialogMap() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  /* sx={{ display: { xs: 'none', md: 'block' } }} desktop- Esconde em telas maiores que lg 
     sx={{ display: { xs: 'block', md: 'none' } }} mobile - Esconde em telas menores que lg   */
  return (


    <div>
      {['bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Box sx={{ '& > :not(style)': { m: 1 } }}>
            <Fab variant="extended" endIcon={<SendIcon />} sx={{ position: "fixed", bottom: 60, left: 1 }} size="medium" color="success" onClick={toggleDrawer(anchor, true)}>
              <SendIcon />
            </Fab>
          </Box>

          <Drawer
            sx={{ display: { xs: 'none', md: 'block' } }}
            PaperProps={{
              sx: {
                elevation: 8,
                maxWidth: '40%',
                margin: "auto",

              }
            }}
            overflowX='hidden'
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <FormCardMsn />
          </Drawer>

          <Drawer
            sx={{ display: { xs: 'block', md: 'none' } }}
            PaperProps={{
              sx: {
                elevation: 8,
                maxWidth: '90%',
                margin: "auto",

              }
            }}
            overflowX='hidden'
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <FormCardMsn />
          </Drawer>


        </React.Fragment>
      ))}
    </div>


  );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Card, CardHeader, Container, Divider, FormHelperText, Grid, Paper, TextField, Typography } from '@mui/material';
import MenuPlan from './MenuPlan';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, NavLink } from 'react-router-dom';
import { Span } from '../../../components/Typography';
import { useState } from 'react';
import FormLivestockProperty from '../../maps/components/Form/FormLivestockProperty';
import ModalChangePassword from './ModalChangePassword';
import { useEffect } from 'react';

export default function CardChangePassword() {
    const [isSubmitting, setIsSubmitting] = useState(false)

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object().shape({
            oldPassword: Yup.string().min(6).required('Insira a sua senha atual'),
            password: Yup.string().min(6).required('Defina uma nova senha com no mínimo 6 caracteres'),
            confirmPassword: Yup.string().min(6).oneOf([Yup.ref('password')], 'As senhas não coincidem. Tente novamente')

        }),
        onSubmit: (data) => {
            setIsSubmitting(true)
            console.log('onSubmit')
            console.log(data)

        }

    });




    console.log(isSubmitting)



    return (
        <>

            <Card sx={{ m: 2 }}>
                <CardHeader
                    sx={{}}

                    title="Pedro Henrique"
                    subheader="Email: pedro@gmail.com"
                >
                </CardHeader>
                <Divider />
            </Card >

            <Container component="main" maxWidth="sm" sx={{ mb: 3 }}>
                <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={12}
                            sx={{ mt: -5 }}
                        >
                            <Typography variant="subtitle1" >
                                <strong>Atualizando a senha:</strong>
                            </Typography>
                        </Grid>


                        <Grid
                            item
                            xs={12}
                        >
                            <TextField
                                required
                                name="oldPassword"
                                label="Sua senha atual"
                                variant="outlined"
                                size='small'
                                type="oldPassword"
                                fullWidth
                                error={Boolean(formik.touched.oldPassword && formik.errors.oldPassword)}
                                onChange={formik.handleChange}
                                value={formik.values.oldPassword}
                                helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <TextField
                                required
                                name="password"
                                label="Sua nova senha"
                                variant="outlined"
                                size='small'
                                type="password"
                                fullWidth
                                error={Boolean(formik.touched.password && formik.errors.password)}
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                helperText={formik.touched.password && formik.errors.password}
                            />

                        </Grid>

                        <Grid
                            item
                            xs={12}
                        >
                            <TextField
                                required
                                name="confirmPassword"
                                label="Confirmar a nova senha"
                                variant="outlined"
                                size="small"
                                type="password"
                                fullWidth
                                error={Boolean(formik.touched.password && formik.errors.password)}
                                onChange={formik.handleChange}
                                value={formik.values.confirmPassword}
                                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                            />
                        </Grid>
                        {formik.errors.submit && (
                            <Grid
                                item
                                xs={12}
                            >
                                <FormHelperText error>
                                    {formik.errors.submit}
                                </FormHelperText>
                            </Grid>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Typography variant="caption" display="block" gutterBottom>
                            Certifique-se de ter pelo menos 6 caracteres, incluindo um número e uma letra.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{ mt: 3 }}
                    >
                        <Button onClick={formik.handleSubmit} variant="contained" disabled={isSubmitting} color="success">
                            <Span sx={{ textTransform: "capitalize" }}>{formik.isSubmitting ? "Atualizado" : "Atualizar"}</Span>

                        </Button>
                    </Grid>

                </Paper>

                <Grid
                    item
                    xs={12}
                >
                    <Typography variant="subtitle1" >
                        <strong>Definindo uma nova senha:</strong>
                    </Typography>
                    <NavLink to="/forgotpassword">
                        <Button variant="contained" color="primary">
                            <span className="text-black" >Esqueci a minha senha.</span>
                        </Button>

                    </NavLink>
                </Grid>
            </Container>
            <Card sx={{}}>
                <Container component="main" maxWidth="sm" sx={{}}>
                    <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                        <Box sx={{ textAlign: "center" }}>
                            <Grid container xs={12} md={12} item alignItems="center" justifyContent="center">
                                <img src="/dist/image/assinatura.png" name=""></img>

                            </Grid>
                            <Typography sx={{ fontSize: 22 }} color="text.secondary" gutterBottom>
                                Proteja suas informações.
                            </Typography>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Troque a sua senha imediatamente sempre que desconfiar que ela foi descoberta ou que o computador no qual você a utilizou pode ter sido invadido ou infectado.
                            </Typography>
                        </Box>
                    </Paper>
                </Container>
            </Card >
            {formik.isSubmitting && <ModalChangePassword start={true} />}

        </>
    );
}

//https://aegro.com.br/termos-de-uso/
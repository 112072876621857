import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, ButtonBase, Grid, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { AccountBalance, ExpandMore, Input, Payments, PointOfSale, ShoppingCart, Timeline } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import FooterModal from '../../../../../components/Footer/FooterModal';
import ThreeDotsSpinner from '../../../../../components/Loader/ThreeDotsSpinner';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '120px',
  },
}));

export default function FormEntryStep1({ data, obj_fn }) {
  // console.log('FormStep1Entry');
  const classes = useStyles();
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState(true);

  //1-texto para acionar o botao radio
  const [showText, setShowText] = useState(false);
  setTimeout(() => {
    setShowText(true);
    // exibe os botões após começar a escrever
    setTimeout(() => {
      setIsLoadingSkeleton(false);
    }, 400);
  }, 500);

  // const para validar o button continuar
  const isError = () => {
    if (!data.operationType) {
      return true;
    }
    return false;
  };

  const returnError = () => {
    return true;
  };

  const handleChangeOperation = (e, title, name) => {
    console.log('handleChangeOperation');
    obj_fn.setData((prevState) => ({ ...prevState, operationType: title }));
    obj_fn.setData((prevState) => ({ ...prevState, operationName: name }));
    obj_fn.setData((prevState) => ({ ...prevState, category: '' }));
    obj_fn.setData((prevState) => ({ ...prevState, subcategory: '' }));
  };

  const geraButtonGeral = function (obj) {
    const iconStyle = {
      fontSize: 50,
      //  color: obj.name === data.operationName ? 'black' : 'lightgray', // Altere a cor do ícone com base no estado do botão
      color:
        obj.name === 'Despesa'
          ? 'red' // Cor para Despesa
          : obj.name === 'Receita'
          ? 'green' // Cor para Receita
          : obj.name === 'Compra'
          ? 'red' // Cor para Compra
          : obj.name === 'Venda'
          ? 'green' // Cor para Venda
          : 'lightgray', // Cor padrão
      filter: obj.name === data.operationName ? 'brightness(100%)' : 'brightness(70%)', // Ajuste o brilho do ícone
    };

    return (
      <>
        {isLoadingSkeleton ? (
          <>
            <Grid item xs={6} md={6} key={obj.name}>
              <Stack direction="column" alignItems="center">
                <Skeleton variant="rectangular" width="100%" height={120} />
              </Stack>
            </Grid>
          </>
        ) : (
          <Grid item xs={6} md={6} key={obj.name}>
            <ButtonBase
              component={Paper}
              elevation={2}
              sx={{
                p: 2,
                borderRadius: 1,
                border: obj.name === data.operationName ? '2px solid #2e7d3280' : obj.border,
              }}
              onClick={(e) => handleChangeOperation(e, obj.title, obj.name)}
              className={classes.button}
            >
              <Stack direction="column" alignItems="center">
                <Box height={50}>
                  {React.cloneElement(obj.icon, {
                    style: iconStyle,
                  })}{' '}
                  {/* Aplique o estilo ao ícone */}
                </Box>
                <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                  {obj.title}
                </Typography>
                <Typography align="center" sx={{ mt: 0 }} fontWeight={400} variant="caption">
                  {obj.outherName}
                </Typography>
              </Stack>
            </ButtonBase>
          </Grid>
        )}
      </>
    );
  };

  console.log(data.operationType);

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Input />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Lançamento: {data?.operationType}</Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} md={12} alignItems="center" style={{ height: 40 }} sx={{ mb: 2 }}>
          <Typography style={{ color: '#2e7d32' }}>
            {!showText ? <ThreeDotsSpinner /> : <Typewriter text="Por favor, defina o tipo de lançamento desejado." variant={'subtitle1'} color={'#2e7d32'} />}
          </Typography>
        </Grid>

        <Box
          sx={{
            ml: 1,
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Controle de Lançamentos Financeiros: </Typography>
          </Stack>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Gerencie entradas e saídas de dinheiro no caixa, como pagamentos e recebimentos.
          </Typography>
        </Box>

        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 2, mt: 1 }}>
          {geraButtonGeral({
            title: 'Registrar Despesa',
            name: 'Despesa',
            outherName: 'Novo Pagamento',
            icon: <Payments style={{ fontSize: 50 }} />,
          })}
          {geraButtonGeral({
            title: 'Registrar Receita',
            name: 'Receita',
            outherName: 'Novo Recebimento',
            icon: <PointOfSale style={{ fontSize: 50 }} />,
          })}
          <Box
            sx={{
              ml: 3,
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Controle de Lançamento no Financeiro e no Estoque: </Typography>
            </Stack>
            <Typography variant="subtitle1" sx={{ ml: 1 }}>
              Use compra e venda para para registrar simultaneamente a entrada e saída do estoque e do financeiro.
            </Typography>
          </Box>
          {geraButtonGeral({
            title: 'Adicionar Compra',
            name: 'Compra',
            icon: <ShoppingCart style={{ fontSize: 50 }} />,
          })}
          {geraButtonGeral({
            title: 'Adicionar Venda',
            name: 'Venda',
            icon: <MonetizationOnIcon style={{ fontSize: 50 }} />,
          })}
        </Grid>
        <Grid item xs={12} md={12} sx={{ mt: 1 }}>
          {/* <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="gestao_financeira" id="gestao_financeira">
              <Timeline sx={{ mr: 1 }} />
              <Typography>Gestão de estoque: Utilize compra e venda para realizar entradas e saídas financeiras no estoque.</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{
                    xs: 1,
                    sm: 2,
                    md: 2,
                    mt: 1,
                  }}
                >
                  {geraButtonGeral({
                    title: 'Adicionar Compra',
                    name: 'Compra',
                    icon: <ShoppingCart style={{ fontSize: 50 }} />,
                  })}
                  {geraButtonGeral({
                    title: 'Adicionar Venda',
                    name: 'Venda',
                    icon: <PointOfSale style={{ fontSize: 50 }} />,
                  })}
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion> */}
        </Grid>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          {data.operationType !== '' && (
            <Grid item xs={12} md={12} alignItems="center" style={{ height: 40 }}>
              <Typography style={{ color: '#2e7d32' }}>
                {data.operationType === 'Registrar Receita' && <Typewriter tam={0} minHeight={0} text={`Clique em continuar para realizar somente a entrada do dinheiro no financeiro.`} />}
                {data.operationType === 'Registrar Despesa' && <Typewriter tam={0} minHeight={0} text={`Clique em continuar para realizar somente a saída do dinheiro no financeiro.`} />}
                {data.operationType === 'Adicionar Compra' && (
                  <Typewriter
                    tam={0}
                    minHeight={0}
                    text={`Clique em continuar para realizar a compra. O sistema automaticamente fará um pedido de entrada no estoque e registrará a entrada no financeiro.`}
                  />
                )}
                {data.operationType === 'Adicionar Venda' && (
                  <Typewriter
                    tam={0}
                    minHeight={0}
                    text={`Clique em continuar para realizar a venda.O sistema automaticamente fará um pedido de saída no estoque e registrará a entrada no financeiro.`}
                  />
                )}
              </Typography>
            </Grid>
          )}
        </Grid>
        <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
      </Grid>
    </>
  );
}

import create from 'zustand'
import { persist, devtools } from 'zustand/middleware'
import api from '../../services/api'
import dayjs from 'dayjs'
import 'dayjs/locale/pt'

function processData(activityPlanting) {
  return {
    id: activityPlanting.id,
    id_property: activityPlanting.id_property,
    id_user: activityPlanting.id_user,
    name_user: activityPlanting.name_user,
    id_harvest: activityPlanting.id_harvest,
    areas: JSON.parse(activityPlanting.areas),
    /* produto utilizado por área
      areas.map((area) => (
        area.id_area
        area.name
        area.id_product
        area.id_patrimony
        area.name_product
        area.unit
        area.quant
        area.value
        area.usedArea
    */
    date_start: dayjs(activityPlanting.date_start).format('YYYY-MM-DD HH:mm:ss'),
    date_start_f: dayjs(activityPlanting.date_start).format('DD/MM/YYYY'),
    date_prev_end: dayjs(activityPlanting.date_prev_end).format('YYYY-MM-DD HH:mm:ss'),
    date_prev_end_f: dayjs(activityPlanting.date_prev_end).format('DD/MM/YYYY'),
    date_end: activityPlanting.date_end ? dayjs(activityPlanting.date_end).format('YYYY-MM-DD HH:mm:ss') : '',
    date_end_f: activityPlanting.date_end ? dayjs(activityPlanting.date_end).format('DD/MM/YYYY') : '',
    description: activityPlanting.description,
    isDeleted: activityPlanting.isDeleted,
    info_areas: JSON.parse(activityPlanting.info),
    id_transaction: activityPlanting.id_transaction,
    info_transaction: activityPlanting.info_transaction ? JSON.parse(activityPlanting.info_transaction) : '',
    record: activityPlanting.record ? JSON.parse(activityPlanting.record) : '',

    type: 'activity',
    activity: activityPlanting.name,
    name_activity: activityPlanting.name,
    progress: activityPlanting.progress || 'CONCLUÍDA',
    planning: activityPlanting.planning || 'realized',
    tipo_repos: 'PLANTIO',
  }
}

// create the store
const useActivityPlantingReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listActivityPlanting: [],
        fetch: async (item) => {
          var id_property = localStorage.getItem('Property') || 1
          if (!item) {
            item = { id_property: id_property }
          } else if (!item.id_property) {
            item.id_property = id_property
          }
          const header = { header: { 'Content-Type': 'application/json' } }
          const response = await api.post('activityPlanting/list', item, header)
          // console.log('activityPlanting')
          // console.log(data._value)
          const data = await response.data
          const data_v = data._value || []

          await set({
            listActivityPlanting: data_v.map((activityPlanting) => {
              return processData(activityPlanting)
            }),
          })

          const lista = JSON.parse(localStorage.getItem('listActvsH'))
          const lista_i = get().listActivityPlanting

          let array_aux = lista.filter((activity) => {
            if (!/PLANTIO.*/.test(activity.activity)) {
              return activity
            }
          })
          const res = array_aux.concat(lista_i)
          localStorage.setItem('listActvsH', JSON.stringify(res))

          return data
        },
        // pega o(s) plantio(s) da listActivityPlanting de acordo com o [key e val] informados
        getActivityPlantingStore: (key, val) => {
          return get().listActivityPlanting.filter((activityPlanting) => {
            if (activityPlanting[key] == val) {
              return activityPlanting
            }
          })
        },
        // // Adiciona um nov plantio à listActivityPlanting
        addActivityPlantingStore: (plantio) => {
          set((state) => ({
            listActivityPlanting: [...state.listActivityPlanting, plantio],
          }))
        },
        // Gera uma nova listActivityPlanting sem a activity informada
        delPlantingStore: (id) => {
          set({
            listActivityPlanting: get().listActivityPlanting.filter((activity) => {
              if (activity.id != id) {
                return activity
              }
            }),
          })
        },
        // atualiza listActivityPlanting de acordo com id, key e val informados
        updateActivityPlantingStore: (id, key, val) => {
          set({
            listActivityPlanting: get().listActivityPlanting.map((activity) => {
              if (activity.id === id) {
                if (key == 'all') {
                  // atualiza todos os dados
                  activity = val
                } else {
                  activity[key] = val
                }
              }
              return activity
            }),
          })
          return get().listActivityPlanting
        },
        // consulta os lançamentos do ano informado
        fetchFiltered: async (filters) => {
          var id_property = localStorage.getItem('Property') || 1
          if (!filters) {
            filters = {}
          }
          if (!filters.filter) {
            filters.filter = true
          }
          if (!filters.id_property) {
            filters.id_property = id_property
          }
          // carrega dados do ano
          if (!filters.between) {
            filters.date = 'ap.date_start'
            filters.dateI = dayjs().startOf('year').format('YYYY-MM-DD HH:mm:ss')
            filters.dateF = dayjs().endOf('year').format('YYYY-MM-DD HH:mm:ss')
          }

          const header = { header: { 'Content-Type': 'application/json' } }
          const response = await api.post('activityPlanting/list', filters, header)
          const data = await response.data
          const data_v = data._value || []
          // console.log('activityPlanting')
          // console.log(data_v)

          var listActivityPlanting = data_v.map((activityPlanting) => {
            return processData(activityPlanting)
          })

          return listActivityPlanting
        },
      }),
      { name: 'reposActivityPlanting' }
    )
  )
)

export default useActivityPlantingReposStore

import React, { useContext, useEffect, useRef, useState } from 'react';
import LoadScriptOnlyIfNeeded from '../../../LoadScript/LoadScriptOnlyIfNeeded';
import { GoogleMap, Polygon } from '@react-google-maps/api';
// import { DrawingManager, LoadScript, Marker, OverlayView } from '@react-google-maps/api'
import './MapDraw.css';
import DrawingCmp from './DrawingCmp';
import { useNavigate } from 'react-router-dom';
import { decode as base64_decode } from 'base-64';
import SaveMapButton from './FormMapId/SaveMapButton';
// import FloatingButtoDraw from './FormMapId/FloatingButtoDraw'
// import FloatingButtoDrawGeneral from './FormMapId/FloatingButtoDrawGeneral'
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Avatar, Box, Card, Fab, Stack, Typography } from '@mui/material';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import CreateIcon from '@mui/icons-material/Create';
import AdjustIcon from '@mui/icons-material/Adjust';
import ReplyIcon from '@mui/icons-material/Reply';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { LocationOn } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import usePropertyReposStore from '../../../store/usePropertyReposStore';
import { AreasButtonVerticalDraw } from './AreasButtonVerticalDraw';
import useStore from '../../../store/useStore';
import { Context } from '../../../components/Context/AuthContext';

const libraries = ['drawing'];

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const center = {
  lat: -20.9223027,
  lng: -42.7958599,
};

const apiKey = 'AIzaSyBNOeEroFWGH9RPyAUlol-YHLcyHAx0xTU';

// container -> variável para abrir o MapDraw dentro do modal DialogRegisterArea
const MapDraw = ({ container, numberHeight }) => {
  // console.log('MapDraw')
  const navigate = useNavigate();
  const [centerInit, setcenterInit] = useState({});
  var property_id = localStorage.getItem('Property') || 1;

  //  verificar se exite a latitude da propriedade
  const reposProperty = usePropertyReposStore((state) => state);
  //reposProperty.fetch()
  //const property_data = reposProperty.getPropertyStore('id', reposProperty.propertyRepos.id)[0];
  const property_data = reposProperty.getPropertyStore('id', property_id)[0];
  // console.log('property_data[0]')
  // console.log(property_data)

  useEffect(() => {
    setcenterInit({
      lat: Number(property_data?.latitude || '-20.9230016'),
      lng: Number(property_data?.longitude || '-42.7960554'),
      address: property_data?.address || property_data?.address_area || '',
      zoom: Number(property_data?.zoom || '14'),
    });
    //setLoading(true);
  }, []);

  const [area, setArea] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [zoom, setZoom] = useState(centerInit.zoom || 15);
  const [map, setMap] = React.useState();

  const [lat, setLat] = useState(centerInit.lat);
  const [lng, setLng] = useState(centerInit.lng);

  const handleCenterChanged = () => {
    if (!mapRef.current) return;
    const newPos = mapRef.current.getCenter().toJSON();
    setLat(newPos.lat);
    setLng(newPos.lng);
  };

  const onZoomChanged = () => {
    if (!mapRef.current) return;
    setZoom(mapRef.current?.zoom);
  };

  //Referência do mapa:onload do google para obter a referência do mapa
  // prop onLoad: Essa função onLoad recebe um parâmetro padrão que é a instância atual do mapa .
  // map: google.maps.Map
  const onLoad = (map) => {
    // console.log('onLoad')
    mapRef.current = map;
    setMap(map);
  };

  // será usado para ferenciar o mapa
  const mapRef = useRef(null);

  //// Estado para armazenar o caminho do polígono
  const [path, setPath] = useState([]);

  const [drawingModeEnabled, setDrawingModeEnabled] = useState('polygon');

  const handleEnableDrawingMode = (mode) => {
    setDrawingModeEnabled(mode);
  };

  const [circle, setCircle] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const drawingManagerRef = useRef(null);

  const [state, setState] = useState({
    drawingMode: 'polygon',
  });

  const noDraw = () => {
    setDrawingModeEnabled('');
    // console.log('noDraw')
    // // setState({
    // //   // drawingMode: "maker"
    // //   drawingMode: "",
    // // });
    // setState(function set(prevState) {
    //   return Object.assign({}, prevState, {
    //     drawingMode: ""
    //   });
    // });
  };

  const handleRemove = () => {
    setArea(0);
    if (circle) {
      circle.setMap(null);
      setCircle(null);
      handleEnableDrawingMode('circle');
    } else {
      setPath([]);
      handleEnableDrawingMode('polygon');
    }
    setIsButtonDisabled(false);
  };

  // cria um array (paths) com lat e long de cada ponto
  // guarda o array na variável path (setPath)
  const onPolygonComplete = React.useCallback(
    function onPolygonComplete(polygon) {
      // console.log('polygoncomplete')
      const polyArray = polygon.getPath().getArray();
      // console.log(polyArray)
      let paths = [];
      polyArray.forEach(function (path) {
        paths.push({ lat: path.lat(), lng: path.lng() });
      });
      setPath(paths);
      //setPoint(paths);
      // console.log(paths)
      //Para remover o controle das ferramentas de desenho do objeto map:
      //point(paths);
      noDraw();
      polygon.setMap(null);
      setIsButtonDisabled(true);
      //setState({ drawingMode: "" });
      //setDrawingModeEnabled(false);
      // Calcular o centro do polígono
      const bounds = new window.google.maps.LatLngBounds();
      paths.forEach((path) => {
        bounds.extend(new window.google.maps.LatLng(path.lat, path.lng));
      });
      const center = bounds.getCenter();

      // Centralizar o mapa no centro do polígono
      map.panTo(center);
      //onZoomChanged(15);
    }
    //[point]
  );

  useEffect(() => {
    calculateCircleArea();
  }, [circle]);

  const calculateCircleArea = () => {
    if (!circle) {
      // console.log('Nenhum círculo foi desenhado para calcular a área.')
      return;
    }
    setDrawingModeEnabled('');
    const radius = circle.getRadius();
    const areaGoogle = Math.PI * radius * radius;
    // console.log('Área do círculo:', areaGoogle)
    //const formattedArea = areaGoogle.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    setArea(areaGoogle);
  };

  // useEffect(() => {
  //   if (circle) {
  //     setDrawingModeEnabled('');
  //   }
  // }, [circle])

  // const [drawingModeEnabled, setDrawingModeEnabled] = useState(true)
  // const handleDrawingMode = () => {
  //   console.log("handleDrawingMode");
  //   setDrawingModeEnabled(!drawingModeEnabled)
  //   //SetIsEdit(!drawingModeEnabled)
  // }
  // // console.log(drawingModeEnabled)

  const [isEdit, SetIsEdit] = useState(true);

  const style_renderOverlayContent = {
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    bottom: container ? '7%' : '0%',
    padding: '5px',
  };
  const renderOverlayContent = () => (
    <Card style={style_renderOverlayContent}>
      <Box sx={{ p: 1, display: 'flex' }}>
        <Avatar variant="rounded" sx={{ bgcolor: green[500] }}>
          {/* <LocationOn sx={{color: grey[500]}} /> */}
          <LocationOn />
        </Avatar>
        <Stack spacing={0} sx={{ ml: 1 }}>
          <Typography fontWeight={700}>Dimensão</Typography>
          <Typography variant="body2" color="text.secondary">
            {getAreaMeasure()}
          </Typography>
        </Stack>
      </Box>
    </Card>
  );

  function getAreaMeasure() {
    //if (property_data.measure === "m2") { defeault do google é m2
    var area_m = area;
    if (property_data.measure === 'ha') {
      area_m = area * 0.0001; // 1 hectare = 10.000 metros quadrados
    } else if (property_data.measure === 'acres') {
      area_m = area * 0.000247105; // 1 acre = 0.000247105 acres
    }

    return area_m.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (' + property_data.measure + ')';
  }

  const { aux_add } = useContext(Context);
  // console.log('start repos')
  const usestore = useStore();

  // Polygon  áreas das criações/safras
  const [isLoadingPoligon, setIsLoadingPoligon] = useState(false);
  const [plgn_areas, setPolygons] = useState([]);

  // Função para remover um polígono específico
  const removePolygon = (index) => {
    const updatedPolygons = [...plgn_areas];
    updatedPolygons.splice(index, 1);
    setPolygons(updatedPolygons);
  };

  // Função para remover um polígono específico
  const removeAllPolygon = () => {
    setPolygons([]);
  };

  // lista de criações
  const list_livestocks = usestore.reposLivestock.listLivestock;
  // lista de safras
  const list_harvest = usestore.reposHarvest.harvestRepos;
  // lista de áreas
  var list_areas = usestore.reposAreas.listAreasMap;
  // Listas as aéras desenhadas e dentro de cada área tem-se a usada ou []

  // let { id } = useParams();
  const tipoMenu = localStorage.getItem('navMenuActive') || 'selectProperty';
  const [filters, setFilters] = useState({ areas: 'todas' });

  // carrega as áreas que contem as aŕeas e filtra as usadas(areas_used)
  async function getId() {
    // console.log('get poligons areaUsed')
    var paths_coord = [];
    // carrega as áreas que contem o array de areas usadas(areas_used[])
    // var aux_activity = []
    // var aux_activity_lv = []
    let zIndex = 3;
    // var filter_map = {}

    // if (id) {
    //   if (tipoMenu == 'selectCreationHarvest') {
    //     filter_map = JSON.parse(localStorage.getItem("creationHarvestRepos"));
    //     var aux_filter = [];
    //     if (filter_map.tipo_repos == "Safras") {
    //       aux_filter = usestore.reposHarvest.getHarvestStore('id', filter_map.id);
    //     } else {
    //       aux_filter = usestore.reposLivestock.getLivestockStore('id', filter_map.id);
    //     }
    //   } else {
    //     aux_filter = usestore.reposAreas.areaMapRepos;
    //     list_areas = usestore.reposAreas.getAreaMapStore('id', aux_filter.id);
    //     console.log(list_areas)
    //   }
    // }
    // lista todas as areas do reposAreas
    // console.log(list_areas)
    for (const areaUsed of list_areas) {
      //console.log(areaUsed);
      var filter_areas = true;
      // console.log('filters')
      // console.log(filters)
      // if (filters.areas == 'remover') {
      //   console.log(filters)
      //   setPolygons([])
      //   return true
      // }
      if (filters.areas == '' || filters.areas == 'remover') {
        filter_areas = false;
      } else if (filters.areas == 'ocupadas') {
        filter_areas = areaUsed.areas_used.length > 0;
      }

      var mark_lvst = [];
      // [areas_used] -> (livestock / harvest) que estão utilizando a área
      if (filter_areas) {
        // if (filter_map.id) {
        //   // filtra se a área está presente na livestock/harvest filtrada
        //   var filtered = (aux_filter[0].areas).filter((area) => {
        //     if (area.id == areaUsed.id) {
        //       return area;
        //     }
        //   })
        //   if (filtered.length <= 0) {
        //     continue;
        //   }
        // }
        //listou as aŕeas udsadas
        // console.log('areaUsed')
        // console.log(areaUsed)
        let decoded_coordinates = JSON.parse(base64_decode(areaUsed.coordinates));
        const min_max_coords = getPolygonExtremits(decoded_coordinates);
        const centerPoligon = getPolygonCentroid(decoded_coordinates);
        const basePolygon = getPolygonBaseCenter(decoded_coordinates, centerPoligon);
        //const basePolygon = { lat: min_max_coords.min_lat, lng: min_max_coords.min_lng };

        //  aux_activity = reposActivityInput.getActivityStore('id_areamap', areaUsed.id);
        // total da área
        // console.log('total da área')
        //console.log(areaUsed.areas_used)
        const areatotal = areaUsed.area;
        let areaUse = 0;
        let numberAnimal = areaUsed.total_animais;
        let position;
        let id_item = 0;
        // let num_animais = 0
        let cont_position = 0;

        //for (const creation of areaUsed.areas_used) {
        for (var i = 0, tam = areaUsed.areas_used.length; i < tam; i++) {
          const creation = areaUsed.areas_used[i];
          id_item = creation.id;
          // if (filter_map.id) {
          //   if (filter_map.id != id_item) {
          //     continue;
          //   }
          // }
          //position = randomPointInPoly(decoded_coordinates);
          //position = circlePointInPoly(min_max_coords, centerPoligon, (cont_position * 3));
          position = circlePointInPoly(min_max_coords, centerPoligon, cont_position * 3);
          areaUse += parseFloat(creation.areaUsed);

          if (filters.areas != 'todas' && filters.areas != 'ocupadas') {
            if (filters.areas != creation.tipo) {
              continue;
            }
          }

          cont_position++;
          zIndex += 3;
        }

        //if (filters.areas == 'todas') {
        paths_coord.push({
          coordenadas: decoded_coordinates,
          marks: mark_lvst,
          texts_position: basePolygon,
          name: areaUsed.name,
          number_animals: numberAnimal || 0,
          areatotal: areatotal,
          areaUse: areaUse,
        });
        //}
      }
    }
    // console.log('paths_coord')
    // console.log(paths_coord)
    setPolygons(paths_coord);

    setTimeout(() => {
      onZoomChanged((zoom || 15) + Number(Math.random().toFixed(2)) / 10);
    }, 350);
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingPoligon(true);
      setTimeout(() => {
        // console.log('getId- filtro')
        getId();
      }, 1000);
    }, 800);
  }, [aux_add, filters]);

  function getPolygonBaseCenter(polygonCoords, center_poly) {
    /*let centroidX = 0,
      centroidY = 0,
      minLat = Infinity,
      minLatIndex = 0;

    for (let i = 0; i < polygonCoords.length; i++) {
      centroidX += polygonCoords[i].lat;
      centroidY += polygonCoords[i].lng;

      if (polygonCoords[i].lat < minLat) {
        minLat = polygonCoords[i].lat;
        minLatIndex = i;
      }
    }

    //centroidX = minLat + 0.001; // adiciona 20px na coordenada lat
    centroidX = minLat + 0.002;
    centroidY = centroidY / polygonCoords.length - 0.002;

    return { lat: centroidX, lng: centroidY };
    */

    let minLat1 = Infinity,
      minLat2 = Infinity;
    for (let i = 0; i < polygonCoords.length; i++) {
      if (polygonCoords[i].lat < minLat1) {
        minLat1 = polygonCoords[i].lat;
      }
    }
    for (let i = 0; i < polygonCoords.length; i++) {
      if (polygonCoords[i].lat == minLat1) {
        continue;
      }
      if (polygonCoords[i].lat < minLat2 && minLat2 > minLat1) {
        minLat2 = polygonCoords[i].lat;
      }
    }

    let center_base = (minLat1 + minLat2) / 2;
    return { lat: center_base, lng: center_poly.lng };
  }

  function getPolygonExtremits(polygonCoords) {
    let minLat = Infinity,
      minLng = Infinity;
    let maxLat = -Infinity,
      maxLng = -Infinity;
    for (let i = 0; i < polygonCoords.length; i++) {
      if (polygonCoords[i].lat < minLat) {
        minLat = polygonCoords[i].lat;
      }
      if (polygonCoords[i].lng < minLng) {
        minLng = polygonCoords[i].lng;
      }
      if (polygonCoords[i].lat > maxLat) {
        maxLat = polygonCoords[i].lat;
      }
      if (polygonCoords[i].lng > maxLng) {
        maxLng = polygonCoords[i].lng;
      }
    }
    return { max_lat: maxLat, max_lng: maxLng, min_lat: minLat, min_lng: minLng };
  }

  function circlePointInPoly(extremes, center, i) {
    var vari_lat = (extremes.max_lat - extremes.min_lat) / 5;
    var vari_lng = (extremes.max_lng - extremes.min_lng) / 5;
    let latitude = center.lat;
    let longitude = center.lng;

    //rosa dos ventos
    switch (i) {
      case 3:
        longitude = center.lng + vari_lng;
        break;
      case 6:
        latitude = center.lat - vari_lat;
        break;
      case 9:
        longitude = center.lng - vari_lng;
        break;
      case 12:
        latitude = center.lat + vari_lat;
        break;
      // 1 volta
      case 15:
        latitude = center.lat + vari_lat / 1.5;
        longitude = center.lng + vari_lng / 1.5;
        break;
      case 18:
        latitude = center.lat - vari_lat / 1.5;
        longitude = center.lng - vari_lng / 1.5;
        break;
      case 21:
        latitude = center.lat - vari_lat / 1.5;
        longitude = center.lng - vari_lng / 1.5;
        break;
      case 24:
        latitude = center.lat + vari_lat / 1.5;
        longitude = center.lng + vari_lng / 1.5;
        break;
      default:
        latitude = center.lat;
        longitude = center.lng;
    }

    return { lat: latitude, lng: longitude };
  }

  //cálculo do centroide
  function getPolygonCentroid(polygonCoords) {
    let centroidX = 0,
      centroidY = 0;
    let signedArea = 0;

    for (let i = 0; i < polygonCoords.length; i++) {
      let x0 = polygonCoords[i].lat;
      let y0 = polygonCoords[i].lng;
      let x1 = polygonCoords[(i + 1) % polygonCoords.length].lat;
      let y1 = polygonCoords[(i + 1) % polygonCoords.length].lng;

      let a = x0 * y1 - x1 * y0;
      signedArea += a;
      centroidX += (x0 + x1) * a;
      centroidY += (y0 + y1) * a;
    }

    signedArea /= 2;
    centroidX /= 6 * signedArea;
    centroidY /= 6 * signedArea;

    return { lat: centroidX, lng: centroidY };
  }

  //const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const size_h = window.innerHeight;
  const size_w = window.innerWidth;

  // const page_height = container && size_h < 700 ? size_h - 250 + 'px' : size_h < 400 ? size_h + 'px' : '100vh';
  // const page_width = container && size_w < 400 ? size_w - 85 + 'px' : container ? '580px' : size_w < 400 ? size_w + 'px' : '100vw';
  const page_height =
    container && size_h < 700
      ? size_h - 250 + 'px'
      : size_h < 400
      ? size_h + 'px'
      : size_h < 800
      ? '600px' // Define um tamanho fixo para diálogos médios
      : '100vh';

  const page_width =
    container && size_w < 400
      ? size_w - 85 + 'px'
      : container
      ? '580px'
      : size_w < 400
      ? size_w + 'px'
      : size_w < 800
      ? '600px' // Define um tamanho fixo para diálogos médios
      : '100vw';
  //cálculo do centroide
  function getStyleIcons(icon) {
    let style = { position: 'fixed', top: 0, right: 10, backgroundColor: '#fff' };
    if (icon == 'ReplyIcon') {
      style.top = 1;
    } else if (icon == 'HighlightOffIcon') {
      // style.top = container ? (size_w < 400 ? 100 : 70) : 55;
      style.top = container ? (size_w < 400 ? 145 : 125) : 110;
    } else if (icon == 'CreateIcon') {
      style.top = container ? (size_w < 400 ? 190 : 180) : 165;
      // style.top = container ? (size_w < 400 ? 145 : 125) : 110;
    } else if (icon == 'AdjustIcon') {
      style.top = container ? (size_w < 400 ? 190 : 180) : 165;
    } else if (icon == 'PanToolAltIcon') {
      style.top = container ? (size_w < 400 ? 235 : 235) : 220;
    } else if (icon == 'AreasButtonVerticalDraw') {
      style = { position: 'fixed', top: container ? (size_w < 400 ? 145 : 115) : 50, left: 10, width: 60, zIndex: 1111 };
    } else {
    }

    if (window.innerWidth < 400) {
      style.width = 40;
      style.height = 40;
    }
    return style;
  }

  return (
    <>
      <LoadScriptOnlyIfNeeded id="script-loader" googleMapsApiKey={apiKey} libraries={libraries}>
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
          {/* <FloatingButtoDraw /> */}
          {/* <FloatingButtoDrawGeneral path={path} setPath={setPath} drawingModeEnabled={drawingModeEnabled} handleDrawingMode={handleDrawingMode} /> */}
          {container ? (
            <></>
          ) : (
            <LightTooltip title="Retornar" placement="left">
              <Fab sx={getStyleIcons('ReplyIcon')} color="neutral" size="medium" onClick={() => navigate('/areasmaps')} aria-label="edit">
                <ReplyIcon />
              </Fab>
            </LightTooltip>
          )}

          <LightTooltip title="Apagar área" placement="left">
            <Fab sx={getStyleIcons('HighlightOffIcon')} color="neutral" size="medium" onClick={handleRemove} aria-label="add">
              <HighlightOffIcon fontSize="small" sx={{ mr: 0 }} />
            </Fab>
          </LightTooltip>
          <LightTooltip title="Desenho livre" placement="left">
            <Fab disabled={isButtonDisabled} sx={getStyleIcons('CreateIcon')} color="neutral" size="medium" onClick={() => handleEnableDrawingMode('polygon')} aria-label="add">
              <CreateIcon fontSize="small" sx={{ mr: 0 }} />
            </Fab>
          </LightTooltip>
          {/* <LightTooltip title="Círculo" placement="left">
            <Fab disabled={isButtonDisabled} sx={getStyleIcons('AdjustIcon')} color="neutral" size="medium" onClick={() => handleEnableDrawingMode('circle')} aria-label="add">
              <AdjustIcon fontSize="small" sx={{ mr: 0 }} />
            </Fab>
          </LightTooltip> */}
          <LightTooltip title="Editar" placement="left">
            <Fab sx={getStyleIcons('PanToolAltIcon')} color="neutral" size="medium" onClick={() => handleEnableDrawingMode('')} aria-label="add">
              <PanToolAltIcon fontSize="small" sx={{ mr: 0 }} />
            </Fab>
          </LightTooltip>

          <div style={getStyleIcons('AreasButtonVerticalDraw')}>
            <AreasButtonVerticalDraw filters={filters} setFilters={setFilters} />
          </div>

          {/* <EditMapButton drawingModeEnabled={drawingModeEnabled} handleDrawingMode={handleDrawingMode} /> */}
        </Box>
        <SaveMapButton container={container} path={path} area={area} removeAllPolygon={removeAllPolygon} />
        <GoogleMap
          id="map"
          mapContainerStyle={{
            // height: page_height,
            // width: page_width,
            // height: '74vh',
            // height: '92vh',
            height: numberHeight,
            width: '100%',
          }}
          onDragEnd={handleCenterChanged}
          center={centerInit}
          //onDragEnd={handleCenterChanged}
          zoom={Number(zoom)}
          onZoomChanged={onZoomChanged}
          //onZoomChanged={onZoomChanged}
          //// Defina a propriedade onLoad para obter a referência do mapa
          onLoad={onLoad}
          options={{
            mapTypeId: 'satellite', // Define o tipo de mapa padrão como "Satélite"
            //mapTypeControl: true,
            streetViewControl: false,
            fullscreenControl: false,
            zoomControl: true,
            zoomControlOptions: {
              //style: window.google.maps.ZoomControlStyle.SMALL,
              gestureHandling: 'greedy', // Torna o zoom mais sensível
            },
            //zoomControlOptions: {
            //  position: new window.google.maps.ControlPosition.RIGHT_CENTER,
            //  // position: window.google.maps.ControlPosition.TOP_RIGHT, // Posicionando os botões de zoom no canto superior direito
            //  // position: window.google.maps.ControlPosition.BOTTOM_CENTER,
            //  style: new window.google.maps.ZoomControlStyle.SMALL, // Definindo o estilo dos botões de zoom
            //  // Outras opções personalizadas, se necessário
            //},
          }}
        >
          {plgn_areas.map((item) => (
            <>
              <Polygon
                options={{
                  fillColor: item.areaUse > 0 ? 'gray' : 'gray',
                  fillOpacity: 0.7,
                  strokeColor: item.areaUse > 0 ? '#53f444' : '#e0e0e0',
                  strokeWeight: 2,
                }}
                path={item.coordenadas}
              />
            </>
          ))}
          {/* <OverlayView position={center} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
            {renderOverlayContent(area)}
          </OverlayView> */}
          {renderOverlayContent(area)}
          <DrawingCmp
            setIsButtonDisabled={setIsButtonDisabled}
            drawingManagerRef={drawingManagerRef}
            setCircle={setCircle}
            center={center}
            state={state}
            setState={setState}
            onLoad={onLoad}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            onPolygonComplete={onPolygonComplete}
            path={path}
            setPath={setPath}
            drawingModeEnabled={drawingModeEnabled}
            isEdit={isEdit}
            mapRef={mapRef}
            map={map}
            setArea={setArea}
          />
        </GoogleMap>
      </LoadScriptOnlyIfNeeded>
    </>
  );
};

//export default React.memo(MapDraw)
export default MapDraw;
//onLoad, isLoading, setIsLoading, onPolygonComplete, path, setPath, drawingModeEnabled,  optionsDraw, onLoadPoly, onUnmount, isEdit

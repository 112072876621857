import React, { useState } from 'react';
import { Dialog, Container, DialogContent, IconButton, Slide } from '@mui/material'
import { AppBar, Box, Button, Divider, Grid, Paper, Toolbar, Typography } from '@mui/material'
import { Close, Handyman, Search } from '@mui/icons-material';
import { green } from '@mui/material/colors'
import dayjs from 'dayjs';
import HeaderModalNew from '../../../../components/Header/HeaderModalNew';
import FormMaintenancenStep5 from '../form/FormMaintenancenStep5';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ViewMaintenance({ row, usestore, isMobile }) {
  // console.log('ViewMaintenance');
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const property_id = localStorage.getItem('Property') || 1;
  const bankRepos = usestore.reposBank.bankRepos;

  const [open, setOpen] = useState(false)
  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: Number(property_id),
    // step 1
    patrimony: null,
    id_patrimony: null,
    name: '',
    type: '',
    group: '',
    date_start: dayjs(),
    date_start_f: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    date_start_fc: dayjs().format("DD/MM/YYYY"),
    date_prev_end: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    date_end: dayjs().format("YYYY-MM-DD HH:mm:ss"),

    // step 2
    products: [],
    services: [],
    value: 0,
    totalQuantity: 0,

    // step 3
    //  Vincular com e descrição
    projects: [],
    projects_apport: [],
    description: "",

    // step 4
    //  Condições pagamento
    installment: 'a_vista',
    installmentInterval: 30,
    formPayment: "dinheiro",
    bankAccount: bankRepos.id,
    payed: false,
    dueDate: dayjs(),
    dueDate_f: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    dueDate_fc: dayjs().format('DD/MM/YYYY'),
    //  Previsão de pagamento
    fee: 0,
    penalty: 0,
    discount: 0,
    value_end: 0,
    //  Parcelas do pagamento
    arr_installment: [],
    isDeleted: 0,
  })

  const getMaintenance = () => {
    //seta infos para data
    let this_maintenance = JSON.parse(JSON.stringify(usestore.reposPatrimonyMaintenance.getPatrimonyMaintenanceStore('id', row.id)[0]))
    let aux_patrimony = usestore.reposPatrimony.getPatrimonyStore('id', this_maintenance.id_patrimony)[0]
    let aux_data = data

    aux_data.id_user = this_maintenance.id_user
    aux_data.id_property = this_maintenance.id_property
    // step 1
    aux_data.patrimony = aux_patrimony
    aux_data.id_patrimony = this_maintenance.id_patrimony
    aux_data.name = aux_patrimony.name
    aux_data.type = aux_patrimony.type
    aux_data.group = aux_patrimony.group

    aux_data.date_start = dayjs(this_maintenance.date_start)
    aux_data.date_start_f = dayjs(this_maintenance.date_start).format("YYYY-MM-DD HH:mm:ss")
    aux_data.date_start_fc = dayjs(this_maintenance.date_start).format("DD/MM/YYYY")
    aux_data.date_prev_end = dayjs(this_maintenance.date_prev_end).format("YYYY-MM-DD HH:mm:ss")
    aux_data.date_end = dayjs(this_maintenance.date_end).format("YYYY-MM-DD HH:mm:ss")

    // step 2
    let aux_item, aux_loc
    aux_data.products = this_maintenance.products.map((product, index) => {
      aux_item = usestore.reposProducts.getProductsStore('id', product.id_product)[0]
      aux_loc = aux_item.location.filter((loc) => loc.id = product.id_location)
      aux_loc.quant_utilizada = product.quant
      aux_loc.value = product.value
      return {
        id: index + 1,
        product: aux_item,
        location_selected: aux_loc,
      }
    })
    aux_data.services = this_maintenance.services.map((service, index) => {
      return {
        id: index + 1,
        person: service.id_person,
        category: service.id_expense,
        subcategory: service.id_expense_sub,
        descriptionEntry: service.description,
        value: service.value,
        value_end: service.value_end,
      }
    })
    aux_data.value = this_maintenance.value
    aux_data.totalQuantity = this_maintenance.info_pay.quanty

    // step 3
    //  Vincular com e descrição
    aux_data.projects = this_maintenance.info_pay.projects || []
    aux_data.projects_apport = this_maintenance.info_pay.projects_apport || []
    aux_data.description = this_maintenance.description || ""

    // step 4
    //  Condições pagamento
    aux_data.installment = this_maintenance.info_pay.installment || 'a_vista'
    aux_data.installmentInterval = 30
    aux_data.formPayment = this_maintenance.info_pay.formPayment || "dinheiro"
    aux_data.bankAccount = this_maintenance.info_pay.id_bank || bankRepos.id
    aux_data.payed = false
    aux_data.dueDate = dayjs(this_maintenance.date_start)
    aux_data.dueDate_f = dayjs(this_maintenance.date_start).format('YYYY-MM-DD HH:mm:ss')
    aux_data.dueDate_fc = dayjs(this_maintenance.date_start).format('DD/MM/YYYY')
    //  Previsão de pagamento
    aux_data.fee = this_maintenance.info_pay.fee || 0
    aux_data.penalty = this_maintenance.penalty || 0
    aux_data.discount = this_maintenance.discount || 0
    aux_data.value_end = this_maintenance.value + aux_data.fee + aux_data.penalty - aux_data.discount;

    //  Parcelas do pagamento
    aux_data.arr_installment = usestore.reposBill.getBillStore('id_transaction', this_maintenance.id_trans_pay)
    // aux_data.arr_installment = this_maintenance.info_pay.arr_installment || []

    const obj_fn = {
      usestore: usestore,
      setData: setData,
      ismobile: isMobile.any(),
    };

    return <FormMaintenancenStep5 data={aux_data} obj_fn={obj_fn} />
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const optionsIcon = {
    title: 'Manutenção Patrimonial:',
    txt_msn: "Informações da manutenção",
    icon: <Handyman color="success" />,
  };

  const optionsImage = {
    title: 'Progresso',
    txt_msn: "Informações da manutenção",
    icon: <Handyman style={{ fontSize: 80 }} />,
  };

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }} color="inherit">
          <Toolbar>
            <Grid item container direction="row" alignItems="center" justifyContent="center" style={{ maxWidth: 900 }}>
              <Grid item>
                <Typography variant="h6" component="div"> Informações da Manutenção</Typography>
              </Grid>
            </Grid>
            <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Fechar"> <Close /> </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent sx={{ mb: 8, mt: -4 }}>
          <Container component="main" maxWidth="md" sx={{ mt: 0 }}>
            <Paper variant="" sx={{ my: { xs: 1, md: 4 }, p: { xs: 2, md: 1 } }}>
              <Box sx={{ width: '100%' }}>
                <HeaderModalNew optionsIcon={optionsIcon} optionsImage={optionsImage} />

                {!open ? <></> : getMaintenance() }

                <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
                  <Toolbar>
                    <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                      <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                        Cancelar
                      </Button>
                    </Box>
                  </Toolbar>
                </AppBar>
              </Box>
            </Paper>
          </Container>
        </DialogContent>
      </Dialog>
      <IconButton aria-label="Visualizar" onClick={() => handleClickOpen()} title="Visualizar">
        <Search sx={{ color: green[500] }} />
      </IconButton>
    </div>
  );
}

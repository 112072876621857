import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Card, CardHeader, Container, Divider, Grid, Paper, Skeleton, TableCell, TableRow, Typography } from "@mui/material";
import Typewriter from "../../../components/Typewriter/Typewriter";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function TableProductionCardEmpty({ isLoadingActivities, setIsLoadingActivities, title }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingText, setIsLoadingText] = useState(false);
  const navigate = useNavigate();
  const { idFarm, id } = useParams();
  // const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  // console.log('userCurrent')
  // console.log(userCurrent)
  // const user = userCurrent;
  // console.log(user)
  // const name = user.name;
  // const charName = name[0].toUpperCase();
  // console.log(charName);
  // function stringAvatar(name) {
  //     return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
  // }
  setTimeout(() => {
    setIsLoading(false);
  }, 1000);

  setTimeout(() => {
    setIsLoadingText(true);
  }, 3000);

  return (
    <>
      {isLoading === true ? (
        <>
          <Skeleton variant="rectangular" width="100%" height={600} />
        </>
      ) : (
        <>
          <Card sx={{ m: 1 }}>
            <CardHeader
              title={
                <>
                  <div className="d-lg-none">
                    <Box sx={{ display: "flex", mb: 1 }}>{title == "Safra" && <Typography sx={{ fontSize: 18 }}>No momento não temos registro da colheita</Typography>}</Box>
                    <Box sx={{ minHeight: 110 }}>
                      <Typography style={{ color: "#2e7d32" }}>
                        {isLoadingText && <Typewriter tam={110} minHeight={0} text="Para visualizar a colheita, precisamos primeiramente definir a quantidade a produzir na aba A PRODUZIR ." />}
                      </Typography>
                    </Box>
                  </div>

                  <div className="d-none d-lg-block">
                    <Box sx={{ display: "flex", mb: 1 }}>
                      {title == "Safra" && (
                        <Typography variant="h6" color="textPrimary">
                          No momento não temos registro da colheita
                        </Typography>
                      )}
                    </Box>
                    <Box sx={{ minHeight: 30 }}>
                      <Typography style={{ color: "#2e7d32" }}>
                        {isLoadingText && <Typewriter tam={30} minHeight={0} text="Para visualizar a colheita, precisamos primeiramente definir a quantidade a produzir na aba A PRODUZIR." />}
                      </Typography>
                    </Box>
                  </div>
                </>
              }
            ></CardHeader>

            <Divider />
          </Card>
          <Card sx={{ m: 1 }}>
            <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
              <Paper variant="" sx={{ my: { xs: 3, md: 1 }, p: { xs: 2, md: 1 } }}>
                <Box sx={{ textAlign: "center" }}>
                  <Grid container xs={12} md={12} sx={{ m: -2 }} item alignItems="center" justifyContent="center">
                    <img src="/dist/image/atividades.png" name=""></img>
                  </Grid>
                  {/* <Grid container xs={12} md={12} sx={{ mt: 1 }} item alignItems="center" justifyContent="center">
                                        <AddActivities isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} />
                                    </Grid> */}
                  <Grid container xs={12} md={12} sx={{ m: 1 }} item alignItems="center" justifyContent="center">
                    {/* <Typography sx={{ fontSize: 22 }} color="text.secondary" gutterBottom>
                      Gestão das atividades
                    </Typography> */}
                    {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      Registre todas as atividades do dia a dia, planeje com antecedência as tarefas futuras e obtenha ideias(insights) valiosos para impulsionar sua produtividade. Desperte o
                      potencial da sua propriedade e comece hoje mesmo a colher os frutos de uma gestão eficiente e estratégica. Clique no botão verde<strong> + NOVA ATIVIDADE</strong> no canto
                      superior direito para iniciar sua atividade.
                    </Typography> */}
                    <Grid container spacing={2} sx={{ mb: -1 }}>
                      {/* <Grid item xs={12} sm={12}>
                        <Box height="80px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
                          <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                            <Button variant="outlined" color="success" startIcon={<i className="fa fa-trophy text-success mr-1" />} onClick={() => navigate("/planning/" + idFarm + "/" + id)}>
                              Planejamento da meta de Produção
                            </Button>
                          </Box>
                        </Box>
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Container>
          </Card>
        </>
      )}
    </>
  );
}

//https://aegro.com.br/termos-de-uso/

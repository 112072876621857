import * as React from 'react';
import Draggable from 'react-draggable';
import { Dialog, DialogContent, Divider, Drawer, Box, Fab, IconButton, Paper, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormSaveMapButton from './FormSaveMapButton';
import SaveIcon from '@mui/icons-material/Save';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import { expandAndFade } from '../../../../components/Animations/animationKeyFrames';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export default function SaveMapButton({ container, path, area, removeAllPolygon }) {
  const [state, setState] = React.useState({ left: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (!path || path.length < 1) {
      toast.warning('Desenhe uma área de no mínimo 3 lados!');
      return false;
    }
    setAnchorEl(event.currentTarget);
    toggleDrawer('left', true);
  };
  function handleClose() {
    setAnchorEl(null);
    toggleDrawer('left', false);
  }

  var style_btn = { position: 'fixed', top: container ? 305 : 320, right: 18, animation: `${expandAndFade} 2s linear infinite` };
  if (window.innerWidth < 400) {
    style_btn.width = 40;
    style_btn.height = 40;
  }
  return (
    <>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <LightTooltip title="Salvar área" placement="left">
            <Fab
              sx={style_btn}
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              //onClick={toggleDrawer(anchor, true)}
              onClick={handleClick}
              color="success"
              aria-label="add"
              size="medium"
            >
              <SaveIcon fontSize="small" sx={{ mr: 0 }} />
              {/* <HighlightAltIcon sx={{ mr: 0.5 }} />
            Área */}
            </Fab>
          </LightTooltip>
          {container ? (
            <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" fullWidth={true} maxWidth={'xs'}>
              <DialogContent style={{ padding: '2px' }}>
                <FormSaveMapButton handleClose={handleClose} path={path} area={area} removeAllPolygon={removeAllPolygon} container={container} />
              </DialogContent>
            </Dialog>
          ) : (
            <Drawer anchor={anchor} anchorEl={anchorEl} open={open} onClose={handleClose}>
              <Box sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 380 }} role="presentation" onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                <Typography sx={{ p: 2 }} variant="BUTTON TEXT">
                  Criação da Área*
                </Typography>
                <IconButton sx={{ ml: 'auto' }} color="inherit" onClick={handleClose} aria-label="Close">
                  <CloseIcon />
                </IconButton>
                <Divider />
                <FormSaveMapButton container={container} handleClose={handleClose} path={path} area={area} removeAllPolygon={removeAllPolygon} />
              </Box>
            </Drawer>
          )}
        </React.Fragment>
      ))}
    </>
  );
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, CardMedia, Grid, Stack, Typography } from '@mui/material';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import SelectHarvest from '../addHarvest/SelectHarvest';

export default function StartProjectHarvest() {
  const [open, setOpen] = React.useState(true);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
        <DialogTitle>
          <Grid container spacing={3} sx={{ p: 2 }}>
            <img src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="Fazenda Premium Logo" className="brand-image" style={{ opacity: '.8', height: 32, maxWidth: '100%' }} />
            <Box sx={{ ml: 1, display: 'flex' }}>
              <Stack spacing={0.5} sx={{ mt: 0 }}>
                <Typography variant="h6" style={{ color: '#808080' }} fontWeight={500}>
                  Fazenda Premium
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Typography variant="h5" color="textPrimary" gutterBottom>
                Bem vindo a Criação de Projetos Agrícolas
              </Typography>
              {/* <Typography gutterBottom sx={{ mt: 0, mb: 2 }} fontWeight={500} style={{ color: '#2e7d32' }}>
                <Typewriter tam={60} text="Com mais gestão, você ganha tempo com informações claras e objetivas, reduz custos operacionais e aumenta a eficiência produtiva da sua operação!" />
              </Typography> */}
            </Grid>
            <Grid item sm={12} xs={12} style={{ textAlign: 'center' }}>
              <img
                src="/dist/image/gestaoagricola.png"
                alt="Gestão Agrícola"
                style={{
                  width: 'auto', // Define a largura da imagem
                  height: '450px', // Mantém a proporção da imagem
                  objectFit: 'contain', // Ajusta a imagem para caber no espaço
                }}
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <Box sx={{ mt: 2 }}>
                <Typography paragraph variant="subtitile2" fontWeight={300}>
                  <strong>Gestão organizada! Aqui, cada projeto inclui atividades específicas, insumos detalhados e despesas individualizadas.</strong>
                </Typography>
                {/* <Typography paragraph variant="subtitile2" fontWeight={300}>
                  Descubra como é fácil criar os projetos pecuários e iniciar a Gestão Agrícola.
                </Typography> */}
              </Box>
            </Grid>
          </Grid>

          <Grid item sm={12} xs={12}>
            <Box sx={{ mt: 0 }}>
              <Typography paragraph variant="subtitile2" fontWeight={300}>
                Clique a seguir no <strong style={{ color: '#2e7d32' }}>botão verde (+ CRIAR PROJETO)</strong> para inciarmos um projeto pecuário...
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <SelectHarvest />
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button color="success" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useActivityInputReposStore from '../../../store/Activities/useActivityInputReposStore';
import FirstAcessMapList from './FirstAcessMapList';
import CreateAreaList from './CreateAreaList';
import { Box, Grid, IconButton, ListItem, ListItemText, Typography } from '@mui/material';
import Typewriter from '../../../components/Typewriter/Typewriter';
import Bot from '../../../components/Animations/Bot';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import CreateProjectList from './CreateProjectList';

export default function CreateProjectInit({ propertyName }) {
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const text = 'Seguiremos para tela áreas, e é lá que iremos desenhar as áreas onde colocaremos os animais e as culturas da propriedade. Clique no botão áreas, logo abaixo. ';

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open alert dialog
            </Button> */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <Grid container sx={{ mt: 0, mb: 2 }}>
            <Grid item sm={10} xs={12}>
              <div className="d-lg-none">
                <Box>
                  <Typography gutterBottom sx={{ mt: 1, mb: 0 }} fontWeight={500} style={{ color: '#2e7d32' }}>
                    <Typewriter tam={90} text={`Registrado a propriedade ${propertyName} no mapa, é hora de criar um projeto pecuário ou agrícola.`} />
                  </Typography>
                </Box>
              </div>

              <div className="d-none d-lg-block">
                <Box>
                  <Typography gutterBottom sx={{ mt: 0, mb: 0 }} fontWeight={500} style={{ color: '#2e7d32' }}>
                    <Typewriter tam={60} text={`Registrado a propriedade ${propertyName} no mapa, é hora de criar um projeto pecuário ou agrícola.`} />
                  </Typography>
                </Box>
              </div>
            </Grid>
            {/* <Grid item sm={2} xs={12} sx={{ textAlign: 'right', mt: -3 }}>
              <Bot text={text} />
            </Grid> */}
          </Grid>
          <ListItem
            key={null}
            disableGutters
            secondaryAction={
              <IconButton aria-label="comment">
                <CropOriginalIcon style={{ color: '#2e7d32' }} />
              </IconButton>
            }
          >
            <ListItemText primary={<strong>Passo 3:</strong>} secondary="Registrar sua primeira safra ou criação." />
          </ListItem>

          <CreateProjectList />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button onClick={handleClose} color="success" autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

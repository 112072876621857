import React, { useState } from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { Map } from '@mui/icons-material'
import FooterModal from '../../../../../components/Footer/FooterModal'
// import ThreeDotsSpinner from '../../../../../components/Loader/ThreeDotsSpinner'
// import Typewriter from '../../../../../components/Typewriter/Typewriter'
// import AddIcon from '@mui/icons-material/Add'
// import { styled } from '@mui/material/styles'
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import AutocompleteWithAddOption from '../../../../../components/Fields/AutocompleteWithAddOption'

// const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: 'rgba(0, 0, 0, 0.87)',
//     boxShadow: theme.shadows[1],
//     fontSize: 11,
//   },
// }))


export default function FormSoilPreparationStep1({ data, obj_fn }) {
  // const [showText, setShowText] = useState(false)
  // setTimeout(() => {
  //   setShowText(true)
  // }, 2000)

  // useEffect(() => {
  //   setShowText(false)
  // }, [])

  // const para validar o button continuar
  const isError = () => {
    var valid = false
    if (!data.selectedOperation) {
      valid = true
    }
    return valid
  }
  const returnError = () => {
    return true
  }

  // Monta o array e obj para o campo de projeto
  // const optionsAreas = obj_fn.usestore.reposAreas.getAreaUsedMapStore([
  //   ['id', data.id_harvest],
  //   ['tipo', 'harvest'],
  // ])

  // const opt_areas = {
  //   multiple: true,
  //   name: 'areas',
  //   label: 'Áreas da Safra',
  //   values: optionsAreas,
  //   field: 'areas',
  //   variant: 'standard',
  //   helperText: 'Selecione a(s) área(s) para o plantio',
  //   listHeight: 225,
  // }
  // const handleChangeArea = (field, newValue) => {
  //   console.log('handleChangeArea')
  //   if (newValue && newValue.length > 0) {
  //     newValue.map((area) => {
  //       if (!area.product) {
  //         area.product = null
  //       }
  //       return area
  //     })
  //   }
  //   obj_fn.setData((prevState) => ({ ...prevState, [field]: newValue }))
  // }

  // const handleChangeInput = (e, area) => {
  //   console.log('handleChangeInput')
  //   //console.log(e.target.value, area)
  //   const usedArea = e.target.value ? parseFloat(e.target.value) : ''

  //   obj_fn.setData((prevState) => ({
  //     ...prevState,
  //     areas: prevState.areas.map((area_prev) => {
  //       if (area_prev.id === area.id) {
  //         return { ...area_prev, usedArea }
  //       }
  //       return area_prev
  //     }),
  //   }))
  // }

  // const handleChange = (event) => {
  //   obj_fn.setData((prevState) => ({ ...prevState, [event.target.name]: event.target.value }))
  // }

  // const filters_prods = [
  //   ['description', 'agricola'],
  //   ['description', 'todos'],
  // ]

  // const operations = ['Aração', 'Gradagem', 'Subsolagem', 'Rotação de culturas', 'Calagem', 'Adubação']
  const [operations, setOperations] = useState(data.listOperations)

  // console.log(data)

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid container spacing={3} sx={{ mb: 0, mt: 2 }}>
          <Map />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Informações essenciais</Typography>
            </Stack>
          </Box>
        </Grid>
        {true && (
          <>
            <Grid container spacing={3} sx={{ mt: 0 }}>
              <Grid item xs={12} sm={12}>
                <Typography fontWeight={500}>Abaixo, escolha ou defina um método de preparo para melhoria do solo.</Typography>
                {/* {true ? (
                  <>
                    <Box display="flex" alignItems="center">
                      <div className="d-lg-none">
                        <Typography gutterBottom style={{ color: '#2e7d32' }}>
                          <Typewriter tam={80} minHeight={0} text=" Sugerimos alguns nomes para o seu trabalho a campo, mas sinta-se a vontade de personalizá-lo. " />
                        </Typography>
                      </div>
                    </Box>
                    <div className="d-none d-lg-block" style={{ height: 30 }}>
                      <Typography gutterBottom style={{ color: '#2e7d32' }}>
                        <Typewriter tam={0} minHeight={0} text=" Sugerimos alguns nomes para o seu trabalho a campo, mas sinta-se a vontade de personalizá-lo. " />
                      </Typography>
                    </div>

                    <></>
                  </>
                ) : (
                  <Box display="flex" alignItems="center" style={{ height: 30 }}>
                    <ThreeDotsSpinner />
                  </Box>
                )} */}
              </Grid>
              <AutocompleteWithAddOption obj_fn={obj_fn} operations={operations} setOperations={setOperations} operation={data.selectedOperation}/>

              <Grid item xs={12} sm={12} sx={{ mt: 0 }}>
                {/* <Typography variant="caption" display="block" gutterBottom sx={{ ml: 2, color: '#808080' }}>
                  Ex.: Aração, Gradagem, Subsolagem, Rotação de culturas, Calagem, Adubação...
                </Typography> */}
                <Typography variant="caption" display="block" gutterBottom sx={{ ml: 2, color: '#808080' }}>
                  Clique na seta a direita e especifique qual operação está sendo realizada na área.
                </Typography>
                <Typography variant="caption" display="block" gutterBottom sx={{ ml: 2, color: '#808080' }}>
                  Para definir uma nova, basta digitar o nome do preparo desejado.
                </Typography>
              </Grid>
            </Grid>
          </>
        )}

        {data.selectedOperation && (
          <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
            <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
              <Typography variant="subtitle1" display="block" gutterBottom sx={{ ml: 2, color: '#2e7d32' }}>
                Clique abaixo em continuar
              </Typography>
            </Grid>
          </Grid>
        )}

        {/* {data.selectedOperation && (
          <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
            <Grid item xs={12} sm={7} sx={{ mt: 1 }}>
              <p>teste</p>
            </Grid>
            <Grid item xs={12} sm={5}></Grid>
          </Grid>
        )} */}

        {/* Descrição */}
        {/* <Grid container spacing={3} sx={{ mb: 0, mt: 2 }}>
          <Notes />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
                {' '}
                Descrição geral do plantio
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 1, mt: 0 }}>
          <Grid item xs={12} sm={12}>
            <TextareaAutosize
              minRows={3} // Customize the initial number of rows
              maxRows={10} // Customize the maximum number of rows
              id="description"
              name="description"
              placeholder="Informações auxiliares para o plantio"
              value={data.observations}
              onChange={handleChange}
              onBlur={(e) => handleChange(e, true)}
              style={{ width: '100%', padding: '8px' }}
            />
          </Grid>
        </Grid> */}

        <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
      </Grid>
    </>
  )
}

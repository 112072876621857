import * as React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import PatrimonyTabs from './components/PatrimonyTabs';
import RegisterNewPatrimony from './RegisterNewPatrimony';
import RegisterNewMaintenance from './RegisterNewMaintenance';
import TutorialPatrimony from './TutorialPatrimony';
import Typewriter from '../../components/Typewriter/Typewriter';
import TutorialStepGeral from '../../components/Tutorial/TutorialStepGeral';

export default function PatrimonyContent() {
  // const [updateComponent, setUpdateComponent] = useState(0);

  const objText = {
    icon: <MapsHomeWorkIcon sx={{ fontSize: 35 }} color="text.secondary" />, // Substitua pelo ícone desejado
    title: 'Controle de Estoque', // Título do diálogo
    subtitle: 'Passo a Passo', // Subtítulo abaixo do título
    textAnimation: 'Controle o patrimônio da propriedade através de entradas, saídas, depreciações e manutenções.', // Texto que será animado pelo componente Typewriter
    description: 'Clique no botão abaixo e siga o passo a passo:', // Descrição adicional
    textButton: ['1- Registrar um novo patrimônio', '2- Realizar uma nova manutenção no patrimônio', '3- Visusalizar a depreciação'], // Texto complementar ou final
    linkButton: ['openPatrimonyRegistrarModal', 'openMaintenanceRegistrarModal', '/patrimony'], // O primeiro agora vai abrir o modal
    // linkButton: ['openPatrimonyRegistrarModal', '/inventory'], // Primeiro é um componente, segundo é um link
    filters_prods: [['group_category']],
  };

  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12} md={4}>
              <Box sx={{ display: 'flex' }}>
                <MapsHomeWorkIcon sx={{ fontSize: 35 }} color="text.secondary" />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    {/* Produção: {nameAtivity?.tipo_repos == "Criações" ? "Criação" : "Colheita"} */}
                    Patrimônio
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                    Gerencia&nbsp;os&nbsp;patrimônios&nbsp;da&nbsp;propriedade
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <RegisterNewPatrimony />
            </Grid>
            <Grid item xs={8} md={3}>
              <RegisterNewMaintenance />
            </Grid>
            <Grid item xs={12} md={2}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ gap: 2 }}>
                <TutorialStepGeral objText={objText} />
                <TutorialPatrimony />
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Box sx={{ minHeight: 35 }}>
                <Typography style={{ color: '#2e7d32' }}>
                  <Typewriter tam={30} minHeight={0} text="Bem-vindo à gestão eficiente do ciclo de vida dos patrimônios da sua propriedade." />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="content ">
        <div className="">
          <PatrimonyTabs />
        </div>
      </div>
    </div>
  );
}

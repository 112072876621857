import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Box, Button, CircularProgress, Divider, ListItem, ListItemText, ListSubheader, Menu, Stack } from '@mui/material'
import { BottomNavigationAction, ButtonBase, Grid, Paper, Typography } from '@mui/material'
import { green, grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import MapIcon from '@mui/icons-material/Map'
import AppsIcon from '@mui/icons-material/Apps'
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PublicIcon from '@mui/icons-material/Public'
import SpaIcon from '@mui/icons-material/Spa'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import GroupsIcon from '@mui/icons-material/Groups'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork'
import WidgetsIcon from '@mui/icons-material/Widgets'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import ListAltIcon from '@mui/icons-material/ListAlt'
import SignpostIcon from '@mui/icons-material/Signpost'
import PersonIcon from '@mui/icons-material/Person'
import CoPresentIcon from '@mui/icons-material/CoPresent'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import KeyIcon from '@mui/icons-material/Key'
import PollIcon from '@mui/icons-material/Poll'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import GrassIcon from '@mui/icons-material/Grass'
import HomeIcon from '@mui/icons-material/Home'
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import { Settings } from '@mui/icons-material'
import CategoryIcon from '@mui/icons-material/Category'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'
import useStore from '../../../store/useStore'
import { Context } from '../../Context/AuthContext'
import IconsLivestockActivities from '../../Icons/IconsLivestockActivities'

const useStyles = makeStyles((theme) => ({
  button: {
    width: '75px',
    height: '50px',
    //boxShadow: '0px 3px 1px -2px rgb(46 125 50 / 20%), 0px 2px 2px 0px rgb(46 125 50 / 14%), 0px 1px 5px 0px rgb(46 125 50 / 12%)',
    boxShadow: '0px 0px 0px -0px rgb(46 125 50 / 20%), 0px 0px 0px 0px rgb(46 125 50 / 14%), 0px 0px 0px 0px rgb(46 125 50 / 12%) !important',
    '&:hover': {
      backgroundColor: grey[200],
      borderRadius: '15px',
    },
  },
}))

export function StyleBN() {
  return {
    bgcolor: '#FFF',
    '& .Mui-selected': {
      '& .MuiBottomNavigationAction-label': {
        fontSize: (theme) => theme.typography.caption,
        transition: 'none',
        fontWeight: 'bold',
        lineHeight: '20px',
      },
      '& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label': {
        color: 'green', //theme => theme.palette.secondary.main
      },
    },
  }
}

export function GetReposValues() {
  const usestore = useStore()

  const propertyRepos = usestore.reposProperty.propertyRepos
  const livestockRepos = usestore.reposLivestock.livestockRepos
  const harvestRepos = usestore.reposHarvest.harvestRepos
  const creationHarvestRepos = localStorage.getItem('creationHarvestRepos')
  const areaMapRepos = usestore.reposAreas.areaMapRepos

  const display_demo = JSON.parse(localStorage.getItem('display')) || false

  const userCurrent = usestore.reposUser.userRepos

  return {
    propertyRepos: propertyRepos,
    livestockRepos: livestockRepos,
    harvestRepos: harvestRepos,
    creationHarvestRepos: creationHarvestRepos ? JSON.parse(creationHarvestRepos) : { label: '', id: 0, tipo_repos: '' },
    areaMapRepos: areaMapRepos,
    display_demo: display_demo,
    userCurrent: userCurrent,
  }
}

export function ToolsMenuButtons(tipoMenu, handleClose, tipo) {
  const navigate = useNavigate()
  const classes = useStyles()
  const activeButton = localStorage.getItem('ButtonActive')

  const aux_repos = GetReposValues()
  const propertyRepos = aux_repos.propertyRepos
  const livestockRepos = aux_repos.livestockRepos
  const harvestRepos = aux_repos.harvestRepos
  const areaMapRepos = aux_repos.areaMapRepos
  const display_demo = aux_repos.display_demo
  const creationHarvestRepos = aux_repos.creationHarvestRepos
  const userCurrent = aux_repos.userCurrent

  const { setMenuContext } = React.useContext(Context)

  const geraButtonGeral = function (obj) {
    const handleChange = (event, newValue) => {
      // console.log("newValue");
      // console.log(event.currentTarget.name);
      let value = event.currentTarget.name || newValue
      localStorage.setItem('ButtonActive', value)

      const navMenuActive = localStorage.getItem('navMenuActive') || 'selectProperty'
      if (tipoMenu && tipoMenu !== navMenuActive) {
        localStorage.setItem('navMenuActive', tipoMenu)
      }

      setMenuContext(new Date().getTime())
    }

    if (obj.nao_exibe) {
      return <></>
    } else if (tipo === 'link') {
      return (
        <NavLink
          to={obj.path || '/'}
          className="nav-link"
          name={obj.title || 'Mapa'}
          value={obj.title || 'Mapa'}
          onClick={(e) => {
            handleChange(e, obj.title)
            handleClose()
          }}
        >
          {obj.icon || <PublicIcon />}
          <p style={{ marginLeft: '5px', color: 'black' }}> {obj.title || 'Mapa'} </p>
        </NavLink>
      )
    } else if (tipo === 'botton') {
      return (
        <BottomNavigationAction
          onClick={(e) => {
            handleChange(e, obj.title)
            handleClose(e)
            navigate(obj.path || '/')
          }}
          name={obj.title || 'Mapa'}
          value={obj.title || 'Mapa'}
          label={obj.title || 'Mapa'}
          icon={obj.icon || <PublicIcon />}
          style={{ whiteSpace: 'nowrap', width: '85px' }}
        />
      )
    } else {
      return (
        <Grid item xs={3} md={3} key={obj.title} style={{ maxWidth: '91px', paddingTop: '15px' }} name={obj.title || 'Mapa'}>
          <ButtonBase
            component={Paper}
            elevation={2}
            sx={{ p: 2, borderRadius: 1 }}
            onClick={(e) => {
              handleChange(e, obj.title)
              handleClose(e)
              navigate(obj.path || '/')
            }}
            className={classes.button}
            name={obj.title || 'Mapa'}
          >
            <Stack direction="column" alignItems="center" style={{ whiteSpace: 'nowrap' }}>
              <Box height={15} sx={{ color: activeButton === obj.title ? 'green' : 'black' }}>
                {obj.icon || <PublicIcon />}
              </Box>
              <Typography align="center" sx={{ mt: 1, fontSize: '12px', color: activeButton === obj.title ? 'green' : 'black' }} variant="body1">
                {obj.title || 'Mapa'}
              </Typography>
            </Stack>
          </ButtonBase>
        </Grid>
      )
    }
  }

  return {
    geraButtonGeral,
    getButtonMap: function () {
      // var visualize_map = 0
      var user_setting = true
      var path = ''

      if (tipoMenu === 'selectProperty') {
        path = '/mapa'
        user_setting = userCurrent.settings['icons_property']['Map']
      } else if (tipoMenu === 'selectCreationHarvest') {
        if (!propertyRepos.id || !creationHarvestRepos.id) {
          return <></>
        }
        path = '/mapa/' + propertyRepos.id + '/' + creationHarvestRepos.id
        user_setting = userCurrent.settings['icons_gestao']['Map']
      } else if (tipoMenu === 'selectAreaMap') {
        if (!propertyRepos.id || !areaMapRepos || !areaMapRepos.id) {
          return <></>
        }
        path = '/mapa/' + propertyRepos.id + '/' + areaMapRepos.id
      }

      if (!user_setting) {
        return <></>
      } else {
        return geraButtonGeral({ path: path, icon: <PublicIcon />, title: 'Mapa' })
      }
    },
    getButtonPainel: function () {
      // var permission_painel = 0
      var user_setting = true
      var path = ''
      var title = ''

      if (tipoMenu === 'selectProperty') {
        path = '/painel'
        title = 'Central'
        user_setting = userCurrent.settings['icons_property']['Painel']
      } else if (tipoMenu === 'selectCreationHarvest') {
        if (creationHarvestRepos.tipo_repos === 'Safras') {
          if (!propertyRepos.id || !harvestRepos || !harvestRepos.id) {
            return <></>
          }
          path = '/controlpanelharvest/' + propertyRepos.id + '/' + harvestRepos.id
        } else {
          if (!propertyRepos.id || !livestockRepos || !livestockRepos.id) {
            return <></>
          }
          path = '/controlpanelcreations/' + propertyRepos.id + '/' + livestockRepos.id
        }
        title = 'Painel'
        user_setting = userCurrent.settings['icons_gestao']['Painel']
      } else if (tipoMenu === 'selectAreaMap') {
        if (!propertyRepos.id || !areaMapRepos || !areaMapRepos.id) {
          return <></>
        }
        path = '/ControlpanelspecificArea/' + propertyRepos.id + '/' + areaMapRepos.id
        title = 'Painel'
      }
      if (!user_setting) {
        return <></>
      } else {
        return geraButtonGeral({ path: path, icon: tipoMenu === 'selectProperty' ? <AppsIcon /> : <DashboardIcon />, title: title })
      }
    },
    getButtonArea: function () {
      // var permission_painel = 0
      var user_setting = true
      var path = ''
      var title = 'Áreas'

      if (tipoMenu === 'selectProperty') {
        path = '/areasmaps'
        user_setting = userCurrent.settings['icons_property']['Area']
      } else if (tipoMenu === 'selectCreationHarvest') {
        path = '/areasmaps/' + creationHarvestRepos.id
        user_setting = userCurrent.settings['icons_gestao']['Area']
      } else if (tipoMenu === 'selectAreaMap') {
        path = '/areasmaps/' + propertyRepos.id + '/' + areaMapRepos.id
      }
      if (!user_setting) {
        return <></>
      } else {
        return geraButtonGeral({ path: path, icon: <MapIcon />, title: title })
      }
    },
    getButtonTeam: function () {
      // var permission_painel = 0
      var user_setting = userCurrent.settings['icons_property']['Team']
      var path = ''

      if (tipoMenu !== 'selectProperty' || !user_setting) {
        return <></>
      } else {
        path = '/team'
      }
      return geraButtonGeral({ path: path, icon: <GroupsIcon />, title: 'Equipe' })
    },
    getButtonSolicitacoes: function () {
      // var permission_painel = 0
      var user_setting = userCurrent.settings['icons_property']['Messenger']
      var path = ''

      if (tipoMenu !== 'selectProperty' || !user_setting) {
        return <></>
      } else {
        path = '/messenger'
      }
      return geraButtonGeral({ path: path, icon: <ConnectWithoutContactIcon />, title: 'Solicitações' })
    },
    getButtonCreations: function () {
      // var permission_painel = 0
      var user_setting = userCurrent.settings['icons_property']['Livestock']
      var path = ''

      if (tipoMenu !== 'selectProperty' || !user_setting) {
        return <></>
      } else {
        path = '/livestock'
      }
      return geraButtonGeral({ path: path, icon: <IconsLivestockActivities activity={'Geral'} widthStyles={'30px'} fill={'#1f2d3d'} />, title: 'Criações' })
    },
    getButtonAnimaisCulturas: function () {
      // var permission_painel = 0
      var user_setting = true
      var path = ''
      var icon = ''
      var title = ''

      if (tipoMenu !== 'selectCreationHarvest') {
        return <></>
      } else if (creationHarvestRepos.tipo_repos === 'Safras') {
        path = '/harvest/' + propertyRepos.id + '/' + harvestRepos.id
        icon = <GrassIcon />
        title = 'Culturas'
        user_setting = userCurrent.settings['icons_gestao']['Culturas']
      } else {
        path = '/livestock/' + propertyRepos.id + '/' + livestockRepos.id
        icon = <IconsLivestockActivities activity={'Geral'} widthStyles={'30px'} fill={'#1f2d3d'} />
        title = 'Animais'
        user_setting = userCurrent.settings['icons_gestao']['Animais']
      }
      if (!user_setting) {
        return <></>
      } else {
        return geraButtonGeral({ path: path, icon: icon, title: title })
      }
    },
    getButtonSafras: function () {
      // var permission_painel = 0
      var user_setting = userCurrent.settings['icons_property']['Harvest']
      var path = ''

      if (tipoMenu !== 'selectProperty' || !user_setting) {
        return <></>
      } else {
        path = '/harvest'
      }
      return geraButtonGeral({ path: path, icon: <SpaIcon />, title: 'Safras' })
    },
    getButtonInventory: function () {
      // var permission_painel = 0
      var user_setting = userCurrent.settings['icons_property']['Inventory']
      var path = ''

      if (tipoMenu !== 'selectProperty' || !user_setting) {
        return <></>
      } else {
        path = '/inventory'
        return geraButtonGeral({ path: path, icon: <WidgetsIcon />, title: 'Estoque' })
      }
    },
    getButtonProduct: function () {
      // var permission_painel = 0
      var user_setting = userCurrent.settings['icons_property']['Product']
      var path = ''

      if (tipoMenu !== 'selectProperty' || !user_setting) {
        return <></>
      } else {
        path = '/productcatalog'
        return geraButtonGeral({ path: path, icon: <CategoryIcon />, title: 'Produtos' })
      }
    },
    geraButtonPatrimony: function () {
      // var permission_painel = 0
      var user_setting = userCurrent.settings['icons_property']['Patrimony']
      var path = ''

      if (tipoMenu !== 'selectProperty' || !user_setting) {
        return <></>
      } else {
        path = '/patrimony'
        return geraButtonGeral({ path: path, icon: <MapsHomeWorkIcon />, title: 'Patrimônio' })
      }
    },
    geraButtonBuy: function () {
      // var permission_painel = 0
      var user_setting = userCurrent.settings['icons_property']['Buy']
      var path = ''

      if (tipoMenu !== 'selectProperty' || !user_setting) {
        return <></>
      } else {
        path = '/buy'
        return geraButtonGeral({ path: path, icon: <ShoppingCartIcon />, title: 'Compra' })
      }
    },
    geraButtonSales: function () {
      // var permission_painel = 0
      var user_setting = userCurrent.settings['icons_property']['Sales']
      var path = ''

      if (tipoMenu !== 'selectProperty' || !user_setting) {
        return <></>
      } else {
        path = '/sales'
        return geraButtonGeral({ path: path, icon: <LocalShippingIcon />, title: 'Venda' })
      }
    },
    getButtonFinancial: function () {
      // var permission_painel = 0
      var user_setting = userCurrent.settings['icons_property']['Financial']
      var path = ''

      if (tipoMenu !== 'selectProperty' || !user_setting) {
        return <></>
      } else {
        path = '/financial'
        return geraButtonGeral({ path: path, icon: <LocalAtmIcon />, title: 'Financeiro' })
      }
    },
    getButtonPlanning: function () {
      // var permission_painel = 0
      var user_setting = userCurrent.settings['icons_gestao']['Planning']
      var path = ''
      if (tipoMenu !== 'selectCreationHarvest' || !user_setting) {
        return <></>
      } else {
        if (creationHarvestRepos.tipo_repos === 'Safras') {
          path = '/planningHarvest/' + propertyRepos.id + '/' + harvestRepos.id
        } else {
          path = '/planning/' + propertyRepos.id + '/' + livestockRepos.id
        }
      }
      return geraButtonGeral({ path: path, icon: <SignpostIcon />, title: 'Planejamentos' })
    },

    // selectProperty
    // selectCreationHarvest
    // selectAreaMap
    getButtonActivities: function () {
      // var permission_painel = 0
      var path = ''
      var user_setting = userCurrent.settings['icons_gestao']['Activities']

      if (tipoMenu !== 'selectCreationHarvest' || !user_setting) {
        return <></>
      } else {
        if (creationHarvestRepos.tipo_repos === 'Safras') {
          path = '/activitiesHarvest/' + propertyRepos.id + '/' + harvestRepos.id
        } else {
          path = '/activities/' + propertyRepos.id + '/' + livestockRepos.id
        }
      }
      return geraButtonGeral({ path: path, icon: <PendingActionsIcon />, title: 'Atividades' })
    },

    getButtonResources: function () {
      // var permission_painel = 0
      var user_setting = userCurrent.settings['icons_gestao']['Resources']
      var path = ''

      if (tipoMenu !== 'selectCreationHarvest' || !livestockRepos.id || !user_setting) {
        return <></>
      } else {
        path = '/resources/' + propertyRepos.id + '/' + livestockRepos.id
        return geraButtonGeral({ path: path, icon: <ListAltIcon />, title: 'Recursos' })
      }
    },
    getButtonProduction: function () {
      // var permission_painel = 0
      var user_setting = userCurrent.settings['icons_gestao']['Production']
      var path = ''

      if (tipoMenu !== 'selectCreationHarvest' || !creationHarvestRepos.id || !user_setting) {
        return <></>
      } else {
        path = '/production/' + propertyRepos.id + '/' + creationHarvestRepos.id
        return geraButtonGeral({ path: path, icon: <EmojiEventsIcon />, title: 'Produção' })
      }
    },
    getButtonProductionCost: function () {
      // var permission_painel = 0
      var user_setting = userCurrent.settings['icons_gestao']['ProductionCost']
      var path = ''

      if (tipoMenu !== 'selectCreationHarvest' || !creationHarvestRepos.id || !user_setting) {
        return <></>
      } else {
        path = '/productioncost/' + propertyRepos.id + '/' + creationHarvestRepos.id
        return geraButtonGeral({ path: path, icon: <CurrencyExchangeIcon />, title: 'Custo Prod.' })
      }
    },
    geraButtonIndicadores: function () {
      // var permission_painel = 0
      var user_setting = userCurrent.settings['icons_property']['Indicators']
      var path = ''

      if (tipoMenu !== 'selectProperty' || !user_setting) {
        return <></>
      } else {
        path = '/indicators'
        return geraButtonGeral({ path: path, icon: <PollIcon />, title: 'Indicadores' })
      }
    },
    geraButtonRelatorios: function () {
      // var permission_painel = 0
      var user_setting = userCurrent.settings['icons_property']['Reports']
      var path = ''

      if (tipoMenu !== 'selectProperty' || !user_setting) {
        return <></>
      } else {
        path = '/reports'
        return geraButtonGeral({ path: path, icon: <ReceiptLongIcon />, title: 'Relatórios' })
      }
    },
    geraButtonGuiaRapido: function (tipo) {
      // var permission_painel = 0
      if (!tipo) {
        return <></>
      }
      var path = '/quickGuide'
      return geraButtonGeral({ path: path, icon: <LocalLibraryIcon />, title: 'Guia Rápido' })
    },
    geraButtonMeusDados: function (tipo) {
      // var permission_painel = 0
      if (!tipo) {
        return <></>
      }
      var path = '/profile'
      return geraButtonGeral({ path: path, icon: <PersonIcon />, title: 'Meus Dados' })
    },
    geraButtonPropriedades: function (tipo) {
      // var permission_painel = 0
      if (!tipo) {
        return <></>
      }
      var path = '/property'
      return geraButtonGeral({ path: path, icon: <HomeWorkIcon />, title: 'Propriedades' })
    },
    geraButtonSettings: function (tipo) {
      // var permission_painel = 0
      if (!tipo) {
        return <></>
      }
      var path = '/settingsuser'
      return geraButtonGeral({ path: path, icon: <Settings />, title: 'Configurações' })
    },
    geraButtonAssinatura: function (tipo) {
      // var permission_painel = 0
      if (!tipo || display_demo) {
        return <></>
      }
      var path = '/signature'
      return geraButtonGeral({ path: path, icon: <AssignmentTurnedInIcon />, title: 'Assinatura' })
    },
    geraButtonImagens: function (tipo) {
      // var permission_painel = 0
      if (!tipo || display_demo) {
        return <></>
      }
      var path = '/image'
      return geraButtonGeral({ path: path, icon: <InsertPhotoIcon />, title: 'Imagens' })
    },
    geraButtonIndiceExperiencia: function (tipo) {
      // var permission_painel = 0
      if (!tipo) {
        return <></>
      }
      var path = '/indexExperience'
      return geraButtonGeral({ path: path, icon: <CoPresentIcon />, title: 'Ind. Experiência' })
    },
    geraButtonChangePassword: function (tipo) {
      // var permission_painel = 0
      if (!tipo || display_demo) {
        return <></>
      }
      var path = '/changepassword'
      return geraButtonGeral({ path: path, icon: <KeyIcon />, title: 'Mudar senha' })
    },
  }
}

export default function ToolsMenu({ tipoMenu, select_tools }) {
  const navMenuActive = localStorage.getItem('navMenuActive') || 'selectProperty'
  //console.log(navMenuActive)
  const { menuContext } = React.useContext(Context)

  if (!tipoMenu) {
    tipoMenu = navMenuActive
  }
  const [anchorEl, setAnchorEl] = useState(null)
  //console.log(anchorEl)

  const activeButton = localStorage.getItem('ButtonActive')
  const [value, setValue] = useState(tipoMenu === navMenuActive ? activeButton || 'Central' : '')

  //problema com o false
  //console.log('problemaX')
  const open = Boolean(anchorEl)
  //console.log('open')
  //console.log(open)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (e) => {
    //if (window.jQuery(e.target).parents().length > 3) {
    //  var element_name = window.jQuery(e.currentTarget).parents().find('#basic-menu').attr('name');
    //  localStorage.setItem("navMenuActive", element_name.replace(/tipoMenu_(.*)/, '$1'));
    //}
    setAnchorEl(null)
  }

  const [isLoading, setIsLoading] = useState(true)

  // const { id } = useParams()
  const aux_repos = GetReposValues()
  const propertyRepos = aux_repos.propertyRepos

  useEffect(() => {
    //atualiza a mudança do seletor em propertyRepos
    //console.log("ToolsMenu");
    if (propertyRepos !== undefined) {
      setIsLoading(false)
    }
    const activeButton_aux = localStorage.getItem('ButtonActive')
    if (activeButton_aux !== value) {
      setValue(activeButton_aux || 'Central')
    }
    //if (activeButton_aux !== value) {
    //  const navMenuActive_aux = localStorage.getItem('navMenuActive') || 'selectProperty'
    //  setValue(tipoMenu === navMenuActive_aux ? activeButton_aux || 'Central' : '')
    //}
  }, [propertyRepos, menuContext])

  //var userCurrent = JSON.parse(localStorage.getItem("userCurrent"));
  //console.log('userCurrentpermissions ')
  //const teste_permission = JSON.parse(localStorage.getItem("permission"));
  //teste_permission.role = userCurrent.role;

  const getColorButton = function () {
    var color = 'success'
    if (tipoMenu === 'selectProperty') {
      // green["28A"] = "#28A745";
      // color = green["28A"];
      grey['2C2'] = '#2C2C2C'
      color = grey['2C2']
    } else if (tipoMenu === 'selectCreationHarvest') {
      // green["10A"] = "#10A37F";
      // color = green["10A"];
      green['28A'] = '#28A745'
      color = green['28A']
    } else if (tipoMenu === 'selectAreaMap') {
      grey['2C2'] = '#2C2C2C'
      color = grey['2C2']
    }
    if (tipoMenu !== navMenuActive) {
      color = grey['500']
    }
    return color
  }

  const getTitle = function () {
    var title = 'Propriedade'
    if (tipoMenu === 'selectProperty') {
      title = 'Propriedade'
    } else if (tipoMenu === 'selectCreationHarvest') {
      title = 'Criações/Safras'
    } else if (tipoMenu === 'selectAreaMap') {
      title = 'Áreas'
    }
    return title
  }

  const getIcon = function () {
    var icon = 'Administração'
    if (tipoMenu === 'selectProperty') {
      icon = <HomeIcon fontSize="large" sx={{ mt: 0.5 }} style={{ color: getColorButton() }} />
    } else if (tipoMenu === 'selectCreationHarvest') {
      icon = <AccountTreeIcon fontSize="large" sx={{ mt: 0.5, ml: 0.5 }} style={{ color: getColorButton() }} />
    } else if (tipoMenu === 'selectAreaMap') {
      icon = <AddLocationAltIcon fontSize="large" sx={{ mt: 0.5 }} style={{ color: getColorButton() }} />
    }
    return icon
  }
  const buttonsMenu = ToolsMenuButtons(tipoMenu, handleClose)

  // const handleChange = (event, newValue) => {
  //   localStorage.setItem('ButtonActive', newValue)
  //   setValue(newValue)
  //   setMenuContext(new Date().getTime())
  // }

  return (
    <>
      {isLoading === true ? (
        <Stack sx={{ color: grey[500], mt: 1 }} spacing={2} direction="row">
          <CircularProgress color="success" />
        </Stack>
      ) : (
        <div style={{ marginTop: '-9px' }}>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{ color: getColorButton(), minWidth: '35px', width: '40px' }}
            disabled={!aux_repos.creationHarvestRepos?.id}
          >
            {getIcon()}
          </Button>
          <div sx={{ overflowY: 'auto', margin: 10, padding: 0 }}>
            <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={(e) => handleClose(e)} MenuListProps={{ 'aria-labelledby': 'basic-button' }} name={'tipoMenu_' + tipoMenu}>
              <ListSubheader sx={{ mb: 0 }}>
                <ListItemText> {getTitle()}</ListItemText>
              </ListSubheader>
              <Divider />
              <Box style={{ width: '290px', padding: '15px' }}>
                <Grid container spacing={3} sx={{}}>
                  {buttonsMenu.getButtonPainel()}
                  {buttonsMenu.getButtonMap()}
                  {buttonsMenu.getButtonArea()}
                  {buttonsMenu.getButtonTeam()}
                  {buttonsMenu.getButtonSolicitacoes()}
                  {buttonsMenu.getButtonCreations()}
                  {buttonsMenu.getButtonSafras()}
                  {buttonsMenu.getButtonAnimaisCulturas()}
                  {buttonsMenu.getButtonProduct()}
                  {buttonsMenu.getButtonInventory()}
                  {buttonsMenu.getButtonActivities()}
                  {buttonsMenu.geraButtonPatrimony()}
                  {buttonsMenu.geraButtonBuy()}
                  {buttonsMenu.geraButtonSales()}
                  {buttonsMenu.getButtonFinancial()}
                  {buttonsMenu.getButtonResources()}
                  {buttonsMenu.getButtonProductionCost()}
                  {/* {buttonsMenu.getButtonPlanning()} */}
                  {buttonsMenu.getButtonProduction()}
                  {buttonsMenu.geraButtonIndicadores()}
                  {buttonsMenu.geraButtonRelatorios()}
                </Grid>
              </Box>
              {/* <ListItem sx={{ ml: -0.5 }}>
                <BottomNavigation
                  showLabels
                  value={value}
                  onChange={(event, newValue) => {
                    handleChange(event, newValue);
                  }}
                  sx={StyleBN}
                >
                  {buttonsMenu.getButtonMap()}
                  {buttonsMenu.getButtonPainel()}
                  {buttonsMenu.getButtonArea()}
                  {buttonsMenu.getButtonTeam()}
                </BottomNavigation>
              </ListItem>
              <ListItem sx={{ ml: -0.5 }}>
                <BottomNavigation
                  showLabels
                  value={value}
                  onChange={(event, newValue) => {
                    handleChange(event, newValue);
                  }}
                  sx={StyleBN}
                >
                  {buttonsMenu.getButtonSolicitacoes()}
                  {buttonsMenu.getButtonCreations()}
                  {buttonsMenu.getButtonSafras()}
                  {buttonsMenu.getButtonAnimaisCulturas()}
                  {buttonsMenu.getButtonPlanning()}
                  {buttonsMenu.getButtonInventory()}
                  {buttonsMenu.getButtonActivities()}
                </BottomNavigation>
              </ListItem>
              <ListItem sx={{ ml: -0.5 }}>
                <BottomNavigation
                  showLabels
                  value={value}
                  onChange={(event, newValue) => {
                    handleChange(event, newValue);
                  }}
                  sx={StyleBN}
                >
                  {buttonsMenu.getButtonResources()}
                  {buttonsMenu.geraButtonPatrimony()}
                  {buttonsMenu.geraButtonSales()}
                  {buttonsMenu.getButtonFinancial()}
                  {buttonsMenu.getButtonProduction()}
                  {buttonsMenu.getButtonProductionCost()}
                </BottomNavigation>
              </ListItem>
              {tipoMenu !== "selectProperty" ? (
                <></>
              ) : (
                <ListItem sx={{ ml: -0.5 }}>
                  <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                      handleChange(event, newValue);
                    }}
                    sx={StyleBN}
                  >
                    {buttonsMenu.geraButtonIndicadores()}
                    {buttonsMenu.geraButtonRelatorios()}
                  </BottomNavigation>
                </ListItem>
              )} */}
              {!select_tools ? <></> : <ListItem sx={{ ml: -0.5 }}>{select_tools}</ListItem>}
            </Menu>
          </div>
        </div>
      )}
    </>
  )
}

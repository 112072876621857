import * as React from "react"
import { Box, Grid, Stack, Tab, Tabs, Typography } from '@mui/material'
import { ShoppingCart } from '@mui/icons-material'
import PropTypes from 'prop-types'
import TableBuySalesOrder from '../inventory/TableBuySalesOrder'
import TablesPerson from '../person/components/outros/TablesPerson'
import TutorialStockProducts from './components/tutorial/TutorialStockProducts'
import RegisterBuysSalesDialog from '../financial/components/FormMain/RegisterBuysSalesDialog'
import TableBuyOrder from "./TableBuyOrder"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index, type) {
  return {
    id: `tabela-compras-${index}`,
    'aria-controls': `Tabela: ${type}`,
  }
}

export default function BuyContentTabs({ tab }) {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={5} md={7}>
              <Box sx={{ display: 'flex' }}>
                <ShoppingCart sx={{ fontSize: 35 }} color="text.secondary" />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Compra
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={10} md={3}>
              <RegisterBuysSalesDialog type={'buy'} />
            </Grid>
            <Grid item xs={1} md={1}>
              <TutorialStockProducts />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="content mb-4">
        <div className="container-fluid">
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs textColor="inherit" TabIndicatorProps={{ style: { background: 'green' } }} value={value} onChange={handleChange} aria-label="Tabelas de Compra">
                <Tab label="Pedidos em aberto" {...a11yProps(0, "Pedidos em aberto")} />
                <Tab label="Pedidos recebidos" {...a11yProps(1, "Pedidos recebidos")} />
                <Tab label="Fornecedores" {...a11yProps(2, "Fornecedores")} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <TableBuyOrder type={'Aberta'} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TableBuyOrder type={'Finalizada'} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <TablesPerson tab_active={['Fornecedor', 'Ativo']} />
            </TabPanel>
          </Box>
        </div>
      </div>
    </div>
  )
}

import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import api from '../../../../services/api';
import UpdateFormProductStep1 from './UpdateFormProductStep1';
import UpdateFormProductStep2 from './UpdateFormProductStep2';
import useStore from '../../../../store/useStore';
import { Context } from '../../../../components/Context/AuthContext';
import { useEffect } from 'react';

export default function MultiStepUpdateFormProduct({ dataToUpdate, handleClose }) {
  console.log('MultiStepUpdateFormProduct ');
  console.log(dataToUpdate);
  const { aux_add, setAuxAdd } = useContext(Context);
  const usestore = useStore();
  var userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const property_id = localStorage.getItem('Property') || 1;

  // const teste  = usestore.reposProducts.listPackaging
  console.log(usestore.reposProducts.listPackaging);
  const list = usestore.reposProducts.listPackaging;
  console.log(list);
  const packaging_name = usestore.reposProducts.listPackaging?.filter((item) => item.id == dataToUpdate?.id_packaging)[0]?.name;
  console.log('packaging_name ');
  console.log(packaging_name);
  const [data, setData] = useState({
    id: dataToUpdate.id,
    id_user: userCurrent.id,
    id_property: Number(property_id),
    id_account: dataToUpdate.id_account,
    name: dataToUpdate.name,
    id_product_location_array: dataToUpdate.id_product_location_array,
    //id_patrimony:
    id_unit: dataToUpdate.id_unit,
    unit_name: dataToUpdate.unit_name,
    id_packaging: dataToUpdate.id_packaging,
    packaging_name: packaging_name,
    unit_sigla: dataToUpdate.unit_sigla,
    // unit_name: "",
    id_category: dataToUpdate.id_category,
    // category_name: "",
    location: dataToUpdate.id_patrimony,
    locationData: dataToUpdate.location,
    description: dataToUpdate.description,
    cod_barra: dataToUpdate.cod_barra,
    brand: dataToUpdate.brand,
    observacoes: dataToUpdate.observacoes,
    image: dataToUpdate.image,
    url: dataToUpdate.url,
    stock_quanty: dataToUpdate.stock_quanty,
    stock_quanty_array: dataToUpdate.stock_quanty_array,
    stock_cost: dataToUpdate.stock_cost,
    stock_quanty_min: dataToUpdate.stock_quanty_min,
    stock_quanty_max: dataToUpdate.stock_quanty_max,
    altura: dataToUpdate.altura,
    largura: dataToUpdate.largura,
    profundidade: dataToUpdate.profundidade,
    volume: dataToUpdate.volume,
    peso_liquido: dataToUpdate.peso_liquido,
    peso_bruto: dataToUpdate.peso_bruto,
  });

  // Função para calcular a soma do stock_quanty_array
  const calculateTotalQuantity = () => {
    if (!data.stock_quanty_array) return 0;
    if (typeof data.stock_quanty_array === 'string') {
      const quantities = data.stock_quanty_array.split(',').map(Number);
      return quantities.reduce((total, quantity) => total + quantity, 0);
    }
  };

  // Atualizar a quantidade total sempre que houver uma alteração em data.stock_quanty_array
  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      stock_quanty: calculateTotalQuantity(),
    }));
  }, [data.stock_quanty_array]);

  console.log(data);

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (submit) => {
    if (submit !== 'submit' && activeStep === steps.length - 1) {
      onSubmit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      handleScroll();
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    handleScroll();
  };

  const handleScroll = () => {
    setTimeout(() => {
      window.jQuery(window.jQuery.find('#focus_top_modal')[0])?.parent()[0]?.scrollTo({ top: 0, left: 0, behavior: "smooth", });
    }, 300);
  };

  const validate = () => {
    let valid = true;
    if (!data.name) {
      valid = false;
    }
    return valid;
  };

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ['UpdateFormProductStep1', 'UpdateFormProductStep2'];

  const onSubmit = async () => {
    console.log(data);
    let formOk = validate() || false;

    // Divide stock_quanty_array em um array de quantidades correspondentes às localizações
    if (typeof data.stock_quanty_array === 'string') {
      console.log(data.stock_quanty_array);
      data.id_product_location_array = data.id_product_location_array ? data.id_product_location_array.split(',').map(Number) : [];
      data.stock_quanty_array = data.stock_quanty_array ? data.stock_quanty_array.split(',').map(Number) : [];
    }

    if (Array.isArray(data.stock_quanty_array)) {
      // Se stock_quanty_array for um array, soma os valores
      data.stock_quanty = data.stock_quanty_array.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }
    console.log(data);

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      const res = await api
        .put(`products/list/${dataToUpdate.id}`, data, header)
        .then((response) => {
          console.log('response.data');
          console.log(response.data);
          if (response.data.isSuccess) {
            uploadImage(data.id);
            toast.success('Produto atualizado com sucesso!');
            usestore.reposProducts.fetch({ id_property: property_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleClose();
              }, 200);
            });
            handleClose();
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error(error.response.data);
        });
    } else {
      toast.error('Por favor, preencha os campos obrigatórios!');
    }
  };

  async function uploadImage(id) {
    console.log('uploadImage');
    // toast.error(`test ${id}`);
    var id_farm = localStorage.getItem('Property') || 1;
    const fd = new FormData();
    fd.append('image', data.file, `${data.name}.png`);
    //const tipo_item = 'area';
    fd.append('item_type', 'product' || '');
    fd.append('item_id', id || 0);
    fd.append('id_farm', id_farm || 1);

    try {
      const response = await api.post('upload', fd);
      if (response.data.isSuccess) {
        toast.success(response.data.isSuccess);
        var farm_id = localStorage.getItem('Property');
        usestore.reposImages.fetch({ id_farm: farm_id }).then((res) => {
          usestore.reposProducts.fetch({ id_farm: property_id }).then((res) => {
            setTimeout(() => {
              setAuxAdd(aux_add + 1);
              // if (container) {
              //   container();
              // } else {
              //   setIsLoading(true);
              // }
            }, 200);
          });

          setTimeout(() => {
            setAuxAdd(aux_add + 1);
          }, 200);
        });
      }
    } catch (error) {
      toast.error('Erro ao salvar na Amazon');
    }
  }

  const getStepContent = (step) => {
    const obj_fn = {
      setData: setData,
      handleNext: handleNext,
      handleBack: handleBack,
      activeStep: activeStep,
      steps: steps,
      handleClose: handleClose,
      onSubmit: onSubmit,
      usestore: usestore,
    };

    switch (step) {
      case 0:
        return <UpdateFormProductStep1 data={data} obj_fn={obj_fn} />;
      case 1:
        // confirmação dos dados
        return <UpdateFormProductStep2 data={data} obj_fn={obj_fn} />;
      default:
        return 'Não existente';
    }
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>{getStepContent(activeStep)}</Box>
    </>
  );
}

import { useContext, useEffect, useState } from 'react';
import { Box, Container, Paper } from '@mui/material';
import { toast } from 'react-toastify';
import api from '../../../../../services/api';
import useStore from '../../../../../store/useStore';
import { Context } from '../../../../../components/Context/AuthContext';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import FormAssignAreaStep1 from './FormAssignAreaStep1';
import FormAssignAreaStep2 from './FormAssignAreaStep2';
import FormDesassignAreaStep1 from './FormDesassignAreaStep1';
import FormDesassignAreaStep2 from './FormDesassignAreaStep2';
import SuccessAssignArea from './SuccessAssignArea';

export default function MultiStepFormAssignArea({ handleClose, tipo }) {
  console.log('MultiStepFormAssignArea');
  const { aux_add, setAuxAdd } = useContext(Context);
  const usestore = useStore();

  const property_id = localStorage.getItem('Property') || 1;
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const livestockRepos = usestore.reposLivestock.getLivestockStore('id', usestore.reposLivestock.livestockRepos.id)[0];
  console.log(livestockRepos);

  const getListAreasLivestock = () => {
    const listAreas_aux = JSON.parse(JSON.stringify(livestockRepos.areas || []));
    console.log(listAreas_aux);
    const listAreas = listAreas_aux.map((area) => {
      var area_repos = usestore.reposAreas.getAreaMapStore('id', area.id)[0];
      area.url = area_repos.url;
      //area.imageDecode = area_repos.imageDecode;
      return area;
    });
    return listAreas;
  };

  const getListAreasProperty = () => {
    const listAreas_aux = JSON.parse(JSON.stringify(usestore.reposAreas.listAreasMap || []));
    const listAreas = listAreas_aux.filter((area) => {
      const area_not = area.areas_used.filter((area_used) => {
        if (area_used.id == livestockRepos.id) {
          return area;
        }
      });
      if (!area_not.length) {
        return area;
      }
    });
    return listAreas;
  };

  const [data, setData] = useState({
    id_property: property_id,
    id_item: livestockRepos.id,
    item_data: livestockRepos,
    tipo_item: 'livestock',
    id_user: userCurrent.id,

    name_activity: (tipo == 'ADIÇÃO' ? 'VINCULAÇÃO' : 'DESVINCULAÇÃO') + ' DE ÁREAS',
    assign: tipo,

    areas_used: getListAreasLivestock(),
    areas_property: getListAreasProperty(),
    new_area: [],

    date_start: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    date_prev_end: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    isDeleted: '',
    progress: 'A FAZER',
    planning: 'realized',
    observation: '',
  });

  // controle do dialog dos componentes
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    handleScroll();
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    handleScroll();
  };

  const handleScroll = () => {
    setTimeout(() => {
      window.jQuery(window.jQuery.find('#focus_top_modal')[0]).parent()[0].scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, 300);
  };

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ['FormAssignAreaStep1', 'FormAssignAreaStep2', 'SuccessAssignArea'];

  const onSubmitAdd = async () => {
    let formOk = true;

    if (!data.new_area.length) {
      formOk = false;
      toast.error('Por favor, escolha pelo menos uma área da propriedade!');
    }

    if (formOk) {
      if (data.date_start == data.date_prev_end) {
        data.progress = 'CONCLUÍDA';
        data.date_end = data.date_start;
      }
      const areas_bkp = JSON.parse(JSON.stringify(data.new_area));
      data.new_area = data.new_area.map((repos) => ({ id: repos.id, area: repos.area, usedArea: repos.usedArea || repos.area }));

      const header = { header: { 'Content-Type': 'application/json' } };
      const res = await api
        .post('/activityAssignArea/save', data, header)
        .then((response) => {
          //console.log(response.data);
          if (response.data.isSuccess) {
            toast.success('Áreas vinculadas com sucesso!');
            usestore.reposLivestock.fetch({ id_farm: property_id });
            usestore.reposProperty.fetch();
            usestore.reposActivityAssignArea.fetch({ id_property: property_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleNext();
              }, 100);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao vincular as áreas, tente novamente mais tarde!');
        });
      data.new_area = areas_bkp;
    }
  };
  const onSubmitRmv = async () => {
    let formOk = true;

    if (!data.new_area.length) {
      formOk = false;
      toast.error('Por favor, escolha pelo menos uma área da criação!');
    }

    if (formOk) {
      if (data.date_start == data.date_prev_end) {
        data.progress = 'CONCLUÍDA';
        data.date_end = data.date_start;
      }
      const areas_bkp = JSON.parse(JSON.stringify(data.new_area));
      data.new_area = data.new_area.map((repos) => ({ id: repos.id, area: repos.area, usedArea: repos.usedArea || repos.area }));

      const header = { header: { 'Content-Type': 'application/json' } };
      const res = await api
        .post('/activityAssignArea/remove', data, header)
        .then((response) => {
          //console.log(response.data);
          if (response.data.isSuccess) {
            toast.success('Áreas desvinculadas com sucesso!');
            usestore.reposLivestock.fetch({ id_farm: property_id });
            usestore.reposProperty.fetch();
            usestore.reposActivityAssignArea.fetch({ id_property: property_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleNext();
              }, 100);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao desvincular as áreas, tente novamente mais tarde!');
        });
      data.new_area = areas_bkp;
    }
  };

  //chamada dos componentes
  const getStepContent = (step) => {
    const obj_fn = {
      setData: setData,
      handleNext: handleNext,
      handleBack: handleBack,
      activeStep: activeStep,
      steps: steps,
      tipo_assing: tipo,
      handleClose: handleClose,
      onSubmitAdd: onSubmitAdd,
      onSubmitRmv: onSubmitRmv,
      usestore: usestore,
    };
    switch (step) {
      case 0: // seleção das áreas a serem vinculadas
        if (tipo == 'ADIÇÃO') {
          return <FormAssignAreaStep1 data={data} obj_fn={obj_fn} />;
        } else {
          return <FormDesassignAreaStep1 data={data} obj_fn={obj_fn} />;
        }
      case 1: // confirmação do vinculo
        if (tipo == 'ADIÇÃO') {
          return <FormAssignAreaStep2 data={data} obj_fn={obj_fn} />;
        } else {
          return <FormDesassignAreaStep2 data={data} obj_fn={obj_fn} />;
        }
      case 2:
        return (
          <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessAssignArea handleClose={handleClose} data={data} />
            </Paper>
          </Container>
        );
      default:
        return 'Não existente';
    }
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>{getStepContent(activeStep)}</Box>
    </>
  );
}

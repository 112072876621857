import React, { useEffect, useState } from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'
import FinancialBillsTable from './FinancialBillsTable'
import ChatSidebar from './ChatSidebar/ChatSidebar'

const getTextcolor = (index) => {
  switch (index) {
    case 1:
      return 'red'
    case 2:
      return 'red'
    case 3:
      return 'blue'
    case 4:
      return 'green'
    case 5:
      return 'blue'
    default:
      return 'black'; // Defina uma cor padrão ou ajuste conforme necessário
  }
}

const getButtonText = (index, type) => {
  let moeda = ' (R$)'
  switch (index) {
    case 1:
      return 'Vencidos' + moeda
    case 2:
      return 'Vencem hoje' + moeda
    case 3:
      return 'A vencer' + moeda
    case 4:
      let text = 'Efetuados'
      if (type == "expense") {
        text = 'Pagos'
      } else if (type == "income") {
        text = 'Recebidos'
      }
      return text + moeda
    case 5:
      return 'Total do período' + moeda
    default:
      return ''
  }
}

const FinancialBillsPayReceive = ({ type, obj_fn }) => {
  const [rowsBills, setRowsBills] = useState([])
  const [activeButton, setActiveButton] = useState(null)

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName)
  }

  useEffect(() => {
    if (type == 'income') {
      setRowsBills(obj_fn.usestore.reposBill.listBillsReceive)
    } else {
      setRowsBills(obj_fn.usestore.reposBill.listBillsPay)
    }
  }, [type, obj_fn.aux_add])


  const getButtonValue = (index) => {
    let value = 0

    switch (index) {
      case 1: // 'Vencidos'
        rowsBills.filter((item) => !item.date_payment && dayjs(dayjs(item.date_expire).format('YYYY-MM-DD')).isBefore(dayjs(dayjs().format('YYYY-MM-DD'))) )
          .map((item) => {
            value += parseFloat(item.value)
          })
        break
      case 2: // 'Vencem hoje'
        rowsBills.filter((item) => !item.date_payment && dayjs(dayjs(item.date_expire).format('YYYY-MM-DD')).isSame(dayjs(dayjs().format('YYYY-MM-DD'))) )
          .map((item) => {
            value += parseFloat(item.value)
          })
        break
      case 3: // 'A vencer'
        rowsBills.filter((item) => item.status == "aberta" && dayjs(dayjs().format('YYYY-MM-DD')).isBefore(dayjs(dayjs(item.date_expire).format('YYYY-MM-DD'))) )
          .map((item) => {
            value += parseFloat(item.value)
        })
        break
      case 4: //'Pagos' || 'Recebidos'
        rowsBills.filter((item) => item.date_payment != '' )
          .map((item) => {
            value += parseFloat(item.value)
          })
        break
      case 5: // 'Total do período'
        rowsBills.map((item) => { value += parseFloat(item.value); })
        break
      default:
        value = 0
        break
    }

    return value.toFixed(2)
  }
  
  return (
    <Box sx={{ display: 'flex' }} >
      <ChatSidebar />
      <Container maxWidth="" sx={{ mt: 2, mb: 4 }}>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid container spacing={0} sx={{ mb: 1 }} justifyContent="center">
            {[1, 2, 3, 4, 5].map((index) => (
              <Grid item key={index} xs={6} md={2}>
                <div
                  style={{
                    cursor: 'pointer',
                    border: '1px solid #ccc',
                    borderBottom: '1px solid #ccc',
                    padding: '10px',
                    textAlign: 'center',
                    position: 'relative',
                  }}
                  onClick={() => handleButtonClick(index)}
                >
                  <Typography >
                    {getButtonText(index, type)}
                  </Typography>
                  <Typography component="p" variant="h6" style={{ color: getTextcolor(index) }}>
                    {getButtonValue(index)}
                  </Typography>
                  {activeButton === index && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        borderBottom: '3px solid green',
                      }}
                    />
                  )}
                </div>
              </Grid>
            ))}
          </Grid>

          {/* lista das contas */}
          <Grid item xs={12}>
            <FinancialBillsTable type={type} rowsBills={rowsBills}/>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default FinancialBillsPayReceive


import React from 'react'
import CardProductionHarvest from './components/CardProductionHarvest'
import { Box, Grid, Stack, Typography } from '@mui/material'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import CardProductionLivestock from './components/CardProductionLivestock'
import CardProductionLivestockTabs from './components/CardProductionLivestockTabs'
import useLivestockTargetReposStore from '../../store/useLivestockTargetReposStore'

import BarChartIcon from '@mui/icons-material/BarChart'
import CardProductionharvestTabsAreaTop from './components/CardProductionharvestTabsAreaTop'
import useHarvestTargetReposStore from '../../store/useHarvestTargetReposStore'
import { useEffect } from 'react'
import { useContext } from 'react'
import { Context } from '../../components/Context/AuthContext'

export default function ProductionContent() {
  console.log('----Production------')
  const nameAtivity = JSON.parse(localStorage.getItem('creationHarvestRepos'))
  //console.log("nameAtivity");
  //console.log(nameAtivity.label);
  //console.log(nameAtivity.tipo_repos);

  // contém os planejamento das metas
  const reposTargetPlanningLivestock = useLivestockTargetReposStore((state) => state)
  //console.log("reposTargetPlanningLivestock");
  //console.log(reposTargetPlanningLivestock);
  //console.log(reposTargetPlanningLivestock.listPlanning);

  // contém os planejamento das metas
  const reposTargetPlanningHarvest = useHarvestTargetReposStore((state) => state)
  //console.log("reposTargetPlanningHarvest#");
  // console.log(reposTargetPlanningHarvest);
  //console.log(reposTargetPlanningHarvest.listPlanning);

  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12} md={8}>
              <Box sx={{ display: 'flex' }}>
                <BarChartIcon style={{ fontSize: 35 }} />
                <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    {/* Produção: {nameAtivity?.tipo_repos == "Criações" ? "Criação" : "Colheita"} */}
                    Produção:{' '}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="content mt-1">
        <div className="container-fluid">
          {/* {nameAtivity.tipo_repos == "Criações" ? <CardProductionLivestockTabs listPlanning={reposTargetPlanningLivestock.listPlanning} /> : <CardProductionHarvest />} */}
          {/* {nameAtivity.tipo_repos == "Criações" ? <CardProductionLivestockTabs listPlanning={reposTargetPlanningLivestock.listPlanning} /> : <CardProductionharvestTabs listPlanning={reposTargetPlanningHarvest.listPlanning} />} */}
          {nameAtivity.tipo_repos == 'Criações' ? (
            <CardProductionLivestockTabs listPlanning={reposTargetPlanningLivestock.listPlanning} />
          ) : (
            <CardProductionharvestTabsAreaTop listPlanning={reposTargetPlanningHarvest.listPlanning} />
          )}
        </div>
      </div>
    </div>
  )
}

import * as React from "react";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import { Fab, ListItemIcon, Menu, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";

import { NavLink } from "react-router-dom";

export default function LivestockPropertyLocationButton() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Fab
        sx={{ position: "fixed", top: 78, right: "1%" }}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color="success"
        aria-label="add"
        variant="extended"
      >
        <HighlightAltIcon sx={{ mr: 0.5 }} />
        Área
      </Fab>

      <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem sx={{ mb: -1 }}>
          <ListItemText>Criação atual</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon sx={{ ml: -1 }}>
            <i>
              <img src="/dist/img_fp/icon-button/todas_pecuarias_pb.png" width="32"></img>
            </i>
          </ListItemIcon>
          <ListItemText sx={{ ml: -1.5 }}></ListItemText>
        </MenuItem>

        <Divider />
        <MenuItem sx={{ mb: -1 }}>
          <ListItemText>ver</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}

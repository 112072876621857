import React, { useState } from 'react';
import Draggable from 'react-draggable';
// import { toast } from 'react-toastify';
import { AppBar, Button, Dialog, DialogContent, DialogTitle, Divider, Fab, Grid, List, ListItem, ListItemText, Paper, Toolbar } from '@mui/material';
import { Close, CheckBoxOutlineBlank } from '@mui/icons-material';
// import DrawArea from './DrawArea';
// import FormSaveArea from './FormSaveArea';
// import html2canvas from 'html2canvas';
import MapDraw from '../../mapsAreasDraw/components/MapDraw';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function DialogRegisterArea({ setAddArea }) {
  const [open, setOpen] = useState(false);
  const [drawM, setDrawM] = useState(true);

  // const [path, setPath] = useState([]);
  // const [image_area, setImage] = useState('');
  // const [drawF, setDrawF] = useState(false);
  // const [textButon, setTextButton] = useState('Próximo');

  /*const captureImage = () => {
    var element = document.getElementById('mapDrawArea');
    html2canvas(element, {
      backgroundColor: null,
      useCORS: true,
    }).then(function (canvas) {
      var base64image = canvas.toDataURL('image/jpeg');
      setDrawM(false);
      setImage(base64image);
      setDrawF(true);
    });
  };*/

  // controla o estado de desenho ou formSave
  /*const handleDraw = (e) => {
    if (!path || path.length < 1) {
      toast.warning('Desenhe uma área de no mínimo 3 lados!');
      return;
    }
    if (drawM) {
      setTextButton('Retornar');
      captureImage();
    } else {
      setDrawM(true);
      setDrawF(false);
      setTextButton('Próximo');
    }
  };*/

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setPath([]);
    setOpen(false);
    setDrawM(true);
    // setDrawF(false);
    // setImage('');
    // setTextButton('Próximo');
  };

  // const onSubmit = async (e) => {
  //   e.preventDefault();
  //   console.log('entrou no submit');
  // };

  return (
    <div className="row" style={{ textAlign: 'center' }}>
      <div className="col">
        <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} size="small" variant="outlined" color="success" onClick={handleClickOpen}>
          Desenhar nova área
        </Button>

        <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" fullWidth={true} maxWidth={'sm'}>
          <section className="content " style={{ overflowY: 'hidden', paddingBottom: 80 }}>
            <div className="container-fluid ">
              <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Desenhe a nova área de produção:
              </DialogTitle>

              <Divider />
              <DialogContent style={{ padding: '2px' }}>
                {!drawM || !open ? <></> : <MapDraw container={handleClose} />}
                {/*!drawM || !open ? <></> : <DrawArea path={path} setPath={setPath}/> */}
                {/*drawF ? <FormSaveArea handleClose={handleClose} path={path} image_area={image_area} setAddArea={setAddArea} /> : <></>*/}
              </DialogContent>
              <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
                <Toolbar>
                  <Grid container sx={{ width: '100%' }} spacing={2} alignItems="center">
                    <Grid item xs={4} textAlign="left">
                      <Fab sx={{ backgroundColor: '#fff' }} color="neutral" variant="extended" size="small" onClick={handleClose} aria-label="Close">
                        <Close sx={{ mr: 1 }} /> Cancelar
                      </Fab>
                    </Grid>

                    <Grid item xs={8} textAlign="right">
                      <div className="d-none d-lg-block">
                        <List sx={{ width: '100%', bgcolor: 'background.paper', m: 0 }}>
                          <ListItem
                            key={null}
                            disableGutters
                            secondaryAction={
                              <span aria-label="comment">
                                <CheckBoxOutlineBlank style={{ color: '#2e7d32' }} />
                              </span>
                            }
                          >
                            <ListItemText primary="" secondary="Clique no mapa, crie os pontos da área e depois salve o desenho" />
                          </ListItem>
                        </List>
                      </div>
                    </Grid>

                    {/* Se quiser adicionar o outro botão, você pode descomentar e ajustar assim:
    <Grid item xs={12} textAlign="left">
      <Fab color="info" variant="extended" size="small" onClick={(e) => handleDraw(e)} aria-label="add">
        {textButon}
      </Fab>
    </Grid>
    */}
                  </Grid>
                </Toolbar>
              </AppBar>
            </div>
          </section>
        </Dialog>
      </div>
    </div>
  );
}

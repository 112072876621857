import * as React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Container,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import Typewriter from '../../../components/Typewriter/Typewriter';
import { useState } from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import useStore from '../../../store/useStore';
import TimelineHorizPhase from '../../planningHarvest/components/GraphPlan/TimelineHorizPhase';
import TableProductionHarvestStorage from './TableProductionHarvestStorage';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { grey } from '@mui/material/colors';
import AddHomeIcon from '@mui/icons-material/AddHome';
import BalanceIcon from '@mui/icons-material/Balance';
import ButtonRegisterProductionTargetHarvest from './ButtonRegisterProductionTargetHarvest';
import FlagIcon from '@mui/icons-material/Flag';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventIcon from '@mui/icons-material/Event';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import GanttChart from '../../../components/ChartsGoogle/GanttChart';
import useHarvestTargetReposStore from '../../../store/useHarvestTargetReposStore';
import { format, parseISO } from 'date-fns';
import { ExpandMore } from '@mui/icons-material';
import ImageIcon from '@mui/icons-material/Image';
import { Context } from '../../../components/Context/AuthContext';
import DeleteModalPlanHarvest from '../../planning/components/MeatProduction/DeleteModalPlanHarvest';
import { useParams } from 'react-router-dom';
import FormGoalButtonTargetEdit from '../../planningHarvest/formGoalTarget/FormGoalButtonTargetEdit';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import useHarvestTargetCostReposStore from '../../../store/useHarvestTargetCostReposStore';

export default function CardRegisterProductionTargetHarvest({ area, index }) {
  const { idFarm, id } = useParams();

  function formatarData(dataString) {
    // Converter a string da data em um objeto de data
    const dataObjeto = parseISO(dataString);

    // Formatar a data no formato desejado 'dd/MM/yyyy'
    const dataFormatada = format(dataObjeto, 'dd/MM/yyyy');

    return dataFormatada;
  }

  const reposHarvestTargetPlanning = useHarvestTargetReposStore((state) => state);
  const reposHarvestTargetCost = useHarvestTargetCostReposStore((state) => state);

  const [imagemVisivel, setImagemVisivel] = useState(false);

  const toggleImagemVisivel = () => {
    setImagemVisivel(!imagemVisivel);
  };

  const handleClose = () => {
    setImagemVisivel(false);
  };

  const [cardActiveAux, setCardActiveAux] = useState(0);
  const { aux_add, setAuxAdd } = React.useContext(Context);

  // const handleSwitchChange = (e, index, target) => {
  //   console.log(index)
  //   console.log(target)
  //   if (target.isActive) {
  //     return true
  //   } else {
  //     console.log(area.target)
  //     area.target.map(item => {
  //       item.isActive = (item.id == target.id ? true : false);
  //       return item;
  //     });
  //   }
  //   //setCardActiveAux(cardActiveAux+1);
  //   setAuxAdd(aux_add + 1);
  // };

  //useEffect(() => {
  //}, [cardActiveAux])

  console.log('antes analise');
  console.log(area);

  area.target.map((item) => {
    for (const dataCost of area.dataCost) {
      console.log(item.id);
      console.log(dataCost.id_target);
      console.log('---');
      if (item.id === dataCost.id_target) {
        item.id_target = dataCost.id;
      }
    }
  });

  console.log('fim analise1');
  console.log(area);

  // updateIsActiveTargetLivestock
  //const [isActive, setIsActive] = React.useState(planRepos[0]?.isActiveTargetLivestock || false);
  const [isActive, setIsActive] = React.useState(false);

  //   const handleSwitchChange = (e, index, target) => {
  //     console.log(index)
  //    console.log(target)
  //  };

  const handleSwitchChange = async (event, index, target) => {
    // console.log('handleSwitchChange');
    const newState = event.target.checked;
    setIsActive(newState);
    // console.log(target);

    const header = { header: { 'Content-Type': 'application/json' } };
    try {
      // const data = { isActiveTargetHarvest: newState, id: plan.id, id_livestock: plan.id_livestock, id_property: plan.id_property };
      const data = { isActiveTargetHarvest: newState ? 1 : 0, id: target.id, id_harvest: target.id_harvest, id_property: target.id_property };
      // console.log('data');
      // console.log(data);
      const res = await api
        .put('/planning/updateIsActiveTargetHarvest', data, header)
        .then((response) => {
          // console.log(response.data);

          if (response.data.isSuccess) {
            toast.success('Meta atualizada com sucesso!');
            reposHarvestTargetPlanning.fetch({ id_farm: idFarm }).then((res) => {
              //setAuxAdd(aux_add + 1);
              // setTimeout(() => {
              //   handleClose()
              // }, 200);
            });
            reposHarvestTargetCost.fetch({ id_farm: idFarm }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleClose();
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Error ao atualizar os dados');
        });
      // if (!response.ok) {
      //   throw new Error("Erro ao enviar dados");
      // }
      // Processamento adicional em caso de sucesso, se necessário
    } catch (error) {
      console.error('Erro ao atualizar estado:', error);
      toast.error('Error ao atualizar os dados');
      // Lógica para lidar com erro
    }
  };

  return (
    <>
      {/* {isLoading === true ? (
        <>
          <Card sx={{ mt: 0 }}>
            <Skeleton variant="rectangular" width="100%" height={110} />
          </Card>
          <Card sx={{ mt: 0.5 }}>
            <Skeleton variant="rectangular" width="100%" height={155} />
          </Card>
        </>
      ) : ( */}
      <>
        {area && (
          <div key={index}>
            {area.active && (
              <div>
                <Card sx={{ mt: 1, mb: 10 }}>
                  <Grid container spacing={2} sx={{ mb: 0, mt: 0 }}>
                    {/* <Grid item xs={12} md={12}>
                      <CardComponent key={index} area={area} imageUrl={area.image} />
                    </Grid> */}
                    <Grid item xs={12} md={12} sx={{ mb: 10 }}>
                      <Grid container spacing={2} sx={{ mt: 0 }}>
                        {area.target.map((target, index) => (
                          <Grid item xs={12} md={6}>
                            {/* {area.target[0]} */}
                            <Card sx={{ maxWidth: 345 }}>
                              <CardHeader subheader={`Meta de Produção OP:${index + 1}`} />
                            </Card>
                            <Card
                              key={index}
                              sx={{
                                //maxWidth: 345,
                                border: target.isActive ? '2px solid green' : '1px solid #ccc', // Definir a borda verde quando ativo
                              }}
                            >
                              <CardHeader
                                avatar={<FlagIcon style={{ height: '50px', width: '50px' }} />}
                                action={
                                  // <IconButton aria-label="settings">
                                  //   <MoreVertIcon />
                                  // </IconButton>
                                  <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>{target ? <FormGoalButtonTargetEdit target={target} area={area} /> : <></>}</Box>
                                }
                                title={target.title}
                                subheader={
                                  <>
                                    <Stack spacing={0.5}>
                                      <Typography variant="body2" color="text.secondary">
                                        {area.target[0].cultivation_name}
                                      </Typography>
                                      <Typography variant="body2" color="text.secondary">
                                        {area.area} {area.measure}
                                      </Typography>
                                    </Stack>
                                  </>
                                }
                              />
                              <CardContent>
                                <Grid item xs={12}>
                                  Meta:{' '}
                                  <strong>
                                    {target.expectedYield} {target.unit}
                                  </strong>
                                </Grid>
                              </CardContent>
                              <CardActions disableSpacing>
                                <Stack spacing={0.5}>
                                  <Typography variant="body2" color="text.secondary">
                                    <CalendarMonthIcon style={{ height: '18px', width: '18px' }} sx={{ color: grey[500] }} /> Data Inicial
                                  </Typography>
                                  <Typography fontWeight={700} variant="h6">
                                    {formatarData(target.date_start)}
                                  </Typography>
                                </Stack>
                                <Stack spacing={0.5} sx={{ ml: 'auto' }}>
                                  <Typography variant="body2" color="text.secondary">
                                    <EventIcon style={{ height: '18px', width: '18px' }} sx={{ color: grey[500] }} /> Data Final de Análise
                                  </Typography>
                                  <Typography fontWeight={700} variant="h6">
                                    {formatarData(target.date_prev_end)}
                                  </Typography>
                                </Stack>
                              </CardActions>
                              <Divider />
                              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: 'background.default' }}>
                                <DeleteModalPlanHarvest planId={target.id} idFarm={idFarm} plan={target} id_target={target?.id_target} />
                                {/* <Chip>Active account</Chip>
                                <Switch
                                  onChange={() => handleSwitchChange(index)}
                                  checked={cardActive}
                                /> */}

                                {/* {target.isActive && <Chip label="Meta ativa" variant="outlined"></Chip>} */}
                                <Chip label={target.isActiveTargetHarvest ? 'Meta ativa' : 'inativa'} color={target.isActiveTargetHarvest ? 'success' : 'default'} variant="outlined" />
                                <Switch onChange={(e) => handleSwitchChange(e, index, target)} checked={target.isActiveTargetHarvest} />
                                {/* <Switch
                                  onChange={(e) => handleSwitchChange(e, index, target)}
                                  checked={target.isActive}
                                /> */}
                              </Stack>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    {/* {area.target?.length > 0 && (
                      <Grid item xs={12} md={12} sx={{ m: 5 }}>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography>Linha do Tempo</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Gantt targets={area.target} />
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )} */}
                  </Grid>
                </Card>
              </div>
            )}
          </div>
        )}
      </>
    </>
  );
}

function CardComponent({ area, imageUrl }) {
  const [imagemVisivel, setImagemVisivel] = useState(false);

  const toggleImagemVisivel = () => {
    setImagemVisivel(!imagemVisivel);
  };

  const handleClose = () => {
    setImagemVisivel(false);
  };

  return (
    <>
      <Card sx={{ border: '2px solid green', alignItems: 'center' }}>
        <CardHeader
          avatar={<FmdGoodIcon style={{ height: '50px', width: '50px' }} />}
          action={
            <IconButton aria-label="settings" onClick={toggleImagemVisivel}>
              <ImageIcon />
            </IconButton>
          }
          title={area.title}
          subheader={
            <>
              <Stack spacing={0.5}>
                <Typography variant="body2" color="text.secondary">
                  {area.area}
                </Typography>
              </Stack>
            </>
          }
        />
      </Card>
      {/* Renderize o componente ModalImage com base no estado imagemVisivel */}
      <ModalImage open={imagemVisivel} handleClose={handleClose} imageUrl={imageUrl} />
    </>
  );
}

function ModalImage({ open, handleClose, imageUrl }) {
  //console.log("/")
  //console.log(imageUrl)
  return (
    <Dialog open={open} onClose={handleClose}>
      <CardMedia
        component="img"
        height="400" // Altura desejada para o modal
        image={imageUrl}
        alt="Imagem em pop-up"
        style={{ maxWidth: '100%' }}
      />
    </Dialog>
  );
}

function Gantt({ targets }) {
  return (
    <>
      <GanttChart targets={targets} />
    </>
  );
}

//https://aegro.com.br/termos-de-uso/

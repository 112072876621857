import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import CardProperty from "./components/CardProperty";
import PropertyButton from "./components/form/PropertyButton";

export default function PropertyContent() {
  return (
    <div className="content-wrapper bg-white">
      <section className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={0} sx={{ mt: 2 }}>
            <Grid item xs={12} md={8}>
              <Typography gutterBottom variant="h5" component="div">
                Suas propriedades
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                <PropertyButton />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <CardProperty />
          </Grid>
        </div>
      </section>
    </div>
  );
}

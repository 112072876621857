import create from "zustand";
import { persist, devtools } from "zustand/middleware";
import api from '../services/api';
import dayjs from 'dayjs';
import { BankList, TypesBankList } from "../components/Icons/bank/BankList";
import { HomeWork } from "@mui/icons-material";

const listBanks = BankList();
const listTypesBank = TypesBankList();
const defaultIcon = (<><HomeWork style={{ fontSize: "1.4rem" }} /></>);

const setBankFormat = (bank, icons) => {
  const bank_aux = listBanks.find((banco) => banco.bank === bank.name_bank);
  const bank_type_aux = listTypesBank.find((type_b) => type_b.name === bank.type);

  if (bank_aux) {
    bank.bank = bank_aux;
    bank.name_bank_title = bank_aux.label;
    bank.icon = !icons ? '' : (<>{bank_aux.icon}</>);
  } else {
    bank.bank = {
      backgroundColor: "#e5ebe9",
      bank: "",
      color: "#e5ebe9",
      icon: !icons ? '' : defaultIcon,
      label: "Padrão"
    };
    bank.name_bank_title = (bank.name_bank || 'Padrão');
    bank.icon = !icons ? '' : defaultIcon;
  }

  if (bank_type_aux) {
    bank.type_title = bank_type_aux.title;
    bank.type_icon = !icons ? '' : (<>{bank_type_aux.icon}</>);
  } else {
    bank.type_title = (bank.type || 'Conta corrente');
    bank.type_icon = !icons ? '' : defaultIcon;
  }

  bank.balance_date = dayjs(bank.balance_entry);

  return bank;
};

const getBankIcons = (bank, type) => {
  const bank_aux = listBanks.find((banco) => banco.bank === bank.name_bank);
  const bank_type_aux = listTypesBank.find((type_b) => type_b.name === bank.type);

  if (type == 'bank') {
    if (bank_aux) {
      return bank_aux;
    } else {
      return {
        backgroundColor: "#e5ebe9",
        bank: "",
        color: "#e5ebe9",
        icon: defaultIcon,
        label: "Padrão"
      };
    }
  } else if (type == 'icon') {
    if (bank_aux) {
      return (<>{bank_aux.icon}</>);
    } else {
      return defaultIcon;
    }
  } else if (type == 'type_icon') {
    if (bank_type_aux) {
      return (<>{bank_type_aux.icon}</>);
    } else {
      return defaultIcon;
    }
  }
};
// create the store
const useBankReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listBank: [],
        bankRepos: { id_property: 0, id: 0, name: '', name_bank: '', balance_entry: '' },
        fetch: async (item) => {
          var id_property = (localStorage.getItem("Property") || 1);

          if (!item) {
            item = { 'id_property': id_property };
          } else if (!item.id_property) {
            item.id_property = id_property;
          }
          const header = { header: { "Content-Type": "application/json" } };
          const response = await api.post("bank/list", (item), header);
          const data = await response.data;
          let padrao = { id_property: id_property, id: 0, name: '', name_bank: '', balance_entry: '', id_account: 0 };
          let aux;
          await set({
            listBank:
              data._value.map((bank) => {
                aux = {
                  id: bank.id,
                  id_property: bank.id_property,
                  name: bank.name,
                  name_bank: bank.name_bank,
                  bank: '',
                  balance_opening: parseFloat(bank.balance_opening || '0'),
                  balance_date: dayjs(bank.balance_entry),
                  balance_entry: dayjs(bank.balance_entry).format('YYYY-MM-DD HH:mm:ss'),
                  balance_entry_f: dayjs(bank.balance_entry).format('DD/MM/YYYY'),
                  agency: bank.agency,
                  account_number: bank.account_number,
                  country: bank.country,
                  id_account: bank.id_account, // conta da transação
                  type: bank.type,
                  standard: bank.standard,
                  isDeleted: bank.isDeleted,
                  noDelet: bank.noDelet,
                  date_update: dayjs(bank.date_update).format('YYYY-MM-DD HH:mm:ss'),
                  date_update_f: dayjs(bank.date_update).format('DD/MM/YYYY'),
                  icon: '',
                  type_icon: '',
                }
                setBankFormat(aux);
                if (aux.standard > 0) {
                  padrao = window.jQuery.extend(true, {}, { 'id_property': bank.id_property, 'id': bank.id, 'id_account': bank.id_account, 'name': bank.name, 'name_bank': bank.name_bank, 'balance_entry': aux.balance_entry_f });
                }
                return aux;
              })
          })
          let aux_bankRepos = get().bankRepos;
          if (!aux_bankRepos.id || padrao.id != aux_bankRepos.id) {
            get().changeBankRepos(padrao);
          }
          //set({ bankRepos: padrao })

          return data;
        },
        changeBankRepos: (bankRepos) => set((state) => ({ bankRepos })),
        // pega os bancos da listBank de acordo com a chave passada
        getBankStore: (key, val) => {
          let aux = '';
          let aux_banks = [];
          get().listBank.filter((bank) => {
            if (bank[key] == val) {
              aux = window.jQuery.extend(true, {}, bank);
              setBankFormat(aux, 'icons');
              aux_banks.push(aux);
              return aux;
            }
          })
          return aux_banks;
        },
        getBankIcon: (key, val, type) => {
          let icon = '';
          let aux = '';
          get().listBank.filter((bank) => {
            if (bank[key] == val) {
              aux = window.jQuery.extend(true, {}, bank);
              icon = getBankIcons(aux, type);
            }
          });
          return icon;
        },
        countBanks: (bankType) => {
          let count = 0;
          get().listBank.filter((bank) => {
            if (bank.name_bank == bankType) {
              count++;
            }
          });
          return count;
        },

        // Adiciona um novo banco à listBank
        addBankStore: (bank) => {
          set(state => ({
            listBank: [...state.listBank, bank]
          }))
        },
        // Gera uma nova listBank sem a bank informada
        delBankStore: (id) => {
          set({
            listBank:
              get().listBank.filter((bank) => {
                if (bank.id != id) {
                  return bank;
                }
              })
          })
        },
        // atualiza listBank de acordo com id, key e val informados
        updateBankStore: (id, key, val) => {
          set({
            listBank:
              get().listBank.map((bank) => {
                if (bank.id === id) {
                  if (key == 'all') { // atualiza todos os dados
                    bank = val;
                  } else {
                    bank[key] = val;
                  }
                }
                return bank;
              })
          })
        },
      }),
      { name: "reposBank" }
    )
  )
);

export default useBankReposStore;
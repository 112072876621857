import React from "react";
// import Nav from "../../components/Nav/Nav";
import NavSide from "../../components/Nav/NavSide";
import NavBotton from "../../components/Nav/NavBotton";
import SalesContentTabs from "./SalesContentTabs";
import Footer from "../../components/Footer/Footer";

export default function Sales() {
  return (
    <>
      <NavSide />
      {/* <Nav /> */}
      <NavBotton />
      <SalesContentTabs />
      <Footer />
    </>
  );
}

import React, { useContext, useEffect, useState } from 'react'
import { Avatar, Box, Card, CardHeader, Chip, Divider, Grid, Paper, TextField, Typography, styled } from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { green } from '@mui/material/colors'
import useStore from '../../../store/useStore'
import { Context } from '../../../components/Context/AuthContext'
import Typewriter from '../../../components/Typewriter/Typewriter'
import { expandAndFade } from '../../../components/Animations/animationKeyFrames'
import { formatValue } from '../../../utils/functions'
import dayjs from "dayjs"

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const columns = [
  { id: 'data', label: 'Data', minWidth: 10 },
  { id: 'user', label: 'Equipe', minWidth: 10 },
  { id: 'type', label: 'Tipo', minWidth: 10, align: 'center' },
  {
    id: 'quanty',
    label: 'Quantidade',
    minWidth: 10,
    align: 'center',
  },
  { id: 'name', label: 'Itens', minWidth: 10 },

  { id: 'localOrig', label: 'Origem', minWidth: 100 },
  { id: 'localDest', label: 'Destino', minWidth: 100 },

  {
    id: 'valor',
    label: 'Valor uni.',
    minWidth: 80,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'total',
    label: 'Valor (R$)',
    minWidth: 80,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];

function getFiltereds(type, tipo) {
  let filtered = false;
  let aux_type = type.toLowerCase();


  if (tipo === 'all') {
    filtered = (
      /.*produto.*/.test(aux_type) ||
      /.*medicamento.*/.test(aux_type) ||
      /.*nutrição.*/.test(aux_type) || /.*nutricao.*/.test(aux_type) ||
      /.*produção.*/.test(aux_type) || /.*producao.*/.test(aux_type) ||
      /.*plantio.*/.test(aux_type) ||
      /.*aplicação.*/.test(aux_type) || /.*aplicacao.*/.test(aux_type) ||
      /.*colheita.*/.test(aux_type) ||
      /.*manutenção.*/.test(aux_type) || /.*manutencao.*/.test(aux_type) ||
      /.*compra.*/.test(aux_type) ||
      /.*venda.*/.test(aux_type) ||
      /.*recursos.*/.test(aux_type)
    );
  } else if (tipo === 'activities') {
    filtered = (
      /.*medicamento.*/.test(aux_type) ||
      /.*nutrição.*/.test(aux_type) || /.*nutricao.*/.test(aux_type) ||
      /.*produção.*/.test(aux_type) || /.*producao.*/.test(aux_type) ||
      /.*plantio.*/.test(aux_type) ||
      /.*aplicação.*/.test(aux_type) || /.*aplicacao.*/.test(aux_type) ||
      /.*colheita.*/.test(aux_type)
    );
  } else if (tipo === 'entrada') {
    filtered = (
      /.*produto.*/.test(aux_type) ||
      /.*compra.*/.test(aux_type)
    );
  } else { // saida
    filtered = (
      /.*manutenção.*/.test(aux_type) || /.*manutencao.*/.test(aux_type) ||
      /.*venda.*/.test(aux_type) ||
      /.*recursos.*/.test(aux_type)
    );
  }
  return filtered;
}

function createData(data, name, type, description, localOrig, localDest, user, stock, quanty, valor, total, nameUser) {
  return { data, name, type, description, localOrig, localDest, user, stock, quanty, valor, total, nameUser }
}

export default function InventoryMov() {
  // console.log('InventoryMov ')
  const usestore = useStore();
  const { aux_add } = useContext(Context);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [dense, setDense] = useState(false);
  const dense = false;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked)
  // }

  const [filterProd, setFilterProd] = useState('');
  const [formatedRows, setFormatedRows] = useState([]);

  const handleRows = (event) => {
    var aux_type;
    let rows_t = usestore.reposFinances.listTransactions.filter((transaction) => {
      aux_type = transaction.type.toLowerCase();

      return getFiltereds(aux_type, 'all');
    })?.sort((a, b) => {
      // decrescente
      let aux = new Date(b.date_entry).getTime() - new Date(a.date_entry).getTime();
      return aux;
    })

    var user, transaction, stock, patrimony_origin, patrimony_destiny, product, aux_product, person;
    var rows = [], aux_prods = {};
    for (var i = 0, tam = rows_t.length; i < tam; i++) {
      transaction = rows_t[i];
      aux_prods = {};

      // guarda os produtos utilizados
      transaction.fkys.map((trans) => {
        if (trans.id_product) {
          if (!aux_prods[trans.id_product]) {
            aux_prods[trans.id_product] = {
              id_product: trans.id_product,
              id_product_location: trans.id_product_location,
              id_person: trans.id_person,
              quant: trans.quant,
              value: trans.credit > 0 ? trans.credit : trans.debit,
            }
          }
        }
        return trans;
      });

      stock = transaction.info.stock || transaction.info.subtype;
      user = usestore.reposUser.getUserStore('id', transaction.id_user)[0];

      for (var p = 0, tamp = Object.keys(aux_prods).length; p < tamp; p++) {
        product = aux_prods[Object.keys(aux_prods)[p]];
        aux_product = usestore.reposProducts.getProductsStore('id', product.id_product)[0];

        if (product.id_person) {
          person = usestore.reposUser.getUserStore('id', transaction.id_user)[0];
        } else {
          person = {};
        }
        patrimony_origin = { name: '' };
        patrimony_destiny = { name: '' };

        if (/M|mov.*/.test(stock)) { //Movimentar
          if (typeof transaction.info.id_patrimony_origin === 'number') {
            patrimony_origin = usestore.reposPatrimony.getPatrimonyStore('id', transaction.info.id_patrimony_origin)[0];
          } else {
            patrimony_origin = {name: person?.name || transaction.info.id_patrimony_origin || 'Fornecedor'};
          }
          if (typeof transaction.info.id_patrimony_destiny === 'number') {
            patrimony_destiny = usestore.reposPatrimony.getPatrimonyStore('id', transaction.info.id_patrimony_destiny)[0];
          } else {
            patrimony_destiny = {name: person?.name || transaction.info.id_patrimony_destiny || 'Cliente'};
          }
        } else if (/Sai|ída/.test(stock)) {
          stock = 'Saída';
          if (getFiltereds(transaction.type, 'activities')) {
            if (transaction.id_harvest) {
              patrimony_destiny = usestore.reposHarvest.getHarvestStore('id', transaction.id_harvest)[0];
            } else {
              patrimony_destiny = usestore.reposLivestock.getLivestockStore('id', transaction.id_livestock)[0];
            }
          } else {
            patrimony_destiny = {name: person?.name || 'Cliente'};
          }
          patrimony_origin = usestore.reposProducts.getLocationStockStore('id', product.id_product_location)[0];
          patrimony_origin = usestore.reposPatrimony.getPatrimonyStore('id', patrimony_origin.id_patrimony)[0];

        } else { // entrada
          if (getFiltereds(transaction.type, 'activities')) {
            if (transaction.id_harvest) {
              patrimony_origin = usestore.reposHarvest.getHarvestStore('id', transaction.id_harvest)[0];
            } else {
              patrimony_origin = usestore.reposLivestock.getLivestockStore('id', transaction.id_livestock)[0];
            }
          } else {
            patrimony_origin = {name: person?.name || 'Fornecedor'};
          }
          patrimony_destiny = usestore.reposProducts.getLocationStockStore('id', product.id_product_location)[0];
          patrimony_destiny = usestore.reposPatrimony.getPatrimonyStore('id', patrimony_destiny.id_patrimony)[0];
        }
        
        rows.push(createData(
          dayjs(transaction.date_entry).format("DD/MM/YYYY HH:mm"), // Data
          aux_product.name, // Itens
          transaction.info.type || transaction.type,
          transaction.description.replace(/\s?\d+$/, ''),
          patrimony_origin?.name || '',
          patrimony_destiny?.name || '',
          user.charName, // user
          stock,
          product.quant + " " + (aux_product.unit_sigla || aux_product.packaging_sigla), // Quantidade
          formatValue(product.value / product.quant), // Valor
          formatValue(product.value), // total
          user.name
        ));
      }
    }

    setFormatedRows(rows);
  }

  useEffect(() => {
    handleRows();
  }, [aux_add])

  // Renderizar as linhas da tabela com base na página atual e na quantidade de linhas por página
  let paginatedRows = []
  if (filterProd) {
    paginatedRows = formatedRows.filter((row) => {
      var regex_p = new RegExp(".*" + filterProd?.toLowerCase() + ".*");
      if (regex_p.test((row.name).toLowerCase())) {
        return row;
      }
    })
  } else {
    paginatedRows = formatedRows;
  }
  paginatedRows = paginatedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <Paper sx={{ width: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <Card sx={{ mt: 1 }}>
            <CardHeader
              title={
                <Grid container spacing={2} sx={{ mb: 1 }}>
                  <Grid item xs={12} md={12}>
                    <Typography sx={{ mt: 0 }} variant="subtitle2">
                      <strong>Movimentações: </strong>Analise o histórico por período de todas as movimentações de entradas e saídas.
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
            <Divider />
          </Card>
        </Box>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table" size={dense ? 'small' : 'medium'}>
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={3}>
                  <TextField
                    id={'filtro_produto'}
                    name={'filtro_produto'}
                    label={'Filtro produto:'}
                    variant="standard"
                    size="small"
                    color="success"
                    value={filterProd}
                    onChange={(e) => setFilterProd(e.target.value)}
                  />
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  Estoque
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  Detalhes
                </TableCell>
              </TableRow>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ top: 57, minWidth: column.minWidth }}>
                    <Typography sx={{ flex: '1 1 100%', fontWeight: 'bold' }} color="inherit" variant="subtitle1" component="div">
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedRows.map((row, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    //console.log(row)
                    const value = row[column.id]
                    // console.log(value)
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.id === 'type' && ( // Verificar se é a coluna 'Tipo'
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <LightTooltip title={value}>
                              <Chip
                                size="small"
                                label={value}
                                sx={{
                                  bgcolor: 
                                    getFiltereds(value, 'activities') ? 'black' :
                                    row.stock === 'Entrada' || row.stock === 'Cadastro' ? 'green' : 
                                    /M|mov.*/.test(row.stock) ? 'gray' :
                                    row.stock === 'Edição' ? '#d32f2f' :
                                    row.stock === 'Saída' ? '#ed6c02' :
                                    'default',
                                  color: 'white', // Cor do texto sempre branco
                                  width: 90, // Definindo um comprimento mínimo de 100px
                                }}
                              />
                            </LightTooltip>
                            <LightTooltip title={row.description} >
                              <Chip
                                sx={{ ml: 1 }}
                                size="small"
                                label="E"
                                color={
                                  row.stock === 'Entrada' || row.stock === 'Cadastro' ? 'success' : 
                                  row.stock === 'Saída' ? 'warning' : 
                                  row.stock === 'Edição' ? 'info' : 
                                  'default'
                                }
                              />
                            </LightTooltip>
                          </Box>
                        )}
                        {column.id === 'user' && ( // Verificar se é a coluna 'user'
                          <LightTooltip
                            title={
                              <Typewriter
                                variant={'subtitle1'}
                                color={'#2e7d32'}
                                minHeight={0}
                                text={
                                  row.stock === 'Edição'
                                    ? `Ocorreu um ajuste no estoque. O estoque do(a) ${(row.name || '').toLowerCase()} é agora ${row['quanty']}.`
                                    : `Na data ${row['data']} o ${(row.nameUser || '').toLowerCase()} ${
                                      row.stock === 'Entrada' || row.stock === 'Cadastro' ? 'adicionou' : 
                                      row.stock === 'Saída' ? 'removeu' : 
                                      /M|mov.*/.test(row.stock) ? 'deslocou' :
                                      row.stock === 'Edição' ? 'editou' : 
                                      'utilizou' // atividade
                                      } ${row['quanty']} ${row['name']} do(a) ${row['localOrig'] == '' ? 'origem' : row['localOrig']} para o destino: ${row['localDest']}, no valor de R$ ${row['total']}`
                                }
                              />
                            }
                          >
                            <Avatar sx={{ bgcolor: green[500], width: 24, height: 24, fontSize: 'small', ml: 2, animation: `${expandAndFade} 2s linear infinite` }} aria-label="recipe">
                              {value}
                            </Avatar>
                          </LightTooltip>
                        )}
                        {column.id !== 'type' && column.id !== 'user' && ( value )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={'Linhas por página'}
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={formatedRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Visualização da tabela" /> */}
      <Box sx={{ mb: 10 }}>
        <br />
      </Box>
    </>
  )
}

import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Typewriter from '../../../../components/Typewriter/Typewriter';
import FooterModal from '../../../../components/Footer/FooterModal';
import { Business, Contacts, Input, Notes } from '@mui/icons-material';
import ThreeDotsSpinner from '../../../../components/Loader/ThreeDotsSpinner';

export default function FormPersonStep2({ data, obj_fn }) {
  const [showTextFase, setShowTextFase] = useState(false);
  setTimeout(() => {
    setShowTextFase(true);
  }, 500);

  // const para validar o button continuar
  const isError = () => {
    return false;
  };

  const returnError = () => {
    return false;
  };

  const textType = (types) => {
    let txt = (types.cliente ? "Cliente" : '');
    txt += txt.length > 0 ? ", " : '';
    txt += (types.fornecedor ? "Fornecedor" : '');
    txt += txt.length > 0 ? ", " : '';
    txt = txt.replace(/, , /, ', ');
    txt += (types.transportadora ? "Transportadora" : '');
    txt = txt.replace(/, $/, '');
    return txt;
  };

  return ( <>
    <Grid container spacing={3} sx={{ mt: 1 }}>
      <Box sx={{ ml: 1, display: "flex" }}>
        <Stack spacing={0.5}>
          <Typography fontWeight={700}>Confirmação dos dados</Typography>
        </Stack>
      </Box>
    </Grid>
    <Grid container spacing={1} sx={{ mt: 2 }} >
      <div className="d-lg-none">
        <Grid container>
          <Grid item xs={12} md={12} alignItems="center" >
            {showTextFase ? (
              <Box display="flex" alignItems="center">
                <Typewriter text={"Pronto! Agora basta conferir os dados da pessoa e clicar em confirmar!"} variant={"subtitle1"} color={"#2e7d32"} />
              </Box>
            ) : (
              <Box display="flex" alignItems="center" >
                <ThreeDotsSpinner />
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
      <div className="d-none d-lg-block">
        <Grid container>
          <Grid item xs={12} md={12} alignItems="center" >
            {showTextFase ? (
              <Box display="flex" alignItems="center">
                <Typewriter text={"Pronto! Agora basta conferir os dados da pessoa e clicar em confirmar!"} variant={"subtitle1"} color={"#2e7d32"} />
              </Box>
            ) : (
              <Box display="flex" alignItems="center" >
                <ThreeDotsSpinner />
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
    </Grid>
    <Divider sx={{ mt: 1, mb: 2, ml: -3, borderColor: '#388e3c' }}/>

    <Grid container sx={{ mb: 4 }}>
      {/* Informações gerais */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} sx={{mb: '-20px'}}>
          <Box sx={{ ml: 1, display: "flex", flexDirection: "column" }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}><Input sx={{mr: 1}}/> Dados gerais</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={6} md={6}>
          <ListItem>
            <ListItemText primary="Cadastrar como:" secondary={textType(data.types)} />
          </ListItem>
        </Grid>
        <Grid item xs={6} md={6}>
          <ListItem>
            <ListItemText primary={data.typePerson == 'Jurídica' ? "Nome Fantasia" : "Nome"} secondary={data.name} />
          </ListItem>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4} md={4}>
          <ListItem>
            <ListItemText primary="Tipo de pessoa:" secondary={data.typePerson} />
          </ListItem>
        </Grid>
        <Grid item xs={4} md={4}>
          <ListItem>
            <ListItemText primary="Tipo de Documento:" secondary={data.document_type} />
          </ListItem>
        </Grid>
        <Grid item xs={4} md={4}>
          <ListItem>
            <ListItemText primary="Documento de Identificação:" secondary={data.document} />
          </ListItem>
        </Grid>
      </Grid>

      {/* Informações fiscais */}
      <Grid container spacing={2} sx={{mt: 1}} display={(data.typePerson == 'Jurídica' ? 'flex' : 'none')}>
        <Grid item xs={12} md={12} sx={{mb: '-20px'}}>
          <Box sx={{ ml: 1, display: "flex", flexDirection: "column" }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}><Input sx={{mr: 1}}/> Informações fiscais</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={6} md={6}>
          <ListItem>
            <ListItemText primary="Razão social:" secondary={data.corporateReason} />
          </ListItem>
        </Grid>
        <Grid item xs={6} md={6}>
          <ListItem>
            <ListItemText primary="Optante pelo simples:" secondary={data.simples_nacional} />
          </ListItem>
        </Grid>
        <Grid item xs={6} md={6}>
          <ListItem>
            <ListItemText primary="Inscrição estadual:" secondary={data.stateregistration} />
          </ListItem>
        </Grid>
        <Grid item xs={6} md={6}>
          <ListItem>
            <ListItemText primary="Inscrição municipal:" secondary={data.municipalRegistration} />
          </ListItem>
        </Grid>
      </Grid>

      {/* Contatos */}
      <Grid container spacing={2} sx={{ mb: 3, mt: 1 }}>
        <Box sx={{ mb: -1, ml: 1, display: "flex" }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}><Contacts sx={{mr: 1}}/> Dados para contato</Typography>
          </Stack>
        </Box>
        {data.contacts.map((contact, index) => (<>
          <Grid item xs={12} sm={12} key={'gc_' + index}>
            <Box sx={{ ml: 1, display: "flex" }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}><Input sx={{mr: 1}}/> Contato {index + 1}</Typography>
              </Stack>
            </Box>
            <Grid container spacing={2} sx={{mb: 2}}>
              <Grid item xs={6} sm={6}>
                <ListItem>
                  <ListItemText primary="Pessoa de contato:" secondary={contact.name} />
                </ListItem>
              </Grid>
              <Grid item xs={6} sm={6}>
                <ListItem>
                  <ListItemText primary="Email:" secondary={contact.email} />
                </ListItem>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={4}>
                <ListItem>
                  <ListItemText primary="Telefone Comercial:" secondary={contact.phoneCommercial} />
                </ListItem>
              </Grid>
              <Grid item xs={6} sm={4}>
                <ListItem>
                  <ListItemText primary="Telefone Celular:" secondary={contact.phoneCell} />
                </ListItem>
              </Grid>
              <Grid item xs={6} sm={4}>
                <ListItem>
                  <ListItemText primary="Cargo:" secondary={contact.office} />
                </ListItem>
              </Grid>
            </Grid>
          </Grid>
        </>))}
      </Grid>

      {/* Endereços */}
      <Grid container spacing={2} sx={{ mb: 3, mt: 1 }}>
        <Box sx={{ mb: -1, ml: 1, display: "flex" }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}><Business sx={{mr: 1}}/> Dados para localização</Typography>
          </Stack>
        </Box>
        {data.addresses.map((address, index) => (<>
          <Grid item xs={12} sm={12} key={'ga_' + index}>
            <Box sx={{ ml: 1, display: "flex" }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}><Input sx={{mr: 1}}/> {address.name}</Typography>
              </Stack>
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={6} sm={2}>
                <ListItem>
                  <ListItemText primary="País:" secondary={address.country} />
                </ListItem>
              </Grid>
              <Grid item xs={12} sm={3}>
                <ListItem>
                  <ListItemText primary="Cep:" secondary={address.cep} />
                </ListItem>
              </Grid>
              <Grid item xs={12} sm={5}>
                <ListItem>
                  <ListItemText primary="Endereço:" secondary={address.address} />
                </ListItem>
              </Grid>
              <Grid item xs={12} sm={2}>
                <ListItem>
                  <ListItemText primary="Número:" secondary={address.number} />
                </ListItem>
              </Grid>

              <Grid item xs={12} sm={2.5}>
                <ListItem>
                  <ListItemText primary="Estado:" secondary={address.state} />
                </ListItem>
              </Grid>
              <Grid item xs={12} sm={3}>
                <ListItem>
                  <ListItemText primary="Cidade:" secondary={address.city} />
                </ListItem>
              </Grid>
              <Grid item xs={12} sm={3}>
                <ListItem>
                  <ListItemText primary="Bairro:" secondary={address.neighborhood} />
                </ListItem>
              </Grid>
              <Grid item xs={12} sm={3.5}>
                <ListItem>
                  <ListItemText primary="Complemento:" secondary={address.complement} />
                </ListItem>
              </Grid>
            </Grid>
          </Grid>
        </>))}
      </Grid>

      {/* Observações */}
      <Grid container spacing={3} sx={{ mb: 2, mt: 0 }}>
        <Box sx={{ mb: -1, ml: 1, display: "flex" }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}><Notes sx={{mr: 1}}/>Observações gerais</Typography>
          </Stack>
        </Box>
        <Grid item xs={12} sm={12}>
          <ListItem>
            <ListItemText primary="" secondary={data.observations} />
          </ListItem>
        </Grid>
      </Grid>
    </Grid >

    <FooterModal
      data={data}
      obj_fn={obj_fn}
      isError={isError}
      returnError={returnError}
    />
  </>);
}
import { useContext, useEffect, useState } from "react";
import { Container, Grid, TextField, Button, Box, Paper, Typography, MenuItem, Select, Stack, FormControl, InputLabel, AppBar, Toolbar, IconButton } from "@mui/material";
import Typewriter from "../../../components/Typewriter/Typewriter";
import ChatPlan from "../components/FormPlan/ChatPlan";
import { Span } from "../../../components/Typography";
import IAIcon from "../../../components/Icons/chat/IAIcon";
import { toast } from "react-toastify";
import useStore from "../../../store/useStore";
import { format } from "date-fns";
import SuccessTarget from "./SuccessTarget/SuccessTarget";
import api from "../../../services/api";
import { Context } from "../../../components/Context/AuthContext";
import { useParams } from "react-router-dom";
import useHarvestTargetReposStore from "../../../store/useHarvestTargetReposStore";

const UnitOptions = [
  { label: "sc 30kg", value: "sc 30kg" },
  { label: "sc 40kg", value: "sc 40kg" },
  { label: "sc 50kg", value: "sc 50kg" },
  { label: "sc 60kg", value: "sc 60kg" },
  { label: "t", value: "t" },
  { label: "kg", value: "kg" },
  { label: "g", value: "g" },
  { label: "mg", value: "mg" },
];

const ValueCost = [
  { label: "R$", value: "pt-BR" },
  { label: "$", value: "en-US" },
];

const MeasureOptions = [
  {
    value: "ha",
    label: "hectare (ha)",
  },
  {
    value: "m2",
    label: "metros (m²)",
  },
];

export default function FormGoalTargetHarvestGeneral({ handleClose, areas }) {
  console.log("FormGoalTargetHarvestGeneral");
  console.log(areas)
  //console.log(planTargetReposFilterIdAreaMap)
  const { idFarm, id } = useParams();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { aux_add, setAuxAdd } = useContext(Context);

  const reposTargetPlanning = useHarvestTargetReposStore((state) => state);
  //console.log(reposTargetPlanning);
  // errors.chave define true (property.chave não validada) ou false
  const [errors, setErrors] = useState({});
  // gaurdar o array de erros de cada chave
  const [formError, setFormError] = useState({});

  const handleOpenChat = () => {
    setOpen(true);
  };

  const handleCloseChat = () => {
    setOpen(false);
  };

  const usestore = useStore();
  //const reposActivitiesInputs = usestore.reposActivityInput;
  //const initCreation = usestore.reposLivestock.livestockRepos;
  //const property_id = localStorage.getItem("Property") || 1;
  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));

  // seta as opções de safras
  //const optionsHarvests = usestore.reposHarvest.listHarvest;

  const initHarvests = usestore.reposHarvest.harvestRepos;
  console.log(initHarvests)
  //const currentHarvests = usestore.reposHarvest.harvestRepos;
  const harvestCurrent = usestore.reposHarvest.getHarvestStore("id", initHarvests.id);
  //console.log("harvestCurrent")
  //console.log(harvestCurrent[0])

  // // atualizar o localstorage
  // const fetchTodos = useActivityInputReposStore((state) => state.fetch);
  // const fetchAreas = useAreasMapReposStore((state) => state);
  // const fetchLivestock = useLivestockReposStore((state) => state);

  function calcularSomatorioUsedArea(array) {
    //console.log(array)
    let somatorio = 0;

    for (let i = 0; i < array.length; i++) {
      const usedArea = parseFloat(array[i].usedArea.replace(",", "."));
      somatorio += usedArea;
    }

    return somatorio;
  }
  console.log("areaActive")

  //console.log(areaActive)
  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: harvestCurrent[0].id_property,
    id_harvest: harvestCurrent[0].id,
    id_cultivation: harvestCurrent[0].id_cultivation,
    id_group: harvestCurrent[0].id_group,
    // group_name: harvestCurrent[0].group_name,
    // id_areamap: areaActive.data.id,
    cultivation_name: harvestCurrent[0].cultivation_name,
    //startDate: harvestCurrent[0].data_inicial,
    // endDate: harvestCurrent.data_fim,
    date_start: format(new Date(harvestCurrent[0].date_start), "yyyy-MM-dd"),
    date_prev_end: format(new Date(harvestCurrent[0].date_prev_end), "yyyy-MM-dd"),
    expectedYield: "",
    unit: "sc 60kg",
    cost: "",
    valueCost: "pt-BR",
    unitCost: "sc 60kg",
    //area: calcularSomatorioUsedArea(harvestCurrent[0].areas),
    // area: areaActive.area,
    // measure: areaActive.measure,
    observation: "",
    isDeleted: 0,
  });

  console.log(data);

  //const [selectedInputs, setSelectedInputs] = useState([{ input: null, unit: "L", dose: "0,000", total: "0,00" }]);

  const handleChange = (event) => {

    const { name, value } = event.target;
    //console.log(name, value);
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const para validar o button continuar, se existem as 2 validações
  const isError = () => {
    if (!data.startDate || data.startDate.trim() === "") {
      return true;
    }
    if (!data.expectedYield || data.expectedYield.trim() === "") {
      return true;
    }
    if (!data.area || data.area.trim() === "") {
      return true;
    }
    return false;
  };

  const returnError = () => {
    return true;
  };
  const color = "#2e7d32";

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log("onSubmit");
    // console.log(data);
    setFormError({});
    let errors = {};
    let formOk = true;

    // if (data.nameBatch.length < 5) {
    //   errors.nameBatch = true;
    //   formOk = false;
    //   // } else if (property.address.length < 3) {
    // } else if (data.areas.length === 0) {
    //   errors.area = true;
    //   formOk = false;
    // }

    const header = { header: { "Content-Type": "application/json" } };

    if (formOk) {
      // console.log(data);
      const res = await api
        .post("/planning/targetHarvest", data, header)
        .then((response) => {
          // console.log(response.data);
          if (response.data.isSuccess) {
            //setIsLoading(true);
            toast.success("Meta criada com sucesso!");
            reposTargetPlanning.fetch({ id_farm: idFarm }).then((res) => {
              setTimeout(() => {
                setAuxAdd(aux_add + 1);
              }, 200);
            });
            // var updt_lv = reposLivestocks.updateLivestockStore(id, "all", data);
            // setTimeout(() => {
            //   setAuxAdd(aux_add + 1);
            //   handleClose();
            // }, updt_lv.length);
          }
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
        });
    } else {
      // escreve mensagem de erro para cliente
      toast.error("Por favor, preencha os campos obrigatórios");
      setFormError(errors);
      //return false;
    }
  };

  return (
    <>
      {/* {isLoading && <SuccessTarget handleClose={handleClose} />} */}
      <Container maxWidth="sm" sx={{ mb: 10 }}>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={10} sm={10}>
            <Typography variant="subtitle1" display="block" gutterBottom sx={{ ml: 2, color: "#808080" }}>
              Em caso de dúvida: clique no ícone IA
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleOpenChat}>
              <IAIcon size={32} />
            </IconButton>
          </Grid>
        </Grid>
        <Paper sx={{ p: 3, mt: 0, mb: 5 }}>
          {/* <Typography variant="h4" align="center" gutterBottom>
            Planejamento da Safra de Milho
          </Typography> */}

          <Grid container sx={{ mt: 1, mb: 6 }}>
            <Grid container sx={{ mt: 1, mb: 2 }} className="d-lg-none">
              <Grid item xs={12} md={12} alignItems="center" style={{ height: 80 }}>
                <Typography style={{ color: "#2e7d32" }}>
                  <Typewriter tam={80} minHeight={0} text="Bem-vindo à definição de metas na safra! Agora é o momento de estabelecer sua meta de produção e alcançar novos patamares de sucesso. " />
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 1, mb: 6 }} className="d-none d-lg-block">
              <Grid item xs={12} md={12} alignItems="center" style={{ height: 30 }}>
                <Typography style={{ color: "#2e7d32" }}>
                  <Typewriter tam={30} minHeight={0} text="Bem-vindo à definição de metas na safra! Agora é o momento de estabelecer sua meta de produção e alcançar novos patamares de sucesso. " />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField name="date_start" label="Data de Início" type="date" required fullWidth value={data.date_start} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField name="date_prev_end" label="Data de Término" type="date" required fullWidth value={data.date_prev_end} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  name="expectedYield"
                  variant="filled"
                  color="success"
                  focused
                  label="Produção Esperada"
                  type="number"
                  required
                  fullWidth
                  value={data.expectedYield}
                  onChange={handleChange}
                  error={formError.expectedYield && data.expectedYield === ""}
                  helperText={formError.expectedYield && data.expectedYield === "" ? "Por favor, preencha o campo obrigatório!" : "Estime o tamanho da sua produção."}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack spacing={0.5} sx={{ flexGrow: 1, mt: -1, textAlign: "center" }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Unidade</InputLabel>
                    <Select name="unit" fullWidth label="Unidade" value={data.unit} onChange={handleChange}>
                      {UnitOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Stack spacing={0.5} sx={{ flexGrow: 1, mt: 0, textAlign: "center" }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 60 }}>
                    <InputLabel id="demo-simple-select-standard-label">Moeda</InputLabel>
                    <Select name="valueCost" fullWidth label="Moeda" value={data.valueCost} onChange={handleChange}>
                      {ValueCost.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="cost"
                  variant="filled"
                  color="success"
                  focused
                  label="Valor da cotação da cultura"
                  type="number"
                  required
                  fullWidth
                  value={data.cost}
                  onChange={handleChange}
                  error={formError.cost && data.cost === ""}
                  helperText={formError.cost && data.cost === "" ? "Por favor, preencha o campo obrigatório!" : "Defina o valor atual da cotação da cultura. Ex.: R$ 54,00 sc 60kg "}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Stack spacing={0.5} sx={{ flexGrow: 1, mt: 0, textAlign: "center" }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Unidade</InputLabel>
                    <Select name="unitCost" fullWidth label="Unidade" value={data.unitCost} onChange={handleChange}>
                      {UnitOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={8}>
                <TextField
                  name="area"
                  variant="standard"
                  label="Área"
                  type="number"
                  disabled
                  required
                  fullWidth
                  value={data.area}
                  onChange={handleChange}
                  error={formError.area && data.area === ""}
                  helperText={formError.area && data.area === "" ? "Por favor, preencha o campo obrigatório!" : "Estime a área da sua produção."}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Stack spacing={0.5} sx={{ flexGrow: 1, mt: -1, textAlign: "center" }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Medida</InputLabel>
                    <Select disabled autoFocus name="measure" fullWidth label="Unidade" value={data.measure} onChange={handleChange}>
                      {MeasureOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
              <Typography variant="caption" display="block" gutterBottom sx={{ ml: 2, color: "#808080" }}>
                Ex.: Produção de 2.000 sacas de 60 kg em uma área de 50 hectares de cultivo.
              </Typography>
            </Grid> */}
            {/* <Button type="submit" variant="contained" color="primary" fullWidth>
              Gerar Planejamento
            </Button> */}
            <AppBar elevation={3} position="fixed" sx={{ top: "auto", bottom: 0, background: "#fff" }}>
              <Toolbar>
                <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                  <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                    <Span>cancelar</Span>
                  </Button>
                </Box>
                {data && (
                  <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                    <Button color="success" variant="contained" type="submit" onClick={onSubmit}>
                      Salvar
                    </Button>
                  </Box>
                )}
              </Toolbar>
            </AppBar>
          </Box>
        </Paper>
        <ChatPlan open={open} onClose={handleCloseChat} />
      </Container>
      {/* <Container maxWidth="sm" sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12} sm={12}>
            <iframe
              title="Widget"
              src="https://cepea.esalq.usp.br/br/widgetproduto.js.php?fonte=arial&tamanho=10&largura=400px&corfundo=ffffff&cortexto=333333&corlinha=ffffff&id_indicador%5B%5D=77"
              style={{ width: "100%", height: "200px", border: "0" }}
            ></iframe>
          </Grid>
          <Grid item xs={12} sm={12}>
            <iframe
              title="Widget"
              src="https://cepea.esalq.usp.br/br/widgetproduto.js.php?fonte=arial&tamanho=10&largura=400px&corfundo=ffffff&cortexto=333333&corlinha=ffffff&id_indicador%5B%5D=2"
              style={{ width: "100%", height: "200px", border: "0" }}
            ></iframe>
          </Grid>
        </Grid>
      </Container> */}
    </>
  );
}
// banco:
//https://www.cepea.esalq.usp.br/br/consultas-ao-banco-de-dados-do-site.aspx
//https://cepea.esalq.usp.br/br/widget.aspx
// site com grafico
//https://web.graodireto.com.br/#/quotation
//https://www.graodireto.com.br/#who-buys

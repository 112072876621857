import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Calculator from '../../components/Calculator/Calculator';
import './mainContent.css';
import { useContext } from 'react';
//import { ThemeContext } from "../../components/context-api/ThemeContext";
//import { modelPecuariaCorte } from "../../bd/modelPecuariaCorte";
import { Context } from '../../components/Context/AuthContext';
import Loading from '../../components/Loader/Loading';
import { toast } from 'react-toastify';
import CardGeral from './components/CardGeral';
import Dialog from '../../components/Dialog/Dialog';
import { Box, Grid, Stack, Typography } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AppsIcon from '@mui/icons-material/Apps';
import Start from './start/Start';
import usePropertyReposStore from '../../store/usePropertyReposStore';
import TutorialCentral from './components/tutorial/TutorialCentral';
import Typewriter from '../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../components/Loader/ThreeDotsSpinner';
import useStore from '../../store/useStore';
import TutorialStepGeral from '../../components/Tutorial/TutorialStepGeral';

export default function MainContent() {
  const usestore = useStore();
  console.log(MainContent);
  const reposUser = usestore.reposUser;
  console.log(reposUser.userRepos);
  console.log(reposUser.userRepos);

  const reposProperty = usePropertyReposStore((state) => state);
  const property_data = reposProperty.getPropertyStore('id', reposProperty.propertyRepos.id);
  console.log(property_data[0]);
  // {property_data[0].latitude == "-21.7" ? <Start /> :

  const criacoes = usestore.reposLivestock.listLivestock;
  const safras = usestore.reposHarvest.listHarvest;

  console.log('criacoes');
  console.log(criacoes.length > 0);
  console.log(safras);

  const [showTypewriter, setShowTypewriter] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTypewriter(true);
    }, 2000);

    return () => clearTimeout(timer); // Limpeza do timer quando o componente é desmontado
  }, []);

  const objText = {
    icon: <AppsIcon sx={{ fontSize: 35 }} color="text.secondary" />, // Substitua pelo ícone desejado
    title: 'Painel Central', // Título do diálogo
    subtitle: 'Passo a Passo', // Subtítulo abaixo do título
    textAnimation: 'Obtenha as informações essenciais e resumidas da propriedade e acesse as suas criações e safras', // Texto que será animado pelo componente Typewriter
    description: 'Clique no botão abaixo e siga o passo a passo:', // Descrição adicional
    textButton: [
      '1- Painel central',
      '2- Criações (Gestão pecuária)',
      '3- Safras (Gestão agrícola)',
      '4- Gestão Financeira',
      '5- Registro de Produtos',
      '6- Controle de Estoque',
      '7- Controle Patrimonial',
    ], // Texto complementar ou final
    linkButton: ['/painel', '/livestock', '/harvest', '/financial', '/productcatalog', '/inventory', '/patrimony'], // O primeiro agora vai abrir o modal
    // linkButton: ['openPatrimonyRegistrarModal', '/inventory'], // Primeiro é um componente, segundo é um link
    filters_prods: [['group_category']],
  };

  return (
    <>
      {property_data[0].latitude !== '-21.7' ? (
        <Start />
      ) : (
        <div className="content-wrapper bg-white ">
          <div className="content-header" style={{ marginTop: 0 }}>
            <div className="container-fluid">
              <Grid container spacing={2} sx={{ mb: 1 }}>
                <Grid item xs={10} md={10}>
                  <Box sx={{ display: 'flex' }}>
                    <AppsIcon sx={{ fontSize: 35 }} color="text.secondary" />
                    <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                      <Typography fontWeight="500" variant="h6">
                        Painel Central
                      </Typography>
                      <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
                        Central&nbsp;de&nbsp;informações&nbsp;da&nbsp;propriedade
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ gap: 2 }}>
                    <TutorialStepGeral objText={objText} />
                    <TutorialCentral />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box display="flex" justifyContent="flex-end"></Box>
                </Grid>
                <Grid item xs={12} md={12} alignItems="center">
                  {true ? (
                    <Typewriter text={`Seja bem vindo a ${property_data[0].label.toUpperCase()}!`} variant={'subtitle1'} color={'#2e7d32'} />
                  ) : (
                    <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                      {' '}
                      <ThreeDotsSpinner />
                    </Box>
                  )}
                </Grid>
                {criacoes.length == 0 && safras.length == 0 && (
                  <Grid item xs={12} md={12} alignItems="center">
                    {showTypewriter ? (
                      <Typewriter text={`Registre sua primeira criação ou safra.`} variant={'subtitle2'} color={'#2e7d32'} />
                    ) : (
                      <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                        {' '}
                        <ThreeDotsSpinner />
                      </Box>
                    )}
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
          <section className="content">
            <div className="container-fluid">
              {/* <StatCards /> */}
              {/* <Dialog /> */}
              <CardGeral />
            </div>
          </section>
        </div>
      )}
    </>
  );
}

import { useState } from "react";
import { Box, Grid, Typography, Stack, RadioGroup, FormControlLabel, Radio } from "@mui/material";

import FormProgressNow from "./FormProgressNow";
import FormProgressRealized from "./FormProgressRealized";
import FormProgressFuture from "./FormProgressFuture";
import FooterModal from "../../../../../components/Footer/FooterModal";

const progress = [
  { id: 1, label: 'Hoje' },
  { id: 2, label: 'Foi realizada' },
  { id: 3, label: 'Será planejada' }
];
export default function FormProgressSelect({ data, obj_fn }) {
  const [showText, setShowText] = useState(false);
  //1.1texto é acionado quando o botão radio é ativado
  setTimeout(() => {
    setShowText(true);
  }, 500);

  // para validar o button next
  const isError = () => {
    return !data.progress_radio;
  };

  // ativar o botao retornar
  const returnError = () => {
    return true;
  };

  const handleRadioProgress = (event) => {
    obj_fn.setData(prevState => ({ ...prevState, [event.target.name]: event.target.value }));
  };

  return (<>
    <Grid container>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Box sx={{ ml: 1, display: "flex" }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Essa atividade será realizada:</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} sx={{ mt: 2 }} >
        <RadioGroup
          aria-label="fases"
          name="fases"
          value={data.progress_radio}
          onChange={handleRadioProgress}
          style={{ display: "flex", flexDirection: "row" }}
        >
          {progress.map((prog) => (
            <FormControlLabel
              key={prog.id}
              value={prog.id}
              name="progress_radio"
              control={<Radio color="success" />}
              label={prog.label}
            />
          ))}
        </RadioGroup>
      </Grid>
      {data.progress_radio == "1" && <FormProgressNow data={data} obj_fn={obj_fn} />}
      {data.progress_radio == "2" && <FormProgressRealized data={data} obj_fn={obj_fn} />}
      {data.progress_radio == "3" && <FormProgressFuture data={data} obj_fn={obj_fn} />}
    </Grid>

    <FooterModal
      data={data}
      obj_fn={obj_fn}
      isError={isError}
      returnError={returnError}
    />
  </>);
}

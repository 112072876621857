import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ListIcon from '@mui/icons-material/List';
import { Card, CardHeader, Divider, Grid } from '@mui/material';
import TableProductionCardEmpty from './TableProductionCardEmpty';
import useStore from '../../../store/useStore';
import CardProductionHarvest from './CardProductionHarvest';
import { useParams } from 'react-router-dom';
import Typewriter from '../../../components/Typewriter/Typewriter';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default function CardProductionharvestTabs({ area, index }) {
  const usestore = useStore();
  const { id, idFarm } = useParams();
  const [value, setValue] = React.useState(0);

  const [isLoadingText, setIsLoadingText] = React.useState(true);
  // setTimeout(() => {
  //   setIsLoadingText(true);
  // }, 3000);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log('CardProductionharvestTabs');
  console.log(area);

  // console.log(id);
  // const listProductionHarvest = listPlanning?.filter((havest) => havest.id_harvest == id);
  // console.log(listProductionHarvest);

  // const [production, setProcuction] = React.useState(listProductionHarvest);

  // console.log(listProductionHarvest.length);
  // console.log(production);

  // React.useEffect(() => {
  //   setProcuction(listProductionHarvest);
  // }, []);

  // const listAreas = usestore.reposAreas.listAreasMap;
  // console.log(listAreas);

  // console.log(listProductionHarvest);

  // for (const production of listProductionHarvest) {
  //   console.log(production);
  //   console.log(production.id_areamap);
  //   console.log(listAreas.id);
  //   for (const [index, area] of listAreas.entries()) {
  //     if (production.id_areamap == area.id) {
  //       production.dataArea = area;
  //       production.idIndex = index;
  //     }
  //   }
  // }

  // for (const production of listPlanning) {
  //   console.log(production);
  //   console.log(production.id_areamap);

  //   const matchingArea = listAreas.find((area) => area.id === production.id_areamap);

  //   if (matchingArea) {
  //     production.dataArea = matchingArea;
  //   }
  // }

  // console.log("listProductionHarvest");

  // console.log(listProductionHarvest);
  //console.log(area)

  //const listAreasHarvest = listAreas.filter(area => areaIds.includes(area.id));

  const target_active = area.target.filter((item) => {
    if (item.isActiveTargetHarvest) {
      return item;
    }
  })[0];

  console.log('target_active');
  console.log(target_active);

  const listActivityHarvesting = usestore.reposActivityHarvesting.listActivityHarvesting;
  console.log(listActivityHarvesting);
  //let this_activity = JSON.parse(JSON.stringify(usestore.reposActivityHarvesting.getActivityHarvestingStore('id', row.id)[0]));

  const listActivityHarvestingActive = listActivityHarvesting
    .flatMap((h) => h.areas) // Flatten the areas arrays into a single array
    .filter((area) => area.id_area === target_active.id_areamap); // Filter areas with id_area equal to 3

  console.log(listActivityHarvestingActive);

  return (
    <>
      <Box sx={{ width: '100%', mt: 1 }}>
        {/* {listProductionHarvest.length == 0 && <TableProductionCardEmpty title={"Safra"} />} */}
        {area && (
          <>
            <Card sx={{ mt: 1 }}>
              <CardHeader
                title={
                  <>
                    <div className="d-lg-none">
                      <Box sx={{ display: 'flex', mb: 1 }}>
                        <Typography sx={{ fontSize: 18 }}>Registre a colheita:</Typography>
                      </Box>
                      <Box sx={{ minHeight: 110 }}>
                        <Typography style={{ color: '#2e7d32' }}>
                          {isLoadingText && <Typewriter tam={110} minHeight={0} text="Registre a quantidade colhida da safra e defina o local de armazenamento." />}
                        </Typography>
                      </Box>
                    </div>

                    <div className="d-none d-lg-block">
                      <Box sx={{ display: 'flex', mb: 1 }}>
                        <Typography variant="h6" color="textPrimary">
                          Registre a colheita:
                        </Typography>
                      </Box>
                      <Box sx={{ minHeight: 30 }}>
                        <Typography style={{ color: '#2e7d32' }}>
                          {isLoadingText && <Typewriter tam={30} minHeight={0} text="Registre a quantidade colhida da safra e defina o local de armazenamento." />}
                        </Typography>
                      </Box>
                    </div>
                  </>
                }
              ></CardHeader>
              <Divider />
            </Card>
            <Box sx={{ mt: 1, mb: 14 }}>
              <CardProductionHarvest production={target_active} type={''} index={index} />
            </Box>
          </>
        )}
        {/* {plan.idIndex == "1" && <CardProductionHarvest production={plan} type={plan.dataArea.name} />}
          {plan.idIndex == "2" && <CardProductionHarvest production={plan} type={plan.dataArea.name} />}
          {plan.idIndex == "3" && <CardProductionHarvest production={plan} type={plan.dataArea.name} />} */}
        {/* </TabPanel>
          // <TabPanel key={plan.id} value={value} index={index}>
          //   {/* Renderizar o conteúdo correspondente ao índice 
             {/* Por exemplo, para o índice 0: 
            {plan.typePlanning === 'Cria' && <CardProductionLivestock plan={plan} type={"Cria"} />}
            {/* Para o índice 1: 
             {plan.typePlanning === 'Corte' && <CardProductionLivestock plan={plan} type={"Corte"} />}
             {/* Para o índice 2: 
            {plan.typePlanning === 'Outros' && <CardProductionLivestock plan={plan} type={"Outros"} />}
           </TabPanel> */}
      </Box>
    </>
  );
}

import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Menu, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import WidgetsIcon from "@mui/icons-material/Widgets";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SellIcon from '@mui/icons-material/Sell';
import HistoryIcon from '@mui/icons-material/History';
import { useState } from "react";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import SearchIcon from "@mui/icons-material/Search";


const columns = [
  { id: "name", label: "Itens", minWidth: 50 },
  { id: "localDest", label: "Local", minWidth: 100 },
  // {
  //   id: "valoruni",
  //   label: "Valor uni (R$)	",
  //   minWidth: 80,
  //   align: "right",
  //   format: (value) => value.toFixed(2),
  // },
  {
    id: "Quantidade",
    label: "Quantidade",
    minWidth: 80,
    align: "right",
  },
  // {
  //   id: "valor",
  //   label: "Valor Total(R$)",
  //   minWidth: 80,
  //   align: "right",
  //   format: (value) => value.toFixed(2),
  // },
  {
    id: "sell",
    label: <SellIcon />,
    minWidth: 50,
    align: "center",
  },
  { id: "menu", label: "", minWidth: 30 }, // Coluna do menu, sem cabeçalho
];

function createData(name, localDest, valoruni, Quantidade, valor, sell, category) {
  //const density = population / size;
  return { name, localDest, valoruni, Quantidade, valor, sell, category };
}

const rows = [
  createData("Safra milho 22/23", "Estoque: Armazen01", 10, "100,00 sc", 7263, "vender", "Milho"),
  createData("Safra milho 22/22", "Estoque: Armazen01", 10, "30,00 sc", 340, "vender", "Milho"),
  createData("Safra café 21/22", "Estoque: Armazen02", 10, "1,00 t", 6961, "vender", "Café"),

];

function groupRowsByCategory(rows) {
  const groups = {};
  rows.forEach(row => {
    if (!groups[row.category]) {
      // Cria um novo grupo se não existir
      groups[row.category] = [];
    }
    groups[row.category].push(row);
  });

  const groupedRows = [];
  Object.entries(groups).forEach(([category, rows]) => {
    // Altere aqui para definir a propriedade category como 'header'
    groupedRows.push(createData(category.toUpperCase(), '', '', '', '', '', 'header'));
    // Insere as linhas do grupo
    groupedRows.push(...rows);
  });

  return groupedRows;
}

const groupedRows = groupRowsByCategory(rows);

export default function SalesInventoryListTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [filter, setFilter] = useState(''); // Estado para o filtro selecionado
  const [searchQuery, setSearchQuery] = useState(''); // Estado para a pesquisa

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Implementação de lógica para imprimir a tabela
  const handlePrint = () => {
    // Implemente a lógica de impressão aqui
  };


  const [openDialog, setOpenDialog] = React.useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSellClick = (rowData) => {
    // Aqui você pode adicionar lógica para lidar com os dados da linha
    console.log("Vendendo item:", rowData);

    // Abre o diálogo
    handleOpenDialog();
  };

  // forma de inseri itens
  // em depesas pois é um despesas e adicinar em custo de estoque/ local de estqoque
  //https://www.youtube.com/watch?v=AiVxyGuy1mc

  return (


    <Paper sx={{ width: "100%" }}>

      {/* https://downloads.intercomcdn.com/i/o/618119275/466961ef5ad10d1db273e50a/relat%C3%B3rioestoque1.gif */}
      {/* https://downloads.intercomcdn.com/i/o/618116475/28263a7533550e88e82f5bf0/relat%C3%B3rioestoque.gif */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, p: 1 }}>
        {/* Dropdown para Filtros */}
        <Select
          sx={{ width: "30%", maxWidth: "30%", }}
          value={filter}
          variant="standard"
          onChange={handleFilterChange}
          displayEmpty
          color="success"
          size="small"
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="">Filtros</MenuItem>
          <MenuItem value="filter1">Em estoque</MenuItem>
          <MenuItem value="filter2">Sem Estoque</MenuItem>
          {/* Adicione mais opções de filtros aqui */}
        </Select>
        {/* Ícone de Imprimir */}
        <IconButton onClick={handlePrint}>
          <LocalPrintshopIcon />
        </IconButton>
      </Box>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={1}>
                Itens em Estoque
              </TableCell>
              <TableCell align="center" colSpan={4}>
                Detalhes
              </TableCell>
            </TableRow>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ top: 57, minWidth: column.minWidth }}>
                  <Typography sx={{ flex: "1 1 100%", fontWeight: "bold" }} color="inherit" variant="subtitle1" component="div">
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
              if (row.category === 'header') {
                // Renderização para a linha de cabeçalho da categoria
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell colSpan={columns.length} style={{ fontWeight: 'bold', color: 'gray', display: 'flex', alignItems: 'center' }}>
                      <WidgetsIcon sx={{ fontSize: 16, mr: 1, color: 'gray' }} />
                      {row.name}
                    </TableCell>
                  </TableRow>
                );
              } else {
                // Renderização para linhas de dados normais
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column, colIndex) => {
                      const value = row[column.id];
                      if (column.id === 'sell') {
                        return (
                          <TableCell key={colIndex} align={column.align}>
                            <Button variant="outlined" color="success" onClick={() => handleSellClick(row)} startIcon={<SellIcon sx={{ mr: 1 }} />}>

                              <Typography variant="button" display="block" gutterBottom>
                                Vender
                              </Typography>
                            </Button>
                          </TableCell>
                        );
                      }
                      if (column.id === 'menu') {
                        // Célula do menu para linhas de dados
                        return (
                          <TableCell key={colIndex} align="right">
                            <IconButton onClick={handleMenuOpen}>
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleMenuClose}
                            >
                              {/* Itens do Menu */}
                              <MenuItem onClick={handleMenuClose}><MoveUpIcon sx={{ mr: 1 }} /> Movimentar</MenuItem>
                              <MenuItem onClick={handleMenuClose}>
                                <AddIcon sx={{ mr: 1 }} /> Adicionar quantidade
                              </MenuItem>
                              <MenuItem onClick={handleMenuClose}>
                                <RemoveIcon sx={{ mr: 1 }} /> Remover quantidade
                              </MenuItem>
                              <MenuItem onClick={handleMenuClose}>
                                <SellIcon sx={{ mr: 1 }} /> Vender
                              </MenuItem>
                              <MenuItem onClick={handleMenuClose}>
                                <HistoryIcon sx={{ mr: 1 }} /> Ver histórico
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        );
                      } else {
                        // Outras células para linhas de dados
                        return (
                          <TableCell key={colIndex} align={column.align}>
                            <strong>{column.format && typeof value === "number" ? column.format(value) : value}</strong>
                          </TableCell>
                        );
                      }

                    })}
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage={"Linhas por página"}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog fullWidth={true}
        maxWidth={'md'} open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Venda</DialogTitle>
        <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
          <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
            <DialogContent>
              <Grid container sx={{ mb: 0, mt: -3 }}>
                < Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
                  <Box sx={{ display: "flex" }}>
                    <Stack spacing={0.5}>
                      <Typography fontWeight={700}>texto</Typography>
                    </Stack>
                  </Box>
                </Grid>
                < Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
                  <Box sx={{ display: "flex" }}>
                    <Stack spacing={0.5}>
                      <Typography fontWeight={400}>texto</Typography>
                    </Stack>
                  </Box>
                </Grid>
                < Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
                  <p>texto</p>
                </Grid>
              </Grid>
            </DialogContent>
          </Paper>
        </Container>
        <DialogActions>
          <Button color="success" onClick={handleCloseDialog}>Cancelar</Button>
          <Button color="success" onClick={handleCloseDialog}>Confirmar Venda</Button>
        </DialogActions>
      </Dialog>
    </Paper>

  );
}
